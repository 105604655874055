import { Injectable } from "@angular/core";

import { SourceTransaction } from "../../../models/data/blobby/source-transaction.data";
import { GlossEncryptedDataType } from "../../../models/enum/glossEncryptedDataType";
import { DataRepositoryService } from "../../DataRepository/data-repository.service";
import { DataServiceAbstraction } from "../data.service.abstraction";

@Injectable({
  providedIn: "root",
})
export class SourceTransactionService implements DataServiceAbstraction {
  constructor(private dataRepositoryService: DataRepositoryService) {}
  create(item: GlossEncryptedDataType): Promise<GlossEncryptedDataType> {
    return Promise.resolve(undefined);
  }

  delete(item: GlossEncryptedDataType, triggerObservable: boolean): Promise<boolean> {
    return Promise.resolve(false);
  }

  async get(sourceTransactionId: string): Promise<SourceTransaction> {
    const sourceTransactions = await this.getAll();
    return sourceTransactions.find((source) => source.id === sourceTransactionId);
  }

  async getAll(): Promise<SourceTransaction[]> {
    return await this.dataRepositoryService.getAllSourceTransactions();
  }

  update(
    item: GlossEncryptedDataType,
    triggerObservable: boolean
  ): Promise<GlossEncryptedDataType> {
    return Promise.resolve(undefined);
  }
}
