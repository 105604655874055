import { AfterViewInit, Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { parse } from "date-fns";

import { ReferenceData } from "@bitwarden/web-vault/app/models/data/blobby/reference-data.data";

import "./reference-data-table.scss";

interface CurrencyRate {
  currency: string;
  rate: number;
}

@Component({
  selector: "app-reference-data-table",
  templateUrl: "./reference-data-table.component.html",
  styles: ["reference-data-table.scss"],
})
export class ReferenceDataTableComponent implements OnInit, AfterViewInit {
  @Input() referenceData: Array<ReferenceData>;

  selectedDate: string;
  selectedBase: string | null = null;
  displayedColumns: string[] = ["currency", "rate"];
  dataSource: CurrencyRate[] = [];
  uniqueBaseCurrencies: string[] = [];
  uniqueAvailableDates: Date[];

  refTableForm: FormGroup;

  constructor(private formBuilder: FormBuilder) {
    this.refTableForm = this.formBuilder.group({
      dateControl: [null, Validators.required],
      baseControl: [null, Validators.required],
    });
  }

  ngAfterViewInit() {
    this.initializeComponent();
  }

  ngOnInit(): void {
    this.initializeComponent();
  }

  private initializeComponent(): void {
    const availableDates: Date[] = this.referenceData?.map((entry) => entry.date?.date);
    this.uniqueAvailableDates = Array.from(new Set(availableDates));
  }

  shouldDisplayTable() {
    // Implement your logic to determine whether to display the table
    return this.dataSource.length > 0 && this.selectedDate && this.selectedBase;
  }

  getDateTime(dateString: string): Date {
    /*todo check for example 05/07/2023 as it is not obvious what is month and what is day*/
    const possibleFormats = ["yyyy-MM-dd", "dd/MM/yyyy", "MM.dd.yyyy"];
    let dateTime: number;
    for (const format of possibleFormats) {
      const dateObject = parse(dateString, format, new Date());
      dateTime = dateObject.getTime();
      if (!isNaN(dateTime)) {
        break;
      }
    }

    return new Date(dateTime);
  }
  private updateUniqueBaseCurrencies() {
    if (this.selectedDate) {
      const selectedData = this.referenceData.filter((entry) => {
        const entryDate = entry.date.date;
        const refDate = this.getDateTime(entryDate.toUTCString());
        const selectedDate = this.getDateTime(this.selectedDate);
        return refDate === selectedDate;
      });

      if (selectedData) {
        this.uniqueBaseCurrencies = Array.from(new Set(selectedData.map((entry) => entry.base)));
      }
    }
  }

  onBaseChange(): void {
    this.selectedBase = this.refTableForm.get("baseControl").value;
    this.updateDataSource();
  }

  private updateDataSource(): void {
    if (this.selectedDate && this.selectedBase) {
      const selectedData = this.referenceData.find((entry) => {
        const entryDate = entry.date.date;
        return entryDate.toUTCString() === this.selectedDate && entry.base === this.selectedBase;
      });
      if (selectedData) {
        this.dataSource = Object.entries(selectedData.symbols || {}).map(
          ([currency, rate]) => ({ currency, rate } as CurrencyRate)
        );
      }
    }
  }

  onDateChange(): void {
    this.selectedBase = null;
    this.refTableForm.get("baseControl").setValue(null);
    this.selectedDate = this.refTableForm.get("dateControl").value.toISOString().slice(0, 10);
    this.updateUniqueBaseCurrencies();
    this.updateDataSource();
  }
}
