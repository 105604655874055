import { Component, EventEmitter, Output } from "@angular/core";

import { KeyConnectorService } from "@bitwarden/common/abstractions/keyConnector.service";
import { ChangePasswordComponent } from "@bitwarden/web-vault/app/settings/change-password.component";

@Component({
  selector: "app-security",
  templateUrl: "security.component.html",
})
export class SecurityComponent {
  showChangePassword = true;
  @Output() toggleDisplay = new EventEmitter<void>();

  constructor(private keyConnectorService: KeyConnectorService) {}

  async ngOnInit() {
    this.showChangePassword = !(await this.keyConnectorService.getUsesKeyConnector());
  }

  ngOnDestroy() {
    this.toggleDisplay.complete();
  }

  onActivate(event: any): void {
    if (event instanceof ChangePasswordComponent) {
      event.toggleDisplay.subscribe(() => this.toggleDisplay.emit());
    }
  }
}
