import { Injector } from "@angular/core";

import { Book } from "@bitwarden/web-vault/app/models/data/blobby/book.data";
import { Category } from "@bitwarden/web-vault/app/models/data/blobby/category.data";
import { SourceCategory } from "@bitwarden/web-vault/app/models/data/blobby/source-category";
import { Transaction } from "@bitwarden/web-vault/app/models/data/blobby/transaction.data";
import { CategoryResponse } from "@bitwarden/web-vault/app/models/data/response/category.response";
import { UnrecognizedAccount } from "@bitwarden/web-vault/app/models/types/account.types";
import { Origin } from "@bitwarden/web-vault/app/models/types/general-types";
import { SplitCategoryType } from "@bitwarden/web-vault/app/models/types/split-category-type";
import { DataRepositoryService } from "@bitwarden/web-vault/app/services/DataRepository/data-repository.service";
import { BookService } from "@bitwarden/web-vault/app/services/DataService/book/book.service";
import { CategoryService } from "@bitwarden/web-vault/app/services/DataService/category/category.service";

import { SourceTransaction } from "../../../../models/data/blobby/source-transaction.data";
import { BasiqIoTransactionType } from "../../../../models/types/basiq-io-transaction.types";
import { TransactionService } from "../../../../services/DataService/transaction/transaction.service";
import { validationResult } from "../../../../validators/base-validator";
import { CsvValidator } from "../../../../validators/csv-validator";

import { BasiqIoTransaction } from "./basiq-io-transaction";

export class BasiqIoMapper {
  private static _rawBasiqIoTransactions: BasiqIoTransactionType[];
  static _records: validationResult = {
    currencies: [],
    duplicated: [],
    newRecord: [],
    preview: [],
    newAccounts: [],
    noCurrencyTransactions: [],
  };
  static sourceTransactions: SourceTransaction[];
  static refresh = false;
  private validator: CsvValidator;
  private transactionService: TransactionService;
  private bookService: BookService;
  private dataRepositoryService: DataRepositoryService;
  private categoryService: CategoryService;
  private defaultCategory: Category;
  constructor(
    rawBasicIoTransactions: BasiqIoTransactionType[],
    accounts: any[],
    private injector: Injector
  ) {
    this.initialiseMapper(rawBasicIoTransactions, accounts);
  }

  initialiseMapper(rawBasicIoTransactions: BasiqIoTransactionType[], accounts: any[]) {
    BasiqIoMapper._records = {
      currencies: [],
      duplicated: [],
      newRecord: [],
      preview: [],
      newAccounts: [],
      noCurrencyTransactions: [],
    };
    BasiqIoMapper._rawBasiqIoTransactions = rawBasicIoTransactions;
    this.validator = this.injector.get(CsvValidator);
    this.transactionService = this.injector.get(TransactionService);
    this.bookService = this.injector.get(BookService);
    this.dataRepositoryService = this.injector.get(DataRepositoryService);

    const accountsFromBasiq = [...accounts];
    const accountsInTransactions = this.getAccountsInTransactions(
      rawBasicIoTransactions,
      accountsFromBasiq,
      []
    );
    this.getNewAccountsFromBasiq(accountsInTransactions).then(
      (newAccounts) => (BasiqIoMapper._records.newAccounts = newAccounts)
    );
  }

  /**
   * @deprecated
   */
  async mapBasiqIoTransaction(
    accounts: any[],
    rawBasiqIoTransaction: BasiqIoTransactionType,
    relatedBook: Book,
    categories: SplitCategoryType[]
  ) {
    const basiqIoTransaction = new BasiqIoTransaction(rawBasiqIoTransaction);
    basiqIoTransaction.runTransactionCalculations(accounts, categories, relatedBook);
    await this.runForDuplication(basiqIoTransaction.transaction);
  }

  async runForDuplication(transaction: Transaction) {
    if (!BasiqIoMapper.sourceTransactions || BasiqIoMapper.refresh) {
      BasiqIoMapper.sourceTransactions = await this.transactionService.getAllSourceTransactions();
    }
    const isDuplicate = await this.validator.checkForDuplication(
      transaction,
      BasiqIoMapper.sourceTransactions
    );
    transaction.setStatus(isDuplicate);
    if (isDuplicate) {
      BasiqIoMapper._records.duplicated.push(transaction);
    } else {
      BasiqIoMapper._records.preview.push(transaction);
    }
  }

  getCategoryNamesFromBasiqIoTransaction(rawBasiqIoTransaction: BasiqIoTransactionType): string[] {
    if (rawBasiqIoTransaction.enrich?.category?.anzsic?.class?.title) {
      return [rawBasiqIoTransaction.enrich.category.anzsic.class.title];
    }

    if (rawBasiqIoTransaction.subClass?.title) {
      return [rawBasiqIoTransaction.subClass.title];
    }

    if (rawBasiqIoTransaction.enrich?.tags?.length) {
      const clearNames: string[] = [];
      const categoryNamesArray: string[] = rawBasiqIoTransaction.enrich.tags.map((tag: string) => {
        const tagSplit = tag.split(":");
        if (tagSplit.length > 1 && isNaN(Number(tagSplit[1]))) {
          return tagSplit[1];
        }
        return "";
      });

      categoryNamesArray.forEach((item, index) => {
        if (item !== "" && !clearNames.includes(item)) {
          clearNames.push(item);
        }
      });
      return clearNames;
    }

    if (rawBasiqIoTransaction.enrich?.cleanDescription) {
      const categories: string[] = [];
      if (rawBasiqIoTransaction.enrich.cleanDescription.startsWith("TRANSFER TO")) {
        categories.push("Transfer");
        return categories;
      } else {
        return [rawBasiqIoTransaction.enrich.cleanDescription];
      }
    }

    return [];
  }

  /**TODO implement a mapper or maybe a better way to map transactions' data to proper categories*/
  getMappedCategoryName(categoryName: string) {
    if (categoryName === "Unknown") {
      return "Uncategorized";
    }

    return categoryName;
  }

  addNewCategory(splitCategories: SplitCategoryType[], mappedCategoryName: string) {
    const newCategory = new Category(new CategoryResponse({ name: mappedCategoryName }));

    if (newCategory) {
      const newSplitCategory: SplitCategoryType = {
        categoryId: newCategory.id,
        category: newCategory,
        weight: 1,
        name: newCategory.name,
      };

      splitCategories.push(newSplitCategory);
    }

    return splitCategories;
  }

  addExistingCategory(splitCategories: SplitCategoryType[], existingCat: Category) {
    const newSplitCategory: SplitCategoryType = {
      categoryId: existingCat.id,
      category: existingCat,
      weight: 1,
      name: existingCat.name,
    };

    splitCategories.push(newSplitCategory);

    return splitCategories;
  }

  async addSourceCategory(
    splitCategories: SplitCategoryType[],
    sourceCategory: SourceCategory,
    mappedCategoryName: string
  ) {
    const existingCat = await this.dataRepositoryService.getCategoryById(sourceCategory.categoryId);
    if (existingCat) {
      splitCategories = this.addExistingCategory(splitCategories, existingCat);
    } else {
      splitCategories = this.addNewCategory(splitCategories, mappedCategoryName);
    }
    return splitCategories;
  }
  async saveCategoriesFromBasiq(
    rawBasiqIoTransaction: BasiqIoTransactionType
  ): Promise<SplitCategoryType[]> {
    const categoryNames = this.getCategoryNamesFromBasiqIoTransaction(rawBasiqIoTransaction);
    let splitCategories: SplitCategoryType[] = [];
    if (categoryNames.length) {
      for (const categoryName of categoryNames) {
        const mappedCategoryName: string = this.getMappedCategoryName(categoryName);
        const sourceCategory = await this.dataRepositoryService.getSourceCategoryByName(
          mappedCategoryName
        );
        if (sourceCategory) {
          splitCategories = await this.addSourceCategory(
            splitCategories,
            sourceCategory,
            mappedCategoryName
          );
        } else {
          splitCategories = this.addNewCategory(splitCategories, mappedCategoryName);
        }
      }
      return splitCategories;
    }

    return [];
  }

  async getDefaultCategory(): Promise<SplitCategoryType[]> {
    this.categoryService = this.categoryService || this.injector.get(CategoryService);
    this.defaultCategory =
      this.defaultCategory || (await this.categoryService.getGeneralDefaultCategory());

    return [
      {
        categoryId: this.defaultCategory.id,
        category: this.defaultCategory,
        weight: 1,
        name: this.defaultCategory.name,
      },
    ];
  }

  async categorizeTransactions(
    transactions: BasiqIoTransactionType[]
  ): Promise<BasiqIoTransactionType[]> {
    for (const transaction of transactions) {
      transaction.categories = await this.saveCategoriesFromBasiq(transaction);
      if (transaction.categories.length === 0) {
        transaction.categories = await this.getDefaultCategory();
      }
    }
    return transactions;
  }

  async classifyTransactions(
    transactions: BasiqIoTransactionType[]
  ): Promise<BasiqIoTransactionType[]> {
    for (const transaction of transactions) {
      transaction.classifications = [];
    }

    return transactions;
  }

  get records(): validationResult {
    return BasiqIoMapper._records;
  }

  get rawBasiqIoTransactions(): BasiqIoTransactionType[] {
    return BasiqIoMapper._rawBasiqIoTransactions;
  }

  getAccountsInTransactions(
    transactions: BasiqIoTransactionType[],
    accounts: any[],
    relatedAccounts: any[]
  ): any[] {
    if (accounts.length > 0) {
      const account = accounts[0];
      const noTransactionsOfAccount = transactions.filter(
        (transaction) => transaction.account !== account.id
      );
      const isAccountInTransactions = noTransactionsOfAccount.length !== transactions.length;
      if (isAccountInTransactions) {
        relatedAccounts.push(account);
        accounts.shift();
        return this.getAccountsInTransactions(noTransactionsOfAccount, accounts, relatedAccounts);
      } else {
        accounts.shift();
        return this.getAccountsInTransactions(noTransactionsOfAccount, accounts, relatedAccounts);
      }
    } else {
      return relatedAccounts;
    }
  }

  async getNewAccountsFromBasiq(accounts: any[]) {
    const newAccounts: UnrecognizedAccount[] = [];

    for (const account of accounts) {
      /** check for book-renderer existence */
      const bookExists = await this.bookService.isThereBook(account.name);

      /** get link to the book-renderer if exists ... Cuz that book-renderer might have been linked before */
      const bookLink = await this.bookService.getBookLink(account.name);
      if (!bookExists && !bookLink) {
        const institution = await this.dataRepositoryService.getInstitutionByBasiqInstoId(
          account.institution
        );
        newAccounts.push({
          _id: account.id,
          currencies: [account.currency],
          source: account.name,
          accountId: account.name,
          action: "default",
          selectedCurrency: account.currency,
          selectedTimeZone: null,
          institutionAccountLink: {
            institutionId: institution?.id,
            institutionAccountId: null,
          },
          origin: Origin.basiq,
        });
      }
    }

    return newAccounts;
  }
}
