import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { Subscription } from "@bitwarden/web-vault/app/models/data/subscription.data";
import { SubscriptionState } from "@bitwarden/web-vault/app/services/DataService/subscription/state/subscription.reducer";

import { SideMenu } from "../../../layouts/menu/side-menu";
import { MenuState } from "../../../layouts/menu/state/side-menu.reducer";
import { Preference } from "../../../models/data/blobby/preference.data";
import { Transaction } from "../../../models/data/blobby/transaction.data";
import { PreferenceSettingState } from "../../../services/DataService/preference/state/preference.reducer";
import { TransactionState } from "../../../services/DataService/transaction/state/transaction.reducer";

export type StateManagementType = boolean | Preference | Transaction[] | SideMenu | Subscription;
export type StoreState = PreferenceSettingState | TransactionState | MenuState | SubscriptionState;

@Injectable({
  providedIn: "root",
})
export class StateManagement {
  private state: StateManagementType;
  protected unsubscribe$ = new Subject<void>();

  getState(observable$: Observable<StateManagementType>) {
    observable$.pipe(takeUntil(this.unsubscribe$)).subscribe((state) => {
      if (state) {
        this.state = state;
      }
    });

    return this.state;
  }
}
