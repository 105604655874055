import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";

import { SubscriptionService } from "../subscription.service";

import { SubscriptionActions } from "./subscription.action";

@Injectable()
export class SubscriptionEffects {
  constructor(private actions$: Actions, private subscriptionService: SubscriptionService) {}

  loadSubscrition$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SubscriptionActions.load),
      mergeMap(() =>
        this.subscriptionService.loadSubscription$().pipe(
          map((subscription) => SubscriptionActions.loadSuccess({ subscription })),
          catchError((error: unknown) => of(SubscriptionActions.loadFailure({ error })))
        )
      )
    );
  });
}
