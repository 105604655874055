<div class="modal-content">
  <div class="modal-header">
    <span> {{ "pleaseTypeAYear" | i18n }}</span>
  </div>
  <div class="modal-body">
    <div>
      <mat-form-field>
        <input
          class="year-input"
          matInput
          [formControl]="inputControl"
          (change)="onChange($event)"
          type="number"
          [max]="currentYear"
        />
        <p class="year-error" *ngIf="!yearFormatFits">{{ "yearFormatError" | i18n }}</p>
      </mat-form-field>
    </div>

    <div class="modal-footer right-buttons-container">
      <button mat-raised-button mat-dialog-close (click)="closeDialogue()">
        {{ "cancel" | i18n }}
      </button>
      <button mat-raised-button color="primary" type="submit" (click)="save()">
        {{ "save" | i18n }}
      </button>
    </div>
  </div>
</div>
