<div class="primary-summary-legend">
  <div
    class="legend-container tw-ml-24 tw-flex tw-w-auto tw-flex-row tw-gap-4 xs:tw-ml-0 xs:tw-justify-around xs:tw-gap-0"
    *ngIf="showLegend"
  >
    <div
      class="legend-entry xs:tw-flex xs:tw-flex-col xs:tw-items-center xs:tw-justify-center"
      *ngFor="let legendEntry of legendData"
    >
      <svg height="10" width="50">
        <line
          x1="0"
          y1="5"
          x2="20"
          y2="5"
          style="stroke:{{ legendEntry.colour }} ;stroke-width:2"
          fill="white"
        />
        <circle
          cx="25"
          cy="5"
          r="5"
          style="stroke:{{ legendEntry.colour }}"
          stroke-width="1"
          fill="white"
        ></circle>
        <line
          x1="30"
          y1="5"
          x2="50"
          y2="5"
          style="stroke:{{ legendEntry.colour }} ;stroke-width:2"
          fill="white"
        />
      </svg>
      <span class="xs:tw-w-12 xs:tw-text-xs">{{ legendEntry.title }}</span>
    </div>
  </div>
</div>
