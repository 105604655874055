import { Injectable } from "@angular/core";

import { AppState } from "../state/app.state";

@Injectable({
  providedIn: "root",
})
export class AppStateService {
  private readonly appState: AppState;

  constructor() {
    if (!this.appState) {
      this.appState = new AppState();
    }
  }

  getAppStateInstance(): AppState {
    return this.appState;
  }
}
