export class TransactionTemplateData {
  private readonly __v = 1;
  private _id: string;

  get id(): string {
    return this._id;
  }

  symbol: string;
  currency: string;
  quantity: number;
}
