import { BaseResponse } from "../../../../../../../libs/common/src/models/response/base.response";

export class ImportHandlerResponse extends BaseResponse {
  id: string;
  name: string;

  constructor(response: any) {
    super(response);
    this.id = this.getResponseProperty("_id");
    this.name = this.getResponseProperty("name");
  }
}
