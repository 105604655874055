import { Injectable } from "@angular/core";

import { NewAccountActionTypes } from "@bitwarden/web-vault/app/models/enum/account.enum";
import { BookService } from "@bitwarden/web-vault/app/services/DataService/book/book.service";

import { potentialTransaction, validationResult } from "../base-validator";

@Injectable({
  providedIn: "root",
})
export class AccountValidator {
  constructor(public bookService: BookService) {}

  async validateAccount(item: potentialTransaction, validated: validationResult): Promise<void> {
    /** check for book-renderer existence */
    const bookExists = await this.bookService.isThereBook(item.accountId);

    /** get link to the book-renderer if exists ... Cuz that book-renderer might have been linked before */
    const bookLink = await this.bookService.getBookLink(item.accountId);

    /** check if the book-renderer has been processed before or not */
    const newAccountProcessed = validated.newAccounts.find((obj) => obj.source === item.accountId);

    if (!bookExists && !bookLink && !newAccountProcessed) {
      const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      validated.newAccounts.push({
        currencies: [item.quantity.currency],
        source: item.accountId,
        accountId: item.accountId,
        action: NewAccountActionTypes.default,
        selectedCurrency: item.quantity.currency,
        selectedTimeZone: timeZone,
        institutionAccountLink: newAccountProcessed?.institutionAccountLink,
        origin: newAccountProcessed?.origin,
      });
    } else if (bookLink) {
      const book = await this.bookService.get(bookLink.accountId);
      item.accountId = book.name;
    } else if (newAccountProcessed) {
      this.processCurrency(validated, item);
    }
  }

  processCurrency(validated: validationResult, item: potentialTransaction) {
    /** check for new currencies and add them to new book-renderer's currencies property */
    const processedAccount = validated.newAccounts.find(
      (newAccount) => newAccount.accountId === item.accountId
    );
    const currencyProcessed = processedAccount.currencies.some(
      (currency: string) => currency === item.quantity.currency
    );

    if (!currencyProcessed) {
      validated.newAccounts.map((newAccount) => {
        if (newAccount.accountId === item.accountId) {
          newAccount.currencies.push(item.quantity.currency);
        }
      });
    }
  }
}
