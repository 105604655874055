import { Transaction } from "../../models/data/blobby/transaction.data";

type ItemTypes = string | number;
type CriteriaTypes = { accountId: string; symbol: string };

export class LinkValidatorRules {
  private readonly EQUALITY_OPERATOR: string = "===";
  private readonly INEQUALITY_OPERATOR: string = "!===";
  private readonly CRITERIA: CriteriaTypes = {
    accountId: "accountId",
    symbol: "symbol",
  };

  /**
   * Validator rule for Transfer
   * Link a transactions if there are different accounts
   */
  protected isDifferentAccounts(transactions: Transaction[]) {
    return this.linkProcess(transactions, this.INEQUALITY_OPERATOR, this.CRITERIA.accountId);
  }

  /**
   * Validator rule for Transfer
   * Link a transactions if the symbols are the same
   */
  protected isSameSymbols(transactions: Transaction[]) {
    return this.linkProcess(transactions, this.EQUALITY_OPERATOR, this.CRITERIA.symbol);
  }

  /**
   * Validator rule for Transfer
   * Link a transactions if the balance is not zero
   */
  protected isNotZeroBalance(transactions: Transaction[]) {
    const balance = transactions.reduce(
      (accumulator, currentValue) => accumulator + currentValue.valuation.value.amount,
      0
    );
    return balance !== 0;
  }

  protected isNotInZeroBalanceThreshold(transactions: Transaction[]) {
    const balance = transactions.reduce(
      (accumulator, currentValue) => accumulator + currentValue.valuation.normalizedValue.amount,
      0
    );
    return !(-0.1 < balance && balance < 0.1);
  }

  protected getZeroBalanceForConversion(transactions: Transaction[]) {
    if (this.isSameSymbols(transactions)) {
      return this.isNotZeroBalance(transactions);
    } else {
      return this.isNotInZeroBalanceThreshold(transactions);
    }
  }

  /**
   * Validator rule for Conversion
   * Process the conversion if transactions have different symbols
   */
  protected isDifferentSymbols(transactions: Transaction[]) {
    return this.linkProcess(transactions, this.INEQUALITY_OPERATOR, this.CRITERIA.symbol);
  }

  private linkProcess(transactions: Transaction[], operator: string, criteria: string) {
    let i = 0;
    while (i < transactions.length) {
      const currKey = i;
      const nextKey = i + 1;

      if (!transactions[nextKey]) {
        break;
      }

      const currentItem = this.getCriteria(transactions[currKey], criteria);
      const nextItem = this.getCriteria(transactions[nextKey], criteria);
      const comparisonResult = this.compareItems(currentItem, nextItem, operator);

      /** Check all transactions for comparison and return false if expression evaluated to falsy */
      if (comparisonResult) {
        i++;
        continue;
      } else {
        return comparisonResult;
      }
    }
    return true;
  }

  /**
   * Return the transaction property as a criteria for comparison
   */
  private getCriteria(transaction: Transaction, criteria: string) {
    switch (criteria) {
      case "accountId":
        return transaction.accountId;
        break;
      case "symbol":
        return transaction.quantity.actualQuantity.symbol;
        break;
      case "balance":
        return transaction.bankImportedBalance;
        break;
    }
  }

  private compareItems(currentTrans: ItemTypes, nextTrans: ItemTypes, operator: string) {
    if (operator === this.EQUALITY_OPERATOR) {
      return currentTrans === nextTrans;
    }

    if (operator === this.INEQUALITY_OPERATOR) {
      return currentTrans !== nextTrans;
    }
  }

  isRealTransactions(transactions: Transaction[]) {
    return !transactions.some((transaction) => transaction.revalTransaction);
  }
}
