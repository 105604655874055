import { MatTableDataSource } from "@angular/material/table";

import { Row } from "../../../importers/data-mapper/mapping-engine-types";
import { ImportFlow } from "../../../importers/store/import.store";
import { Institution } from "../../../models/data/blobby/institution.data";
import { ImportStepsEnum, TransactionImportType } from "../../../models/enum/transactionImportType";
import { ProcessStatus, TableColumns } from "../../../models/types/import.types";

export interface CsvImport {
  typeName: TransactionImportType;
  institution: Institution | null;
  institutions: Institution[];
  importFlow: ImportFlow;
}

export class CsvType implements CsvImport {
  typeName = TransactionImportType.transactioncsv;
  institution: Institution = null;
  institutions: Institution[] = [];
  arrangeTableColumns: TableColumns[] = null;
  displayedColumns: string[] = null;
  materialTableDataSource: MatTableDataSource<Row> = null;
  importFlow: ImportFlow = {
    step: ImportStepsEnum.institution,
    completedSteps: [],
  };
  arrangementStatus: ProcessStatus = null;
}
