import { SupportConfigType } from "@bitwarden/web-vault/app/models/types/environement-config.type";

const supportConfig = process.env.SUPPORT as unknown as SupportConfigType;

export class SupportConfigData {
  private readonly __v = 1;
  config: Record<string, any>;

  private _url: string;
  private _secret: string;

  constructor() {
    this.config = supportConfig;
    this.url = `${supportConfig.url}/${supportConfig.apiStage}`;
  }

  get url(): string {
    return this._url;
  }

  set url(value: string) {
    this._url = value;
  }
}
