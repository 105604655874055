import { Injectable } from "@angular/core";

import { GlobalService } from "@bitwarden/common/services/global/global.service";
import { AccountView } from "@bitwarden/web-vault/app/models/view/account.view";

import { BasiqLocalStorage } from "../models/enum/basiq.enum";
import {
  BasiqConnection,
  BasiqConsent,
  BasiqJob,
  BasiqJobStep,
  GlossBasiqTokenResponse,
  GlossConnectionRefreshResponse,
  GlossGetBasiqJobResponse,
} from "../models/types/basiq.types";
import { DataRepositoryService } from "../services/DataRepository/data-repository.service";
import { BasiqIoConfigService } from "../services/api/basiqio-config.service";

@Injectable({
  providedIn: "root",
})
export class BasiqAuth {
  private _hasBasiqUser: boolean;

  constructor(
    private basiqConfigService: BasiqIoConfigService,
    private dataRepositoryService: DataRepositoryService,
    private globalService: GlobalService
  ) {}

  async checkBasiqUser() {
    const result = await this.basiqConfigService.getBasiqUser();
    this._hasBasiqUser = result && !!result.user && !!result.user?.id;
  }

  get hasBasiqUser() {
    return this._hasBasiqUser;
  }

  private setWithExpiry(key: string, basiqTokenObject: GlossBasiqTokenResponse) {
    const value = JSON.stringify(basiqTokenObject);
    const expirationTimeInSeconds = basiqTokenObject.token.expires_in;
    const now = new Date();
    const item = {
      value: value,
      expiry: now.getTime() + expirationTimeInSeconds * 1000, // Convert minutes to milliseconds
    };
    sessionStorage.setItem(key, JSON.stringify(item));
  }

  getTokenFromLocalStorage(name: string): GlossBasiqTokenResponse {
    const storedValue = JSON.parse(sessionStorage.getItem(name));
    const expiryTime = storedValue ? storedValue.expiry : 0;
    const nowTime = new Date().getTime();
    if (nowTime >= expiryTime) {
      return null;
    } else {
      return JSON.parse(storedValue.value);
    }
  }

  async getBasiqUser() {
    if (!this.hasBasiqUser) {
      await this.createBasiqUser();
    }

    return this._hasBasiqUser;
  }

  /*  async isBasiqUser(): Promise<boolean> {
    if (this._isBasiqUser) {
      return true;
    } else {
      // TODO - make an api call to gloss-api to check if the user is a basiq user or not
      const preference = await this.getPreference();
      if (preference.basiqUser && preference.basiqUser.id) {
        this._isBasiqUser = preference.basiqUser;
        return true;
      }
    }
    return false;
  }*/

  private async createBasiqUser() {
    const basiqUser = await this.basiqConfigService.createBasiqIoUser();
    if (basiqUser && basiqUser.isUserCreated) {
      this._hasBasiqUser = true;
    }
  }

  private async createUserToken() {
    if (this.hasBasiqUser) {
      const glossBasiqTokenResponse = await this.basiqConfigService.createClientAccessToken();
      if (glossBasiqTokenResponse && glossBasiqTokenResponse.token) {
        this.saveUserTokenToLocalStorage(glossBasiqTokenResponse);
        return glossBasiqTokenResponse;
      }
    } else {
      await this.createBasiqUser();
      await this.createUserToken();
    }
  }

  private getUserTokenFromLocalStorage(): GlossBasiqTokenResponse {
    return this.getTokenFromLocalStorage(BasiqLocalStorage.basiqUserToken);
  }

  private saveUserTokenToLocalStorage(basiqToken: GlossBasiqTokenResponse) {
    this.setWithExpiry(BasiqLocalStorage.basiqUserToken, basiqToken);
  }

  async getUserToken() {
    if (this.hasUserToken()) {
      return this.getUserTokenFromLocalStorage();
    } else {
      return await this.createUserToken();
    }
  }

  hasUserToken(): boolean {
    const apiToken = this.getUserTokenFromLocalStorage();
    return !!apiToken;
  }

  async authenticate() {
    const basiqUser = await this.getBasiqUser();
    const userToken = await this.getUserToken();
    await this.getUserConsent();

    return !!basiqUser && !!userToken;
  }

  private async getUserConsent() {
    /** What if user has canceled at the stage of giving consent? That is what this check is for */
    if (!(await this.isUserConsent())) {
      await this.requestBasiqConsent();
    }
  }

  async isUserConsent(): Promise<boolean> {
    const userActiveConsent = await this.getActiveUserConsent();
    return !!userActiveConsent;
  }

  async requestBasiqConsent() {
    if (await this.isAuthenticated()) {
      const userToken = this.getUserTokenFromLocalStorage();
      window.location.href = `https://consent.basiq.io/home?token=${userToken.token.access_token}`;
    } else {
      await this.recurseIfAuthenticate(this.requestBasiqConsent.bind(this));
    }
  }

  private async isAuthenticated() {
    const hasUserToken = this.hasUserToken();

    return this.hasBasiqUser && hasUserToken;
  }

  private async isConnectionOpen(): Promise<boolean> {
    if (await this.isAuthenticated()) {
      try {
        const connectionsResponse = await this.getConnectionResponse();
        return !!connectionsResponse.connections.length;
      } catch (e) {
        return false;
      }
    } else {
      return await this.recurseIfAuthenticate(this.isConnectionOpen.bind(this));
    }
  }

  async getConnectionResponse() {
    return await this.basiqConfigService.getConnections();
  }

  async recurseIfAuthenticate(method: CallableFunction) {
    const isAuthenticated = await this.authenticate();
    if (isAuthenticated) {
      return await method();
    } else {
      this.showAuthenticationErrorMessage();
    }
  }

  private showAuthenticationErrorMessage() {
    this.globalService.showWarningMessage("warning", "authenticationErrorPleaseTryLater");
  }

  private async getActiveUserConsent(): Promise<BasiqConsent> {
    const glossConsentResponse = await this.basiqConfigService.getConsent();
    if (glossConsentResponse && glossConsentResponse.consents.data?.length) {
      return glossConsentResponse.consents.data.find((consent) => consent.status === "active");
    }

    return null;
  }

  /*  async isConnectionRefreshed(): Promise<boolean> {
    const refreshingJobIds: string[] = [];
    const fresh = true;
    const connectionResponse = await this.getConnectionResponse();

    for (const connection of connectionResponse.data) {
      if (this.shouldConnectionRefresh(connection)) {
        const refreshmentJob = await this.refreshConnection(connection);
        if (refreshmentJob?.id) {
          refreshingJobIds.push(refreshmentJob.id);
        }
      }
    }
    if (refreshingJobIds.length) {
      return await this.resultOfJobs(refreshingJobIds);
    } else {
      return fresh;
    }
  }*/

  async refreshConnection(connection: BasiqConnection): Promise<GlossConnectionRefreshResponse> {
    return this.basiqConfigService.refreshConnection(connection);
  }

  async resultOfJobs(refreshingJobIds: string[]): Promise<boolean> {
    const jobsAvailable = await this.areJobsResolved(refreshingJobIds.join(","));
    if (jobsAvailable) {
      return true;
    } else {
      await this.delay(30000);
      // Call the method recursively until jobsAvailable is true
      return this.resultOfJobs(refreshingJobIds);
    }
  }

  delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async areJobsResolved(jobIds: string | BasiqJob[]): Promise<boolean> {
    let properJobIds: string = jobIds as string;
    if (typeof jobIds !== "string") {
      properJobIds = jobIds.map((job: BasiqJob) => job.id).join(",");
    }
    const jobs = await this.getBasiqJobs(properJobIds);

    for (const job of jobs) {
      const failed = job.steps.some((step: BasiqJobStep) => step.status === "failed");
      if (failed) {
        continue;
      }

      const notCompleted = job.steps.some(
        (step: BasiqJobStep) => step.status === "pending" || step.status === "in-progress"
      );
      if (notCompleted) {
        await this.delay(5000);
        return this.areJobsResolved(jobIds);
      }
    }
    return true;
  }

  async extendConsent() {
    if (await this.isAuthenticated()) {
      if (await this.isUserConsent()) {
        const userToken = await this.getUserToken();
        window.location.href = `https://consent.basiq.io/home?&token=${userToken.token.access_token}&action=extend&state=toImport`;
      } else {
        const confirm = await this.globalService.showDialogue(
          "wouldYouLikeToMakeAFirstConsent",
          "info"
        );
        if (confirm) {
          const isConnectionOpen = await this.isConnectionOpen();
          if (isConnectionOpen) {
            await this.connectToAnotherInstitution();
          }
        }
      }
    } else {
      await this.recurseIfAuthenticate(this.extendConsent.bind(this));
    }
  }

  async updateConsent() {
    if (await this.isAuthenticated()) {
      const userToken = await this.getUserToken();
      const isConnectionOpen = await this.isConnectionOpen();
      if (isConnectionOpen) {
        window.location.href = `https://consent.basiq.io/home?&token=${userToken.token.access_token}&action=update&state=toImport`;
      }
    } else {
      await this.recurseIfAuthenticate(this.updateConsent.bind(this));
    }
  }

  async manageConsent() {
    if (await this.isAuthenticated()) {
      if (await this.isUserConsent()) {
        const userToken = await this.getUserToken();
        const isConnectionOpen = await this.isConnectionOpen();
        if (isConnectionOpen) {
          window.location.href = `https://consent.basiq.io/home?&token=${userToken.token.access_token}&action=manage&state=toImport`;
        }
      } else {
        const confirm = await this.globalService.showDialogue(
          "wouldYouLikeToMakeAFirstConsent",
          "info"
        );
        if (confirm) {
          const isConnectionOpen = await this.isConnectionOpen();
          if (isConnectionOpen) {
            await this.connectToAnotherInstitution();
          }
        }
      }
    } else {
      await this.recurseIfAuthenticate(this.manageConsent.bind(this));
    }
  }

  async connectToAnotherInstitution() {
    if (await this.isAuthenticated()) {
      const userToken = await this.getUserToken();
      const isConnectionOpen = await this.isConnectionOpen();
      if (isConnectionOpen) {
        window.location.href = `https://consent.basiq.io/home?&token=${userToken.token.access_token}&action=connect`;
      }
    } else {
      await this.recurseIfAuthenticate(this.connectToAnotherInstitution.bind(this));
    }
  }

  async connectToInstitution(institutionId: string) {
    if (await this.isAuthenticated()) {
      const userToken = await this.getUserToken();
      const isConnectionOpen = await this.isConnectionOpen();
      if (isConnectionOpen) {
        /*https://consent.basiq.io/home?token=${token}&action=${action}&state={yourState}&institutionId={institutionId}*/
        window.location.href = `https://consent.basiq.io/home?&token=${userToken.token.access_token}&action=connect&state=sync&institutionId=${institutionId}`;
      }
    } else {
      await this.recurseIfAuthenticate(this.connectToAnotherInstitution.bind(this));
    }
  }

  async isThereConnection() {
    const connectionsResponse = await this.getConnectionResponse();
    return !!connectionsResponse.connections?.length;
  }

  //TODO make sure this return only one connection
  async getConnection(accountView: AccountView): Promise<BasiqConnection> {
    const connectionResponse = await this.basiqConfigService.getAccountConnections(accountView);
    return connectionResponse.connections.length > 0 ? connectionResponse.connections[0] : null;
  }

  async getBasiqJobs(jobIds: string): Promise<BasiqJob[]> {
    const jobIdArr = jobIds.split(",");
    const jobs: BasiqJob[] = [];
    for (const jobId of jobIdArr) {
      const glossGetBasiqJobResponse = await this.getBasiqJob(jobId);
      if (glossGetBasiqJobResponse?.job) {
        jobs.push(glossGetBasiqJobResponse.job);
      }
    }

    return jobs;
  }

  async getBasiqJob(jobId: string): Promise<GlossGetBasiqJobResponse> {
    return await this.basiqConfigService.getJob(jobId);
  }
}
