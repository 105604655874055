import { MatTableDataSource } from "@angular/material/table";

import { Row } from "../../../importers/data-mapper/mapping-engine-types";
import { ImportFlow } from "../../../importers/store/import.store";
import { Institution } from "../../../models/data/blobby/institution.data";
import { TransactionImportType } from "../../../models/enum/transactionImportType";
import { ProcessStatus, TableColumns } from "../../../models/types/import.types";

export interface BasiqImport {
  typeName: TransactionImportType;
  institution: Institution | null;
}

export class BasiqType implements BasiqImport {
  typeName = TransactionImportType.basiq;
  institution: Institution = null;
  institutions?: Institution[];
  importFlow?: ImportFlow = null;
  arrangeTableColumns?: TableColumns[] = null;
  displayedColumns: string[] = null;
  materialTableDataSource: MatTableDataSource<Row> = null;
  arrangementStatus?: ProcessStatus = null;
}
