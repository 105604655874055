export enum EstimateType {
  Transaction = "transaction",
  Interest = "interest",
  Revaluation = "revaluation",
}

export type EstimatePeriod = {
  daily: "Daily";
  weekly: "Weekly";
  monthly: "Monthly";
  yearly: "Yearly";
};

export interface EstimateActionKeyNamePair {
  name: string;
  key: string;
}

export type MonthlyOption = {
  dateSpecific: { date: number };
  degreeSpecific: {
    lastDayOfMonth: boolean;
    day: 0 | 1 | 2 | 3 | 4 | 5 | 6;
    degree: 1 | 2 | 3 | 4 | "last";
  };
};
export type YearlyOption = {
  month: MonthOfYear;
  dateSpecific: { date: number };
  degreeSpecific: {
    lastDayOfMonth: boolean;
    day: 0 | 1 | 2 | 3 | 4 | 5 | 6;
    degree: 1 | 2 | 3 | 4 | "last";
  };
};
export type WeeklyOption = 0 | 1 | 2 | 3 | 4 | 5 | 6;
export type DayOfMonth =
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12
  | 13
  | 14
  | 15
  | 16
  | 17
  | 18
  | 19
  | 20
  | 21
  | 22
  | 23
  | 24
  | 25
  | 26
  | 27
  | 28
  | 29
  | 30
  | 31;
export type MonthOfYear = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11;

/** combines User estimate actions with estimate actions generally so user estimate will know which action to have on the fly based on the key */
/*
export type UserEstimateActions = {
  Interest : {
    name: UserEstimateActionsEnum.interest,
    key:EstimateActionsEnum.interest
  },

  TransactionTemplate : {
    name: UserEstimateActionsEnum.template,
    key:EstimateActionsEnum.transactionTemplate
  }
}
*/
