import { Component, Injector, OnDestroy, OnInit } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Subject, takeUntil } from "rxjs";

import { GlobalService } from "@bitwarden/common/services/global/global.service";
import { CategoriesAddEditComponent } from "@bitwarden/web-vault/app/gloss/settings/manage-categories/categories-add-edit/categories-add-edit.component";
import { Category } from "@bitwarden/web-vault/app/models/data/blobby/category.data";
import { CategoryService } from "@bitwarden/web-vault/app/services/DataService/category/category.service";
import { HelperCategory } from "@bitwarden/web-vault/app/shared/utils/helper.category";

@Component({
  selector: "app-manage-categories",
  templateUrl: "./manage-categories.component.html",
})
export class ManageCategoriesComponent extends HelperCategory implements OnInit, OnDestroy {
  existingCategories: Category[];
  loading = false;
  private destroy$: Subject<boolean> = new Subject<boolean>();
  private dialogueRef: MatDialogRef<CategoriesAddEditComponent>;
  constructor(
    public dialog: MatDialog,
    private categoryServices: CategoryService,
    private globalService: GlobalService,
    private injector: Injector
  ) {
    super(injector);
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
  }

  async ngOnInit(): Promise<void> {
    this.existingCategories = await this.categoryServices.getAll();
  }

  invite() {
    this.edit(null);
  }

  async edit(category: Category) {
    if (category?.generalDefault) {
      this.globalService.showWarningMessage("warning", "canNotActOnDefaultCategory");
      return;
    }
    const dialogRef = this.dialog.open(CategoriesAddEditComponent, {
      width: "800px",
      data: {
        delete: this.delete.bind(this, category),
        actionSucceeded: this.actionSucceeded.bind(this),
        category: category,
      },
    });

    this.dialogueRef = dialogRef;

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe((newCategories) => {
        if (newCategories) {
          this.existingCategories = newCategories;
        }
      });
  }

  async delete(category: Category) {
    if (category.generalDefault) {
      this.globalService.showWarningMessage("warning", "canNotActOnDefaultCategory");
      return;
    }
    const confirmed = await this.globalService.showRemoveDialogue();
    if (confirmed) {
      this.loading = true;
      try {
        const deleted = await this.categoryServices.delete(category, false);
        if (deleted) {
          /** Update the Transaction category-renderer to default for the deleted category-renderer */
          await this.updateTransactionCategoryToDefault(category);
          await this.actionSucceeded("deletedSuccessfully");
        }
      } catch (e) {
        this.globalService.showErrorMessage("errorOccurred", e);
      } finally {
        this.loading = false;
      }
    }
  }

  async actionSucceeded(actionKey: string) {
    this.existingCategories = await this.categoryServices.getAll();
    this.dialogueRef?.close(this.existingCategories);
    this.globalService.showSuccessMessage("succeeded", actionKey);
  }
}
