import { Injectable } from "@angular/core";
import { saveAs } from "file-saver";

import { Utils } from "@bitwarden/common/misc/utils";
import { EncArrayBuffer } from "@bitwarden/common/models/domain/enc-array-buffer";
import { SymmetricCryptoKey } from "@bitwarden/common/models/domain/symmetric-crypto-key";
import { GlobalService } from "@bitwarden/common/services/global/global.service";
import { VaultFileView } from "@bitwarden/web-vault/app/models/view/vault-file.view";
import { DataRepositoryService } from "@bitwarden/web-vault/app/services/DataRepository/data-repository.service";
import { FileEncryptionService } from "@bitwarden/web-vault/app/services/DataService/file-upload/file-encryption.service";
import { GlossApiProxyRequest } from "@bitwarden/web-vault/app/shared/utils/helper.glos-api-call/gloss-api-proxy-request";

import { VaultFile } from "../../../models/data/blobby/vault-file.data";
import { VaultFileResponse } from "../../../models/data/response/vault-file.response";
import {
  CabinetFileUploadedData,
  VaultFileValidationType,
} from "../../../models/types/cabinet.types";

import { VaultFileService } from "./vault-file.service";

const MAXIMUM_FILE_SIZE = 1 * 1024 * 1024; // 1MB 1*1024*1024

@Injectable({
  providedIn: "root",
})
export class CabinetComponentService {
  constructor(
    private vaultFileService: VaultFileService,
    private globalService: GlobalService,
    private dataRepositoryService: DataRepositoryService,
    private fileEncryption: FileEncryptionService
  ) {}

  async getAllVaultFileViews(): Promise<VaultFileView[]> {
    const vaultFiles = await this.vaultFileService.getAll();
    const vaultFileViews: VaultFileView[] = [];
    for (const vaultFile of vaultFiles) {
      const fileAccounts = await this.vaultFileService.getFileAccounts(vaultFile);
      const institution = await this.vaultFileService.getFileInstitutions(vaultFile);
      vaultFileViews.push(new VaultFileView(vaultFile, fileAccounts, institution));
    }

    return vaultFileViews;
  }

  displayCatchError(error: any) {
    this.globalService.showErrorMessage("errorOccurred", "fileUploadFailed");
    // eslint-disable-next-line no-console
    console.error(error);
  }

  async saveFile(data: CabinetFileUploadedData) {
    const fileValidation = this.validateFile(data);
    if (!fileValidation.isValid) {
      this.globalService.showWarningMessage("warning", fileValidation.messageKey);
      return false;
    }

    let isSaved = false;
    await this.fileEncryption.encrypt(data.file);

    const newlySavedVaultFile = await this.saveFileToBlobby(data);
    if (newlySavedVaultFile) {
      const serverResponse = await this.saveContentToServer(newlySavedVaultFile, data.file);
      isSaved = !!serverResponse;
    }

    if (isSaved) {
      this.globalService.showSuccessMessage("success", "fileUploadedSuccessfully");
    } else {
      this.globalService.showErrorMessage("errorOccurred", "fileUploadFailed");
    }

    return isSaved;
  }

  private async saveFileToBlobby(data: CabinetFileUploadedData) {
    try {
      const fileValidation = this.validateFile(data);
      if (!fileValidation.isValid) {
        this.globalService.showWarningMessage("warning", fileValidation.messageKey);
        return;
      }

      const newVaultFile = this.generateVaultFile(data);
      return await this.vaultFileService.create(newVaultFile);
    } catch (error) {
      this.globalService.showErrorMessage("errorOccurred", "fileUploadFailed");
    }
  }

  validateFile(data: CabinetFileUploadedData): VaultFileValidationType {
    if (!data.file) {
      return { isValid: false, messageKey: "emptyFileErrorMessage" };
    }

    if (!data.name) {
      return { isValid: false, messageKey: "fileUploadNameRequired" };
    }
    if (!data.mimeType) {
      return { isValid: false, messageKey: "fileTypeNotSupported" };
    }
    if (!data.statementToDate) {
      return { isValid: false, messageKey: "fileUploadStatementToDateRequired" };
    }
    if (!data.statementFromDate) {
      return { isValid: false, messageKey: "fileUploadStatementFromDateRequired" };
    }
    if (!data.statementAccounts || data.statementAccounts.length === 0) {
      return { isValid: false, messageKey: "fileUploadStatementAccountsRequired" };
    }

    return { isValid: true, messageKey: "" };
  }

  validateFileSize(file: File) {
    if (file.size > MAXIMUM_FILE_SIZE) {
      this.globalService.showErrorMessage("errorOccurred", "fileSizeExceedsLimit");
      return false;
    }
    return true;
  }

  private generateVaultFile(data: CabinetFileUploadedData): VaultFile {
    const newVaultFileObject = {
      name: data.name,
      mimeType: data.mimeType,
      statementToDate: data.statementToDate,
      statementFromDate: data.statementFromDate,
      encryptedKey: this.fileEncryption.getEncryptedKey(),
      encryptedFile: this.fileEncryption.getEncryptedFile(),
      statementAccounts: data.statementAccounts,
      dateCreated: new Date().toISOString(),
    };
    return new VaultFile(new VaultFileResponse(newVaultFileObject));
  }

  private async saveContentToServer(vaultFile: VaultFile, file: File) {
    if (file) {
      const encArrayBuffer = this.fileEncryption.getEncryptedFile();

      const bufferText = Utils.fromBufferToB64(encArrayBuffer.buffer);
      const encryptedBlob = new Blob([bufferText], { type: "text/plain" });
      const encryptedFile = new File([encryptedBlob], vaultFile.id, { type: "text/plain" });

      const formData = new FormData();
      formData.append("file", encryptedFile);

      const glossRequestObject = new GlossApiProxyRequest({ endpoint: "uploadFile", formData });
      return await this.dataRepositoryService.send(
        glossRequestObject.method,
        glossRequestObject.path,
        glossRequestObject.data,
        glossRequestObject.authed,
        glossRequestObject.hasResponse,
        glossRequestObject.url,
        glossRequestObject.alterHeaders
      );
    }
    return true;
  }

  async downloadFile(fileId: string) {
    const vaultFile = await this.vaultFileService.get(fileId);
    const encryptedKey = vaultFile.encryptedKey;
    const symCryptkey = SymmetricCryptoKey.fromJSON(encryptedKey);
    const symmetricCryptoKey = new SymmetricCryptoKey(symCryptkey.key);

    /** Endpoint to request a encrypted file */
    const glossRequestObject = new GlossApiProxyRequest({ endpoint: "downloadFile", fileId });

    const response = await this.dataRepositoryService.send(
      glossRequestObject.method,
      glossRequestObject.path,
      glossRequestObject.data,
      glossRequestObject.authed,
      glossRequestObject.hasResponse,
      glossRequestObject.url,
      glossRequestObject.alterHeaders,
      true
    );

    const encB64Text = await response.text();
    const encText = Utils.fromB64ToArray(encB64Text).buffer;
    const encArrayBuffer = new EncArrayBuffer(encText);
    const decryptedFile = await this.fileEncryption.decrypt(encArrayBuffer, symmetricCryptoKey);

    saveAs(decryptedFile, vaultFile.name);
  }

  async deleteFile(vaultFile: VaultFile): Promise<VaultFileView[]> {
    const data = { endpoint: "deleteFile", fileId: vaultFile.id };
    const glossRequestObject = new GlossApiProxyRequest(data);
    const response = await this.dataRepositoryService.send(
      glossRequestObject.method,
      glossRequestObject.path,
      glossRequestObject.data,
      glossRequestObject.authed,
      glossRequestObject.hasResponse,
      glossRequestObject.url,
      glossRequestObject.alterHeaders
    );
    if (response) {
      await this.vaultFileService.delete(vaultFile);
      this.globalService.showSuccessMessage("success", "fileDeletedSuccessfully");
    }

    return await this.getAllVaultFileViews();
  }
}
