<div class="autocomplete-container">
  <label class="autocomplete-label">{{ options.label }}</label>
  <mat-form-field appearance="fill" class="autocomplete-form-field" floatLabel="always">
    <input
      matInput
      class="autocomplete-input"
      [placeholder]="options.placeholder"
      type="text"
      [formControl]="inputValueControl"
      (blur)="onInputBlur()"
      (keyup.enter)="onInputBlur()"
      (input)="onInput($event)"
      [disabled]="options.isDisabled"
    />
    <span matSuffix *ngIf="options.suffix">{{ options.suffix }}</span>
    <mat-icon
      *ngIf="!options.isDisabled && inputValue"
      class="icon clear-icon"
      matSuffix
      (click)="clearInput()"
      >clear</mat-icon
    >
    <mat-error class="error-inline input-error" *ngIf="inputValueControl.hasError('required')">{{
      "inputRequired" | i18n
    }}</mat-error>
  </mat-form-field>
</div>
