import { TimezoneFormatIndex } from "../../../models/types/general-types";

export type TZAtomRegExType = {
  matchedFormat: string;
  regex: string;
  tzPart: keyof TimezoneFormatIndex;
};
export const tzRegexZ: TZAtomRegExType = {
  matchedFormat: "X",
  regex: "(?<timezone>Z)",
  tzPart: "timezoneName",
};

export const tzRegexZZZZ: TZAtomRegExType = {
  matchedFormat: "XXXX",
  regex: "(?<timezone>[A-Z][a-z]+/[A-Z][a-z]+)",
  tzPart: "timezoneName",
};

export const tzRegexOOOO: TZAtomRegExType = {
  matchedFormat: "OOOO",
  regex: "(?<timezone>GMT[+-]([01]\\d|\\d):([0-5]\\d)|GMT\\+00:00)",
  tzPart: "timezoneName",
};
export const tzRegexxxx: TZAtomRegExType = {
  matchedFormat: "xxx",
  regex: "(?<timezoneOffset>[+-]([01]\\d|\\d):([0-5]\\d)|\\+00:00)",
  tzPart: "timezoneOffset",
};
