import { Component, DoCheck, Injector } from "@angular/core";
import { Router } from "@angular/router";
import _ from "lodash";
import { BehaviorSubject, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { glossRouteInfo } from "@bitwarden/web-vault/app/layouts/sections-info-texts";
import { RoleAccessData } from "@bitwarden/web-vault/app/models/data/role-access.data";
import { GlossRouteEnum } from "@bitwarden/web-vault/app/models/enum/navigation.enum";
import { GranularityProperty } from "@bitwarden/web-vault/app/models/types/balanceGroupingTypes";
import { DashboardService } from "@bitwarden/web-vault/app/services/dashboard/dashboard-service";
import { RoleAccessService } from "@bitwarden/web-vault/app/services/permission/role-access.service";

import "../../../../src/scss/new-theme.scss";

@Component({
  selector: "app-dashboard-selector",
  templateUrl: "./dashboard-selector.component.html",
  styles: ["new-theme.scss"],
})
export class DashboardSelectorComponent implements DoCheck {
  private destroy$ = new Subject<void>();
  private roleAccessService: RoleAccessService;
  private roleAccessData: RoleAccessData;
  private dashboardViewsSubject = new BehaviorSubject<any>([]);

  dashboardViews: Record<string, any> = [];
  dashboardTypes: Array<any>;
  dashboardID: string;
  dashboardService: DashboardService;
  showSpinner = false;
  tooltipInfo: string;
  showHelpTooltip = false;
  xPosition: number;
  yPosition: number;
  pointer: string;
  isInfoOpen = false;
  isClicked = false;

  constructor(private injector: Injector) {
    this.dashboardService = this.injector.get(DashboardService);
    this.roleAccessService = this.injector.get(RoleAccessService);
    this.subscribeToDashboardObservables();
    this.dashboardID = this.dashboardService.dashboardID;
  }

  ngDoCheck() {
    if (!this.roleAccessData) {
      this.roleAccessData = this.roleAccessService.getRoleAccess();
      this.dashboardViewsSubject.next(this.roleAccessData);
    }

    if (!_.isEmpty(this.roleAccessData) && _.isEmpty(this.dashboardViews)) {
      const glossViews = this.roleAccessData.getClaim().gloss_views;
      _.each(dashboards, (dashboard, key) => {
        if (glossViews.includes(dashboard.name)) {
          this.dashboardViews[key] = dashboard;
        }
      });

      this.dashboardTypes = this.createDashboardOptions();
    }

    if (this.dashboardID !== this.dashboardService.dashboardID) {
      this.dashboardID = this.dashboardService.dashboardID;
    }
  }

  async updateDashboardSelection(event: string) {
    this.handleSpinner(true);
    this.dashboardID = event;
    setTimeout(async () => {
      await this.dashboardService.updateDashboardID(event);
      this.handleSpinner(false);
      const router = this.injector.get(Router);
      router.navigate(["/primary-dashboard"]);
    }, 250);
  }

  createDashboardOptions() {
    const dashboardOptions = [];

    for (const dashboardID in dashboards) {
      const dashType = dashboards[dashboardID].type;
      dashboardOptions.push({
        dashboardID: dashboardID,
        display: DashboardTypes[dashType],
      });
    }

    return dashboardOptions;
  }

  async openDashboardHelp() {
    this.getToolTipInfo();
    this.showHelpTooltip = true;
    this.pointer = "modal";
    this.isInfoOpen = true;
  }

  getToolTipInfo() {
    this.tooltipInfo = "";
    const helpInfo = glossRouteInfo[GlossRouteEnum.dashboard];

    if (typeof helpInfo === "object") {
      if (helpInfo?.overview) {
        for (const dashboard of helpInfo.overview) {
          if (typeof dashboard === "object") {
            let divClass = "dashboard-help";
            if (dashboard?.type === dashboards[this.dashboardID].type) {
              divClass += " selected-dashboard";
            }
            this.tooltipInfo +=
              `<div class='${divClass}'>` +
              `<div class='help-heading'>${dashboard?.title}</div>` +
              `${dashboard?.text}</div>`;
          }
        }
      }
    }
  }

  onTooltipClose() {
    this.showHelpTooltip = false;
    this.isInfoOpen = false;
    this.isClicked = !this.isClicked;
  }

  toggleClick() {
    this.isClicked = !this.isClicked;
  }

  private subscribeToDashboardObservables() {
    this.dashboardService.dashboardID$.pipe(takeUntil(this.destroy$)).subscribe((dashboardID) => {
      this.dashboardID = dashboardID;
    });
  }

  private handleSpinner(spinnerValue: boolean) {
    this.showSpinner = spinnerValue;
  }

  ngOnDestroy() {
    this.showHelpTooltip = false;
    this.showSpinner = false;
    this.destroy$.next();
    this.destroy$.complete();
  }

  protected readonly open = open;
}

export const DashboardTypes = {
  transactionOnly: "Summary View",
  scenarioOnly: "Forecast View",
  transactionAndScenario: "Combined View",
};

export type DashboardTypeProperties = typeof DashboardTypes;
export type DashboardType = keyof DashboardTypeProperties; // accountCreation | transactionTransfer

export const transactionOnly = "transactionOnly" as DashboardType;
export const scenarioOnly = "scenarioOnly" as DashboardType;
export const transactionAndScenario = "transactionAndScenario" as DashboardType;

export type FilterType = {
  accounts?: Array<string>;
  startDate?: Date;
  endDate?: Date;
  granularity: GranularityProperty;
};

export type DashboardParameters = {
  type: DashboardType;
  scenario: boolean;
  transaction: boolean;
  filters?: FilterType;
  name?: string;
};

// eventually turn this into a data object with index to dashboard parameters
export type Dashboards = Record<string, DashboardParameters>;

export const dashboards: Dashboards = {
  "1": { type: transactionOnly, scenario: false, transaction: true, name: "summary" },
  "2": { type: scenarioOnly, scenario: true, transaction: false, name: "scenario" },
  "3": { type: transactionAndScenario, scenario: true, transaction: true, name: "combined" },
};
