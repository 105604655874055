import { Injectable } from "@angular/core";
import { ComponentStore } from "@ngrx/component-store";
import { Observable } from "rxjs";

import { GlobalService } from "@bitwarden/common/services/global/global.service";
import { MappingEngine } from "@bitwarden/web-vault/app/importers/data-mapper/mapping-engine";
import { ErrorType, TableColumns } from "@bitwarden/web-vault/app/models/types/import.types";

import { Institution } from "../../models/data/blobby/institution.data";
import { DateFormatIndex } from "../../models/types/general-types";
import { InstitutionService } from "../../services/DataService/institution/institution.service";
import HelperCsvImporter from "../../shared/utils/helper-csv-importer";
import { MappingConfigurationItem, Row } from "../data-mapper/mapping-engine-types";

export interface ImportUploadInterface {
  file: File;
  fileList: FileList;
  fileContent: string;
  fileHasOwnCurrency: boolean;
  fileRows: Row[];
  userDateFormatIndex: DateFormatIndex;
  possibleDateFormats: string[];
  canProceed: boolean;
  error: ErrorType;
  arrangeTableColumns: TableColumns[];
  headersMapping: MappingConfigurationItem[];
  mappingEngine: MappingEngine;
  goWithDefaultMapper: boolean;
  setInstitutionMapper: boolean;
}

const initialUploadState: ImportUploadInterface = {
  file: null,
  fileList: null,
  fileContent: null,
  fileHasOwnCurrency: null,
  fileRows: null,
  userDateFormatIndex: null,
  possibleDateFormats: null,
  canProceed: false,
  error: null,
  arrangeTableColumns: [],
  headersMapping: [],
  mappingEngine: null,
  goWithDefaultMapper: false,
  setInstitutionMapper: false,
};
@Injectable()
export class UploadStore extends ComponentStore<ImportUploadInterface> {
  constructor(
    private institutionService: InstitutionService,
    private globalService: GlobalService
  ) {
    super(initialUploadState);
  }
  readonly file$: Observable<File> = this.select((state) => state.file);
  readonly fileList$: Observable<FileList> = this.select((state) => state.fileList);
  readonly fileContent$: Observable<string> = this.select((state) => state.fileContent);
  readonly fileRows$: Observable<Row[]> = this.select((state) => state.fileRows);
  readonly fileHasOwnCurrency$: Observable<boolean> = this.select(
    (state) => state.fileHasOwnCurrency
  );
  readonly userDateFormatIndex$: Observable<DateFormatIndex> = this.select(
    (state) => state.userDateFormatIndex
  );
  readonly possibleDateFormats$: Observable<string[]> = this.select(
    (state) => state.possibleDateFormats
  );
  readonly canProceed$: Observable<boolean> = this.select((state) => state.canProceed);
  readonly arrangeTableColumns$: Observable<TableColumns[]> = this.select(
    (state) => state.arrangeTableColumns
  );
  readonly mappingEngine$: Observable<MappingEngine> = this.select((state) => state.mappingEngine);
  readonly headersMapping$: Observable<MappingConfigurationItem[]> = this.select(
    (state) => state.headersMapping
  );

  readonly goWithDefaultMapper$: Observable<boolean> = this.select(
    (state) => state.goWithDefaultMapper
  );

  readonly setInstitutionMapper$: Observable<boolean> = this.select(
    (state) => state.setInstitutionMapper
  );
  readonly error$: Observable<ErrorType> = this.select((state) => {
    const error = state.error;
    if (error) {
      this.globalService.showErrorMessage(error.titleKey, error.textKey);
    }
    return state.error;
  });

  readonly vmUpload$ = this.select(
    this.file$,
    this.fileList$,
    this.fileContent$,
    this.fileRows$,
    this.fileHasOwnCurrency$,
    this.userDateFormatIndex$,
    this.possibleDateFormats$,
    this.canProceed$,
    this.arrangeTableColumns$,
    this.headersMapping$,
    this.mappingEngine$,
    this.error$,
    this.goWithDefaultMapper$,
    this.setInstitutionMapper$,
    (
      file,
      fileList,
      fileContent,
      fileRows,
      fileHasOwnCurrency,
      userDateFormatIndex,
      possibleDateFormats,
      canProceed,
      arrangeTableColumns,
      headersMapping,
      mappingEngine,
      error,
      goWithDefaultMapper,
      setInstitutionMapper
    ) => ({
      file,
      fileList,
      fileContent,
      fileRows,
      fileHasOwnCurrency,
      userDateFormatIndex,
      possibleDateFormats,
      canProceed,
      arrangeTableColumns,
      headersMapping,
      mappingEngine,
      error,
      goWithDefaultMapper,
      setInstitutionMapper,
    })
  );

  readonly setFileList = this.updater((state, fileList: FileList) => {
    return {
      ...state,
      fileList,
      file: fileList[0],
      error: null,
      canProceed: true,
    };
  });

  async fileSelected(selectedInstitution: Institution, fileList: FileList) {
    if (fileList === null || fileList.length === 0) {
      this.setError({
        titleKey: "errorOccurred",
        textKey: "selectFile",
      });
      return;
    }

    if (!fileList[0].name.endsWith(".csv")) {
      this.setError({
        titleKey: "errorOccurred",
        textKey: "pleaseSelectACsvFile",
      });
      return;
    }

    const content = await this.getFileContent(fileList);
    this.setFileList(fileList);
    await this.setImportDataBeforeArrangement(selectedInstitution, content);
  }

  readonly setFile = this.updater((state, file: File) => ({
    ...state,
    file,
  }));

  readonly setFileContent = this.updater((state, fileContent: string) => ({
    ...state,
    fileContent,
  }));

  readonly reset = this.updater((state) => ({
    ...initialUploadState,
  }));

  readonly setFileRows = this.updater((state, fileRows: Row[]) => ({
    ...state,
    fileRows,
  }));

  readonly setUserDateFormatIndex = this.updater((state, userDateFormatIndex: DateFormatIndex) => ({
    ...state,
    userDateFormatIndex,
  }));

  readonly setPossibleDateFormats = this.updater((state, possibleDateFormats: string[]) => ({
    ...state,
    possibleDateFormats,
  }));

  readonly setFileHasOwnCurrency = this.updater((state, fileHasOwnCurrency: boolean) => ({
    ...state,
    fileHasOwnCurrency,
  }));

  readonly setHeadersMapping = this.updater(
    (state, headersMapping: MappingConfigurationItem[]) => ({
      ...state,
      headersMapping,
    })
  );

  readonly setArrangeTableColumns = this.updater((state, arrangeTableColumns: TableColumns[]) => ({
    ...state,
    arrangeTableColumns,
  }));

  readonly setMappingEngine = this.updater((state, mappingEngine: MappingEngine) => ({
    ...state,
    mappingEngine,
  }));

  readonly setError = this.updater((state, error: ErrorType) => ({
    ...state,
    error,
  }));
  readonly setGoWithDefaultMapper = this.updater((state, goWithDefaultMapper: boolean) => ({
    ...state,
    goWithDefaultMapper,
  }));

  readonly setInstitutionMapper = this.updater((state, setInstitutionMapper: boolean) => ({
    ...state,
    setInstitutionMapper,
  }));

  private async getFileContent(fileList: FileList) {
    const helperCsvImporter = new HelperCsvImporter();
    return await helperCsvImporter.getFileContents(fileList[0]);
  }
  arrangeFileContent(fileList: FileList) {
    this.getFileContent(fileList).then((content) => this.setFileContent(content));
  }

  processError(uploadState: ImportUploadInterface) {
    this.globalService.showErrorMessage(uploadState.error.titleKey, uploadState.error.textKey);
  }

  mapAmountFromInOrOut(isIn: boolean, isOut: boolean, columns: TableColumns[]): TableColumns[] {
    if (isIn) {
      columns = columns.map((column) => {
        if (column.mappedHeader === "in") {
          column.mappedHeader = "amount";
          column.lastKey = "amount";
        }

        return column;
      });
    }

    if (isOut) {
      columns = columns.map((column) => {
        if (column.mappedHeader === "out") {
          column.mappedHeader = "amount";
          column.lastKey = "amount";
        }

        return column;
      });
    }

    return columns;
  }

  clearedFromNewLine(headers: string[]) {
    return headers.map((header) => header.replace("\n", ""));
  }
  async setImportDataBeforeArrangement(
    selectedInstitution: Institution,
    upload: ImportUploadInterface | string
  ) {
    try {
      let isIn = false;
      let isOut = false;
      let isAmount = false;
      const fileContent = typeof upload === "string" ? upload : upload.fileContent;
      if (fileContent.replace(/\s/g, "") === "") {
        this.globalService.showErrorMessage("errorOccurred", "emptyFile");
        return;
      }
      const helperCsvImporter = new HelperCsvImporter();
      if (
        selectedInstitution.csvMapper &&
        selectedInstitution.csvMapper[0] &&
        typeof upload !== "string" &&
        !upload.goWithDefaultMapper
      ) {
        const headers = await helperCsvImporter.getCsvHeaders(fileContent);

        const keysOriginalHeader = this.clearedFromNewLine(Object.keys(headers));

        const hasAllKeys = keysOriginalHeader.every((key) => {
          return selectedInstitution.csvMapper.some(
            (mappingConfigItem) =>
              mappingConfigItem.mapping.includes(key.toLowerCase()) ||
              mappingConfigItem.mapping.includes(key)
          );
        });
        if (!hasAllKeys) {
          const goWithDefault = await this.globalService.showDialogue(
            "fileNotBelongingInstitution",
            "warning"
          );
          if (goWithDefault) {
            this.setGoWithDefaultMapper(true);
            this.setInstitutionMapper(true);
          } else {
            this.reset();
            return;
          }
        }
      }

      if (!selectedInstitution.csvMapper) {
        this.setGoWithDefaultMapper(true);
        this.setInstitutionMapper(true);
      }
      const mapping = new MappingEngine(selectedInstitution.csvMapper);

      const { dateFormatIndex, csvResults, possibleDateFormats } = helperCsvImporter.getInfoFromCsv(
        fileContent,
        null,
        mapping
      );
      const headersMapping: MappingConfigurationItem[] = [];
      let columns: TableColumns[] = Object.entries(mapping.headersMapping).map((headerMapping) => {
        headersMapping.push(headerMapping[1]);
        if (headerMapping[1].key === "in") {
          isIn = headerMapping[1].key === "in";
        }

        if (headerMapping[1].key === "out") {
          isOut = headerMapping[1].key === "out";
        }

        if (headerMapping[1].key === "amount") {
          isAmount = headerMapping[1].key === "amount";
        }
        return {
          columnDef: headerMapping[0],
          originalHeader: headerMapping[1].displayAs,
          mappedHeader: headerMapping[1].key,
          lastKey: headerMapping[1].key,
          cell: (element: any) => `${element[headerMapping[1].key]}`,
        };
      });

      const isContainInAndOutTogether = !(!isAmount && isIn !== isOut);

      if (!isContainInAndOutTogether) {
        columns = this.mapAmountFromInOrOut(isIn, isOut, columns);
      }

      this.setState((state) => {
        return {
          ...state,
          fileRows: csvResults,
          userDateFormatIndex: dateFormatIndex,
          possibleDateFormats,
          headersMapping,
          arrangeTableColumns: columns,
          mappingEngine: mapping,
        };
      });
    } catch (e) {
      this.globalService.showMessageAsIs(
        "error",
        "Invalid values exist",
        `invalid value : ${e.value}`
      );
      this.reset();
    }
  }

  checkForErrors(state: ImportUploadInterface) {
    const fileList = state.fileList;
    if (fileList === null || fileList.length === 0) {
      this.globalService.showErrorMessage("errorOccurred", "selectFile");
    }

    if (!fileList[0].name.endsWith(".csv")) {
      this.globalService.showErrorMessage("errorOccurred", "pleaseSelectACsvFile");
    }
  }
}
