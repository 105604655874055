<div class="progress-bar-container">
  <span class="in-progress progress-bar-label">{{
    mainProgressBar.getProgressLabel() | async
  }}</span>
  <mat-progress-bar
    class="gloss-progress-bar"
    mode="buffer"
    [value]="mainProgressBar.getLoadingPercentage() | async"
    color="warn"
  >
  </mat-progress-bar>
</div>
