import { Injectable, Injector } from "@angular/core";

import { TransactionBasiqImporter } from "@bitwarden/web-vault/app/importers/transaction-basiq-importer";
import { Connector } from "@bitwarden/web-vault/app/models/data/blobby/connector.data";
import { ConnectorResponse } from "@bitwarden/web-vault/app/models/data/response/connector.response";
import { GlossDate } from "@bitwarden/web-vault/app/models/data/shared/gloss-date";
import { DataRepositoryService } from "@bitwarden/web-vault/app/services/DataRepository/data-repository.service";
import { InstitutionService } from "@bitwarden/web-vault/app/services/DataService/institution/institution.service";
import { BasiqConnector } from "@bitwarden/web-vault/app/services/syncing/basiq-connector.service";
import { GlossConnectorService } from "@bitwarden/web-vault/app/services/syncing/gloss-connector.service";

import {
  AccountOriginGroup,
  AccountStatusOriginGroup,
  AccountSyncStatus,
  Origin,
  SyncStatus,
} from "../../models/types/general-types";
import { AccountView } from "../../models/view/account.view";
import { BookService } from "../DataService/book/book.service";

@Injectable({
  providedIn: "root",
})
export class GlossSyncService {
  private dataRepositoryService: DataRepositoryService;
  private transactionBasiqImporter: TransactionBasiqImporter;
  private institutionService: InstitutionService;
  private autoAccounts: AccountView[] = [];
  private accountOriginGroup: AccountOriginGroup = null;

  constructor(private bookService: BookService, private injector: Injector) {
    this.dataRepositoryService = this.injector.get(DataRepositoryService);
    this.transactionBasiqImporter = this.injector.get(TransactionBasiqImporter);
    this.institutionService = this.injector.get(InstitutionService);
  }

  async getLastStatus(): Promise<SyncStatus> {
    const accountStatus: AccountSyncStatus[] = [];
    const connectors: Connector[] = await this.dataRepositoryService.getAllConnectors();
    if (connectors.length > 0) {
      for (const connector of connectors) {
        const accountStatusWithViews: AccountSyncStatus[] = [];
        let connectorAccountStatus = connector.accountStatus;
        for (const accStatus of connectorAccountStatus) {
          const account = await this.bookService.get(accStatus.accountId);
          //TODO - @SinanRefactor - cehck out the connector ststus with removing consent and the sync as well. Looks like each time it adds up accounts to it and never delete it
          if (!account) {
            continue;
          }
          const institution = await this.institutionService.getInstitutionById(
            account.institutionLink.institutionId
          );
          accStatus.isCompleted = false;
          accStatus.accountView = new AccountView(account, institution);
          accStatus.lastSyncedAt = new GlossDate().setToDateObj(
            accStatus.lastSyncedAt as GlossDate
          );
          accountStatusWithViews.push(accStatus);
        }
        connectorAccountStatus = accountStatusWithViews;
        accountStatus.push(...connectorAccountStatus);
      }
    }
    return {
      isStarted: false,
      isFinished: null,
      isAvailable: true,
      accountStatus,
    };
  }

  async isStatusAvailable(): Promise<boolean> {
    this.autoAccounts = await this.bookService.getAutoBooksView();
    this.accountOriginGroup = this.getAccountOriginGroup();
    return this.autoAccounts.length > 0;
  }

  private getAccountOriginGroup(): AccountOriginGroup {
    const grouped = {} as AccountOriginGroup;
    for (const account of this.autoAccounts) {
      const accountOrigin = account.origin;
      if (!grouped[accountOrigin]) {
        grouped[accountOrigin] = [];
      }
      grouped[accountOrigin].push(account);
    }

    return grouped;
  }

  getGlossConnectors(): BasiqConnector[] {
    this.accountOriginGroup = this.getAccountOriginGroup();
    const connectors: BasiqConnector[] = [];
    for (const origin in this.accountOriginGroup) {
      const connector = new GlossConnectorService(
        origin as Origin,
        this.accountOriginGroup[origin as Origin],
        this.injector
      ).getConnector();
      connectors.push(connector);
    }
    return connectors;
  }

  async mergeSyncedData(accountStatuses: AccountSyncStatus[]) {
    for (const accountStatus of accountStatuses) {
      if (accountStatus.rawTransactions.length === 0) {
        accountStatus.isMerged = true;
        continue;
      }
      const { accountView, rawTransactions } = accountStatus;

      await this.transactionBasiqImporter.saveSyncedTransactions(accountView.originalBook, false, {
        data: rawTransactions,
      });

      accountStatus.isMerged = true;
    }
    await this.updateConnectorsInBlobby(accountStatuses);
  }

  async updateConnectorsInBlobby(accountStatuses: AccountSyncStatus[]) {
    const blobbyConnectors = await this.getBlobbyConnectors();
    const groupedAccountStatuses: AccountStatusOriginGroup = accountStatuses.reduce(
      (acc: AccountStatusOriginGroup, accountStatus: AccountSyncStatus) => {
        if (!acc[accountStatus.accountView.originalBook.origin]) {
          acc[accountStatus.accountView.originalBook.origin] = [];
        }

        acc[accountStatus.accountView.originalBook.origin as Origin].push(accountStatus);
        return acc;
      },
      {} as AccountStatusOriginGroup
    );

    for (const origin in groupedAccountStatuses) {
      const accountStatuses: AccountSyncStatus[] = groupedAccountStatuses[origin as Origin].map(
        (status: AccountSyncStatus) => {
          status.rawTransactions = [];
          status.accountId = status.accountView.originalBook.id;
          status.accountView = null;
          status.point.key = status.point.data.type === "success" ? "synced" : "failed";
          return status;
        }
      );
      const connector: Connector = blobbyConnectors.find(
        (connector: Connector) => connector.origin === origin
      );
      if (connector) {
        connector.accountStatus = accountStatuses;
        await this.dataRepositoryService.updateConnector(connector);
      } else {
        const newConnectorObj: any = {
          _id: crypto.randomUUID(),
          _name: origin,
          _origin: origin,
          _connectorType: origin,
          _institution: [],
          _connectionInfo: {},
          accountStatus: accountStatuses,
        };

        const newConnector = new Connector(new ConnectorResponse(newConnectorObj));
        await this.dataRepositoryService.createConnector(newConnector);
      }
    }
  }

  async getBlobbyConnectors(): Promise<Connector[]> {
    return await this.dataRepositoryService.getAllConnectors();
  }

  //TODO - when we get a failure in store just save it to blobby
  async updateFailedConnectorsInBlobby(accountStatuses: AccountSyncStatus[]) {
    await this.updateConnectorsInBlobby(accountStatuses);
  }
}
