<!--<app-spinner [showSpinner]="loading"></app-spinner>-->
<!-- todo remove my style="max-height: 85vh" workaround -->
<form class="modal-content" (ngSubmit)="submit()" style="max-height: 85vh">
  <div class="modal-body">
    <div
      class="row-container new-accounts-container"
      *ngFor="let newAccount of unrecognizedAccounts; index as newBookNameIndex"
    >
      <div class="menu-container">
        <div class="top-container">
          <p class="new-account m-0">
            {{ "detectedAccount" | i18n }} :
            <span class="detected-account-name">{{
              newAccount.source || ("noAccountDetected" | i18n)
            }}</span>
          </p>
          <div class="button-container">
            <div
              *ngIf="accounts?.length > 0 && !isEmptyAccountName(newAccount.accountId)"
              class="menu-item gloss-btn"
              [class.selected-item]="isNameMap.get(newAccount.source)"
              (click)="showRenaming(newAccount.source)"
            >
              {{ "renameThisAccount" | i18n }}
            </div>

            <div
              *ngIf="accounts?.length > 0 && !isEmptyAccountName(newAccount.accountId)"
              class="menu-item"
              [class.selected-item]="isLinkMap.get(newAccount.source)"
              (click)="showLinking(newAccount.source, newBookNameIndex)"
            >
              {{ "linkToAnExistingAccount" | i18n }}
            </div>

            <button
              matSuffix
              mat-icon-button
              size="small"
              aria-label="Clear"
              class="refresh-button"
              (click)="resetAccount($event, newAccount.source)"
            >
              <mat-icon>refresh</mat-icon>
            </button>
          </div>
        </div>
      </div>
      <div class="row-container-for-new-accounts" *ngIf="newAccount.accountId !== ''">
        <div class="account-option" *ngIf="newAccount">
          <ng-container>
            <div class="form-group mb-4">
              <label>{{ "accountCurrency" | i18n }}</label>
              <div class="form-control">
                <mat-select
                  [value]="this.newBooks.currency[newAccount.source]"
                  id="`accountCurrency`"
                  (selectionChange)="onAccountCurrencyChange($event, newAccount)"
                  [formControl]="currencyFormControls[newBookNameIndex]"
                  [disabled]="newAccount.action === 'link'"
                >
                  <!--
                  todo :at this stage the transactions have been processed once meaning the currency has been set at least to base currency . It is how structered in GlossQuantity
                  todo : so if a transaction , in this case new account since it is getting its currency from transcation , has only one currency this could mean that its currency
                  todo : is missing in the file itself but comming from GlossQuantity default value which is baseCurrency . So if it is just oen value and it is base currency
                  todo : we show all other options to the user to select its currency . Down side the file might actually have a currency that is baseCurrency in which case we should
                  todo: just display that one currency . Discuss with Alex see what can be done .Check todo in GlossQuantity related to static BASE_CURRENCY

                  -->
                  <mat-option
                    *ngFor="
                      let item of newAccount.currencies.length &&
                      newAccount.currencies.length < 2 &&
                      newAccount.currencies[0] !== baseCurrency
                        ? newAccount.currencies
                        : allCurrencies
                    "
                    [value]="item"
                  >
                    {{ item }}
                  </mat-option>
                </mat-select>
              </div>
            </div>
          </ng-container>
        </div>

        <div class="account-option">
          <ng-container>
            <div class="form-group mb-4">
              <label>{{ "accountTimeZone" | i18n }}</label>
              <div class="form-control">
                <mat-select
                  [value]="this.newBooks.timeZone[newAccount.source]"
                  id="`accountTimeZone`"
                  (selectionChange)="onAccountTimeZoneChange($event, newAccount)"
                  [formControl]="timeZoneFormControls[newBookNameIndex]"
                >
                  <mat-option *ngFor="let item of timeZones" [value]="item">
                    {{ item }}
                  </mat-option>
                </mat-select>
              </div>
            </div>
          </ng-container>
        </div>

        <div class="account-option" *ngIf="isLinkMap.get(newAccount.source) && accounts.length > 0">
          <ng-container>
            <div class="form-group mb-4">
              <label>{{ "linkAccountTo" | i18n }}</label>
              <div class="form-control">
                <mat-select
                  [value]="this.newBookNames.linkedBook[newAccount.source]"
                  (selectionChange)="onAccountChange($event, newAccount.source, newBookNameIndex)"
                  [formControl]="linkAccountFormControls[newBookNameIndex]"
                >
                  <mat-option *ngFor="let item of this.accounts" [value]="item">
                    {{ item.name }}
                  </mat-option>
                </mat-select>
              </div>
            </div>
          </ng-container>
        </div>

        <div
          class="account-option"
          *ngIf="isNameMap.get(newAccount.source) && this.data.institution.availableAccounts"
        >
          <ng-container>
            <div class="form-group mb-4">
              <label>{{ "accountType" | i18n }}</label>
              <div class="form-control">
                <mat-select
                  (selectionChange)="
                    onInstitutionAccountChange($event, newAccount.source, newBookNameIndex)
                  "
                  [formControl]="linkInstitutionAccountFormControls[newBookNameIndex]"
                >
                  <mat-option
                    *ngFor="let item of this.data.institution.availableAccounts"
                    [value]="item"
                  >
                    {{ item.name }}
                  </mat-option>
                </mat-select>
              </div>
            </div>
          </ng-container>
        </div>

        <div class="account-option" *ngIf="isNameMap.get(newAccount.source)">
          <app-input
            labelKey="name"
            [inputValue]="this.newBookNames.rename[newAccount.source]"
            (changeEvent)="renameTheAccount($event, newAccount.source)"
          ></app-input>
          <button
            *ngIf="
              this.newBookNames.rename[newAccount.source] !== '' &&
              this.newBookNames.rename[newAccount.source]
            "
            matSuffix
            mat-icon-button
            size="small"
            aria-label="Clear"
            class="clear-input-button"
            (click)="clearRename($event, newAccount.source)"
          >
            <mat-icon>clear</mat-icon>
          </button>
        </div>
      </div>

      <div class="empty-account-name-container" *ngIf="isEmptyAccountName(newAccount.accountId)">
        <div class="empty-account-name-insto-container">
          <p class="insto-name">{{ this.data.institution.name }} :</p>
        </div>

        <div class="empty-account-name-insto-container">
          <ng-container>
            <div class="form-group mb-4" *ngIf="isInstoAccounts()">
              <div class="form-control">
                <mat-select
                  id="`emptyAccount`"
                  (selectionChange)="onEmptyAccountNameChange($event, newAccount)"
                  [formControl]="emptyAccountNameControl"
                  [value]="this.emptyAccountNameControl.value"
                >
                  <mat-option *ngFor="let item of this.instoAccounts" [value]="item">
                    {{ item.name }}
                  </mat-option>
                </mat-select>
              </div>
            </div>
          </ng-container>
        </div>

        <div class="menu-item" (click)="createNewAccount()">
          {{ "createAccount" | i18n }}
        </div>
      </div>
    </div>

    <div class="modal-footer right-buttons-container">
      <button mat-raised-button mat-dialog-close (click)="closeDialogue()">
        {{ "cancel" | i18n }}
      </button>
      <button mat-raised-button color="primary" type="submit">
        {{ "save" | i18n }}
      </button>
    </div>
  </div>
</form>
