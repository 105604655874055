import { EstimateActionWorker } from "@bitwarden/web-vault/app/services/web-worker/estimate-actions/estimate-action.worker";
import { WebWorker } from "@bitwarden/web-vault/web-worker/web.worker";
import { WorkerMessage } from "@bitwarden/web-vault/web-worker/worker.message";

export class WebWorkerQueue {
  private maxWorkers = 4;
  private workers: Array<WebWorker> = [];
  private workersBusy: Array<boolean> = [];
  private queue: Array<{ message: WorkerMessage; callback: (data: any) => void }> = [];

  constructor() {
    this.createNewWorker();
  }

  createNewWorker(): number {
    if (this.workers.length >= this.maxWorkers) {
      return;
    }
    const worker = EstimateActionWorker.getInstance();
    worker.workerIndex = this.workers.length;
    this.workers.push(worker);
    this.workersBusy.push(false);

    return worker.workerIndex;
  }

  async runJob(workerIndex: number) {
    this.workersBusy[workerIndex] = true;
    const worker = this.workers[workerIndex];
    if (this.queue.length > 0) {
      const job = this.queue.shift();
      if (job && job?.message?.id) {
        job.message.data.workerIndex = workerIndex;
        if (worker instanceof EstimateActionWorker) {
          await worker.runEstimateAction(job.message, (data: any) => {
            const returnedWorkerIndex = data?.data?.workerIndex;
            this.workersBusy[returnedWorkerIndex] = false;
            this.processQueue();
            job.callback(data);
          });
        }
      }
    }
  }

  getFreeWorker(): number {
    for (let i = 0; i < this.workersBusy.length; i++) {
      if (!this.workersBusy[i]) {
        return i;
      }
    }
    if (this.workers.length < this.maxWorkers) {
      return this.createNewWorker();
    }
  }

  async processQueue() {
    if (this.queue.length > 0) {
      const workerIndex = this.getFreeWorker();
      if (workerIndex !== undefined) {
        await this.runJob(workerIndex);
      }
    }
  }

  async postMessage(message: WorkerMessage, callback: (data: any) => void) {
    this.queue.push({ message: message, callback: callback });

    const workerIndex = this.getFreeWorker();
    if (workerIndex !== undefined) {
      await this.runJob(workerIndex);
    }
  }
}
