import { inject } from "@angular/core";
import { takeUntil } from "rxjs/operators";

import {
  BaseProgressBar,
  ProgressBarInterface,
} from "@bitwarden/web-vault/app/components/progress-bar/base-progress-bar";
import { BalanceAlignmentService } from "@bitwarden/web-vault/app/services/DataCalculationService/transaction/balance-alignment.service";

export class BalanceProgressBar extends BaseProgressBar implements ProgressBarInterface {
  protected balanceAlignmentService: BalanceAlignmentService;
  constructor(loadingPercent: number, importingLabel: string, totalItems: number) {
    super(loadingPercent, importingLabel, totalItems);
    this.balanceAlignmentService = inject(BalanceAlignmentService);
  }
  show() {
    this.balanceAlignmentService.totalAlignmentTransactionNumber$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (value) => {
          this.totalItems = value;
          this.processItems("balance");
        },
      });
  }

  processItems(type: string) {
    this.setLabel(type);
    this.balanceAlignmentService.startRecalculatingBalance();
    this.balanceAlignmentService.numberOfBalanceAlignmentOnProcess$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (value) => {
          this.loadingPercent = this.calculatePercentage(value);
          if (this.totalItems === 0) {
            this.balanceAlignmentService.completeBalanceProgress();
          }
        },
      });
  }
}
