import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatSelectChange } from "@angular/material/select";
import { Subject, takeUntil } from "rxjs";

import { GlobalService } from "@bitwarden/common/services/global/global.service";
import { defaultMapping } from "@bitwarden/web-vault/app/importers/data-mapper/mappers/default-mapping";
import { MappingConfigurationItem } from "@bitwarden/web-vault/app/importers/data-mapper/mapping-engine-types";
import {
  ImportInstitutionsInterface,
  InstitutionStore,
} from "@bitwarden/web-vault/app/importers/store/institution.import.store";
import {
  ImportUploadInterface,
  UploadStore,
} from "@bitwarden/web-vault/app/importers/store/upload.import.store";
import { TableColumns } from "@bitwarden/web-vault/app/models/types/import.types";
import DateFormat from "@bitwarden/web-vault/app/shared/utils/helper.date/date-format";

@Component({
  selector: "app-drop-down-header",
  templateUrl: "./drop-down-header.component.html",
})
export class DropDownHeaderComponent implements OnInit {
  private destroy$: Subject<boolean> = new Subject();
  selectedOptionControl: FormControl<MappingConfigurationItem> = new FormControl();
  dropdownOptions = defaultMapping;
  uploadState: ImportUploadInterface;
  institutionState: ImportInstitutionsInterface;
  vmUpload$ = this.uploadStore.vmUpload$;
  vmInstitution = this.institutionStore.vmInstitution$;
  @Input() preselect: any;
  @Input() selectedOption: any;
  previousOption: any;

  @Output() optionChanged: EventEmitter<{ selectedOption: MappingConfigurationItem; column: any }> =
    new EventEmitter();

  constructor(
    private uploadStore: UploadStore,
    private globalService: GlobalService,
    private institutionStore: InstitutionStore
  ) {}
  ngOnInit() {
    this.previousOption = this.getSelectedOption();
    this.selectedOptionControl.setValue(this.previousOption);
    this.vmUpload$.pipe(takeUntil(this.destroy$)).subscribe((state) => {
      this.uploadState = state;
    });

    this.vmInstitution.pipe(takeUntil(this.destroy$)).subscribe((state) => {
      this.institutionState = state;
    });
  }
  ngOnDestroy(): void {
    this.destroy$.next(true);
  }

  getSelectedOption() {
    return this.dropdownOptions.find((mapping) => mapping.key === this.preselect.mappedHeader);
  }

  onOptionChange(optionChange: MatSelectChange) {
    const selectedOption = optionChange.value;
    if (this.isOptionOk(this.preselect, selectedOption)) {
      this.optionChanged.emit({ selectedOption, column: this.preselect });
      this.previousOption = selectedOption;
    } else {
      this.selectedOptionControl.setValue(this.previousOption);
    }
  }

  isValidDate(value: string): boolean {
    const v = Date.parse(value);
    return !isNaN(v);
  }
  isColumnADate(column: TableColumns) {
    const dateFormatSample: string[] = [];
    for (const csvRow of this.uploadState.fileRows) {
      dateFormatSample.push(csvRow[column.mappedHeader]);
    }

    const possibleDateFormatsFromRegEx =
      DateFormat.getPossibleDateFormatsFromRegEx(dateFormatSample);

    return possibleDateFormatsFromRegEx.highestMatchFormats.length > 0;
  }
  isOptionOk(column: TableColumns, selectedOption: MappingConfigurationItem) {
    if (selectedOption.key === "date") {
      const canBeDate = this.isColumnADate(column);
      if (!canBeDate) {
        this.globalService.showErrorMessage("errorOccurred", "columnCanNotBeADate");
        return false;
      }
    }
    return true;
  }
}
