<div class="tw-max-w-5xl">
  <div class="tw-mx-14">
    <span class="tw-m-auto tw-block tw-text-left tw-text-base"> {{ message?.info | i18n }} </span>

    <span class="tw-m-auto tw-block tw-text-left tw-text-base">
      {{ message?.action?.pre | i18n }}
    </span>

    <span class="tw-m-auto tw-block tw-text-left tw-text-base">
      <span
        class="tw-cursor-pointer tw-text-lg tw-font-bold tw-text-accent tw-underline"
        (click)="openHelp($event)"
      >
        {{ message?.action?.text | i18n }}
      </span>

      {{ message?.action?.post | i18n }}
    </span>
  </div>
</div>
