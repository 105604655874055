<div
  class="tw-mb-[5px] tw-flex tw-h-[70px] tw-items-center tw-justify-between tw-rounded-xl tw-bg-white tw-px-10 xs:tw-px-5"
>
  <button
    *ngIf="showBackButton"
    (click)="handleBack()"
    class="icon-button"
    data-testid="back-button"
  >
    <svg class="tw-h-7 tw-w-7">
      <use xlink:href="images/sprite.svg#left-arrow" />
    </svg>
  </button>

  <app-help-tooltip
    [ngClass]="{ 'tw-hidden': !enableTooltip }"
    [showHelpTooltip]="tooltip.showHelpTooltip"
    [tooltipInfo]="tooltipInfo"
    [xPosition]="tooltip.xPosition"
    [yPosition]="tooltip.yPosition"
    [pointer]="'modal-header'"
    (onTooltipClose)="tooltip.close()"
  ></app-help-tooltip>

  <div
    class="tw-flex tw-items-center"
    [ngClass]="{ 'tw-w-full tw-justify-center': !showBackButton }"
  >
    <span class="tw-text-2xl xs:tw-text-lg">
      {{ title }}
    </span>
    <div *ngIf="enableTooltip" title="help" (click)="tooltip.show($event)">
      <mat-icon
        class="mat-icon-secondary mat-icon-tooltip"
        id="help-info-button-creation"
        [ngClass]="{ clicked: tooltip.isClicked }"
        (click)="tooltip.toggle()"
      >
        info_outline
      </mat-icon>
    </div>
  </div>

  <button mat-dialog-close aria-label="Close dialog" tabindex="-1" class="icon-button">
    <svg class="tw-h-4 tw-w-4">
      <use xlink:href="images/sprite.svg#close" />
    </svg>
  </button>
</div>
