import { TransactionFieldFormatterData } from "./transaction-field-formatter.data";

interface mapping {
  key: string;
  value: string;
  formatter: TransactionFieldFormatterData;
}

export class ImportHandler {
  private readonly __v = 1;
  private _id: string;
  mapping: Array<mapping>;
  hashFunction: string;
  name: string;

  constructor(obj: any) {
    if (obj && obj._id) {
      this._id = obj._id;
    } else {
      this._id = crypto.randomUUID();
    }

    if (obj) {
      this.name = obj.name;
    }
  }

  get id(): string {
    return this._id;
  }
}
