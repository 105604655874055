import { FormGroup } from "@angular/forms";

import { EstimateActionsEnum } from "../../../../../models/enum/estimate-actions.enum";
import { SplitCategoryType } from "../../../../../models/types/split-category-type";
import { SplitClassificationType } from "../../../../../models/types/split-classification-type";

export class FormHelper {
  getTransactionGenerator(estimateFormGroup: FormGroup) {
    const recurring = estimateFormGroup.controls["isRecurring"].value;
    const transactionGenerator = estimateFormGroup.controls["calculateAs"].value.key;
    if (transactionGenerator) {
      return transactionGenerator;
    }
    let generator = EstimateActionsEnum.nonRecurring;
    if (recurring) {
      if (transactionGenerator) {
        generator = transactionGenerator;
      } else {
        generator = EstimateActionsEnum.recurringFixed;
      }
    }
    return generator;
  }

  getCalculationMethod(estimateFormGroup: FormGroup) {
    const isRecurring = estimateFormGroup.controls["isRecurring"].value;

    if (isRecurring === "Yes") {
      return {
        name: "Recurring Fixed",
        key: EstimateActionsEnum.recurringFixed,
      };
    }

    return {
      name: "Non-Recurring",
      key: EstimateActionsEnum.nonRecurring,
    };
  }

  generateSplitCategoriesFromForm(estimateFormGroup: FormGroup): SplitCategoryType[] {
    const splitCategories: SplitCategoryType[] = [];
    for (const key in estimateFormGroup.controls) {
      if (key.startsWith("category")) {
        const value = estimateFormGroup.controls[key].value;
        const keySplits = key.split("_");
        if (value !== 0 && keySplits[1]) {
          splitCategories.push({
            categoryId: keySplits[1],
            weight: value,
            roundingDefault: false,
          });
        }
      }
    }

    return splitCategories;
  }

  generateSplitClassificationsFromForm(estimateFormGroup: FormGroup): SplitClassificationType[] {
    const splitClassifications: SplitClassificationType[] = [];
    for (const key in estimateFormGroup.controls) {
      if (key.startsWith("classification")) {
        const value = estimateFormGroup.controls[key].value;
        const keySplits = key.split("_");
        if (value !== 0 && keySplits[1]) {
          splitClassifications.push({
            classificationId: keySplits[1],
            weight: value,
            roundingDefault: false,
          });
        }
      }
    }
    return splitClassifications;
  }
}
