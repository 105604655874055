export enum RoleScope {
  API = "api",
  OFFLINE_MODE = "offline_mode",
  VAULT = "VAULT",
  VIEW = "VIEWS",
  DATA_AGGREGATOR = "DATA_AGGREGATOR",
  EQUITY = "EQUITY",
  GLOSS = "GLOSS",
  FOREX = "FOREX",
  BETA_ACCESS = "BETA_ACCESS",
  FORECASTING = "FORECASTING",
}

export const DATA_AGGREGATOR_OPTIONS = {
  PLAID: "PLAID",
  BASIQ: "BASIQ",
};

export const GLOSS_VIEW_OPTIONS = {
  SCENARIO: "scenario",
  SUMMARY: "summary",
  COMBINED: "combined",
};
