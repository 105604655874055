<div class="d-flex">
  <app-drop-down-select-button
    buttonText="selectAll"
    (click)="selectAll()"
  ></app-drop-down-select-button>
  <mat-divider vertical></mat-divider>

  <app-drop-down-select-button
    buttonText="removeAll"
    (click)="removeAll()"
  ></app-drop-down-select-button>
  <mat-divider vertical></mat-divider>

  <app-drop-down-select-button
    buttonText="reverse"
    (click)="reverseAll()"
  ></app-drop-down-select-button>
  <mat-divider vertical></mat-divider>

  <app-drop-down-select-button buttonText="cancel" (click)="cancel()"></app-drop-down-select-button>
  <mat-divider vertical></mat-divider>

  <app-drop-down-select-button buttonText="apply" (click)="apply()"></app-drop-down-select-button>
</div>
