import { Injectable } from "@angular/core";

import { TransactionStatusEnum } from "@bitwarden/web-vault/app/models/enum/transactionType";

import { SourceTransaction } from "../../../models/data/blobby/source-transaction.data";
import { Transaction } from "../../../models/data/blobby/transaction.data";

@Injectable({
  providedIn: "root",
})
export class BalanceAlignment {
  static isBalanceAlignmentTransaction(trans: Transaction): boolean {
    return !!(
      this.isBalanceAlignmentTransactionOpen(trans) ||
      this.isBalanceAlignmentTransactionClose(trans)
    );
  }

  static isBalanceAlignmentTransactionOpen(trans: Transaction): boolean {
    return !!(trans && trans.definition === TransactionStatusEnum.opening);
  }

  static isBalanceAlignmentTransactionClose(trans: Transaction): boolean {
    return !!(trans && trans.definition === TransactionStatusEnum.closing);
  }

  static isBalanceAlignmentSourceTransactionOpen(st: SourceTransaction): boolean {
    return !!(st && st.definition === TransactionStatusEnum.opening);
  }

  static isBalanceAlignmentSourceTransactionClose(st: SourceTransaction): boolean {
    return !!(st && st.definition === TransactionStatusEnum.closing);
  }

  static isBalanceAlignmentSourceTransaction(st: SourceTransaction): boolean {
    return !!(
      this.isBalanceAlignmentSourceTransactionOpen(st) ||
      this.isBalanceAlignmentSourceTransactionClose(st)
    );
  }
}
