<div class="tw-flex tw-gap-2 tw-rounded-lg" [formGroup]="dateForm">
  <div
    class="tw-w-full tw-cursor-pointer tw-flex-col tw-items-center tw-justify-start tw-gap-2 tw-rounded-full tw-pl-2 md:tw-pl-0"
    (click)="startPicker.open()"
  >
    <div
      class="tw-relative tw-flex tw-w-full tw-items-center tw-whitespace-nowrap tw-font-medium tw-text-neutral-600 xs:tw-scale-90"
    >
      Start Date
    </div>
    <div
      class="tw-min-h-12 tw-relative tw-flex tw-h-auto tw-w-full tw-items-center tw-justify-between tw-gap-3 tw-rounded-full tw-bg-neutral-50 tw-px-6 tw-py-2 tw-shadow-button xs:tw-gap-0 xs:tw-px-2"
    >
      <div class="tw-relative tw-flex tw-items-center tw-justify-center">
        <mat-datepicker #startPicker></mat-datepicker>
        <input
          matInput
          [matDatepicker]="startPicker"
          formControlName="startDate"
          (dateChange)="onDateChange($event, 'startDate')"
          class="tw-pointer-events-none tw-absolute tw-opacity-0"
        />
        <div
          class="tw-relative tw-flex tw-w-full tw-min-w-[6rem] tw-justify-center tw-whitespace-nowrap tw-text-neutral-800"
        >
          {{ startDateText }}
        </div>
      </div>
      <div class="tw-overflow-hidden tw-rounded">
        <svg class="tw-h-7 tw-w-7">
          <use xlink:href="images/sprite.svg#date-start" />
        </svg>
      </div>
    </div>
  </div>

  <div
    class="tw-w-full tw-cursor-pointer tw-flex-col tw-items-center tw-justify-start tw-gap-2 tw-rounded-full tw-pr-2"
    (click)="endPicker.open()"
  >
    <div
      class="tw-relative tw-flex tw-w-full tw-items-center tw-whitespace-nowrap tw-font-medium tw-text-neutral-600 xs:tw-scale-90"
    >
      End Date
    </div>
    <div
      class="tw-min-h-12 tw-relative tw-flex tw-h-auto tw-w-full tw-items-center tw-justify-between tw-gap-3 tw-rounded-full tw-bg-neutral-50 tw-px-6 tw-py-2 tw-shadow-button xs:tw-gap-0 xs:tw-px-2"
    >
      <div class="tw-relative tw-flex tw-items-center tw-justify-center">
        <mat-datepicker #endPicker></mat-datepicker>
        <input
          matInput
          [matDatepicker]="endPicker"
          [min]="getMinEndDate()"
          formControlName="endDate"
          (dateChange)="onDateChange($event, 'endDate')"
          class="tw-pointer-events-none tw-absolute tw-opacity-0"
        />
        <div
          class="tw-relative tw-flex tw-w-full tw-min-w-[6rem] tw-justify-center tw-whitespace-nowrap tw-text-neutral-800"
        >
          {{ endDateText }}
        </div>
      </div>
      <div class="tw-overflow-hidden tw-rounded">
        <svg class="tw-h-7 tw-w-7">
          <use xlink:href="images/sprite.svg#date-end" />
        </svg>
      </div>
    </div>
  </div>
</div>
