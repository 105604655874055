const getRandomLocations = (n: number) => {
  let m_w = 129356789;
  let m_z = 987454381;
  const mask = 0xffffffff;

  // Taken from https://stackoverflow.com/a/19301306
  const random = () => {
    m_z = (36969 * (m_z & 65535) + (m_z >> 16)) & mask;
    m_w = (18000 * (m_w & 65535) + (m_w >> 16)) & mask;
    let result = ((m_z << 16) + m_w) & mask;
    result /= 4294967296;
    return result + 0.5;
  };

  const locs = [];
  for (let i = 0; i < n; i++) {
    locs.push({
      x: random() * 100,
      y: random() * 100,
    });
  }
  return locs;
};

export function distBenchmark(runTimeInMilliSec: number): number {
  const locs = getRandomLocations(10);
  const start = performance.now();
  let ops = 0;
  let curTime = performance.now();
  while (curTime < start + runTimeInMilliSec) {
    const dists = [];
    locs.forEach((a) =>
      locs.forEach((b) => {
        const d = Math.sqrt(Math.pow(b.x - a.x, 2) + Math.pow(b.y - a.y, 2));
        dists.push(d);
      })
    );
    ops++;
    curTime = performance.now();
  }
  return ops;
}

function fibonacci(i: number): number {
  if (i <= 1) {
    return i;
  }
  return fibonacci(i - 1) + fibonacci(i - 2);
}

export function fibBenchmark(i: number): number {
  const start = performance.now();
  fibonacci(i);
  const end = performance.now();
  return end - start;
}
