import { Injectable } from "@angular/core";

import { BookService } from "@bitwarden/web-vault/app/services/DataService/book/book.service";
import { AccountValidator } from "@bitwarden/web-vault/app/validators/entity/account-validator";

import { TransactionService } from "../services/DataService/transaction/transaction.service";

import { BaseValidator, potentialTransaction, validationResult } from "./base-validator";
import { Validator } from "./validator";

@Injectable({
  providedIn: "root",
})
export class CsvValidator extends BaseValidator implements Validator {
  constructor(
    protected transactionService: TransactionService,
    public accountValidator: AccountValidator,
    public bookService: BookService
  ) {
    super(accountValidator, bookService);
  }

  async matchTransactionRecord(
    importTransactions: Array<potentialTransaction>
  ): Promise<validationResult> {
    const existingTransactions = await this.transactionService.getAllSourceTransactions();

    return await this.validate(importTransactions, existingTransactions);
  }
}
