<div>
  <div>
    <label>{{ labelKey | i18n }}</label>
    <div
      #selectContainer
      class="tw-min-h-12 tw-relative tw-inline-flex tw-h-auto tw-cursor-pointer tw-items-center tw-justify-between tw-gap-4 tw-rounded-full tw-bg-neutral-50 tw-px-6 tw-py-2 tw-shadow-button xs:tw-gap-[90px]"
      (click)="openSelect()"
    >
      <mat-select
        class="tw-relative tw-w-full tw-items-center"
        panelClass="tw-bg-white tw-shadow-button tw-mt-[32px]"
        #select
        [(value)]="selectedValue"
        (selectionChange)="onSelectionChange(selectedValue)"
        (openedChange)="onPanelChange($event)"
        [id]="selectElementId"
      >
        <mat-option
          class="tw-w-full"
          *ngFor="let item of itemList"
          [value]="!item[valueKey] ? item : item[valueKey]"
        >
          {{ !item[displayKey] ? item : item[displayKey] }}
        </mat-option>
      </mat-select>

      <div class="tw-relative tw-items-center">
        <svg class="tw-h-7 tw-w-7">
          <use xlink:href="images/sprite.svg#down-arrow" />
        </svg>
      </div>
    </div>
  </div>
</div>
