<app-spinner [showSpinner]="isImporting"></app-spinner>
<div class="content" *ngIf="!isImportComplete">
  <div class="container">
    <div class="page-header">
      <h1>{{ "importReferenceData" | i18n }}</h1>
    </div>
    <form [formGroup]="currencyForm" ngNativeValidate>
      <!-- Currency Import Form -->
      <fieldset>
        <!--        <legend>{{ "importCurrencyData" | i18n }}</legend>-->
        <div class="file-input-container">
          <button type="button" mat-raised-button (click)="currencyCsvReader.click()">
            {{ "chooseFile" | i18n }}
          </button>
          <input
            type="file"
            #currencyCsvReader
            id="currencyFileReader"
            formControlName="currencyCsvFile"
            accept=".csv"
            aria-describedby="file-upload-description"
            [hidden]="true"
            [required]="true"
            (change)="uploadListener($event)"
          />
          <span id="currency-file-upload-description">{{
            selectedCurrencyFileName || ("noFileChosen" | i18n)
          }}</span>
        </div>
        <br />
        <!--        <button-->
        <!--          mat-raised-button-->
        <!--          color="primary"-->
        <!--          type="button"-->
        <!--          [disabled]="!selectedCurrencyFileName"-->
        <!--          (click)="submit('currencyFileReader')"-->
        <!--        >-->
        <!--          <span>{{ "importCurrencyData" | i18n }}</span>-->
        <!--        </button>-->
        <button
          mat-raised-button
          color="primary"
          type="button"
          [disabled]="!selectedCurrencyFileName"
          (click)="preview('currencyFileReader')"
        >
          <span>{{ "previewData" | i18n }}</span>
        </button>
      </fieldset>
    </form>
  </div>
</div>
<!--<ng-template #referenceDataTable>-->
<!--  <app-reference-data-table [referenceData]="importedReferenceData"></app-reference-data-table>-->
<!--</ng-template>-->
