import { GlossDate } from "@bitwarden/web-vault/app/models/data/shared/gloss-date";

import { BaseResponse } from "../../../../../../../libs/common/src/models/response/base.response";

export class ReferenceDataResponse extends BaseResponse {
  __v: number;
  id: string;
  glossDate: GlossDate;
  base: string;
  symbols: { [key: string]: number };

  constructor(response: any = null) {
    super(response);
    this.__v = this.getResponseProperty("__v");
    this.id = this.getResponseProperty("_id") || this.getResponseProperty("id");
    this.glossDate = this.getResponseProperty("glossDate") || this.getResponseProperty("date");
    this.base = this.getResponseProperty("base");
    this.symbols = this.getResponseProperty("symbols");
  }
}
