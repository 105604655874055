const Experimental = {
  glossSettings: {
    manageProjections: "manageProjections",
  },
  vaultSettings: {
    fingerprint: "Your account's fingerprint phrase",
    deauthorizeSessions: "Deauthorize sessions",
    purgeVault: "Purge Vault",
    security: "My account's encryption",
  },
  transaction: {
    action: "Delete / Update",
    toggleEstimate: "Show hide estimate",
  },
  estimates: "Estimate Feature",
  referenceManagement: "Reference Management Feature",
};

export const FeatureFlagConfig: Record<string, any> = { Experimental };
