import { inject } from "@angular/core";
import { NavigationEnd, Router, RouterEvent } from "@angular/router";
import { BehaviorSubject, filter, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

export class HelperRoute {
  private router: Router;
  currentUrl = "";
  private destroy$: Subject<string> = new Subject<string>();

  constructor() {
    this.router = inject(Router);
  }

  currentRoute(currentUrlSubject: BehaviorSubject<string>) {
    this.router.events
      .pipe(
        filter((event: RouterEvent) => event instanceof NavigationEnd),
        takeUntil(this.destroy$)
      )
      .subscribe((event: NavigationEnd) => {
        if (this.isUrlChanged()) {
          currentUrlSubject.next(event.url);
        }
      });
  }

  private isUrlChanged() {
    return this.router.url !== "" && this.router.url !== this.currentUrl;
  }
}
