import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { MatSelect } from "@angular/material/select";

import { BalanceDirection } from "@bitwarden/common/enums/balanceDirection";
import { RecurringPeriod } from "@bitwarden/common/enums/recurringPeriod";
import { Book } from "@bitwarden/web-vault/app/models/data/blobby/book.data";
import { TransactionDirection } from "@bitwarden/web-vault/app/models/enum/transactionDirection";

@Component({
  selector: "app-select",
  templateUrl: "./select.component.html",
})
export class SelectComponent {
  @Input() labelKey: string;
  @Input() selectedValue: any;
  @Input() selectElementId: string;
  @Input() itemList: Array<any>;
  @Input() valueKey: string;
  @Input() displayKey: string;

  @Output() selectEvent = new EventEmitter();

  @ViewChild("select") select: MatSelect;
  @ViewChild("selectContainer") selectContainer: ElementRef;

  constructor(private renderer: Renderer2) {}

  onSelectionChange(
    selectedValue: string | Book | BalanceDirection | RecurringPeriod | TransactionDirection
  ) {
    this.selectEvent.emit(selectedValue);
  }

  openSelect(): void {
    this.select.open();
  }

  onPanelChange(isOpen: boolean) {
    const selectedContainer = this.selectContainer.nativeElement as HTMLElement;
    if (isOpen) {
      this.renderer.setStyle(selectedContainer, "border-radius", "4px");
    } else {
      this.renderer.setStyle(selectedContainer, "border-radius", "9999px");
    }
  }
}
