import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";

import { I18nService } from "@bitwarden/common/abstractions/i18n.service";
import { PlatformUtilsService } from "@bitwarden/common/abstractions/platformUtils.service";
import { Subscription } from "@bitwarden/web-vault/app/models/data/subscription.data";
import {
  StateManagement,
  StoreState,
} from "@bitwarden/web-vault/app/services/DataService/state-management/state.management";
import { SubscriptionActions } from "@bitwarden/web-vault/app/services/DataService/subscription/state/subscription.action";
import { SubscriptionService } from "@bitwarden/web-vault/app/services/DataService/subscription/subscription.service";
import { StripeConfigService } from "@bitwarden/web-vault/app/services/api/stripe-config.service";

@Component({
  selector: "app-subscription",
  templateUrl: "subscription.component.html",
})
export class SubscriptionComponent extends StateManagement implements OnInit {
  item: Subscription;
  subscription: null;
  buttonLabel: string;
  loading: boolean;
  member: Record<string, any>;
  isPremiumSubscription = false;

  constructor(
    private store: Store<StoreState>,
    private stripeConfigService: StripeConfigService,
    protected platformUtilsService: PlatformUtilsService,
    protected i18nService: I18nService,
    private subscriptionService: SubscriptionService
  ) {
    super();
  }

  async ngOnInit() {
    this.loading = true;
  }

  async ngDoCheck() {
    const userSubscription = this.subscriptionService.getUserSubscription();
    if (!userSubscription) {
      this.subscriptionService.initSubscription();
    }

    if (userSubscription?.metadata.id) {
      this.loading = false;
      this.item = this.subscriptionService.getUserSubscription();
      this.isPremiumSubscription = this.subscriptionService.isPremiumAccount();
    }
  }

  async ngAfterViewChecked() {
    if (this.item) {
      this.toggleButtonLabel();
    }
  }

  toggleButtonLabel() {
    this.buttonLabel = this.isPremiumSubscription ? "Cancel" : "Subscribe";
    return this.buttonLabel;
  }

  private async handleCancel() {
    const isConfirmed = await this.isCancelConfirmed();
    if (isConfirmed) {
      this.store.dispatch(SubscriptionActions.clear());
      this.subscriptionService.setUserSubscription(null);
      this.loading = true;
      await this.stripeConfigService.cancelSubscription(this.item.id);
      this.loadSubscriptionState();
    }
  }

  handleSubscribe() {
    window.location = this.stripeConfigService.gotoSubscriptionPage();
  }

  private loadSubscriptionState() {
    this.store.dispatch(SubscriptionActions.load());
  }

  private async isCancelConfirmed() {
    return await this.platformUtilsService.showDialog(
      this.i18nService.t("cancelSubscriptionConfirmation"),
      this.i18nService.t("cancelSubscription"),
      this.i18nService.t("yes"),
      this.i18nService.t("no"),
      "warning",
      false
    );
  }

  getBillingAmount() {
    return (this.item?.subscription.plan.amount / 100).toFixed(2);
  }
}
