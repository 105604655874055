import { ModelValidator } from "@bitwarden/web-vault/app/models/data/blobby/model.validator";

import {
  AlignmentMappingItem,
  MappingConfigurationItem,
} from "../../../importers/data-mapper/mapping-engine-types";
import { BicType, InstitutionAccount, SwiftType } from "../../types/institution.type";
import { InstitutionResponse } from "../response/institution.response";

export class Institution extends ModelValidator {
  private readonly __v = 1;
  private readonly _id: string;

  constructor(response: InstitutionResponse) {
    super();
    if (response == null) {
      return;
    }
    this.checkVersion(this.__v, response.__v);
    this._id = response.id ? response.id : crypto.randomUUID();
    this._name = response.name;
    this.split = response.split;
    this.csvMapper = response.csvMapper;
    this.balanceAlignmentMapper = response.balanceAlignmentMapper;
    this._swift = response.swift;
    this._bic = response.bic;
    this._availableAccounts = response.availableAccounts;
    this._basiqId = response.basiqId;
  }

  get id(): string {
    return this._id;
  }

  get basiqId(): string {
    return this._basiqId;
  }
  set basiqId(basiqId: string) {
    this._basiqId = basiqId;
  }

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  get swift(): SwiftType {
    return this._swift;
  }

  set swift(value: SwiftType) {
    this._swift = value;
  }

  get bic(): BicType {
    return this._bic;
  }

  set bic(value: BicType) {
    this._bic = value;
  }

  get availableAccounts(): InstitutionAccount[] {
    return this._availableAccounts;
  }

  set availableAccounts(value: InstitutionAccount[]) {
    this._availableAccounts = value;
  }

  split: number;

  /** Name of the Institution*/
  private _name: string;

  /** CSV file mapper for the transaction imports */
  csvMapper: MappingConfigurationItem[];

  /** CSV balance alignment map */
  balanceAlignmentMapper: AlignmentMappingItem[];

  /** swift code of the institution */
  private _swift: SwiftType;

  /** bic : business identifier code of the institution */
  private _bic: BicType;

  /** basiqId : is the id on the institution that is linked here from Basiq */
  private _basiqId: string;

  /** currently available accounts in the institution
   * TODO : if need be create a nonAvailableAccounts as well.  Just in case we need to keep track of previous data
   * */
  private _availableAccounts: InstitutionAccount[];
}
