// todo create our own implementation of rounding with precision and drop the need of mathjs
// import * as math from "mathjs";
/**
 * Gloss number will be used throughout to system to represent a quantity or an amount.
 * It makes sense that most numbers need to be tied to a symbol be that a quantity or a
 * currency amount and the GlossNumber is how we will keep that consistent across the
 * Gloss.
 * This will allow us to handle Balances and summations more elegantly in a more
 * uniform manner.
 */
export class GlossNumberUtils {
  static add(n1: IGlossNumber, n2: IGlossNumber) {
    // check that the symbols are the same
    if (n1.symbol === n2.symbol) {
      n1.amount = n1.amount + n2.amount;
      // Note: removed precision for now to see if it helps the javascript decimal error
      // this.applyPrecision();
    } else {
      // throw Error("Cannot add two Gloss Numbers which have different symbols");
    }
  }

  static copy(n: IGlossNumber): GlossNumber {
    const copyGlossNum = new GlossNumber();
    GlossNumberUtils.copyFrom(copyGlossNum, n);
    return copyGlossNum;
  }

  static copyFrom(dst: IGlossNumber, src: IGlossNumber): void {
    dst.amount = src.amount;
    dst.symbol = src.symbol;
    dst.precision = src.precision;
  }
}

export class IGlossNumber {
  amount: number;
  symbol: string;
  precision: number;
}

export class GlossNumber {
  amount: number;
  symbol: string;
  precision: number;

  constructor() {
    this.setDefaults();
  }

  // TODO: remove this
  // get amount(): number {
  // return math.round(this._amount == null ? 0 : this._amount, this.precision);
  // }

  // TODO: remove this
  // set amount(value: number) {
  // this._amount = value;
  // }

  /**
   * Sets the default values for the GlossNumber
   * @private
   */
  private setDefaults() {
    this.amount = 0;
    this.precision = 8;
  }

  /**
   * Get the default precision for this symbol and set this as the precision for this gloss number
   * @param symbol
   */
  setDefaultPrecision() {
    if (this.symbol) {
      // get the precision for the symbol
      const precision = 8;
      // TODO: fix this when the symbolService is up and running correctly with precisions
      // const precision = symbolService.getPrecision(this._symbol)
      this.precision = precision;
    }
  }

  /**
   * Updated the applyPrecision function to be for display as opposed to number storage.
   * Need to decide if we save to a finite number of decimal places once we overhaul glossNumber
   * to use the BigNumber library
   * @private
   */
  private applyPrecisionForDisplay() {
    this.amount = Number(this.amount.toFixed(this.precision));
  }

  setToGlossNumberObj(response: Record<string, any> | GlossNumber) {
    return response instanceof GlossNumber
      ? this.setFromObject(response)
      : this.setFromQueryString(response);
  }

  setFromObject(glossNumber: GlossNumber) {
    return glossNumber;
  }

  setFromQueryString(response: Record<string, any>) {
    if (response.amount || response._amount) {
      this.amount = response.amount || response._amount;
    }
    if (response.symbol || response._symbol) {
      this.symbol = response.symbol || response._symbol;
    }
    return this;
  }

  add(additionalAmount: GlossNumber) {
    GlossNumberUtils.add(this, additionalAmount);
  }

  subtract(subAmount: GlossNumber) {
    // check that the symbols are the same
    if (this.symbol === subAmount.symbol) {
      this.amount = this.amount - subAmount.amount;
      // Note: removed precision for now to see if it helps the javascript decimal error
      // this.applyPrecision();
    } else {
      return new Error("Cannot add two Gloss Numbers which have different symbols");
    }
  }

  copyFrom(copy: GlossNumber) {
    GlossNumberUtils.copyFrom(this, copy);
  }

  copy(): GlossNumber {
    return GlossNumberUtils.copy(this);
  }
}
