export class TransactionColumnSortable {
  private _accountName: string;
  private _productName: string;
  private _institutionName: string;
  private _amountIn: number;
  private _amountOut: number;
  private _aggregateAmount: number;

  get accountName(): string {
    return this._accountName;
  }

  set accountName(value: string) {
    if (value) {
      this._accountName = value;
    }
  }

  get productName(): string {
    return this._productName;
  }

  set productName(value: string) {
    this._productName = value;
  }

  get institutionName(): string {
    return this._institutionName;
  }

  set institutionName(value: string) {
    this._institutionName = value;
  }

  get amountIn(): number {
    return this._amountIn;
  }

  set amountIn(value: number) {
    this._amountIn = value;
  }

  get amountOut(): number {
    return this._amountOut;
  }

  set amountOut(value: number) {
    this._amountOut = value;
  }

  get aggregateAmount(): number {
    return this._aggregateAmount;
  }

  set aggregateAmount(value: number) {
    this._aggregateAmount = value;
  }
}
