export enum TransactionType {
  Charge = 0,
  Credit = 1,
  PromotionalCredit = 2,
  ReferralCredit = 3,
  Refund = 4,
}

export enum TransactionStatusEnum {
  opening = "opening",
  transaction = "transaction",
  alignment = "alignment",
  fake = "fake",
  closing = "closing",
  pending = "pending",
}
export enum AmountTypeEnum {
  regular = "regular",
  reverse = "reverse",
}
