<ng-container *ngIf="vm$ | async as vm">
  <div class="page-content">
    <div class="container">
      <div class="stepper-wrapper">
        <div
          class="stepper-item"
          [ngClass]="{
            'active-step': vm.importFlow?.step === ImportStepsEnum.institution,
            completed: competedSteps.includes(ImportStepsEnum.institution)
          }"
        >
          <div class="step-counter">1</div>
          <div class="step-name">{{ ImportStepsEnum.institution | i18n }}</div>
        </div>
        <div
          class="stepper-item"
          [ngClass]="{
            'active-step': vm.importFlow?.step === ImportStepsEnum.upload,
            completed: competedSteps.includes(ImportStepsEnum.upload)
          }"
        >
          <div class="step-counter">2</div>
          <div class="step-name">{{ ImportStepsEnum.upload | i18n }}</div>
        </div>
        <div
          class="stepper-item"
          [ngClass]="{
            'active-step': vm.importFlow?.step === ImportStepsEnum.arrange,
            completed: competedSteps.includes(ImportStepsEnum.arrange)
          }"
        >
          <div class="step-counter">3</div>
          <div class="step-name">{{ ImportStepsEnum.arrange | i18n }}</div>
        </div>

        <div class="stepper-item" [ngClass]="{ 'active-step': step === 'import' }">
          <div class="step-counter">4</div>
          <div class="step-name">{{ "import" | i18n }}</div>
        </div>
      </div>
    </div>

    <div *ngIf="vm.importFlow?.step === ImportStepsEnum.institution" class="container">
      <div class="select-container" *ngIf="vmInstitution$ | async as vmInstitution">
        <span class="section-info">{{ "selectInstitutionInfo" | i18n }}</span>
        <mat-form-field>
          <mat-select
            [(value)]="vmInstitution.selectedInstitution"
            (selectionChange)="onInstitutionSelected($event)"
          >
            <mat-option *ngFor="let option of vmInstitution.institutions" [value]="option">
              {{ option.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="upload-step-btn-container">
        <button mat-raised-button color="accent" (click)="goBackToMainImportSection()">
          {{ "previous" | i18n }}
        </button>
        <button
          class="next-button"
          mat-raised-button
          color="primary"
          (click)="this.createNewInstitution()"
        >
          <span>{{ "createNewInstitution" | i18n }}</span>
        </button>
        <button
          class="next-button"
          mat-raised-button
          color="primary"
          (click)="this.gotToUpload($event)"
        >
          <span>{{ "next" | i18n }}</span>
        </button>
      </div>
    </div>

    <div class="container" *ngIf="vm.importFlow?.step === ImportStepsEnum.upload">
      <div class="buttons-overlay" *ngIf="this.showSpinner">
        <app-spinner [showSpinner]="true" [diameter]="25"></app-spinner>
      </div>
      <form #form ngNativeValidate>
        <div class="row">
          <div>
            <span class="section-info">{{ "uploadSectionInfo" | i18n }}</span>
            <div class="form-group">
              <br />
              <div class="file-selector" *ngIf="vmUpload$ | async as vmUpload">
                <button
                  type="button"
                  mat-stroked-button
                  color="basic"
                  (click)="fileSelector.click()"
                >
                  {{ "chooseFile" | i18n }}
                </button>
                {{ vmUpload.file ? vmUpload.file.name : ("noFileChosen" | i18n) }}
              </div>
              <input
                #fileSelector
                type="file"
                id="file"
                class="form-control-file"
                name="file"
                (change)="setSelectedFile($event)"
                accept=".csv"
                hidden
                multiple
              />
            </div>
          </div>
        </div>
        <div class="upload-step-btn-container">
          <button mat-raised-button color="accent" (click)="goBAckToInstitution()">
            {{ "previous" | i18n }}
          </button>
          <button
            class="next-button"
            mat-raised-button
            color="primary"
            (click)="this.gotToArrangement($event)"
          >
            <span>{{ "next" | i18n }}</span>
          </button>
        </div>
      </form>
    </div>
    <div *ngIf="vm.importFlow?.step === ImportStepsEnum.arrange">
      <ng-container *ngIf="vmUpload$ | async as vmUpload">
        <app-arrange-columns-table
          [records]="records"
          (goToPreview)="goToPreview($event)"
        ></app-arrange-columns-table>
      </ng-container>
    </div>
    <div *ngIf="vm.importFlow?.step === ImportStepsEnum.import">
      <app-preview-transaction-table
        (closeTable)="handleCloseTable()"
        (backToArrange)="goBackToArrange()"
      ></app-preview-transaction-table>
    </div>
  </div>
</ng-container>
