<div class="autocomplete-container">
  <label class="autocomplete-label">{{ labelKey | i18n }}</label>
  <mat-form-field appearance="fill" class="autocomplete-form-field" floatLabel="always">
    <input
      (click)="openDatePicker()"
      autocomplete="off"
      class="autocomplete-input"
      type="text"
      matInput
      [name]="controlName"
      [id]="inputId"
      [(ngModel)]="dateValue"
      [matDatepicker]="picker"
      (ngModelChange)="onDateChange()"
      [disabled]="isDisabled"
      [max]="maxDate"
    />
  </mat-form-field>
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker #picker></mat-datepicker>
</div>
