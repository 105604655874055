import { Book } from "@bitwarden/web-vault/app/models/data/blobby/book.data";
import { Transaction } from "@bitwarden/web-vault/app/models/data/blobby/transaction.data";

import { TransactionFilterResponse } from "./response/transaction-filter.response";

export class TransactionFilter {
  private readonly __v = 1;
  private readonly _id: string;
  symbol: string;
  private _accounts: Book[];
  private _transactions: Transaction[];
  get id(): string {
    return this._id;
  }

  get accounts(): Book[] {
    return this._accounts;
  }

  set accounts(accounts: Book[]) {
    this._accounts = accounts;
  }

  get transactions(): Transaction[] {
    return this._transactions;
  }

  set transactions(transactions: Transaction[]) {
    this._transactions = transactions;
  }

  constructor(response: TransactionFilterResponse) {
    if (response == null) {
      return;
    }

    this._id = response.id ? response.id : crypto.randomUUID();
    this.symbol = response.symbol;
    this._accounts = response.accounts;
    this._transactions = response.transactions;
  }
}
