import { StripeConfigType } from "@bitwarden/web-vault/app/models/types/environement-config.type";

const stripeConfig = process.env.STRIPE as unknown as StripeConfigType;

export class StripeConfigData {
  private readonly __v = 1;

  private _url: string;
  private _redirectUrl: string;
  config: Record<string, any>;

  constructor() {
    this.config = stripeConfig;
    this.url = `${stripeConfig.url}/${stripeConfig.apiStage}`;
    this._redirectUrl = stripeConfig.redirectUrl;
  }

  get url(): string {
    return this._url;
  }

  set url(value: string) {
    this._url = value;
  }

  get redirectUrl(): string {
    return this._redirectUrl;
  }
}
