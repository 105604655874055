import { GlossEncryptedDataType } from "@bitwarden/web-vault/app/models/enum/glossEncryptedDataType";

import { BlobbyDataTypeEnum } from "../../models/enum/blobbyDataTypeEnum";

type BlobbyData = { id: string };

class BlobbyDataMemoryStorage<T extends BlobbyData> {
  private store = new Map<string, T>();
  type: BlobbyDataTypeEnum;

  constructor(type: BlobbyDataTypeEnum) {
    this.type = type;
  }

  index(data: T[]) {
    for (const item of data) {
      if (item.id) {
        this.store.set(item.id, item);
      }
    }
  }

  delete(item: T) {
    return this.store.delete(item.id);
  }

  values() {
    return Array.from(this.store.values());
  }

  clear() {
    this.store.clear();
  }

  get size() {
    return this.store.size;
  }
}

class BlobbyDataMemoryManager {
  private stores = new Map<string, BlobbyDataMemoryStorage<BlobbyData>>();

  protected set(store: BlobbyDataMemoryStorage<BlobbyData>) {
    this.stores.set(store.type, store);
  }

  size(type: BlobbyDataTypeEnum | null): number {
    return type ? this.get(type)?.size : this.stores.size;
  }

  clear() {
    this.stores.forEach((store) => store.clear());
  }

  protected get(type: BlobbyDataTypeEnum): BlobbyDataMemoryStorage<BlobbyData> {
    return this.stores.get(type);
  }
}

export class BlobbyMemoryStorage extends BlobbyDataMemoryManager {
  constructor() {
    super();
    this.set(new BlobbyDataMemoryStorage(BlobbyDataTypeEnum.Book));
    this.set(new BlobbyDataMemoryStorage(BlobbyDataTypeEnum.Classification));
    this.set(new BlobbyDataMemoryStorage(BlobbyDataTypeEnum.Institution));
    this.set(new BlobbyDataMemoryStorage(BlobbyDataTypeEnum.Category));
    this.set(new BlobbyDataMemoryStorage(BlobbyDataTypeEnum.Estimate));
    this.set(new BlobbyDataMemoryStorage(BlobbyDataTypeEnum.Symbol));
    this.set(new BlobbyDataMemoryStorage(BlobbyDataTypeEnum.Transaction));
    this.set(new BlobbyDataMemoryStorage(BlobbyDataTypeEnum.ReferenceData));
    this.set(new BlobbyDataMemoryStorage(BlobbyDataTypeEnum.Preference));
    this.set(new BlobbyDataMemoryStorage(BlobbyDataTypeEnum.SourceTransaction));
    this.set(new BlobbyDataMemoryStorage(BlobbyDataTypeEnum.SourceBook));
    this.set(new BlobbyDataMemoryStorage(BlobbyDataTypeEnum.Scenario));
    this.set(new BlobbyDataMemoryStorage(BlobbyDataTypeEnum.ScenarioGroup));
    this.set(new BlobbyDataMemoryStorage(BlobbyDataTypeEnum.EstimateGroup));
    this.set(new BlobbyDataMemoryStorage(BlobbyDataTypeEnum.SourceCategory));
    this.set(new BlobbyDataMemoryStorage(BlobbyDataTypeEnum.Connector));
    this.set(new BlobbyDataMemoryStorage(BlobbyDataTypeEnum.VaultFile));
    this.set(new BlobbyDataMemoryStorage(BlobbyDataTypeEnum.UserLocation));
  }

  getContext() {
    return {
      categories: this.size(BlobbyDataTypeEnum.Category),
      institutions: this.size(BlobbyDataTypeEnum.Institution),
      accounts: this.size(BlobbyDataTypeEnum.Book),
      transactions: this.size(BlobbyDataTypeEnum.Transaction),
      references: this.size(BlobbyDataTypeEnum.ReferenceData),
      classifications: this.size(BlobbyDataTypeEnum.Classification),
      connectors: this.size(BlobbyDataTypeEnum.Connector),
      vaultFiles: this.size(BlobbyDataTypeEnum.VaultFile),
    };
  }

  getAll(type: BlobbyDataTypeEnum) {
    const store = this.get(type);
    if (!store) {
      throw new Error(`Unsupported type ${type}`);
    }
    return store.values();
  }

  indexBramByType(type: BlobbyDataTypeEnum, data: GlossEncryptedDataType[]) {
    this.get(type)?.index(data);
  }

  deleteItem(type: BlobbyDataTypeEnum, item: GlossEncryptedDataType) {
    this.get(type)?.delete(item);
  }
}
