import { Injectable, Injector } from "@angular/core";

import { LogService } from "@bitwarden/common/abstractions/log.service";
import { ConsoleLogService } from "@bitwarden/common/services/consoleLog.service";
import { NormalizeTransaction } from "@bitwarden/web-vault/app/services/DataCalculationService/normalize/normalizeTransaction";
import { ReferenceDataService } from "@bitwarden/web-vault/app/services/DataService/reference-data/reference-data.service";
import { StateManagement } from "@bitwarden/web-vault/app/services/DataService/state-management/state.management";

import { Transaction } from "../../../models/data/blobby/transaction.data";
import { PreferenceService } from "../../DataService/preference/preference.service";
import { CalculationServiceAbstraction } from "../calculation.service.abstraction";

@Injectable({
  providedIn: "root",
})
export class TransactionNormalizeService
  extends StateManagement
  implements CalculationServiceAbstraction
{
  private _baseCurrency: string;
  protected logService: LogService = new ConsoleLogService(false);
  // preferenceSetting$: Observable<Preference | boolean>;
  private preferenceService: PreferenceService;
  private injector: Injector;
  private normalizeTransaction: NormalizeTransaction;
  referenceDataService: ReferenceDataService;

  constructor(injector: Injector) {
    super();
    this.injector = injector;
    this.preferenceService = this.injector.get(PreferenceService);
    this.referenceDataService = this.injector.get(ReferenceDataService);
  }

  private async getBaseCurrency(): Promise<void> {
    /*   const preferences  = <Preference | false>this.getState(this.preferenceSetting$);
    if(preferences){
      this._baseCurrency = preferences.baseCurrency;
    }*/
    // TODO: get the base currency from ngrx when preferences are stored correctly
    // don't cache the base currency as this might cause issues with a stale base currency
    // after setting it
    const prefBaseCurrency = await this.preferenceService.get("baseCurrency");
    if (prefBaseCurrency) {
      if (typeof prefBaseCurrency === "string" && this._baseCurrency !== prefBaseCurrency) {
        this._baseCurrency = prefBaseCurrency;
      }
    }
  }

  private async setupNormalizeTransactionClass() {
    if (!this._baseCurrency) {
      await this.getBaseCurrency();
    }

    if (!this.normalizeTransaction) {
      this.normalizeTransaction = new NormalizeTransaction(
        this._baseCurrency,
        this.logService,
        null,
        this.referenceDataService
      );
    }
  }

  async normalizeImportedTransaction(transaction: Transaction): Promise<void> {
    await this.setupNormalizeTransactionClass();
    // logic moved to NormalizeTransaction class when web workers were introduced
    return await this.normalizeTransaction.normalizeImportedTransaction(transaction);
  }

  // TODO: this method might be removed if we no longer need to call it once state management is fully implemented
  /*
  async processNormalizeImportedTransaction(finalisedTransactions: Transaction[]) {
    this.perfService.mark("TransactionNormalizeService::processNormalizeImportedTransaction");

    let shoulCallNormalize = false;
    const startDate = await this.marketDataService.getFirstTransactionDate(finalisedTransactions);
    const accounts = await this.bookService.getBooks();
    const currencies = await this.marketDataService.getSymbolsInTheSystem(
      finalisedTransactions,
      accounts
    );

    const systemHasAllNeededReferences = await this.marketDataService.isDataUpToDate(
      startDate,
      currencies
    );

    if (!systemHasAllNeededReferences) {
      await this.marketDataService.importCurrencyRates(
        startDate,
        fnsSubDays(new Date(), 1),
        currencies
      );
      shoulCallNormalize = true;
    }

    for (const finalisedTransaction of finalisedTransactions) {
      await this.normalizeImportedTransaction(finalisedTransaction);
      // TODO: @sinan - not sure why we were setting the convsym here before if it was not set.
      // It should be empty on import if there is no conversion from a symbol to a currency with a supplied rate
      // Have switched this to currency in case we are trying to catch transactions where currency is not set correctly
      // from the account creation
      if (!finalisedTransaction.quantity.currency) {
        const transactionAccount = await this.bookService.get(finalisedTransaction.accountId);
        finalisedTransaction.quantity.currency = transactionAccount.currency;
        await this.normalizeImportedTransaction(finalisedTransaction);
      } else if (shoulCallNormalize) {
        await this.normalizeImportedTransaction(finalisedTransaction);
      }
    }
    this.perfService.markEnd();
  }
   */
}
