import { Injectable, Injector } from "@angular/core";
import { Observable } from "rxjs";

import { BasiqAuth } from "@bitwarden/web-vault/app/importers/importer.auth.basiq";
import {
  AccountSyncStatus,
  GlossConnectorMessage,
  Origin,
} from "@bitwarden/web-vault/app/models/types/general-types";

import { InstitutionGroup } from "../../models/types/institution.type";
import { AccountView } from "../../models/view/account.view";

import { BasiqInstitutionConnector } from "./basiq-institution-connector";

@Injectable({
  providedIn: "root",
})
export class BasiqConnector {
  id: string;
  accountViews: AccountView[];
  institutionConnectors: BasiqInstitutionConnector[] = [];
  institutionConnectorListeners: Observable<AccountSyncStatus[]>[] = [];
  origin = Origin.basiq;

  private authService: BasiqAuth;

  constructor(accountViews: AccountView[], private injector: Injector) {
    this.id = crypto.randomUUID();
    this.authService = this.injector.get(BasiqAuth);
    this.accountViews = accountViews;
    this.setInstitutionConnections();
  }

  //TODO make this come from interface so Plaid can have the same method
  async isConnectorAvailable(): Promise<GlossConnectorMessage> {
    try {
      const isConsent = await this.authService.isUserConsent();
      if (!isConsent) {
        return {
          message: "We need your consent to continue",
          connectorOrigin: Origin.basiq,
          isOpen: false,
          key: "consent-error",
          actions: ["consent"],
        };
      }

      return {
        message: "Connector is available",
        connectorOrigin: Origin.basiq,
        isOpen: true,
        key: "connector-available",
        actions: [],
      };
    } catch (error) {
      return {
        message: "Something went wrong",
        connectorOrigin: Origin.basiq,
        isOpen: false,
        key: "consent-error",
        actions: ["retry", "consent"],
      };
    }
  }

  private getGroupOfInstitutions(): InstitutionGroup[] {
    const sortedAccountViews = this.accountViews.sort((a: AccountView, b: AccountView) =>
      a.name.localeCompare(b.name)
    );
    return sortedAccountViews.reduce((grouped: InstitutionGroup[], accountView) => {
      const key = accountView.institution.basiqId;
      const group = grouped.find((g) => g.institutionId === key);
      if (!group) {
        grouped.push({ institutionId: key, accountViews: [accountView] });
      } else {
        group.accountViews.push(accountView);
      }
      return grouped;
    }, []);
  }

  setInstitutionConnections() {
    const groupOfInstitutions = this.getGroupOfInstitutions();

    for (const institutionGroup of groupOfInstitutions) {
      const institutionConnector = new BasiqInstitutionConnector(institutionGroup, this.injector);
      this.institutionConnectors.push(institutionConnector);
      this.institutionConnectorListeners.push(institutionConnector.syncStatus$);
    }
  }
}
