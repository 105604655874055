<button
  class="tw-flex tw-w-full tw-flex-col tw-gap-4 tw-rounded-xl tw-border-0 tw-bg-neutral-50 tw-p-5 tw-text-left tw-text-base tw-font-semibold tw-text-neutral-800 tw-shadow-button md:tw-h-auto md:tw-flex-row-reverse md:tw-justify-between"
  [ngClass]="{ 'tw-opacity-40': !isEnabled, 'tw-h-[233px]': description }"
  (click)="handleClick()"
  tabindex="-1"
>
  <ng-content></ng-content>
  <div>
    <span> {{ title }} </span>
    <span
      class="tw-mt-2 tw-block tw-text-left tw-text-sm tw-font-normal tw-leading-5 tw-tracking-wider tw-text-neutral-700"
    >
      {{ description }}
    </span>
  </div>
</button>
