import { Injector } from "@angular/core";
import * as d3 from "d3";
import { differenceInDays, format } from "date-fns";
import { DeviceDetectorService } from "ngx-device-detector";

import { DateTimeFormatter } from "@bitwarden/web-vault/app/components/primary-summary-graph/graph-elements/date-time-formatter";
import {
  BandData,
  BandDataSet,
  GraphDataSet,
} from "@bitwarden/web-vault/app/models/types/graph.types";
import { ScenarioData } from "@bitwarden/web-vault/app/models/types/scenario-group.types";
import { BlobbyUtils } from "@bitwarden/web-vault/app/services/blobby/blobbyUtils";
import { DashboardService } from "@bitwarden/web-vault/app/services/dashboard/dashboard-service";

export class Slider {
  private graphContentGroup: any;
  private dateTimeFormatter: DateTimeFormatter;
  xScale: d3.ScaleBand<string>;
  yScale: d3.ScaleLinear<number, number, never>;
  sliderHeight = 10;
  private brushGroup: d3.Selection<SVGSVGElement, unknown, HTMLElement, any>;
  private xScaleSlider: d3.ScaleLinear<number, number, never>;
  private brush: d3.BrushBehavior<any>;
  private brushHandle: (g: any, selection: any, fill?: boolean) => void;
  private dashboardService: DashboardService;
  private bandData: BandData;
  private deviceDetectorService: DeviceDetectorService;

  constructor(
    graphContentGroup: any,
    dateTimeFormatter: DateTimeFormatter,
    xScale: d3.ScaleBand<string>,
    yScale: d3.ScaleLinear<number, number, never>,
    dashboardService: DashboardService,
    injector: Injector
  ) {
    this.graphContentGroup = graphContentGroup;
    this.dateTimeFormatter = dateTimeFormatter;
    this.xScale = xScale;
    this.yScale = yScale;
    this.dashboardService = dashboardService;
    this.deviceDetectorService = injector.get(DeviceDetectorService);
    if (this.deviceDetectorService.isMobile()) {
      this.sliderHeight = 0;
    }
  }

  // <editor-fold desc="Public Functions">
  /**
   * combineDataForBands - this function combines the data from the balance line with the
   * data from the different scenarios to be used for the slider and the tooltip. Maps the band
   * number to any data required for user interaction.
   * @param graphData
   * @param scenarioData
   */
  combineDataForBands(graphData: Array<GraphDataSet>, scenarioData?: ScenarioData): BandData {
    const combinedBands: BandData = graphData.map((dataSet) => {
      const dateMonth = this.dateTimeFormatter.formatDisplayDate(
        this.dateTimeFormatter.timeParse(dataSet.date)
      );
      const balanceArray = [{ display: dateMonth, balance: dataSet.balance }];
      return {
        endDate: dataSet.endDate,
        date: dataSet.date,
        midDate: dataSet?.midDate ? dataSet.midDate : null,
        nextStartDate: dataSet.nextStartDate,
        highestBalance: dataSet.balance,
        balances: balanceArray,
        balanceData: "",
      };
    });

    if (scenarioData && scenarioData?.scenario.length > 0) {
      let i = 1;
      for (const scenario of scenarioData.scenario) {
        for (const dataSet of scenario.graphData) {
          // check if the date is already in combinedBands
          const bandDefinition = combinedBands.find(
            (band) =>
              band.date === dataSet.date ||
              band.endDate === dataSet.endDate ||
              this.dateTimeFormatter.formatDisplayDate(
                this.dateTimeFormatter.timeParse(dataSet.date)
              ) ===
                this.dateTimeFormatter.formatDisplayDate(
                  this.dateTimeFormatter.timeParse(band.date)
                )
          );
          // if the band doesn't exist yet, then add it to the list
          if (bandDefinition === undefined) {
            const balanceArray = [{ display: "Scenario " + i, balance: dataSet.balance }];
            combinedBands.push({
              endDate: dataSet.endDate,
              date: dataSet.date,
              midDate: dataSet?.midDate ? dataSet.midDate : null,
              nextStartDate: dataSet.nextStartDate,
              highestBalance: dataSet.balance,
              balances: balanceArray,
              balanceData: "",
            });
          } else {
            // if the enddate is after the previous enddate for the same dataSet, then use the later enddate
            if (dataSet.endDate > bandDefinition.endDate) {
              bandDefinition.endDate = dataSet.endDate;
            }
            // update the balance if the balance of the scenario is higher
            if (dataSet.balance > bandDefinition.highestBalance) {
              bandDefinition.highestBalance = dataSet.balance;
            }
            // add the scenario balance to the array
            const balanceData = { display: "Scenario " + i, balance: dataSet.balance };
            bandDefinition.balances.push(balanceData);
          }
        }
        i++;
      }
    }

    // Order the balances array in each band and then create the bandData string
    for (const band of combinedBands) {
      band.balances.sort(this.sortBalances);
      band.balanceData = this.combineBalances(band.balances);
    }

    /*
    if (scenarioData?.anchorPoint && scenarioData.anchorPoint !== null) {
      const bandDefinition = combinedBands.find(
        (band) =>
          this.dateTimeFormatter.timeParse(band.date) <= new Date(scenarioData.anchorPoint.anchorDate) &&
          this.dateTimeFormatter.timeParse(band.endDate) >= new Date(scenarioData.anchorPoint.anchorDate)
      );
      if (bandDefinition) {
        bandDefinition.balanceData += "Anchor Balance<br/>";
        const anchorDate = scenarioData.anchorPoint.anchorDate;
        bandDefinition.balanceData += anchorDate + ": $" + scenarioData.anchorPoint.anchorBalance.toFixed(2) + "<br/>";
      }
    }
     */

    return combinedBands;
  }

  drawDateSlider(innerWidth: number, innerHeight: number, graphData: BandData) {
    this.bandData = graphData;
    this.brushGroup = this.graphContentGroup
      .append("g")
      .attr("class", "brush")
      .call(this.createBrush(innerWidth, innerHeight, this.sliderHeight));

    const initialDatePoints = this.snap([0, innerWidth], innerWidth).graph;
    this.brushGroup.call(this.brush.move, initialDatePoints);
    this.brushGroup.call(this.createBrushHandle(innerHeight), initialDatePoints);
  }

  updateDateSlider(innerWidth: number, graphData: BandData) {
    // TODO: redo the slider with the new scale
    this.bandData = graphData;
    const initialDatePoints = this.snap([0, innerWidth], innerWidth).graph;
    this.brushGroup.call(this.brush.move, initialDatePoints);
    this.brushGroup.call(this.brushHandle, initialDatePoints);
  }

  /**
   * Given a set of co-ords on the graph, work out which band we have clicked in.
   * This is so that we can snap the slider and brushes, find the hud data and filter
   * the transactions in the table by dates
   * @param selection
   * @param innerWidth
   * @param graphData
   */
  snap(selection: Array<number>, innerWidth: number) {
    const start = selection[0];
    let end = null;
    if (selection.length > 1) {
      end = selection[1];
    }

    const numberBands = this.xScale.domain().length;
    const fullBandWidth = innerWidth / (this.xScale.padding() + numberBands);

    let startBand;
    let endBand;

    // snap the start
    startBand = Math.floor((start - fullBandWidth / 2) / fullBandWidth);
    const startLeftOver = (start - fullBandWidth / 2) % fullBandWidth;

    // if we pass the mid-point in the band then snap up unless there is a midpoint
    if (!this.bandData[startBand]?.midDate && startLeftOver > fullBandWidth / 2) {
      startBand = startBand + 1;
    }

    // if the end is supplied then we need to snap it
    if (end) {
      // snap the end
      endBand = Math.floor((end - fullBandWidth / 2) / fullBandWidth);
      const endLeftOver = (end - fullBandWidth / 2) % fullBandWidth;

      // if past the mid-point in the band then snap up to the next band
      if (endLeftOver > fullBandWidth / 2) {
        endBand = endBand + 1;
      } else if (this.bandData[endBand + 1]?.midDate && endLeftOver > 0) {
        endBand = endBand + 1;
      }

      // if the start band is left of the lowest band then set to the minimum
      if (startBand < 0) {
        startBand = 0;
      }
      // if the end band is left of the first band then snap to the end of the first band
      if (endBand < 0) {
        endBand = 1;
      }
      // if the end band is to the right of the last band then snap to the right most band
      if (endBand > this.bandData.length - 1) {
        endBand = this.bandData.length - 1;
      }
      //  if the bands are the same for start and end then we need to perform a flip
      if (endBand === startBand) {
        if (startBand == 0) {
          endBand = 1;
        } else if (endBand == this.bandData.length - 1) {
          startBand = endBand - 1;
        } else if (startBand >= endBand) {
          endBand = endBand + 1;
        } else if (startLeftOver > fullBandWidth / 2 && endBand != this.bandData.length - 1) {
          startBand = startBand - 1;
        } else if (endLeftOver && endBand != 1) {
          endBand = endBand + 1;
        }
      }

      startBand = Math.max(0, startBand);
      startBand = Math.min(this.bandData.length - 1, startBand);
      endBand = Math.min(this.bandData.length - 1, endBand);
    } else {
      // if no end parameter then set the max and min of the date
      startBand = Math.max(0, startBand);
      startBand = Math.min(this.bandData.length - 1, startBand);
      endBand = startBand;
    }

    // get the dates and co-ordinates based on the band
    let newStartDate;
    let newEndDate;
    let newStart;
    let nextStartDate;
    let newEnd;
    if (end) {
      newStartDate = this.bandData[startBand].date;
      newEndDate = this.bandData[endBand].endDate;
      nextStartDate = this.bandData[startBand].nextStartDate;

      newStart =
        this.xScale(
          this.dateTimeFormatter.formatYearDate(this.dateTimeFormatter.timeParse(newStartDate))
        ) +
        this.xScale.bandwidth() / 2;
      newEnd =
        this.xScale.bandwidth() / 2 +
        this.xScale(
          this.dateTimeFormatter.formatYearDate(this.dateTimeFormatter.timeParse(newEndDate))
        );

      if (this.bandData[endBand]?.midDate && this.bandData[endBand].midDate) {
        if (endBand === this.bandData.length - 1) {
          newEndDate = format(this.bandData[endBand].midDate, "yyyyMMdd");
          newEnd = this.calculateMiddleX(this.bandData[endBand], this.bandData[endBand].midDate);
        }
      }
      if (this.bandData[startBand]?.midDate && this.bandData[startBand].midDate) {
        newStartDate = format(this.bandData[startBand].midDate, "yyyyMMdd");
        newStart = this.calculateMiddleX(
          this.bandData[startBand + 1],
          this.bandData[startBand].midDate
        );
      }
    } else {
      if (this.bandData[startBand]?.midDate && this.bandData[startBand].midDate) {
        if (startBand === this.bandData.length - 1) {
          newStartDate = format(this.bandData[startBand].midDate, "yyyyMMdd");
          newStart = this.calculateMiddleX(
            this.bandData[startBand],
            this.bandData[startBand].midDate
          );
        } else {
          newStartDate = this.bandData[startBand].midDate;
          newStart = this.calculateMiddleX(
            this.bandData[startBand + 1],
            this.bandData[startBand].midDate
          );
        }
      } else {
        newStartDate = this.bandData[startBand].endDate;
        newStart =
          this.xScale(
            this.dateTimeFormatter.formatYearDate(this.dateTimeFormatter.timeParse(newStartDate))
          ) +
          this.xScale.bandwidth() / 2;
      }
      newEndDate = newStartDate;
      nextStartDate = newStartDate;
      newEnd = newStart;
    }

    const graphReturnData: [number, number] = [newStart, newEnd];

    const returnData = {
      graph: graphReturnData, // used to align the slider and brushes
      dates: [newStartDate, newEndDate, nextStartDate], // used to filter the transactions in the table
      bands: [startBand, endBand], // used to pick the data for the hud from the graphData
    };

    return returnData;
  }

  findBandIndex(date: Date): number {
    const anchorBandIndex = this.bandData.findIndex((band) => {
      return (
        this.dateTimeFormatter.timeParse(band.date).getTime() <=
          new Date(date.toDateString()).getTime() &&
        this.dateTimeFormatter.timeParse(band.endDate).getTime() >=
          new Date(date.toDateString()).getTime()
      );
    });
    return anchorBandIndex;
  }

  findBand(date: Date): BandDataSet {
    const anchorBand = this.bandData.find((band) => {
      return (
        this.dateTimeFormatter.timeParse(band.date).getTime() <=
          new Date(date.toDateString()).getTime() &&
        this.dateTimeFormatter.timeParse(band.endDate).getTime() >=
          new Date(date.toDateString()).getTime()
      );
    });
    return anchorBand;
  }

  calculateMiddleX(middleBand: BandDataSet, middleDate: Date): number {
    const bandStartDate = middleBand.date;
    const nextBandStartDate = middleBand.nextStartDate;

    const bandDiff = differenceInDays(
      this.dateTimeFormatter.timeParse(nextBandStartDate),
      this.dateTimeFormatter.timeParse(bandStartDate)
    );

    // get the days between bands
    const pointDiff = differenceInDays(middleDate, this.dateTimeFormatter.timeParse(bandStartDate));

    // calculate a rough percentage
    const diffPercentage = pointDiff / bandDiff;

    let pointBuffer;
    // how far along to plot the anchor point in the band
    if (diffPercentage === 0) {
      pointBuffer = this.xScale.bandwidth() * 0.8;
    } else if (diffPercentage < 0.3) {
      pointBuffer = this.xScale.bandwidth() * 0.25;
    } else if (diffPercentage < 0.7) {
      pointBuffer = this.xScale.bandwidth() * 0.5;
    } else {
      pointBuffer = this.xScale.bandwidth() * 0.75;
    }

    // Return the x position part way through the band
    const x =
      this.xScale.bandwidth() / 2 +
      this.xScale(
        this.dateTimeFormatter.formatYearDate(this.dateTimeFormatter.timeParse(bandStartDate))
      ) -
      this.xScale.bandwidth() +
      pointBuffer;

    return x;
  }

  // </editor-fold desc="Public Functions">

  // <editor-fold desc="Private Functions">
  private createBrush(innerWidth: number, innerHeight: number, sliderHeight: number): any {
    const brush = d3
      .brushX()
      .extent([
        [0, innerHeight - sliderHeight / 2],
        [innerWidth, innerHeight + sliderHeight / 2],
      ])
      .handleSize(10)
      .on("start brush end", (brushEvent) => this.brushed(brushEvent, innerWidth));
    // .on("brush", (brushEvent) => this.brushed(brushEvent, innerWidth));
    this.brush = brush;
    this.xScaleSlider = d3.scaleLinear([0, 1], [0, innerWidth]);

    return brush;
  }

  private createBrushHandle(innerHeight: number): any {
    const brushHandle = (g: any, selection: any, fill?: boolean): void => {
      if (fill === false) {
        this.graphContentGroup
          .selectAll("#left-brush-handle")
          .attr("class", "brush-handle not-including");
      } else {
        this.graphContentGroup.selectAll("#left-brush-handle").attr("class", "brush-handle");
      }
      g.selectAll(".brush-handle")
        .data([{ type: "w" }, { type: "e" }])
        .join((enter: any) =>
          enter
            .append("path")
            .attr("class", "brush-handle")
            .attr("id", (d: any, i: any) => (i ? "" : "left-brush-handle"))
            .attr("fill", "#2A6CE2")
            .attr("fill-opacity", 0.8)
            .attr("stroke", "#2A6CE2")
            .style("stroke-width", "0.5px")
            .attr("cursor", "ew-resize")
            .attr("d", this.createArc())
        )
        .attr("display", selection === null ? "none" : null)
        .attr("transform", (d: any, i: any) => `translate(${selection[i]},${innerHeight})`);
    };
    this.brushHandle = brushHandle;
    return brushHandle;
  }

  private async brushed(brushEvent: any, innerWidth: number): Promise<void> {
    if (!brushEvent.sourceEvent || !brushEvent.selection) {
      return;
    }

    const snappedData = this.snap(brushEvent.selection, innerWidth);
    this.brushGroup.call(this.brush.move, snappedData.graph);
    if (snappedData?.bands?.[0] && snappedData.bands[0]) {
      this.brushGroup.call(this.brushHandle, snappedData.graph, false);
    } else {
      this.brushGroup.call(this.brushHandle, snappedData.graph);
    }

    if (brushEvent.type === "end") {
      if (snappedData?.dates) {
        const filterStartDate = format(
          new Date(BlobbyUtils.setYMDToDate(Number(snappedData.dates[2]))),
          "yyyy-MM-dd"
        );
        const filterEndDate = format(
          new Date(BlobbyUtils.setYMDToDate(Number(snappedData.dates[1]))),
          "yyyy-MM-dd"
        );
        await this.dashboardService.updateFilterDates(filterStartDate, filterEndDate);
      }
    }
  }

  private createArc(): any {
    return d3
      .arc()
      .innerRadius(0)
      .outerRadius(this.sliderHeight * 0.8)
      .startAngle(0)
      .endAngle((d, i) => (i ? Math.PI : -Math.PI));
  }

  /**
   * sortBalances - used to sort the balances from highest to lowest for the tooltip display
   * @param a
   * @param b
   * @private
   */
  private sortBalances(
    a: { display: string; balance: number },
    b: { display: string; balance: number }
  ) {
    if (a.balance > b.balance) {
      return -1;
    } else if (a.balance < b.balance) {
      return 1;
    }
    return 0;
  }

  /**
   * combineBalances - creates the required tooltip string for the balance component from the
   * different balances collected
   * @param balances
   * @private
   */
  private combineBalances(balances: Array<{ display: string; balance: number }>): string {
    let balanceData = "";
    for (const balance of balances) {
      if (balance?.balance || balance.balance === 0) {
        balanceData += balance.display + ": $" + balance.balance.toFixed(2) + "<br/>";
      }
    }
    return balanceData;
  }

  // </editor-fold desc="Private Functions">
}
