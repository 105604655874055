<app-progress-bar *ngIf="loading" [isStart]="loading"></app-progress-bar>
<h1 class="preview-nodata" *ngIf="dataSource.data.length === 0">
  {{ "importNothingError" | i18n }}
</h1>
<div class="preview-table-wrapper">
  <mat-card class="preview-table mat-elevation-z2" #cardRef>
    <mat-card-header class="preview-table-header">
      <button
        class="button-remove-rows"
        mat-raised-button
        color="warn"
        (click)="removeSelectedRows()"
      >
        Remove Selected Rows
      </button>
      <mat-form-field appearance="outline" class="search-box">
        <mat-label>Search</mat-label>
        <input matInput (keyup)="helperListTable.applyFilter($event)" />
      </mat-form-field>

      <div class="color-codes">
        <div class="color-code-container">
          <span class="color-block row-new"></span> {{ "newTransactions" | i18n }}
        </div>

        <div class="color-code-container">
          <span class="color-block row-duplicate"></span>
          {{ "possiblyExistingTransactions" | i18n }}
        </div>
      </div>
    </mat-card-header>
    <br />
    <mat-card-content #cardContentRef class="preview-table-content fill-available-height">
      <app-list-table
        [transactions]="previewData"
        [cardContentRef]="cardContentRef"
        [helperListTable]="helperListTable"
        (onTransactionDefinitionChangeEvent)="callTransactionDefinitionChange($event)"
      ></app-list-table>
    </mat-card-content>
    <mat-card-actions align="end" class="preview-table-actions preview-import">
      <button
        *ngIf="importState.importType"
        mat-raised-button
        color="accent"
        (click)="goBackToArrange()"
      >
        {{ "previous" | i18n }}
      </button>
      <button mat-raised-button color="warn" (click)="onCloseTable()">{{ "cancel" | i18n }}</button>
      <button mat-raised-button color="primary" (click)="handleImportProcess()">
        <span>{{ "next" | i18n }}</span>
      </button>
    </mat-card-actions>
  </mat-card>
</div>
