export enum RecurringPeriod {
  Days = "Days",
  Weeks = "Weeks",
  Months = "Months",
  Years = "Years",
}

export enum EstimatePeriods {
  daily = "Daily",
  weekly = "Weekly",
  monthly = "Monthly",
  yearly = "Yearly",
}
