// async-cell-renderer.component.ts
import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";

import { ClassificationPipe } from "@bitwarden/web-vault/app/pipes/classification.pipe";

@Component({
  selector: "app-classification-cell-renderer",
  template: `{{ value }}`,
})
export class ClassificationCellRenderer implements ICellRendererAngularComp {
  value: string;

  constructor(private pipe: ClassificationPipe) {}

  agInit(params: any): void {
    if (params.value) {
      this.pipe.getClassificationsNamesList(params.value).then((clsList) => {
        this.value = clsList;
      });
    } else {
      this.value = "No classification found";
    }
  }

  refresh(params: any): boolean {
    return false; // No need to refresh since data fetch is already handled
  }
}
