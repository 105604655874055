export enum NewAccountActionTypes {
  link = "link",
  rename = "rename",
  default = "default",
}

export enum AccountTypes {
  transaction = "transaction",
  savings = "savings",
  creditCard = "credit-card",
  mortgage = "mortgage",
  loan = "loan",
  investment = "investment",
  termDeposit = "term-deposit",
  insurance = "insurance",
  foreign = "foreign",
  unknown = "unknown",
  depository = "depository",
  credit = "credit",
  regular = "regular",
}
