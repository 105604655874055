import { GLOBAL_BASE_CURRENCY } from "@bitwarden/web-vault/app/models/constants/global.constants";

export function getReferenceData(symbol: string, base: string, ymd: number): any {
  if (symbol === base) {
    return 1;
  }
  if (symbol.length === 3) {
    return getFromFixer(symbol, base, ymd);
  } else if (symbol.length > 3) {
    return getFromGloss(symbol, base, ymd);
  }

  function getFromFixer(symbol: string, base: string, ymd: number): any {
    // assumes this array is sorted descending - MOST RECENT DATA FIRST
    const FIXER_ARRAY_202303 = [
      {
        base: "USD",
        date: "2023-03-31",
        rates: {
          AED: 3.672704,
          AFN: 86.756146,
          ALL: 104.303989,
          AMD: 388.480403,
          ANG: 1.798237,
          AOA: 507.503981,
          ARS: 208.389515,
          AUD: 1.49525,
          AWG: 1.8,
          AZN: 1.70397,
          BAM: 1.795531,
          BBD: 2.014762,
          BDT: 107.017608,
          BGN: 1.796844,
          BHD: 0.376561,
          BIF: 2085,
          BMD: 1,
          BND: 1.327599,
          BOB: 6.895285,
          BRL: 5.063304,
          BSD: 0.997833,
          BTC: 3.5085376e-5,
          BTN: 82.02732,
          BWP: 13.00975,
          BYN: 2.518648,
          BYR: 19600,
          BZD: 2.011366,
          CAD: 1.35265,
          CDF: 2046.000362,
          CHF: 0.914864,
          CLF: 0.028663,
          CLP: 790.910396,
          CNY: 6.868804,
          COP: 4654.63,
          CRC: 539.530699,
          CUC: 1,
          CUP: 26.5,
          CVE: 101.570394,
          CZK: 21.645504,
          DJF: 177.720394,
          DKK: 6.871104,
          DOP: 54.803886,
          DZD: 135.91704,
          EGP: 30.710508,
          ERN: 15,
          ETB: 53.903876,
          EUR: 0.919904,
          FJD: 2.210504,
          FKP: 0.808506,
          GBP: 0.81011,
          GEL: 2.550391,
          GGP: 0.808506,
          GHS: 11.65039,
          GIP: 0.808506,
          GMD: 62.15039,
          GNF: 8650.000355,
          GTQ: 7.782658,
          GYD: 211.02008,
          HKD: 7.84995,
          HNL: 24.660389,
          HRK: 7.018274,
          HTG: 154.664635,
          HUF: 350.480388,
          IDR: 14968.65,
          ILS: 3.60045,
          IMP: 0.808506,
          INR: 82.18075,
          IQD: 1460,
          IRR: 42275.000352,
          ISK: 137.340386,
          JEP: 0.808506,
          JMD: 150.642173,
          JOD: 0.709404,
          JPY: 132.77504,
          KES: 132.503804,
          KGS: 87.420385,
          KHR: 4052.000351,
          KMF: 452.375039,
          KPW: 900.019076,
          KRW: 1306.470384,
          KWD: 0.30684,
          KYD: 0.831574,
          KZT: 454.82254,
          LAK: 17005.000349,
          LBP: 15037.109932,
          LKR: 329.000349,
          LRD: 162.303775,
          LSL: 17.730382,
          LTL: 2.95274,
          LVL: 0.60489,
          LYD: 4.780381,
          MAD: 10.224039,
          MDL: 18.304073,
          MGA: 4345.000347,
          MKD: 56.561335,
          MMK: 2095.459303,
          MNT: 3530.541632,
          MOP: 8.067378,
          MRO: 356.999828,
          MUR: 45.450379,
          MVR: 15.350378,
          MWK: 1022.503739,
          MXN: 18.017039,
          MYR: 4.412504,
          MZN: 63.103732,
          NAD: 17.730377,
          NGN: 464.503727,
          NIO: 36.575039,
          NOK: 10.501504,
          NPR: 131.243344,
          NZD: 1.5977,
          OMR: 0.384558,
          PAB: 0.997833,
          PEN: 3.763039,
          PGK: 3.520375,
          PHP: 54.292504,
          PKR: 283.803704,
          PLN: 4.316148,
          PYG: 7134.59526,
          QAR: 3.641038,
          RON: 4.563604,
          RSD: 108.220373,
          RUB: 78.000341,
          RWF: 1108.698746,
          SAR: 3.752321,
          SBD: 8.232259,
          SCR: 13.929871,
          SDG: 597.00034,
          SEK: 10.378504,
          SGD: 1.330804,
          SHP: 1.21675,
          SLE: 21.359134,
          SLL: 19750.000338,
          SOS: 568.503664,
          SRD: 36.097504,
          STD: 20697.981008,
          SVC: 8.731536,
          SYP: 2511.800586,
          SZL: 17.73037,
          THB: 34.080369,
          TJS: 10.901421,
          TMT: 3.49,
          TND: 3.048504,
          TOP: 2.350604,
          TRY: 19.178204,
          TTD: 6.773281,
          TWD: 30.540104,
          TZS: 2338.855038,
          UAH: 36.671685,
          UGX: 3762.187173,
          USD: 1,
          UYU: 38.806162,
          UZS: 11415.000334,
          VEF: 2443307.898392,
          VES: 24.466192,
          VND: 23465,
          VUV: 119.05212,
          WST: 2.723766,
          XAF: 602.238196,
          XAG: 0.041485,
          XAU: 0.000508,
          XCD: 2.70255,
          XDR: 0.743797,
          XOF: 601.000332,
          XPF: 110.203595,
          YER: 250.350363,
          ZAR: 17.793855,
          ZMK: 9001.203589,
          ZMW: 21.178933,
          ZWL: 321.999592,
        },
      },
      {
        base: "USD",
        date: "2023-03-30",
        rates: {
          AED: 3.672903,
          AFN: 85.499204,
          ALL: 104.825009,
          AMD: 388.285115,
          ANG: 1.802528,
          AOA: 507.498291,
          ARS: 208.579801,
          AUD: 1.48886,
          AWG: 1.8025,
          AZN: 1.70458,
          BAM: 1.798023,
          BBD: 2.019398,
          BDT: 107.267295,
          BGN: 1.795183,
          BHD: 0.377,
          BIF: 2085,
          BMD: 1,
          BND: 1.329074,
          BOB: 6.910913,
          BRL: 5.094202,
          BSD: 1.000138,
          BTC: 3.567564e-5,
          BTN: 82.163116,
          BWP: 13.107791,
          BYN: 2.524937,
          BYR: 19600,
          BZD: 2.015996,
          CAD: 1.352065,
          CDF: 2047.49161,
          CHF: 0.91342,
          CLF: 0.028606,
          CLP: 789.320311,
          CNY: 6.871904,
          COP: 4645,
          CRC: 540.41133,
          CUC: 1,
          CUP: 26.5,
          CVE: 101.52497,
          CZK: 21.578503,
          DJF: 177.719671,
          DKK: 6.83066,
          DOP: 54.793814,
          DZD: 135.568978,
          EGP: 30.895785,
          ERN: 15,
          ETB: 53.909612,
          EUR: 0.91706,
          FJD: 2.20565,
          FKP: 0.812258,
          GBP: 0.807305,
          GEL: 2.550176,
          GGP: 0.812258,
          GHS: 11.850217,
          GIP: 0.812258,
          GMD: 62.169972,
          GNF: 8665.000079,
          GTQ: 7.795909,
          GYD: 211.538108,
          HKD: 7.84995,
          HNL: 24.659956,
          HRK: 7.018274,
          HTG: 155.017237,
          HUF: 348.650374,
          IDR: 15024,
          ILS: 3.589609,
          IMP: 0.812258,
          INR: 82.10075,
          IQD: 1461,
          IRR: 42250.00023,
          ISK: 135.819782,
          JEP: 0.812258,
          JMD: 151.146863,
          JOD: 0.709404,
          JPY: 133.211502,
          KES: 132.395038,
          KGS: 87.420197,
          KHR: 4053.9997,
          KMF: 451.825016,
          KPW: 899.98744,
          KRW: 1296.875037,
          KWD: 0.30667,
          KYD: 0.833464,
          KZT: 450.31487,
          LAK: 16989.999938,
          LBP: 15270.000222,
          LKR: 330.057458,
          LRD: 162.249978,
          LSL: 18.090124,
          LTL: 2.95274,
          LVL: 0.60489,
          LYD: 4.790272,
          MAD: 10.212502,
          MDL: 18.392094,
          MGA: 4328.999374,
          MKD: 56.50637,
          MMK: 2100.298782,
          MNT: 3529.499208,
          MOP: 8.086049,
          MRO: 356.999828,
          MUR: 45.949559,
          MVR: 15.397339,
          MWK: 1023.999692,
          MXN: 18.092397,
          MYR: 4.422498,
          MZN: 63.102324,
          NAD: 18.089791,
          NGN: 463.502751,
          NIO: 36.570213,
          NOK: 10.397865,
          NPR: 131.453919,
          NZD: 1.594911,
          OMR: 0.385017,
          PAB: 1.000138,
          PEN: 3.755027,
          PGK: 3.525021,
          PHP: 54.313005,
          PKR: 283.749872,
          PLN: 4.28732,
          PYG: 7170.765341,
          QAR: 3.640977,
          RON: 4.542175,
          RSD: 107.589864,
          RUB: 77.275005,
          RWF: 1103,
          SAR: 3.753439,
          SBD: 8.232683,
          SCR: 13.009748,
          SDG: 594.000061,
          SEK: 10.358065,
          SGD: 1.32795,
          SHP: 1.21675,
          SLE: 21.463037,
          SLL: 19749.999814,
          SOS: 569.500308,
          SRD: 35.867501,
          STD: 20697.981008,
          SVC: 8.751092,
          SYP: 2512.588402,
          SZL: 18.089981,
          THB: 34.122045,
          TJS: 10.921535,
          TMT: 3.355,
          TND: 3.0455,
          TOP: 2.35445,
          TRY: 19.180701,
          TTD: 6.788699,
          TWD: 30.481997,
          TZS: 2332.000419,
          UAH: 36.756516,
          UGX: 3785.796369,
          USD: 1,
          UYU: 38.740519,
          UZS: 11438.000111,
          VEF: 2436845.534607,
          VES: 24.456816,
          VND: 23480,
          VUV: 119.070493,
          WST: 2.718089,
          XAF: 603.079752,
          XAG: 0.041879,
          XAU: 0.000505,
          XCD: 2.70255,
          XDR: 0.745484,
          XOF: 604.511367,
          XPF: 109.949873,
          YER: 250.349911,
          ZAR: 17.82466,
          ZMK: 9001.197294,
          ZMW: 21.4289,
          ZWL: 321.999592,
        },
      },
      {
        base: "USD",
        date: "2023-03-29",
        rates: {
          AED: 3.672603,
          AFN: 85.497294,
          ALL: 104.824989,
          AMD: 388.209993,
          ANG: 1.801825,
          AOA: 508.999726,
          ARS: 208.295099,
          AUD: 1.49653,
          AWG: 1.8,
          AZN: 1.689005,
          BAM: 1.800904,
          BBD: 2.018628,
          BDT: 106.831429,
          BGN: 1.80334,
          BHD: 0.377092,
          BIF: 2078,
          BMD: 1,
          BND: 1.329073,
          BOB: 6.908776,
          BRL: 5.135706,
          BSD: 0.999738,
          BTC: 3.527928e-5,
          BTN: 82.239114,
          BWP: 13.137382,
          BYN: 2.523516,
          BYR: 19600,
          BZD: 2.015156,
          CAD: 1.35646,
          CDF: 2047.502853,
          CHF: 0.918299,
          CLF: 0.028707,
          CLP: 792.1203,
          CNY: 6.887101,
          COP: 4636,
          CRC: 540.050276,
          CUC: 1,
          CUP: 26.5,
          CVE: 101.999851,
          CZK: 21.740398,
          DJF: 177.719857,
          DKK: 6.87002,
          DOP: 54.750223,
          DZD: 135.684008,
          EGP: 30.8039,
          ERN: 15,
          ETB: 53.89631,
          EUR: 0.922231,
          FJD: 2.21295,
          FKP: 0.810208,
          GBP: 0.81225,
          GEL: 2.559957,
          GGP: 0.810208,
          GHS: 11.850458,
          GIP: 0.810208,
          GMD: 62.150123,
          GNF: 8670.000025,
          GTQ: 7.792746,
          GYD: 210.946492,
          HKD: 7.84995,
          HNL: 24.650177,
          HRK: 7.018274,
          HTG: 155.458974,
          HUF: 351.115002,
          IDR: 15035.5,
          ILS: 3.57993,
          IMP: 0.810208,
          INR: 82.25565,
          IQD: 1460,
          IRR: 42262.544655,
          ISK: 136.570272,
          JEP: 0.810208,
          JMD: 150.871991,
          JOD: 0.709398,
          JPY: 132.585497,
          KES: 132.149774,
          KGS: 87.419615,
          KHR: 4055.000256,
          KMF: 454.499425,
          KPW: 899.9892,
          KRW: 1307.454991,
          KWD: 0.30656,
          KYD: 0.833161,
          KZT: 447.473952,
          LAK: 16982.49594,
          LBP: 15249.999718,
          LKR: 324.783491,
          LRD: 162.100706,
          LSL: 18.0898,
          LTL: 2.95274,
          LVL: 0.60489,
          LYD: 4.779813,
          MAD: 10.232499,
          MDL: 18.420641,
          MGA: 4329.999689,
          MKD: 56.829134,
          MMK: 2099.50093,
          MNT: 3528.393777,
          MOP: 8.083811,
          MRO: 356.999828,
          MUR: 46.000063,
          MVR: 15.425028,
          MWK: 1020.502355,
          MXN: 18.09673,
          MYR: 4.419703,
          MZN: 63.098376,
          NAD: 18.090154,
          NGN: 460.00033,
          NIO: 36.575008,
          NOK: 10.41971,
          NPR: 131.581371,
          NZD: 1.607575,
          OMR: 0.385008,
          PAB: 0.999705,
          PEN: 3.753997,
          PGK: 3.520242,
          PHP: 54.275012,
          PKR: 283.419974,
          PLN: 4.32335,
          PYG: 7181.333505,
          QAR: 3.640498,
          RON: 4.570203,
          RSD: 108.189984,
          RUB: 77.324987,
          RWF: 1103.5,
          SAR: 3.755153,
          SBD: 8.266721,
          SCR: 13.087801,
          SDG: 595.000471,
          SEK: 10.39983,
          SGD: 1.32932,
          SHP: 1.21675,
          SLE: 21.106252,
          SLL: 19750.000439,
          SOS: 568.500785,
          SRD: 35.885498,
          STD: 20697.981008,
          SVC: 8.747617,
          SYP: 2512.521394,
          SZL: 18.090177,
          THB: 34.179906,
          TJS: 10.907258,
          TMT: 3.505,
          TND: 3.054985,
          TOP: 2.35115,
          TRY: 19.15179,
          TTD: 6.78631,
          TWD: 30.531002,
          TZS: 2332.000025,
          UAH: 36.922801,
          UGX: 3789.186775,
          USD: 1,
          UYU: 38.785066,
          UZS: 11437.502424,
          VEF: 2433547.790334,
          VES: 24.384594,
          VND: 23490,
          VUV: 119.546063,
          WST: 2.731023,
          XAF: 604.040441,
          XAG: 0.042895,
          XAU: 0.000509,
          XCD: 2.70255,
          XDR: 0.745222,
          XOF: 605.497801,
          XPF: 110.600827,
          YER: 250.301068,
          ZAR: 18.099535,
          ZMK: 9001.220523,
          ZMW: 21.269602,
          ZWL: 321.999592,
        },
      },
      {
        base: "USD",
        date: "2023-03-28",
        rates: {
          AED: 3.672899,
          AFN: 86.587875,
          ALL: 105.059209,
          AMD: 389.53011,
          ANG: 1.79957,
          AOA: 504.800988,
          ARS: 207.822303,
          AUD: 1.49117,
          AWG: 1.8,
          AZN: 1.693041,
          BAM: 1.804452,
          BBD: 2.016118,
          BDT: 107.540076,
          BGN: 1.803695,
          BHD: 0.377029,
          BIF: 2076.841474,
          BMD: 1,
          BND: 1.326758,
          BOB: 6.900242,
          BRL: 5.1657,
          BSD: 0.998556,
          BTC: 3.667397e-5,
          BTN: 82.095095,
          BWP: 13.155697,
          BYN: 2.520898,
          BYR: 19600,
          BZD: 2.012705,
          CAD: 1.359485,
          CDF: 2051.499235,
          CHF: 0.920302,
          CLF: 0.028938,
          CLP: 798.47973,
          CNY: 6.874301,
          COP: 4660.28,
          CRC: 541.672625,
          CUC: 1,
          CUP: 26.5,
          CVE: 101.727598,
          CZK: 21.811005,
          DJF: 177.789669,
          DKK: 6.87388,
          DOP: 54.785421,
          DZD: 135.715971,
          EGP: 30.908499,
          ERN: 15,
          ETB: 53.812606,
          EUR: 0.92266,
          FJD: 2.207599,
          FKP: 0.814422,
          GBP: 0.81112,
          GEL: 2.565019,
          GGP: 0.814422,
          GHS: 12.03183,
          GIP: 0.814422,
          GMD: 61.950175,
          GNF: 8584.055283,
          GTQ: 7.783221,
          GYD: 210.688502,
          HKD: 7.84995,
          HNL: 24.629451,
          HRK: 7.018274,
          HTG: 154.743785,
          HUF: 351.759694,
          IDR: 15066,
          ILS: 3.544097,
          IMP: 0.814422,
          INR: 82.16455,
          IQD: 1309.129492,
          IRR: 42275.000111,
          ISK: 136.830208,
          JEP: 0.814422,
          JMD: 150.673746,
          JOD: 0.709405,
          JPY: 131.056022,
          KES: 130.810285,
          KGS: 87.419976,
          KHR: 4048.70624,
          KMF: 461.29594,
          KPW: 899.911923,
          KRW: 1297.330567,
          KWD: 0.3063,
          KYD: 0.832126,
          KZT: 453.351048,
          LAK: 16874.106196,
          LBP: 14988.144116,
          LKR: 324.546755,
          LRD: 161.200541,
          LSL: 18.199562,
          LTL: 2.95274,
          LVL: 0.60489,
          LYD: 4.783806,
          MAD: 10.229093,
          MDL: 18.437976,
          MGA: 4327.107158,
          MKD: 56.832542,
          MMK: 2096.940117,
          MNT: 3529.505959,
          MOP: 8.07418,
          MRO: 356.999828,
          MUR: 46.155195,
          MVR: 15.41968,
          MWK: 1025.58898,
          MXN: 18.22685,
          MYR: 4.41013,
          MZN: 63.077673,
          NAD: 18.196577,
          NGN: 464.501297,
          NIO: 36.517295,
          NOK: 10.363655,
          NPR: 131.352152,
          NZD: 1.599348,
          OMR: 0.385039,
          PAB: 0.998547,
          PEN: 3.766671,
          PGK: 3.518872,
          PHP: 54.294012,
          PKR: 283.158973,
          PLN: 4.31554,
          PYG: 7172.479968,
          QAR: 3.640979,
          RON: 4.567795,
          RSD: 108.22501,
          RUB: 76.560055,
          RWF: 1114.345421,
          SAR: 3.755569,
          SBD: 8.232683,
          SCR: 13.41195,
          SDG: 594.999865,
          SEK: 10.35715,
          SGD: 1.32806,
          SHP: 1.21675,
          SLE: 21.273082,
          SLL: 19749.999834,
          SOS: 569.505563,
          SRD: 35.644963,
          STD: 20697.981008,
          SVC: 8.737452,
          SYP: 2512.368637,
          SZL: 18.191296,
          THB: 34.230541,
          TJS: 10.909267,
          TMT: 3.5,
          TND: 3.118033,
          TOP: 2.358901,
          TRY: 19.1266,
          TTD: 6.777691,
          TWD: 30.204497,
          TZS: 2332.000099,
          UAH: 36.877286,
          UGX: 3768.884788,
          USD: 1,
          UYU: 38.897474,
          UZS: 11375.190294,
          VEF: 2434312.133723,
          VES: 24.371098,
          VND: 23495,
          VUV: 119.309128,
          WST: 2.730199,
          XAF: 605.241428,
          XAG: 0.042844,
          XAU: 0.000507,
          XCD: 2.70255,
          XDR: 0.744281,
          XOF: 605.241428,
          XPF: 110.450031,
          YER: 250.350039,
          ZAR: 18.15455,
          ZMK: 9001.194649,
          ZMW: 21.243421,
          ZWL: 321.999592,
        },
      },
      {
        base: "USD",
        date: "2023-03-27",
        rates: {
          AED: 3.672602,
          AFN: 86.000215,
          ALL: 106.60203,
          AMD: 388.530229,
          ANG: 1.801565,
          AOA: 504.800986,
          ARS: 207.424099,
          AUD: 1.50156,
          AWG: 1.8,
          AZN: 1.727402,
          BAM: 1.815633,
          BBD: 2.018381,
          BDT: 105.05013,
          BGN: 1.81101,
          BHD: 0.377088,
          BIF: 2080,
          BMD: 1,
          BND: 1.331929,
          BOB: 6.907403,
          BRL: 5.198601,
          BSD: 0.999619,
          BTC: 3.6855119e-5,
          BTN: 82.305914,
          BWP: 13.221813,
          BYN: 2.523126,
          BYR: 19600,
          BZD: 2.014881,
          CAD: 1.365485,
          CDF: 2054.999925,
          CHF: 0.91512,
          CLF: 0.029219,
          CLP: 806.230007,
          CNY: 6.88525,
          COP: 4677,
          CRC: 540.924408,
          CUC: 1,
          CUP: 26.5,
          CVE: 102.549785,
          CZK: 22.016198,
          DJF: 177.720332,
          DKK: 6.894798,
          DOP: 54.749566,
          DZD: 135.841969,
          EGP: 30.9005,
          ERN: 15,
          ETB: 53.924019,
          EUR: 0.92535,
          FJD: 2.216399,
          FKP: 0.817653,
          GBP: 0.813215,
          GEL: 2.574964,
          GGP: 0.817653,
          GHS: 12.098393,
          GIP: 0.817653,
          GMD: 61.625005,
          GNF: 8654.999922,
          GTQ: 7.799634,
          GYD: 210.908382,
          HKD: 7.84975,
          HNL: 24.650461,
          HRK: 7.018274,
          HTG: 155.197222,
          HUF: 357.249612,
          IDR: 15111.5,
          ILS: 3.59098,
          IMP: 0.817653,
          INR: 82.21515,
          IQD: 1460,
          IRR: 42275.000249,
          ISK: 137.790179,
          JEP: 0.817653,
          JMD: 151.202622,
          JOD: 0.709402,
          JPY: 131.2375,
          KES: 131.601836,
          KGS: 87.419919,
          KHR: 4056.000343,
          KMF: 461.297666,
          KPW: 900,
          KRW: 1298.174983,
          KWD: 0.30637,
          KYD: 0.833059,
          KZT: 457.527443,
          LAK: 16980.000024,
          LBP: 15260.000342,
          LKR: 324.894867,
          LRD: 161.200866,
          LSL: 18.290491,
          LTL: 2.95274,
          LVL: 0.60489,
          LYD: 4.795018,
          MAD: 10.262499,
          MDL: 18.472121,
          MGA: 4337.498169,
          MKD: 57.064928,
          MMK: 2099.170562,
          MNT: 3530.337699,
          MOP: 8.08232,
          MRO: 356.999828,
          MUR: 46.000465,
          MVR: 15.419795,
          MWK: 1020.505703,
          MXN: 18.333605,
          MYR: 4.420498,
          MZN: 63.102267,
          NAD: 18.289647,
          NGN: 461.501494,
          NIO: 36.554804,
          NOK: 10.45865,
          NPR: 131.689611,
          NZD: 1.612185,
          OMR: 0.385037,
          PAB: 0.999596,
          PEN: 3.766985,
          PGK: 3.519499,
          PHP: 54.089923,
          PKR: 283.649651,
          PLN: 4.337897,
          PYG: 7160.03082,
          QAR: 3.640992,
          RON: 4.57268,
          RSD: 108.575012,
          RUB: 76.534978,
          RWF: 1100,
          SAR: 3.755582,
          SBD: 8.220124,
          SCR: 13.394716,
          SDG: 594.999431,
          SEK: 10.368475,
          SGD: 1.330705,
          SHP: 1.21675,
          SLE: 20.774945,
          SLL: 19749.99983,
          SOS: 568.493657,
          SRD: 35.563503,
          STD: 20697.981008,
          SVC: 8.744721,
          SYP: 2512.536334,
          SZL: 18.290096,
          THB: 34.420008,
          TJS: 10.920551,
          TMT: 3.51,
          TND: 3.115,
          TOP: 2.357795,
          TRY: 19.096035,
          TTD: 6.798919,
          TWD: 30.170496,
          TZS: 2331.999723,
          UAH: 36.91655,
          UGX: 3770.799972,
          USD: 1,
          UYU: 38.730336,
          UZS: 11415.000164,
          VEF: 2425375.111476,
          VES: 24.371098,
          VND: 23515,
          VUV: 118.00881,
          WST: 2.787818,
          XAF: 608.966474,
          XAG: 0.04325,
          XAU: 0.000511,
          XCD: 2.70255,
          XDR: 0.745056,
          XOF: 609.494813,
          XPF: 111.674974,
          YER: 250.300338,
          ZAR: 18.301198,
          ZMK: 9001.202674,
          ZMW: 21.291646,
          ZWL: 321.999592,
        },
      },
      {
        base: "USD",
        date: "2023-03-26",
        rates: {
          AED: 3.672415,
          AFN: 86.751053,
          ALL: 106.529226,
          AMD: 388.070224,
          ANG: 1.803769,
          AOA: 504.800999,
          ARS: 205.373241,
          AUD: 1.50287,
          AWG: 1.8,
          AZN: 1.67226,
          BAM: 1.823012,
          BBD: 2.020991,
          BDT: 105.19742,
          BGN: 1.818004,
          BHD: 0.376351,
          BIF: 2081.799286,
          BMD: 1,
          BND: 1.335017,
          BOB: 6.915546,
          BRL: 5.246597,
          BSD: 1.000895,
          BTC: 3.572087e-5,
          BTN: 82.523767,
          BWP: 13.204338,
          BYN: 2.526331,
          BYR: 19600,
          BZD: 2.017486,
          CAD: 1.37302,
          CDF: 2052.000373,
          CHF: 0.91904,
          CLF: 0.029445,
          CLP: 812.490046,
          CNY: 6.867972,
          COP: 4754.37,
          CRC: 540.213065,
          CUC: 1,
          CUP: 26.5,
          CVE: 102.770593,
          CZK: 21.967901,
          DJF: 177.719965,
          DKK: 6.91462,
          DOP: 54.916069,
          DZD: 135.833063,
          EGP: 30.860497,
          ERN: 15,
          ETB: 53.910778,
          EUR: 0.927945,
          FJD: 2.218297,
          FKP: 0.817754,
          GBP: 0.81676,
          GEL: 2.575,
          GGP: 0.817754,
          GHS: 12.259715,
          GIP: 0.817754,
          GMD: 61.500101,
          GNF: 8611.722158,
          GTQ: 7.809602,
          GYD: 211.175783,
          HKD: 7.84985,
          HNL: 24.68588,
          HRK: 7.018274,
          HTG: 155.400619,
          HUF: 357.350043,
          IDR: 15155,
          ILS: 3.593898,
          IMP: 0.817754,
          INR: 82.33905,
          IQD: 1457.845,
          IRR: 42250.000007,
          ISK: 139.290218,
          JEP: 0.817754,
          JMD: 151.101666,
          JOD: 0.709404,
          JPY: 130.869774,
          KES: 131.399915,
          KGS: 87.419838,
          KHR: 4063.733509,
          KMF: 461.739842,
          KPW: 899.999742,
          KRW: 1295.829978,
          KWD: 0.30617,
          KYD: 0.834091,
          KZT: 457.971366,
          LAK: 16908.602025,
          LBP: 15022.29844,
          LKR: 323.319414,
          LRD: 161.050272,
          LSL: 18.419749,
          LTL: 2.95274,
          LVL: 0.60489,
          LYD: 4.763059,
          MAD: 10.253421,
          MDL: 18.530307,
          MGA: 4316.157865,
          MKD: 57.436337,
          MMK: 2101.741043,
          MNT: 3531.194361,
          MOP: 8.0924,
          MRO: 356.999828,
          MUR: 46.000512,
          MVR: 15.432476,
          MWK: 1027.918486,
          MXN: 18.429855,
          MYR: 4.42799,
          MZN: 63.096561,
          NAD: 18.419817,
          NGN: 460.480407,
          NIO: 36.613102,
          NOK: 10.471265,
          NPR: 132.037803,
          NZD: 1.610375,
          OMR: 0.384206,
          PAB: 1.000867,
          PEN: 3.773755,
          PGK: 3.526824,
          PHP: 54.288027,
          PKR: 283.116291,
          PLN: 4.348855,
          PYG: 7189.725236,
          QAR: 3.640973,
          RON: 4.571988,
          RSD: 108.817992,
          RUB: 77.279746,
          RWF: 1099.05678,
          SAR: 3.7565,
          SBD: 8.200165,
          SCR: 13.050777,
          SDG: 593.492074,
          SEK: 10.407403,
          SGD: 1.332101,
          SHP: 1.21675,
          SLE: 20.774955,
          SLL: 19749.999567,
          SOS: 569.500056,
          SRD: 35.454008,
          STD: 20697.981008,
          SVC: 8.757777,
          SYP: 2512.53012,
          SZL: 18.256217,
          THB: 34.201599,
          TJS: 10.919174,
          TMT: 3.5,
          TND: 3.128976,
          TOP: 2.351699,
          TRY: 19.085198,
          TTD: 6.807591,
          TWD: 30.336022,
          TZS: 2331.99982,
          UAH: 36.962681,
          UGX: 3775.959136,
          USD: 1,
          UYU: 39.025982,
          UZS: 11418.291637,
          VEF: 2425376.16505,
          VES: 24.095671,
          VND: 23525,
          VUV: 118.009004,
          WST: 2.787823,
          XAF: 611.424124,
          XAG: 0.043103,
          XAU: 0.000506,
          XCD: 2.70255,
          XDR: 0.749934,
          XOF: 611.455469,
          XPF: 112.249955,
          YER: 250.301804,
          ZAR: 18.16275,
          ZMK: 9001.197229,
          ZMW: 21.197731,
          ZWL: 321.999592,
        },
      },
      {
        base: "USD",
        date: "2023-03-25",
        rates: {
          AED: 3.67255,
          AFN: 86.751053,
          ALL: 106.529226,
          AMD: 388.070403,
          ANG: 1.803769,
          AOA: 504.801041,
          ARS: 205.259912,
          AUD: 1.50335,
          AWG: 1.8,
          AZN: 1.70397,
          BAM: 1.823012,
          BBD: 2.020991,
          BDT: 105.19742,
          BGN: 1.818004,
          BHD: 0.376351,
          BIF: 2081.799286,
          BMD: 1,
          BND: 1.335017,
          BOB: 6.915546,
          BRL: 5.246604,
          BSD: 1.000895,
          BTC: 3.6361516e-5,
          BTN: 82.523767,
          BWP: 13.204338,
          BYN: 2.526331,
          BYR: 19600,
          BZD: 2.017486,
          CAD: 1.38035,
          CDF: 2052.000362,
          CHF: 0.920095,
          CLF: 0.029373,
          CLP: 810.503912,
          CNY: 6.868041,
          COP: 4750.23,
          CRC: 540.213065,
          CUC: 1,
          CUP: 26.5,
          CVE: 102.770593,
          CZK: 22.002904,
          DJF: 177.720394,
          DKK: 6.925404,
          DOP: 54.916069,
          DZD: 135.833063,
          EGP: 30.838859,
          ERN: 15,
          ETB: 53.910778,
          EUR: 0.927504,
          FJD: 2.218304,
          FKP: 0.818079,
          GBP: 0.817862,
          GEL: 2.57504,
          GGP: 0.818079,
          GHS: 12.259715,
          GIP: 0.818079,
          GMD: 61.503853,
          GNF: 8611.722158,
          GTQ: 7.809602,
          GYD: 211.175783,
          HKD: 7.84995,
          HNL: 24.68588,
          HRK: 7.018274,
          HTG: 155.400619,
          HUF: 357.440388,
          IDR: 15166.05,
          ILS: 3.59461,
          IMP: 0.818079,
          INR: 82.34745,
          IQD: 1457.845,
          IRR: 42250.000352,
          ISK: 139.503816,
          JEP: 0.818079,
          JMD: 151.101666,
          JOD: 0.709404,
          JPY: 130.750385,
          KES: 131.403804,
          KGS: 87.420385,
          KHR: 4063.733509,
          KMF: 461.740384,
          KPW: 899.971219,
          KRW: 1295.830384,
          KWD: 0.30617,
          KYD: 0.834091,
          KZT: 457.971366,
          LAK: 16908.602025,
          LBP: 15022.29844,
          LKR: 323.319414,
          LRD: 161.050382,
          LSL: 18.420382,
          LTL: 2.95274,
          LVL: 0.60489,
          LYD: 4.763059,
          MAD: 10.253421,
          MDL: 18.530307,
          MGA: 4316.157865,
          MKD: 57.436337,
          MMK: 2101.741043,
          MNT: 3528.95122,
          MOP: 8.0924,
          MRO: 356.999828,
          MUR: 46.010379,
          MVR: 15.403741,
          MWK: 1027.918486,
          MXN: 18.441504,
          MYR: 4.428039,
          MZN: 63.103732,
          NAD: 18.420377,
          NGN: 460.480377,
          NIO: 36.613102,
          NOK: 10.467985,
          NPR: 132.037803,
          NZD: 1.611604,
          OMR: 0.384206,
          PAB: 1.000867,
          PEN: 3.773755,
          PGK: 3.526824,
          PHP: 54.288039,
          PKR: 283.116291,
          PLN: 4.357617,
          PYG: 7189.725236,
          QAR: 3.641038,
          RON: 4.581038,
          RSD: 108.817992,
          RUB: 77.280373,
          RWF: 1099.05678,
          SAR: 3.756429,
          SBD: 8.200165,
          SCR: 13.050777,
          SDG: 593.503678,
          SEK: 10.656715,
          SGD: 1.332904,
          SHP: 1.21675,
          SLE: 21.044272,
          SLL: 19750.000338,
          SOS: 569.503664,
          SRD: 35.454038,
          STD: 20697.981008,
          SVC: 8.757777,
          SYP: 2512.491351,
          SZL: 18.256217,
          THB: 34.090369,
          TJS: 10.919174,
          TMT: 3.5,
          TND: 3.129038,
          TOP: 2.351704,
          TRY: 19.034038,
          TTD: 6.807591,
          TWD: 30.334904,
          TZS: 2341.959038,
          UAH: 36.962681,
          UGX: 3775.959136,
          USD: 1,
          UYU: 39.025982,
          UZS: 11418.291637,
          VEF: 2425264.36531,
          VES: 24.095671,
          VND: 23525,
          VUV: 118.892592,
          WST: 2.745634,
          XAF: 611.424124,
          XAG: 0.04305,
          XAU: 0.000505,
          XCD: 2.70255,
          XDR: 0.749934,
          XOF: 611.455469,
          XPF: 112.250364,
          YER: 250.303593,
          ZAR: 18.170304,
          ZMK: 9001.203589,
          ZMW: 21.197731,
          ZWL: 321.999592,
        },
      },
      {
        base: "USD",
        date: "2023-03-24",
        rates: {
          AED: 3.672415,
          AFN: 86.751053,
          ALL: 106.529226,
          AMD: 388.070403,
          ANG: 1.803769,
          AOA: 504.801041,
          ARS: 205.259912,
          AUD: 1.50335,
          AWG: 1.8,
          AZN: 1.70397,
          BAM: 1.823012,
          BBD: 2.020991,
          BDT: 105.19742,
          BGN: 1.818004,
          BHD: 0.376351,
          BIF: 2081.799286,
          BMD: 1,
          BND: 1.335017,
          BOB: 6.915546,
          BRL: 5.246504,
          BSD: 1.000895,
          BTC: 3.6348229e-5,
          BTN: 82.523767,
          BWP: 13.204338,
          BYN: 2.526331,
          BYR: 19600,
          BZD: 2.017486,
          CAD: 1.38025,
          CDF: 2052.000362,
          CHF: 0.920404,
          CLF: 0.029373,
          CLP: 810.503912,
          CNY: 6.868104,
          COP: 4750.23,
          CRC: 540.213065,
          CUC: 1,
          CUP: 26.5,
          CVE: 102.770593,
          CZK: 22.002904,
          DJF: 177.720394,
          DKK: 6.925404,
          DOP: 54.916069,
          DZD: 135.833063,
          EGP: 30.838859,
          ERN: 15,
          ETB: 53.910778,
          EUR: 0.927604,
          FJD: 2.218304,
          FKP: 0.811565,
          GBP: 0.817862,
          GEL: 2.57504,
          GGP: 0.811565,
          GHS: 12.259715,
          GIP: 0.811565,
          GMD: 61.503853,
          GNF: 8611.722158,
          GTQ: 7.809602,
          GYD: 211.175783,
          HKD: 7.84995,
          HNL: 24.68588,
          HRK: 7.018274,
          HTG: 155.400619,
          HUF: 357.440388,
          IDR: 15166.05,
          ILS: 3.59461,
          IMP: 0.811565,
          INR: 82.34745,
          IQD: 1457.845,
          IRR: 42250.000352,
          ISK: 139.503816,
          JEP: 0.811565,
          JMD: 151.101666,
          JOD: 0.709404,
          JPY: 130.750385,
          KES: 131.403804,
          KGS: 87.420385,
          KHR: 4063.733509,
          KMF: 461.740384,
          KPW: 899.911171,
          KRW: 1295.830384,
          KWD: 0.30617,
          KYD: 0.834091,
          KZT: 457.971366,
          LAK: 16908.602025,
          LBP: 15022.29844,
          LKR: 323.319414,
          LRD: 161.050382,
          LSL: 18.420382,
          LTL: 2.95274,
          LVL: 0.60489,
          LYD: 4.763059,
          MAD: 10.253421,
          MDL: 18.530307,
          MGA: 4316.157865,
          MKD: 57.436337,
          MMK: 2101.741043,
          MNT: 3530.903684,
          MOP: 8.0924,
          MRO: 356.999828,
          MUR: 46.010379,
          MVR: 15.403741,
          MWK: 1027.918486,
          MXN: 18.441504,
          MYR: 4.428039,
          MZN: 63.103732,
          NAD: 18.420377,
          NGN: 460.480377,
          NIO: 36.613102,
          NOK: 10.467985,
          NPR: 132.037803,
          NZD: 1.611604,
          OMR: 0.384206,
          PAB: 1.000867,
          PEN: 3.773755,
          PGK: 3.526824,
          PHP: 54.274039,
          PKR: 283.116291,
          PLN: 4.357617,
          PYG: 7189.725236,
          QAR: 3.641038,
          RON: 4.581038,
          RSD: 108.817992,
          RUB: 77.280373,
          RWF: 1099.05678,
          SAR: 3.756429,
          SBD: 8.200165,
          SCR: 13.050777,
          SDG: 593.503678,
          SEK: 10.656715,
          SGD: 1.332904,
          SHP: 1.21675,
          SLE: 21.219167,
          SLL: 19750.000338,
          SOS: 569.503664,
          SRD: 35.454038,
          STD: 20697.981008,
          SVC: 8.757777,
          SYP: 2511.985917,
          SZL: 18.256217,
          THB: 34.080369,
          TJS: 10.919174,
          TMT: 3.5,
          TND: 3.129038,
          TOP: 2.351704,
          TRY: 19.034038,
          TTD: 6.807591,
          TWD: 30.335904,
          TZS: 2341.959038,
          UAH: 36.962681,
          UGX: 3775.959136,
          USD: 1,
          UYU: 39.025982,
          UZS: 11418.291637,
          VEF: 2414911.192416,
          VES: 24.095671,
          VND: 23525,
          VUV: 119.096944,
          WST: 2.712666,
          XAF: 611.424124,
          XAG: 0.04305,
          XAU: 0.000505,
          XCD: 2.70255,
          XDR: 0.749934,
          XOF: 611.455469,
          XPF: 112.250364,
          YER: 250.303593,
          ZAR: 18.170295,
          ZMK: 9001.203589,
          ZMW: 21.197731,
          ZWL: 321.999592,
        },
      },
      {
        base: "USD",
        date: "2023-03-23",
        rates: {
          AED: 3.6725,
          AFN: 86.838323,
          ALL: 104.981332,
          AMD: 388.330031,
          ANG: 1.800222,
          AOA: 504.800991,
          ARS: 205.727099,
          AUD: 1.49682,
          AWG: 1.8,
          AZN: 1.702985,
          BAM: 1.796191,
          BBD: 2.001267,
          BDT: 104.980393,
          BGN: 1.80557,
          BHD: 0.377205,
          BIF: 2077.690679,
          BMD: 1,
          BND: 1.325135,
          BOB: 6.907414,
          BRL: 5.298201,
          BSD: 0.998843,
          BTC: 3.5281201e-5,
          BTN: 82.126774,
          BWP: 13.126317,
          BYN: 2.521697,
          BYR: 19600,
          BZD: 2.002627,
          CAD: 1.371925,
          CDF: 2052.000059,
          CHF: 0.916715,
          CLF: 0.029257,
          CLP: 807.296767,
          CNY: 6.819902,
          COP: 4756.5,
          CRC: 539.11574,
          CUC: 1,
          CUP: 26.5,
          CVE: 101.26094,
          CZK: 21.802596,
          DJF: 177.719832,
          DKK: 6.875298,
          DOP: 54.721547,
          DZD: 135.853062,
          EGP: 30.950201,
          ERN: 15,
          ETB: 53.800096,
          EUR: 0.92292,
          FJD: 2.210505,
          FKP: 0.817676,
          GBP: 0.814239,
          GEL: 2.574954,
          GGP: 0.817676,
          GHS: 12.336177,
          GIP: 0.817676,
          GMD: 61.499443,
          GNF: 8594.230716,
          GTQ: 7.790621,
          GYD: 210.771887,
          HKD: 7.84955,
          HNL: 24.636184,
          HRK: 7.018274,
          HTG: 154.830296,
          HUF: 354.197557,
          IDR: 15083.5,
          ILS: 3.549215,
          IMP: 0.817676,
          INR: 82.26555,
          IQD: 1457.841616,
          IRR: 42250.000216,
          ISK: 138.350311,
          JEP: 0.817676,
          JMD: 150.900942,
          JOD: 0.709398,
          JPY: 130.803016,
          KES: 131.302406,
          KGS: 87.420196,
          KHR: 4058.335706,
          KMF: 461.740012,
          KPW: 899.961701,
          KRW: 1286.640192,
          KWD: 0.30601,
          KYD: 0.832472,
          KZT: 464.031519,
          LAK: 16852.885123,
          LBP: 14993.823334,
          LKR: 319.6433,
          LRD: 161.049655,
          LSL: 18.420098,
          LTL: 2.95274,
          LVL: 0.60489,
          LYD: 4.787581,
          MAD: 10.212608,
          MDL: 18.517396,
          MGA: 4361.822799,
          MKD: 56.883961,
          MMK: 2097.71263,
          MNT: 3530.132881,
          MOP: 8.076914,
          MRO: 356.999828,
          MUR: 46.560333,
          MVR: 15.399323,
          MWK: 1026.029612,
          MXN: 18.585498,
          MYR: 4.417977,
          MZN: 63.098038,
          NAD: 18.420396,
          NGN: 460.479745,
          NIO: 36.533287,
          NOK: 10.404565,
          NPR: 131.398814,
          NZD: 1.60104,
          OMR: 0.384974,
          PAB: 0.998889,
          PEN: 3.768265,
          PGK: 3.519455,
          PHP: 54.289727,
          PKR: 282.54771,
          PLN: 4.322409,
          PYG: 7177.494903,
          QAR: 3.641014,
          RON: 4.545175,
          RSD: 108.290184,
          RUB: 76.000126,
          RWF: 1096.890628,
          SAR: 3.756421,
          SBD: 8.200165,
          SCR: 13.395173,
          SDG: 593.488667,
          SEK: 10.334445,
          SGD: 1.327986,
          SHP: 1.21675,
          SLE: 21.119445,
          SLL: 19749.999802,
          SOS: 569.498393,
          SRD: 34.792388,
          STD: 20697.981008,
          SVC: 8.739335,
          SYP: 2512.511631,
          SZL: 18.126294,
          THB: 34.050031,
          TJS: 10.92224,
          TMT: 3.5,
          TND: 3.12896,
          TOP: 2.35335,
          TRY: 19.062599,
          TTD: 6.792675,
          TWD: 30.145503,
          TZS: 2332.00022,
          UAH: 36.710798,
          UGX: 3769.99798,
          USD: 1,
          UYU: 38.892206,
          UZS: 11377.850432,
          VEF: 2418572.632717,
          VES: 24.095671,
          VND: 23500,
          VUV: 119.433656,
          WST: 2.715521,
          XAF: 602.45943,
          XAG: 0.043382,
          XAU: 0.000502,
          XCD: 2.70255,
          XDR: 0.748391,
          XOF: 602.45943,
          XPF: 112.249559,
          YER: 250.30415,
          ZAR: 18.11842,
          ZMK: 9001.199737,
          ZMW: 21.114555,
          ZWL: 321.999592,
        },
      },
      {
        base: "USD",
        date: "2023-03-22",
        rates: {
          AED: 3.672799,
          AFN: 87.111564,
          ALL: 105.77502,
          AMD: 388.169691,
          ANG: 1.802343,
          AOA: 504.800986,
          ARS: 205.372903,
          AUD: 1.49277,
          AWG: 1.8,
          AZN: 1.70433,
          BAM: 1.811488,
          BBD: 2.003631,
          BDT: 105.115939,
          BGN: 1.80109,
          BHD: 0.377162,
          BIF: 2079.933312,
          BMD: 1,
          BND: 1.333951,
          BOB: 6.93095,
          BRL: 5.237601,
          BSD: 1,
          BTC: 3.663455e-5,
          BTN: 82.631408,
          BWP: 13.253804,
          BYN: 2.524244,
          BYR: 19600,
          BZD: 2.005076,
          CAD: 1.371745,
          CDF: 2051.99992,
          CHF: 0.916625,
          CLF: 0.029382,
          CLP: 810.749881,
          CNY: 6.880699,
          COP: 4773,
          CRC: 539.804566,
          CUC: 1,
          CUP: 26.5,
          CVE: 102.12427,
          CZK: 21.810598,
          DJF: 177.719786,
          DKK: 6.850199,
          DOP: 54.767749,
          DZD: 135.760313,
          EGP: 30.902898,
          ERN: 15,
          ETB: 53.851711,
          EUR: 0.91996,
          FJD: 2.2095,
          FKP: 0.820009,
          GBP: 0.814399,
          GEL: 2.574987,
          GGP: 0.820009,
          GHS: 12.301209,
          GIP: 0.820009,
          GMD: 61.497439,
          GNF: 8604.520847,
          GTQ: 7.79975,
          GYD: 211.012828,
          HKD: 7.84746,
          HNL: 24.664487,
          HRK: 7.018274,
          HTG: 155.013199,
          HUF: 356.234987,
          IDR: 15258,
          ILS: 3.62347,
          IMP: 0.820009,
          INR: 82.52605,
          IQD: 1459.572103,
          IRR: 42250.000366,
          ISK: 137.910317,
          JEP: 0.820009,
          JMD: 150.834522,
          JOD: 0.709399,
          JPY: 131.18396,
          KES: 130.902851,
          KGS: 87.419664,
          KHR: 4060.575186,
          KMF: 461.73981,
          KPW: 900.009128,
          KRW: 1298.949698,
          KWD: 0.30621,
          KYD: 0.833387,
          KZT: 464.666954,
          LAK: 16896.216366,
          LBP: 15011.068402,
          LKR: 322.518501,
          LRD: 161.050271,
          LSL: 18.420016,
          LTL: 2.95274,
          LVL: 0.60489,
          LYD: 4.797784,
          MAD: 10.311672,
          MDL: 18.595841,
          MGA: 4354.188858,
          MKD: 56.745394,
          MMK: 2100.047236,
          MNT: 3529.648625,
          MOP: 8.084935,
          MRO: 356.999828,
          MUR: 46.485792,
          MVR: 15.403608,
          MWK: 1026.536378,
          MXN: 18.600903,
          MYR: 4.454968,
          MZN: 63.083311,
          NAD: 18.420223,
          NGN: 460.449752,
          NIO: 36.575313,
          NOK: 10.446825,
          NPR: 132.210439,
          NZD: 1.604685,
          OMR: 0.384994,
          PAB: 1.000037,
          PEN: 3.782522,
          PGK: 3.569642,
          PHP: 54.420222,
          PKR: 283.019682,
          PLN: 4.309824,
          PYG: 7174.547307,
          QAR: 3.641,
          RON: 4.524967,
          RSD: 107.929356,
          RUB: 76.925002,
          RWF: 1095.80794,
          SAR: 3.756902,
          SBD: 8.200165,
          SCR: 13.133288,
          SDG: 593.499887,
          SEK: 10.299625,
          SGD: 1.329685,
          SHP: 1.21675,
          SLE: 20.905268,
          SLL: 19750.000193,
          SOS: 569.496724,
          SRD: 34.878502,
          STD: 20697.981008,
          SVC: 8.750863,
          SYP: 2511.928512,
          SZL: 18.423478,
          THB: 34.220359,
          TJS: 10.905655,
          TMT: 3.5,
          TND: 3.129037,
          TOP: 2.358898,
          TRY: 19.048904,
          TTD: 6.796647,
          TWD: 30.36503,
          TZS: 2331.999547,
          UAH: 36.933757,
          UGX: 3775.157684,
          USD: 1,
          UYU: 39.252916,
          UZS: 11386.86537,
          VEF: 2416368.058658,
          VES: 24.095671,
          VND: 23525,
          VUV: 118.905645,
          WST: 2.71655,
          XAF: 607.604316,
          XAG: 0.043517,
          XAU: 0.000508,
          XCD: 2.70255,
          XDR: 0.749317,
          XOF: 607.595875,
          XPF: 112.250043,
          YER: 250.300406,
          ZAR: 18.226202,
          ZMK: 9001.200846,
          ZMW: 20.901218,
          ZWL: 321.999592,
        },
      },
      {
        base: "USD",
        date: "2023-03-21",
        rates: {
          AED: 3.672993,
          AFN: 87.021059,
          ALL: 105.946748,
          AMD: 389.646535,
          ANG: 1.802208,
          AOA: 504.800955,
          ARS: 204.996027,
          AUD: 1.497544,
          AWG: 1.8,
          AZN: 1.652639,
          BAM: 1.814454,
          BBD: 2.003525,
          BDT: 107.00436,
          BGN: 1.814534,
          BHD: 0.377034,
          BIF: 2079.653407,
          BMD: 1,
          BND: 1.33564,
          BOB: 6.905093,
          BRL: 5.242401,
          BSD: 1,
          BTC: 3.547657e-5,
          BTN: 82.602444,
          BWP: 13.315459,
          BYN: 2.523993,
          BYR: 19600,
          BZD: 2.00488,
          CAD: 1.371025,
          CDF: 2052.000184,
          CHF: 0.92168,
          CLF: 0.029817,
          CLP: 822.750309,
          CNY: 6.884498,
          COP: 4813.43,
          CRC: 540.021523,
          CUC: 1,
          CUP: 26.5,
          CVE: 102.299821,
          CZK: 22.083963,
          DJF: 177.720017,
          DKK: 6.91165,
          DOP: 54.790708,
          DZD: 135.557846,
          EGP: 30.892038,
          ERN: 15,
          ETB: 53.846739,
          EUR: 0.92817,
          FJD: 2.216901,
          FKP: 0.815928,
          GBP: 0.818045,
          GEL: 2.574976,
          GGP: 0.815928,
          GHS: 12.299842,
          GIP: 0.815928,
          GMD: 61.501015,
          GNF: 8603.686764,
          GTQ: 7.798497,
          GYD: 210.993599,
          HKD: 7.845745,
          HNL: 24.662999,
          HRK: 7.018274,
          HTG: 154.810279,
          HUF: 361.085001,
          IDR: 15301,
          ILS: 3.65833,
          IMP: 0.815928,
          INR: 82.67725,
          IQD: 1459.504592,
          IRR: 42249.999844,
          ISK: 139.32021,
          JEP: 0.815928,
          JMD: 151.022339,
          JOD: 0.7099,
          JPY: 132.350971,
          KES: 130.496888,
          KGS: 87.420033,
          KHR: 4057.815051,
          KMF: 461.739799,
          KPW: 899.983174,
          KRW: 1303.804984,
          KWD: 0.30663,
          KYD: 0.833271,
          KZT: 464.635594,
          LAK: 16893.80375,
          LBP: 15009.64839,
          LKR: 323.016977,
          LRD: 161.049743,
          LSL: 18.419928,
          LTL: 2.95274,
          LVL: 0.60489,
          LYD: 4.806568,
          MAD: 10.286202,
          MDL: 18.621393,
          MGA: 4299.98794,
          MKD: 57.209577,
          MMK: 2099.877542,
          MNT: 3531.214072,
          MOP: 8.081733,
          MRO: 356.999828,
          MUR: 46.503208,
          MVR: 15.405966,
          MWK: 1026.347528,
          MXN: 18.62753,
          MYR: 4.472497,
          MZN: 63.100208,
          NAD: 18.41989,
          NGN: 460.419724,
          NIO: 36.57111,
          NOK: 10.54217,
          NPR: 132.165321,
          NZD: 1.614925,
          OMR: 0.385056,
          PAB: 0.999981,
          PEN: 3.777901,
          PGK: 3.569871,
          PHP: 54.230376,
          PKR: 281.615905,
          PLN: 4.349513,
          PYG: 7198.12231,
          QAR: 3.640995,
          RON: 4.569099,
          RSD: 108.875059,
          RUB: 77.250053,
          RWF: 1095.824327,
          SAR: 3.756993,
          SBD: 8.200165,
          SCR: 13.902145,
          SDG: 593.501353,
          SEK: 10.338155,
          SGD: 1.33693,
          SHP: 1.21675,
          SLE: 21.189003,
          SLL: 19749.999812,
          SOS: 569.503969,
          SRD: 34.878497,
          STD: 20697.981008,
          SVC: 8.749339,
          SYP: 2512.538145,
          SZL: 18.603163,
          THB: 34.443962,
          TJS: 10.93979,
          TMT: 3.5,
          TND: 3.129013,
          TOP: 2.350598,
          TRY: 19.038203,
          TTD: 6.789992,
          TWD: 30.495099,
          TZS: 2339.999965,
          UAH: 36.930263,
          UGX: 3761.016792,
          USD: 1,
          UYU: 39.512014,
          UZS: 11378.60655,
          VEF: 2414813.862979,
          VES: 24.095671,
          VND: 23585,
          VUV: 119.079029,
          WST: 2.713076,
          XAF: 608.579486,
          XAG: 0.044573,
          XAU: 0.000515,
          XCD: 2.70255,
          XDR: 0.749228,
          XOF: 608.585132,
          XPF: 112.249736,
          YER: 250.297158,
          ZAR: 18.54757,
          ZMK: 9001.198647,
          ZMW: 20.774654,
          ZWL: 321.999592,
        },
      },
      {
        base: "USD",
        date: "2023-03-20",
        rates: {
          AED: 3.672798,
          AFN: 87.416179,
          ALL: 106.823318,
          AMD: 388.480454,
          ANG: 1.802245,
          AOA: 504.800999,
          ARS: 204.529598,
          AUD: 1.488804,
          AWG: 1.8,
          AZN: 1.706379,
          BAM: 1.826621,
          BBD: 2.003568,
          BDT: 105.284786,
          BGN: 1.825027,
          BHD: 0.377057,
          BIF: 2079.628661,
          BMD: 1,
          BND: 1.339473,
          BOB: 6.904571,
          BRL: 5.237203,
          BSD: 0.999963,
          BTC: 3.6012852e-5,
          BTN: 82.607823,
          BWP: 13.279849,
          BYN: 2.524096,
          BYR: 19600,
          BZD: 2.005006,
          CAD: 1.36715,
          CDF: 2051.999951,
          CHF: 0.92928,
          CLF: 0.029938,
          CLP: 826.079807,
          CNY: 6.878697,
          COP: 4816.98,
          CRC: 541.691572,
          CUC: 1,
          CUP: 26.5,
          CVE: 102.976955,
          CZK: 22.388968,
          DJF: 177.720592,
          DKK: 6.944425,
          DOP: 54.888299,
          DZD: 135.848987,
          EGP: 30.8981,
          ERN: 15,
          ETB: 53.84351,
          EUR: 0.93294,
          FJD: 2.229198,
          FKP: 0.821178,
          GBP: 0.814765,
          GEL: 2.574983,
          GGP: 0.821178,
          GHS: 12.399836,
          GIP: 0.821178,
          GMD: 61.491881,
          GNF: 8605.384202,
          GTQ: 7.798491,
          GYD: 210.994266,
          HKD: 7.84285,
          HNL: 24.663778,
          HRK: 7.018274,
          HTG: 154.808824,
          HUF: 368.409912,
          IDR: 15347,
          ILS: 3.66713,
          IMP: 0.821178,
          INR: 82.52935,
          IQD: 1459.457945,
          IRR: 42249.999744,
          ISK: 140.040491,
          JEP: 0.821178,
          JMD: 151.234683,
          JOD: 0.709401,
          JPY: 131.400499,
          KES: 130.000173,
          KGS: 87.41952,
          KHR: 4053.34728,
          KMF: 461.739739,
          KPW: 900,
          KRW: 1305.290078,
          KWD: 0.30652,
          KYD: 0.833364,
          KZT: 464.672974,
          LAK: 16883.650559,
          LBP: 15009.432729,
          LKR: 345.009223,
          LRD: 161.050261,
          LSL: 18.420075,
          LTL: 2.95274,
          LVL: 0.60489,
          LYD: 4.8229,
          MAD: 10.357423,
          MDL: 18.63571,
          MGA: 4301.697891,
          MKD: 57.46167,
          MMK: 2099.988793,
          MNT: 3532.359561,
          MOP: 8.077778,
          MRO: 356.999828,
          MUR: 46.649884,
          MVR: 15.40029,
          MWK: 1026.392962,
          MXN: 18.8198,
          MYR: 4.486001,
          MZN: 63.034371,
          NAD: 18.420402,
          NGN: 460.389874,
          NIO: 36.573691,
          NOK: 10.650075,
          NPR: 132.172143,
          NZD: 1.602095,
          OMR: 0.384989,
          PAB: 0.999981,
          PEN: 3.79016,
          PGK: 3.523797,
          PHP: 54.249519,
          PKR: 281.884806,
          PLN: 4.387054,
          PYG: 7207.299761,
          QAR: 3.640985,
          RON: 4.592705,
          RSD: 109.453029,
          RUB: 77.499295,
          RWF: 1092.816049,
          SAR: 3.756056,
          SBD: 8.200165,
          SCR: 13.902755,
          SDG: 593.495129,
          SEK: 10.37083,
          SGD: 1.3377,
          SHP: 1.21675,
          SLE: 20.595084,
          SLL: 19750.000071,
          SOS: 569.496467,
          SRD: 34.878498,
          STD: 20697.981008,
          SVC: 8.750146,
          SYP: 2511.947529,
          SZL: 18.462516,
          THB: 34.046502,
          TJS: 10.939835,
          TMT: 3.5,
          TND: 3.129029,
          TOP: 2.345597,
          TRY: 19.017045,
          TTD: 6.785961,
          TWD: 30.52097,
          TZS: 2332.00002,
          UAH: 36.933465,
          UGX: 3761.954573,
          USD: 1,
          UYU: 39.692732,
          UZS: 11377.337785,
          VEF: 2407075.129695,
          VES: 24.095671,
          VND: 23585,
          VUV: 118.621947,
          WST: 2.724076,
          XAF: 612.671847,
          XAG: 0.044418,
          XAU: 0.000506,
          XCD: 2.70255,
          XDR: 0.749309,
          XOF: 612.668986,
          XPF: 112.249774,
          YER: 250.297478,
          ZAR: 18.517465,
          ZMK: 9001.201488,
          ZMW: 20.624054,
          ZWL: 321.999592,
        },
      },
      {
        base: "USD",
        date: "2023-03-19",
        rates: {
          AED: 3.672685,
          AFN: 86.239043,
          ALL: 106.395807,
          AMD: 382.728074,
          ANG: 1.777005,
          AOA: 504.800993,
          ARS: 202.014431,
          AUD: 1.48968,
          AWG: 1.8,
          AZN: 1.709376,
          BAM: 1.814202,
          BBD: 1.99091,
          BDT: 105.746487,
          BGN: 1.824867,
          BHD: 0.371968,
          BIF: 2050.554241,
          BMD: 1,
          BND: 1.324243,
          BOB: 6.813228,
          BRL: 5.279402,
          BSD: 0.98604,
          BTC: 3.5675543e-5,
          BTN: 81.43036,
          BWP: 13.085664,
          BYN: 2.488846,
          BYR: 19600,
          BZD: 1.987477,
          CAD: 1.37123,
          CDF: 2052.000346,
          CHF: 0.92699,
          CLF: 0.029531,
          CLP: 814.841612,
          CNY: 6.887203,
          COP: 4764.714067,
          CRC: 535.411159,
          CUC: 1,
          CUP: 26.5,
          CVE: 102.27726,
          CZK: 22.500704,
          DJF: 175.55772,
          DKK: 6.970803,
          DOP: 54.04202,
          DZD: 134.803581,
          EGP: 30.769447,
          ERN: 15,
          ETB: 53.087519,
          EUR: 0.93647,
          FJD: 2.21097,
          FKP: 0.821071,
          GBP: 0.820569,
          GEL: 2.575023,
          GGP: 0.821071,
          GHS: 12.275868,
          GIP: 0.821071,
          GMD: 61.500744,
          GNF: 8491.257363,
          GTQ: 7.690738,
          GYD: 208.051575,
          HKD: 7.848975,
          HNL: 24.318909,
          HRK: 7.018274,
          HTG: 152.386253,
          HUF: 372.025007,
          IDR: 15374,
          ILS: 3.66645,
          IMP: 0.821071,
          INR: 82.540503,
          IQD: 1439.07982,
          IRR: 42249.999718,
          ISK: 140.150436,
          JEP: 0.821071,
          JMD: 149.584899,
          JOD: 0.709906,
          JPY: 132.290136,
          KES: 127.192616,
          KGS: 87.41983,
          KHR: 3998.886879,
          KMF: 461.739744,
          KPW: 899.999949,
          KRW: 1308.750033,
          KWD: 0.30686,
          KYD: 0.821669,
          KZT: 451.964195,
          LAK: 16644.868049,
          LBP: 14800.148416,
          LKR: 334.27021,
          LRD: 161.050022,
          LSL: 18.419675,
          LTL: 2.95274,
          LVL: 0.60489,
          LYD: 4.754881,
          MAD: 10.211307,
          MDL: 18.388757,
          MGA: 4238.207875,
          MKD: 57.154121,
          MMK: 2070.590418,
          MNT: 3532.350166,
          MOP: 7.972543,
          MRO: 356.999828,
          MUR: 45.998701,
          MVR: 15.393685,
          MWK: 1032.326887,
          MXN: 18.849205,
          MYR: 4.485503,
          MZN: 63.070907,
          NAD: 18.41998,
          NGN: 460.440037,
          NIO: 36.065118,
          NOK: 10.67736,
          NPR: 130.288948,
          NZD: 1.59427,
          OMR: 0.38498,
          PAB: 0.98604,
          PEN: 3.736561,
          PGK: 3.474792,
          PHP: 54.724954,
          PKR: 277.81012,
          PLN: 4.405049,
          PYG: 7110.05983,
          QAR: 3.64101,
          RON: 4.610395,
          RSD: 109.994981,
          RUB: 76.95972,
          RWF: 1079.263485,
          SAR: 3.756497,
          SBD: 8.200165,
          SCR: 13.902034,
          SDG: 593.499955,
          SEK: 10.509897,
          SGD: 1.340565,
          SHP: 1.21675,
          SLE: 20.5951,
          SLL: 19750.000046,
          SOS: 569.529093,
          SRD: 34.7995,
          STD: 20697.981008,
          SVC: 8.627615,
          SYP: 2512.031413,
          SZL: 18.103056,
          THB: 34.073495,
          TJS: 10.747553,
          TMT: 3.5,
          TND: 3.128988,
          TOP: 2.36005,
          TRY: 19.01878,
          TTD: 6.691712,
          TWD: 30.632961,
          TZS: 2307.314132,
          UAH: 36.415751,
          UGX: 3694.63383,
          USD: 1,
          UYU: 39.005612,
          UZS: 11215.620797,
          VEF: 2407075.711653,
          VES: 24.095671,
          VND: 23590,
          VUV: 118.621982,
          WST: 2.724077,
          XAF: 608.506099,
          XAG: 0.04466,
          XAU: 0.000506,
          XCD: 2.70255,
          XDR: 0.742915,
          XOF: 608.506099,
          XPF: 112.249872,
          YER: 250.297209,
          ZAR: 18.339299,
          ZMK: 9001.1992,
          ZMW: 20.238393,
          ZWL: 321.999592,
        },
      },
      {
        base: "USD",
        date: "2023-03-18",
        rates: {
          AED: 3.672904,
          AFN: 86.239043,
          ALL: 106.395807,
          AMD: 382.728074,
          ANG: 1.777005,
          AOA: 504.801041,
          ARS: 200.102129,
          AUD: 1.493429,
          AWG: 1.8,
          AZN: 1.70397,
          BAM: 1.814202,
          BBD: 1.99091,
          BDT: 105.746487,
          BGN: 1.824867,
          BHD: 0.371968,
          BIF: 2050.554241,
          BMD: 1,
          BND: 1.324243,
          BOB: 6.813228,
          BRL: 5.279404,
          BSD: 0.98604,
          BTC: 3.7049345e-5,
          BTN: 81.43036,
          BWP: 13.085664,
          BYN: 2.488846,
          BYR: 19600,
          BZD: 1.987477,
          CAD: 1.37705,
          CDF: 2052.000362,
          CHF: 0.923178,
          CLF: 0.029531,
          CLP: 814.841612,
          CNY: 6.887204,
          COP: 4764.714067,
          CRC: 535.411159,
          CUC: 1,
          CUP: 26.5,
          CVE: 102.27726,
          CZK: 22.49804,
          DJF: 175.55772,
          DKK: 6.980304,
          DOP: 54.04202,
          DZD: 134.803581,
          EGP: 30.478178,
          ERN: 15,
          ETB: 53.087519,
          EUR: 0.93005,
          FJD: 2.21104,
          FKP: 0.82366,
          GBP: 0.821187,
          GEL: 2.57504,
          GGP: 0.82366,
          GHS: 12.275868,
          GIP: 0.82366,
          GMD: 61.503853,
          GNF: 8491.257363,
          GTQ: 7.690738,
          GYD: 208.051575,
          HKD: 7.85005,
          HNL: 24.318909,
          HRK: 7.018274,
          HTG: 152.386253,
          HUF: 372.960388,
          IDR: 15374,
          ILS: 3.66689,
          IMP: 0.82366,
          INR: 82.540504,
          IQD: 1439.07982,
          IRR: 42250.000352,
          ISK: 140.150386,
          JEP: 0.82366,
          JMD: 149.584899,
          JOD: 0.709904,
          JPY: 131.84504,
          KES: 127.192616,
          KGS: 87.420385,
          KHR: 3998.886879,
          KMF: 461.740384,
          KPW: 900.042443,
          KRW: 1308.750384,
          KWD: 0.30686,
          KYD: 0.821669,
          KZT: 451.964195,
          LAK: 16644.868049,
          LBP: 14800.148416,
          LKR: 334.27021,
          LRD: 161.050382,
          LSL: 18.420382,
          LTL: 2.95274,
          LVL: 0.60489,
          LYD: 4.754881,
          MAD: 10.211307,
          MDL: 18.388757,
          MGA: 4238.207875,
          MKD: 57.154121,
          MMK: 2070.590418,
          MNT: 3540.840133,
          MOP: 7.972543,
          MRO: 356.999828,
          MUR: 45.998701,
          MVR: 15.403741,
          MWK: 1032.326887,
          MXN: 18.914039,
          MYR: 4.485504,
          MZN: 63.103732,
          NAD: 18.420377,
          NGN: 460.440377,
          NIO: 36.065118,
          NOK: 10.70247,
          NPR: 130.288948,
          NZD: 1.597062,
          OMR: 0.38498,
          PAB: 0.98604,
          PEN: 3.736561,
          PGK: 3.474792,
          PHP: 54.725039,
          PKR: 277.81012,
          PLN: 4.40835,
          PYG: 7110.05983,
          QAR: 3.641038,
          RON: 4.615204,
          RSD: 109.995038,
          RUB: 76.960373,
          RWF: 1079.263485,
          SAR: 3.754318,
          SBD: 8.200165,
          SCR: 13.902038,
          SDG: 593.503678,
          SEK: 10.639965,
          SGD: 1.341504,
          SHP: 1.21675,
          SLE: 20.595511,
          SLL: 19750.000338,
          SOS: 569.503664,
          SRD: 34.799504,
          STD: 20697.981008,
          SVC: 8.627615,
          SYP: 2512.023722,
          SZL: 18.103056,
          THB: 34.076038,
          TJS: 10.747553,
          TMT: 3.5,
          TND: 3.129038,
          TOP: 2.36005,
          TRY: 19.020904,
          TTD: 6.691712,
          TWD: 30.633038,
          TZS: 2307.314132,
          UAH: 36.415751,
          UGX: 3694.63383,
          USD: 1,
          UYU: 39.005612,
          UZS: 11215.620797,
          VEF: 2407079.31584,
          VES: 24.095671,
          VND: 23590,
          VUV: 119.733327,
          WST: 2.73297,
          XAF: 608.506099,
          XAG: 0.044256,
          XAU: 0.000503,
          XCD: 2.70255,
          XDR: 0.742915,
          XOF: 608.506099,
          XPF: 112.250364,
          YER: 250.303593,
          ZAR: 18.783525,
          ZMK: 9001.203589,
          ZMW: 20.238393,
          ZWL: 321.999592,
        },
      },
      {
        base: "USD",
        date: "2023-03-17",
        rates: {
          AED: 3.672685,
          AFN: 87.148073,
          ALL: 107.49262,
          AMD: 386.746028,
          ANG: 1.795736,
          AOA: 504.801041,
          ARS: 201.227958,
          AUD: 1.47929,
          AWG: 1.8,
          AZN: 1.70397,
          BAM: 1.833247,
          BBD: 2.011433,
          BDT: 106.859138,
          BGN: 1.833118,
          BHD: 0.376608,
          BIF: 2072.120207,
          BMD: 1,
          BND: 1.33817,
          BOB: 6.88469,
          BRL: 5.279304,
          BSD: 0.996204,
          BTC: 3.6464348e-5,
          BTN: 82.285232,
          BWP: 13.22304,
          BYN: 2.514974,
          BYR: 19600,
          BZD: 2.007966,
          CAD: 1.37695,
          CDF: 2052.000362,
          CHF: 0.923041,
          CLF: 0.030102,
          CLP: 830.610396,
          CNY: 6.887104,
          COP: 4850.5,
          CRC: 541.037081,
          CUC: 1,
          CUP: 26.5,
          CVE: 104.250394,
          CZK: 22.49804,
          DJF: 177.367509,
          DKK: 6.980204,
          DOP: 54.609876,
          DZD: 135.976608,
          EGP: 30.478178,
          ERN: 15,
          ETB: 53.645345,
          EUR: 0.93005,
          FJD: 2.21104,
          FKP: 0.825823,
          GBP: 0.820883,
          GEL: 2.57504,
          GGP: 0.825823,
          GHS: 12.45039,
          GIP: 0.825823,
          GMD: 61.503853,
          GNF: 8580.561102,
          GTQ: 7.771477,
          GYD: 210.23377,
          HKD: 7.84995,
          HNL: 24.67504,
          HRK: 7.018274,
          HTG: 153.987477,
          HUF: 372.950388,
          IDR: 15375.05,
          ILS: 3.66689,
          IMP: 0.825823,
          INR: 82.53265,
          IQD: 1461,
          IRR: 42250.000352,
          ISK: 140.150386,
          JEP: 0.825823,
          JMD: 151.156687,
          JOD: 0.709904,
          JPY: 131.84504,
          KES: 128.527909,
          KGS: 87.420385,
          KHR: 4054.000351,
          KMF: 461.740384,
          KPW: 900.044866,
          KRW: 1308.750384,
          KWD: 0.30686,
          KYD: 0.83033,
          KZT: 456.728268,
          LAK: 16820.003281,
          LBP: 14955.523269,
          LKR: 337.779444,
          LRD: 161.050382,
          LSL: 18.420382,
          LTL: 2.95274,
          LVL: 0.60489,
          LYD: 4.803899,
          MAD: 10.374504,
          MDL: 18.58198,
          MGA: 4282.882064,
          MKD: 57.759364,
          MMK: 2091.935711,
          MNT: 3539.879327,
          MOP: 8.05658,
          MRO: 356.999828,
          MUR: 46.420379,
          MVR: 15.403741,
          MWK: 1043.208459,
          MXN: 18.914304,
          MYR: 4.485504,
          MZN: 63.103732,
          NAD: 18.420377,
          NGN: 460.000344,
          NIO: 36.444419,
          NOK: 10.70247,
          NPR: 131.657981,
          NZD: 1.594134,
          OMR: 0.384271,
          PAB: 0.996382,
          PEN: 3.791504,
          PGK: 3.51142,
          PHP: 54.613039,
          PKR: 281.850375,
          PLN: 4.408445,
          PYG: 7183.355981,
          QAR: 3.641038,
          RON: 4.615204,
          RSD: 109.995038,
          RUB: 76.960373,
          RWF: 1090.604027,
          SAR: 3.756418,
          SBD: 8.200165,
          SCR: 13.125614,
          SDG: 593.503678,
          SEK: 10.462485,
          SGD: 1.341604,
          SHP: 1.21675,
          SLE: 21.202729,
          SLL: 19750.000338,
          SOS: 569.503664,
          SRD: 34.799504,
          STD: 20697.981008,
          SVC: 8.718557,
          SYP: 2512.553093,
          SZL: 18.289677,
          THB: 34.045038,
          TJS: 10.860841,
          TMT: 3.5,
          TND: 3.129038,
          TOP: 2.36005,
          TRY: 19.013604,
          TTD: 6.76209,
          TWD: 30.633104,
          TZS: 2340.000335,
          UAH: 36.799603,
          UGX: 3733.385824,
          USD: 1,
          UYU: 39.414731,
          UZS: 11333.470811,
          VEF: 2399667.669703,
          VES: 24.095671,
          VND: 23590,
          VUV: 119.890853,
          WST: 2.735958,
          XAF: 614.900079,
          XAG: 0.044256,
          XAU: 0.000503,
          XCD: 2.70255,
          XDR: 0.750722,
          XOF: 342.000332,
          XPF: 112.250364,
          YER: 250.303593,
          ZAR: 18.43176,
          ZMK: 9001.203589,
          ZMW: 20.447027,
          ZWL: 321.999592,
        },
      },
      {
        base: "USD",
        date: "2023-03-16",
        rates: {
          AED: 3.672798,
          AFN: 88.999547,
          ALL: 108.34988,
          AMD: 388.629369,
          ANG: 1.80292,
          AOA: 507.504216,
          ARS: 202.950499,
          AUD: 1.500769,
          AWG: 1.805,
          AZN: 1.702384,
          BAM: 1.843564,
          BBD: 2.019908,
          BDT: 107.288289,
          BGN: 1.844644,
          BHD: 0.377058,
          BIF: 2120,
          BMD: 1,
          BND: 1.348302,
          BOB: 6.913064,
          BRL: 5.231099,
          BSD: 1.000386,
          BTC: 3.9936397e-5,
          BTN: 82.728087,
          BWP: 13.285072,
          BYN: 2.52505,
          BYR: 19600,
          BZD: 2.016514,
          CAD: 1.371975,
          CDF: 2052.000205,
          CHF: 0.92927,
          CLF: 0.029911,
          CLP: 825.329986,
          CNY: 6.898499,
          COP: 4851,
          CRC: 543.600185,
          CUC: 1,
          CUP: 26.5,
          CVE: 104.250067,
          CZK: 22.599025,
          DJF: 177.720069,
          DKK: 7.01509,
          DOP: 54.711773,
          DZD: 136.46057,
          EGP: 30.80301,
          ERN: 15,
          ETB: 53.818945,
          EUR: 0.94217,
          FJD: 2.219802,
          FKP: 0.831136,
          GBP: 0.82578,
          GEL: 2.580147,
          GGP: 0.831136,
          GHS: 12.350282,
          GIP: 0.831136,
          GMD: 61.502412,
          GNF: 8639.999845,
          GTQ: 7.798169,
          GYD: 211.077492,
          HKD: 7.849545,
          HNL: 24.685025,
          HRK: 7.018274,
          HTG: 154.059328,
          HUF: 371.86036,
          IDR: 15429,
          ILS: 3.66441,
          IMP: 0.831136,
          INR: 82.62305,
          IQD: 1460,
          IRR: 42274.999855,
          ISK: 141.040211,
          JEP: 0.831136,
          JMD: 152.013875,
          JOD: 0.709597,
          JPY: 133.369497,
          KES: 130.103315,
          KGS: 87.419749,
          KHR: 4053.999547,
          KMF: 467.598478,
          KPW: 899.82076,
          KRW: 1301.944995,
          KWD: 0.30709,
          KYD: 0.83364,
          KZT: 464.845554,
          LAK: 16959.00065,
          LBP: 15249.999453,
          LKR: 342.146688,
          LRD: 160.882409,
          LSL: 18.410113,
          LTL: 2.95274,
          LVL: 0.60489,
          LYD: 4.810138,
          MAD: 10.433502,
          MDL: 18.626813,
          MGA: 4324.999719,
          MKD: 58.059166,
          MMK: 2100.783305,
          MNT: 3537.180286,
          MOP: 8.088209,
          MRO: 356.999828,
          MUR: 47.150163,
          MVR: 15.409858,
          MWK: 1050.000264,
          MXN: 18.772896,
          MYR: 4.504023,
          MZN: 63.102109,
          NAD: 18.409693,
          NGN: 460.000196,
          NIO: 36.549871,
          NOK: 10.752341,
          NPR: 132.365278,
          NZD: 1.612215,
          OMR: 0.38502,
          PAB: 1.000386,
          PEN: 3.803504,
          PGK: 3.525035,
          PHP: 54.604968,
          PKR: 283.375019,
          PLN: 4.42665,
          PYG: 7192.074579,
          QAR: 3.641496,
          RON: 4.634296,
          RSD: 110.52499,
          RUB: 76.496938,
          RWF: 1095.5,
          SAR: 3.757119,
          SBD: 8.233939,
          SCR: 13.177093,
          SDG: 594.501592,
          SEK: 10.50604,
          SGD: 1.346045,
          SHP: 1.216751,
          SLE: 20.467753,
          SLL: 19749.999936,
          SOS: 568.496067,
          SRD: 34.244503,
          STD: 20697.981008,
          SVC: 8.752987,
          SYP: 2511.718636,
          SZL: 18.409864,
          THB: 34.439962,
          TJS: 10.939211,
          TMT: 3.51,
          TND: 3.15825,
          TOP: 2.362803,
          TRY: 19.006983,
          TTD: 6.789582,
          TWD: 30.563995,
          TZS: 2331.999931,
          UAH: 36.76564,
          UGX: 3749.681871,
          USD: 1,
          UYU: 39.278527,
          UZS: 11434.999806,
          VEF: 2394103.56367,
          VES: 23.9703,
          VND: 23575,
          VUV: 119.172109,
          WST: 2.723914,
          XAF: 618.348745,
          XAG: 0.045998,
          XAU: 0.00052,
          XCD: 2.70255,
          XDR: 0.753801,
          XOF: 617.999825,
          XPF: 113.549737,
          YER: 250.350314,
          ZAR: 18.37255,
          ZMK: 9001.205638,
          ZMW: 20.528047,
          ZWL: 321.999592,
        },
      },
      {
        base: "USD",
        date: "2023-03-15",
        rates: {
          AED: 3.672952,
          AFN: 88.000063,
          ALL: 108.904105,
          AMD: 388.398235,
          ANG: 1.804128,
          AOA: 507.503781,
          ARS: 202.567101,
          AUD: 1.511031,
          AWG: 1.8,
          AZN: 1.702564,
          BAM: 1.849246,
          BBD: 2.005768,
          BDT: 105.573705,
          BGN: 1.848965,
          BHD: 0.377061,
          BIF: 2080.5,
          BMD: 1,
          BND: 1.351754,
          BOB: 6.912691,
          BRL: 5.288599,
          BSD: 1.00104,
          BTC: 4.1086182e-5,
          BTN: 82.847336,
          BWP: 13.305027,
          BYN: 2.52735,
          BYR: 19600,
          BZD: 2.00729,
          CAD: 1.37664,
          CDF: 2051.999737,
          CHF: 0.93125,
          CLF: 0.029739,
          CLP: 820.599323,
          CNY: 6.906897,
          COP: 4848.75,
          CRC: 545.953101,
          CUC: 1,
          CUP: 26.5,
          CVE: 103.249876,
          CZK: 22.724986,
          DJF: 177.720166,
          DKK: 7.03484,
          DOP: 54.849861,
          DZD: 136.716381,
          EGP: 30.907501,
          ERN: 15,
          ETB: 53.809763,
          EUR: 0.94491,
          FJD: 2.226699,
          FKP: 0.823268,
          GBP: 0.828615,
          GEL: 2.585012,
          GGP: 0.823268,
          GHS: 12.450229,
          GIP: 0.823268,
          GMD: 61.49346,
          GNF: 8639.999987,
          GTQ: 7.803148,
          GYD: 211.228986,
          HKD: 7.848605,
          HNL: 24.674996,
          HRK: 7.018274,
          HTG: 154.319674,
          HUF: 374.494756,
          IDR: 15488,
          ILS: 3.62583,
          IMP: 0.823268,
          INR: 82.71595,
          IQD: 1461,
          IRR: 42274.999997,
          ISK: 141.820373,
          JEP: 0.823268,
          JMD: 152.586443,
          JOD: 0.709599,
          JPY: 132.9355,
          KES: 130.105413,
          KGS: 87.417704,
          KHR: 4054.000103,
          KMF: 459.506766,
          KPW: 899.934993,
          KRW: 1312.864968,
          KWD: 0.306959,
          KYD: 0.83433,
          KZT: 464.393445,
          LAK: 16958.999721,
          LBP: 15356.847551,
          LKR: 337.902665,
          LRD: 160.898411,
          LSL: 18.410175,
          LTL: 2.95274,
          LVL: 0.60489,
          LYD: 4.809765,
          MAD: 10.433497,
          MDL: 18.647554,
          MGA: 4322.000013,
          MKD: 58.25385,
          MMK: 2102.287233,
          MNT: 3532.312314,
          MOP: 8.09232,
          MRO: 356.999828,
          MUR: 46.84991,
          MVR: 15.410463,
          MWK: 1050.000301,
          MXN: 19.006898,
          MYR: 4.483801,
          MZN: 63.097226,
          NAD: 18.410323,
          NGN: 459.999571,
          NIO: 36.559855,
          NOK: 10.760675,
          NPR: 132.56301,
          NZD: 1.622365,
          OMR: 0.38495,
          PAB: 1.001106,
          PEN: 3.803501,
          PGK: 3.520191,
          PHP: 55.025021,
          PKR: 283.375005,
          PLN: 4.444765,
          PYG: 7194.387451,
          QAR: 3.6415,
          RON: 4.64508,
          RSD: 110.804982,
          RUB: 40.250123,
          RWF: 1095.5,
          SAR: 3.75496,
          SBD: 8.220396,
          SCR: 13.155757,
          SDG: 594.505277,
          SEK: 10.604545,
          SGD: 1.351155,
          SHP: 1.21675,
          SLE: 20.797255,
          SLL: 19749.99969,
          SOS: 568.498278,
          SRD: 34.812506,
          STD: 20697.981008,
          SVC: 8.759828,
          SYP: 2512.433746,
          SZL: 18.409941,
          THB: 34.569749,
          TJS: 10.931611,
          TMT: 3.5,
          TND: 3.13225,
          TOP: 2.35335,
          TRY: 18.991986,
          TTD: 6.794565,
          TWD: 30.527501,
          TZS: 2342.66803,
          UAH: 36.78655,
          UGX: 3749.019015,
          USD: 1,
          UYU: 39.390329,
          UZS: 11459.999443,
          VEF: 2406187.376298,
          VES: 23.9703,
          VND: 23577.5,
          VUV: 119.078414,
          WST: 2.724333,
          XAF: 620.298708,
          XAG: 0.045839,
          XAU: 0.00052,
          XCD: 2.70255,
          XDR: 0.754357,
          XOF: 611.49631,
          XPF: 113.549652,
          YER: 250.349527,
          ZAR: 18.426505,
          ZMK: 9001.198139,
          ZMW: 20.53869,
          ZWL: 321.999592,
        },
      },
      {
        base: "USD",
        date: "2023-03-14",
        rates: {
          AED: 3.673026,
          AFN: 88.000263,
          ALL: 106.24997,
          AMD: 388.789732,
          ANG: 1.801352,
          AOA: 507.497792,
          ARS: 202.096899,
          AUD: 1.49505,
          AWG: 1.8025,
          AZN: 1.689344,
          BAM: 1.823318,
          BBD: 2.018254,
          BDT: 105.541364,
          BGN: 1.822825,
          BHD: 0.377009,
          BIF: 2081,
          BMD: 1,
          BND: 1.344558,
          BOB: 6.907225,
          BRL: 5.253199,
          BSD: 0.999548,
          BTC: 4.047415e-5,
          BTN: 82.429217,
          BWP: 13.187213,
          BYN: 2.523027,
          BYR: 19600,
          BZD: 2.014879,
          CAD: 1.36894,
          CDF: 2049.99972,
          CHF: 0.914555,
          CLF: 0.029089,
          CLP: 802.650192,
          CNY: 6.871498,
          COP: 4744.6,
          CRC: 545.378269,
          CUC: 1,
          CUP: 26.5,
          CVE: 103.250176,
          CZK: 22.160499,
          DJF: 177.71974,
          DKK: 6.941049,
          DOP: 54.950119,
          DZD: 135.789179,
          EGP: 30.901502,
          ERN: 15,
          ETB: 53.750258,
          EUR: 0.932202,
          FJD: 2.21445,
          FKP: 0.822649,
          GBP: 0.82297,
          GEL: 2.589675,
          GGP: 0.822649,
          GHS: 12.450374,
          GIP: 0.822649,
          GMD: 61.50639,
          GNF: 8812.496279,
          GTQ: 7.791845,
          GYD: 210.908548,
          HKD: 7.84825,
          HNL: 24.670117,
          HRK: 7.018274,
          HTG: 153.93149,
          HUF: 363.429898,
          IDR: 15379,
          ILS: 3.62739,
          IMP: 0.822649,
          INR: 82.30515,
          IQD: 1460,
          IRR: 42250.00008,
          ISK: 140.09876,
          JEP: 0.822649,
          JMD: 152.322264,
          JOD: 0.709401,
          JPY: 134.398006,
          KES: 129.950486,
          KGS: 87.419898,
          KHR: 4052.00003,
          KMF: 459.496617,
          KPW: 899.88174,
          KRW: 1304.905001,
          KWD: 0.30678,
          KYD: 0.833007,
          KZT: 461.086665,
          LAK: 16949.999672,
          LBP: 15274.999966,
          LKR: 339.866783,
          LRD: 160.749623,
          LSL: 18.180613,
          LTL: 2.95274,
          LVL: 0.60489,
          LYD: 4.810154,
          MAD: 10.3625,
          MDL: 18.647189,
          MGA: 4319.999842,
          MKD: 57.411342,
          MMK: 2099.110144,
          MNT: 3538.619672,
          MOP: 8.07958,
          MRO: 356.999828,
          MUR: 47.139601,
          MVR: 15.397801,
          MWK: 1048.999639,
          MXN: 18.615503,
          MYR: 4.486977,
          MZN: 63.098518,
          NAD: 18.179597,
          NGN: 460.530349,
          NIO: 36.549942,
          NOK: 10.552135,
          NPR: 131.882146,
          NZD: 1.605155,
          OMR: 0.384911,
          PAB: 0.999618,
          PEN: 3.786499,
          PGK: 3.525004,
          PHP: 54.873955,
          PKR: 281.649768,
          PLN: 4.387196,
          PYG: 7184.942107,
          QAR: 3.641003,
          RON: 4.58968,
          RSD: 109.33495,
          RUB: 75.749929,
          RWF: 1095.5,
          SAR: 3.755452,
          SBD: 8.233939,
          SCR: 13.200988,
          SDG: 594.504186,
          SEK: 10.47455,
          SGD: 1.34514,
          SHP: 1.21675,
          SLE: 20.718922,
          SLL: 19750.000151,
          SOS: 569.500961,
          SRD: 34.790312,
          STD: 20697.981008,
          SVC: 8.746277,
          SYP: 2511.755499,
          SZL: 18.159823,
          THB: 34.549755,
          TJS: 10.915498,
          TMT: 3.5,
          TND: 3.104964,
          TOP: 2.35505,
          TRY: 18.988135,
          TTD: 6.785065,
          TWD: 30.39403,
          TZS: 2331.99987,
          UAH: 36.736199,
          UGX: 3738.370033,
          USD: 1,
          UYU: 39.256632,
          UZS: 11454.999992,
          VEF: 2408933.842521,
          VES: 24.088053,
          VND: 23585,
          VUV: 119.8054,
          WST: 2.754207,
          XAF: 611.580881,
          XAG: 0.046116,
          XAU: 0.000525,
          XCD: 2.70255,
          XDR: 0.753202,
          XOF: 613.498224,
          XPF: 111.720536,
          YER: 250.299647,
          ZAR: 18.140699,
          ZMK: 9001.206841,
          ZMW: 20.296557,
          ZWL: 321.999592,
        },
      },
      {
        base: "USD",
        date: "2023-03-13",
        rates: {
          AED: 3.672993,
          AFN: 88.503778,
          ALL: 106.401046,
          AMD: 388.898013,
          ANG: 1.803813,
          AOA: 506.439783,
          ARS: 200.645902,
          AUD: 1.50175,
          AWG: 1.8,
          AZN: 1.697429,
          BAM: 1.835587,
          BBD: 2.005284,
          BDT: 105.566453,
          BGN: 1.820883,
          BHD: 0.37698,
          BIF: 2080,
          BMD: 1,
          BND: 1.348344,
          BOB: 6.920532,
          BRL: 5.247197,
          BSD: 1.000859,
          BTC: 4.1538755e-5,
          BTN: 82.172387,
          BWP: 13.221581,
          BYN: 2.526244,
          BYR: 19600,
          BZD: 2.006673,
          CAD: 1.373665,
          CDF: 2049.999878,
          CHF: 0.912589,
          CLF: 0.029132,
          CLP: 803.849937,
          CNY: 6.845701,
          COP: 4765.5,
          CRC: 548.480042,
          CUC: 1,
          CUP: 26.5,
          CVE: 103.319788,
          CZK: 22.129902,
          DJF: 177.719766,
          DKK: 6.942397,
          DOP: 54.999856,
          DZD: 135.907883,
          EGP: 30.903402,
          ERN: 15,
          ETB: 53.797811,
          EUR: 0.93255,
          FJD: 2.217902,
          FKP: 0.830916,
          GBP: 0.82165,
          GEL: 2.580057,
          GGP: 0.830916,
          GHS: 12.450273,
          GIP: 0.830916,
          GMD: 61.449659,
          GNF: 8800.000161,
          GTQ: 7.81349,
          GYD: 211.057257,
          HKD: 7.84465,
          HNL: 24.669903,
          HRK: 7.018274,
          HTG: 153.91113,
          HUF: 366.440244,
          IDR: 15416,
          ILS: 3.623315,
          IMP: 0.830916,
          INR: 82.41765,
          IQD: 1461,
          IRR: 42275.000272,
          ISK: 140.89009,
          JEP: 0.830916,
          JMD: 153.315533,
          JOD: 0.709397,
          JPY: 133.120976,
          KES: 129.792482,
          KGS: 87.419766,
          KHR: 4049.000247,
          KMF: 458.875008,
          KPW: 900,
          KRW: 1296.704978,
          KWD: 0.30653,
          KYD: 0.834104,
          KZT: 456.669561,
          LAK: 16959.999528,
          LBP: 15249.99997,
          LKR: 330.300286,
          LRD: 159.69682,
          LSL: 18.209978,
          LTL: 2.95274,
          LVL: 0.60489,
          LYD: 4.809569,
          MAD: 10.376502,
          MDL: 18.731611,
          MGA: 4321.999871,
          MKD: 57.437298,
          MMK: 2101.805315,
          MNT: 3532.035876,
          MOP: 8.084943,
          MRO: 356.999828,
          MUR: 47.150374,
          MVR: 15.370153,
          MWK: 1050.000035,
          MXN: 18.923415,
          MYR: 4.496798,
          MZN: 63.099323,
          NAD: 18.210187,
          NGN: 460.497497,
          NIO: 36.559808,
          NOK: 10.56479,
          NPR: 131.476007,
          NZD: 1.608865,
          OMR: 0.385021,
          PAB: 1.000849,
          PEN: 3.802979,
          PGK: 3.520146,
          PHP: 55.185019,
          PKR: 281.95023,
          PLN: 4.365192,
          PYG: 7208.754493,
          QAR: 3.641496,
          RON: 4.5884,
          RSD: 109.255443,
          RUB: 75.549858,
          RWF: 1095.5,
          SAR: 3.754068,
          SBD: 8.200165,
          SCR: 13.466039,
          SDG: 594.500947,
          SEK: 10.609195,
          SGD: 1.347298,
          SHP: 1.21675,
          SLE: 20.409914,
          SLL: 19749.999747,
          SOS: 568.503383,
          SRD: 33.967011,
          STD: 20697.981008,
          SVC: 8.757391,
          SYP: 2512.531679,
          SZL: 18.210106,
          THB: 34.529739,
          TJS: 10.929594,
          TMT: 3.51,
          TND: 3.105044,
          TOP: 2.369035,
          TRY: 18.975987,
          TTD: 6.795934,
          TWD: 30.469575,
          TZS: 2342.031986,
          UAH: 36.962567,
          UGX: 3722.318424,
          USD: 1,
          UYU: 39.231697,
          UZS: 11429.999705,
          VEF: 2407994.84251,
          VES: 24.115735,
          VND: 23587.5,
          VUV: 119.389328,
          WST: 2.787821,
          XAF: 615.699396,
          XAG: 0.045864,
          XAU: 0.000523,
          XCD: 2.70255,
          XDR: 0.75411,
          XOF: 611.498647,
          XPF: 111.475055,
          YER: 250.299642,
          ZAR: 18.2189,
          ZMK: 9001.196439,
          ZMW: 20.334577,
          ZWL: 321.999592,
        },
      },
      {
        base: "USD",
        date: "2023-03-12",
        rates: {
          AED: 3.67265,
          AFN: 87.600432,
          ALL: 107.249918,
          AMD: 385.941832,
          ANG: 1.79345,
          AOA: 507.507696,
          ARS: 198.593987,
          AUD: 1.50742,
          AWG: 1.8025,
          AZN: 1.702795,
          BAM: 1.837899,
          BBD: 2.009209,
          BDT: 104.900625,
          BGN: 1.833764,
          BHD: 0.377108,
          BIF: 2068.975238,
          BMD: 1,
          BND: 1.348212,
          BOB: 6.87591,
          BRL: 5.217197,
          BSD: 0.995066,
          BTC: 4.5182899e-5,
          BTN: 81.613118,
          BWP: 13.233097,
          BYN: 2.51177,
          BYR: 19600,
          BZD: 2.005826,
          CAD: 1.37663,
          CDF: 2050.999704,
          CHF: 0.91865,
          CLF: 0.028874,
          CLP: 792.905136,
          CNY: 6.906203,
          COP: 4722.830428,
          CRC: 545.505803,
          CUC: 1,
          CUP: 26.5,
          CVE: 103.613212,
          CZK: 22.125699,
          DJF: 177.183668,
          DKK: 6.96652,
          DOP: 54.907673,
          DZD: 136.582996,
          EGP: 30.628882,
          ERN: 15,
          ETB: 53.531175,
          EUR: 0.93604,
          FJD: 2.236697,
          FKP: 0.830428,
          GBP: 0.827965,
          GEL: 2.58048,
          GGP: 0.830428,
          GHS: 12.289621,
          GIP: 0.830428,
          GMD: 61.249582,
          GNF: 8567.401212,
          GTQ: 7.771461,
          GYD: 209.970399,
          HKD: 7.847645,
          HNL: 24.540713,
          HRK: 7.054794,
          HTG: 152.713433,
          HUF: 357.710023,
          IDR: 15502.55,
          ILS: 3.58553,
          IMP: 0.830428,
          INR: 81.965498,
          IQD: 1452.332848,
          IRR: 42275.00014,
          ISK: 141.279737,
          JEP: 0.830428,
          JMD: 152.22478,
          JOD: 0.709398,
          JPY: 134.982987,
          KES: 128.365362,
          KGS: 87.420224,
          KHR: 4026.687967,
          KMF: 461.84965,
          KPW: 899.967769,
          KRW: 1320.640317,
          KWD: 0.30696,
          KYD: 0.8293,
          KZT: 449.889583,
          LAK: 16789.926232,
          LBP: 14936.616079,
          LKR: 323.431847,
          LRD: 160.495409,
          LSL: 18.245014,
          LTL: 2.95274,
          LVL: 0.60489,
          LYD: 4.827327,
          MAD: 10.339896,
          MDL: 18.658084,
          MGA: 4281.351313,
          MKD: 57.90537,
          MMK: 2089.742987,
          MNT: 3542.785124,
          MOP: 8.045764,
          MRO: 356.999828,
          MUR: 47.061035,
          MVR: 15.359723,
          MWK: 1012.075365,
          MXN: 18.322502,
          MYR: 4.519501,
          MZN: 63.082747,
          NAD: 18.355003,
          NGN: 460.519947,
          NIO: 36.395245,
          NOK: 10.581141,
          NPR: 130.581215,
          NZD: 1.623363,
          OMR: 0.385096,
          PAB: 0.99516,
          PEN: 3.766292,
          PGK: 3.507024,
          PHP: 55.179846,
          PKR: 279.298971,
          PLN: 4.3817,
          PYG: 7163.463797,
          QAR: 3.641056,
          RON: 4.602765,
          RSD: 109.98026,
          RUB: 76.203321,
          RWF: 1086.970822,
          SAR: 3.754298,
          SBD: 8.198925,
          SCR: 13.037636,
          SDG: 593.999699,
          SEK: 10.66087,
          SGD: 1.347515,
          SHP: 1.21675,
          SLE: 21.081375,
          SLL: 19750.00006,
          SOS: 569.520974,
          SRD: 34.476975,
          STD: 20697.981008,
          SVC: 8.707419,
          SYP: 2512.4607,
          SZL: 18.23897,
          THB: 34.626022,
          TJS: 10.866607,
          TMT: 3.51,
          TND: 3.122032,
          TOP: 2.36955,
          TRY: 18.97212,
          TTD: 6.763144,
          TWD: 30.843693,
          TZS: 2328.525114,
          UAH: 36.728375,
          UGX: 3698.726683,
          USD: 1,
          UYU: 38.988864,
          UZS: 11334.868205,
          VEF: 2407751.369323,
          VES: 24.104859,
          VND: 23675,
          VUV: 120.34239,
          WST: 2.748473,
          XAF: 616.454447,
          XAG: 0.048427,
          XAU: 0.000533,
          XCD: 2.70255,
          XDR: 0.748203,
          XOF: 616.454447,
          XPF: 112.250104,
          YER: 250.325047,
          ZAR: 18.232199,
          ZMK: 9001.213024,
          ZMW: 20.101489,
          ZWL: 321.999592,
        },
      },
      {
        base: "USD",
        date: "2023-03-11",
        rates: {
          AED: 3.672704,
          AFN: 87.600432,
          ALL: 107.249918,
          AMD: 385.941832,
          ANG: 1.79345,
          AOA: 507.503981,
          ARS: 199.375934,
          AUD: 1.520219,
          AWG: 1.8025,
          AZN: 1.70397,
          BAM: 1.837899,
          BBD: 2.009209,
          BDT: 104.900625,
          BGN: 1.833764,
          BHD: 0.377108,
          BIF: 2068.975238,
          BMD: 1,
          BND: 1.348212,
          BOB: 6.87591,
          BRL: 5.217204,
          BSD: 0.995066,
          BTC: 4.8717682e-5,
          BTN: 81.613118,
          BWP: 13.233097,
          BYN: 2.51177,
          BYR: 19600,
          BZD: 2.005826,
          CAD: 1.38545,
          CDF: 2051.000362,
          CHF: 0.92006,
          CLF: 0.028874,
          CLP: 792.905136,
          CNY: 6.906204,
          COP: 4722.830428,
          CRC: 545.505803,
          CUC: 1,
          CUP: 26.5,
          CVE: 103.613212,
          CZK: 22.209204,
          DJF: 177.183668,
          DKK: 6.995104,
          DOP: 54.907673,
          DZD: 136.58304,
          EGP: 30.749049,
          ERN: 15,
          ETB: 53.531175,
          EUR: 0.938304,
          FJD: 2.236704,
          FKP: 0.830428,
          GBP: 0.830979,
          GEL: 2.580391,
          GGP: 0.830428,
          GHS: 12.289621,
          GIP: 0.830428,
          GMD: 61.25039,
          GNF: 8567.401212,
          GTQ: 7.771461,
          GYD: 209.970399,
          HKD: 7.84985,
          HNL: 24.540713,
          HRK: 7.054794,
          HTG: 152.713433,
          HUF: 360.03504,
          IDR: 15502.55,
          ILS: 3.58575,
          IMP: 0.830428,
          INR: 81.965504,
          IQD: 1452.332848,
          IRR: 42275.000352,
          ISK: 141.280386,
          JEP: 0.830428,
          JMD: 152.22478,
          JOD: 0.709404,
          JPY: 135.01504,
          KES: 128.365362,
          KGS: 87.420385,
          KHR: 4026.687967,
          KMF: 461.850384,
          KPW: 899.967769,
          KRW: 1320.640384,
          KWD: 0.30696,
          KYD: 0.8293,
          KZT: 449.889583,
          LAK: 16789.926232,
          LBP: 14936.616079,
          LKR: 323.431847,
          LRD: 160.503775,
          LSL: 18.245039,
          LTL: 2.95274,
          LVL: 0.60489,
          LYD: 4.827327,
          MAD: 10.339896,
          MDL: 18.658084,
          MGA: 4281.351313,
          MKD: 57.90537,
          MMK: 2089.742987,
          MNT: 3542.785124,
          MOP: 8.045764,
          MRO: 356.999828,
          MUR: 47.061035,
          MVR: 15.360378,
          MWK: 1012.075365,
          MXN: 18.493404,
          MYR: 4.519504,
          MZN: 63.103732,
          NAD: 18.355039,
          NGN: 460.520377,
          NIO: 36.395245,
          NOK: 10.668125,
          NPR: 130.581215,
          NZD: 1.631322,
          OMR: 0.385096,
          PAB: 0.99516,
          PEN: 3.766292,
          PGK: 3.507024,
          PHP: 55.180375,
          PKR: 279.298971,
          PLN: 4.39715,
          PYG: 7163.463797,
          QAR: 3.641038,
          RON: 4.618804,
          RSD: 109.980373,
          RUB: 76.20369,
          RWF: 1086.970822,
          SAR: 3.75493,
          SBD: 8.198925,
          SCR: 13.037636,
          SDG: 594.00034,
          SEK: 10.70838,
          SGD: 1.348904,
          SHP: 1.21675,
          SLE: 21.081375,
          SLL: 19750.000338,
          SOS: 569.503664,
          SRD: 34.477038,
          STD: 20697.981008,
          SVC: 8.707419,
          SYP: 2512.4607,
          SZL: 18.23897,
          THB: 34.803038,
          TJS: 10.866607,
          TMT: 3.51,
          TND: 3.122038,
          TOP: 2.36955,
          TRY: 18.957504,
          TTD: 6.763144,
          TWD: 30.843704,
          TZS: 2328.525114,
          UAH: 36.728375,
          UGX: 3698.726683,
          USD: 1,
          UYU: 38.988864,
          UZS: 11334.868205,
          VEF: 2407751.369323,
          VES: 24.104859,
          VND: 23675,
          VUV: 120.34239,
          WST: 2.748473,
          XAF: 616.454447,
          XAG: 0.048694,
          XAU: 0.000535,
          XCD: 2.70255,
          XDR: 0.748203,
          XOF: 616.454447,
          XPF: 112.250364,
          YER: 250.325037,
          ZAR: 18.321604,
          ZMK: 9001.203589,
          ZMW: 20.101489,
          ZWL: 321.999592,
        },
      },
      {
        base: "USD",
        date: "2023-03-10",
        rates: {
          AED: 3.67265,
          AFN: 87.575743,
          ALL: 107.219691,
          AMD: 385.833059,
          ANG: 1.792945,
          AOA: 507.503981,
          ARS: 199.375934,
          AUD: 1.52045,
          AWG: 1.8025,
          AZN: 1.70397,
          BAM: 1.837381,
          BBD: 2.008643,
          BDT: 104.87106,
          BGN: 1.833405,
          BHD: 0.377108,
          BIF: 2068.392127,
          BMD: 1,
          BND: 1.347832,
          BOB: 6.873972,
          BRL: 5.217104,
          BSD: 0.994786,
          BTC: 4.9453473e-5,
          BTN: 81.590117,
          BWP: 13.229367,
          BYN: 2.511062,
          BYR: 19600,
          BZD: 2.005261,
          CAD: 1.38585,
          CDF: 2051.000362,
          CHF: 0.920104,
          CLF: 0.028874,
          CLP: 796.720396,
          CNY: 6.906304,
          COP: 4718.37,
          CRC: 545.35206,
          CUC: 1,
          CUP: 26.5,
          CVE: 103.584011,
          CZK: 22.209204,
          DJF: 177.133731,
          DKK: 6.994904,
          DOP: 54.892198,
          DZD: 136.615139,
          EGP: 30.749049,
          ERN: 15,
          ETB: 53.516088,
          EUR: 0.938304,
          FJD: 2.236704,
          FKP: 0.838892,
          GBP: 0.830979,
          GEL: 2.580391,
          GGP: 0.838892,
          GHS: 12.286157,
          GIP: 0.838892,
          GMD: 61.25039,
          GNF: 8564.986613,
          GTQ: 7.769271,
          GYD: 209.911222,
          HKD: 7.84995,
          HNL: 24.533797,
          HRK: 7.054794,
          HTG: 152.670393,
          HUF: 360.040388,
          IDR: 15502.55,
          ILS: 3.58575,
          IMP: 0.838892,
          INR: 82.01855,
          IQD: 1451.923529,
          IRR: 42275.000352,
          ISK: 141.280386,
          JEP: 0.838892,
          JMD: 152.181878,
          JOD: 0.709404,
          JPY: 134.99504,
          KES: 128.329184,
          KGS: 87.420385,
          KHR: 4025.553103,
          KMF: 461.850384,
          KPW: 900.006894,
          KRW: 1320.630384,
          KWD: 0.30696,
          KYD: 0.829067,
          KZT: 449.762788,
          LAK: 16785.194232,
          LBP: 14932.406407,
          LKR: 323.340692,
          LRD: 160.503775,
          LSL: 18.245039,
          LTL: 2.95274,
          LVL: 0.60489,
          LYD: 4.825966,
          MAD: 10.336982,
          MDL: 18.652825,
          MGA: 4280.144676,
          MKD: 57.889051,
          MMK: 2089.154023,
          MNT: 3538.038767,
          MOP: 8.043497,
          MRO: 356.999828,
          MUR: 47.060379,
          MVR: 15.360378,
          MWK: 1011.790126,
          MXN: 18.492704,
          MYR: 4.519504,
          MZN: 63.103732,
          NAD: 18.355039,
          NGN: 460.520377,
          NIO: 36.384988,
          NOK: 10.620305,
          NPR: 130.544413,
          NZD: 1.631322,
          OMR: 0.385096,
          PAB: 0.99488,
          PEN: 3.765231,
          PGK: 3.506036,
          PHP: 55.175039,
          PKR: 279.220255,
          PLN: 4.39745,
          PYG: 7161.444878,
          QAR: 3.641038,
          RON: 4.618804,
          RSD: 110.022651,
          RUB: 76.20369,
          RWF: 1086.664475,
          SAR: 3.75393,
          SBD: 8.198925,
          SCR: 13.037636,
          SDG: 594.00034,
          SEK: 10.70348,
          SGD: 1.348804,
          SHP: 1.21675,
          SLE: 20.721737,
          SLL: 19750.000338,
          SOS: 569.503664,
          SRD: 34.477038,
          STD: 20697.981008,
          SVC: 8.704965,
          SYP: 2512.005964,
          SZL: 18.23383,
          THB: 34.820369,
          TJS: 10.863545,
          TMT: 3.51,
          TND: 3.122038,
          TOP: 2.36955,
          TRY: 18.974604,
          TTD: 6.761238,
          TWD: 30.843038,
          TZS: 2327.868852,
          UAH: 36.718023,
          UGX: 3697.68425,
          USD: 1,
          UYU: 38.977876,
          UZS: 11331.673634,
          VEF: 2411552.800016,
          VES: 24.104859,
          VND: 23675,
          VUV: 120.10517,
          WST: 2.753832,
          XAF: 616.280708,
          XAG: 0.048694,
          XAU: 0.000535,
          XCD: 2.70255,
          XDR: 0.747992,
          XOF: 616.280708,
          XPF: 112.250364,
          YER: 250.325037,
          ZAR: 18.321805,
          ZMK: 9001.203589,
          ZMW: 20.095824,
          ZWL: 321.999592,
        },
      },
      {
        base: "USD",
        date: "2023-03-09",
        rates: {
          AED: 3.673098,
          AFN: 88.000256,
          ALL: 107.849702,
          AMD: 388.489964,
          ANG: 1.803006,
          AOA: 507.505131,
          ARS: 200.348397,
          AUD: 1.517681,
          AWG: 1.8,
          AZN: 1.703608,
          BAM: 1.850891,
          BBD: 2.019892,
          BDT: 105.461394,
          BGN: 1.852823,
          BHD: 0.377,
          BIF: 2080,
          BMD: 1,
          BND: 1.352052,
          BOB: 6.913097,
          BRL: 5.1652,
          BSD: 1.000388,
          BTC: 4.912848e-5,
          BTN: 81.99101,
          BWP: 13.391818,
          BYN: 2.525622,
          BYR: 19600,
          BZD: 2.016505,
          CAD: 1.383175,
          CDF: 2050.000242,
          CHF: 0.932955,
          CLF: 0.028864,
          CLP: 796.450274,
          CNY: 6.964703,
          COP: 4747.51,
          CRC: 549.551425,
          CUC: 1,
          CUP: 26.5,
          CVE: 104.500129,
          CZK: 22.345801,
          DJF: 177.720382,
          DKK: 7.030903,
          DOP: 55.102778,
          DZD: 136.68854,
          EGP: 30.9019,
          ERN: 15,
          ETB: 53.775035,
          EUR: 0.94479,
          FJD: 2.236698,
          FKP: 0.845003,
          GBP: 0.838815,
          GEL: 2.584968,
          GGP: 0.845003,
          GHS: 12.197594,
          GIP: 0.845003,
          GMD: 61.149959,
          GNF: 8800.000381,
          GTQ: 7.808419,
          GYD: 211.085665,
          HKD: 7.849945,
          HNL: 24.660195,
          HRK: 7.054794,
          HTG: 153.564432,
          HUF: 362.179984,
          IDR: 15496,
          ILS: 3.569541,
          IMP: 0.845003,
          INR: 82.01865,
          IQD: 1460.5,
          IRR: 42274.999954,
          ISK: 141.640274,
          JEP: 0.845003,
          JMD: 153.225196,
          JOD: 0.709401,
          JPY: 136.422327,
          KES: 129.198466,
          KGS: 87.420083,
          KHR: 4046.000276,
          KMF: 465.650284,
          KPW: 900.036927,
          KRW: 1325.898421,
          KWD: 0.30735,
          KYD: 0.833641,
          KZT: 441.657992,
          LAK: 16960.000073,
          LBP: 15250.000046,
          LKR: 325.134854,
          LRD: 160.450101,
          LSL: 18.530115,
          LTL: 2.95274,
          LVL: 0.604891,
          LYD: 4.849725,
          MAD: 10.445942,
          MDL: 18.761296,
          MGA: 4324.999945,
          MKD: 58.218806,
          MMK: 2100.805345,
          MNT: 3532.670237,
          MOP: 8.089109,
          MRO: 356.999828,
          MUR: 46.855734,
          MVR: 15.349828,
          MWK: 1018.501759,
          MXN: 18.34314,
          MYR: 4.520984,
          MZN: 63.107984,
          NAD: 18.45983,
          NGN: 460.498534,
          NIO: 36.435058,
          NOK: 10.66586,
          NPR: 131.193928,
          NZD: 1.63922,
          OMR: 0.385041,
          PAB: 1.000397,
          PEN: 3.781496,
          PGK: 3.519781,
          PHP: 55.260168,
          PKR: 283.150052,
          PLN: 4.42895,
          PYG: 7191.391907,
          QAR: 3.640992,
          RON: 4.642197,
          RSD: 110.850119,
          RUB: 75.850004,
          RWF: 1093.5,
          SAR: 3.753896,
          SBD: 8.232683,
          SCR: 14.076562,
          SDG: 594.00017,
          SEK: 10.727935,
          SGD: 1.35334,
          SHP: 1.21675,
          SLE: 20.560782,
          SLL: 19750.000017,
          SOS: 568.504962,
          SRD: 34.139499,
          STD: 20697.981008,
          SVC: 8.75375,
          SYP: 2512.601266,
          SZL: 18.460482,
          THB: 35.009921,
          TJS: 10.924207,
          TMT: 3.5,
          TND: 3.149029,
          TOP: 2.37235,
          TRY: 18.956896,
          TTD: 6.792974,
          TWD: 30.828898,
          TZS: 2331.999836,
          UAH: 36.946503,
          UGX: 3712.57145,
          USD: 1,
          UYU: 39.349289,
          UZS: 11424.999805,
          VEF: 2410169.530494,
          VES: 24.149251,
          VND: 23695,
          VUV: 119.851256,
          WST: 2.766096,
          XAF: 620.806481,
          XAG: 0.049785,
          XAU: 0.000546,
          XCD: 2.70255,
          XDR: 0.75216,
          XOF: 620.99966,
          XPF: 113.230106,
          YER: 250.302791,
          ZAR: 18.575798,
          ZMK: 9001.20406,
          ZMW: 20.182835,
          ZWL: 321.999592,
        },
      },
      {
        base: "USD",
        date: "2023-03-08",
        rates: {
          AED: 3.673099,
          AFN: 88.503157,
          ALL: 108.450198,
          AMD: 388.534669,
          ANG: 1.821406,
          AOA: 505.999929,
          ARS: 199.971201,
          AUD: 1.516461,
          AWG: 1.8025,
          AZN: 1.700387,
          BAM: 1.855607,
          BBD: 2.018786,
          BDT: 105.256666,
          BGN: 1.854017,
          BHD: 0.37701,
          BIF: 2079,
          BMD: 1,
          BND: 1.354144,
          BOB: 6.909115,
          BRL: 5.145897,
          BSD: 0.999858,
          BTC: 4.6090909e-5,
          BTN: 82.046472,
          BWP: 13.41088,
          BYN: 2.523744,
          BYR: 19600,
          BZD: 2.015352,
          CAD: 1.37995,
          CDF: 2048.000303,
          CHF: 0.94092,
          CLF: 0.029102,
          CLP: 803.019906,
          CNY: 6.951695,
          COP: 4750.94,
          CRC: 549.831113,
          CUC: 1,
          CUP: 26.5,
          CVE: 104.649895,
          CZK: 22.3626,
          DJF: 177.72014,
          DKK: 7.054495,
          DOP: 55.149933,
          DZD: 136.912037,
          EGP: 30.881796,
          ERN: 15,
          ETB: 53.779897,
          EUR: 0.94791,
          FJD: 2.233702,
          FKP: 0.843457,
          GBP: 0.843925,
          GEL: 2.579967,
          GGP: 0.843457,
          GHS: 12.250402,
          GIP: 0.843457,
          GMD: 61.225046,
          GNF: 8824.999989,
          GTQ: 7.803901,
          GYD: 210.966261,
          HKD: 7.84965,
          HNL: 24.680085,
          HRK: 7.054794,
          HTG: 153.100242,
          HUF: 358.629931,
          IDR: 15462,
          ILS: 3.59173,
          IMP: 0.843457,
          INR: 81.96475,
          IQD: 1461,
          IRR: 42249.999957,
          ISK: 142.089768,
          JEP: 0.843457,
          JMD: 153.233076,
          JOD: 0.7094,
          JPY: 137.223494,
          KES: 128.80109,
          KGS: 87.420315,
          KHR: 4050.00012,
          KMF: 467.197187,
          KPW: 899.983203,
          KRW: 1316.804003,
          KWD: 0.307371,
          KYD: 0.833246,
          KZT: 438.597906,
          LAK: 16934.999776,
          LBP: 15274.99986,
          LKR: 329.954127,
          LRD: 160.349901,
          LSL: 18.594989,
          LTL: 2.95274,
          LVL: 0.60489,
          LYD: 4.775021,
          MAD: 10.442963,
          MDL: 18.717978,
          MGA: 4323.999882,
          MKD: 58.444176,
          MMK: 2099.720101,
          MNT: 3534.21235,
          MOP: 8.083798,
          MRO: 356.999828,
          MUR: 47.050792,
          MVR: 15.359768,
          MWK: 1017.49479,
          MXN: 17.977295,
          MYR: 4.524496,
          MZN: 63.096467,
          NAD: 18.612449,
          NGN: 460.500967,
          NIO: 36.394993,
          NOK: 10.676205,
          NPR: 131.274432,
          NZD: 1.63563,
          OMR: 0.385007,
          PAB: 0.999848,
          PEN: 3.788502,
          PGK: 3.524953,
          PHP: 55.405043,
          PKR: 278.250051,
          PLN: 4.43795,
          PYG: 7180.538161,
          QAR: 3.64098,
          RON: 4.6556,
          RSD: 111.21276,
          RUB: 76.049773,
          RWF: 1093.5,
          SAR: 3.754642,
          SBD: 8.246265,
          SCR: 13.303626,
          SDG: 593.499098,
          SEK: 10.72545,
          SGD: 1.35331,
          SHP: 1.21675,
          SLE: 20.687371,
          SLL: 19749.999717,
          SOS: 569.500226,
          SRD: 33.965023,
          STD: 20697.981008,
          SVC: 8.748618,
          SYP: 2511.826553,
          SZL: 18.591627,
          THB: 35.077024,
          TJS: 10.918346,
          TMT: 3.51,
          TND: 3.16802,
          TOP: 2.374603,
          TRY: 18.948805,
          TTD: 6.783874,
          TWD: 30.739796,
          TZS: 2331.999898,
          UAH: 36.925004,
          UGX: 3705.109351,
          USD: 1,
          UYU: 39.232243,
          UZS: 11394.999913,
          VEF: 2421890.213044,
          VES: 24.125638,
          VND: 23720,
          VUV: 118.837794,
          WST: 2.748486,
          XAF: 622.420418,
          XAG: 0.049895,
          XAU: 0.000551,
          XCD: 2.70255,
          XDR: 0.751838,
          XOF: 621.502436,
          XPF: 113.569709,
          YER: 250.299416,
          ZAR: 18.57588,
          ZMK: 9001.192896,
          ZMW: 20.171352,
          ZWL: 321.999592,
        },
      },
      {
        base: "USD",
        date: "2023-03-07",
        rates: {
          AED: 3.673099,
          AFN: 89.000319,
          ALL: 107.474986,
          AMD: 388.602368,
          ANG: 1.801841,
          AOA: 507.498421,
          ARS: 199.669099,
          AUD: 1.518142,
          AWG: 1.8,
          AZN: 1.699831,
          BAM: 1.834804,
          BBD: 2.018584,
          BDT: 105.26217,
          BGN: 1.853532,
          BHD: 0.376949,
          BIF: 2078,
          BMD: 1,
          BND: 1.346174,
          BOB: 6.908651,
          BRL: 5.191399,
          BSD: 0.999803,
          BTC: 4.5057146e-5,
          BTN: 81.553563,
          BWP: 13.294931,
          BYN: 2.523571,
          BYR: 19600,
          BZD: 2.015273,
          CAD: 1.37525,
          CDF: 2046.000517,
          CHF: 0.94226,
          CLF: 0.029128,
          CLP: 803.720141,
          CNY: 6.962398,
          COP: 4761,
          CRC: 552.48567,
          CUC: 1,
          CUP: 26.5,
          CVE: 103.650168,
          CZK: 22.307795,
          DJF: 177.719905,
          DKK: 7.055398,
          DOP: 55.149921,
          DZD: 136.867984,
          EGP: 30.7296,
          ERN: 15,
          ETB: 53.710501,
          EUR: 0.94793,
          FJD: 2.234697,
          FKP: 0.830105,
          GBP: 0.84548,
          GEL: 2.590468,
          GGP: 0.830105,
          GHS: 12.350071,
          GIP: 0.830105,
          GMD: 61.302799,
          GNF: 8800.000209,
          GTQ: 7.808352,
          GYD: 210.947981,
          HKD: 7.84985,
          HNL: 24.679894,
          HRK: 7.054794,
          HTG: 152.470659,
          HUF: 358.994998,
          IDR: 15444,
          ILS: 3.62154,
          IMP: 0.830105,
          INR: 82.07485,
          IQD: 1460,
          IRR: 42275.000281,
          ISK: 142.09055,
          JEP: 0.830105,
          JMD: 153.091284,
          JOD: 0.709404,
          JPY: 137.388501,
          KES: 128.597717,
          KGS: 87.420187,
          KHR: 4051.000146,
          KMF: 467.55497,
          KPW: 900.017439,
          KRW: 1318.225372,
          KWD: 0.30728,
          KYD: 0.833197,
          KZT: 440.079861,
          LAK: 16919.99975,
          LBP: 15249.999718,
          LKR: 324.922956,
          LRD: 160.250186,
          LSL: 18.590328,
          LTL: 2.95274,
          LVL: 0.60489,
          LYD: 4.804961,
          MAD: 10.432504,
          MDL: 18.765421,
          MGA: 4328.999695,
          MKD: 58.375178,
          MMK: 2099.550143,
          MNT: 3534.491094,
          MOP: 8.083025,
          MRO: 356.999828,
          MUR: 46.339942,
          MVR: 15.350159,
          MWK: 1017.479026,
          MXN: 18.114302,
          MYR: 4.478016,
          MZN: 63.094895,
          NAD: 18.589945,
          NGN: 460.49982,
          NIO: 36.389687,
          NOK: 10.691375,
          NPR: 130.484866,
          NZD: 1.63659,
          OMR: 0.384983,
          PAB: 0.999803,
          PEN: 3.782502,
          PGK: 3.525028,
          PHP: 55.360503,
          PKR: 276.298617,
          PLN: 4.446852,
          PYG: 7177.033493,
          QAR: 3.640973,
          RON: 4.6624,
          RSD: 111.170186,
          RUB: 75.598647,
          RWF: 1092,
          SAR: 3.753677,
          SBD: 8.265733,
          SCR: 14.046763,
          SDG: 593.491591,
          SEK: 10.73105,
          SGD: 1.354402,
          SHP: 1.21675,
          SLE: 20.631255,
          SLL: 19749.999757,
          SOS: 569.516238,
          SRD: 33.555503,
          STD: 20697.981008,
          SVC: 8.748106,
          SYP: 2512.539813,
          SZL: 18.220175,
          THB: 35.080564,
          TJS: 10.91762,
          TMT: 3.51,
          TND: 3.11625,
          TOP: 2.36005,
          TRY: 18.92133,
          TTD: 6.780122,
          TWD: 30.732796,
          TZS: 2338.999928,
          UAH: 36.921944,
          UGX: 3700.941418,
          USD: 1,
          UYU: 39.439183,
          UZS: 11360.000272,
          VEF: 2426202.182868,
          VES: 24.125506,
          VND: 23650,
          VUV: 118.683912,
          WST: 2.709194,
          XAF: 615.433688,
          XAG: 0.049739,
          XAU: 0.000551,
          XCD: 2.70255,
          XDR: 0.751752,
          XOF: 615.498617,
          XPF: 112.201083,
          YER: 250.349742,
          ZAR: 18.63305,
          ZMK: 9001.200827,
          ZMW: 20.120836,
          ZWL: 321.999592,
        },
      },
      {
        base: "USD",
        date: "2023-03-06",
        rates: {
          AED: 3.673101,
          AFN: 88.524357,
          ALL: 108.37503,
          AMD: 388.489855,
          ANG: 1.802077,
          AOA: 503.991959,
          ARS: 199.317023,
          AUD: 1.485222,
          AWG: 1.8,
          AZN: 1.693911,
          BAM: 1.837985,
          BBD: 2.018795,
          BDT: 104.933747,
          BGN: 1.831012,
          BHD: 0.377053,
          BIF: 2079.5,
          BMD: 1,
          BND: 1.346346,
          BOB: 6.90945,
          BRL: 5.153399,
          BSD: 0.999812,
          BTC: 4.4636753e-5,
          BTN: 81.905362,
          BWP: 13.243552,
          BYN: 2.523812,
          BYR: 19600,
          BZD: 2.015478,
          CAD: 1.36155,
          CDF: 2047.000202,
          CHF: 0.930704,
          CLF: 0.028976,
          CLP: 799.540179,
          CNY: 6.930402,
          COP: 4732.01,
          CRC: 555.612988,
          CUC: 1,
          CUP: 26.5,
          CVE: 104.288498,
          CZK: 22.040301,
          DJF: 177.719997,
          DKK: 6.964598,
          DOP: 55.249589,
          DZD: 136.274001,
          EGP: 30.825497,
          ERN: 15,
          ETB: 53.705016,
          EUR: 0.93575,
          FJD: 2.21395,
          FKP: 0.830675,
          GBP: 0.83147,
          GEL: 2.595023,
          GGP: 0.830675,
          GHS: 12.70406,
          GIP: 0.830675,
          GMD: 61.101776,
          GNF: 8800.000178,
          GTQ: 7.808844,
          GYD: 210.976928,
          HKD: 7.849515,
          HNL: 24.659888,
          HRK: 7.054794,
          HTG: 152.445127,
          HUF: 352.820256,
          IDR: 15354,
          ILS: 3.5802,
          IMP: 0.830675,
          INR: 81.85355,
          IQD: 1460.5,
          IRR: 42274.999688,
          ISK: 140.079761,
          JEP: 0.830675,
          JMD: 153.348057,
          JOD: 0.709403,
          JPY: 135.994005,
          KES: 128.979656,
          KGS: 87.420414,
          KHR: 4056.999683,
          KMF: 463.503637,
          KPW: 900,
          KRW: 1299.349961,
          KWD: 0.30682,
          KYD: 0.83322,
          KZT: 436.424809,
          LAK: 16930.000006,
          LBP: 15150.521721,
          LKR: 333.69984,
          LRD: 159.497922,
          LSL: 18.170376,
          LTL: 2.95274,
          LVL: 0.60489,
          LYD: 4.824996,
          MAD: 10.38604,
          MDL: 18.788591,
          MGA: 4324.999869,
          MKD: 57.685196,
          MMK: 2099.80734,
          MNT: 3525.260991,
          MOP: 8.084676,
          MRO: 356.999828,
          MUR: 46.352534,
          MVR: 15.349641,
          MWK: 1017.501973,
          MXN: 18.004097,
          MYR: 4.475982,
          MZN: 63.10145,
          NAD: 18.169953,
          NGN: 460.46003,
          NIO: 36.395015,
          NOK: 10.41481,
          NPR: 131.048353,
          NZD: 1.613385,
          OMR: 0.384998,
          PAB: 0.999939,
          PEN: 3.7705,
          PGK: 3.520144,
          PHP: 55.070121,
          PKR: 275.697825,
          PLN: 4.389929,
          PYG: 7205.488464,
          QAR: 3.641023,
          RON: 4.602898,
          RSD: 109.800322,
          RUB: 75.399774,
          RWF: 1092.5,
          SAR: 3.75309,
          SBD: 8.266721,
          SCR: 14.022924,
          SDG: 593.500609,
          SEK: 10.44818,
          SGD: 1.345204,
          SHP: 1.21675,
          SLE: 20.429943,
          SLL: 19750.000157,
          SOS: 568.500568,
          SRD: 33.555504,
          STD: 20697.981008,
          SVC: 8.749178,
          SYP: 2512.523225,
          SZL: 18.170221,
          THB: 34.488499,
          TJS: 10.924017,
          TMT: 3.5,
          TND: 3.11625,
          TOP: 2.35505,
          TRY: 18.906304,
          TTD: 6.776937,
          TWD: 30.581897,
          TZS: 2332.000183,
          UAH: 36.927212,
          UGX: 3705.478808,
          USD: 1,
          UYU: 39.150456,
          UZS: 11380.000055,
          VEF: 2430134.860641,
          VES: 24.286401,
          VND: 23665,
          VUV: 118.020978,
          WST: 2.686003,
          XAF: 616.503691,
          XAG: 0.047516,
          XAU: 0.000542,
          XCD: 2.702551,
          XDR: 0.751834,
          XOF: 617.496955,
          XPF: 112.840154,
          YER: 250.289851,
          ZAR: 18.22717,
          ZMK: 9001.194114,
          ZMW: 20.123115,
          ZWL: 321.999592,
        },
      },
      {
        base: "USD",
        date: "2023-03-05",
        rates: {
          AED: 3.672865,
          AFN: 89.507894,
          ALL: 108.375008,
          AMD: 388.170212,
          ANG: 1.80271,
          AOA: 506.501968,
          ARS: 198.417187,
          AUD: 1.480363,
          AWG: 1.8025,
          AZN: 1.700226,
          BAM: 1.843088,
          BBD: 2.019658,
          BDT: 107.027743,
          BGN: 1.839795,
          BHD: 0.37645,
          BIF: 2078,
          BMD: 1,
          BND: 1.346457,
          BOB: 6.911834,
          BRL: 5.195902,
          BSD: 1.000311,
          BTC: 4.4585449e-5,
          BTN: 81.957745,
          BWP: 13.230932,
          BYN: 2.52476,
          BYR: 19600,
          BZD: 2.016265,
          CAD: 1.360602,
          CDF: 2045.000115,
          CHF: 0.93707,
          CLF: 0.029107,
          CLP: 803.159577,
          CNY: 6.907798,
          COP: 4788.63,
          CRC: 555.801615,
          CUC: 1,
          CUP: 26.5,
          CVE: 104.299899,
          CZK: 22.081501,
          DJF: 177.719864,
          DKK: 7.00272,
          DOP: 55.250295,
          DZD: 136.470979,
          EGP: 30.793772,
          ERN: 15,
          ETB: 53.705027,
          EUR: 0.94106,
          FJD: 2.213016,
          FKP: 0.830696,
          GBP: 0.831285,
          GEL: 2.59498,
          GGP: 0.830696,
          GHS: 12.825026,
          GIP: 0.830696,
          GMD: 61.09837,
          GNF: 8799.999919,
          GTQ: 7.812058,
          GYD: 211.059491,
          HKD: 7.849625,
          HNL: 24.659854,
          HRK: 7.054794,
          HTG: 151.250978,
          HUF: 356.5318,
          IDR: 15277.75,
          ILS: 3.66995,
          IMP: 0.830696,
          INR: 81.71275,
          IQD: 1460.5,
          IRR: 42274.999957,
          ISK: 140.949963,
          JEP: 0.830696,
          JMD: 153.319889,
          JOD: 0.709399,
          JPY: 136.018009,
          KES: 127.9996,
          KGS: 87.41985,
          KHR: 4057.0003,
          KMF: 463.497916,
          KPW: 899.999987,
          KRW: 1295.780188,
          KWD: 0.30698,
          KYD: 0.833506,
          KZT: 431.212718,
          LAK: 18441.999723,
          LBP: 15185.146867,
          LKR: 339.588194,
          LRD: 159.507217,
          LSL: 18.17039,
          LTL: 2.95274,
          LVL: 0.60489,
          LYD: 4.825012,
          MAD: 10.385993,
          MDL: 18.844871,
          MGA: 4324.999771,
          MKD: 58.092832,
          MMK: 2100.564189,
          MNT: 3525.050358,
          MOP: 8.087603,
          MRO: 356.999828,
          MUR: 46.280215,
          MVR: 15.34981,
          MWK: 1017.505413,
          MXN: 17.983398,
          MYR: 4.47599,
          MZN: 63.095602,
          NAD: 18.169721,
          NGN: 460.460289,
          NIO: 36.394999,
          NOK: 10.41155,
          NPR: 131.132618,
          NZD: 1.610445,
          OMR: 0.384422,
          PAB: 1.000217,
          PEN: 3.784497,
          PGK: 3.520202,
          PHP: 55.520058,
          PKR: 278.896166,
          PLN: 4.430901,
          PYG: 7206.47964,
          QAR: 3.641011,
          RON: 4.633599,
          RSD: 110.124419,
          RUB: 75.395983,
          RWF: 1092.5,
          SAR: 3.753097,
          SBD: 8.266721,
          SCR: 14.003474,
          SDG: 593.502774,
          SEK: 10.48425,
          SGD: 1.345479,
          SHP: 1.21675,
          SLE: 20.429939,
          SLL: 19750.000222,
          SOS: 568.498512,
          SRD: 33.522024,
          STD: 20697.981008,
          SVC: 8.751661,
          SYP: 2512.527347,
          SZL: 18.170004,
          THB: 34.562496,
          TJS: 10.927938,
          TMT: 3.5,
          TND: 3.134986,
          TOP: 2.35725,
          TRY: 18.904815,
          TTD: 6.779592,
          TWD: 30.513599,
          TZS: 2340.00023,
          UAH: 36.941047,
          UGX: 3710.998238,
          USD: 1,
          UYU: 38.925536,
          UZS: 11380.000159,
          VEF: 2430134.193987,
          VES: 24.286401,
          VND: 23725,
          VUV: 118.02103,
          WST: 2.686003,
          XAF: 618.153804,
          XAG: 0.047188,
          XAU: 0.00054,
          XCD: 2.70255,
          XDR: 0.752293,
          XOF: 617.499053,
          XPF: 112.839778,
          YER: 250.298816,
          ZAR: 18.152497,
          ZMK: 9001.196714,
          ZMW: 19.980399,
          ZWL: 321.999592,
        },
      },
      {
        base: "USD",
        date: "2023-03-04",
        rates: {
          AED: 3.673104,
          AFN: 89.503991,
          ALL: 108.375041,
          AMD: 388.170403,
          ANG: 1.80271,
          AOA: 506.503981,
          ARS: 197.972865,
          AUD: 1.47735,
          AWG: 1.8025,
          AZN: 1.70397,
          BAM: 1.843088,
          BBD: 2.019658,
          BDT: 107.027743,
          BGN: 1.839795,
          BHD: 0.37645,
          BIF: 2078,
          BMD: 1,
          BND: 1.346457,
          BOB: 6.911834,
          BRL: 5.195904,
          BSD: 1.000311,
          BTC: 4.4734428e-5,
          BTN: 81.957745,
          BWP: 13.230932,
          BYN: 2.52476,
          BYR: 19600,
          BZD: 2.016265,
          CAD: 1.36185,
          CDF: 2045.000362,
          CHF: 0.936103,
          CLF: 0.029107,
          CLP: 803.160396,
          CNY: 6.907804,
          COP: 4788.63,
          CRC: 555.801615,
          CUC: 1,
          CUP: 26.5,
          CVE: 104.303897,
          CZK: 22.08504,
          DJF: 177.720394,
          DKK: 6.996904,
          DOP: 55.250393,
          DZD: 136.47104,
          EGP: 30.722501,
          ERN: 15,
          ETB: 53.70504,
          EUR: 0.93895,
          FJD: 2.21304,
          FKP: 0.833678,
          GBP: 0.83091,
          GEL: 2.59504,
          GGP: 0.833678,
          GHS: 12.82504,
          GIP: 0.833678,
          GMD: 61.103853,
          GNF: 8800.000355,
          GTQ: 7.812058,
          GYD: 211.059491,
          HKD: 7.84865,
          HNL: 24.660389,
          HRK: 7.054794,
          HTG: 151.250978,
          HUF: 356.560388,
          IDR: 15277.75,
          ILS: 3.670475,
          IMP: 0.833678,
          INR: 81.71275,
          IQD: 1460.5,
          IRR: 42275.000352,
          ISK: 140.950386,
          JEP: 0.833678,
          JMD: 153.319889,
          JOD: 0.709404,
          JPY: 135.84504,
          KES: 128.000351,
          KGS: 87.420385,
          KHR: 4057.000351,
          KMF: 463.503796,
          KPW: 899.922652,
          KRW: 1295.780384,
          KWD: 0.30698,
          KYD: 0.833506,
          KZT: 431.212718,
          LAK: 18442.000349,
          LBP: 15185.146867,
          LKR: 339.588194,
          LRD: 159.503775,
          LSL: 18.170382,
          LTL: 2.95274,
          LVL: 0.60489,
          LYD: 4.825039,
          MAD: 10.386039,
          MDL: 18.844871,
          MGA: 4325.000347,
          MKD: 58.092832,
          MMK: 2100.564189,
          MNT: 3536.41673,
          MOP: 8.087603,
          MRO: 356.999828,
          MUR: 46.280379,
          MVR: 15.350378,
          MWK: 1017.503739,
          MXN: 17.956604,
          MYR: 4.476039,
          MZN: 63.103732,
          NAD: 18.170377,
          NGN: 460.460377,
          NIO: 36.395039,
          NOK: 10.381304,
          NPR: 131.132618,
          NZD: 1.606684,
          OMR: 0.384422,
          PAB: 1.000217,
          PEN: 3.784504,
          PGK: 3.520375,
          PHP: 55.520375,
          PKR: 278.903704,
          PLN: 4.426284,
          PYG: 7206.47964,
          QAR: 3.641038,
          RON: 4.631504,
          RSD: 110.124419,
          RUB: 75.40369,
          RWF: 1092.5,
          SAR: 3.75288,
          SBD: 8.266721,
          SCR: 14.003474,
          SDG: 593.503678,
          SEK: 10.455904,
          SGD: 1.345204,
          SHP: 1.21675,
          SLE: 20.830292,
          SLL: 19750.000338,
          SOS: 568.503664,
          SRD: 33.522038,
          STD: 20697.981008,
          SVC: 8.751661,
          SYP: 2512.442943,
          SZL: 18.17037,
          THB: 34.440369,
          TJS: 10.927938,
          TMT: 3.5,
          TND: 3.135038,
          TOP: 2.35725,
          TRY: 18.815904,
          TTD: 6.779592,
          TWD: 30.513604,
          TZS: 2340.000335,
          UAH: 36.941047,
          UGX: 3710.998238,
          USD: 1,
          UYU: 38.925536,
          UZS: 11380.000334,
          VEF: 2430118.412151,
          VES: 24.286401,
          VND: 23725,
          VUV: 118.538796,
          WST: 2.721334,
          XAF: 618.153804,
          XAG: 0.047008,
          XAU: 0.000539,
          XCD: 2.70255,
          XDR: 0.752293,
          XOF: 617.503597,
          XPF: 112.840364,
          YER: 250.303593,
          ZAR: 18.142604,
          ZMK: 9001.203589,
          ZMW: 19.980399,
          ZWL: 321.999592,
        },
      },
      {
        base: "USD",
        date: "2023-03-03",
        rates: {
          AED: 3.673104,
          AFN: 89.503991,
          ALL: 108.375041,
          AMD: 388.170403,
          ANG: 1.80271,
          AOA: 506.503981,
          ARS: 197.972865,
          AUD: 1.477542,
          AWG: 1.8025,
          AZN: 1.70397,
          BAM: 1.843088,
          BBD: 2.019658,
          BDT: 107.027743,
          BGN: 1.839795,
          BHD: 0.37645,
          BIF: 2078,
          BMD: 1,
          BND: 1.346457,
          BOB: 6.911834,
          BRL: 5.195804,
          BSD: 1.000311,
          BTC: 4.4723332e-5,
          BTN: 81.957745,
          BWP: 13.230932,
          BYN: 2.52476,
          BYR: 19600,
          BZD: 2.016265,
          CAD: 1.36185,
          CDF: 2045.000362,
          CHF: 0.936104,
          CLF: 0.029107,
          CLP: 803.160396,
          CNY: 6.907404,
          COP: 4788.63,
          CRC: 555.801615,
          CUC: 1,
          CUP: 26.5,
          CVE: 104.303897,
          CZK: 22.08504,
          DJF: 177.720394,
          DKK: 6.996804,
          DOP: 55.250393,
          DZD: 136.47104,
          EGP: 30.722501,
          ERN: 15,
          ETB: 53.70504,
          EUR: 0.93904,
          FJD: 2.21304,
          FKP: 0.837562,
          GBP: 0.83091,
          GEL: 2.59504,
          GGP: 0.837562,
          GHS: 12.82504,
          GIP: 0.837562,
          GMD: 61.103853,
          GNF: 8800.000355,
          GTQ: 7.812058,
          GYD: 211.059491,
          HKD: 7.84855,
          HNL: 24.660389,
          HRK: 7.054794,
          HTG: 151.250978,
          HUF: 356.560388,
          IDR: 15277.75,
          ILS: 3.670475,
          IMP: 0.837562,
          INR: 81.71275,
          IQD: 1460.5,
          IRR: 42275.000352,
          ISK: 140.950386,
          JEP: 0.837562,
          JMD: 153.319889,
          JOD: 0.709404,
          JPY: 135.84504,
          KES: 128.000351,
          KGS: 87.420385,
          KHR: 4057.000351,
          KMF: 463.503796,
          KPW: 899.933659,
          KRW: 1295.780384,
          KWD: 0.30698,
          KYD: 0.833506,
          KZT: 431.212718,
          LAK: 18442.000349,
          LBP: 15185.146867,
          LKR: 339.588194,
          LRD: 159.503775,
          LSL: 18.170382,
          LTL: 2.95274,
          LVL: 0.60489,
          LYD: 4.825039,
          MAD: 10.386039,
          MDL: 18.844871,
          MGA: 4325.000347,
          MKD: 58.092832,
          MMK: 2100.564189,
          MNT: 3530.179817,
          MOP: 8.087603,
          MRO: 356.999828,
          MUR: 46.280379,
          MVR: 15.350378,
          MWK: 1017.503739,
          MXN: 17.956704,
          MYR: 4.476039,
          MZN: 63.103732,
          NAD: 18.170377,
          NGN: 461.000344,
          NIO: 36.395039,
          NOK: 10.381304,
          NPR: 131.132618,
          NZD: 1.606684,
          OMR: 0.384422,
          PAB: 1.000217,
          PEN: 3.784504,
          PGK: 3.520375,
          PHP: 54.725039,
          PKR: 278.903704,
          PLN: 4.42645,
          PYG: 7206.47964,
          QAR: 3.641038,
          RON: 4.631504,
          RSD: 110.124419,
          RUB: 75.40369,
          RWF: 1092.5,
          SAR: 3.75288,
          SBD: 8.266721,
          SCR: 14.003474,
          SDG: 593.503678,
          SEK: 10.456304,
          SGD: 1.345204,
          SHP: 1.21675,
          SLE: 20.875181,
          SLL: 19750.000338,
          SOS: 568.503664,
          SRD: 33.522038,
          STD: 20697.981008,
          SVC: 8.751661,
          SYP: 2512.098269,
          SZL: 18.17037,
          THB: 34.440369,
          TJS: 10.927938,
          TMT: 3.5,
          TND: 3.135038,
          TOP: 2.35725,
          TRY: 18.815904,
          TTD: 6.779592,
          TWD: 30.512904,
          TZS: 2340.000335,
          UAH: 36.941047,
          UGX: 3710.998238,
          USD: 1,
          UYU: 38.925536,
          UZS: 11380.000334,
          VEF: 2430197.530168,
          VES: 24.286401,
          VND: 23725,
          VUV: 118.84894,
          WST: 2.718091,
          XAF: 618.153804,
          XAG: 0.047008,
          XAU: 0.000539,
          XCD: 2.70255,
          XDR: 0.752293,
          XOF: 617.503597,
          XPF: 112.840364,
          YER: 250.303593,
          ZAR: 18.142604,
          ZMK: 9001.203589,
          ZMW: 19.980399,
          ZWL: 321.999592,
        },
      },
      {
        base: "USD",
        date: "2023-03-02",
        rates: {
          AED: 3.673097,
          AFN: 88.511773,
          ALL: 108.649662,
          AMD: 388.950192,
          ANG: 1.802564,
          AOA: 507.502153,
          ARS: 197.853797,
          AUD: 1.485222,
          AWG: 1.8,
          AZN: 1.697369,
          BAM: 1.84111,
          BBD: 2.019448,
          BDT: 107.01564,
          BGN: 1.844325,
          BHD: 0.376999,
          BIF: 2062,
          BMD: 1,
          BND: 1.347949,
          BOB: 6.911194,
          BRL: 5.201097,
          BSD: 1.000169,
          BTC: 4.2628562e-5,
          BTN: 82.601296,
          BWP: 13.230036,
          BYN: 2.524593,
          BYR: 19600,
          BZD: 2.016059,
          CAD: 1.359525,
          CDF: 2046.000105,
          CHF: 0.94192,
          CLF: 0.02946,
          CLP: 812.88046,
          CNY: 6.915299,
          COP: 4819.83,
          CRC: 558.456962,
          CUC: 1,
          CUP: 26.5,
          CVE: 104.449639,
          CZK: 22.117,
          DJF: 177.719836,
          DKK: 7.02085,
          DOP: 55.450122,
          DZD: 136.677059,
          EGP: 30.768098,
          ERN: 15,
          ETB: 53.760355,
          EUR: 0.94345,
          FJD: 2.21395,
          FKP: 0.830928,
          GBP: 0.836775,
          GEL: 2.600677,
          GGP: 0.830928,
          GHS: 12.796899,
          GIP: 0.830928,
          GMD: 61.040195,
          GNF: 8824.999639,
          GTQ: 7.811394,
          GYD: 211.040092,
          HKD: 7.849525,
          HNL: 24.679857,
          HRK: 7.054794,
          HTG: 151.026998,
          HUF: 354.980253,
          IDR: 15329,
          ILS: 3.661869,
          IMP: 0.830928,
          INR: 82.35595,
          IQD: 1460.5,
          IRR: 42249.999996,
          ISK: 142.550297,
          JEP: 0.830928,
          JMD: 154.022902,
          JOD: 0.709397,
          JPY: 136.706032,
          KES: 127.350414,
          KGS: 87.419012,
          KHR: 4059.999957,
          KMF: 464.950159,
          KPW: 899.910348,
          KRW: 1311.409788,
          KWD: 0.30715,
          KYD: 0.833439,
          KZT: 435.872092,
          LAK: 18434.000237,
          LBP: 15189.109126,
          LKR: 346.053871,
          LRD: 159.249683,
          LSL: 18.230042,
          LTL: 2.95274,
          LVL: 0.60489,
          LYD: 4.830075,
          MAD: 10.397502,
          MDL: 18.878576,
          MGA: 4324.999849,
          MKD: 58.137505,
          MMK: 2100.306583,
          MNT: 3530.216088,
          MOP: 8.08628,
          MRO: 356.999828,
          MUR: 46.359984,
          MVR: 15.359547,
          MWK: 1023.502493,
          MXN: 18.120301,
          MYR: 4.475501,
          MZN: 63.099154,
          NAD: 18.230052,
          NGN: 459.999778,
          NIO: 36.394394,
          NOK: 10.445335,
          NPR: 132.162036,
          NZD: 1.607575,
          OMR: 0.38502,
          PAB: 1.000169,
          PEN: 3.782497,
          PGK: 3.524991,
          PHP: 55.07502,
          PKR: 281.84984,
          PLN: 4.433138,
          PYG: 7223.058965,
          QAR: 3.641005,
          RON: 4.644903,
          RSD: 110.695025,
          RUB: 75.524988,
          RWF: 1093.5,
          SAR: 3.753054,
          SBD: 8.246265,
          SCR: 13.164127,
          SDG: 592.502246,
          SEK: 10.506655,
          SGD: 1.347415,
          SHP: 1.216751,
          SLE: 20.786461,
          SLL: 19750.000292,
          SOS: 568.502545,
          SRD: 33.933038,
          STD: 20697.981008,
          SVC: 8.7516,
          SYP: 2512.572824,
          SZL: 18.230456,
          THB: 34.829726,
          TJS: 10.926831,
          TMT: 3.51,
          TND: 3.1325,
          TOP: 2.35835,
          TRY: 18.890701,
          TTD: 6.785808,
          TWD: 30.616501,
          TZS: 2331.999627,
          UAH: 36.938464,
          UGX: 3716.711694,
          USD: 1,
          UYU: 38.916711,
          UZS: 11375.000204,
          VEF: 2430573.358272,
          VES: 24.326567,
          VND: 23720,
          VUV: 118.988876,
          WST: 2.739333,
          XAF: 617.342243,
          XAG: 0.047725,
          XAU: 0.000544,
          XCD: 2.70255,
          XDR: 0.749612,
          XOF: 616.498168,
          XPF: 112.949619,
          YER: 250.24997,
          ZAR: 18.19395,
          ZMK: 9001.217366,
          ZMW: 19.978538,
          ZWL: 321.999592,
        },
      },
      {
        base: "USD",
        date: "2023-03-01",
        rates: {
          AED: 3.673104,
          AFN: 89.000262,
          ALL: 107.850029,
          AMD: 389.009922,
          ANG: 1.802148,
          AOA: 506.503045,
          ARS: 197.5619,
          AUD: 1.479435,
          AWG: 1.8025,
          AZN: 1.700744,
          BAM: 1.832958,
          BBD: 2.018742,
          BDT: 104.462167,
          BGN: 1.834597,
          BHD: 0.377026,
          BIF: 2060,
          BMD: 1,
          BND: 1.339756,
          BOB: 6.908869,
          BRL: 5.179802,
          BSD: 0.999859,
          BTC: 4.2289312e-5,
          BTN: 82.497891,
          BWP: 13.209098,
          BYN: 2.524003,
          BYR: 19600,
          BZD: 2.015604,
          CAD: 1.359035,
          CDF: 2045.000094,
          CHF: 0.93963,
          CLF: 0.029394,
          CLP: 811.069612,
          CNY: 6.869098,
          COP: 4848.28,
          CRC: 558.328062,
          CUC: 1,
          CUP: 26.5,
          CVE: 103.650319,
          CZK: 21.89285,
          DJF: 177.720577,
          DKK: 6.97491,
          DOP: 55.45026,
          DZD: 136.330401,
          EGP: 30.629862,
          ERN: 15,
          ETB: 53.749868,
          EUR: 0.93729,
          FJD: 2.207601,
          FKP: 0.826819,
          GBP: 0.831485,
          GEL: 2.605035,
          GGP: 0.826819,
          GHS: 12.849552,
          GIP: 0.826819,
          GMD: 61.049985,
          GNF: 8800.000418,
          GTQ: 7.809511,
          GYD: 210.981576,
          HKD: 7.84835,
          HNL: 24.660161,
          HRK: 7.054794,
          HTG: 150.77543,
          HUF: 349.83982,
          IDR: 15249,
          ILS: 3.616795,
          IMP: 0.826819,
          INR: 82.42015,
          IQD: 1460.5,
          IRR: 42249.999786,
          ISK: 142.030072,
          JEP: 0.826819,
          JMD: 153.115307,
          JOD: 0.709398,
          JPY: 136.202,
          KES: 127.598925,
          KGS: 87.415982,
          KHR: 4064.999888,
          KMF: 461.624985,
          KPW: 900.006189,
          KRW: 1306.055058,
          KWD: 0.30692,
          KYD: 0.833318,
          KZT: 440.968643,
          LAK: 16960.000058,
          LBP: 15275.00052,
          LKR: 357.97331,
          LRD: 158.950095,
          LSL: 18.265035,
          LTL: 2.95274,
          LVL: 0.60489,
          LYD: 4.835031,
          MAD: 10.366002,
          MDL: 18.888629,
          MGA: 4304.000079,
          MKD: 57.754763,
          MMK: 2099.842745,
          MNT: 3527.689555,
          MOP: 8.084739,
          MRO: 356.999828,
          MUR: 46.34997,
          MVR: 15.349787,
          MWK: 1023.499729,
          MXN: 18.11874,
          MYR: 4.576028,
          MZN: 63.101876,
          NAD: 18.270289,
          NGN: 460.500193,
          NIO: 36.430132,
          NOK: 10.381695,
          NPR: 131.996326,
          NZD: 1.59904,
          OMR: 0.385033,
          PAB: 0.999977,
          PEN: 3.794538,
          PGK: 3.519925,
          PHP: 54.864969,
          PKR: 266.230109,
          PLN: 4.379116,
          PYG: 7282.106545,
          QAR: 3.641015,
          RON: 4.612275,
          RSD: 109.934998,
          RUB: 75.265003,
          RWF: 1092.5,
          SAR: 3.752109,
          SBD: 8.233939,
          SCR: 14.064506,
          SDG: 589.497004,
          SEK: 10.435335,
          SGD: 1.341955,
          SHP: 1.21675,
          SLE: 20.609745,
          SLL: 19750.000016,
          SOS: 569.49428,
          SRD: 34.112498,
          STD: 20697.981008,
          SVC: 8.749274,
          SYP: 2512.502517,
          SZL: 18.265006,
          THB: 34.7005,
          TJS: 10.904167,
          TMT: 3.5,
          TND: 3.137983,
          TOP: 2.35835,
          TRY: 18.888602,
          TTD: 6.783489,
          TWD: 30.575703,
          TZS: 2331.99977,
          UAH: 36.931713,
          UGX: 3719.753341,
          USD: 1,
          UYU: 39.052162,
          UZS: 11360.000079,
          VEF: 2445829.21483,
          VES: 24.326654,
          VND: 23725,
          VUV: 119.243555,
          WST: 2.738018,
          XAF: 614.733005,
          XAG: 0.047671,
          XAU: 0.000544,
          XCD: 2.70255,
          XDR: 0.752535,
          XOF: 617.49859,
          XPF: 112.10406,
          YER: 250.298718,
          ZAR: 18.125498,
          ZMK: 9001.202368,
          ZMW: 19.923528,
          ZWL: 321.999592,
        },
      },
    ];
    const FIXER_ARRAY_202302 = [
      {
        base: "USD",
        date: "2023-02-28",
        rates: {
          AED: 3.6731,
          AFN: 88.99996,
          ALL: 108.421132,
          AMD: 389.339885,
          ANG: 1.801836,
          AOA: 503.427497,
          ARS: 197.133595,
          AUD: 1.486105,
          AWG: 1.8,
          AZN: 1.703567,
          BAM: 1.84314,
          BBD: 2.018678,
          BDT: 104.481743,
          BGN: 1.848705,
          BHD: 0.377028,
          BIF: 2060,
          BMD: 1,
          BND: 1.348494,
          BOB: 6.908608,
          BRL: 5.2373,
          BSD: 0.999774,
          BTC: 4.3239974e-5,
          BTN: 82.651147,
          BWP: 13.321711,
          BYN: 2.523607,
          BYR: 19600,
          BZD: 2.015285,
          CAD: 1.36465,
          CDF: 2045.999951,
          CHF: 0.941966,
          CLF: 0.029988,
          CLP: 827.460245,
          CNY: 6.933395,
          COP: 4809.75,
          CRC: 559.78476,
          CUC: 1,
          CUP: 26.5,
          CVE: 104.449859,
          CZK: 22.223903,
          DJF: 177.71988,
          DKK: 7.03699,
          DOP: 55.58833,
          DZD: 136.437077,
          EGP: 30.632102,
          ERN: 15,
          ETB: 53.786464,
          EUR: 0.94546,
          FJD: 2.216898,
          FKP: 0.830879,
          GBP: 0.831075,
          GEL: 2.630195,
          GGP: 0.830879,
          GHS: 12.747388,
          GIP: 0.830879,
          GMD: 61.103608,
          GNF: 8825.000072,
          GTQ: 7.808395,
          GYD: 210.952372,
          HKD: 7.84925,
          HNL: 24.653674,
          HRK: 7.054794,
          HTG: 149.968901,
          HUF: 357.569719,
          IDR: 15249,
          ILS: 3.654165,
          IMP: 0.830879,
          INR: 82.63555,
          IQD: 1459.194828,
          IRR: 42299.999717,
          ISK: 143.989956,
          JEP: 0.830879,
          JMD: 153.667386,
          JOD: 0.709398,
          JPY: 136.353003,
          KES: 127.298681,
          KGS: 87.419769,
          KHR: 4053.999896,
          KMF: 464.850106,
          KPW: 899.982593,
          KRW: 1321.999796,
          KWD: 0.307002,
          KYD: 0.833255,
          KZT: 445.014065,
          LAK: 16944.999791,
          LBP: 15249.999953,
          LKR: 361.930216,
          LRD: 158.99972,
          LSL: 18.409854,
          LTL: 2.95274,
          LVL: 0.60489,
          LYD: 4.847805,
          MAD: 10.39646,
          MDL: 18.921066,
          MGA: 4285.976808,
          MKD: 58.241093,
          MMK: 2099.742896,
          MNT: 3529.713969,
          MOP: 8.083973,
          MRO: 356.999828,
          MUR: 46.350393,
          MVR: 15.360297,
          MWK: 1026.902326,
          MXN: 18.30425,
          MYR: 4.487502,
          MZN: 63.10286,
          NAD: 18.419567,
          NGN: 460.469966,
          NIO: 36.532409,
          NOK: 10.38845,
          NPR: 132.234793,
          NZD: 1.616985,
          OMR: 0.385036,
          PAB: 0.999868,
          PEN: 3.792503,
          PGK: 3.524622,
          PHP: 55.45956,
          PKR: 261.574993,
          PLN: 4.45065,
          PYG: 7286.032003,
          QAR: 3.640991,
          RON: 4.657801,
          RSD: 110.880401,
          RUB: 75.050387,
          RWF: 1089.55463,
          SAR: 3.751855,
          SBD: 8.232683,
          SCR: 13.030203,
          SDG: 589.493041,
          SEK: 10.470506,
          SGD: 1.348395,
          SHP: 1.21675,
          SLE: 20.705341,
          SLL: 19749.999733,
          SOS: 568.501203,
          SRD: 33.414998,
          STD: 20697.981008,
          SVC: 8.749081,
          SYP: 2512.489929,
          SZL: 18.454964,
          THB: 35.239848,
          TJS: 10.917744,
          TMT: 3.51,
          TND: 3.164977,
          TOP: 2.36005,
          TRY: 18.887304,
          TTD: 6.781669,
          TWD: 30.660795,
          TZS: 2332.000114,
          UAH: 36.926325,
          UGX: 3724.219821,
          USD: 1,
          UYU: 38.77151,
          UZS: 11333.688297,
          VEF: 2436586.0672,
          VES: 24.385805,
          VND: 23760,
          VUV: 119.234342,
          WST: 2.725009,
          XAF: 618.185845,
          XAG: 0.047882,
          XAU: 0.000548,
          XCD: 2.70255,
          XDR: 0.75384,
          XOF: 618.165455,
          XPF: 112.686694,
          YER: 250.349992,
          ZAR: 18.343198,
          ZMK: 9001.196971,
          ZMW: 19.795973,
          ZWL: 321.999592,
        },
      },
      {
        base: "USD",
        date: "2023-02-27",
        rates: {
          AED: 3.673098,
          AFN: 87.49688,
          ALL: 108.450129,
          AMD: 389.059625,
          ANG: 1.802293,
          AOA: 507.500325,
          ARS: 196.7945,
          AUD: 1.48346,
          AWG: 1.8025,
          AZN: 1.699549,
          BAM: 1.851726,
          BBD: 2.019106,
          BDT: 106.647448,
          BGN: 1.842443,
          BHD: 0.377053,
          BIF: 2060,
          BMD: 1,
          BND: 1.349069,
          BOB: 6.909989,
          BRL: 5.201201,
          BSD: 0.999991,
          BTC: 4.2559779e-5,
          BTN: 82.851909,
          BWP: 13.31525,
          BYN: 2.524119,
          BYR: 19600,
          BZD: 2.015679,
          CAD: 1.35765,
          CDF: 2044.999705,
          CHF: 0.935802,
          CLF: 0.030212,
          CLP: 833.639546,
          CNY: 6.945021,
          COP: 4779.01,
          CRC: 560.39992,
          CUC: 1,
          CUP: 26.5,
          CVE: 104.450376,
          CZK: 22.287397,
          DJF: 178.049844,
          DKK: 7.01622,
          DOP: 55.498647,
          DZD: 136.720853,
          EGP: 30.629702,
          ERN: 15,
          ETB: 53.699288,
          EUR: 0.94255,
          FJD: 2.216902,
          FKP: 0.837209,
          GBP: 0.829125,
          GEL: 2.629624,
          GGP: 0.837209,
          GHS: 12.903067,
          GIP: 0.837209,
          GMD: 60.999829,
          GNF: 8799.999992,
          GTQ: 7.815018,
          GYD: 210.999706,
          HKD: 7.84495,
          HNL: 24.650052,
          HRK: 7.054794,
          HTG: 150.287348,
          HUF: 356.2395,
          IDR: 15216,
          ILS: 3.66635,
          IMP: 0.837209,
          INR: 82.65225,
          IQD: 1460,
          IRR: 42300.000123,
          ISK: 143.559978,
          JEP: 0.837209,
          JMD: 153.878927,
          JOD: 0.709603,
          JPY: 136.319941,
          KES: 127.101488,
          KGS: 87.417008,
          KHR: 4042.999995,
          KMF: 464.7498,
          KPW: 900,
          KRW: 1318.697594,
          KWD: 0.3071,
          KYD: 0.833333,
          KZT: 447.349984,
          LAK: 16934.999688,
          LBP: 14954.21362,
          LKR: 362.991555,
          LRD: 157.73843,
          LSL: 18.409927,
          LTL: 2.95274,
          LVL: 0.60489,
          LYD: 4.84975,
          MAD: 10.391503,
          MDL: 18.920101,
          MGA: 4301.000269,
          MKD: 58.099953,
          MMK: 2099.950485,
          MNT: 3521.181603,
          MOP: 8.082917,
          MRO: 356.999828,
          MUR: 46.254053,
          MVR: 15.359576,
          MWK: 1020.506766,
          MXN: 18.381899,
          MYR: 4.480501,
          MZN: 63.100885,
          NAD: 18.419853,
          NGN: 460.49885,
          NIO: 36.400387,
          NOK: 10.338698,
          NPR: 132.562675,
          NZD: 1.622465,
          OMR: 0.385038,
          PAB: 0.999991,
          PEN: 3.802499,
          PGK: 3.524975,
          PHP: 55.344994,
          PKR: 260.000162,
          PLN: 4.44435,
          PYG: 7287.56592,
          QAR: 3.641024,
          RON: 4.641901,
          RSD: 110.530192,
          RUB: 74.64981,
          RWF: 1092,
          SAR: 3.752679,
          SBD: 8.232683,
          SCR: 13.022664,
          SDG: 589.512855,
          SEK: 10.389698,
          SGD: 1.346974,
          SHP: 1.21675,
          SLE: 20.065006,
          SLL: 19750.000064,
          SOS: 568.49739,
          SRD: 33.894497,
          STD: 20697.981008,
          SVC: 8.750154,
          SYP: 2512.104125,
          SZL: 18.409562,
          THB: 35.00028,
          TJS: 10.915064,
          TMT: 3.51,
          TND: 3.164944,
          TOP: 2.3617,
          TRY: 18.890615,
          TTD: 6.787665,
          TWD: 30.612805,
          TZS: 2331.999708,
          UAH: 36.751593,
          UGX: 3723.028564,
          USD: 1,
          UYU: 38.88526,
          UZS: 11325.000037,
          VEF: 2431515.717415,
          VES: 24.385805,
          VND: 23820,
          VUV: 117.382665,
          WST: 2.678097,
          XAF: 621.030258,
          XAG: 0.048474,
          XAU: 0.00055,
          XCD: 2.70255,
          XDR: 0.753143,
          XOF: 621.484438,
          XPF: 112.657375,
          YER: 250.349928,
          ZAR: 18.41523,
          ZMK: 9001.189445,
          ZMW: 19.774952,
          ZWL: 321.999592,
        },
      },
      {
        base: "USD",
        date: "2023-02-26",
        rates: {
          AED: 3.67289,
          AFN: 88.847952,
          ALL: 108.733645,
          AMD: 392.470833,
          ANG: 1.801238,
          AOA: 506.497632,
          ARS: 195.773591,
          AUD: 1.48473,
          AWG: 1.8,
          AZN: 1.694738,
          BAM: 1.84765,
          BBD: 2.017949,
          BDT: 107.00836,
          BGN: 1.848189,
          BHD: 0.376742,
          BIF: 2076.434746,
          BMD: 1,
          BND: 1.345331,
          BOB: 6.906051,
          BRL: 5.210399,
          BSD: 0.999386,
          BTC: 4.2442455e-5,
          BTN: 82.705777,
          BWP: 13.307921,
          BYN: 2.522602,
          BYR: 19600,
          BZD: 2.014548,
          CAD: 1.35962,
          CDF: 2045.00029,
          CHF: 0.94028,
          CLF: 0.029409,
          CLP: 811.482122,
          CNY: 6.956099,
          COP: 4855.320958,
          CRC: 560.927684,
          CUC: 1,
          CUP: 26.5,
          CVE: 104.166076,
          CZK: 22.41165,
          DJF: 177.950971,
          DKK: 7.05361,
          DOP: 55.752681,
          DZD: 136.788986,
          EGP: 30.612023,
          ERN: 15,
          ETB: 53.752964,
          EUR: 0.94748,
          FJD: 2.219302,
          FKP: 0.837248,
          GBP: 0.836725,
          GEL: 2.634982,
          GGP: 0.837248,
          GHS: 12.867602,
          GIP: 0.837248,
          GMD: 61.098024,
          GNF: 8602.049974,
          GTQ: 7.805489,
          GYD: 210.882811,
          HKD: 7.847905,
          HNL: 24.64031,
          HRK: 7.054794,
          HTG: 149.73407,
          HUF: 360.120006,
          IDR: 15265.15,
          ILS: 3.667115,
          IMP: 0.837248,
          INR: 82.925499,
          IQD: 1458.655708,
          IRR: 42324.999806,
          ISK: 144.779848,
          JEP: 0.837248,
          JMD: 154.175995,
          JOD: 0.709403,
          JPY: 136.401498,
          KES: 126.527042,
          KGS: 87.419872,
          KHR: 4043.644608,
          KMF: 466.899267,
          KPW: 899.999991,
          KRW: 1314.804398,
          KWD: 0.30704,
          KYD: 0.832837,
          KZT: 448.840395,
          LAK: 16854.281801,
          LBP: 15001.369798,
          LKR: 364.78976,
          LRD: 157.793572,
          LSL: 18.40983,
          LTL: 2.95274,
          LVL: 0.60489,
          LYD: 4.819612,
          MAD: 10.407633,
          MDL: 18.839167,
          MGA: 4299.617401,
          MKD: 58.206981,
          MMK: 2098.790043,
          MNT: 3521.207977,
          MOP: 8.079165,
          MRO: 356.999828,
          MUR: 46.350007,
          MVR: 15.350188,
          MWK: 1025.843843,
          MXN: 18.39399,
          MYR: 4.435498,
          MZN: 63.096955,
          NAD: 18.410525,
          NGN: 460.440338,
          NIO: 36.518823,
          NOK: 10.371196,
          NPR: 132.329129,
          NZD: 1.622095,
          OMR: 0.383657,
          PAB: 0.999386,
          PEN: 3.802088,
          PGK: 3.523027,
          PHP: 54.919848,
          PKR: 257.801426,
          PLN: 4.472298,
          PYG: 7308.96037,
          QAR: 3.640987,
          RON: 4.663655,
          RSD: 111.235016,
          RUB: 76.014977,
          RWF: 1088.470077,
          SAR: 3.751956,
          SBD: 8.245284,
          SCR: 13.048016,
          SDG: 587.99987,
          SEK: 10.48634,
          SGD: 1.34972,
          SHP: 1.21675,
          SLE: 20.065005,
          SLL: 19749.999838,
          SOS: 568.537212,
          SRD: 33.661028,
          STD: 20697.981008,
          SVC: 8.744981,
          SYP: 2511.939471,
          SZL: 18.404421,
          THB: 34.952496,
          TJS: 10.898682,
          TMT: 3.51,
          TND: 3.129738,
          TOP: 2.35285,
          TRY: 18.887645,
          TTD: 6.783714,
          TWD: 30.671805,
          TZS: 2337.65056,
          UAH: 36.730244,
          UGX: 3717.821548,
          USD: 1,
          UYU: 38.862595,
          UZS: 11343.460394,
          VEF: 2431523.793742,
          VES: 24.38578,
          VND: 23795,
          VUV: 117.382637,
          WST: 2.678096,
          XAF: 619.675027,
          XAG: 0.048114,
          XAU: 0.000552,
          XCD: 2.70255,
          XDR: 0.751122,
          XOF: 619.675027,
          XPF: 113.450232,
          YER: 250.349839,
          ZAR: 18.422496,
          ZMK: 9001.200031,
          ZMW: 19.713665,
          ZWL: 321.999592,
        },
      },
      {
        base: "USD",
        date: "2023-02-25",
        rates: {
          AED: 3.673104,
          AFN: 88.847952,
          ALL: 108.733645,
          AMD: 392.470833,
          ANG: 1.801238,
          AOA: 506.503981,
          ARS: 195.197487,
          AUD: 1.486768,
          AWG: 1.8,
          AZN: 1.70397,
          BAM: 1.84765,
          BBD: 2.017949,
          BDT: 107.00836,
          BGN: 1.848189,
          BHD: 0.376742,
          BIF: 2076.434746,
          BMD: 1,
          BND: 1.345331,
          BOB: 6.906051,
          BRL: 5.210404,
          BSD: 0.999386,
          BTC: 4.3129067e-5,
          BTN: 82.705777,
          BWP: 13.307921,
          BYN: 2.522602,
          BYR: 19600,
          BZD: 2.014548,
          CAD: 1.36325,
          CDF: 2045.000362,
          CHF: 0.940359,
          CLF: 0.029409,
          CLP: 811.482122,
          CNY: 6.956104,
          COP: 4855.320958,
          CRC: 560.927684,
          CUC: 1,
          CUP: 26.5,
          CVE: 104.166076,
          CZK: 22.419704,
          DJF: 177.950971,
          DKK: 7.058904,
          DOP: 55.752681,
          DZD: 136.78904,
          EGP: 30.52194,
          ERN: 15,
          ETB: 53.752964,
          EUR: 0.944804,
          FJD: 2.219304,
          FKP: 0.8371,
          GBP: 0.837065,
          GEL: 2.63504,
          GGP: 0.8371,
          GHS: 12.867602,
          GIP: 0.8371,
          GMD: 61.103853,
          GNF: 8602.049974,
          GTQ: 7.805489,
          GYD: 210.882811,
          HKD: 7.84955,
          HNL: 24.64031,
          HRK: 7.054794,
          HTG: 149.73407,
          HUF: 360.43504,
          IDR: 15265.15,
          ILS: 3.66777,
          IMP: 0.8371,
          INR: 82.925504,
          IQD: 1458.655708,
          IRR: 42325.000352,
          ISK: 144.780386,
          JEP: 0.8371,
          JMD: 154.175995,
          JOD: 0.709404,
          JPY: 136.48504,
          KES: 126.527042,
          KGS: 87.420385,
          KHR: 4043.644608,
          KMF: 466.903796,
          KPW: 899.974486,
          KRW: 1314.803792,
          KWD: 0.30704,
          KYD: 0.832837,
          KZT: 448.840395,
          LAK: 16854.281801,
          LBP: 15001.369798,
          LKR: 364.78976,
          LRD: 157.803775,
          LSL: 18.410382,
          LTL: 2.95274,
          LVL: 0.60489,
          LYD: 4.819612,
          MAD: 10.407633,
          MDL: 18.839167,
          MGA: 4299.617401,
          MKD: 58.206981,
          MMK: 2098.790043,
          MNT: 3522.698591,
          MOP: 8.079165,
          MRO: 356.999828,
          MUR: 46.350379,
          MVR: 15.350378,
          MWK: 1025.843843,
          MXN: 18.387404,
          MYR: 4.435504,
          MZN: 63.103732,
          NAD: 18.410377,
          NGN: 460.440377,
          NIO: 36.518823,
          NOK: 10.439805,
          NPR: 132.329129,
          NZD: 1.622718,
          OMR: 0.383657,
          PAB: 0.999386,
          PEN: 3.802088,
          PGK: 3.523027,
          PHP: 54.920375,
          PKR: 257.801426,
          PLN: 4.475717,
          PYG: 7308.96037,
          QAR: 3.641038,
          RON: 4.664204,
          RSD: 111.235038,
          RUB: 76.015038,
          RWF: 1088.470077,
          SAR: 3.749361,
          SBD: 8.245284,
          SCR: 13.048038,
          SDG: 588.00034,
          SEK: 10.50518,
          SGD: 1.350104,
          SHP: 1.21675,
          SLE: 20.801566,
          SLL: 19750.000338,
          SOS: 568.503664,
          SRD: 33.661038,
          STD: 20697.981008,
          SVC: 8.744981,
          SYP: 2511.948091,
          SZL: 18.404421,
          THB: 34.920369,
          TJS: 10.898682,
          TMT: 3.51,
          TND: 3.130369,
          TOP: 2.35285,
          TRY: 18.874404,
          TTD: 6.783714,
          TWD: 30.671804,
          TZS: 2337.65056,
          UAH: 36.730244,
          UGX: 3717.821548,
          USD: 1,
          UYU: 38.862595,
          UZS: 11343.460394,
          VEF: 2431467.365679,
          VES: 24.38578,
          VND: 23795,
          VUV: 118.820677,
          WST: 2.71496,
          XAF: 619.675027,
          XAG: 0.048175,
          XAU: 0.000552,
          XCD: 2.70255,
          XDR: 0.751122,
          XOF: 619.675027,
          XPF: 113.450364,
          YER: 250.350363,
          ZAR: 18.423304,
          ZMK: 9001.203589,
          ZMW: 19.713665,
          ZWL: 321.999592,
        },
      },
      {
        base: "USD",
        date: "2023-02-24",
        rates: {
          AED: 3.673042,
          AFN: 89.000368,
          ALL: 108.050403,
          AMD: 389.420403,
          ANG: 1.802907,
          AOA: 506.503981,
          ARS: 195.016674,
          AUD: 1.486768,
          AWG: 1.8,
          AZN: 1.70397,
          BAM: 1.849423,
          BBD: 2.019819,
          BDT: 107.109546,
          BGN: 1.85418,
          BHD: 0.376485,
          BIF: 2062.5,
          BMD: 1,
          BND: 1.346578,
          BOB: 6.91245,
          BRL: 5.210504,
          BSD: 1.000312,
          BTC: 4.312758e-5,
          BTN: 82.783982,
          BWP: 13.320253,
          BYN: 2.525023,
          BYR: 19600,
          BZD: 2.016415,
          CAD: 1.36355,
          CDF: 2045.000362,
          CHF: 0.940404,
          CLF: 0.029827,
          CLP: 823.030396,
          CNY: 6.956204,
          COP: 4841.58,
          CRC: 561.447469,
          CUC: 1,
          CUP: 26.5,
          CVE: 104.87504,
          CZK: 22.419804,
          DJF: 177.720394,
          DKK: 7.05904,
          DOP: 55.603886,
          DZD: 136.78904,
          EGP: 30.52194,
          ERN: 15,
          ETB: 53.850392,
          EUR: 0.94475,
          FJD: 2.219304,
          FKP: 0.83212,
          GBP: 0.83717,
          GEL: 2.63504,
          GGP: 0.83212,
          GHS: 12.85039,
          GIP: 0.83212,
          GMD: 61.103853,
          GNF: 8800.000355,
          GTQ: 7.812722,
          GYD: 211.078227,
          HKD: 7.84945,
          HNL: 24.680389,
          HRK: 7.054794,
          HTG: 149.872822,
          HUF: 360.450388,
          IDR: 15265.15,
          ILS: 3.66777,
          IMP: 0.83212,
          INR: 82.933504,
          IQD: 1460,
          IRR: 42325.000352,
          ISK: 144.780386,
          JEP: 0.83212,
          JMD: 154.318863,
          JOD: 0.709404,
          JPY: 136.47504,
          KES: 126.903804,
          KGS: 87.420385,
          KHR: 4042.000351,
          KMF: 466.903796,
          KPW: 899.968194,
          KRW: 1314.803792,
          KWD: 0.307104,
          KYD: 0.833609,
          KZT: 449.239323,
          LAK: 16940.503782,
          LBP: 15030.079106,
          LKR: 365.134698,
          LRD: 157.803775,
          LSL: 18.410382,
          LTL: 2.95274,
          LVL: 0.60489,
          LYD: 4.824283,
          MAD: 10.433504,
          MDL: 18.856089,
          MGA: 4300.000347,
          MKD: 58.260093,
          MMK: 2100.774621,
          MNT: 3520.725844,
          MOP: 8.086651,
          MRO: 356.999828,
          MUR: 46.280379,
          MVR: 15.350378,
          MWK: 1017.503739,
          MXN: 18.387039,
          MYR: 4.435504,
          MZN: 63.103732,
          NAD: 18.410377,
          NGN: 459.000344,
          NIO: 36.380377,
          NOK: 10.437145,
          NPR: 132.454257,
          NZD: 1.625752,
          OMR: 0.384456,
          PAB: 1.000312,
          PEN: 3.812504,
          PGK: 3.520375,
          PHP: 55.226504,
          PKR: 260.175038,
          PLN: 4.476217,
          PYG: 7315.733238,
          QAR: 3.641038,
          RON: 4.666704,
          RSD: 111.235038,
          RUB: 76.015038,
          RWF: 1092.5,
          SAR: 3.749402,
          SBD: 8.245284,
          SCR: 13.028826,
          SDG: 588.00034,
          SEK: 10.50368,
          SGD: 1.350372,
          SHP: 1.21675,
          SLE: 20.074723,
          SLL: 19750.000338,
          SOS: 568.503664,
          SRD: 33.661038,
          STD: 20697.981008,
          SVC: 8.753085,
          SYP: 2512.496161,
          SZL: 18.42037,
          THB: 34.860369,
          TJS: 10.908369,
          TMT: 3.51,
          TND: 3.130369,
          TOP: 2.35285,
          TRY: 18.874404,
          TTD: 6.79,
          TWD: 30.681038,
          TZS: 2339.000335,
          UAH: 36.763238,
          UGX: 3721.266677,
          USD: 1,
          UYU: 38.898607,
          UZS: 11360.000334,
          VEF: 2433504.258566,
          VES: 24.38578,
          VND: 23795,
          VUV: 119.528182,
          WST: 2.670181,
          XAF: 620.260981,
          XAG: 0.048175,
          XAU: 0.000552,
          XCD: 2.70255,
          XDR: 0.751832,
          XOF: 619.503597,
          XPF: 113.450364,
          YER: 250.350363,
          ZAR: 18.423304,
          ZMK: 9001.203589,
          ZMW: 19.731933,
          ZWL: 321.999592,
        },
      },
      {
        base: "USD",
        date: "2023-02-23",
        rates: {
          AED: 3.673106,
          AFN: 88.50795,
          ALL: 108.579025,
          AMD: 390.07967,
          ANG: 1.801845,
          AOA: 503.831095,
          ARS: 195.307302,
          AUD: 1.468595,
          AWG: 1.8,
          AZN: 1.709743,
          BAM: 1.844061,
          BBD: 2.018696,
          BDT: 107.04934,
          BGN: 1.845298,
          BHD: 0.377006,
          BIF: 2065,
          BMD: 1,
          BND: 1.342152,
          BOB: 6.908503,
          BRL: 5.1377,
          BSD: 0.999778,
          BTC: 4.1780823e-5,
          BTN: 82.70863,
          BWP: 13.277642,
          BYN: 2.523642,
          BYR: 19600,
          BZD: 2.015302,
          CAD: 1.354325,
          CDF: 2046.516013,
          CHF: 0.933735,
          CLF: 0.029149,
          CLP: 804.299468,
          CNY: 6.908099,
          COP: 4876.77,
          CRC: 557.807351,
          CUC: 1,
          CUP: 26.5,
          CVE: 103.963757,
          CZK: 22.322797,
          DJF: 177.72022,
          DKK: 7.02504,
          DOP: 55.733964,
          DZD: 137.024992,
          EGP: 30.6288,
          ERN: 15,
          ETB: 53.759629,
          EUR: 0.94355,
          FJD: 2.20465,
          FKP: 0.828992,
          GBP: 0.83216,
          GEL: 2.634953,
          GGP: 0.828992,
          GHS: 12.897484,
          GIP: 0.828992,
          GMD: 61.063464,
          GNF: 8599.296631,
          GTQ: 7.808341,
          GYD: 210.963502,
          HKD: 7.84715,
          HNL: 24.649023,
          HRK: 7.054794,
          HTG: 149.248074,
          HUF: 359.535004,
          IDR: 15217.5,
          ILS: 3.62825,
          IMP: 0.828992,
          INR: 82.61815,
          IQD: 1459.235723,
          IRR: 42349.999869,
          ISK: 144.060018,
          JEP: 0.828992,
          JMD: 154.25557,
          JOD: 0.709403,
          JPY: 134.635039,
          KES: 126.279731,
          KGS: 87.420163,
          KHR: 4053.233517,
          KMF: 462.849743,
          KPW: 899.963916,
          KRW: 1298.429625,
          KWD: 0.30685,
          KYD: 0.8332,
          KZT: 448.213764,
          LAK: 16860.768803,
          LBP: 15041.759071,
          LKR: 364.929616,
          LRD: 157.798524,
          LSL: 18.270294,
          LTL: 2.95274,
          LVL: 0.60489,
          LYD: 4.817228,
          MAD: 10.394584,
          MDL: 18.796353,
          MGA: 4301.16864,
          MKD: 58.149302,
          MMK: 2099.59429,
          MNT: 3523.642162,
          MOP: 8.079782,
          MRO: 356.999828,
          MUR: 46.139701,
          MVR: 15.349821,
          MWK: 1026.887666,
          MXN: 18.371698,
          MYR: 4.434019,
          MZN: 63.098647,
          NAD: 18.269783,
          NGN: 460.44007,
          NIO: 36.53275,
          NOK: 10.306375,
          NPR: 132.334223,
          NZD: 1.604795,
          OMR: 0.385007,
          PAB: 0.999778,
          PEN: 3.806112,
          PGK: 3.56823,
          PHP: 54.969507,
          PKR: 260.949548,
          PLN: 4.461155,
          PYG: 7309.472851,
          QAR: 3.64102,
          RON: 4.638703,
          RSD: 110.690132,
          RUB: 75.391881,
          RWF: 1087.977673,
          SAR: 3.752071,
          SBD: 8.266721,
          SCR: 13.889596,
          SDG: 587.497801,
          SEK: 10.430598,
          SGD: 1.342795,
          SHP: 1.21675,
          SLE: 20.000031,
          SLL: 19749.999927,
          SOS: 568.535859,
          SRD: 33.47013,
          STD: 20697.981008,
          SVC: 8.748739,
          SYP: 2512.02876,
          SZL: 18.308992,
          THB: 34.709826,
          TJS: 10.912871,
          TMT: 3.51,
          TND: 3.152502,
          TOP: 2.35115,
          TRY: 18.873397,
          TTD: 6.790178,
          TWD: 30.290505,
          TZS: 2331.999871,
          UAH: 36.744326,
          UGX: 3724.184097,
          USD: 1,
          UYU: 39.306642,
          UZS: 11314.752831,
          VEF: 2434569.523251,
          VES: 24.370899,
          VND: 23830,
          VUV: 118.927887,
          WST: 2.714364,
          XAF: 618.471446,
          XAG: 0.046889,
          XAU: 0.000548,
          XCD: 2.70255,
          XDR: 0.750417,
          XOF: 618.471446,
          XPF: 112.249709,
          YER: 250.349643,
          ZAR: 18.22045,
          ZMK: 9001.196346,
          ZMW: 19.670763,
          ZWL: 321.999592,
        },
      },
      {
        base: "USD",
        date: "2023-02-22",
        rates: {
          AED: 3.673104,
          AFN: 89.500507,
          ALL: 108.42484,
          AMD: 390.48033,
          ANG: 1.801825,
          AOA: 503.830597,
          ARS: 194.963298,
          AUD: 1.467997,
          AWG: 1.8025,
          AZN: 1.697339,
          BAM: 1.839569,
          BBD: 2.018623,
          BDT: 107.091798,
          BGN: 1.844145,
          BHD: 0.377045,
          BIF: 2070,
          BMD: 1,
          BND: 1.339541,
          BOB: 6.908484,
          BRL: 5.152698,
          BSD: 0.999812,
          BTC: 4.1339325e-5,
          BTN: 82.771633,
          BWP: 13.224699,
          BYN: 2.523514,
          BYR: 19600,
          BZD: 2.015237,
          CAD: 1.35475,
          CDF: 2046.492106,
          CHF: 0.931305,
          CLF: 0.02903,
          CLP: 801.040181,
          CNY: 6.892303,
          COP: 4896.18,
          CRC: 557.806622,
          CUC: 1,
          CUP: 26.5,
          CVE: 103.710497,
          CZK: 22.327101,
          DJF: 177.719991,
          DKK: 7.01967,
          DOP: 55.982882,
          DZD: 136.96884,
          EGP: 30.631398,
          ERN: 15,
          ETB: 53.758371,
          EUR: 0.942901,
          FJD: 2.20515,
          FKP: 0.825569,
          GBP: 0.83006,
          GEL: 2.635015,
          GGP: 0.825569,
          GHS: 12.846951,
          GIP: 0.825569,
          GMD: 61.10529,
          GNF: 8603.64936,
          GTQ: 7.815839,
          GYD: 210.957487,
          HKD: 7.845645,
          HNL: 24.649172,
          HRK: 7.054794,
          HTG: 149.244733,
          HUF: 359.619837,
          IDR: 15203,
          ILS: 3.631595,
          IMP: 0.825569,
          INR: 82.83555,
          IQD: 1459.179834,
          IRR: 42349.99951,
          ISK: 144.170433,
          JEP: 0.825569,
          JMD: 154.216516,
          JOD: 0.709403,
          JPY: 134.926997,
          KES: 126.069954,
          KGS: 87.42006,
          KHR: 4060.10158,
          KMF: 462.850109,
          KPW: 899.891511,
          KRW: 1303.159916,
          KWD: 0.306711,
          KYD: 0.833145,
          KZT: 449.250622,
          LAK: 16854.787512,
          LBP: 15208.107471,
          LKR: 364.921181,
          LRD: 157.801088,
          LSL: 18.270078,
          LTL: 2.95274,
          LVL: 0.60489,
          LYD: 4.814334,
          MAD: 10.394658,
          MDL: 18.790914,
          MGA: 4279.552295,
          MKD: 58.092558,
          MMK: 2099.476785,
          MNT: 3524.633122,
          MOP: 8.079587,
          MRO: 356.999828,
          MUR: 45.549905,
          MVR: 15.3495,
          MWK: 1026.213318,
          MXN: 18.368975,
          MYR: 4.441497,
          MZN: 63.099964,
          NAD: 18.27033,
          NGN: 460.469668,
          NIO: 36.532167,
          NOK: 10.354125,
          NPR: 132.434161,
          NZD: 1.606775,
          OMR: 0.385,
          PAB: 0.999718,
          PEN: 3.834368,
          PGK: 3.522856,
          PHP: 55.140036,
          PKR: 261.693378,
          PLN: 4.4783,
          PYG: 7320.917983,
          QAR: 3.640965,
          RON: 4.63564,
          RSD: 110.659933,
          RUB: 74.89841,
          RWF: 1102.257336,
          SAR: 3.751196,
          SBD: 8.266721,
          SCR: 14.05836,
          SDG: 587.494993,
          SEK: 10.421795,
          SGD: 1.34079,
          SHP: 1.21675,
          SLE: 20.000017,
          SLL: 19749.999676,
          SOS: 568.499493,
          SRD: 33.312954,
          STD: 20697.981008,
          SVC: 8.747178,
          SYP: 2512.532432,
          SZL: 18.261569,
          THB: 34.639687,
          TJS: 10.83766,
          TMT: 3.51,
          TND: 3.152496,
          TOP: 2.342297,
          TRY: 18.88019,
          TTD: 6.784424,
          TWD: 30.490299,
          TZS: 2331.999823,
          UAH: 36.74351,
          UGX: 3708.239278,
          USD: 1,
          UYU: 39.30681,
          UZS: 11314.227939,
          VEF: 2434573.201683,
          VES: 24.370899,
          VND: 23800,
          VUV: 118.892889,
          WST: 2.701973,
          XAF: 616.964823,
          XAG: 0.046482,
          XAU: 0.000548,
          XCD: 2.70255,
          XDR: 0.749436,
          XOF: 616.950316,
          XPF: 112.249597,
          YER: 250.349945,
          ZAR: 18.2182,
          ZMK: 9001.193084,
          ZMW: 19.590764,
          ZWL: 321.999592,
        },
      },
      {
        base: "USD",
        date: "2023-02-21",
        rates: {
          AED: 3.673096,
          AFN: 89.329815,
          ALL: 108.055065,
          AMD: 393.804229,
          ANG: 1.803593,
          AOA: 507.499549,
          ARS: 193.114097,
          AUD: 1.457344,
          AWG: 1.8025,
          AZN: 1.702449,
          BAM: 1.836598,
          BBD: 2.020621,
          BDT: 106.282256,
          BGN: 1.836625,
          BHD: 0.37704,
          BIF: 2078.851734,
          BMD: 1,
          BND: 1.339268,
          BOB: 6.915355,
          BRL: 5.168022,
          BSD: 1.000732,
          BTC: 4.0914901e-5,
          BTN: 82.868197,
          BWP: 13.220336,
          BYN: 2.526011,
          BYR: 19600,
          BZD: 2.017241,
          CAD: 1.35354,
          CDF: 2044.999711,
          CHF: 0.927555,
          CLF: 0.029094,
          CLP: 802.789748,
          CNY: 6.873497,
          COP: 4969.25,
          CRC: 558.661684,
          CUC: 1,
          CUP: 26.5,
          CVE: 103.542989,
          CZK: 22.278026,
          DJF: 178.191789,
          DKK: 6.98974,
          DOP: 56.033317,
          DZD: 137.107028,
          EGP: 30.600803,
          ERN: 15,
          ETB: 53.806577,
          EUR: 0.93882,
          FJD: 2.200302,
          FKP: 0.830715,
          GBP: 0.82585,
          GEL: 2.63501,
          GGP: 0.830715,
          GHS: 12.759926,
          GIP: 0.830715,
          GMD: 61.097068,
          GNF: 8611.244143,
          GTQ: 7.823592,
          GYD: 211.161402,
          HKD: 7.84485,
          HNL: 24.671337,
          HRK: 7.054794,
          HTG: 149.395248,
          HUF: 359.575006,
          IDR: 15240,
          ILS: 3.63556,
          IMP: 0.830715,
          INR: 82.86145,
          IQD: 1460.654321,
          IRR: 42350.000151,
          ISK: 144.860202,
          JEP: 0.830715,
          JMD: 154.304897,
          JOD: 0.709401,
          JPY: 134.977027,
          KES: 126.150194,
          KGS: 87.4181,
          KHR: 4078.223307,
          KMF: 462.849945,
          KPW: 899.956278,
          KRW: 1306.179632,
          KWD: 0.30655,
          KYD: 0.833959,
          KZT: 447.301206,
          LAK: 16866.525185,
          LBP: 15021.597867,
          LKR: 365.282932,
          LRD: 157.801546,
          LSL: 18.270366,
          LTL: 2.952739,
          LVL: 0.60489,
          LYD: 4.814352,
          MAD: 10.386602,
          MDL: 18.78958,
          MGA: 4289.157871,
          MKD: 57.858806,
          MMK: 2101.627071,
          MNT: 3524.998196,
          MOP: 8.085208,
          MRO: 356.999828,
          MUR: 46.650706,
          MVR: 15.349756,
          MWK: 1027.220568,
          MXN: 18.444894,
          MYR: 4.432501,
          MZN: 63.103496,
          NAD: 18.270098,
          NGN: 459.99984,
          NIO: 36.568692,
          NOK: 10.298401,
          NPR: 132.589302,
          NZD: 1.608895,
          OMR: 0.385039,
          PAB: 1.000732,
          PEN: 3.844045,
          PGK: 3.57163,
          PHP: 55.143038,
          PKR: 262.453142,
          PLN: 4.45855,
          PYG: 7339.424558,
          QAR: 3.641038,
          RON: 4.619401,
          RSD: 110.160387,
          RUB: 74.64973,
          RWF: 1088.296883,
          SAR: 3.750857,
          SBD: 8.266721,
          SCR: 14.137212,
          SDG: 587.497933,
          SEK: 10.37562,
          SGD: 1.340305,
          SHP: 1.216751,
          SLE: 19.840028,
          SLL: 19749.999732,
          SOS: 568.505977,
          SRD: 33.523993,
          STD: 20697.981008,
          SVC: 8.756526,
          SYP: 2512.529747,
          SZL: 18.221369,
          THB: 34.65503,
          TJS: 10.713293,
          TMT: 3.51,
          TND: 3.152496,
          TOP: 2.3423,
          TRY: 18.867696,
          TTD: 6.79112,
          TWD: 30.499502,
          TZS: 2331.9995,
          UAH: 36.779946,
          UGX: 3692.859558,
          USD: 1,
          UYU: 39.616121,
          UZS: 11328.860384,
          VEF: 2434503.294366,
          VES: 24.370899,
          VND: 23720,
          VUV: 119.066504,
          WST: 2.70784,
          XAF: 615.962552,
          XAG: 0.04573,
          XAU: 0.000545,
          XCD: 2.70255,
          XDR: 0.751887,
          XOF: 615.968336,
          XPF: 112.249749,
          YER: 250.35032,
          ZAR: 18.255303,
          ZMK: 9001.196482,
          ZMW: 19.540153,
          ZWL: 321.999592,
        },
      },
      {
        base: "USD",
        date: "2023-02-20",
        rates: {
          AED: 3.673102,
          AFN: 89.281034,
          ALL: 108.098746,
          AMD: 392.349693,
          ANG: 1.802676,
          AOA: 506.501299,
          ARS: 193.107301,
          AUD: 1.447597,
          AWG: 1.8,
          AZN: 1.702782,
          BAM: 1.830959,
          BBD: 2.019491,
          BDT: 106.221869,
          BGN: 1.829846,
          BHD: 0.37703,
          BIF: 2077.673448,
          BMD: 1,
          BND: 1.335611,
          BOB: 6.911505,
          BRL: 5.168039,
          BSD: 1.000197,
          BTC: 4.0263205e-5,
          BTN: 82.705486,
          BWP: 13.16935,
          BYN: 2.524621,
          BYR: 19600,
          BZD: 2.01614,
          CAD: 1.345325,
          CDF: 2046.999907,
          CHF: 0.9233,
          CLF: 0.028915,
          CLP: 797.840296,
          CNY: 6.855098,
          COP: 4911.33,
          CRC: 558.351589,
          CUC: 1,
          CUP: 26.5,
          CVE: 103.223602,
          CZK: 22.202903,
          DJF: 177.719771,
          DKK: 6.968621,
          DOP: 56.001685,
          DZD: 136.806969,
          EGP: 30.570802,
          ERN: 15,
          ETB: 53.769215,
          EUR: 0.93605,
          FJD: 2.191603,
          FKP: 0.830365,
          GBP: 0.830695,
          GEL: 2.64499,
          GGP: 0.830365,
          GHS: 12.752907,
          GIP: 0.830365,
          GMD: 61.149587,
          GNF: 8607.630486,
          GTQ: 7.826697,
          GYD: 211.047608,
          HKD: 7.83425,
          HNL: 24.655495,
          HRK: 7.054794,
          HTG: 149.308182,
          HUF: 358.274994,
          IDR: 15168,
          ILS: 3.574755,
          IMP: 0.830365,
          INR: 82.69735,
          IQD: 1459.801535,
          IRR: 42250.000309,
          ISK: 144.810119,
          JEP: 0.830365,
          JMD: 154.307968,
          JOD: 0.709405,
          JPY: 134.311499,
          KES: 126.129862,
          KGS: 87.419984,
          KHR: 4078.843282,
          KMF: 460.600601,
          KPW: 900,
          KRW: 1296.45997,
          KWD: 0.30639,
          KYD: 0.833462,
          KZT: 447.095829,
          LAK: 16858.143214,
          LBP: 15013.246707,
          LKR: 361.99457,
          LRD: 157.298158,
          LSL: 18.029981,
          LTL: 2.95274,
          LVL: 0.60489,
          LYD: 4.801371,
          MAD: 10.342071,
          MDL: 18.703894,
          MGA: 4289.642195,
          MKD: 57.674488,
          MMK: 2100.432691,
          MNT: 3519.89932,
          MOP: 8.068246,
          MRO: 356.999828,
          MUR: 46.050261,
          MVR: 15.36034,
          MWK: 1026.602323,
          MXN: 18.385695,
          MYR: 4.430497,
          MZN: 63.105277,
          NAD: 18.030326,
          NGN: 460.549778,
          NIO: 36.547634,
          NOK: 10.24058,
          NPR: 132.329152,
          NZD: 1.59853,
          OMR: 0.385012,
          PAB: 1.000206,
          PEN: 3.845833,
          PGK: 3.52434,
          PHP: 54.958007,
          PKR: 261.678899,
          PLN: 4.439036,
          PYG: 7299.194907,
          QAR: 3.641047,
          RON: 4.602401,
          RSD: 109.8498,
          RUB: 75.498974,
          RWF: 1087.418087,
          SAR: 3.750971,
          SBD: 8.265733,
          SCR: 13.617778,
          SDG: 585.499968,
          SEK: 10.346501,
          SGD: 1.335705,
          SHP: 1.21675,
          SLE: 19.84005,
          SLL: 19749.999919,
          SOS: 569.498168,
          SRD: 33.373497,
          STD: 20697.981008,
          SVC: 8.752188,
          SYP: 2512.097462,
          SZL: 18.079462,
          THB: 34.407848,
          TJS: 10.742133,
          TMT: 3.51,
          TND: 3.100498,
          TOP: 2.347799,
          TRY: 18.870865,
          TTD: 6.787276,
          TWD: 30.3798,
          TZS: 2331.999931,
          UAH: 36.940086,
          UGX: 3677.844558,
          USD: 1,
          UYU: 39.593144,
          UZS: 11322.63289,
          VEF: 2433164.977531,
          VES: 24.356926,
          VND: 23740,
          VUV: 117.126065,
          WST: 2.692506,
          XAF: 614.076952,
          XAG: 0.045859,
          XAU: 0.000543,
          XCD: 2.70255,
          XDR: 0.751451,
          XOF: 614.08845,
          XPF: 112.097197,
          YER: 250.350125,
          ZAR: 18.0927,
          ZMK: 9001.202556,
          ZMW: 19.499335,
          ZWL: 321.999592,
        },
      },
      {
        base: "USD",
        date: "2023-02-19",
        rates: {
          AED: 3.673032,
          AFN: 88.761411,
          ALL: 108.010986,
          AMD: 390.978851,
          ANG: 1.792073,
          AOA: 506.505413,
          ARS: 191.750734,
          AUD: 1.455695,
          AWG: 1.8,
          AZN: 1.691965,
          BAM: 1.830282,
          BBD: 2.007683,
          BDT: 105.203468,
          BGN: 1.830526,
          BHD: 0.377223,
          BIF: 2065.15036,
          BMD: 1,
          BND: 1.332766,
          BOB: 6.871078,
          BRL: 5.188564,
          BSD: 0.99439,
          BTC: 4.1187404e-5,
          BTN: 82.337649,
          BWP: 13.152972,
          BYN: 2.509838,
          BYR: 19600,
          BZD: 2.004314,
          CAD: 1.348375,
          CDF: 2046.999805,
          CHF: 0.92523,
          CLF: 0.02884,
          CLP: 795.780293,
          CNY: 6.867603,
          COP: 4899.307505,
          CRC: 556.092083,
          CUC: 1,
          CUP: 26.5,
          CVE: 103.186412,
          CZK: 22.191803,
          DJF: 177.04556,
          DKK: 6.96983,
          DOP: 55.703724,
          DZD: 136.953663,
          EGP: 30.581981,
          ERN: 15,
          ETB: 53.448999,
          EUR: 0.935898,
          FJD: 2.214901,
          FKP: 0.830379,
          GBP: 0.83126,
          GEL: 2.644953,
          GGP: 0.830379,
          GHS: 12.678083,
          GIP: 0.830379,
          GMD: 61.150048,
          GNF: 8561.428792,
          GTQ: 7.780835,
          GYD: 209.807224,
          HKD: 7.844915,
          HNL: 24.510575,
          HRK: 7.054794,
          HTG: 149.022562,
          HUF: 359.369714,
          IDR: 15166,
          ILS: 3.55223,
          IMP: 0.830379,
          INR: 82.765496,
          IQD: 1451.282051,
          IRR: 42249.99984,
          ISK: 144.11985,
          JEP: 0.830379,
          JMD: 153.465064,
          JOD: 0.710016,
          JPY: 134.311978,
          KES: 125.140395,
          KGS: 87.420276,
          KHR: 4058.936651,
          KMF: 460.599267,
          KPW: 900.000008,
          KRW: 1295.920328,
          KWD: 0.30637,
          KYD: 0.828561,
          KZT: 446.125772,
          LAK: 16761.448444,
          LBP: 14925.392689,
          LKR: 363.439282,
          LRD: 157.303101,
          LSL: 18.029915,
          LTL: 2.95274,
          LVL: 0.60489,
          LYD: 4.773277,
          MAD: 10.315647,
          MDL: 18.619402,
          MGA: 4277.850823,
          MKD: 57.664503,
          MMK: 2088.159873,
          MNT: 3519.857365,
          MOP: 8.03425,
          MRO: 356.999828,
          MUR: 46.088556,
          MVR: 15.360123,
          MWK: 1020.647673,
          MXN: 18.36975,
          MYR: 4.431992,
          MZN: 63.100354,
          NAD: 18.030201,
          NGN: 460.539547,
          NIO: 36.33352,
          NOK: 10.294455,
          NPR: 131.739659,
          NZD: 1.603765,
          OMR: 0.385282,
          PAB: 0.994296,
          PEN: 3.828485,
          PGK: 3.548772,
          PHP: 55.524975,
          PKR: 261.268582,
          PLN: 4.456551,
          PYG: 7251.110103,
          QAR: 3.641011,
          RON: 4.60012,
          RSD: 109.825849,
          RUB: 73.999956,
          RWF: 1080.587126,
          SAR: 3.752502,
          SBD: 8.265733,
          SCR: 13.103036,
          SDG: 585.508796,
          SEK: 10.46199,
          SGD: 1.337065,
          SHP: 1.21675,
          SLE: 19.840041,
          SLL: 19750.000131,
          SOS: 569.498699,
          SRD: 32.981991,
          STD: 20697.981008,
          SVC: 8.700168,
          SYP: 2511.987572,
          SZL: 18.125202,
          THB: 34.438496,
          TJS: 10.455643,
          TMT: 3.51,
          TND: 3.100497,
          TOP: 2.340699,
          TRY: 18.86234,
          TTD: 6.74752,
          TWD: 30.388298,
          TZS: 2325.810995,
          UAH: 36.53779,
          UGX: 3649.260715,
          USD: 1,
          UYU: 39.138125,
          UZS: 11278.052022,
          VEF: 2433164.963915,
          VES: 24.356926,
          VND: 23820,
          VUV: 117.126721,
          WST: 2.692507,
          XAF: 613.849962,
          XAG: 0.046296,
          XAU: 0.000544,
          XCD: 2.70255,
          XDR: 0.743874,
          XOF: 613.849962,
          XPF: 112.103419,
          YER: 250.349722,
          ZAR: 18.075,
          ZMK: 9001.235859,
          ZMW: 19.315456,
          ZWL: 321.999592,
        },
      },
      {
        base: "USD",
        date: "2023-02-18",
        rates: {
          AED: 3.673042,
          AFN: 88.467099,
          ALL: 107.652847,
          AMD: 389.684279,
          ANG: 1.786131,
          AOA: 506.503981,
          ARS: 192.6237,
          AUD: 1.453488,
          AWG: 1.8,
          AZN: 1.70397,
          BAM: 1.824213,
          BBD: 2.001026,
          BDT: 104.854638,
          BGN: 1.827704,
          BHD: 0.375974,
          BIF: 2058.322063,
          BMD: 1,
          BND: 1.328359,
          BOB: 6.848295,
          BRL: 5.166404,
          BSD: 0.991093,
          BTC: 4.0579295e-5,
          BTN: 82.064636,
          BWP: 13.10936,
          BYN: 2.501516,
          BYR: 19600,
          BZD: 1.997668,
          CAD: 1.34755,
          CDF: 2047.000362,
          CHF: 0.924942,
          CLF: 0.028607,
          CLP: 793.149093,
          CNY: 6.867604,
          COP: 4883.085389,
          CRC: 554.250804,
          CUC: 1,
          CUP: 26.5,
          CVE: 102.844751,
          CZK: 22.159504,
          DJF: 176.458518,
          DKK: 6.962204,
          DOP: 55.519284,
          DZD: 136.499557,
          EGP: 30.478011,
          ERN: 15,
          ETB: 53.272024,
          EUR: 0.932804,
          FJD: 2.214904,
          FKP: 0.832342,
          GBP: 0.830289,
          GEL: 2.64504,
          GGP: 0.832342,
          GHS: 12.636105,
          GIP: 0.832342,
          GMD: 61.15039,
          GNF: 8533.041086,
          GTQ: 7.755072,
          GYD: 209.112531,
          HKD: 7.84615,
          HNL: 24.429418,
          HRK: 7.054794,
          HTG: 148.530523,
          HUF: 358.803833,
          IDR: 15166,
          ILS: 3.551404,
          IMP: 0.832342,
          INR: 82.765504,
          IQD: 1446.476706,
          IRR: 42250.000352,
          ISK: 144.120386,
          JEP: 0.832342,
          JMD: 152.956209,
          JOD: 0.710385,
          JPY: 134.11504,
          KES: 124.725458,
          KGS: 87.420385,
          KHR: 4045.516019,
          KMF: 460.603796,
          KPW: 899.986999,
          KRW: 1295.840384,
          KWD: 0.30637,
          KYD: 0.825817,
          KZT: 444.648603,
          LAK: 16705.87138,
          LBP: 14875.903558,
          LKR: 362.234202,
          LRD: 157.303775,
          LSL: 18.030382,
          LTL: 2.95274,
          LVL: 0.60489,
          LYD: 4.75745,
          MAD: 10.28149,
          MDL: 18.557665,
          MGA: 4263.666465,
          MKD: 57.473301,
          MMK: 2081.236021,
          MNT: 3521.819623,
          MOP: 8.007648,
          MRO: 356.999828,
          MUR: 46.050379,
          MVR: 15.360378,
          MWK: 1017.263443,
          MXN: 18.361204,
          MYR: 4.432039,
          MZN: 63.103732,
          NAD: 18.030377,
          NGN: 460.540377,
          NIO: 36.213216,
          NOK: 10.317235,
          NPR: 131.303456,
          NZD: 1.601281,
          OMR: 0.384004,
          PAB: 0.990999,
          PEN: 3.815791,
          PGK: 3.537005,
          PHP: 55.525039,
          PKR: 260.402276,
          PLN: 4.44935,
          PYG: 7227.067108,
          QAR: 3.641038,
          RON: 4.589504,
          RSD: 109.463228,
          RUB: 74.000341,
          RWF: 1077.004151,
          SAR: 3.747703,
          SBD: 8.265733,
          SCR: 13.07,
          SDG: 585.503678,
          SEK: 10.451204,
          SGD: 1.337038,
          SHP: 1.21675,
          SLE: 19.83967,
          SLL: 19750.000338,
          SOS: 569.503664,
          SRD: 32.982038,
          STD: 20697.981008,
          SVC: 8.671361,
          SYP: 2511.812308,
          SZL: 18.065103,
          THB: 34.453038,
          TJS: 10.421023,
          TMT: 3.51,
          TND: 3.100504,
          TOP: 2.340704,
          TRY: 18.829704,
          TTD: 6.725178,
          TWD: 30.388304,
          TZS: 2318.099147,
          UAH: 36.416639,
          UGX: 3637.177634,
          USD: 1,
          UYU: 39.008534,
          UZS: 11240.656625,
          VEF: 2432955.73414,
          VES: 24.356926,
          VND: 23820,
          VUV: 118.031104,
          WST: 2.700823,
          XAF: 611.814578,
          XAG: 0.045982,
          XAU: 0.000543,
          XCD: 2.70255,
          XDR: 0.741407,
          XOF: 611.814578,
          XPF: 112.103595,
          YER: 250.350363,
          ZAR: 18.05194,
          ZMK: 9001.203589,
          ZMW: 19.251411,
          ZWL: 321.999592,
        },
      },
      {
        base: "USD",
        date: "2023-02-17",
        rates: {
          AED: 3.673042,
          AFN: 89.000368,
          ALL: 108.250403,
          AMD: 393.314223,
          ANG: 1.802726,
          AOA: 506.503981,
          ARS: 192.6237,
          AUD: 1.4537,
          AWG: 1.8,
          AZN: 1.70397,
          BAM: 1.841223,
          BBD: 2.019637,
          BDT: 105.83087,
          BGN: 1.82859,
          BHD: 0.376849,
          BIF: 2070,
          BMD: 1,
          BND: 1.340726,
          BOB: 6.912055,
          BRL: 5.168041,
          BSD: 1.000311,
          BTC: 4.069546e-5,
          BTN: 82.828686,
          BWP: 13.231288,
          BYN: 2.524841,
          BYR: 19600,
          BZD: 2.016248,
          CAD: 1.34805,
          CDF: 2047.000362,
          CHF: 0.924942,
          CLF: 0.028607,
          CLP: 789.350396,
          CNY: 6.867704,
          COP: 4899.91,
          CRC: 559.405801,
          CUC: 1,
          CUP: 26.5,
          CVE: 103.62504,
          CZK: 22.159404,
          DJF: 177.720394,
          DKK: 6.962104,
          DOP: 55.980393,
          DZD: 136.588735,
          EGP: 30.478011,
          ERN: 15,
          ETB: 53.650392,
          EUR: 0.93285,
          FJD: 2.20875,
          FKP: 0.832145,
          GBP: 0.83022,
          GEL: 2.64504,
          GGP: 0.832145,
          GHS: 12.57504,
          GIP: 0.832145,
          GMD: 61.15039,
          GNF: 8800.000355,
          GTQ: 7.827127,
          GYD: 211.057452,
          HKD: 7.84615,
          HNL: 24.703841,
          HRK: 7.054794,
          HTG: 149.911981,
          HUF: 358.803833,
          IDR: 15166,
          ILS: 3.551404,
          IMP: 0.832145,
          INR: 82.81515,
          IQD: 1460.5,
          IRR: 42250.000352,
          ISK: 144.120386,
          JEP: 0.832145,
          JMD: 154.380284,
          JOD: 0.710385,
          JPY: 134.10504,
          KES: 125.903804,
          KGS: 87.420385,
          KHR: 4081.000351,
          KMF: 460.603796,
          KPW: 899.905445,
          KRW: 1295.903792,
          KWD: 0.30637,
          KYD: 0.833498,
          KZT: 448.784207,
          LAK: 16932.503782,
          LBP: 15205.837396,
          LKR: 365.606725,
          LRD: 157.303775,
          LSL: 18.030382,
          LTL: 2.95274,
          LVL: 0.60489,
          LYD: 4.803765,
          MAD: 10.333504,
          MDL: 18.730707,
          MGA: 4312.503758,
          MKD: 57.786176,
          MMK: 2100.613033,
          MNT: 3521.83702,
          MOP: 8.082202,
          MRO: 356.999828,
          MUR: 46.054508,
          MVR: 15.360378,
          MWK: 1024.503739,
          MXN: 18.361204,
          MYR: 4.432039,
          MZN: 63.103732,
          NAD: 18.030377,
          NGN: 460.000344,
          NIO: 36.405039,
          NOK: 10.22522,
          NPR: 132.525935,
          NZD: 1.601281,
          OMR: 0.384255,
          PAB: 1.000217,
          PEN: 3.839504,
          PGK: 3.520375,
          PHP: 55.283039,
          PKR: 262.950375,
          PLN: 4.44935,
          PYG: 7294.28488,
          QAR: 3.641038,
          RON: 4.589504,
          RSD: 109.740373,
          RUB: 74.000341,
          RWF: 1090,
          SAR: 3.748603,
          SBD: 8.265733,
          SCR: 13.078538,
          SDG: 585.503678,
          SEK: 10.45515,
          SGD: 1.337104,
          SHP: 1.21675,
          SLE: 19.944318,
          SLL: 19750.000338,
          SOS: 569.503664,
          SRD: 32.982038,
          STD: 20697.981008,
          SVC: 8.752012,
          SYP: 2512.4919,
          SZL: 18.03037,
          THB: 34.360369,
          TJS: 10.517947,
          TMT: 3.51,
          TND: 3.100504,
          TOP: 2.340704,
          TRY: 18.828904,
          TTD: 6.787728,
          TWD: 30.387504,
          TZS: 2340.000335,
          UAH: 36.75621,
          UGX: 3671.00643,
          USD: 1,
          UYU: 39.370976,
          UZS: 11350.000334,
          VEF: 2430871.330498,
          VES: 24.356926,
          VND: 23820,
          VUV: 118.090963,
          WST: 2.698985,
          XAF: 617.510779,
          XAG: 0.045982,
          XAU: 0.000543,
          XCD: 2.70255,
          XDR: 0.74831,
          XOF: 615.503597,
          XPF: 112.103595,
          YER: 250.350363,
          ZAR: 18.051855,
          ZMK: 9001.203589,
          ZMW: 19.430465,
          ZWL: 321.999592,
        },
      },
      {
        base: "USD",
        date: "2023-02-16",
        rates: {
          AED: 3.67295,
          AFN: 89.999861,
          ALL: 108.29885,
          AMD: 393.883632,
          ANG: 1.802218,
          AOA: 507.502481,
          ARS: 192.796899,
          AUD: 1.456885,
          AWG: 1.8,
          AZN: 1.700423,
          BAM: 1.826905,
          BBD: 2.019037,
          BDT: 105.796071,
          BGN: 1.83267,
          BHD: 0.376962,
          BIF: 2070,
          BMD: 1,
          BND: 1.333869,
          BOB: 6.909842,
          BRL: 5.218299,
          BSD: 0.999944,
          BTC: 4.2424698e-5,
          BTN: 82.648334,
          BWP: 13.157506,
          BYN: 2.524543,
          BYR: 19600,
          BZD: 2.015674,
          CAD: 1.34735,
          CDF: 2046.999631,
          CHF: 0.926645,
          CLF: 0.028689,
          CLP: 791.620354,
          CNY: 6.8607,
          COP: 4925.05,
          CRC: 561.855478,
          CUC: 1,
          CUP: 26.5,
          CVE: 103.224979,
          CZK: 22.185502,
          DJF: 177.720438,
          DKK: 6.983795,
          DOP: 56.000033,
          DZD: 136.840921,
          EGP: 30.568369,
          ERN: 15,
          ETB: 53.654973,
          EUR: 0.937599,
          FJD: 2.196903,
          FKP: 0.831177,
          GBP: 0.835075,
          GEL: 2.635006,
          GGP: 0.831177,
          GHS: 12.374529,
          GIP: 0.831177,
          GMD: 61.098074,
          GNF: 8609.712583,
          GTQ: 7.824783,
          GYD: 210.990407,
          HKD: 7.84915,
          HNL: 24.710211,
          HRK: 7.054794,
          HTG: 150.995741,
          HUF: 359.91026,
          IDR: 15147,
          ILS: 3.55392,
          IMP: 0.831177,
          INR: 82.69555,
          IQD: 1459.460469,
          IRR: 42249.999997,
          ISK: 144.859854,
          JEP: 0.831177,
          JMD: 154.494335,
          JOD: 0.709397,
          JPY: 134.061984,
          KES: 125.649568,
          KGS: 87.419898,
          KHR: 4082.00028,
          KMF: 460.949726,
          KPW: 899.970745,
          KRW: 1289.959936,
          KWD: 0.30648,
          KYD: 0.833294,
          KZT: 443.530082,
          LAK: 16929.392753,
          LBP: 15009.621133,
          LKR: 364.989912,
          LRD: 157.30229,
          LSL: 18.04015,
          LTL: 2.95274,
          LVL: 0.60489,
          LYD: 4.794993,
          MAD: 10.309744,
          MDL: 18.724324,
          MGA: 4310.000135,
          MKD: 57.82929,
          MMK: 2099.941339,
          MNT: 3519.341233,
          MOP: 8.084572,
          MRO: 356.999828,
          MUR: 46.139908,
          MVR: 15.360021,
          MWK: 1026.376849,
          MXN: 18.550197,
          MYR: 4.404034,
          MZN: 63.097943,
          NAD: 18.040432,
          NGN: 460.550261,
          NIO: 36.404944,
          NOK: 10.26411,
          NPR: 132.237334,
          NZD: 1.60101,
          OMR: 0.385034,
          PAB: 0.999944,
          PEN: 3.854022,
          PGK: 3.51981,
          PHP: 55.25003,
          PKR: 264.240171,
          PLN: 4.474052,
          PYG: 7277.618465,
          QAR: 3.641025,
          RON: 4.591703,
          RSD: 109.960034,
          RUB: 74.715005,
          RWF: 1085.983037,
          SAR: 3.750984,
          SBD: 8.265733,
          SCR: 13.565568,
          SDG: 585.502559,
          SEK: 10.45872,
          SGD: 1.33711,
          SHP: 1.21675,
          SLE: 19.843553,
          SLL: 19749.999796,
          SOS: 569.502526,
          SRD: 33.016501,
          STD: 20697.981008,
          SVC: 8.749626,
          SYP: 2511.94363,
          SZL: 18.114538,
          THB: 34.410284,
          TJS: 10.514586,
          TMT: 3.51,
          TND: 3.120039,
          TOP: 2.340096,
          TRY: 18.86375,
          TTD: 6.789718,
          TWD: 30.260499,
          TZS: 2338.99972,
          UAH: 36.930794,
          UGX: 3669.855029,
          USD: 1,
          UYU: 39.098798,
          UZS: 11359.999958,
          VEF: 2428744.272849,
          VES: 24.168502,
          VND: 23705,
          VUV: 116.529397,
          WST: 2.715825,
          XAF: 612.717524,
          XAG: 0.046451,
          XAU: 0.000545,
          XCD: 2.70255,
          XDR: 0.747639,
          XOF: 613.499831,
          XPF: 111.649725,
          YER: 250.350068,
          ZAR: 18.15937,
          ZMK: 9001.1977,
          ZMW: 19.404424,
          ZWL: 321.999592,
        },
      },
      {
        base: "USD",
        date: "2023-02-15",
        rates: {
          AED: 3.673101,
          AFN: 89.626261,
          ALL: 108.409698,
          AMD: 396.416039,
          ANG: 1.806374,
          AOA: 507.511333,
          ARS: 192.387594,
          AUD: 1.447695,
          AWG: 1.8025,
          AZN: 1.702967,
          BAM: 1.830205,
          BBD: 2.023787,
          BDT: 106.496575,
          BGN: 1.829398,
          BHD: 0.377039,
          BIF: 2081.259884,
          BMD: 1,
          BND: 1.337457,
          BOB: 6.925999,
          BRL: 5.219499,
          BSD: 1.002302,
          BTC: 4.1115749e-5,
          BTN: 82.990624,
          BWP: 13.136481,
          BYN: 2.529945,
          BYR: 19600,
          BZD: 2.020418,
          CAD: 1.339099,
          CDF: 2047.00051,
          CHF: 0.92341,
          CLF: 0.028708,
          CLP: 792.129842,
          CNY: 6.85296,
          COP: 4823.94,
          CRC: 569.6854,
          CUC: 1,
          CUP: 26.5,
          CVE: 103.18255,
          CZK: 22.1079,
          DJF: 178.460473,
          DKK: 6.968535,
          DOP: 56.319129,
          DZD: 136.775105,
          EGP: 30.541595,
          ERN: 15,
          ETB: 53.862314,
          EUR: 0.93528,
          FJD: 2.189703,
          FKP: 0.822805,
          GBP: 0.83069,
          GEL: 2.649687,
          GGP: 0.822805,
          GHS: 12.403709,
          GIP: 0.822805,
          GMD: 61.104811,
          GNF: 8630.808676,
          GTQ: 7.848126,
          GYD: 211.493113,
          HKD: 7.84648,
          HNL: 24.650357,
          HRK: 7.054794,
          HTG: 150.589986,
          HUF: 355.398271,
          IDR: 15215,
          ILS: 3.52951,
          IMP: 0.822805,
          INR: 82.741104,
          IQD: 1460.5,
          IRR: 42250.000182,
          ISK: 143.940365,
          JEP: 0.822805,
          JMD: 154.730223,
          JOD: 0.709396,
          JPY: 133.919004,
          KES: 125.889637,
          KGS: 86.969701,
          KHR: 4095.370882,
          KMF: 460.949691,
          KPW: 899.926211,
          KRW: 1284.095025,
          KWD: 0.30639,
          KYD: 0.835259,
          KZT: 448.079352,
          LAK: 16925.000073,
          LBP: 15249.999645,
          LKR: 365.847152,
          LRD: 157.303383,
          LSL: 18.039901,
          LTL: 2.95274,
          LVL: 0.60489,
          LYD: 4.803956,
          MAD: 10.31199,
          MDL: 18.768435,
          MGA: 4301.922987,
          MKD: 57.657396,
          MMK: 2104.873671,
          MNT: 3515.924406,
          MOP: 8.102746,
          MRO: 356.999828,
          MUR: 45.747905,
          MVR: 15.359993,
          MWK: 1028.767803,
          MXN: 18.58499,
          MYR: 4.392497,
          MZN: 63.10203,
          NAD: 18.040128,
          NGN: 461.497745,
          NIO: 36.350346,
          NOK: 10.211365,
          NPR: 132.782176,
          NZD: 1.591279,
          OMR: 0.385008,
          PAB: 1.002302,
          PEN: 3.851503,
          PGK: 3.531713,
          PHP: 55.410035,
          PKR: 265.859367,
          PLN: 4.45563,
          PYG: 7295.628088,
          QAR: 3.641037,
          RON: 4.58208,
          RSD: 109.720345,
          RUB: 74.000135,
          RWF: 1088.391069,
          SAR: 3.75131,
          SBD: 0.122502,
          SCR: 13.434413,
          SDG: 584.000293,
          SEK: 10.41907,
          SGD: 1.335445,
          SHP: 1.21675,
          SLE: 19.857866,
          SLL: 19750.000396,
          SOS: 569.499,
          SRD: 32.539499,
          STD: 20697.981008,
          SVC: 8.769943,
          SYP: 2512.375463,
          SZL: 18.020334,
          THB: 34.281042,
          TJS: 10.464137,
          TMT: 3.51,
          TND: 3.120269,
          TOP: 2.331403,
          TRY: 18.85653,
          TTD: 6.801572,
          TWD: 30.316971,
          TZS: 2331.999507,
          UAH: 37.016544,
          UGX: 3678.507262,
          USD: 1,
          UYU: 39.125064,
          UZS: 11395.36233,
          VEF: 2419572.701445,
          VES: 24.168502,
          VND: 23625,
          VUV: 117.179337,
          WST: 2.683325,
          XAF: 613.812625,
          XAG: 0.046214,
          XAU: 0.000545,
          XCD: 2.70255,
          XDR: 0.747017,
          XOF: 613.824113,
          XPF: 111.649871,
          YER: 250.312686,
          ZAR: 18.035905,
          ZMK: 9001.202111,
          ZMW: 19.369853,
          ZWL: 321.999592,
        },
      },
      {
        base: "USD",
        date: "2023-02-14",
        rates: {
          AED: 3.672976,
          AFN: 90.000158,
          ALL: 108.224987,
          AMD: 394.104205,
          ANG: 1.801931,
          AOA: 504.368597,
          ARS: 191.977098,
          AUD: 1.431199,
          AWG: 1.8025,
          AZN: 1.700451,
          BAM: 1.818041,
          BBD: 2.01861,
          BDT: 107.126059,
          BGN: 1.822005,
          BHD: 0.377039,
          BIF: 2070,
          BMD: 1,
          BND: 1.327142,
          BOB: 6.908319,
          BRL: 5.192604,
          BSD: 0.99973,
          BTC: 4.5039164e-5,
          BTN: 82.720415,
          BWP: 13.05165,
          BYN: 2.523307,
          BYR: 19600,
          BZD: 2.01517,
          CAD: 1.334105,
          CDF: 2045.000079,
          CHF: 0.92161,
          CLF: 0.028534,
          CLP: 787.329671,
          CNY: 6.826897,
          COP: 4773.75,
          CRC: 571.803047,
          CUC: 1,
          CUP: 26.5,
          CVE: 103.224983,
          CZK: 22.079901,
          DJF: 177.719956,
          DKK: 6.941395,
          DOP: 56.203141,
          DZD: 136.519099,
          EGP: 30.595028,
          ERN: 15,
          ETB: 53.650353,
          EUR: 0.93153,
          FJD: 2.17775,
          FKP: 0.824141,
          GBP: 0.821475,
          GEL: 2.655004,
          GGP: 0.824141,
          GHS: 12.275028,
          GIP: 0.824141,
          GMD: 61.149903,
          GNF: 8825.000325,
          GTQ: 7.836038,
          GYD: 210.956854,
          HKD: 7.848865,
          HNL: 24.70163,
          HRK: 7.054794,
          HTG: 150.969793,
          HUF: 351.830049,
          IDR: 15190,
          ILS: 3.522885,
          IMP: 0.824141,
          INR: 82.86675,
          IQD: 1460.5,
          IRR: 42250.000498,
          ISK: 142.810107,
          JEP: 0.824141,
          JMD: 154.110445,
          JOD: 0.7094,
          JPY: 132.978988,
          KES: 125.506089,
          KGS: 86.969202,
          KHR: 4099.999388,
          KMF: 458.875026,
          KPW: 899.987863,
          KRW: 1272.605954,
          KWD: 0.30608,
          KYD: 0.833198,
          KZT: 449.424625,
          LAK: 16930.000219,
          LBP: 15250.000301,
          LKR: 364.930582,
          LRD: 157.199925,
          LSL: 17.909681,
          LTL: 2.95274,
          LVL: 0.60489,
          LYD: 4.795,
          MAD: 10.305008,
          MDL: 18.709393,
          MGA: 4311.999627,
          MKD: 57.270995,
          MMK: 2099.570718,
          MNT: 3515.111362,
          MOP: 8.083682,
          MRO: 356.999828,
          MUR: 45.853786,
          MVR: 15.35008,
          MWK: 1029.503104,
          MXN: 18.509503,
          MYR: 4.348502,
          MZN: 63.113126,
          NAD: 17.909759,
          NGN: 460.559834,
          NIO: 36.400406,
          NOK: 10.140303,
          NPR: 132.355087,
          NZD: 1.578715,
          OMR: 0.385049,
          PAB: 0.99973,
          PEN: 3.842503,
          PGK: 3.524996,
          PHP: 54.955008,
          PKR: 267.503214,
          PLN: 4.44795,
          PYG: 7273.192067,
          QAR: 3.640977,
          RON: 4.564897,
          RSD: 109.280509,
          RUB: 73.602481,
          RWF: 1088,
          SAR: 3.751941,
          SBD: 8.211126,
          SCR: 13.450442,
          SDG: 584.000164,
          SEK: 10.358801,
          SGD: 1.328535,
          SHP: 1.21675,
          SLE: 19.860767,
          SLL: 19750.000596,
          SOS: 569.497245,
          SRD: 32.476013,
          STD: 20697.981008,
          SVC: 8.74799,
          SYP: 2512.001842,
          SZL: 17.909647,
          THB: 33.890263,
          TJS: 10.42219,
          TMT: 3.5,
          TND: 3.12125,
          TOP: 2.32975,
          TRY: 18.847196,
          TTD: 6.788221,
          TWD: 30.179504,
          TZS: 2331.999844,
          UAH: 36.740531,
          UGX: 3662.145958,
          USD: 1,
          UYU: 39.152765,
          UZS: 11364.999864,
          VEF: 2414530.263689,
          VES: 24.168502,
          VND: 23610,
          VUV: 118.221997,
          WST: 2.694639,
          XAF: 609.755803,
          XAG: 0.045734,
          XAU: 0.000539,
          XCD: 2.70255,
          XDR: 0.747775,
          XOF: 610.502186,
          XPF: 111.650208,
          YER: 250.297576,
          ZAR: 17.887802,
          ZMK: 9001.204652,
          ZMW: 19.340299,
          ZWL: 321.999592,
        },
      },
      {
        base: "USD",
        date: "2023-02-13",
        rates: {
          AED: 3.6731,
          AFN: 89.999565,
          ALL: 108.762858,
          AMD: 394.69035,
          ANG: 1.802367,
          AOA: 504.318103,
          ARS: 191.648702,
          AUD: 1.435132,
          AWG: 1.8,
          AZN: 1.699774,
          BAM: 1.832202,
          BBD: 2.019307,
          BDT: 106.910012,
          BGN: 1.825197,
          BHD: 0.377116,
          BIF: 2070,
          BMD: 1,
          BND: 1.332759,
          BOB: 6.910571,
          BRL: 5.159898,
          BSD: 1.000098,
          BTC: 4.5897569e-5,
          BTN: 82.679301,
          BWP: 13.124895,
          BYN: 2.524837,
          BYR: 19600,
          BZD: 2.015907,
          CAD: 1.333455,
          CDF: 2044.999905,
          CHF: 0.918825,
          CLF: 0.028729,
          CLP: 792.729785,
          CNY: 6.822895,
          COP: 4814.8,
          CRC: 575.815343,
          CUC: 1,
          CUP: 26.5,
          CVE: 103.250316,
          CZK: 22.154014,
          DJF: 177.819808,
          DKK: 6.94367,
          DOP: 56.249979,
          DZD: 136.703491,
          EGP: 30.526102,
          ERN: 15,
          ETB: 53.872269,
          EUR: 0.93196,
          FJD: 2.17725,
          FKP: 0.828953,
          GBP: 0.822945,
          GEL: 2.65499,
          GGP: 0.828953,
          GHS: 12.275001,
          GIP: 0.828953,
          GMD: 61.050309,
          GNF: 8570.853047,
          GTQ: 7.840387,
          GYD: 211.024301,
          HKD: 7.84987,
          HNL: 24.572349,
          HRK: 7.054794,
          HTG: 149.817095,
          HUF: 356.815021,
          IDR: 15218,
          ILS: 3.52582,
          IMP: 0.828953,
          INR: 82.59765,
          IQD: 1459.613394,
          IRR: 42274.999735,
          ISK: 142.705954,
          JEP: 0.828953,
          JMD: 154.187139,
          JOD: 0.709403,
          JPY: 132.341499,
          KES: 125.260208,
          KGS: 86.770244,
          KHR: 4094.409263,
          KMF: 461.499323,
          KPW: 900,
          KRW: 1273.289752,
          KWD: 0.30602,
          KYD: 0.833454,
          KZT: 451.89488,
          LAK: 16854.007316,
          LBP: 15011.217851,
          LKR: 365.032577,
          LRD: 157.250297,
          LSL: 17.898099,
          LTL: 2.95274,
          LVL: 0.60489,
          LYD: 4.789339,
          MAD: 10.28455,
          MDL: 18.751821,
          MGA: 4320.307267,
          MKD: 57.457224,
          MMK: 2100.187075,
          MNT: 3509.718164,
          MOP: 8.086109,
          MRO: 356.999828,
          MUR: 45.850303,
          MVR: 15.359979,
          MWK: 1026.536453,
          MXN: 18.568103,
          MYR: 4.362503,
          MZN: 63.096843,
          NAD: 17.898083,
          NGN: 460.519815,
          NIO: 36.679079,
          NOK: 10.090875,
          NPR: 132.286636,
          NZD: 1.572165,
          OMR: 0.385018,
          PAB: 1.000098,
          PEN: 3.837146,
          PGK: 3.523874,
          PHP: 54.675022,
          PKR: 267.023799,
          PLN: 4.461863,
          PYG: 7282.844416,
          QAR: 3.64075,
          RON: 4.566202,
          RSD: 109.39466,
          RUB: 74.098985,
          RWF: 1085.355629,
          SAR: 3.752779,
          SBD: 8.211116,
          SCR: 14.165816,
          SDG: 584.503969,
          SEK: 10.372399,
          SGD: 1.328115,
          SHP: 1.377402,
          SLE: 19.735073,
          SLL: 19724.999603,
          SOS: 568.500748,
          SRD: 32.350375,
          STD: 20697.981008,
          SVC: 8.751493,
          SYP: 2512.529392,
          SZL: 18.000487,
          THB: 33.802337,
          TJS: 10.417201,
          TMT: 3.51,
          TND: 3.111495,
          TOP: 2.33685,
          TRY: 18.849335,
          TTD: 6.791039,
          TWD: 30.234985,
          TZS: 2332.000104,
          UAH: 36.755053,
          UGX: 3663.317642,
          USD: 1,
          UYU: 39.128419,
          UZS: 11339.59436,
          VEF: 2414324.95157,
          VES: 24.168502,
          VND: 23605,
          VUV: 116.118233,
          WST: 2.680979,
          XAF: 614.485382,
          XAG: 0.045427,
          XAU: 0.000539,
          XCD: 2.70255,
          XDR: 0.746424,
          XOF: 611.499323,
          XPF: 112.25038,
          YER: 250.350024,
          ZAR: 17.855699,
          ZMK: 9001.200876,
          ZMW: 19.277163,
          ZWL: 321.999592,
        },
      },
      {
        base: "USD",
        date: "2023-02-12",
        rates: {
          AED: 3.673015,
          AFN: 89.679981,
          ALL: 108.451296,
          AMD: 396.103714,
          ANG: 1.801542,
          AOA: 504.318103,
          ARS: 190.496802,
          AUD: 1.446455,
          AWG: 1.8,
          AZN: 1.700483,
          BAM: 1.827452,
          BBD: 2.018313,
          BDT: 104.640878,
          BGN: 1.831028,
          BHD: 0.375987,
          BIF: 2075.402943,
          BMD: 1,
          BND: 1.328288,
          BOB: 6.907452,
          BRL: 5.215142,
          BSD: 0.999673,
          BTC: 4.58763e-5,
          BTN: 82.482784,
          BWP: 13.06704,
          BYN: 2.523149,
          BYR: 19600,
          BZD: 2.01495,
          CAD: 1.335665,
          CDF: 2044.99992,
          CHF: 0.92384,
          CLF: 0.028953,
          CLP: 805.648774,
          CNY: 6.809399,
          COP: 4743.003971,
          CRC: 579.911236,
          CUC: 1,
          CUP: 26.5,
          CVE: 103.02733,
          CZK: 22.205991,
          DJF: 177.958421,
          DKK: 6.97537,
          DOP: 56.24854,
          DZD: 136.55021,
          EGP: 30.497284,
          ERN: 15,
          ETB: 53.696052,
          EUR: 0.936475,
          FJD: 2.18585,
          FKP: 0.829159,
          GBP: 0.829699,
          GEL: 2.665042,
          GGP: 0.829159,
          GHS: 12.195468,
          GIP: 0.829159,
          GMD: 61.049794,
          GNF: 8603.784163,
          GTQ: 7.837047,
          GYD: 210.922682,
          HKD: 7.849655,
          HNL: 24.64284,
          HRK: 7.042198,
          HTG: 151.443121,
          HUF: 362.535029,
          IDR: 15187.75,
          ILS: 3.538835,
          IMP: 0.829159,
          INR: 82.520499,
          IQD: 1458.976875,
          IRR: 42250.000338,
          ISK: 142.62981,
          JEP: 0.829159,
          JMD: 154.247139,
          JOD: 0.7094,
          JPY: 131.556499,
          KES: 125.003224,
          KGS: 86.765595,
          KHR: 4097.453866,
          KMF: 461.499549,
          KPW: 899.999999,
          KRW: 1267.969735,
          KWD: 0.30575,
          KYD: 0.832983,
          KZT: 450.735809,
          LAK: 16839.710348,
          LBP: 15006.400374,
          LKR: 364.863723,
          LRD: 157.250283,
          LSL: 17.902537,
          LTL: 2.952739,
          LVL: 0.60489,
          LYD: 4.786919,
          MAD: 10.249848,
          MDL: 18.772997,
          MGA: 4303.770147,
          MKD: 57.570661,
          MMK: 2099.215324,
          MNT: 3509.60834,
          MOP: 8.082411,
          MRO: 356.999828,
          MUR: 45.450202,
          MVR: 15.359668,
          MWK: 1026.051857,
          MXN: 18.682315,
          MYR: 4.332501,
          MZN: 63.094136,
          NAD: 17.897423,
          NGN: 459.489788,
          NIO: 36.526045,
          NOK: 10.145685,
          NPR: 131.972904,
          NZD: 1.58435,
          OMR: 0.38406,
          PAB: 0.99958,
          PEN: 3.857323,
          PGK: 3.523009,
          PHP: 54.474993,
          PKR: 269.274375,
          PLN: 4.468502,
          PYG: 7275.029199,
          QAR: 3.64075,
          RON: 4.58796,
          RSD: 109.588414,
          RUB: 73.109809,
          RWF: 1084.213969,
          SAR: 3.752599,
          SBD: 8.211116,
          SCR: 14.119131,
          SDG: 584.506879,
          SEK: 10.45375,
          SGD: 1.329295,
          SHP: 1.377398,
          SLE: 19.735077,
          SLL: 19724.999989,
          SOS: 568.497933,
          SRD: 32.346982,
          STD: 20697.981008,
          SVC: 8.746555,
          SYP: 2512.531387,
          SZL: 17.884606,
          THB: 33.548993,
          TJS: 10.381126,
          TMT: 3.51,
          TND: 3.131037,
          TOP: 2.33355,
          TRY: 18.835095,
          TTD: 6.789535,
          TWD: 30.195799,
          TZS: 2336.127073,
          UAH: 36.731231,
          UGX: 3663.629993,
          USD: 1,
          UYU: 39.089932,
          UZS: 11333.968699,
          VEF: 2414324.973292,
          VES: 24.168502,
          VND: 23575,
          VUV: 116.118528,
          WST: 2.680983,
          XAF: 612.900724,
          XAG: 0.045603,
          XAU: 0.000537,
          XCD: 2.70255,
          XDR: 0.744032,
          XOF: 612.900724,
          XPF: 112.249602,
          YER: 250.349792,
          ZAR: 17.924995,
          ZMK: 9001.197348,
          ZMW: 19.247839,
          ZWL: 321.999592,
        },
      },
      {
        base: "USD",
        date: "2023-02-11",
        rates: {
          AED: 3.673042,
          AFN: 89.679981,
          ALL: 108.451296,
          AMD: 396.103714,
          ANG: 1.801542,
          AOA: 504.318104,
          ARS: 190.066059,
          AUD: 1.445714,
          AWG: 1.8,
          AZN: 1.70397,
          BAM: 1.827452,
          BBD: 2.018313,
          BDT: 104.640878,
          BGN: 1.831028,
          BHD: 0.375987,
          BIF: 2075.402943,
          BMD: 1,
          BND: 1.328288,
          BOB: 6.907452,
          BRL: 5.215142,
          BSD: 0.999673,
          BTC: 4.5737723e-5,
          BTN: 82.482784,
          BWP: 13.06704,
          BYN: 2.523149,
          BYR: 19600,
          BZD: 2.01495,
          CAD: 1.33885,
          CDF: 2045.000362,
          CHF: 0.924983,
          CLF: 0.028953,
          CLP: 805.648774,
          CNY: 6.809404,
          COP: 4743.003971,
          CRC: 579.911236,
          CUC: 1,
          CUP: 26.5,
          CVE: 103.02733,
          CZK: 22.230394,
          DJF: 177.958421,
          DKK: 6.974704,
          DOP: 56.24854,
          DZD: 136.550393,
          EGP: 30.42747,
          ERN: 15,
          ETB: 53.696052,
          EUR: 0.93445,
          FJD: 2.18585,
          FKP: 0.829014,
          GBP: 0.828912,
          GEL: 2.66504,
          GGP: 0.829014,
          GHS: 12.195468,
          GIP: 0.829014,
          GMD: 61.05039,
          GNF: 8603.784163,
          GTQ: 7.837047,
          GYD: 210.922682,
          HKD: 7.84995,
          HNL: 24.64284,
          HRK: 7.042198,
          HTG: 151.443121,
          HUF: 362.490388,
          IDR: 15187.75,
          ILS: 3.53906,
          IMP: 0.829014,
          INR: 82.520504,
          IQD: 1458.976875,
          IRR: 42250.000352,
          ISK: 142.630386,
          JEP: 0.829014,
          JMD: 154.247139,
          JOD: 0.709404,
          JPY: 131.41504,
          KES: 125.003224,
          KGS: 86.765604,
          KHR: 4097.453866,
          KMF: 461.503796,
          KPW: 899.981277,
          KRW: 1267.970384,
          KWD: 0.30575,
          KYD: 0.832983,
          KZT: 450.735809,
          LAK: 16839.710348,
          LBP: 15006.400374,
          LKR: 364.863723,
          LRD: 157.250382,
          LSL: 17.903772,
          LTL: 2.95274,
          LVL: 0.60489,
          LYD: 4.786919,
          MAD: 10.249848,
          MDL: 18.772997,
          MGA: 4303.770147,
          MKD: 57.570661,
          MMK: 2099.215324,
          MNT: 3514.644789,
          MOP: 8.082411,
          MRO: 356.999828,
          MUR: 45.450379,
          MVR: 15.360378,
          MWK: 1026.051857,
          MXN: 18.662039,
          MYR: 4.332504,
          MZN: 63.103732,
          NAD: 17.903729,
          NGN: 459.490377,
          NIO: 36.526045,
          NOK: 10.212065,
          NPR: 131.972904,
          NZD: 1.585289,
          OMR: 0.38406,
          PAB: 0.99958,
          PEN: 3.857323,
          PGK: 3.523009,
          PHP: 54.475039,
          PKR: 269.274375,
          PLN: 4.466807,
          PYG: 7275.029199,
          QAR: 3.64075,
          RON: 4.587504,
          RSD: 109.588414,
          RUB: 73.110373,
          RWF: 1084.213969,
          SAR: 3.751334,
          SBD: 8.211116,
          SCR: 14.119131,
          SDG: 584.503678,
          SEK: 10.47844,
          SGD: 1.330304,
          SHP: 1.377404,
          SLE: 19.801907,
          SLL: 19725.000338,
          SOS: 568.503664,
          SRD: 32.347038,
          STD: 20697.981008,
          SVC: 8.746555,
          SYP: 2512.508979,
          SZL: 17.884606,
          THB: 33.713038,
          TJS: 10.381126,
          TMT: 3.51,
          TND: 3.131038,
          TOP: 2.33355,
          TRY: 18.824204,
          TTD: 6.789535,
          TWD: 30.195804,
          TZS: 2336.127073,
          UAH: 36.731231,
          UGX: 3663.629993,
          USD: 1,
          UYU: 39.089932,
          UZS: 11333.968699,
          VEF: 2414220.611274,
          VES: 24.168502,
          VND: 23575,
          VUV: 117.772667,
          WST: 2.687067,
          XAF: 612.900724,
          XAG: 0.045433,
          XAU: 0.000536,
          XCD: 2.70255,
          XDR: 0.744032,
          XOF: 612.900724,
          XPF: 112.250364,
          YER: 250.350363,
          ZAR: 17.899825,
          ZMK: 9001.203589,
          ZMW: 19.247839,
          ZWL: 321.999592,
        },
      },
      {
        base: "USD",
        date: "2023-02-10",
        rates: {
          AED: 3.673042,
          AFN: 90.000368,
          ALL: 108.603989,
          AMD: 395.240403,
          ANG: 1.802569,
          AOA: 504.318104,
          ARS: 190.066059,
          AUD: 1.447597,
          AWG: 1.8,
          AZN: 1.70397,
          BAM: 1.828271,
          BBD: 2.019361,
          BDT: 104.700553,
          BGN: 1.831804,
          BHD: 0.376057,
          BIF: 2075,
          BMD: 1,
          BND: 1.329089,
          BOB: 6.911391,
          BRL: 5.215604,
          BSD: 1.000192,
          BTC: 4.6195984e-5,
          BTN: 82.529437,
          BWP: 13.074431,
          BYN: 2.524281,
          BYR: 19600,
          BZD: 2.016071,
          CAD: 1.33875,
          CDF: 2045.000362,
          CHF: 0.925104,
          CLF: 0.028953,
          CLP: 798.903912,
          CNY: 6.809504,
          COP: 4798.97,
          CRC: 580.233814,
          CUC: 1,
          CUP: 26.5,
          CVE: 103.503897,
          CZK: 22.230394,
          DJF: 177.720394,
          DKK: 6.974904,
          DOP: 56.150393,
          DZD: 136.249708,
          EGP: 30.42747,
          ERN: 15,
          ETB: 53.603876,
          EUR: 0.93465,
          FJD: 2.18585,
          FKP: 0.822803,
          GBP: 0.829119,
          GEL: 2.66504,
          GGP: 0.822803,
          GHS: 12.22504,
          GIP: 0.822803,
          GMD: 61.05039,
          GNF: 8607.503851,
          GTQ: 7.841517,
          GYD: 211.044941,
          HKD: 7.84995,
          HNL: 24.63504,
          HRK: 7.042198,
          HTG: 151.525945,
          HUF: 362.470388,
          IDR: 15187.85,
          ILS: 3.53906,
          IMP: 0.822803,
          INR: 82.49495,
          IQD: 1459.856676,
          IRR: 42250.000352,
          ISK: 142.630386,
          JEP: 0.822803,
          JMD: 154.340154,
          JOD: 0.709404,
          JPY: 131.42504,
          KES: 125.303804,
          KGS: 86.765604,
          KHR: 4100.000351,
          KMF: 461.503796,
          KPW: 900.017106,
          KRW: 1267.965039,
          KWD: 0.30575,
          KYD: 0.833454,
          KZT: 450.927514,
          LAK: 16930.000349,
          LBP: 15267.175573,
          LKR: 365.073506,
          LRD: 157.250382,
          LSL: 17.903772,
          LTL: 2.95274,
          LVL: 0.60489,
          LYD: 4.789089,
          MAD: 10.255598,
          MDL: 18.783527,
          MGA: 4310.000347,
          MKD: 57.595147,
          MMK: 2100.422294,
          MNT: 3509.038884,
          MOP: 8.087058,
          MRO: 356.999828,
          MUR: 45.440379,
          MVR: 15.360378,
          MWK: 1026.613005,
          MXN: 18.662039,
          MYR: 4.332504,
          MZN: 63.103732,
          NAD: 17.903729,
          NGN: 459.490377,
          NIO: 36.375039,
          NOK: 10.24482,
          NPR: 132.048783,
          NZD: 1.585289,
          OMR: 0.384132,
          PAB: 1.000136,
          PEN: 3.823039,
          PGK: 3.520375,
          PHP: 54.528504,
          PKR: 271.503704,
          PLN: 4.466894,
          PYG: 7279.178041,
          QAR: 3.64075,
          RON: 4.587504,
          RSD: 109.608897,
          RUB: 73.804038,
          RWF: 1086.5,
          SAR: 3.752334,
          SBD: 8.211116,
          SCR: 14.12177,
          SDG: 584.503678,
          SEK: 10.55767,
          SGD: 1.330604,
          SHP: 1.377404,
          SLE: 19.709554,
          SLL: 19725.000338,
          SOS: 568.503664,
          SRD: 32.347038,
          STD: 20697.981008,
          SVC: 8.75142,
          SYP: 2512.482517,
          SZL: 17.903653,
          THB: 33.630369,
          TJS: 10.385541,
          TMT: 3.51,
          TND: 3.131038,
          TOP: 2.33355,
          TRY: 18.815304,
          TTD: 6.79328,
          TWD: 30.195704,
          TZS: 2339.000335,
          UAH: 36.751835,
          UGX: 3665.410898,
          USD: 1,
          UYU: 39.112225,
          UZS: 11340.000334,
          VEF: 2377964.005354,
          VES: 24.168502,
          VND: 23575,
          VUV: 117.001258,
          WST: 2.689543,
          XAF: 613.270319,
          XAG: 0.045433,
          XAU: 0.000536,
          XCD: 2.70255,
          XDR: 0.74448,
          XOF: 611.503597,
          XPF: 112.250364,
          YER: 250.350363,
          ZAR: 17.778225,
          ZMK: 9001.203589,
          ZMW: 19.257196,
          ZWL: 321.999592,
        },
      },
      {
        base: "USD",
        date: "2023-02-09",
        rates: {
          AED: 3.673101,
          AFN: 90.999908,
          ALL: 107.750469,
          AMD: 395.920201,
          ANG: 1.80073,
          AOA: 504.3181,
          ARS: 190.191201,
          AUD: 1.441125,
          AWG: 1.8025,
          AZN: 1.70203,
          BAM: 1.81561,
          BBD: 2.017396,
          BDT: 104.591127,
          BGN: 1.819387,
          BHD: 0.377053,
          BIF: 2076,
          BMD: 1,
          BND: 1.321538,
          BOB: 6.904097,
          BRL: 5.291007,
          BSD: 0.999137,
          BTC: 4.5897125e-5,
          BTN: 82.428822,
          BWP: 12.975966,
          BYN: 2.521931,
          BYR: 19600,
          BZD: 2.013962,
          CAD: 1.344935,
          CDF: 2045.999935,
          CHF: 0.922098,
          CLF: 0.029004,
          CLP: 800.298055,
          CNY: 6.780698,
          COP: 4750.88,
          CRC: 581.779193,
          CUC: 1,
          CUP: 26.5,
          CVE: 102.949726,
          CZK: 22.074004,
          DJF: 177.719648,
          DKK: 6.930301,
          DOP: 56.250191,
          DZD: 136.184122,
          EGP: 30.495803,
          ERN: 15,
          ETB: 53.604985,
          EUR: 0.93105,
          FJD: 2.182011,
          FKP: 0.827018,
          GBP: 0.82535,
          GEL: 2.66976,
          GGP: 0.827018,
          GHS: 12.17496,
          GIP: 0.827018,
          GMD: 61.093505,
          GNF: 8812.522666,
          GTQ: 7.837324,
          GYD: 210.818488,
          HKD: 7.84981,
          HNL: 24.620226,
          HRK: 7.042198,
          HTG: 150.745895,
          HUF: 361.020219,
          IDR: 15145,
          ILS: 3.49522,
          IMP: 0.827018,
          INR: 82.53345,
          IQD: 1458.76,
          IRR: 42274.999824,
          ISK: 140.690049,
          JEP: 0.827018,
          JMD: 154.228902,
          JOD: 0.709403,
          JPY: 131.520168,
          KES: 125.208892,
          KGS: 86.77046,
          KHR: 4099.999878,
          KMF: 458.650538,
          KPW: 899.950894,
          KRW: 1264.899831,
          KWD: 0.30579,
          KYD: 0.832598,
          KZT: 451.775189,
          LAK: 16934.999966,
          LBP: 15250.000185,
          LKR: 365.1897,
          LRD: 157.202105,
          LSL: 17.750123,
          LTL: 2.95274,
          LVL: 0.60489,
          LYD: 4.785608,
          MAD: 10.240497,
          MDL: 18.789024,
          MGA: 4314.999802,
          MKD: 57.365106,
          MMK: 2098.216444,
          MNT: 3506.920215,
          MOP: 8.078573,
          MRO: 356.999828,
          MUR: 45.35084,
          MVR: 15.360003,
          MWK: 1025.53308,
          MXN: 18.783698,
          MYR: 4.314498,
          MZN: 63.099594,
          NAD: 17.749692,
          NGN: 460.469715,
          NIO: 36.450512,
          NOK: 10.179765,
          NPR: 131.886412,
          NZD: 1.580585,
          OMR: 0.385033,
          PAB: 0.999137,
          PEN: 3.857506,
          PGK: 3.525025,
          PHP: 54.639985,
          PKR: 269.549926,
          PLN: 4.42795,
          PYG: 7264.094019,
          QAR: 3.64125,
          RON: 4.554901,
          RSD: 109.214993,
          RUB: 73.039992,
          RWF: 1078,
          SAR: 3.752547,
          SBD: 8.231707,
          SCR: 13.105076,
          SDG: 584.999634,
          SEK: 10.343897,
          SGD: 1.325445,
          SHP: 1.377401,
          SLE: 19.68503,
          SLL: 19705.000069,
          SOS: 568.50087,
          SRD: 32.242948,
          STD: 20697.981008,
          SVC: 8.742794,
          SYP: 2512.53544,
          SZL: 17.749785,
          THB: 33.569841,
          TJS: 10.365062,
          TMT: 3.51,
          TND: 3.102499,
          TOP: 2.197695,
          TRY: 18.834695,
          TTD: 6.781931,
          TWD: 30.0105,
          TZS: 2332.000128,
          UAH: 36.720199,
          UGX: 3663.841519,
          USD: 1,
          UYU: 39.062224,
          UZS: 11365.000238,
          VEF: 2355692.483086,
          VES: 23.779763,
          VND: 23570,
          VUV: 117.34531,
          WST: 2.689245,
          XAF: 608.915252,
          XAG: 0.045582,
          XAU: 0.000537,
          XCD: 2.70255,
          XDR: 0.744578,
          XOF: 610.510656,
          XPF: 111.37504,
          YER: 250.325013,
          ZAR: 17.755599,
          ZMK: 9001.20111,
          ZMW: 19.22867,
          ZWL: 321.999592,
        },
      },
      {
        base: "USD",
        date: "2023-02-08",
        rates: {
          AED: 3.672974,
          AFN: 91.000141,
          ALL: 108.050199,
          AMD: 395.897342,
          ANG: 1.80149,
          AOA: 504.318101,
          ARS: 189.831696,
          AUD: 1.443262,
          AWG: 1.8,
          AZN: 1.710014,
          BAM: 1.821071,
          BBD: 2.018138,
          BDT: 106.403907,
          BGN: 1.825201,
          BHD: 0.377024,
          BIF: 2070,
          BMD: 1,
          BND: 1.323389,
          BOB: 6.906826,
          BRL: 5.201008,
          BSD: 0.999525,
          BTC: 4.3599437e-5,
          BTN: 82.442772,
          BWP: 12.930819,
          BYN: 2.523464,
          BYR: 19600,
          BZD: 2.014804,
          CAD: 1.344425,
          CDF: 2047.999664,
          CHF: 0.920799,
          CLF: 0.029024,
          CLP: 800.850232,
          CNY: 6.789198,
          COP: 4770.28,
          CRC: 578.803045,
          CUC: 1,
          CUP: 26.5,
          CVE: 103.150032,
          CZK: 22.18525,
          DJF: 177.719777,
          DKK: 6.942605,
          DOP: 56.300559,
          DZD: 136.508188,
          EGP: 30.367733,
          ERN: 15,
          ETB: 53.625,
          EUR: 0.93315,
          FJD: 2.18445,
          FKP: 0.832398,
          GBP: 0.828445,
          GEL: 2.644975,
          GGP: 0.832398,
          GHS: 12.174984,
          GIP: 0.832398,
          GMD: 61.050156,
          GNF: 8799.99983,
          GTQ: 7.839479,
          GYD: 210.903166,
          HKD: 7.84895,
          HNL: 24.620582,
          HRK: 7.042198,
          HTG: 151.43062,
          HUF: 361.019606,
          IDR: 15130,
          ILS: 3.48855,
          IMP: 0.832398,
          INR: 82.65975,
          IQD: 1460.5,
          IRR: 42250.000012,
          ISK: 141.203582,
          JEP: 0.832398,
          JMD: 154.40346,
          JOD: 0.709404,
          JPY: 131.378504,
          KES: 125.041137,
          KGS: 86.770006,
          KHR: 4101.000368,
          KMF: 458.675022,
          KPW: 900.14775,
          KRW: 1260.844961,
          KWD: 0.30573,
          KYD: 0.832961,
          KZT: 454.8561,
          LAK: 16925.000105,
          LBP: 15265.000513,
          LKR: 365.341881,
          LRD: 157.210149,
          LSL: 17.7599,
          LTL: 2.95274,
          LVL: 0.60489,
          LYD: 4.790239,
          MAD: 10.249685,
          MDL: 18.826454,
          MGA: 4305.000178,
          MKD: 57.33708,
          MMK: 2099.090956,
          MNT: 3504.619785,
          MOP: 8.081305,
          MRO: 356.999828,
          MUR: 45.349971,
          MVR: 15.359768,
          MWK: 1021.999577,
          MXN: 18.93702,
          MYR: 4.305488,
          MZN: 63.830082,
          NAD: 17.759888,
          NGN: 460.420267,
          NIO: 36.374987,
          NOK: 10.313535,
          NPR: 131.908732,
          NZD: 1.5838,
          OMR: 0.385003,
          PAB: 0.999525,
          PEN: 3.8575,
          PGK: 3.519729,
          PHP: 54.816981,
          PKR: 274.502678,
          PLN: 4.422296,
          PYG: 7286.133222,
          QAR: 3.641503,
          RON: 4.5691,
          RSD: 109.519716,
          RUB: 73.256003,
          RWF: 1076.5,
          SAR: 3.752095,
          SBD: 8.23101,
          SCR: 13.277633,
          SDG: 584.493309,
          SEK: 10.587396,
          SGD: 1.325903,
          SHP: 1.377403,
          SLE: 19.603766,
          SLL: 19674.999931,
          SOS: 569.502819,
          SRD: 32.170303,
          STD: 20697.981008,
          SVC: 8.74581,
          SYP: 2512.248572,
          SZL: 17.759773,
          THB: 33.484995,
          TJS: 10.338172,
          TMT: 3.51,
          TND: 3.105028,
          TOP: 2.33685,
          TRY: 18.836899,
          TTD: 6.788391,
          TWD: 30.056503,
          TZS: 2331.999719,
          UAH: 36.734667,
          UGX: 3668.308489,
          USD: 1,
          UYU: 39.257532,
          UZS: 11374.999769,
          VEF: 2311740.706989,
          VES: 23.58318,
          VND: 23595,
          VUV: 117.316068,
          WST: 2.69643,
          XAF: 610.760708,
          XAG: 0.044843,
          XAU: 0.000533,
          XCD: 2.70255,
          XDR: 0.746555,
          XOF: 609.502175,
          XPF: 111.597386,
          YER: 250.325008,
          ZAR: 17.750925,
          ZMK: 9001.202105,
          ZMW: 19.216208,
          ZWL: 321.999592,
        },
      },
      {
        base: "USD",
        date: "2023-02-07",
        rates: {
          AED: 3.6731,
          AFN: 90.999837,
          ALL: 108.549927,
          AMD: 395.880194,
          ANG: 1.801771,
          AOA: 504.317603,
          ARS: 189.516803,
          AUD: 1.435864,
          AWG: 1.8025,
          AZN: 1.698872,
          BAM: 1.826615,
          BBD: 2.018641,
          BDT: 106.687648,
          BGN: 1.824301,
          BHD: 0.377068,
          BIF: 2066,
          BMD: 1,
          BND: 1.325949,
          BOB: 6.908235,
          BRL: 5.210801,
          BSD: 0.999748,
          BTC: 4.3012468e-5,
          BTN: 82.670857,
          BWP: 12.983834,
          BYN: 2.523582,
          BYR: 19600,
          BZD: 2.015167,
          CAD: 1.339125,
          CDF: 2045.999866,
          CHF: 0.92173,
          CLF: 0.028827,
          CLP: 795.420315,
          CNY: 6.792699,
          COP: 4747.53,
          CRC: 573.008209,
          CUC: 1,
          CUP: 26.5,
          CVE: 103.450514,
          CZK: 22.178998,
          DJF: 177.72009,
          DKK: 6.93496,
          DOP: 56.250165,
          DZD: 136.693545,
          EGP: 30.293101,
          ERN: 15,
          ETB: 53.494813,
          EUR: 0.93202,
          FJD: 2.20175,
          FKP: 0.832848,
          GBP: 0.82963,
          GEL: 2.649803,
          GGP: 0.832848,
          GHS: 12.21069,
          GIP: 0.832848,
          GMD: 61.039445,
          GNF: 8804.999818,
          GTQ: 7.838025,
          GYD: 210.950998,
          HKD: 7.84875,
          HNL: 24.620158,
          HRK: 7.042198,
          HTG: 150.530454,
          HUF: 365.760663,
          IDR: 15154,
          ILS: 3.479015,
          IMP: 0.832848,
          INR: 82.74215,
          IQD: 1461,
          IRR: 42200.000202,
          ISK: 141.769903,
          JEP: 0.832848,
          JMD: 154.461742,
          JOD: 0.709396,
          JPY: 131.046501,
          KES: 125.030012,
          KGS: 86.763603,
          KHR: 4100.000442,
          KMF: 458.375027,
          KPW: 900.014433,
          KRW: 1255.697874,
          KWD: 0.30556,
          KYD: 0.833139,
          KZT: 456.156899,
          LAK: 16935.00029,
          LBP: 15169.402367,
          LKR: 365.409658,
          LRD: 157.101579,
          LSL: 17.589894,
          LTL: 2.95274,
          LVL: 0.60489,
          LYD: 4.804965,
          MAD: 10.24875,
          MDL: 18.815163,
          MGA: 4299.999815,
          MKD: 57.423113,
          MMK: 2099.480467,
          MNT: 3508.016815,
          MOP: 8.081697,
          MRO: 356.999828,
          MUR: 45.555814,
          MVR: 15.350637,
          MWK: 1024.99995,
          MXN: 18.885403,
          MYR: 4.302979,
          MZN: 63.830098,
          NAD: 17.590009,
          NGN: 460.419983,
          NIO: 36.436536,
          NOK: 10.318755,
          NPR: 132.274905,
          NZD: 1.58197,
          OMR: 0.385038,
          PAB: 0.999748,
          PEN: 3.830542,
          PGK: 3.524964,
          PHP: 55.075016,
          PKR: 276.498611,
          PLN: 4.42675,
          PYG: 7299.420044,
          QAR: 3.641025,
          RON: 4.560601,
          RSD: 109.379885,
          RUB: 72.13497,
          RWF: 1076.5,
          SAR: 3.751563,
          SBD: 8.197272,
          SCR: 13.478403,
          SDG: 585.516238,
          SEK: 10.57341,
          SGD: 1.323704,
          SHP: 1.377399,
          SLE: 19.598561,
          SLL: 19624.999913,
          SOS: 568.500197,
          SRD: 32.246501,
          STD: 20697.981008,
          SVC: 8.747829,
          SYP: 2512.521928,
          SZL: 17.60318,
          THB: 33.511501,
          TJS: 10.317339,
          TMT: 3.51,
          TND: 3.106502,
          TOP: 2.339601,
          TRY: 18.836601,
          TTD: 6.7844,
          TWD: 30.042103,
          TZS: 2331.999621,
          UAH: 36.742001,
          UGX: 3674.050692,
          USD: 1,
          UYU: 39.125116,
          UZS: 11350.000165,
          VEF: 2276605.839142,
          VES: 23.119789,
          VND: 23610,
          VUV: 117.23598,
          WST: 2.692531,
          XAF: 612.580196,
          XAG: 0.045,
          XAU: 0.000534,
          XCD: 2.70255,
          XDR: 0.745137,
          XOF: 610.502255,
          XPF: 112.049814,
          YER: 250.124997,
          ZAR: 17.528698,
          ZMK: 9001.19611,
          ZMW: 19.170044,
          ZWL: 321.999592,
        },
      },
      {
        base: "USD",
        date: "2023-02-06",
        rates: {
          AED: 3.6731,
          AFN: 90.503747,
          ALL: 108.250117,
          AMD: 396.62015,
          ANG: 1.801578,
          AOA: 503.734897,
          ARS: 189.118796,
          AUD: 1.451863,
          AWG: 1.8,
          AZN: 1.701015,
          BAM: 1.815998,
          BBD: 2.018384,
          BDT: 106.674373,
          BGN: 1.823501,
          BHD: 0.377045,
          BIF: 2068,
          BMD: 1,
          BND: 1.32442,
          BOB: 6.907614,
          BRL: 5.147203,
          BSD: 0.999629,
          BTC: 4.3954716e-5,
          BTN: 82.662965,
          BWP: 12.965706,
          BYN: 2.523213,
          BYR: 19600,
          BZD: 2.015042,
          CAD: 1.34435,
          CDF: 2044.999993,
          CHF: 0.92792,
          CLF: 0.029152,
          CLP: 804.39942,
          CNY: 6.795025,
          COP: 4741.07,
          CRC: 566.861653,
          CUC: 1,
          CUP: 26.5,
          CVE: 103.306089,
          CZK: 22.2475,
          DJF: 177.720333,
          DKK: 6.936196,
          DOP: 56.45048,
          DZD: 136.366973,
          EGP: 30.193403,
          ERN: 15,
          ETB: 53.519954,
          EUR: 0.932023,
          FJD: 2.18875,
          FKP: 0.829513,
          GBP: 0.83154,
          GEL: 2.639761,
          GGP: 0.829513,
          GHS: 12.302943,
          GIP: 0.829513,
          GMD: 61.000313,
          GNF: 8815.000123,
          GTQ: 7.84737,
          GYD: 210.931443,
          HKD: 7.846885,
          HNL: 24.620003,
          HRK: 7.042198,
          HTG: 150.188487,
          HUF: 367.93504,
          IDR: 15207,
          ILS: 3.474755,
          IMP: 0.829513,
          INR: 82.75525,
          IQD: 1460,
          IRR: 42224.999672,
          ISK: 142.52015,
          JEP: 0.829513,
          JMD: 153.921077,
          JOD: 0.709402,
          JPY: 132.626495,
          KES: 124.903848,
          KGS: 86.2502,
          KHR: 4099.999782,
          KMF: 459.150298,
          KPW: 900,
          KRW: 1261.555011,
          KWD: 0.30575,
          KYD: 0.833055,
          KZT: 457.24234,
          LAK: 16920.999742,
          LBP: 15453.969083,
          LKR: 365.122934,
          LRD: 157.100709,
          LSL: 17.0503,
          LTL: 2.95274,
          LVL: 0.60489,
          LYD: 4.780156,
          MAD: 10.272502,
          MDL: 18.748729,
          MGA: 4294.999818,
          MKD: 57.441156,
          MMK: 2099.239751,
          MNT: 3498.700561,
          MOP: 8.079387,
          MRO: 356.999828,
          MUR: 45.350118,
          MVR: 15.355019,
          MWK: 1023.000232,
          MXN: 19.171497,
          MYR: 4.264964,
          MZN: 63.829822,
          NAD: 17.05018,
          NGN: 459.701894,
          NIO: 36.402218,
          NOK: 10.340545,
          NPR: 132.258139,
          NZD: 1.585276,
          OMR: 0.384964,
          PAB: 0.999629,
          PEN: 3.89425,
          PGK: 3.520264,
          PHP: 54.839645,
          PKR: 275.175013,
          PLN: 4.42115,
          PYG: 7322.562674,
          QAR: 3.640971,
          RON: 4.564403,
          RSD: 109.414986,
          RUB: 71.509912,
          RWF: 1092,
          SAR: 3.75158,
          SBD: 8.23101,
          SCR: 13.245397,
          SDG: 584.999633,
          SEK: 10.61584,
          SGD: 1.327701,
          SHP: 1.377404,
          SLE: 19.595085,
          SLL: 19594.999652,
          SOS: 568.500523,
          SRD: 32.307502,
          STD: 20697.981008,
          SVC: 8.74664,
          SYP: 2512.05683,
          SZL: 17.049922,
          THB: 33.759743,
          TJS: 10.296379,
          TMT: 3.5,
          TND: 3.100956,
          TOP: 2.334099,
          TRY: 18.835655,
          TTD: 6.784865,
          TWD: 30.013301,
          TZS: 2332.000028,
          UAH: 36.739137,
          UGX: 3673.740361,
          USD: 1,
          UYU: 38.571959,
          UZS: 11344.999658,
          VEF: 2262965.946128,
          VES: 23.119764,
          VND: 23482.5,
          VUV: 114.532742,
          WST: 2.602806,
          XAF: 609.059424,
          XAG: 0.044885,
          XAU: 0.000535,
          XCD: 2.70255,
          XDR: 0.738533,
          XOF: 607.49739,
          XPF: 111.501068,
          YER: 250.374998,
          ZAR: 17.669501,
          ZMK: 9001.162788,
          ZMW: 19.118384,
          ZWL: 321.999592,
        },
      },
      {
        base: "USD",
        date: "2023-02-05",
        rates: {
          AED: 3.67301,
          AFN: 90.511151,
          ALL: 107.290945,
          AMD: 399.879946,
          ANG: 1.818996,
          AOA: 504.408049,
          ARS: 188.103229,
          AUD: 1.445005,
          AWG: 1.8025,
          AZN: 1.697588,
          BAM: 1.806187,
          BBD: 2.037863,
          BDT: 108.34834,
          BGN: 1.809272,
          BHD: 0.375768,
          BIF: 2094.546798,
          BMD: 1,
          BND: 1.322806,
          BOB: 6.974281,
          BRL: 5.1518,
          BSD: 1.009281,
          BTC: 4.3605313e-5,
          BTN: 82.589463,
          BWP: 12.857367,
          BYN: 2.548091,
          BYR: 19600,
          BZD: 2.034446,
          CAD: 1.340035,
          CDF: 2045.000212,
          CHF: 0.92641,
          CLF: 0.028879,
          CLP: 790.836681,
          CNY: 6.775304,
          COP: 4629.63476,
          CRC: 565.609272,
          CUC: 1,
          CUP: 26.5,
          CVE: 101.828508,
          CZK: 22.028005,
          DJF: 179.683243,
          DKK: 6.90015,
          DOP: 57.156578,
          DZD: 135.521023,
          EGP: 30.279102,
          ERN: 15,
          ETB: 54.182943,
          EUR: 0.92674,
          FJD: 2.17399,
          FKP: 0.829848,
          GBP: 0.830275,
          GEL: 2.629889,
          GGP: 0.829848,
          GHS: 12.363947,
          GIP: 0.829848,
          GMD: 61.000122,
          GNF: 8689.107448,
          GTQ: 7.922981,
          GYD: 212.965785,
          HKD: 7.846455,
          HNL: 24.882486,
          HRK: 7.042198,
          HTG: 151.360761,
          HUF: 359.710483,
          IDR: 15095.1,
          ILS: 3.41825,
          IMP: 0.829848,
          INR: 82.233495,
          IQD: 1473.07568,
          IRR: 42200.00023,
          ISK: 142.206766,
          JEP: 0.829848,
          JMD: 155.936649,
          JOD: 0.709398,
          JPY: 132.015964,
          KES: 125.809207,
          KGS: 86.250203,
          KHR: 4148.219975,
          KMF: 451.294926,
          KPW: 900.000011,
          KRW: 1247.850313,
          KWD: 0.30505,
          KYD: 0.841114,
          KZT: 462.307799,
          LAK: 16997.645103,
          LBP: 15149.559034,
          LKR: 368.394422,
          LRD: 156.949864,
          LSL: 17.089665,
          LTL: 2.95274,
          LVL: 0.60489,
          LYD: 4.775269,
          MAD: 10.257192,
          MDL: 18.944544,
          MGA: 4310.421573,
          MKD: 56.900771,
          MMK: 2119.529205,
          MNT: 3499.328985,
          MOP: 8.15607,
          MRO: 356.999828,
          MUR: 45.260193,
          MVR: 15.350044,
          MWK: 1035.950501,
          MXN: 18.995525,
          MYR: 4.258023,
          MZN: 63.829747,
          NAD: 17.0902,
          NGN: 460.540138,
          NIO: 36.879531,
          NOK: 10.228897,
          NPR: 132.142956,
          NZD: 1.580535,
          OMR: 0.38496,
          PAB: 1.009281,
          PEN: 3.858614,
          PGK: 3.557095,
          PHP: 53.665031,
          PKR: 277.809669,
          PLN: 4.368798,
          PYG: 7375.998522,
          QAR: 3.64099,
          RON: 4.53854,
          RSD: 108.389897,
          RUB: 70.649759,
          RWF: 1092.690585,
          SAR: 3.752698,
          SBD: 8.197272,
          SCR: 13.31394,
          SDG: 584.497745,
          SEK: 10.532665,
          SGD: 1.324875,
          SHP: 1.377402,
          SLE: 19.595044,
          SLL: 19449.999804,
          SOS: 568.500773,
          SRD: 32.284022,
          STD: 20697.981008,
          SVC: 8.831325,
          SYP: 2511.789689,
          SZL: 17.30646,
          THB: 33.545502,
          TJS: 10.410953,
          TMT: 3.51,
          TND: 3.040504,
          TOP: 2.31195,
          TRY: 18.836995,
          TTD: 6.850349,
          TWD: 29.980804,
          TZS: 2360.75172,
          UAH: 37.277647,
          UGX: 3718.243524,
          USD: 1,
          UYU: 39.074664,
          UZS: 11422.302258,
          VEF: 2266303.561038,
          VES: 22.652449,
          VND: 23450,
          VUV: 114.532238,
          WST: 2.602806,
          XAF: 605.769035,
          XAG: 0.044877,
          XAU: 0.000536,
          XCD: 2.70255,
          XDR: 0.743593,
          XOF: 605.769035,
          XPF: 109.629542,
          YER: 250.400028,
          ZAR: 17.467102,
          ZMK: 9001.197197,
          ZMW: 19.282634,
          ZWL: 321.999592,
        },
      },
      {
        base: "USD",
        date: "2023-02-04",
        rates: {
          AED: 3.673104,
          AFN: 90.511151,
          ALL: 107.290945,
          AMD: 399.879946,
          ANG: 1.818996,
          AOA: 504.408041,
          ARS: 187.42836,
          AUD: 1.444669,
          AWG: 1.8025,
          AZN: 1.70397,
          BAM: 1.806187,
          BBD: 2.037863,
          BDT: 108.34834,
          BGN: 1.809272,
          BHD: 0.375768,
          BIF: 2094.546798,
          BMD: 1,
          BND: 1.322806,
          BOB: 6.974281,
          BRL: 5.151804,
          BSD: 1.009281,
          BTC: 4.2864741e-5,
          BTN: 82.589463,
          BWP: 12.857367,
          BYN: 2.548091,
          BYR: 19600,
          BZD: 2.034446,
          CAD: 1.34105,
          CDF: 2045.000362,
          CHF: 0.926044,
          CLF: 0.028879,
          CLP: 790.836681,
          CNY: 6.775304,
          COP: 4629.63476,
          CRC: 565.609272,
          CUC: 1,
          CUP: 26.5,
          CVE: 101.828508,
          CZK: 22.021104,
          DJF: 179.683243,
          DKK: 6.895104,
          DOP: 57.156578,
          DZD: 135.52104,
          EGP: 30.171307,
          ERN: 15,
          ETB: 54.182943,
          EUR: 0.92355,
          FJD: 2.17404,
          FKP: 0.828213,
          GBP: 0.829566,
          GEL: 2.630391,
          GGP: 0.828213,
          GHS: 12.363947,
          GIP: 0.828213,
          GMD: 61.000356,
          GNF: 8689.107448,
          GTQ: 7.922981,
          GYD: 212.965785,
          HKD: 7.84785,
          HNL: 24.882486,
          HRK: 7.042198,
          HTG: 151.360761,
          HUF: 359.660388,
          IDR: 15095.1,
          ILS: 3.41815,
          IMP: 0.828213,
          INR: 82.233504,
          IQD: 1473.07568,
          IRR: 42200.000352,
          ISK: 142.203816,
          JEP: 0.828213,
          JMD: 155.936649,
          JOD: 0.709404,
          JPY: 131.180385,
          KES: 125.809207,
          KGS: 86.250385,
          KHR: 4148.219975,
          KMF: 451.303796,
          KPW: 899.984959,
          KRW: 1247.850384,
          KWD: 0.30505,
          KYD: 0.841114,
          KZT: 462.307799,
          LAK: 16997.645103,
          LBP: 15149.559034,
          LKR: 368.394422,
          LRD: 156.950382,
          LSL: 17.090382,
          LTL: 2.95274,
          LVL: 0.60489,
          LYD: 4.775269,
          MAD: 10.257192,
          MDL: 18.944544,
          MGA: 4310.421573,
          MKD: 56.900771,
          MMK: 2119.529205,
          MNT: 3502.812796,
          MOP: 8.15607,
          MRO: 356.999828,
          MUR: 45.260193,
          MVR: 15.350378,
          MWK: 1035.950501,
          MXN: 18.967604,
          MYR: 4.258039,
          MZN: 63.830377,
          NAD: 17.090377,
          NGN: 460.540377,
          NIO: 36.879531,
          NOK: 10.14823,
          NPR: 132.142956,
          NZD: 1.579155,
          OMR: 0.38496,
          PAB: 1.009281,
          PEN: 3.858614,
          PGK: 3.557095,
          PHP: 53.665039,
          PKR: 277.809669,
          PLN: 4.369542,
          PYG: 7375.998522,
          QAR: 3.641038,
          RON: 4.530704,
          RSD: 108.389897,
          RUB: 70.650373,
          RWF: 1092.690585,
          SAR: 3.752101,
          SBD: 8.197272,
          SCR: 13.31394,
          SDG: 584.503678,
          SEK: 10.54809,
          SGD: 1.323404,
          SHP: 1.377404,
          SLE: 19.597683,
          SLL: 19450.000338,
          SOS: 568.503664,
          SRD: 32.284038,
          STD: 20697.981008,
          SVC: 8.831325,
          SYP: 2512.053968,
          SZL: 17.30646,
          THB: 33.383038,
          TJS: 10.410953,
          TMT: 3.51,
          TND: 3.040504,
          TOP: 2.31195,
          TRY: 18.823604,
          TTD: 6.850349,
          TWD: 29.980804,
          TZS: 2360.75172,
          UAH: 37.277647,
          UGX: 3718.243524,
          USD: 1,
          UYU: 39.074664,
          UZS: 11422.302258,
          VEF: 2265162.321082,
          VES: 22.652449,
          VND: 23450,
          VUV: 115.408142,
          WST: 2.654398,
          XAF: 605.769035,
          XAG: 0.044752,
          XAU: 0.000536,
          XCD: 2.70255,
          XDR: 0.743593,
          XOF: 605.769035,
          XPF: 109.630364,
          YER: 250.403593,
          ZAR: 17.46777,
          ZMK: 9001.203589,
          ZMW: 19.282634,
          ZWL: 321.999592,
        },
      },
      {
        base: "USD",
        date: "2023-02-03",
        rates: {
          AED: 3.673104,
          AFN: 90.503991,
          ALL: 106.297033,
          AMD: 396.480403,
          ANG: 1.802146,
          AOA: 504.408041,
          ARS: 187.42836,
          AUD: 1.444252,
          AWG: 1.8025,
          AZN: 1.70397,
          BAM: 1.789455,
          BBD: 2.018985,
          BDT: 107.34807,
          BGN: 1.812085,
          BHD: 0.376955,
          BIF: 2071,
          BMD: 1,
          BND: 1.310593,
          BOB: 6.909894,
          BRL: 5.152504,
          BSD: 0.999931,
          BTC: 4.2659037e-5,
          BTN: 81.826999,
          BWP: 12.73826,
          BYN: 2.524486,
          BYR: 19600,
          BZD: 2.0156,
          CAD: 1.34095,
          CDF: 2045.000362,
          CHF: 0.926604,
          CLF: 0.028879,
          CLP: 796.850396,
          CNY: 6.775104,
          COP: 4697.5,
          CRC: 560.369633,
          CUC: 1,
          CUP: 26.5,
          CVE: 100.885199,
          CZK: 22.021104,
          DJF: 177.720394,
          DKK: 6.89504,
          DOP: 56.627096,
          DZD: 135.150815,
          EGP: 30.171307,
          ERN: 15,
          ETB: 53.681008,
          EUR: 0.92355,
          FJD: 2.17404,
          FKP: 0.815736,
          GBP: 0.830013,
          GEL: 2.630391,
          GGP: 0.815736,
          GHS: 12.249411,
          GIP: 0.815736,
          GMD: 61.000356,
          GNF: 8608.614104,
          GTQ: 7.849585,
          GYD: 210.992932,
          HKD: 7.84765,
          HNL: 24.651982,
          HRK: 7.042198,
          HTG: 149.958599,
          HUF: 359.670388,
          IDR: 15095.1,
          ILS: 3.41815,
          IMP: 0.815736,
          INR: 82.491604,
          IQD: 1459.476275,
          IRR: 42200.000352,
          ISK: 142.203816,
          JEP: 0.815736,
          JMD: 154.497045,
          JOD: 0.709404,
          JPY: 131.19504,
          KES: 124.750385,
          KGS: 86.250385,
          KHR: 4109.923692,
          KMF: 451.303796,
          KPW: 899.975445,
          KRW: 1247.850384,
          KWD: 0.30505,
          KYD: 0.833349,
          KZT: 458.025115,
          LAK: 16840.723187,
          LBP: 15020.807283,
          LKR: 364.993412,
          LRD: 156.950382,
          LSL: 17.090382,
          LTL: 2.95274,
          LVL: 0.60489,
          LYD: 4.731032,
          MAD: 10.162172,
          MDL: 18.769048,
          MGA: 4270.491091,
          MKD: 56.373659,
          MMK: 2099.961755,
          MNT: 3497.465429,
          MOP: 8.080773,
          MRO: 356.999828,
          MUR: 45.403743,
          MVR: 15.350378,
          MWK: 1026.353759,
          MXN: 18.967604,
          MYR: 4.258039,
          MZN: 63.830377,
          NAD: 17.090377,
          NGN: 460.540377,
          NIO: 36.53789,
          NOK: 10.15468,
          NPR: 130.923015,
          NZD: 1.581278,
          OMR: 0.383907,
          PAB: 0.999931,
          PEN: 3.82725,
          PGK: 3.524256,
          PHP: 54.239504,
          PKR: 275.503704,
          PLN: 4.370042,
          PYG: 7307.903453,
          QAR: 3.641038,
          RON: 4.530704,
          RSD: 108.715038,
          RUB: 70.650373,
          RWF: 1082.56822,
          SAR: 3.752339,
          SBD: 8.197272,
          SCR: 13.31886,
          SDG: 584.503678,
          SEK: 10.53759,
          SGD: 1.323604,
          SHP: 1.377404,
          SLE: 19.878854,
          SLL: 19450.000338,
          SOS: 568.503664,
          SRD: 32.284038,
          STD: 20697.981008,
          SVC: 8.749514,
          SYP: 2512.53685,
          SZL: 17.146138,
          THB: 33.340369,
          TJS: 10.314509,
          TMT: 3.51,
          TND: 3.040504,
          TOP: 2.31195,
          TRY: 18.819904,
          TTD: 6.787106,
          TWD: 29.980504,
          TZS: 2339.000335,
          UAH: 36.932318,
          UGX: 3683.798806,
          USD: 1,
          UYU: 38.712688,
          UZS: 11316.489398,
          VEF: 2240927.675832,
          VES: 22.652449,
          VND: 23450,
          VUV: 114.977965,
          WST: 2.66624,
          XAF: 600.176588,
          XAG: 0.044752,
          XAU: 0.000536,
          XCD: 2.70255,
          XDR: 0.736728,
          XOF: 600.157369,
          XPF: 109.630364,
          YER: 250.403593,
          ZAR: 17.46749,
          ZMK: 9001.203589,
          ZMW: 19.104005,
          ZWL: 321.999592,
        },
      },
      {
        base: "USD",
        date: "2023-02-02",
        rates: {
          AED: 3.673102,
          AFN: 89.999946,
          ALL: 106.349632,
          AMD: 396.0403,
          ANG: 1.802075,
          AOA: 504.274898,
          ARS: 187.583605,
          AUD: 1.41356,
          AWG: 1.8,
          AZN: 1.698675,
          BAM: 1.77793,
          BBD: 2.018909,
          BDT: 107.339254,
          BGN: 1.792876,
          BHD: 0.376997,
          BIF: 2070,
          BMD: 1,
          BND: 1.306217,
          BOB: 6.90958,
          BRL: 5.050703,
          BSD: 0.999909,
          BTC: 4.2634658e-5,
          BTN: 82.154786,
          BWP: 12.689801,
          BYN: 2.523771,
          BYR: 19600,
          BZD: 2.015545,
          CAD: 1.331735,
          CDF: 2044.99968,
          CHF: 0.913397,
          CLF: 0.028268,
          CLP: 780.000143,
          CNY: 6.7325,
          COP: 4587,
          CRC: 553.736364,
          CUC: 1,
          CUP: 26.5,
          CVE: 101.350169,
          CZK: 21.76603,
          DJF: 177.720321,
          DKK: 6.824149,
          DOP: 56.650114,
          DZD: 135.417412,
          EGP: 30.281802,
          ERN: 15,
          ETB: 53.506879,
          EUR: 0.91703,
          FJD: 2.173987,
          FKP: 0.812538,
          GBP: 0.818197,
          GEL: 2.629755,
          GGP: 0.812538,
          GHS: 12.250311,
          GIP: 0.812538,
          GMD: 60.999964,
          GNF: 8800.000109,
          GTQ: 7.849383,
          GYD: 210.984695,
          HKD: 7.84465,
          HNL: 24.620112,
          HRK: 7.042198,
          HTG: 149.987727,
          HUF: 353.314959,
          IDR: 14895.5,
          ILS: 3.39055,
          IMP: 0.812538,
          INR: 82.04615,
          IQD: 1460.5,
          IRR: 42200.000118,
          ISK: 140.730041,
          JEP: 0.812538,
          JMD: 153.769927,
          JOD: 0.709398,
          JPY: 128.751502,
          KES: 124.696363,
          KGS: 86.2206,
          KHR: 4109.999977,
          KMF: 451.297745,
          KPW: 899.98113,
          KRW: 1225.249715,
          KWD: 0.30511,
          KYD: 0.833295,
          KZT: 458.702253,
          LAK: 16834.9997,
          LBP: 15480.000064,
          LKR: 365.983436,
          LRD: 156.949945,
          LSL: 17.089608,
          LTL: 2.95274,
          LVL: 0.60489,
          LYD: 4.730227,
          MAD: 10.1705,
          MDL: 18.792611,
          MGA: 4285.000501,
          MKD: 56.485289,
          MMK: 2099.904725,
          MNT: 3466.729705,
          MOP: 8.078038,
          MRO: 356.999828,
          MUR: 44.496401,
          MVR: 15.350198,
          MWK: 1020.999642,
          MXN: 18.656398,
          MYR: 4.246055,
          MZN: 63.830118,
          NAD: 17.089947,
          NGN: 460.539756,
          NIO: 36.349938,
          NOK: 10.04929,
          NPR: 131.447221,
          NZD: 1.54406,
          OMR: 0.385,
          PAB: 0.999909,
          PEN: 3.820396,
          PGK: 3.524985,
          PHP: 53.860204,
          PKR: 270.650045,
          PLN: 4.295782,
          PYG: 7292.842792,
          QAR: 3.641044,
          RON: 4.497595,
          RSD: 107.634985,
          RUB: 75.065987,
          RWF: 1092.5,
          SAR: 3.752536,
          SBD: 8.197272,
          SCR: 13.94948,
          SDG: 584.502165,
          SEK: 10.38495,
          SGD: 1.310605,
          SHP: 1.377403,
          SLE: 19.883776,
          SLL: 19450.000089,
          SOS: 568.502706,
          SRD: 32.000327,
          STD: 20697.981008,
          SVC: 8.74921,
          SYP: 2512.533601,
          SZL: 17.08997,
          THB: 33.060311,
          TJS: 10.288712,
          TMT: 3.51,
          TND: 3.040498,
          TOP: 2.31735,
          TRY: 18.81714,
          TTD: 6.786851,
          TWD: 29.573027,
          TZS: 2331.999934,
          UAH: 36.746814,
          UGX: 3683.693785,
          USD: 1,
          UYU: 38.691466,
          UZS: 11300.000131,
          VEF: 2234550.370978,
          VES: 22.652449,
          VND: 23450,
          VUV: 115.705491,
          WST: 2.661798,
          XAF: 596.340809,
          XAG: 0.042597,
          XAU: 0.000522,
          XCD: 2.70255,
          XDR: 0.739838,
          XOF: 602.999762,
          XPF: 109.630227,
          YER: 250.404736,
          ZAR: 17.084018,
          ZMK: 9001.197048,
          ZMW: 19.099244,
          ZWL: 321.999592,
        },
      },
      {
        base: "USD",
        date: "2023-02-01",
        rates: {
          AED: 3.673101,
          AFN: 90.000225,
          ALL: 106.659794,
          AMD: 395.739847,
          ANG: 1.8021,
          AOA: 504.274902,
          ARS: 187.255581,
          AUD: 1.399639,
          AWG: 1.8,
          AZN: 1.698907,
          BAM: 1.795443,
          BBD: 2.018947,
          BDT: 106.076647,
          BGN: 1.794986,
          BHD: 0.377026,
          BIF: 2072.5,
          BMD: 1,
          BND: 1.312684,
          BOB: 6.909413,
          BRL: 5.054992,
          BSD: 0.999972,
          BTC: 4.217966e-5,
          BTN: 81.972854,
          BWP: 12.819623,
          BYN: 2.524396,
          BYR: 19600,
          BZD: 2.015551,
          CAD: 1.32775,
          CDF: 2042.9997,
          CHF: 0.90731,
          CLF: 0.028505,
          CLP: 786.550074,
          CNY: 6.7418,
          COP: 4607.03,
          CRC: 551.494956,
          CUC: 1,
          CUP: 26.5,
          CVE: 101.375042,
          CZK: 21.604396,
          DJF: 178.008501,
          DKK: 6.755901,
          DOP: 56.550061,
          DZD: 135.528986,
          EGP: 30.2039,
          ERN: 15,
          ETB: 53.514659,
          EUR: 0.90806,
          FJD: 2.16645,
          FKP: 0.811532,
          GBP: 0.807325,
          GEL: 2.630312,
          GGP: 0.811532,
          GHS: 12.449625,
          GIP: 0.811532,
          GMD: 61.604285,
          GNF: 8825.000428,
          GTQ: 7.84934,
          GYD: 210.982898,
          HKD: 7.83925,
          HNL: 24.664989,
          HRK: 7.042198,
          HTG: 149.189869,
          HUF: 354.269715,
          IDR: 14888,
          ILS: 3.45185,
          IMP: 0.811532,
          INR: 81.71055,
          IQD: 1460.5,
          IRR: 42205.000546,
          ISK: 139.419582,
          JEP: 0.811532,
          JMD: 153.24692,
          JOD: 0.709399,
          JPY: 128.624975,
          KES: 124.583254,
          KGS: 86.149777,
          KHR: 4112.999623,
          KMF: 451.249898,
          KPW: 899.990138,
          KRW: 1222.275018,
          KWD: 0.30489,
          KYD: 0.833356,
          KZT: 460.517377,
          LAK: 16840.000031,
          LBP: 1512.502645,
          LKR: 365.969853,
          LRD: 156.876319,
          LSL: 17.180254,
          LTL: 2.95274,
          LVL: 0.60489,
          LYD: 4.759584,
          MAD: 10.162976,
          MDL: 18.823416,
          MGA: 4287.999747,
          MKD: 56.075064,
          MMK: 2099.826684,
          MNT: 3467.182389,
          MOP: 8.077075,
          MRO: 356.999828,
          MUR: 44.197294,
          MVR: 15.359709,
          MWK: 1020.498948,
          MXN: 18.597895,
          MYR: 4.266011,
          MZN: 63.829824,
          NAD: 17.180416,
          NGN: 460.520215,
          NIO: 36.407612,
          NOK: 9.890445,
          NPR: 131.150054,
          NZD: 1.534765,
          OMR: 0.385034,
          PAB: 0.999972,
          PEN: 3.8475,
          PGK: 3.519812,
          PHP: 54.110264,
          PKR: 267.197705,
          PLN: 4.270472,
          PYG: 7345.157115,
          QAR: 3.64125,
          RON: 4.458497,
          RSD: 106.589797,
          RUB: 69.934987,
          RWF: 1087,
          SAR: 3.752388,
          SBD: 8.244583,
          SCR: 14.085671,
          SDG: 583.496577,
          SEK: 10.312135,
          SGD: 1.305245,
          SHP: 1.377394,
          SLE: 19.73314,
          SLL: 19475.000357,
          SOS: 568.498055,
          SRD: 32.460499,
          STD: 20697.981008,
          SVC: 8.749415,
          SYP: 2512.511579,
          SZL: 17.180134,
          THB: 32.73798,
          TJS: 10.314135,
          TMT: 3.5,
          TND: 3.028502,
          TOP: 2.31575,
          TRY: 18.81681,
          TTD: 6.786006,
          TWD: 29.5405,
          TZS: 2331.999722,
          UAH: 36.930682,
          UGX: 3684.741997,
          USD: 1,
          UYU: 38.762003,
          UZS: 11274.999769,
          VEF: 2192409.357596,
          VES: 22.410053,
          VND: 23450,
          VUV: 115.684624,
          WST: 2.662262,
          XAF: 602.184899,
          XAG: 0.041583,
          XAU: 0.000512,
          XCD: 2.70255,
          XDR: 0.741581,
          XOF: 602.999636,
          XPF: 109.625041,
          YER: 250.402706,
          ZAR: 17.032502,
          ZMK: 9001.203814,
          ZMW: 19.103577,
          ZWL: 321.999592,
        },
      },
    ];
    const FIXER_ARRAY_202301 = [
      {
        base: "USD",
        date: "2023-01-31",
        rates: {
          AED: 3.673095,
          AFN: 88.999792,
          ALL: 107.61827,
          AMD: 395.669766,
          ANG: 1.802158,
          AOA: 504.2749,
          ARS: 186.998573,
          AUD: 1.418239,
          AWG: 1.80275,
          AZN: 1.700141,
          BAM: 1.804904,
          BBD: 2.019066,
          BDT: 106.745106,
          BGN: 1.803133,
          BHD: 0.376997,
          BIF: 2073.5,
          BMD: 1,
          BND: 1.317129,
          BOB: 6.909802,
          BRL: 5.0733,
          BSD: 0.999982,
          BTC: 4.3219723e-5,
          BTN: 81.996595,
          BWP: 12.869436,
          BYN: 2.52404,
          BYR: 19600,
          BZD: 2.015651,
          CAD: 1.330435,
          CDF: 2042.50636,
          CHF: 0.91643,
          CLF: 0.028854,
          CLP: 796.16019,
          CNY: 6.754699,
          COP: 4660.24,
          CRC: 552.315387,
          CUC: 1,
          CUP: 26.5,
          CVE: 101.874969,
          CZK: 21.909865,
          DJF: 177.720142,
          DKK: 6.849051,
          DOP: 56.708994,
          DZD: 135.932005,
          EGP: 30.17949,
          ERN: 15,
          ETB: 53.659678,
          EUR: 0.92072,
          FJD: 2.161299,
          FKP: 0.808096,
          GBP: 0.81237,
          GEL: 2.630012,
          GGP: 0.808096,
          GHS: 12.349717,
          GIP: 0.808096,
          GMD: 61.797509,
          GNF: 8609.84478,
          GTQ: 7.849619,
          GYD: 210.996475,
          HKD: 7.83995,
          HNL: 24.656245,
          HRK: 7.042198,
          HTG: 149.497056,
          HUF: 360.219958,
          IDR: 14999.35,
          ILS: 3.454801,
          IMP: 0.808096,
          INR: 81.76865,
          IQD: 1459.453972,
          IRR: 42204.999919,
          ISK: 141.150199,
          JEP: 0.808096,
          JMD: 153.838203,
          JOD: 0.709402,
          JPY: 130.121501,
          KES: 124.501894,
          KGS: 86.150412,
          KHR: 4109.999694,
          KMF: 453.350279,
          KPW: 900.022785,
          KRW: 1232.549948,
          KWD: 0.30525,
          KYD: 0.833299,
          KZT: 460.548901,
          LAK: 16842.231543,
          LBP: 1511.96914,
          LKR: 367.48317,
          LRD: 156.750081,
          LSL: 17.209918,
          LTL: 2.95274,
          LVL: 0.60489,
          LYD: 4.75628,
          MAD: 10.209986,
          MDL: 18.749129,
          MGA: 4284.961518,
          MKD: 56.721886,
          MMK: 2099.903289,
          MNT: 3470.372397,
          MOP: 8.072385,
          MRO: 356.999828,
          MUR: 44.198946,
          MVR: 15.360157,
          MWK: 1027.086986,
          MXN: 18.845385,
          MYR: 4.265798,
          MZN: 63.830026,
          NAD: 17.209929,
          NGN: 460.520313,
          NIO: 36.539562,
          NOK: 9.98406,
          NPR: 131.194256,
          NZD: 1.55339,
          OMR: 0.384997,
          PAB: 0.999982,
          PEN: 3.837501,
          PGK: 3.524206,
          PHP: 54.69886,
          PKR: 267.95007,
          PLN: 4.336082,
          PYG: 7385.536776,
          QAR: 3.64125,
          RON: 4.529739,
          RSD: 108.040196,
          RUB: 70.584977,
          RWF: 1080.566053,
          SAR: 3.753124,
          SBD: 8.214418,
          SCR: 14.1849,
          SDG: 582.497167,
          SEK: 10.44177,
          SGD: 1.314299,
          SHP: 1.377404,
          SLE: 19.679347,
          SLL: 19399.999895,
          SOS: 569.452639,
          SRD: 32.037495,
          STD: 20697.981008,
          SVC: 8.7503,
          SYP: 2512.05441,
          SZL: 17.435507,
          THB: 32.849913,
          TJS: 10.289769,
          TMT: 3.51,
          TND: 3.049979,
          TOP: 2.314102,
          TRY: 18.812125,
          TTD: 6.792879,
          TWD: 29.930497,
          TZS: 2332.000434,
          UAH: 36.743306,
          UGX: 3687.916428,
          USD: 1,
          UYU: 38.643652,
          UZS: 11269.55944,
          VEF: 2183137.0523,
          VES: 21.699941,
          VND: 23447.5,
          VUV: 115.205126,
          WST: 2.651385,
          XAF: 605.338587,
          XAG: 0.042233,
          XAU: 0.000519,
          XCD: 2.70255,
          XDR: 0.739743,
          XOF: 605.338587,
          XPF: 110.200162,
          YER: 250.39797,
          ZAR: 17.41675,
          ZMK: 9001.20174,
          ZMW: 19.024566,
          ZWL: 321.999592,
        },
      },
      {
        base: "USD",
        date: "2023-01-30",
        rates: {
          AED: 3.673102,
          AFN: 88.999783,
          ALL: 107.251296,
          AMD: 395.879682,
          ANG: 1.801817,
          AOA: 504.274898,
          ARS: 186.553671,
          AUD: 1.417063,
          AWG: 1.8,
          AZN: 1.69315,
          BAM: 1.794496,
          BBD: 2.018607,
          BDT: 106.073676,
          BGN: 1.799683,
          BHD: 0.377033,
          BIF: 2063.5,
          BMD: 1,
          BND: 1.31223,
          BOB: 6.908185,
          BRL: 5.116295,
          BSD: 0.999716,
          BTC: 4.3792875e-5,
          BTN: 81.437563,
          BWP: 12.800965,
          BYN: 2.523431,
          BYR: 19600,
          BZD: 2.015212,
          CAD: 1.33865,
          CDF: 2044.501001,
          CHF: 0.925401,
          CLF: 0.029265,
          CLP: 807.498247,
          CNY: 6.748803,
          COP: 4602.18,
          CRC: 554.256773,
          CUC: 1,
          CUP: 26.5,
          CVE: 101.920478,
          CZK: 21.975699,
          DJF: 177.719841,
          DKK: 6.85598,
          DOP: 56.650299,
          DZD: 135.7448,
          EGP: 30.082166,
          ERN: 15,
          ETB: 53.642348,
          EUR: 0.921705,
          FJD: 2.17825,
          FKP: 0.807191,
          GBP: 0.809755,
          GEL: 2.629871,
          GGP: 0.807191,
          GHS: 12.508119,
          GIP: 0.807191,
          GMD: 61.802143,
          GNF: 8608.285967,
          GTQ: 7.848059,
          GYD: 210.943055,
          HKD: 7.83545,
          HNL: 24.656164,
          HRK: 7.042198,
          HTG: 148.872843,
          HUF: 359.519966,
          IDR: 15013.2,
          ILS: 3.46193,
          IMP: 0.807191,
          INR: 81.58015,
          IQD: 1458.808421,
          IRR: 42200.000012,
          ISK: 140.940303,
          JEP: 0.807191,
          JMD: 153.610849,
          JOD: 0.7093,
          JPY: 130.521499,
          KES: 124.42019,
          KGS: 86.14999,
          KHR: 4109.99992,
          KMF: 453.350044,
          KPW: 899.999999,
          KRW: 1231.850174,
          KWD: 0.30537,
          KYD: 0.833096,
          KZT: 459.687223,
          LAK: 16836.315258,
          LBP: 1515.304762,
          LKR: 365.909441,
          LRD: 156.750142,
          LSL: 17.210395,
          LTL: 2.95274,
          LVL: 0.60489,
          LYD: 4.750737,
          MAD: 10.154658,
          MDL: 18.70605,
          MGA: 4270.732983,
          MKD: 56.78357,
          MMK: 2099.479952,
          MNT: 3487.439887,
          MOP: 8.067713,
          MRO: 356.999828,
          MUR: 44.451205,
          MVR: 15.360112,
          MWK: 1026.841543,
          MXN: 18.773969,
          MYR: 4.24397,
          MZN: 63.830066,
          NAD: 17.210073,
          NGN: 460.41021,
          NIO: 36.530372,
          NOK: 9.979445,
          NPR: 130.300028,
          NZD: 1.54616,
          OMR: 0.385002,
          PAB: 0.999716,
          PEN: 3.840986,
          PGK: 3.522709,
          PHP: 54.638013,
          PKR: 269.504398,
          PLN: 4.342346,
          PYG: 7389.350089,
          QAR: 3.64125,
          RON: 4.528195,
          RSD: 108.194998,
          RUB: 69.914975,
          RWF: 1080.905763,
          SAR: 3.754309,
          SBD: 8.214418,
          SCR: 14.183998,
          SDG: 582.49688,
          SEK: 10.39898,
          SGD: 1.3148,
          SHP: 1.377398,
          SLE: 19.396018,
          SLL: 19399.999778,
          SOS: 569.492933,
          SRD: 32.05301,
          STD: 20697.981008,
          SVC: 8.747471,
          SYP: 2512.525092,
          SZL: 17.331223,
          THB: 32.781009,
          TJS: 10.272269,
          TMT: 3.51,
          TND: 3.050237,
          TOP: 2.310401,
          TRY: 18.812993,
          TTD: 6.791295,
          TWD: 30.092972,
          TZS: 2331.99963,
          UAH: 36.727515,
          UGX: 3687.025772,
          USD: 1,
          UYU: 38.965043,
          UZS: 11331.993779,
          VEF: 2158004.077508,
          VES: 21.699941,
          VND: 23472.5,
          VUV: 114.380285,
          WST: 2.620527,
          XAF: 601.850628,
          XAG: 0.042409,
          XAU: 0.00052,
          XCD: 2.70255,
          XDR: 0.739976,
          XOF: 601.842346,
          XPF: 110.201118,
          YER: 250.400085,
          ZAR: 17.393503,
          ZMK: 9001.201278,
          ZMW: 19.019754,
          ZWL: 321.999592,
        },
      },
      {
        base: "USD",
        date: "2023-01-29",
        rates: {
          AED: 3.67297,
          AFN: 89.134133,
          ALL: 107.352738,
          AMD: 395.898285,
          ANG: 1.803099,
          AOA: 504.2745,
          ARS: 185.578189,
          AUD: 1.40694,
          AWG: 1.8025,
          AZN: 1.705413,
          BAM: 1.798712,
          BBD: 2.020049,
          BDT: 106.052145,
          BGN: 1.797759,
          BHD: 0.37688,
          BIF: 2075.670207,
          BMD: 1,
          BND: 1.31448,
          BOB: 6.913413,
          BRL: 5.109202,
          BSD: 1.000506,
          BTC: 4.2120435e-5,
          BTN: 81.556261,
          BWP: 12.77767,
          BYN: 2.525866,
          BYR: 19600,
          BZD: 2.016738,
          CAD: 1.330385,
          CDF: 2045.000099,
          CHF: 0.92092,
          CLF: 0.029225,
          CLP: 803.044926,
          CNY: 6.7834,
          COP: 4529.912172,
          CRC: 562.827057,
          CUC: 1,
          CUP: 26.5,
          CVE: 101.407091,
          CZK: 21.892033,
          DJF: 178.111923,
          DKK: 6.84202,
          DOP: 56.797719,
          DZD: 135.585598,
          EGP: 29.882858,
          ERN: 15,
          ETB: 53.680416,
          EUR: 0.91987,
          FJD: 2.15245,
          FKP: 0.807592,
          GBP: 0.806494,
          GEL: 2.629823,
          GGP: 0.807592,
          GHS: 12.406125,
          GIP: 0.807592,
          GMD: 61.796617,
          GNF: 8617.740378,
          GTQ: 7.853865,
          GYD: 211.100382,
          HKD: 7.828645,
          HNL: 24.674668,
          HRK: 7.042198,
          HTG: 148.984228,
          HUF: 359.284997,
          IDR: 14972.8,
          ILS: 3.435855,
          IMP: 0.807592,
          INR: 81.515499,
          IQD: 1460.238194,
          IRR: 42199.99971,
          ISK: 142.630299,
          JEP: 0.807592,
          JMD: 153.795926,
          JOD: 0.709295,
          JPY: 129.859505,
          KES: 124.461213,
          KGS: 86.149916,
          KHR: 4105.025981,
          KMF: 453.350083,
          KPW: 899.999492,
          KRW: 1230.050257,
          KWD: 0.30526,
          KYD: 0.83377,
          KZT: 460.578471,
          LAK: 16847.289281,
          LBP: 1512.760381,
          LKR: 364.179151,
          LRD: 156.749987,
          LSL: 17.210072,
          LTL: 2.95274,
          LVL: 0.60489,
          LYD: 4.754311,
          MAD: 10.180807,
          MDL: 18.764243,
          MGA: 4264.606612,
          MKD: 56.665287,
          MMK: 2101.03343,
          MNT: 3488.119337,
          MOP: 8.066676,
          MRO: 356.999828,
          MUR: 45.128064,
          MVR: 15.360446,
          MWK: 1027.615763,
          MXN: 18.77125,
          MYR: 4.24401,
          MZN: 63.829881,
          NAD: 17.209857,
          NGN: 460.280292,
          NIO: 36.557686,
          NOK: 9.86615,
          NPR: 130.489723,
          NZD: 1.54049,
          OMR: 0.384862,
          PAB: 1.000506,
          PEN: 3.836115,
          PGK: 3.52536,
          PHP: 54.489823,
          PKR: 250.623258,
          PLN: 4.334454,
          PYG: 7377.84522,
          QAR: 3.641005,
          RON: 4.5074,
          RSD: 107.930289,
          RUB: 70.727503,
          RWF: 1080.222559,
          SAR: 3.753902,
          SBD: 8.214418,
          SCR: 14.146994,
          SDG: 582.497201,
          SEK: 10.31476,
          SGD: 1.31216,
          SHP: 1.377403,
          SLE: 19.405042,
          SLL: 19399.999665,
          SOS: 569.49862,
          SRD: 32.011503,
          STD: 20697.981008,
          SVC: 8.754311,
          SYP: 2512.530707,
          SZL: 17.205959,
          THB: 32.779968,
          TJS: 10.280039,
          TMT: 3.51,
          TND: 3.049789,
          TOP: 2.312499,
          TRY: 18.8126,
          TTD: 6.791006,
          TWD: 30.256499,
          TZS: 2340.148066,
          UAH: 36.763048,
          UGX: 3686.853359,
          USD: 1,
          UYU: 39.124477,
          UZS: 11340.534327,
          VEF: 2175106.543289,
          VES: 21.699941,
          VND: 23467.5,
          VUV: 114.282875,
          WST: 2.620523,
          XAF: 603.262059,
          XAG: 0.042297,
          XAU: 0.000518,
          XCD: 2.70255,
          XDR: 0.739688,
          XOF: 603.262059,
          XPF: 110.199675,
          YER: 250.40319,
          ZAR: 17.20936,
          ZMK: 9001.198917,
          ZMW: 18.929324,
          ZWL: 321.999592,
        },
      },
      {
        base: "USD",
        date: "2023-01-28",
        rates: {
          AED: 3.672904,
          AFN: 89.134133,
          ALL: 107.352738,
          AMD: 395.898285,
          ANG: 1.803099,
          AOA: 504.274504,
          ARS: 185.543201,
          AUD: 1.407658,
          AWG: 1.8025,
          AZN: 1.70397,
          BAM: 1.798712,
          BBD: 2.020049,
          BDT: 106.052145,
          BGN: 1.79776,
          BHD: 0.37688,
          BIF: 2075.670207,
          BMD: 1,
          BND: 1.31448,
          BOB: 6.913413,
          BRL: 5.109204,
          BSD: 1.000506,
          BTC: 4.3410089e-5,
          BTN: 81.556261,
          BWP: 12.77767,
          BYN: 2.525866,
          BYR: 19600,
          BZD: 2.016738,
          CAD: 1.33385,
          CDF: 2045.000362,
          CHF: 0.922381,
          CLF: 0.029225,
          CLP: 803.044926,
          CNY: 6.783404,
          COP: 4529.912172,
          CRC: 562.827057,
          CUC: 1,
          CUP: 26.5,
          CVE: 101.407091,
          CZK: 21.911504,
          DJF: 178.111923,
          DKK: 6.843404,
          DOP: 56.797719,
          DZD: 135.585598,
          EGP: 29.877224,
          ERN: 15,
          ETB: 53.680416,
          EUR: 0.91975,
          FJD: 2.15245,
          FKP: 0.807773,
          GBP: 0.807689,
          GEL: 2.630391,
          GGP: 0.807773,
          GHS: 12.406125,
          GIP: 0.807773,
          GMD: 61.803853,
          GNF: 8617.740378,
          GTQ: 7.853865,
          GYD: 211.100382,
          HKD: 7.83025,
          HNL: 24.674668,
          HRK: 7.042198,
          HTG: 148.984228,
          HUF: 359.310388,
          IDR: 14972.8,
          ILS: 3.44027,
          IMP: 0.807773,
          INR: 81.515504,
          IQD: 1460.238194,
          IRR: 42200.000352,
          ISK: 142.630386,
          JEP: 0.807773,
          JMD: 153.795926,
          JOD: 0.709304,
          JPY: 129.87504,
          KES: 124.461213,
          KGS: 86.150385,
          KHR: 4105.025981,
          KMF: 453.350384,
          KPW: 899.990146,
          KRW: 1230.050384,
          KWD: 0.30526,
          KYD: 0.83377,
          KZT: 460.578471,
          LAK: 16847.289281,
          LBP: 1512.760381,
          LKR: 364.179151,
          LRD: 156.750382,
          LSL: 17.210382,
          LTL: 2.95274,
          LVL: 0.60489,
          LYD: 4.754311,
          MAD: 10.180807,
          MDL: 18.764243,
          MGA: 4264.606612,
          MKD: 56.665287,
          MMK: 2101.03343,
          MNT: 3470.887346,
          MOP: 8.066676,
          MRO: 356.999828,
          MUR: 45.128064,
          MVR: 15.360378,
          MWK: 1027.615763,
          MXN: 18.778404,
          MYR: 4.244039,
          MZN: 63.830377,
          NAD: 17.210377,
          NGN: 460.280377,
          NIO: 36.557686,
          NOK: 9.816955,
          NPR: 130.489723,
          NZD: 1.540832,
          OMR: 0.384862,
          PAB: 1.000506,
          PEN: 3.836115,
          PGK: 3.52536,
          PHP: 54.490375,
          PKR: 250.623258,
          PLN: 4.33015,
          PYG: 7377.84522,
          QAR: 3.641038,
          RON: 4.506904,
          RSD: 107.930289,
          RUB: 70.727504,
          RWF: 1080.222559,
          SAR: 3.753637,
          SBD: 8.214418,
          SCR: 14.147038,
          SDG: 582.503678,
          SEK: 10.308038,
          SGD: 1.313504,
          SHP: 1.377404,
          SLE: 19.714556,
          SLL: 19400.000338,
          SOS: 569.503664,
          SRD: 32.011504,
          STD: 20697.981008,
          SVC: 8.754311,
          SYP: 2512.499957,
          SZL: 17.205959,
          THB: 32.784038,
          TJS: 10.280039,
          TMT: 3.51,
          TND: 3.050369,
          TOP: 2.312504,
          TRY: 18.811038,
          TTD: 6.791006,
          TWD: 30.256504,
          TZS: 2340.148066,
          UAH: 36.763048,
          UGX: 3686.853359,
          USD: 1,
          UYU: 39.124477,
          UZS: 11340.534327,
          VEF: 2154207.443431,
          VES: 21.699941,
          VND: 23467.5,
          VUV: 114.984486,
          WST: 2.64631,
          XAF: 603.262059,
          XAG: 0.042383,
          XAU: 0.000519,
          XCD: 2.70255,
          XDR: 0.739688,
          XOF: 603.262059,
          XPF: 110.203595,
          YER: 250.403593,
          ZAR: 17.20318,
          ZMK: 9001.203589,
          ZMW: 18.929324,
          ZWL: 321.999592,
        },
      },
      {
        base: "USD",
        date: "2023-01-27",
        rates: {
          AED: 3.672904,
          AFN: 88.503991,
          ALL: 107.303989,
          AMD: 396.020403,
          ANG: 1.802668,
          AOA: 504.274504,
          ARS: 185.543201,
          AUD: 1.398204,
          AWG: 1.8025,
          AZN: 1.70397,
          BAM: 1.798282,
          BBD: 2.019566,
          BDT: 106.026793,
          BGN: 1.799104,
          BHD: 0.377055,
          BIF: 2061,
          BMD: 1,
          BND: 1.314166,
          BOB: 6.911761,
          BRL: 5.109304,
          BSD: 1.000267,
          BTC: 4.3341351e-5,
          BTN: 81.536764,
          BWP: 12.774616,
          BYN: 2.525262,
          BYR: 19600,
          BZD: 2.016256,
          CAD: 1.33375,
          CDF: 2045.000362,
          CHF: 0.922204,
          CLF: 0.029225,
          CLP: 806.410396,
          CNY: 6.783504,
          COP: 4548.5,
          CRC: 562.692509,
          CUC: 1,
          CUP: 26.5,
          CVE: 102.00036,
          CZK: 21.909804,
          DJF: 177.720394,
          DKK: 6.843304,
          DOP: 56.750393,
          DZD: 135.648598,
          EGP: 29.877224,
          ERN: 15,
          ETB: 53.503876,
          EUR: 0.91975,
          FJD: 2.17504,
          FKP: 0.808541,
          GBP: 0.807689,
          GEL: 2.630391,
          GGP: 0.808541,
          GHS: 12.503858,
          GIP: 0.808541,
          GMD: 61.803853,
          GNF: 8800.000355,
          GTQ: 7.851987,
          GYD: 211.049917,
          HKD: 7.83015,
          HNL: 24.670389,
          HRK: 7.042198,
          HTG: 148.948612,
          HUF: 359.310388,
          IDR: 14972.7,
          ILS: 3.44027,
          IMP: 0.808541,
          INR: 81.51455,
          IQD: 7960.5,
          IRR: 42200.000352,
          ISK: 142.630386,
          JEP: 0.808541,
          JMD: 153.75916,
          JOD: 0.709304,
          JPY: 129.88504,
          KES: 124.450385,
          KGS: 86.150385,
          KHR: 4110.000351,
          KMF: 453.350384,
          KPW: 899.971395,
          KRW: 1230.050384,
          KWD: 0.30526,
          KYD: 0.833571,
          KZT: 460.468366,
          LAK: 16835.000349,
          LBP: 1516.099356,
          LKR: 364.092092,
          LRD: 156.750382,
          LSL: 17.210382,
          LTL: 2.95274,
          LVL: 0.60489,
          LYD: 4.753174,
          MAD: 10.174304,
          MDL: 18.759758,
          MGA: 4265.000347,
          MKD: 56.589824,
          MMK: 2100.531165,
          MNT: 3481.428026,
          MOP: 8.064747,
          MRO: 356.999828,
          MUR: 45.150379,
          MVR: 15.360378,
          MWK: 1020.503739,
          MXN: 18.778304,
          MYR: 4.244039,
          MZN: 63.830377,
          NAD: 17.210377,
          NGN: 460.280377,
          NIO: 36.403725,
          NOK: 9.86144,
          NPR: 130.458528,
          NZD: 1.540832,
          OMR: 0.385041,
          PAB: 1.000267,
          PEN: 3.824504,
          PGK: 3.520375,
          PHP: 54.595504,
          PKR: 262.850375,
          PLN: 4.33015,
          PYG: 7376.0815,
          QAR: 3.641038,
          RON: 4.506904,
          RSD: 107.975038,
          RUB: 70.727504,
          RWF: 1082.5,
          SAR: 3.753939,
          SBD: 8.214418,
          SCR: 13.970585,
          SDG: 582.503678,
          SEK: 10.30865,
          SGD: 1.313604,
          SHP: 1.377404,
          SLE: 19.360264,
          SLL: 19400.000338,
          SOS: 569.503664,
          SRD: 32.011504,
          STD: 20697.981008,
          SVC: 8.752218,
          SYP: 2512.359966,
          SZL: 17.21037,
          THB: 32.770369,
          TJS: 10.277581,
          TMT: 3.51,
          TND: 3.050369,
          TOP: 2.312504,
          TRY: 18.811038,
          TTD: 6.789382,
          TWD: 30.266504,
          TZS: 2339.000335,
          UAH: 36.754259,
          UGX: 3685.971994,
          USD: 1,
          UYU: 39.115124,
          UZS: 11340.000334,
          VEF: 2130528.566296,
          VES: 21.699941,
          VND: 23467.5,
          VUV: 115.109158,
          WST: 2.655623,
          XAF: 603.117846,
          XAG: 0.042383,
          XAU: 0.000519,
          XCD: 2.70255,
          XDR: 0.739511,
          XOF: 602.000332,
          XPF: 110.203595,
          YER: 250.403593,
          ZAR: 17.203204,
          ZMK: 9001.203589,
          ZMW: 18.924798,
          ZWL: 321.999592,
        },
      },
      {
        base: "USD",
        date: "2023-01-26",
        rates: {
          AED: 3.673101,
          AFN: 88.498072,
          ALL: 107.349671,
          AMD: 395.649515,
          ANG: 1.804259,
          AOA: 504.549104,
          ARS: 185.323364,
          AUD: 1.405738,
          AWG: 1.8,
          AZN: 1.700773,
          BAM: 1.795839,
          BBD: 2.020838,
          BDT: 106.092899,
          BGN: 1.795401,
          BHD: 0.37701,
          BIF: 2062.5,
          BMD: 1,
          BND: 1.312893,
          BOB: 6.916051,
          BRL: 5.066417,
          BSD: 1.000872,
          BTC: 4.3463677e-5,
          BTN: 81.581861,
          BWP: 12.736872,
          BYN: 2.52688,
          BYR: 19600,
          BZD: 2.017442,
          CAD: 1.33188,
          CDF: 2043.999985,
          CHF: 0.920195,
          CLF: 0.029075,
          CLP: 802.279727,
          CNY: 6.783497,
          COP: 4520.85,
          CRC: 563.042181,
          CUC: 1,
          CUP: 26.5,
          CVE: 101.624959,
          CZK: 21.860801,
          DJF: 177.720368,
          DKK: 6.827299,
          DOP: 56.749762,
          DZD: 135.664978,
          EGP: 29.895803,
          ERN: 15,
          ETB: 53.502826,
          EUR: 0.917896,
          FJD: 2.170699,
          FKP: 0.808292,
          GBP: 0.805502,
          GEL: 2.645008,
          GGP: 0.808292,
          GHS: 12.39754,
          GIP: 0.808292,
          GMD: 61.802912,
          GNF: 8621.601521,
          GTQ: 7.853713,
          GYD: 211.180979,
          HKD: 7.82875,
          HNL: 24.68498,
          HRK: 7.042198,
          HTG: 148.997787,
          HUF: 356.049924,
          IDR: 14955.1,
          ILS: 3.41675,
          IMP: 0.808292,
          INR: 81.44535,
          IQD: 1460.5,
          IRR: 42200.000056,
          ISK: 143.29028,
          JEP: 0.808292,
          JMD: 153.956973,
          JOD: 0.709304,
          JPY: 129.803991,
          KES: 124.350241,
          KGS: 86.122501,
          KHR: 4107.503563,
          KMF: 452.700983,
          KPW: 899.963273,
          KRW: 1232.199323,
          KWD: 0.30519,
          KYD: 0.834274,
          KZT: 461.376011,
          LAK: 16844.999907,
          LBP: 1516.014104,
          LKR: 364.406156,
          LRD: 156.749942,
          LSL: 17.209701,
          LTL: 2.95274,
          LVL: 0.60489,
          LYD: 4.754993,
          MAD: 10.144498,
          MDL: 18.815984,
          MGA: 4317.500338,
          MKD: 56.573197,
          MMK: 2101.84119,
          MNT: 3475.103904,
          MOP: 8.073731,
          MRO: 356.999828,
          MUR: 45.147017,
          MVR: 15.349834,
          MWK: 1024.503834,
          MXN: 18.800495,
          MYR: 4.245496,
          MZN: 63.830056,
          NAD: 17.210276,
          NGN: 460.28016,
          NIO: 36.409583,
          NOK: 9.861225,
          NPR: 130.562212,
          NZD: 1.54082,
          OMR: 0.385015,
          PAB: 1.000872,
          PEN: 3.827496,
          PGK: 3.525029,
          PHP: 54.385004,
          PKR: 250.503214,
          PLN: 4.327088,
          PYG: 7393.052916,
          QAR: 3.641021,
          RON: 4.475801,
          RSD: 107.764981,
          RUB: 69.493084,
          RWF: 1082,
          SAR: 3.754211,
          SBD: 8.244583,
          SCR: 14.147228,
          SDG: 579.500677,
          SEK: 10.26805,
          SGD: 1.311445,
          SHP: 1.377404,
          SLE: 19.350287,
          SLL: 19425.000069,
          SOS: 568.501128,
          SRD: 32.000191,
          STD: 20697.981008,
          SVC: 8.759607,
          SYP: 2512.423556,
          SZL: 17.210112,
          THB: 32.781496,
          TJS: 10.276469,
          TMT: 3.5,
          TND: 3.090063,
          TOP: 2.31145,
          TRY: 18.81359,
          TTD: 6.793593,
          TWD: 30.223798,
          TZS: 2331.99978,
          UAH: 36.79246,
          UGX: 3683.205581,
          USD: 1,
          UYU: 39.198421,
          UZS: 11325.000115,
          VEF: 2114387.920519,
          VES: 21.327302,
          VND: 23450,
          VUV: 115.548082,
          WST: 2.658808,
          XAF: 602.292719,
          XAG: 0.041812,
          XAU: 0.000518,
          XCD: 2.70255,
          XDR: 0.741713,
          XOF: 600.50406,
          XPF: 110.249566,
          YER: 250.349624,
          ZAR: 17.175503,
          ZMK: 9001.194781,
          ZMW: 18.89659,
          ZWL: 321.999592,
        },
      },
      {
        base: "USD",
        date: "2023-01-25",
        rates: {
          AED: 3.6731,
          AFN: 89.493319,
          ALL: 107.050264,
          AMD: 395.625941,
          ANG: 1.802126,
          AOA: 504.548978,
          ARS: 184.966537,
          AUD: 1.406885,
          AWG: 1.8025,
          AZN: 1.700609,
          BAM: 1.800294,
          BBD: 2.019275,
          BDT: 103.863329,
          BGN: 1.79156,
          BHD: 0.37702,
          BIF: 2061.5,
          BMD: 1,
          BND: 1.315448,
          BOB: 6.910657,
          BRL: 5.073702,
          BSD: 1.00011,
          BTC: 4.330107e-5,
          BTN: 81.616216,
          BWP: 12.770604,
          BYN: 2.524772,
          BYR: 19600,
          BZD: 2.015869,
          CAD: 1.338165,
          CDF: 2044.999686,
          CHF: 0.917185,
          CLF: 0.029118,
          CLP: 803.450089,
          CNY: 6.783902,
          COP: 4545.95,
          CRC: 563.348,
          CUC: 1,
          CUP: 26.5,
          CVE: 101.450031,
          CZK: 21.773969,
          DJF: 177.71992,
          DKK: 6.81145,
          DOP: 56.599944,
          DZD: 135.531034,
          EGP: 29.890601,
          ERN: 15,
          ETB: 53.550049,
          EUR: 0.91561,
          FJD: 2.15155,
          FKP: 0.811628,
          GBP: 0.80569,
          GEL: 2.64501,
          GGP: 0.811628,
          GHS: 12.403625,
          GIP: 0.811628,
          GMD: 61.805751,
          GNF: 8804.999618,
          GTQ: 7.844462,
          GYD: 211.021926,
          HKD: 7.82975,
          HNL: 24.699624,
          HRK: 7.042198,
          HTG: 148.845708,
          HUF: 354.450216,
          IDR: 14939.8,
          ILS: 3.37905,
          IMP: 0.811628,
          INR: 81.50295,
          IQD: 1460,
          IRR: 42199.999832,
          ISK: 143.839796,
          JEP: 0.811628,
          JMD: 154.000276,
          JOD: 0.709298,
          JPY: 129.24009,
          KES: 124.349864,
          KGS: 86.0906,
          KHR: 4115.999617,
          KMF: 451.22504,
          KPW: 899.942907,
          KRW: 1232.940069,
          KWD: 0.30506,
          KYD: 0.83328,
          KZT: 461.399682,
          LAK: 16839.999611,
          LBP: 1522.999703,
          LKR: 363.976623,
          LRD: 156.479702,
          LSL: 17.220329,
          LTL: 2.95274,
          LVL: 0.60489,
          LYD: 4.75498,
          MAD: 10.15975,
          MDL: 18.836692,
          MGA: 4314.999707,
          MKD: 56.370225,
          MMK: 2099.864893,
          MNT: 3458.177882,
          MOP: 8.066817,
          MRO: 356.999828,
          MUR: 44.76029,
          MVR: 15.369826,
          MWK: 1019.00016,
          MXN: 18.791198,
          MYR: 4.2845,
          MZN: 63.829829,
          NAD: 17.219903,
          NGN: 459.969671,
          NIO: 36.450231,
          NOK: 9.891301,
          NPR: 130.585799,
          NZD: 1.54241,
          OMR: 0.385032,
          PAB: 1.00011,
          PEN: 3.872499,
          PGK: 3.520201,
          PHP: 54.569951,
          PKR: 230.2498,
          PLN: 4.31735,
          PYG: 7387.418895,
          QAR: 3.640982,
          RON: 4.4835,
          RSD: 107.409716,
          RUB: 69.229857,
          RWF: 1080,
          SAR: 3.754242,
          SBD: 8.247738,
          SCR: 14.212435,
          SDG: 576.505819,
          SEK: 10.19681,
          SGD: 1.31193,
          SHP: 1.377399,
          SLE: 19.349074,
          SLL: 19264.999617,
          SOS: 569.496338,
          SRD: 32.286499,
          STD: 20697.981008,
          SVC: 8.751082,
          SYP: 2511.947267,
          SZL: 17.220342,
          THB: 32.748498,
          TJS: 10.270897,
          TMT: 3.51,
          TND: 3.090151,
          TOP: 2.31305,
          TRY: 18.808956,
          TTD: 6.801395,
          TWD: 30.262595,
          TZS: 2332.000235,
          UAH: 36.75465,
          UGX: 3685.358714,
          USD: 1,
          UYU: 39.177212,
          UZS: 11295.000098,
          VEF: 2109824.739896,
          VES: 21.147932,
          VND: 23450,
          VUV: 115.861457,
          WST: 2.663803,
          XAF: 603.706226,
          XAG: 0.041725,
          XAU: 0.000513,
          XCD: 2.70255,
          XDR: 0.741061,
          XOF: 601.487483,
          XPF: 112.089904,
          YER: 250.374991,
          ZAR: 17.12574,
          ZMK: 9001.199915,
          ZMW: 18.821775,
          ZWL: 321.999592,
        },
      },
      {
        base: "USD",
        date: "2023-01-24",
        rates: {
          AED: 3.673094,
          AFN: 89.480379,
          ALL: 106.881891,
          AMD: 396.392422,
          ANG: 1.802386,
          AOA: 503.173025,
          ARS: 184.61701,
          AUD: 1.41917,
          AWG: 1.8,
          AZN: 1.696461,
          BAM: 1.799871,
          BBD: 2.019197,
          BDT: 106.604885,
          BGN: 1.796487,
          BHD: 0.37697,
          BIF: 2062.5,
          BMD: 1,
          BND: 1.319597,
          BOB: 6.910327,
          BRL: 5.139899,
          BSD: 1.000074,
          BTC: 4.4180849e-5,
          BTN: 81.663507,
          BWP: 12.804811,
          BYN: 2.52418,
          BYR: 19600,
          BZD: 2.015736,
          CAD: 1.337545,
          CDF: 2039.999842,
          CHF: 0.922505,
          CLF: 0.029045,
          CLP: 801.449721,
          CNY: 6.784504,
          COP: 4516.88,
          CRC: 563.324805,
          CUC: 1,
          CUP: 26.5,
          CVE: 101.625002,
          CZK: 21.926901,
          DJF: 177.719736,
          DKK: 6.8323,
          DOP: 56.559914,
          DZD: 135.772963,
          EGP: 29.847905,
          ERN: 15,
          ETB: 53.644872,
          EUR: 0.91846,
          FJD: 2.15805,
          FKP: 0.808671,
          GBP: 0.811125,
          GEL: 2.644982,
          GGP: 0.808671,
          GHS: 12.531123,
          GIP: 0.808671,
          GMD: 61.749868,
          GNF: 8784.999798,
          GTQ: 7.850435,
          GYD: 211.0131,
          HKD: 7.83175,
          HNL: 24.670317,
          HRK: 7.042198,
          HTG: 149.002899,
          HUF: 357.689435,
          IDR: 14955.55,
          ILS: 3.36388,
          IMP: 0.808671,
          INR: 81.60215,
          IQD: 1459.562681,
          IRR: 42175.000023,
          ISK: 143.740296,
          JEP: 0.808671,
          JMD: 154.172572,
          JOD: 0.709301,
          JPY: 130.241987,
          KES: 124.304144,
          KGS: 86.049951,
          KHR: 4115.999425,
          KMF: 452.850109,
          KPW: 900.03195,
          KRW: 1233.160054,
          KWD: 0.30531,
          KYD: 0.833322,
          KZT: 461.679336,
          LAK: 16839.999785,
          LBP: 1515.499426,
          LKR: 364.016768,
          LRD: 156.503349,
          LSL: 17.220014,
          LTL: 2.95274,
          LVL: 0.60489,
          LYD: 4.758241,
          MAD: 10.145221,
          MDL: 18.875872,
          MGA: 4267.703492,
          MKD: 56.598401,
          MMK: 2100.096814,
          MNT: 3463.861334,
          MOP: 8.063905,
          MRO: 356.999828,
          MUR: 44.296888,
          MVR: 15.370114,
          MWK: 1026.482119,
          MXN: 18.798597,
          MYR: 4.284497,
          MZN: 63.830018,
          NAD: 17.219755,
          NGN: 455.070191,
          NIO: 36.541921,
          NOK: 9.871065,
          NPR: 130.662053,
          NZD: 1.54023,
          OMR: 0.385002,
          PAB: 0.999982,
          PEN: 3.874877,
          PGK: 3.524415,
          PHP: 54.705013,
          PKR: 231.510648,
          PLN: 4.329136,
          PYG: 7437.629411,
          QAR: 3.641002,
          RON: 4.514197,
          RSD: 107.790279,
          RUB: 69.450139,
          RWF: 1077.589222,
          SAR: 3.753588,
          SBD: 8.247738,
          SCR: 13.519944,
          SDG: 576.502679,
          SEK: 10.21172,
          SGD: 1.319845,
          SHP: 1.3774,
          SLE: 19.330153,
          SLL: 19199.999909,
          SOS: 568.501353,
          SRD: 32.286498,
          STD: 20697.981008,
          SVC: 8.750109,
          SYP: 2512.559048,
          SZL: 17.269686,
          THB: 32.779623,
          TJS: 10.255471,
          TMT: 3.51,
          TND: 3.091976,
          TOP: 2.315749,
          TRY: 18.808598,
          TTD: 6.789126,
          TWD: 30.282502,
          TZS: 2332.000138,
          UAH: 36.918886,
          UGX: 3675.056366,
          USD: 1,
          UYU: 39.166586,
          UZS: 11270.88759,
          VEF: 2041740.05382,
          VES: 21.147932,
          VND: 23450,
          VUV: 116.175795,
          WST: 2.671951,
          XAF: 603.667335,
          XAG: 0.042319,
          XAU: 0.000516,
          XCD: 2.70255,
          XDR: 0.740645,
          XOF: 603.650669,
          XPF: 112.089553,
          YER: 250.374961,
          ZAR: 17.10851,
          ZMK: 9001.187652,
          ZMW: 18.76538,
          ZWL: 321.999592,
        },
      },
      {
        base: "USD",
        date: "2023-01-23",
        rates: {
          AED: 3.673097,
          AFN: 88.999724,
          ALL: 107.050142,
          AMD: 396.659488,
          ANG: 1.803476,
          AOA: 503.172796,
          ARS: 184.375203,
          AUD: 1.422617,
          AWG: 1.8025,
          AZN: 1.700838,
          BAM: 1.798663,
          BBD: 2.020406,
          BDT: 106.182751,
          BGN: 1.798797,
          BHD: 0.377001,
          BIF: 2062.5,
          BMD: 1,
          BND: 1.319551,
          BOB: 6.914694,
          BRL: 5.208199,
          BSD: 1.000639,
          BTC: 4.3628878e-5,
          BTN: 81.403833,
          BWP: 12.763598,
          BYN: 2.525796,
          BYR: 19600,
          BZD: 2.017004,
          CAD: 1.336965,
          CDF: 2038.498539,
          CHF: 0.921605,
          CLF: 0.029474,
          CLP: 813.280307,
          CNY: 6.784498,
          COP: 4546.5,
          CRC: 563.672229,
          CUC: 1,
          CUP: 26.5,
          CVE: 101.874959,
          CZK: 21.954203,
          DJF: 177.719689,
          DKK: 6.84206,
          DOP: 56.549546,
          DZD: 135.967981,
          EGP: 29.872395,
          ERN: 15,
          ETB: 53.549923,
          EUR: 0.91978,
          FJD: 2.159901,
          FKP: 0.807063,
          GBP: 0.807715,
          GEL: 2.644993,
          GGP: 0.807063,
          GHS: 12.401824,
          GIP: 0.807063,
          GMD: 62.474989,
          GNF: 8785.000128,
          GTQ: 7.855143,
          GYD: 209.351622,
          HKD: 7.831355,
          HNL: 24.70203,
          HRK: 7.042198,
          HTG: 148.363957,
          HUF: 363.684962,
          IDR: 15026.3,
          ILS: 3.36576,
          IMP: 0.807063,
          INR: 81.48835,
          IQD: 1460,
          IRR: 41899.999975,
          ISK: 142.489751,
          JEP: 0.807063,
          JMD: 153.49004,
          JOD: 0.709899,
          JPY: 130.557008,
          KES: 124.250003,
          KGS: 86.1391,
          KHR: 4115.000543,
          KMF: 453.549635,
          KPW: 900,
          KRW: 1230.280232,
          KWD: 0.30544,
          KYD: 0.833912,
          KZT: 462.359757,
          LAK: 16850.000309,
          LBP: 1522.00014,
          LKR: 364.24948,
          LRD: 156.501852,
          LSL: 17.220164,
          LTL: 2.952739,
          LVL: 0.60489,
          LYD: 4.755021,
          MAD: 10.18575,
          MDL: 18.932592,
          MGA: 4314.999921,
          MKD: 56.681968,
          MMK: 2101.438963,
          MNT: 3481.110489,
          MOP: 8.069396,
          MRO: 356.999828,
          MUR: 44.349781,
          MVR: 15.369976,
          MWK: 1018.999961,
          MXN: 18.81365,
          MYR: 4.284499,
          MZN: 63.83005,
          NAD: 17.219759,
          NGN: 455.059924,
          NIO: 36.450117,
          NOK: 9.820615,
          NPR: 130.246096,
          NZD: 1.540785,
          OMR: 0.38503,
          PAB: 1.000639,
          PEN: 3.872506,
          PGK: 3.519861,
          PHP: 54.364985,
          PKR: 230.25041,
          PLN: 4.333657,
          PYG: 7443.396053,
          QAR: 3.640979,
          RON: 4.521298,
          RSD: 107.974987,
          RUB: 68.979888,
          RWF: 1080,
          SAR: 3.754404,
          SBD: 8.247738,
          SCR: 14.118913,
          SDG: 576.495368,
          SEK: 10.238404,
          SGD: 1.318295,
          SHP: 1.377402,
          SLE: 19.20006,
          SLL: 19200.00026,
          SOS: 568.501257,
          SRD: 32.176976,
          STD: 20697.981008,
          SVC: 8.755627,
          SYP: 2512.530839,
          SZL: 17.220135,
          THB: 32.729665,
          TJS: 10.261817,
          TMT: 3.51,
          TND: 3.095011,
          TOP: 2.32005,
          TRY: 18.797896,
          TTD: 6.793485,
          TWD: 30.308044,
          TZS: 2332.000165,
          UAH: 36.956134,
          UGX: 3677.470676,
          USD: 1,
          UYU: 39.19073,
          UZS: 11295.000249,
          VEF: 2024935.149757,
          VES: 21.488483,
          VND: 23450,
          VUV: 116.009639,
          WST: 2.674506,
          XAF: 603.225999,
          XAG: 0.042726,
          XAU: 0.000518,
          XCD: 2.70255,
          XDR: 0.742219,
          XOF: 601.497895,
          XPF: 112.090012,
          YER: 250.374993,
          ZAR: 17.180503,
          ZMK: 9001.207518,
          ZMW: 18.667378,
          ZWL: 321.999592,
        },
      },
      {
        base: "USD",
        date: "2023-01-22",
        rates: {
          AED: 3.67302,
          AFN: 88.913931,
          ALL: 107.427368,
          AMD: 396.249813,
          ANG: 1.802017,
          AOA: 503.561019,
          ARS: 183.315799,
          AUD: 1.43218,
          AWG: 1.8,
          AZN: 1.704367,
          BAM: 1.806463,
          BBD: 2.018833,
          BDT: 105.637343,
          BGN: 1.801845,
          BHD: 0.376945,
          BIF: 2073.920473,
          BMD: 1,
          BND: 1.322163,
          BOB: 6.908878,
          BRL: 5.207799,
          BSD: 0.999857,
          BTC: 4.3999537e-5,
          BTN: 81.146016,
          BWP: 12.802553,
          BYN: 2.524188,
          BYR: 19600,
          BZD: 2.015416,
          CAD: 1.337165,
          CDF: 2028.495038,
          CHF: 0.919965,
          CLF: 0.029547,
          CLP: 815.300953,
          CNY: 6.783604,
          COP: 4648.58,
          CRC: 563.217276,
          CUC: 1,
          CUP: 26.5,
          CVE: 101.842145,
          CZK: 22.00275,
          DJF: 177.720016,
          DKK: 6.8479,
          DOP: 56.753062,
          DZD: 135.992152,
          EGP: 29.813064,
          ERN: 15,
          ETB: 53.628341,
          EUR: 0.92042,
          FJD: 2.174898,
          FKP: 0.807025,
          GBP: 0.8061,
          GEL: 2.650404,
          GGP: 0.807025,
          GHS: 12.297646,
          GIP: 0.807025,
          GMD: 62.349778,
          GNF: 8612.318221,
          GTQ: 7.849014,
          GYD: 209.186972,
          HKD: 7.827565,
          HNL: 24.667012,
          HRK: 7.042197,
          HTG: 148.292106,
          HUF: 362.265056,
          IDR: 15063.3,
          ILS: 3.401697,
          IMP: 0.807025,
          INR: 80.97625,
          IQD: 1459.320907,
          IRR: 41849.99993,
          ISK: 142.329787,
          JEP: 0.807025,
          JMD: 153.366832,
          JOD: 0.709303,
          JPY: 129.357502,
          KES: 124.103435,
          KGS: 86.079897,
          KHR: 4116.495169,
          KMF: 454.824996,
          KPW: 900.000027,
          KRW: 1231.030459,
          KWD: 0.30558,
          KYD: 0.833256,
          KZT: 462.766866,
          LAK: 16858.541542,
          LBP: 1511.917352,
          LKR: 365.956106,
          LRD: 156.350046,
          LSL: 17.280506,
          LTL: 2.95274,
          LVL: 0.60489,
          LYD: 4.765023,
          MAD: 10.200717,
          MDL: 18.947738,
          MGA: 4270.669678,
          MKD: 56.908917,
          MMK: 2099.748019,
          MNT: 3480.935301,
          MOP: 8.066524,
          MRO: 356.999828,
          MUR: 43.889766,
          MVR: 15.350103,
          MWK: 1018.635497,
          MXN: 18.881285,
          MYR: 4.285499,
          MZN: 63.830353,
          NAD: 17.280437,
          NGN: 455.059533,
          NIO: 36.533923,
          NOK: 9.86685,
          NPR: 129.833182,
          NZD: 1.54123,
          OMR: 0.384996,
          PAB: 0.999857,
          PEN: 3.861466,
          PGK: 3.523826,
          PHP: 54.460228,
          PKR: 229.847407,
          PLN: 4.3325,
          PYG: 7424.026898,
          QAR: 3.64096,
          RON: 4.543795,
          RSD: 108.114998,
          RUB: 68.250312,
          RWF: 1075.095601,
          SAR: 3.756301,
          SBD: 8.265027,
          SCR: 13.769408,
          SDG: 573.502853,
          SEK: 10.287345,
          SGD: 1.321025,
          SHP: 1.377402,
          SLE: 19.20005,
          SLL: 19185.000109,
          SOS: 569.499688,
          SRD: 32.418499,
          STD: 20697.981008,
          SVC: 8.748782,
          SYP: 2512.525113,
          SZL: 17.242892,
          THB: 32.735047,
          TJS: 10.247944,
          TMT: 3.5,
          TND: 3.085501,
          TOP: 2.328699,
          TRY: 18.80745,
          TTD: 6.788988,
          TWD: 30.332701,
          TZS: 2334.999931,
          UAH: 36.738387,
          UGX: 3677.339208,
          USD: 1,
          UYU: 39.250152,
          UZS: 11307.801366,
          VEF: 2024935.136303,
          VES: 19.959523,
          VND: 23450,
          VUV: 116.009645,
          WST: 2.674506,
          XAF: 605.892133,
          XAG: 0.041676,
          XAU: 0.000519,
          XCD: 2.70255,
          XDR: 0.741066,
          XOF: 605.855758,
          XPF: 110.494362,
          YER: 250.349587,
          ZAR: 17.122502,
          ZMK: 9001.198954,
          ZMW: 18.546669,
          ZWL: 321.999592,
        },
      },
      {
        base: "USD",
        date: "2023-01-21",
        rates: {
          AED: 3.673104,
          AFN: 88.913931,
          ALL: 107.427368,
          AMD: 396.250403,
          ANG: 1.802017,
          AOA: 503.561041,
          ARS: 183.099242,
          AUD: 1.436404,
          AWG: 1.8,
          AZN: 1.70397,
          BAM: 1.806463,
          BBD: 2.018833,
          BDT: 105.637343,
          BGN: 1.801845,
          BHD: 0.376945,
          BIF: 2073.920473,
          BMD: 1,
          BND: 1.322163,
          BOB: 6.908878,
          BRL: 5.207804,
          BSD: 0.999857,
          BTC: 4.3902156e-5,
          BTN: 81.146016,
          BWP: 12.802553,
          BYN: 2.524188,
          BYR: 19600,
          BZD: 2.015416,
          CAD: 1.33935,
          CDF: 2028.50392,
          CHF: 0.92001,
          CLF: 0.029547,
          CLP: 815.303912,
          CNY: 6.783604,
          COP: 4648.58,
          CRC: 563.217276,
          CUC: 1,
          CUP: 26.5,
          CVE: 101.842145,
          CZK: 21.98605,
          DJF: 177.720394,
          DKK: 6.853304,
          DOP: 56.753062,
          DZD: 135.992152,
          EGP: 29.777982,
          ERN: 15,
          ETB: 53.628341,
          EUR: 0.91935,
          FJD: 2.174904,
          FKP: 0.808336,
          GBP: 0.807168,
          GEL: 2.650391,
          GGP: 0.808336,
          GHS: 12.297646,
          GIP: 0.808336,
          GMD: 62.35039,
          GNF: 8612.318221,
          GTQ: 7.849014,
          GYD: 209.186972,
          HKD: 7.83475,
          HNL: 24.667012,
          HRK: 7.042204,
          HTG: 148.292106,
          HUF: 361.730388,
          IDR: 15063.3,
          ILS: 3.401804,
          IMP: 0.808336,
          INR: 80.97625,
          IQD: 1459.320907,
          IRR: 41850.000352,
          ISK: 142.330386,
          JEP: 0.808336,
          JMD: 153.366832,
          JOD: 0.709304,
          JPY: 129.55504,
          KES: 124.103804,
          KGS: 86.079904,
          KHR: 4116.495169,
          KMF: 454.825039,
          KPW: 900.030007,
          KRW: 1231.030384,
          KWD: 0.30558,
          KYD: 0.833256,
          KZT: 462.766866,
          LAK: 16858.541542,
          LBP: 1511.917352,
          LKR: 365.956106,
          LRD: 156.350382,
          LSL: 17.280382,
          LTL: 2.95274,
          LVL: 0.60489,
          LYD: 4.765023,
          MAD: 10.200717,
          MDL: 18.947738,
          MGA: 4270.669678,
          MKD: 56.908917,
          MMK: 2099.748019,
          MNT: 3459.539803,
          MOP: 8.066524,
          MRO: 356.999828,
          MUR: 43.890379,
          MVR: 15.350378,
          MWK: 1018.635497,
          MXN: 18.874804,
          MYR: 4.285504,
          MZN: 63.830377,
          NAD: 17.280377,
          NGN: 455.060377,
          NIO: 36.533923,
          NOK: 9.79287,
          NPR: 129.833182,
          NZD: 1.545118,
          OMR: 0.384996,
          PAB: 0.999857,
          PEN: 3.861466,
          PGK: 3.523826,
          PHP: 54.460375,
          PKR: 229.847407,
          PLN: 4.13765,
          PYG: 7424.026898,
          QAR: 3.641038,
          RON: 4.525604,
          RSD: 108.115038,
          RUB: 68.250373,
          RWF: 1075.095601,
          SAR: 3.753851,
          SBD: 8.265027,
          SCR: 13.769408,
          SDG: 573.503678,
          SEK: 10.295504,
          SGD: 1.319704,
          SHP: 1.377404,
          SLE: 19.199401,
          SLL: 19185.000338,
          SOS: 569.503664,
          SRD: 32.418504,
          STD: 20697.981008,
          SVC: 8.748782,
          SYP: 2512.44523,
          SZL: 17.242892,
          THB: 32.640369,
          TJS: 10.247944,
          TMT: 3.5,
          TND: 3.085504,
          TOP: 2.328704,
          TRY: 18.757904,
          TTD: 6.788988,
          TWD: 30.332704,
          TZS: 2335.000335,
          UAH: 36.738387,
          UGX: 3677.339208,
          USD: 1,
          UYU: 39.250152,
          UZS: 11307.801366,
          VEF: 2024824.703668,
          VES: 19.959523,
          VND: 23450,
          VUV: 116.93671,
          WST: 2.682994,
          XAF: 605.892133,
          XAG: 0.041768,
          XAU: 0.000519,
          XCD: 2.70255,
          XDR: 0.741066,
          XOF: 605.855758,
          XPF: 110.503595,
          YER: 250.350363,
          ZAR: 17.115204,
          ZMK: 9001.203589,
          ZMW: 18.546669,
          ZWL: 321.999592,
        },
      },
      {
        base: "USD",
        date: "2023-01-20",
        rates: {
          AED: 3.673104,
          AFN: 88.913931,
          ALL: 107.427368,
          AMD: 396.250403,
          ANG: 1.802017,
          AOA: 503.561041,
          ARS: 183.099242,
          AUD: 1.436369,
          AWG: 1.8,
          AZN: 1.70397,
          BAM: 1.806463,
          BBD: 2.018833,
          BDT: 105.637343,
          BGN: 1.801845,
          BHD: 0.376945,
          BIF: 2073.920473,
          BMD: 1,
          BND: 1.322163,
          BOB: 6.908878,
          BRL: 5.207904,
          BSD: 0.999857,
          BTC: 4.4065362e-5,
          BTN: 81.146016,
          BWP: 12.802553,
          BYN: 2.524188,
          BYR: 19600,
          BZD: 2.015416,
          CAD: 1.33925,
          CDF: 2028.50392,
          CHF: 0.92001,
          CLF: 0.029547,
          CLP: 815.303912,
          CNY: 6.783804,
          COP: 4648.58,
          CRC: 563.217276,
          CUC: 1,
          CUP: 26.5,
          CVE: 101.842145,
          CZK: 21.987104,
          DJF: 177.720394,
          DKK: 6.853304,
          DOP: 56.753062,
          DZD: 135.992152,
          EGP: 29.777982,
          ERN: 15,
          ETB: 53.628341,
          EUR: 0.919404,
          FJD: 2.174904,
          FKP: 0.808764,
          GBP: 0.807168,
          GEL: 2.650391,
          GGP: 0.808764,
          GHS: 12.297646,
          GIP: 0.808764,
          GMD: 62.35039,
          GNF: 8612.318221,
          GTQ: 7.849014,
          GYD: 209.186972,
          HKD: 7.83465,
          HNL: 24.667012,
          HRK: 7.042204,
          HTG: 148.292106,
          HUF: 361.75304,
          IDR: 15063.2,
          ILS: 3.401804,
          IMP: 0.808764,
          INR: 80.97625,
          IQD: 1459.320907,
          IRR: 41850.000352,
          ISK: 142.330386,
          JEP: 0.808764,
          JMD: 153.366832,
          JOD: 0.709304,
          JPY: 129.55504,
          KES: 124.103804,
          KGS: 86.079904,
          KHR: 4116.495169,
          KMF: 454.825039,
          KPW: 900.006704,
          KRW: 1231.030384,
          KWD: 0.30558,
          KYD: 0.833256,
          KZT: 462.766866,
          LAK: 16858.541542,
          LBP: 1511.917352,
          LKR: 365.956106,
          LRD: 156.350382,
          LSL: 17.280382,
          LTL: 2.95274,
          LVL: 0.60489,
          LYD: 4.765023,
          MAD: 10.200717,
          MDL: 18.947738,
          MGA: 4270.669678,
          MKD: 56.908917,
          MMK: 2099.748019,
          MNT: 3463.164865,
          MOP: 8.066524,
          MRO: 356.999828,
          MUR: 43.890379,
          MVR: 15.350378,
          MWK: 1018.635497,
          MXN: 18.875039,
          MYR: 4.285504,
          MZN: 63.830377,
          NAD: 17.280377,
          NGN: 455.060377,
          NIO: 36.533923,
          NOK: 9.79287,
          NPR: 129.833182,
          NZD: 1.545118,
          OMR: 0.384996,
          PAB: 0.999857,
          PEN: 3.861466,
          PGK: 3.523826,
          PHP: 54.430504,
          PKR: 229.847407,
          PLN: 4.137624,
          PYG: 7424.026898,
          QAR: 3.641038,
          RON: 4.525604,
          RSD: 108.115038,
          RUB: 69.124038,
          RWF: 1075.095601,
          SAR: 3.753851,
          SBD: 8.265027,
          SCR: 13.769408,
          SDG: 573.503678,
          SEK: 10.294904,
          SGD: 1.319704,
          SHP: 1.377404,
          SLE: 19.200417,
          SLL: 19185.000338,
          SOS: 569.503664,
          SRD: 32.418504,
          STD: 20697.981008,
          SVC: 8.748782,
          SYP: 2511.9486,
          SZL: 17.242892,
          THB: 32.640369,
          TJS: 10.247944,
          TMT: 3.5,
          TND: 3.085504,
          TOP: 2.328704,
          TRY: 18.757904,
          TTD: 6.788988,
          TWD: 30.332804,
          TZS: 2335.000335,
          UAH: 36.738387,
          UGX: 3677.339208,
          USD: 1,
          UYU: 39.250152,
          UZS: 11307.801366,
          VEF: 1994917.458852,
          VES: 19.959523,
          VND: 23450,
          VUV: 116.240017,
          WST: 2.675591,
          XAF: 605.892133,
          XAG: 0.041768,
          XAU: 0.000519,
          XCD: 2.70255,
          XDR: 0.741066,
          XOF: 605.855758,
          XPF: 110.503595,
          YER: 250.350363,
          ZAR: 17.11059,
          ZMK: 9001.203589,
          ZMW: 18.546669,
          ZWL: 321.999592,
        },
      },
      {
        base: "USD",
        date: "2023-01-19",
        rates: {
          AED: 3.6731,
          AFN: 89.000482,
          ALL: 107.749769,
          AMD: 396.370055,
          ANG: 1.802271,
          AOA: 503.561035,
          ARS: 183.110099,
          AUD: 1.44655,
          AWG: 1.8,
          AZN: 1.705751,
          BAM: 1.807272,
          BBD: 2.019128,
          BDT: 103.810017,
          BGN: 1.805525,
          BHD: 0.377062,
          BIF: 2065,
          BMD: 1,
          BND: 1.325276,
          BOB: 6.909998,
          BRL: 5.174503,
          BSD: 1,
          BTC: 4.7443445e-5,
          BTN: 81.339956,
          BWP: 12.787932,
          BYN: 2.524118,
          BYR: 19600,
          BZD: 2.015709,
          CAD: 1.346205,
          CDF: 2028.502909,
          CHF: 0.91582,
          CLF: 0.029854,
          CLP: 823.750157,
          CNY: 6.7769,
          COP: 4669.2,
          CRC: 564.026982,
          CUC: 1,
          CUP: 26.5,
          CVE: 102.424974,
          CZK: 22.080898,
          DJF: 177.719764,
          DKK: 6.866502,
          DOP: 56.649706,
          DZD: 136.063637,
          EGP: 29.844503,
          ERN: 15,
          ETB: 53.580132,
          EUR: 0.92293,
          FJD: 2.17685,
          FKP: 0.80868,
          GBP: 0.80685,
          GEL: 2.649959,
          GGP: 0.80868,
          GHS: 12.350357,
          GIP: 0.80868,
          GMD: 62.349869,
          GNF: 8789.999838,
          GTQ: 7.8501,
          GYD: 209.212715,
          HKD: 7.829265,
          HNL: 24.67978,
          HRK: 7.042202,
          HTG: 149.500092,
          HUF: 363.949747,
          IDR: 15146,
          ILS: 3.42064,
          IMP: 0.80868,
          INR: 81.24005,
          IQD: 1461,
          IRR: 41850.00002,
          ISK: 142.589971,
          JEP: 0.80868,
          JMD: 153.276227,
          JOD: 0.7093,
          JPY: 128.387995,
          KES: 124.149712,
          KGS: 85.8889,
          KHR: 4117.999889,
          KMF: 454.824993,
          KPW: 900.07251,
          KRW: 1232.554986,
          KWD: 0.30529,
          KYD: 0.83331,
          KZT: 463.652409,
          LAK: 16865.00029,
          LBP: 1522.000086,
          LKR: 367.003576,
          LRD: 156.350218,
          LSL: 17.279994,
          LTL: 2.95274,
          LVL: 0.60489,
          LYD: 4.764942,
          MAD: 10.171497,
          MDL: 18.964979,
          MGA: 4310.000094,
          MKD: 56.892627,
          MMK: 2100.01959,
          MNT: 3454.345418,
          MOP: 8.065404,
          MRO: 356.999828,
          MUR: 44.350476,
          MVR: 15.349782,
          MWK: 1017.495699,
          MXN: 18.997202,
          MYR: 4.308497,
          MZN: 63.830006,
          NAD: 17.279494,
          NGN: 460.508216,
          NIO: 36.470028,
          NOK: 9.90715,
          NPR: 130.145353,
          NZD: 1.563755,
          OMR: 0.385045,
          PAB: 1,
          PEN: 3.853499,
          PGK: 3.525015,
          PHP: 54.730945,
          PKR: 229.198173,
          PLN: 4.357218,
          PYG: 7412.400665,
          QAR: 3.640947,
          RON: 4.542495,
          RSD: 108.369754,
          RUB: 69.394157,
          RWF: 1076.5,
          SAR: 3.755647,
          SBD: 8.265027,
          SCR: 13.363294,
          SDG: 573.506935,
          SEK: 10.312404,
          SGD: 1.321399,
          SHP: 1.377406,
          SLE: 19.200699,
          SLL: 19184.999498,
          SOS: 569.50234,
          SRD: 31.725016,
          STD: 20697.981008,
          SVC: 8.749769,
          SYP: 2512.609171,
          SZL: 17.280356,
          THB: 32.890073,
          TJS: 10.245059,
          TMT: 3.5,
          TND: 3.085502,
          TOP: 2.323798,
          TRY: 18.79697,
          TTD: 6.793569,
          TWD: 30.35398,
          TZS: 2331.999889,
          UAH: 36.751432,
          UGX: 3680.003696,
          USD: 1,
          UYU: 39.600255,
          UZS: 11294.999986,
          VEF: 1992910.328289,
          VES: 19.959523,
          VND: 23450,
          VUV: 116.340711,
          WST: 2.675096,
          XAF: 606.138478,
          XAG: 0.041891,
          XAU: 0.000518,
          XCD: 2.70255,
          XDR: 0.740813,
          XOF: 606.497882,
          XPF: 110.50394,
          YER: 250.350304,
          ZAR: 17.2916,
          ZMK: 9001.193911,
          ZMW: 18.699963,
          ZWL: 321.999592,
        },
      },
      {
        base: "USD",
        date: "2023-01-18",
        rates: {
          AED: 3.673104,
          AFN: 88.498703,
          ALL: 107.749593,
          AMD: 395.756713,
          ANG: 1.801195,
          AOA: 503.560996,
          ARS: 182.783701,
          AUD: 1.439957,
          AWG: 1.8,
          AZN: 1.692558,
          BAM: 1.807339,
          BBD: 2.017798,
          BDT: 106.436192,
          BGN: 1.811498,
          BHD: 0.377044,
          BIF: 2063,
          BMD: 1,
          BND: 1.317125,
          BOB: 6.905477,
          BRL: 5.186803,
          BSD: 0.999381,
          BTC: 4.8345842e-5,
          BTN: 81.206454,
          BWP: 12.714126,
          BYN: 2.52301,
          BYR: 19600,
          BZD: 2.014379,
          CAD: 1.349435,
          CDF: 2027.000185,
          CHF: 0.915925,
          CLF: 0.029863,
          CLP: 823.999762,
          CNY: 6.748095,
          COP: 4703.48,
          CRC: 564.680226,
          CUC: 1,
          CUP: 26.5,
          CVE: 102.550257,
          CZK: 22.19455,
          DJF: 177.719683,
          DKK: 6.89053,
          DOP: 56.649709,
          DZD: 135.931907,
          EGP: 29.643098,
          ERN: 15,
          ETB: 53.601751,
          EUR: 0.92616,
          FJD: 2.170698,
          FKP: 0.815562,
          GBP: 0.80975,
          GEL: 2.649649,
          GGP: 0.815562,
          GHS: 12.292106,
          GIP: 0.815562,
          GMD: 62.309086,
          GNF: 8862.492461,
          GTQ: 7.842391,
          GYD: 209.082802,
          HKD: 7.82515,
          HNL: 0.033839,
          HRK: 7.042202,
          HTG: 149.399828,
          HUF: 365.775025,
          IDR: 15129,
          ILS: 3.37625,
          IMP: 0.815562,
          INR: 81.43625,
          IQD: 1460,
          IRR: 41900.000117,
          ISK: 143.11917,
          JEP: 0.815562,
          JMD: 152.836535,
          JOD: 0.7093,
          JPY: 128.582504,
          KES: 124.09364,
          KGS: 85.680423,
          KHR: 4115.000188,
          KMF: 456.225042,
          KPW: 899.933122,
          KRW: 1238.254996,
          KWD: 0.305299,
          KYD: 0.833041,
          KZT: 463.755527,
          LAK: 16864.999618,
          LBP: 1523.000181,
          LKR: 363.760003,
          LRD: 155.56068,
          LSL: 17.140258,
          LTL: 2.95274,
          LVL: 0.60489,
          LYD: 4.770173,
          MAD: 13.679784,
          MDL: 18.957475,
          MGA: 4339.999976,
          MKD: 57.091366,
          MMK: 2098.614186,
          MNT: 3467.108382,
          MOP: 8.055132,
          MRO: 356.999828,
          MUR: 44.454737,
          MVR: 15.389882,
          MWK: 1012.504543,
          MXN: 18.87407,
          MYR: 4.313499,
          MZN: 63.829796,
          NAD: 17.14023,
          NGN: 453.579887,
          NIO: 36.296437,
          NOK: 9.91865,
          NPR: 129.930326,
          NZD: 1.55155,
          OMR: 0.385025,
          PAB: 0.999658,
          PEN: 3.83225,
          PGK: 3.520059,
          PHP: 54.694988,
          PKR: 228.772936,
          PLN: 4.37804,
          PYG: 7402.396991,
          QAR: 3.641501,
          RON: 4.573007,
          RSD: 108.679992,
          RUB: 68.750255,
          RWF: 1077,
          SAR: 3.755849,
          SBD: 8.278713,
          SCR: 14.231533,
          SDG: 579.505905,
          SEK: 10.331315,
          SGD: 1.320135,
          SHP: 1.377397,
          SLE: 19.174207,
          SLL: 19185.000106,
          SOS: 568.502326,
          SRD: 31.728505,
          STD: 20697.981008,
          SVC: 8.74732,
          SYP: 2511.870093,
          SZL: 17.14021,
          THB: 32.999628,
          TJS: 10.213368,
          TMT: 3.51,
          TND: 3.10498,
          TOP: 2.32325,
          TRY: 18.78305,
          TTD: 6.777863,
          TWD: 30.204976,
          TZS: 2331.999609,
          UAH: 36.727254,
          UGX: 3675.577774,
          USD: 1,
          UYU: 39.679166,
          UZS: 11304.999731,
          VEF: 1963552.376841,
          VES: 19.947533,
          VND: 23442,
          VUV: 116.59597,
          WST: 2.677679,
          XAF: 606.172087,
          XAG: 0.042557,
          XAU: 0.000524,
          XCD: 2.70255,
          XDR: 0.741131,
          XOF: 606.509923,
          XPF: 110.950093,
          YER: 250.4106,
          ZAR: 17.125299,
          ZMK: 9001.184213,
          ZMW: 18.462996,
          ZWL: 321.999592,
        },
      },
      {
        base: "USD",
        date: "2023-01-17",
        rates: {
          AED: 3.67295,
          AFN: 89.56157,
          ALL: 107.850025,
          AMD: 396.840217,
          ANG: 1.803835,
          AOA: 503.561202,
          ARS: 182.501762,
          AUD: 1.431311,
          AWG: 1.8025,
          AZN: 1.689175,
          BAM: 1.807189,
          BBD: 2.020873,
          BDT: 103.941159,
          BGN: 1.813024,
          BHD: 0.377068,
          BIF: 2061,
          BMD: 1,
          BND: 1.323724,
          BOB: 6.916023,
          BRL: 5.1009,
          BSD: 1.000873,
          BTC: 4.7294859e-5,
          BTN: 81.832117,
          BWP: 12.750255,
          BYN: 2.526311,
          BYR: 19600,
          BZD: 2.017454,
          CAD: 1.33945,
          CDF: 2030.000084,
          CHF: 0.92213,
          CLF: 0.029542,
          CLP: 815.150046,
          CNY: 6.773901,
          COP: 4721.2,
          CRC: 571.427251,
          CUC: 1,
          CUP: 26.5,
          CVE: 102.849726,
          CZK: 22.207101,
          DJF: 177.719743,
          DKK: 6.896199,
          DOP: 56.404736,
          DZD: 136.120936,
          EGP: 29.580403,
          ERN: 15,
          ETB: 53.650194,
          EUR: 0.92701,
          FJD: 2.16785,
          FKP: 0.819144,
          GBP: 0.814205,
          GEL: 2.675007,
          GGP: 0.819144,
          GHS: 12.249771,
          GIP: 0.819144,
          GMD: 62.311502,
          GNF: 8775.000007,
          GTQ: 7.854377,
          GYD: 209.397089,
          HKD: 7.8182,
          HNL: 24.634971,
          HRK: 7.042196,
          HTG: 148.12936,
          HUF: 367.798309,
          IDR: 15179.85,
          ILS: 3.403601,
          IMP: 0.819144,
          INR: 81.52665,
          IQD: 1461,
          IRR: 41849.999985,
          ISK: 143.230415,
          JEP: 0.819144,
          JMD: 152.783553,
          JOD: 0.7093,
          JPY: 128.280499,
          KES: 124.050036,
          KGS: 85.679818,
          KHR: 4120.000109,
          KMF: 456.502424,
          KPW: 899.952888,
          KRW: 1237.349923,
          KWD: 0.30526,
          KYD: 0.834099,
          KZT: 464.436847,
          LAK: 16849.999798,
          LBP: 1516.288985,
          LKR: 367.82272,
          LRD: 155.349908,
          LSL: 17.080232,
          LTL: 2.95274,
          LVL: 0.60489,
          LYD: 4.764985,
          MAD: 10.174502,
          MDL: 19.01641,
          MGA: 4359.999818,
          MKD: 57.093746,
          MMK: 2101.843575,
          MNT: 3453.909226,
          MOP: 8.063424,
          MRO: 356.999828,
          MUR: 44.29878,
          MVR: 15.402706,
          MWK: 1020.500203,
          MXN: 18.657398,
          MYR: 4.326502,
          MZN: 63.830293,
          NAD: 17.08016,
          NGN: 453.580506,
          NIO: 36.360104,
          NOK: 9.88801,
          NPR: 130.931536,
          NZD: 1.555097,
          OMR: 0.385023,
          PAB: 1.000873,
          PEN: 3.8275,
          PGK: 3.525004,
          PHP: 54.664981,
          PKR: 228.724968,
          PLN: 4.356414,
          PYG: 7397.089397,
          QAR: 3.641043,
          RON: 4.573701,
          RSD: 108.789957,
          RUB: 68.489175,
          RWF: 1074,
          SAR: 3.758574,
          SBD: 8.265027,
          SCR: 13.79029,
          SDG: 581.999923,
          SEK: 10.430401,
          SGD: 1.320525,
          SHP: 1.377402,
          SLE: 19.109655,
          SLL: 19149.99957,
          SOS: 569.499783,
          SRD: 31.749707,
          STD: 20697.981008,
          SVC: 8.757276,
          SYP: 2511.994346,
          SZL: 17.080037,
          THB: 32.999878,
          TJS: 10.223413,
          TMT: 3.5,
          TND: 3.0575,
          TOP: 2.327598,
          TRY: 18.7776,
          TTD: 6.797197,
          TWD: 30.2905,
          TZS: 2331.999632,
          UAH: 36.783213,
          UGX: 3683.235467,
          USD: 1,
          UYU: 39.69508,
          UZS: 11319.999766,
          VEF: 1923490.793637,
          VES: 19.947502,
          VND: 23432,
          VUV: 116.559535,
          WST: 2.681728,
          XAF: 606.104874,
          XAG: 0.041755,
          XAU: 0.000524,
          XCD: 2.70255,
          XDR: 0.74225,
          XOF: 605.49558,
          XPF: 111.025036,
          YER: 250.249982,
          ZAR: 17.0695,
          ZMK: 9001.208699,
          ZMW: 18.496011,
          ZWL: 321.999592,
        },
      },
      {
        base: "USD",
        date: "2023-01-16",
        rates: {
          AED: 3.673097,
          AFN: 90.0375,
          ALL: 107.53145,
          AMD: 396.802428,
          ANG: 1.80232,
          AOA: 503.770131,
          ARS: 182.178325,
          AUD: 1.435534,
          AWG: 1.8,
          AZN: 1.696759,
          BAM: 1.806504,
          BBD: 2.019249,
          BDT: 103.963848,
          BGN: 1.8071,
          BHD: 0.377012,
          BIF: 2073.725823,
          BMD: 1,
          BND: 1.320267,
          BOB: 6.910295,
          BRL: 5.1475,
          BSD: 1.000037,
          BTC: 4.724331e-5,
          BTN: 81.713003,
          BWP: 12.739364,
          BYN: 2.524153,
          BYR: 19600,
          BZD: 2.015776,
          CAD: 1.33993,
          CDF: 2029.502245,
          CHF: 0.925595,
          CLF: 0.029815,
          CLP: 822.680128,
          CNY: 6.733605,
          COP: 4689.88,
          CRC: 570.95487,
          CUC: 1,
          CUP: 26.5,
          CVE: 101.846378,
          CZK: 22.163033,
          DJF: 177.720363,
          DKK: 6.868201,
          DOP: 56.581937,
          DZD: 136.44015,
          EGP: 29.645698,
          ERN: 15,
          ETB: 53.631149,
          EUR: 0.92338,
          FJD: 2.172099,
          FKP: 0.817625,
          GBP: 0.819215,
          GEL: 2.660253,
          GGP: 0.817625,
          GHS: 12.000443,
          GIP: 0.817625,
          GMD: 62.175035,
          GNF: 8616.278425,
          GTQ: 7.850295,
          GYD: 209.231224,
          HKD: 7.810545,
          HNL: 24.672566,
          HRK: 7.042198,
          HTG: 148.005837,
          HUF: 368.280065,
          IDR: 15107,
          ILS: 3.40905,
          IMP: 0.817625,
          INR: 81.65285,
          IQD: 1459.553322,
          IRR: 42224.999996,
          ISK: 142.659833,
          JEP: 0.817625,
          JMD: 152.920584,
          JOD: 0.709304,
          JPY: 128.323993,
          KES: 123.9159,
          KGS: 85.679866,
          KHR: 4105.166904,
          KMF: 454.825042,
          KPW: 900,
          KRW: 1241.709918,
          KWD: 0.30534,
          KYD: 0.83341,
          KZT: 463.469603,
          LAK: 16868.638354,
          LBP: 1512.293795,
          LKR: 366.013615,
          LRD: 154.875054,
          LSL: 16.819964,
          LTL: 2.95274,
          LVL: 0.60489,
          LYD: 4.758465,
          MAD: 10.131158,
          MDL: 19.040696,
          MGA: 4477.416733,
          MKD: 56.928372,
          MMK: 2100.077771,
          MNT: 3464.97311,
          MOP: 8.045462,
          MRO: 356.999828,
          MUR: 43.689847,
          MVR: 15.395433,
          MWK: 1021.648532,
          MXN: 18.78145,
          MYR: 4.315978,
          MZN: 63.829979,
          NAD: 16.819757,
          NGN: 452.620168,
          NIO: 36.542494,
          NOK: 9.907229,
          NPR: 130.740953,
          NZD: 1.565155,
          OMR: 0.384979,
          PAB: 1.000037,
          PEN: 3.81266,
          PGK: 3.524375,
          PHP: 54.590361,
          PKR: 228.883491,
          PLN: 4.33329,
          PYG: 7403.339922,
          QAR: 3.641023,
          RON: 4.554401,
          RSD: 108.404968,
          RUB: 68.750163,
          RWF: 1070.539227,
          SAR: 3.756013,
          SBD: 8.299327,
          SCR: 13.699048,
          SDG: 582.000296,
          SEK: 10.413401,
          SGD: 1.32014,
          SHP: 1.377399,
          SLE: 19.109948,
          SLL: 19099.999897,
          SOS: 568.498186,
          SRD: 31.674984,
          STD: 20697.981008,
          SVC: 8.75067,
          SYP: 2511.90698,
          SZL: 17.105647,
          THB: 33.029806,
          TJS: 10.210408,
          TMT: 3.51,
          TND: 3.071498,
          TOP: 2.32595,
          TRY: 18.78783,
          TTD: 6.791702,
          TWD: 30.343987,
          TZS: 2331.999829,
          UAH: 36.746347,
          UGX: 3675.114995,
          USD: 1,
          UYU: 39.661574,
          UZS: 11342.563686,
          VEF: 1923545.324402,
          VES: 19.427685,
          VND: 23439,
          VUV: 115.580109,
          WST: 2.672384,
          XAF: 605.875344,
          XAG: 0.041157,
          XAU: 0.000521,
          XCD: 2.70255,
          XDR: 0.741692,
          XOF: 605.875344,
          XPF: 110.624981,
          YER: 250.264817,
          ZAR: 17.05796,
          ZMK: 9001.200308,
          ZMW: 18.425637,
          ZWL: 321.999592,
        },
      },
      {
        base: "USD",
        date: "2023-01-15",
        rates: {
          AED: 3.67303,
          AFN: 89.72669,
          ALL: 106.770521,
          AMD: 394.672074,
          ANG: 1.796193,
          AOA: 503.760367,
          ARS: 181.346254,
          AUD: 1.434105,
          AWG: 1.8,
          AZN: 1.702706,
          BAM: 1.802858,
          BBD: 2.012352,
          BDT: 103.839425,
          BGN: 1.804988,
          BHD: 0.376273,
          BIF: 2066.525326,
          BMD: 1,
          BND: 1.318708,
          BOB: 6.886759,
          BRL: 5.087701,
          BSD: 0.996635,
          BTC: 4.7890416e-5,
          BTN: 81.066323,
          BWP: 12.631608,
          BYN: 2.515647,
          BYR: 19600,
          BZD: 2.008941,
          CAD: 1.339715,
          CDF: 2029.501114,
          CHF: 0.92677,
          CLF: 0.029627,
          CLP: 818.337466,
          CNY: 6.702903,
          COP: 4670.783979,
          CRC: 576.982993,
          CUC: 1,
          CUP: 26.5,
          CVE: 101.64078,
          CZK: 22.148988,
          DJF: 177.425451,
          DKK: 6.87266,
          DOP: 56.499055,
          DZD: 136.451945,
          EGP: 29.564655,
          ERN: 15,
          ETB: 53.444992,
          EUR: 0.92385,
          FJD: 2.174903,
          FKP: 0.817616,
          GBP: 0.817955,
          GEL: 2.659795,
          GGP: 0.817616,
          GHS: 11.959626,
          GIP: 0.817616,
          GMD: 62.175035,
          GNF: 8586.993594,
          GTQ: 7.82357,
          GYD: 208.508089,
          HKD: 7.811145,
          HNL: 24.589575,
          HRK: 7.042201,
          HTG: 148.000184,
          HUF: 366.379701,
          IDR: 15114.85,
          ILS: 3.41525,
          IMP: 0.817616,
          INR: 81.285499,
          IQD: 1454.606628,
          IRR: 42225.000116,
          ISK: 142.439913,
          JEP: 0.817616,
          JMD: 152.310458,
          JOD: 0.709296,
          JPY: 128.030992,
          KES: 123.433286,
          KGS: 85.680058,
          KHR: 4102.133936,
          KMF: 454.824972,
          KPW: 900.000025,
          KRW: 1237.690177,
          KWD: 0.30509,
          KYD: 0.83053,
          KZT: 460.598239,
          LAK: 16814.213947,
          LBP: 1506.936443,
          LKR: 364.768678,
          LRD: 154.875021,
          LSL: 16.820243,
          LTL: 2.95274,
          LVL: 0.604889,
          LYD: 4.768954,
          MAD: 10.055584,
          MDL: 19.050652,
          MGA: 4497.32221,
          MKD: 56.71291,
          MMK: 2092.934691,
          MNT: 3463.592317,
          MOP: 8.018897,
          MRO: 356.999828,
          MUR: 43.697777,
          MVR: 15.396617,
          MWK: 1023.654883,
          MXN: 18.779101,
          MYR: 4.334984,
          MZN: 63.829785,
          NAD: 16.819967,
          NGN: 452.590109,
          NIO: 36.387519,
          NOK: 9.87366,
          NPR: 129.706411,
          NZD: 1.566335,
          OMR: 0.38501,
          PAB: 0.996635,
          PEN: 3.767065,
          PGK: 3.51173,
          PHP: 54.925,
          PKR: 228.030235,
          PLN: 4.335903,
          PYG: 7370.051159,
          QAR: 3.641001,
          RON: 4.565298,
          RSD: 108.180854,
          RUB: 65.999714,
          RWF: 1069.521132,
          SAR: 3.755803,
          SBD: 8.299327,
          SCR: 13.427663,
          SDG: 582.000118,
          SEK: 10.36775,
          SGD: 1.319702,
          SHP: 1.377397,
          SLE: 19.109932,
          SLL: 19100.000163,
          SOS: 568.500127,
          SRD: 31.736503,
          STD: 20697.981008,
          SVC: 8.721021,
          SYP: 2512.028398,
          SZL: 16.836982,
          THB: 32.893497,
          TJS: 10.200581,
          TMT: 3.51,
          TND: 3.071501,
          TOP: 2.324899,
          TRY: 18.788955,
          TTD: 6.768493,
          TWD: 30.294297,
          TZS: 2328.137531,
          UAH: 36.621376,
          UGX: 3657.648523,
          USD: 1,
          UYU: 39.670922,
          UZS: 11279.043186,
          VEF: 1923572.172941,
          VES: 19.427685,
          VND: 23440,
          VUV: 115.580114,
          WST: 2.672384,
          XAF: 604.652256,
          XAG: 0.041174,
          XAU: 0.000522,
          XCD: 2.70255,
          XDR: 0.741393,
          XOF: 604.652256,
          XPF: 110.624963,
          YER: 250.299446,
          ZAR: 16.815006,
          ZMK: 9001.200704,
          ZMW: 18.313223,
          ZWL: 321.999592,
        },
      },
      {
        base: "USD",
        date: "2023-01-14",
        rates: {
          AED: 3.673042,
          AFN: 89.72669,
          ALL: 106.770521,
          AMD: 394.672074,
          ANG: 1.796193,
          AOA: 503.760403,
          ARS: 180.950915,
          AUD: 1.433281,
          AWG: 1.8,
          AZN: 1.70397,
          BAM: 1.802858,
          BBD: 2.012352,
          BDT: 103.839425,
          BGN: 1.804988,
          BHD: 0.376273,
          BIF: 2066.525326,
          BMD: 1,
          BND: 1.318708,
          BOB: 6.886759,
          BRL: 5.087704,
          BSD: 0.996635,
          BTC: 4.7705682e-5,
          BTN: 81.066323,
          BWP: 12.631608,
          BYN: 2.515647,
          BYR: 19600,
          BZD: 2.008941,
          CAD: 1.34015,
          CDF: 2029.50392,
          CHF: 0.927316,
          CLF: 0.029627,
          CLP: 818.337466,
          CNY: 6.702904,
          COP: 4670.783979,
          CRC: 576.982993,
          CUC: 1,
          CUP: 26.5,
          CVE: 101.64078,
          CZK: 22.154904,
          DJF: 177.425451,
          DKK: 6.867704,
          DOP: 56.499055,
          DZD: 136.45204,
          EGP: 29.499931,
          ERN: 15,
          ETB: 53.444992,
          EUR: 0.92185,
          FJD: 2.174904,
          FKP: 0.82012,
          GBP: 0.817595,
          GEL: 2.660391,
          GGP: 0.82012,
          GHS: 11.959626,
          GIP: 0.82012,
          GMD: 62.17504,
          GNF: 8586.993594,
          GTQ: 7.82357,
          GYD: 208.508089,
          HKD: 7.82115,
          HNL: 24.589575,
          HRK: 7.042204,
          HTG: 148.000184,
          HUF: 366.103833,
          IDR: 15114.85,
          ILS: 3.415904,
          IMP: 0.82012,
          INR: 81.285504,
          IQD: 1454.606628,
          IRR: 42225.000352,
          ISK: 142.440386,
          JEP: 0.82012,
          JMD: 152.310458,
          JOD: 0.709304,
          JPY: 127.88504,
          KES: 123.433286,
          KGS: 85.680385,
          KHR: 4102.133936,
          KMF: 454.825039,
          KPW: 899.941415,
          KRW: 1237.690384,
          KWD: 0.30509,
          KYD: 0.83053,
          KZT: 460.598239,
          LAK: 16814.213947,
          LBP: 1506.936443,
          LKR: 364.768678,
          LRD: 154.875039,
          LSL: 16.820382,
          LTL: 2.95274,
          LVL: 0.60489,
          LYD: 4.768954,
          MAD: 10.055584,
          MDL: 19.050652,
          MGA: 4497.32221,
          MKD: 56.71291,
          MMK: 2092.934691,
          MNT: 3457.217428,
          MOP: 8.018897,
          MRO: 356.999828,
          MUR: 43.703743,
          MVR: 15.403741,
          MWK: 1023.654883,
          MXN: 18.767104,
          MYR: 4.335039,
          MZN: 63.830377,
          NAD: 16.820377,
          NGN: 452.590377,
          NIO: 36.387519,
          NOK: 9.904855,
          NPR: 129.706411,
          NZD: 1.565681,
          OMR: 0.38501,
          PAB: 0.996635,
          PEN: 3.767065,
          PGK: 3.51173,
          PHP: 54.925039,
          PKR: 228.030235,
          PLN: 4.33145,
          PYG: 7370.051159,
          QAR: 3.641038,
          RON: 4.565404,
          RSD: 108.180854,
          RUB: 66.000341,
          RWF: 1069.521132,
          SAR: 3.754022,
          SBD: 8.299327,
          SCR: 13.427663,
          SDG: 582.00034,
          SEK: 10.403504,
          SGD: 1.318704,
          SHP: 1.377404,
          SLE: 19.109213,
          SLL: 19100.000338,
          SOS: 568.503664,
          SRD: 31.736504,
          STD: 20697.981008,
          SVC: 8.721021,
          SYP: 2511.833363,
          SZL: 16.836982,
          THB: 32.844038,
          TJS: 10.200581,
          TMT: 3.51,
          TND: 3.071504,
          TOP: 2.324904,
          TRY: 18.803504,
          TTD: 6.768493,
          TWD: 30.294304,
          TZS: 2328.137531,
          UAH: 36.621376,
          UGX: 3657.648523,
          USD: 1,
          UYU: 39.670922,
          UZS: 11279.043186,
          VEF: 1923479.041599,
          VES: 19.427685,
          VND: 23440,
          VUV: 116.340092,
          WST: 2.675104,
          XAF: 604.652256,
          XAG: 0.041205,
          XAU: 0.000521,
          XCD: 2.70255,
          XDR: 0.741393,
          XOF: 604.652256,
          XPF: 110.625037,
          YER: 250.303593,
          ZAR: 16.80897,
          ZMK: 9001.203589,
          ZMW: 18.313223,
          ZWL: 321.999592,
        },
      },
      {
        base: "USD",
        date: "2023-01-13",
        rates: {
          AED: 3.67303,
          AFN: 88.503991,
          ALL: 106.850403,
          AMD: 396.560403,
          ANG: 1.80239,
          AOA: 503.760403,
          ARS: 180.950915,
          AUD: 1.432254,
          AWG: 1.8,
          AZN: 1.70397,
          BAM: 1.809019,
          BBD: 2.019295,
          BDT: 104.197684,
          BGN: 1.805604,
          BHD: 0.376273,
          BIF: 2063,
          BMD: 1,
          BND: 1.323257,
          BOB: 6.910519,
          BRL: 5.096041,
          BSD: 1.000074,
          BTC: 5.0176185e-5,
          BTN: 81.346012,
          BWP: 12.675189,
          BYN: 2.524245,
          BYR: 19600,
          BZD: 2.015872,
          CAD: 1.34005,
          CDF: 2029.50392,
          CHF: 0.927404,
          CLF: 0.029627,
          CLP: 817.510396,
          CNY: 6.703041,
          COP: 4687.28,
          CRC: 578.973657,
          CUC: 1,
          CUP: 26.5,
          CVE: 102.320394,
          CZK: 22.154704,
          DJF: 177.720394,
          DKK: 6.867904,
          DOP: 56.750393,
          DZD: 136.219754,
          EGP: 29.499931,
          ERN: 15,
          ETB: 53.803876,
          EUR: 0.92185,
          FJD: 2.19904,
          FKP: 0.821013,
          GBP: 0.817595,
          GEL: 2.660391,
          GGP: 0.821013,
          GHS: 12.25039,
          GIP: 0.821013,
          GMD: 62.17504,
          GNF: 8780.000355,
          GTQ: 7.850708,
          GYD: 209.227468,
          HKD: 7.82115,
          HNL: 24.640389,
          HRK: 7.042204,
          HTG: 148.510804,
          HUF: 366.103833,
          IDR: 15114.75,
          ILS: 3.415904,
          IMP: 0.821013,
          INR: 81.28505,
          IQD: 1460,
          IRR: 42225.000352,
          ISK: 142.440386,
          JEP: 0.821013,
          JMD: 152.835948,
          JOD: 0.709304,
          JPY: 127.89504,
          KES: 123.950385,
          KGS: 85.680385,
          KHR: 4119.000351,
          KMF: 454.825039,
          KPW: 899.949882,
          KRW: 1237.685039,
          KWD: 0.30509,
          KYD: 0.833395,
          KZT: 462.189499,
          LAK: 17035.000349,
          LBP: 1513.852395,
          LKR: 366.027176,
          LRD: 154.875039,
          LSL: 16.820382,
          LTL: 2.95274,
          LVL: 0.60489,
          LYD: 4.785039,
          MAD: 10.085504,
          MDL: 19.116379,
          MGA: 4480.000347,
          MKD: 56.908576,
          MMK: 2100.155579,
          MNT: 3451.252049,
          MOP: 8.046563,
          MRO: 356.999828,
          MUR: 43.630379,
          MVR: 15.403741,
          MWK: 1020.503739,
          MXN: 18.767104,
          MYR: 4.335039,
          MZN: 63.830377,
          NAD: 16.820377,
          NGN: 452.590377,
          NIO: 36.403725,
          NOK: 9.904855,
          NPR: 130.153914,
          NZD: 1.565681,
          OMR: 0.38501,
          PAB: 1.000074,
          PEN: 3.809504,
          PGK: 3.520375,
          PHP: 54.814039,
          PKR: 229.000342,
          PLN: 4.331375,
          PYG: 7395.478763,
          QAR: 3.641038,
          RON: 4.565404,
          RSD: 108.327564,
          RUB: 69.000341,
          RWF: 1075,
          SAR: 3.753663,
          SBD: 8.299327,
          SCR: 13.427663,
          SDG: 582.00034,
          SEK: 10.403604,
          SGD: 1.318904,
          SHP: 1.377404,
          SLE: 19.109785,
          SLL: 19100.000338,
          SOS: 568.503664,
          SRD: 31.736504,
          STD: 20697.981008,
          SVC: 8.751069,
          SYP: 2512.508984,
          SZL: 16.82037,
          THB: 32.820369,
          TJS: 10.235869,
          TMT: 3.51,
          TND: 3.071504,
          TOP: 2.324904,
          TRY: 18.801504,
          TTD: 6.791845,
          TWD: 30.294404,
          TZS: 2336.000335,
          UAH: 36.747725,
          UGX: 3670.26787,
          USD: 1,
          UYU: 39.807792,
          UZS: 11325.000334,
          VEF: 1900670.105769,
          VES: 19.427685,
          VND: 23440,
          VUV: 116.93185,
          WST: 2.687883,
          XAF: 606.738382,
          XAG: 0.041205,
          XAU: 0.000521,
          XCD: 2.70255,
          XDR: 0.743951,
          XOF: 604.503597,
          XPF: 110.625037,
          YER: 250.303593,
          ZAR: 16.80953,
          ZMK: 9001.203589,
          ZMW: 18.376406,
          ZWL: 321.999592,
        },
      },
      {
        base: "USD",
        date: "2023-01-12",
        rates: {
          AED: 3.672969,
          AFN: 88.999607,
          ALL: 107.502153,
          AMD: 396.309875,
          ANG: 1.802174,
          AOA: 503.760139,
          ARS: 180.973332,
          AUD: 1.43533,
          AWG: 1.8025,
          AZN: 1.704375,
          BAM: 1.817365,
          BBD: 2.019049,
          BDT: 104.18714,
          BGN: 1.802852,
          BHD: 0.377018,
          BIF: 2061,
          BMD: 1,
          BND: 1.329493,
          BOB: 6.909839,
          BRL: 5.10895,
          BSD: 1,
          BTC: 5.3032769e-5,
          BTN: 81.542464,
          BWP: 12.69002,
          BYN: 2.52409,
          BYR: 19600,
          BZD: 2.01561,
          CAD: 1.33758,
          CDF: 2030.000032,
          CHF: 0.927397,
          CLF: 0.029663,
          CLP: 818.498444,
          CNY: 6.738798,
          COP: 4696.76,
          CRC: 579.511243,
          CUC: 1,
          CUP: 26.5,
          CVE: 102.040191,
          CZK: 22.136796,
          DJF: 177.72041,
          DKK: 6.84933,
          DOP: 56.649904,
          DZD: 136.453969,
          EGP: 29.548903,
          ERN: 15,
          ETB: 53.896429,
          EUR: 0.92083,
          FJD: 2.172098,
          FKP: 0.824517,
          GBP: 0.818475,
          GEL: 2.679424,
          GGP: 0.824517,
          GHS: 11.000171,
          GIP: 0.824517,
          GMD: 62.099045,
          GNF: 8774.99935,
          GTQ: 7.84278,
          GYD: 209.208326,
          HKD: 7.80875,
          HNL: 24.650227,
          HRK: 7.042203,
          HTG: 148.495633,
          HUF: 365.980354,
          IDR: 15205.6,
          ILS: 3.42185,
          IMP: 0.824517,
          INR: 81.11585,
          IQD: 1461,
          IRR: 41850.000178,
          ISK: 142.089951,
          JEP: 0.824517,
          JMD: 153.016168,
          JOD: 0.709598,
          JPY: 129.099504,
          KES: 123.902537,
          KGS: 85.680004,
          KHR: 4119.000284,
          KMF: 455.791301,
          KPW: 899.987694,
          KRW: 1239.454959,
          KWD: 0.30541,
          KYD: 0.833302,
          KZT: 462.130997,
          LAK: 17125.0001,
          LBP: 1469.572027,
          LKR: 367.506946,
          LRD: 154.650261,
          LSL: 16.814994,
          LTL: 2.95274,
          LVL: 0.60489,
          LYD: 4.784982,
          MAD: 10.162979,
          MDL: 19.149685,
          MGA: 4500.000248,
          MKD: 56.711864,
          MMK: 2100.039214,
          MNT: 3451.15028,
          MOP: 8.043022,
          MRO: 356.999828,
          MUR: 44.549841,
          MVR: 15.409699,
          MWK: 1020.49945,
          MXN: 18.847203,
          MYR: 4.358966,
          MZN: 63.830081,
          NAD: 16.81499,
          NGN: 452.129756,
          NIO: 36.330188,
          NOK: 9.888515,
          NPR: 130.474376,
          NZD: 1.565575,
          OMR: 0.385021,
          PAB: 0.999907,
          PEN: 3.77375,
          PGK: 3.525021,
          PHP: 55.034974,
          PKR: 228.500156,
          PLN: 4.325783,
          PYG: 7379.576287,
          QAR: 3.640964,
          RON: 4.551501,
          RSD: 108.094972,
          RUB: 67.785002,
          RWF: 1075,
          SAR: 3.757773,
          SBD: 8.296205,
          SCR: 13.417028,
          SDG: 575.497816,
          SEK: 10.40858,
          SGD: 1.32252,
          SHP: 1.3774,
          SLE: 19.089878,
          SLL: 19074.999811,
          SOS: 569.499915,
          SRD: 31.603567,
          STD: 20697.981008,
          SVC: 8.749303,
          SYP: 2511.999065,
          SZL: 16.814996,
          THB: 33.101933,
          TJS: 10.224584,
          TMT: 3.5,
          TND: 3.067502,
          TOP: 2.33305,
          TRY: 18.782398,
          TTD: 6.790002,
          TWD: 30.3405,
          TZS: 2332.000109,
          UAH: 36.75076,
          UGX: 3684.944108,
          USD: 1,
          UYU: 39.80394,
          UZS: 11325.000068,
          VEF: 1876710.830891,
          VES: 19.254302,
          VND: 23443,
          VUV: 117.011172,
          WST: 2.68129,
          XAF: 609.503677,
          XAG: 0.042007,
          XAU: 0.000527,
          XCD: 2.70255,
          XDR: 0.74539,
          XOF: 608.999894,
          XPF: 110.944975,
          YER: 250.298331,
          ZAR: 16.72404,
          ZMK: 9001.177334,
          ZMW: 18.324645,
          ZWL: 321.999592,
        },
      },
      {
        base: "USD",
        date: "2023-01-11",
        rates: {
          AED: 3.672898,
          AFN: 89.495038,
          ALL: 108.202797,
          AMD: 395.960292,
          ANG: 1.803178,
          AOA: 503.779797,
          ARS: 180.681679,
          AUD: 1.447807,
          AWG: 1.8,
          AZN: 1.703293,
          BAM: 1.820613,
          BBD: 2.020144,
          BDT: 104.335316,
          BGN: 1.818399,
          BHD: 0.37704,
          BIF: 2063,
          BMD: 1,
          BND: 1.332465,
          BOB: 6.913561,
          BRL: 5.159539,
          BSD: 1.000484,
          BTC: 5.5706978e-5,
          BTN: 81.580236,
          BWP: 12.743543,
          BYN: 2.525478,
          BYR: 19600,
          BZD: 2.016811,
          CAD: 1.34345,
          CDF: 2028.499219,
          CHF: 0.930899,
          CLF: 0.029854,
          CLP: 823.759724,
          CNY: 6.770499,
          COP: 4732.5,
          CRC: 585.709365,
          CUC: 1,
          CUP: 26.5,
          CVE: 102.874985,
          CZK: 22.327199,
          DJF: 177.71995,
          DKK: 6.909501,
          DOP: 56.44998,
          DZD: 136.52619,
          EGP: 29.702464,
          ERN: 15,
          ETB: 53.85033,
          EUR: 0.929011,
          FJD: 2.181597,
          FKP: 0.823078,
          GBP: 0.822501,
          GEL: 2.675025,
          GGP: 0.823078,
          GHS: 10.591069,
          GIP: 0.823078,
          GMD: 62.097721,
          GNF: 8784.999841,
          GTQ: 7.847043,
          GYD: 209.323541,
          HKD: 7.81195,
          HNL: 24.650202,
          HRK: 7.042201,
          HTG: 148.07823,
          HUF: 370.185047,
          IDR: 15432.3,
          ILS: 3.435805,
          IMP: 0.823078,
          INR: 81.63205,
          IQD: 1461,
          IRR: 41900.000272,
          ISK: 143.33984,
          JEP: 0.823078,
          JMD: 153.004803,
          JOD: 0.709299,
          JPY: 131.975994,
          KES: 123.849583,
          KGS: 85.680006,
          KHR: 4117.999887,
          KMF: 458.224953,
          KPW: 900.069376,
          KRW: 1242.35043,
          KWD: 0.30589,
          KYD: 0.833764,
          KZT: 462.509425,
          LAK: 17129.99973,
          LBP: 1518.475482,
          LKR: 366.689473,
          LRD: 154.499915,
          LSL: 16.899053,
          LTL: 2.95274,
          LVL: 0.60489,
          LYD: 4.789849,
          MAD: 10.209951,
          MDL: 19.166923,
          MGA: 4529.999799,
          MKD: 57.184417,
          MMK: 2101.153549,
          MNT: 3451.387351,
          MOP: 8.05252,
          MRO: 356.999828,
          MUR: 44.298095,
          MVR: 15.430785,
          MWK: 1023.496458,
          MXN: 18.954165,
          MYR: 4.370977,
          MZN: 63.829766,
          NAD: 16.906597,
          NGN: 451.597529,
          NIO: 36.375051,
          NOK: 9.99015,
          NPR: 130.531068,
          NZD: 1.570735,
          OMR: 0.385032,
          PAB: 1.000493,
          PEN: 3.770497,
          PGK: 3.519779,
          PHP: 55.034998,
          PKR: 228.502236,
          PLN: 4.35425,
          PYG: 7364.095022,
          QAR: 3.640985,
          RON: 4.589802,
          RSD: 109.045035,
          RUB: 69.154029,
          RWF: 1076,
          SAR: 3.756073,
          SBD: 8.281893,
          SCR: 13.276281,
          SDG: 575.500379,
          SEK: 10.462403,
          SGD: 1.330471,
          SHP: 1.377401,
          SLE: 19.089852,
          SLL: 19075.000331,
          SOS: 568.502819,
          SRD: 31.626031,
          STD: 20697.981008,
          SVC: 8.754782,
          SYP: 2512.501268,
          SZL: 16.893234,
          THB: 33.376499,
          TJS: 10.235416,
          TMT: 3.5,
          TND: 3.092399,
          TOP: 2.33355,
          TRY: 18.77936,
          TTD: 6.791153,
          TWD: 30.446596,
          TZS: 2331.99991,
          UAH: 36.764763,
          UGX: 3689.947592,
          USD: 1,
          UYU: 39.861487,
          UZS: 11325.000175,
          VEF: 1857256.779148,
          VES: 19.029284,
          VND: 23445,
          VUV: 116.89214,
          WST: 2.682317,
          XAF: 610.618571,
          XAG: 0.042666,
          XAU: 0.000533,
          XCD: 2.70255,
          XDR: 0.745916,
          XOF: 609.496685,
          XPF: 111.529466,
          YER: 250.297757,
          ZAR: 16.926535,
          ZMK: 9001.202368,
          ZMW: 18.334683,
          ZWL: 321.999592,
        },
      },
      {
        base: "USD",
        date: "2023-01-10",
        rates: {
          AED: 3.6729,
          AFN: 88.502559,
          ALL: 109.150109,
          AMD: 395.130029,
          ANG: 1.803109,
          AOA: 503.78967,
          ARS: 180.36987,
          AUD: 1.450747,
          AWG: 1.8025,
          AZN: 1.695747,
          BAM: 1.822819,
          BBD: 2.020075,
          BDT: 105.105294,
          BGN: 1.821221,
          BHD: 0.377046,
          BIF: 2062,
          BMD: 1,
          BND: 1.331942,
          BOB: 6.913682,
          BRL: 5.199794,
          BSD: 1.000485,
          BTC: 5.7335274e-5,
          BTN: 81.802329,
          BWP: 12.77822,
          BYN: 2.525327,
          BYR: 19600,
          BZD: 2.016804,
          CAD: 1.343355,
          CDF: 2014.999604,
          CHF: 0.92244,
          CLF: 0.029969,
          CLP: 826.94973,
          CNY: 6.778502,
          COP: 4785.5,
          CRC: 589.336726,
          CUC: 1,
          CUP: 26.5,
          CVE: 103.074989,
          CZK: 22.32715,
          DJF: 177.720091,
          DKK: 6.92602,
          DOP: 56.550246,
          DZD: 136.534307,
          EGP: 27.6733,
          ERN: 15,
          ETB: 53.849829,
          EUR: 0.93122,
          FJD: 2.183498,
          FKP: 0.819483,
          GBP: 0.822785,
          GEL: 2.690127,
          GGP: 0.819483,
          GHS: 10.297124,
          GIP: 0.819483,
          GMD: 62.050255,
          GNF: 8775.000292,
          GTQ: 7.848888,
          GYD: 209.314246,
          HKD: 7.80875,
          HNL: 24.650301,
          HRK: 7.042201,
          HTG: 148.573133,
          HUF: 371.319876,
          IDR: 15535.9,
          ILS: 3.467535,
          IMP: 0.819483,
          INR: 81.63765,
          IQD: 1460.5,
          IRR: 41850.000209,
          ISK: 144.059702,
          JEP: 0.819483,
          JMD: 152.355123,
          JOD: 0.709398,
          JPY: 132.106033,
          KES: 123.808119,
          KGS: 85.679717,
          KHR: 4118.00003,
          KMF: 458.450494,
          KPW: 899.903704,
          KRW: 1241.160344,
          KWD: 0.30591,
          KYD: 0.833788,
          KZT: 462.794512,
          LAK: 17124.999867,
          LBP: 1523.000247,
          LKR: 367.693684,
          LRD: 154.550058,
          LSL: 16.99969,
          LTL: 2.95274,
          LVL: 0.60489,
          LYD: 4.7899,
          MAD: 10.216497,
          MDL: 19.190258,
          MGA: 4524.999885,
          MKD: 57.312529,
          MMK: 2101.106021,
          MNT: 3451.386657,
          MOP: 8.047663,
          MRO: 356.999828,
          MUR: 43.710125,
          MVR: 15.39912,
          MWK: 1024.505713,
          MXN: 19.06325,
          MYR: 4.3725,
          MZN: 63.82985,
          NAD: 17.000304,
          NGN: 450.079697,
          NIO: 36.379846,
          NOK: 9.98717,
          NPR: 130.883689,
          NZD: 1.569575,
          OMR: 0.385001,
          PAB: 1.000485,
          PEN: 3.790499,
          PGK: 3.525021,
          PHP: 54.779795,
          PKR: 228.49768,
          PLN: 4.366353,
          PYG: 7333.156249,
          QAR: 3.641009,
          RON: 4.594802,
          RSD: 109.320199,
          RUB: 69.675006,
          RWF: 1075,
          SAR: 3.754715,
          SBD: 8.278288,
          SCR: 13.179403,
          SDG: 572.502571,
          SEK: 10.417898,
          SGD: 1.331975,
          SHP: 1.377397,
          SLE: 19.10385,
          SLL: 19075.000426,
          SOS: 569.494858,
          SRD: 31.786501,
          STD: 20697.981008,
          SVC: 8.754491,
          SYP: 2511.85677,
          SZL: 16.995007,
          THB: 33.460007,
          TJS: 10.185189,
          TMT: 3.51,
          TND: 3.093897,
          TOP: 2.32975,
          TRY: 18.78193,
          TTD: 6.796772,
          TWD: 30.447603,
          TZS: 2332.000025,
          UAH: 36.771193,
          UGX: 3706.958819,
          USD: 1,
          UYU: 39.901393,
          UZS: 11304.999538,
          VEF: 1854425.490151,
          VES: 19.07185,
          VND: 23450,
          VUV: 117.333074,
          WST: 2.692676,
          XAF: 611.347009,
          XAG: 0.042343,
          XAU: 0.000533,
          XCD: 2.70255,
          XDR: 0.747178,
          XOF: 610.499522,
          XPF: 111.497754,
          YER: 250.250282,
          ZAR: 16.91055,
          ZMK: 9001.205413,
          ZMW: 18.299633,
          ZWL: 321.999592,
        },
      },
      {
        base: "USD",
        date: "2023-01-09",
        rates: {
          AED: 3.673008,
          AFN: 87.999532,
          ALL: 110.849895,
          AMD: 394.929818,
          ANG: 1.801866,
          AOA: 503.789598,
          ARS: 180.118407,
          AUD: 1.446815,
          AWG: 1.8,
          AZN: 1.698022,
          BAM: 1.830093,
          BBD: 2.018649,
          BDT: 104.677496,
          BGN: 1.823135,
          BHD: 0.376989,
          BIF: 2062,
          BMD: 1,
          BND: 1.332778,
          BOB: 6.908397,
          BRL: 5.255105,
          BSD: 0.999733,
          BTC: 5.8182674e-5,
          BTN: 82.356668,
          BWP: 12.768675,
          BYN: 2.523989,
          BYR: 19600,
          BZD: 2.01528,
          CAD: 1.33937,
          CDF: 2027.00008,
          CHF: 0.92094,
          CLF: 0.030284,
          CLP: 835.630276,
          CNY: 6.772497,
          COP: 4851.39,
          CRC: 589.64054,
          CUC: 1,
          CUP: 26.5,
          CVE: 104.325015,
          CZK: 22.33703,
          DJF: 177.720134,
          DKK: 6.92865,
          DOP: 56.662246,
          DZD: 136.871529,
          EGP: 27.601001,
          ERN: 15,
          ETB: 53.805244,
          EUR: 0.93158,
          FJD: 2.181602,
          FKP: 0.826883,
          GBP: 0.82058,
          GEL: 2.685002,
          GGP: 0.826883,
          GHS: 10.30203,
          GIP: 0.826883,
          GMD: 61.999802,
          GNF: 8769.999809,
          GTQ: 7.843301,
          GYD: 209.285243,
          HKD: 7.80535,
          HNL: 24.650181,
          HRK: 7.042202,
          HTG: 148.968344,
          HUF: 369.765024,
          IDR: 15587.5,
          ILS: 3.47025,
          IMP: 0.826883,
          INR: 82.18045,
          IQD: 1460.5,
          IRR: 41900.000459,
          ISK: 143.929637,
          JEP: 0.826883,
          JMD: 152.417491,
          JOD: 0.709303,
          JPY: 131.729838,
          KES: 123.696617,
          KGS: 85.680132,
          KHR: 4117.999883,
          KMF: 462.949718,
          KPW: 900,
          KRW: 1239.654982,
          KWD: 0.30596,
          KYD: 0.833092,
          KZT: 462.545441,
          LAK: 17185.00004,
          LBP: 1516.026836,
          LKR: 367.421,
          LRD: 154.520523,
          LSL: 17.129561,
          LTL: 2.95274,
          LVL: 0.60489,
          LYD: 4.825012,
          MAD: 10.33597,
          MDL: 19.175622,
          MGA: 4510.000336,
          MKD: 57.320271,
          MMK: 2099.548223,
          MNT: 3458.557978,
          MOP: 8.039058,
          MRO: 356.999828,
          MUR: 43.699859,
          MVR: 15.391317,
          MWK: 1025.000208,
          MXN: 19.140998,
          MYR: 4.375497,
          MZN: 63.830264,
          NAD: 17.130103,
          NGN: 450.050104,
          NIO: 36.400559,
          NOK: 9.920125,
          NPR: 131.770331,
          NZD: 1.569795,
          OMR: 0.385002,
          PAB: 0.999733,
          PEN: 3.792499,
          PGK: 3.519756,
          PHP: 54.769831,
          PKR: 227.599797,
          PLN: 4.37216,
          PYG: 7325.443233,
          QAR: 3.64098,
          RON: 4.594396,
          RSD: 109.349927,
          RUB: 69.915031,
          RWF: 1073,
          SAR: 3.758671,
          SBD: 8.292016,
          SCR: 13.293516,
          SDG: 573.491881,
          SEK: 10.400795,
          SGD: 1.330115,
          SHP: 1.377399,
          SLE: 18.92002,
          SLL: 18910.000353,
          SOS: 568.502227,
          SRD: 31.805031,
          STD: 20697.981008,
          SVC: 8.748134,
          SYP: 2512.528258,
          SZL: 17.129845,
          THB: 33.434505,
          TJS: 10.192525,
          TMT: 3.5,
          TND: 3.134032,
          TOP: 2.353399,
          TRY: 18.766895,
          TTD: 6.785723,
          TWD: 30.267014,
          TZS: 2332.000212,
          UAH: 36.743007,
          UGX: 3707.172834,
          USD: 1,
          UYU: 39.871055,
          UZS: 11305.000256,
          VEF: 1835385.123039,
          VES: 18.371706,
          VND: 23452,
          VUV: 117.922721,
          WST: 2.710011,
          XAF: 613.806881,
          XAG: 0.042269,
          XAU: 0.000535,
          XCD: 2.70255,
          XDR: 0.753834,
          XOF: 618.999733,
          XPF: 112.570266,
          YER: 250.289175,
          ZAR: 16.88015,
          ZMK: 9001.202605,
          ZMW: 18.290797,
          ZWL: 321.999592,
        },
      },
      {
        base: "USD",
        date: "2023-01-08",
        rates: {
          AED: 3.672825,
          AFN: 88.499162,
          ALL: 110.849833,
          AMD: 396.368041,
          ANG: 1.803832,
          AOA: 503.864797,
          ARS: 179.075907,
          AUD: 1.450635,
          AWG: 1.8025,
          AZN: 1.702673,
          BAM: 1.865351,
          BBD: 2.020877,
          BDT: 103.89459,
          BGN: 1.838592,
          BHD: 0.377004,
          BIF: 2062,
          BMD: 1,
          BND: 1.349172,
          BOB: 6.916133,
          BRL: 5.225898,
          BSD: 1.000854,
          BTC: 5.8437729e-5,
          BTN: 82.683243,
          BWP: 12.898454,
          BYN: 2.526395,
          BYR: 19600,
          BZD: 2.017444,
          CAD: 1.343585,
          CDF: 2027.000293,
          CHF: 0.926935,
          CLF: 0.030492,
          CLP: 841.379603,
          CNY: 6.838398,
          COP: 4860,
          CRC: 591.108292,
          CUC: 1,
          CUP: 26.5,
          CVE: 104.32495,
          CZK: 22.533503,
          DJF: 177.720247,
          DKK: 6.97972,
          DOP: 56.550468,
          DZD: 137.313954,
          EGP: 27.173041,
          ERN: 15,
          ETB: 53.900135,
          EUR: 0.93844,
          FJD: 2.18775,
          FKP: 0.826886,
          GBP: 0.825395,
          GEL: 2.713532,
          GGP: 0.826886,
          GHS: 10.350103,
          GIP: 0.826886,
          GMD: 62.120296,
          GNF: 8775.000246,
          GTQ: 7.854421,
          GYD: 209.515362,
          HKD: 7.807945,
          HNL: 24.670098,
          HRK: 7.042199,
          HTG: 148.134442,
          HUF: 371.469499,
          IDR: 15607.3,
          ILS: 3.507902,
          IMP: 0.826886,
          INR: 82.273649,
          IQD: 1460.5,
          IRR: 41850.000067,
          ISK: 143.659801,
          JEP: 0.826886,
          JMD: 152.577113,
          JOD: 0.7093,
          JPY: 131.8675,
          KES: 123.650093,
          KGS: 85.679578,
          KHR: 4119.999857,
          KMF: 462.950108,
          KPW: 899.999924,
          KRW: 1253.879915,
          KWD: 0.30614,
          KYD: 0.834057,
          KZT: 464.767173,
          LAK: 17180.000203,
          LBP: 1515.692539,
          LKR: 365.833015,
          LRD: 154.550197,
          LSL: 17.130119,
          LTL: 2.95274,
          LVL: 0.60489,
          LYD: 4.819925,
          MAD: 10.335973,
          MDL: 19.247482,
          MGA: 4499.999564,
          MKD: 58.76182,
          MMK: 2101.933007,
          MNT: 3458.388184,
          MOP: 8.052496,
          MRO: 356.999828,
          MUR: 43.750184,
          MVR: 15.410104,
          MWK: 1023.999747,
          MXN: 19.1268,
          MYR: 4.4035,
          MZN: 63.829641,
          NAD: 17.129668,
          NGN: 450.050123,
          NIO: 36.380319,
          NOK: 10.00595,
          NPR: 132.291508,
          NZD: 1.572735,
          OMR: 0.385028,
          PAB: 1.000854,
          PEN: 3.789668,
          PGK: 3.52497,
          PHP: 55.521991,
          PKR: 227.249817,
          PLN: 4.406698,
          PYG: 7330.325805,
          QAR: 3.64101,
          RON: 4.64855,
          RSD: 110.240164,
          RUB: 72.503806,
          RWF: 1072,
          SAR: 3.759024,
          SBD: 8.298898,
          SCR: 14.28893,
          SDG: 572.999459,
          SEK: 10.51894,
          SGD: 1.333865,
          SHP: 1.377398,
          SLE: 18.920019,
          SLL: 18910.000161,
          SOS: 569.500874,
          SRD: 31.557502,
          STD: 20697.981008,
          SVC: 8.757231,
          SYP: 2512.527375,
          SZL: 17.129862,
          THB: 33.6475,
          TJS: 10.22904,
          TMT: 3.51,
          TND: 3.124503,
          TOP: 2.35285,
          TRY: 18.77595,
          TTD: 6.793291,
          TWD: 30.576501,
          TZS: 2337.0002,
          UAH: 36.778479,
          UGX: 3713.180771,
          USD: 1,
          UYU: 39.911871,
          UZS: 11324.999815,
          VEF: 1835385.003827,
          VES: 18.371701,
          VND: 23470,
          VUV: 117.922276,
          WST: 2.710014,
          XAF: 625.638556,
          XAG: 0.04166,
          XAU: 0.000535,
          XCD: 2.70255,
          XDR: 0.750618,
          XOF: 623.439783,
          XPF: 112.570224,
          YER: 250.296392,
          ZAR: 17.125051,
          ZMK: 9001.198126,
          ZMW: 18.250781,
          ZWL: 321.999592,
        },
      },
      {
        base: "USD",
        date: "2023-01-07",
        rates: {
          AED: 3.672804,
          AFN: 88.503991,
          ALL: 110.850403,
          AMD: 396.368041,
          ANG: 1.803832,
          AOA: 503.864804,
          ARS: 178.947438,
          AUD: 1.453304,
          AWG: 1.8025,
          AZN: 1.70397,
          BAM: 1.865351,
          BBD: 2.020877,
          BDT: 103.89459,
          BGN: 1.838592,
          BHD: 0.377004,
          BIF: 2062,
          BMD: 1,
          BND: 1.349172,
          BOB: 6.916133,
          BRL: 5.225904,
          BSD: 1.000854,
          BTC: 5.9011484e-5,
          BTN: 82.683243,
          BWP: 12.898454,
          BYN: 2.526395,
          BYR: 19600,
          BZD: 2.017444,
          CAD: 1.34495,
          CDF: 2027.000362,
          CHF: 0.927892,
          CLF: 0.030492,
          CLP: 841.380396,
          CNY: 6.838404,
          COP: 4860,
          CRC: 591.108292,
          CUC: 1,
          CUP: 26.5,
          CVE: 104.32504,
          CZK: 22.541604,
          DJF: 177.720394,
          DKK: 6.987904,
          DOP: 56.550393,
          DZD: 137.31404,
          EGP: 27.154311,
          ERN: 15,
          ETB: 53.903876,
          EUR: 0.93775,
          FJD: 2.18775,
          FKP: 0.828782,
          GBP: 0.826925,
          GEL: 2.703863,
          GGP: 0.828782,
          GHS: 10.35039,
          GIP: 0.828782,
          GMD: 62.12039,
          GNF: 8775.000355,
          GTQ: 7.854421,
          GYD: 209.515362,
          HKD: 7.80775,
          HNL: 24.670389,
          HRK: 7.042204,
          HTG: 148.134442,
          HUF: 370.580388,
          IDR: 15607.3,
          ILS: 3.50699,
          IMP: 0.828782,
          INR: 82.27365,
          IQD: 1460.5,
          IRR: 41850.000352,
          ISK: 143.660386,
          JEP: 0.828782,
          JMD: 152.577113,
          JOD: 0.709304,
          JPY: 132.08504,
          KES: 123.650385,
          KGS: 85.680385,
          KHR: 4120.000351,
          KMF: 462.950384,
          KPW: 899.986683,
          KRW: 1253.880384,
          KWD: 0.30614,
          KYD: 0.834057,
          KZT: 464.767173,
          LAK: 17180.000349,
          LBP: 1515.692539,
          LKR: 365.833015,
          LRD: 154.550382,
          LSL: 17.130382,
          LTL: 2.95274,
          LVL: 0.60489,
          LYD: 4.820381,
          MAD: 10.336039,
          MDL: 19.247482,
          MGA: 4500.000347,
          MKD: 58.76182,
          MMK: 2101.933007,
          MNT: 3437.590164,
          MOP: 8.052496,
          MRO: 356.999828,
          MUR: 43.750379,
          MVR: 15.410378,
          MWK: 1024.000345,
          MXN: 19.13525,
          MYR: 4.403504,
          MZN: 63.830377,
          NAD: 17.130377,
          NGN: 450.050377,
          NIO: 36.380377,
          NOK: 9.98755,
          NPR: 132.291508,
          NZD: 1.574308,
          OMR: 0.385039,
          PAB: 1.000854,
          PEN: 3.790375,
          PGK: 3.525039,
          PHP: 55.522039,
          PKR: 227.250375,
          PLN: 4.1699,
          PYG: 7330.325805,
          QAR: 3.641038,
          RON: 4.629704,
          RSD: 110.240373,
          RUB: 72.50369,
          RWF: 1072,
          SAR: 3.758508,
          SBD: 8.298898,
          SCR: 14.28893,
          SDG: 573.00034,
          SEK: 10.52261,
          SGD: 1.336404,
          SHP: 1.377404,
          SLE: 18.919679,
          SLL: 18910.000338,
          SOS: 569.503664,
          SRD: 31.557504,
          STD: 20697.981008,
          SVC: 8.757231,
          SYP: 2512.486348,
          SZL: 17.13037,
          THB: 33.730369,
          TJS: 10.22904,
          TMT: 3.51,
          TND: 3.124504,
          TOP: 2.35285,
          TRY: 18.754404,
          TTD: 6.793291,
          TWD: 30.576504,
          TZS: 2337.000335,
          UAH: 36.778479,
          UGX: 3713.180771,
          USD: 1,
          UYU: 39.911871,
          UZS: 11325.000334,
          VEF: 1835358.135767,
          VES: 18.371704,
          VND: 23470,
          VUV: 118.760593,
          WST: 2.717658,
          XAF: 625.638556,
          XAG: 0.041959,
          XAU: 0.000536,
          XCD: 2.70255,
          XDR: 0.750618,
          XOF: 623.503597,
          XPF: 112.570364,
          YER: 250.303593,
          ZAR: 17.10771,
          ZMK: 9001.203589,
          ZMW: 18.250781,
          ZWL: 321.999592,
        },
      },
      {
        base: "USD",
        date: "2023-01-06",
        rates: {
          AED: 3.672804,
          AFN: 88.503991,
          ALL: 110.850403,
          AMD: 396.368041,
          ANG: 1.803832,
          AOA: 503.864804,
          ARS: 178.947438,
          AUD: 1.453277,
          AWG: 1.8025,
          AZN: 1.70397,
          BAM: 1.865351,
          BBD: 2.020877,
          BDT: 103.89459,
          BGN: 1.838592,
          BHD: 0.377004,
          BIF: 2062,
          BMD: 1,
          BND: 1.349172,
          BOB: 6.916133,
          BRL: 5.225804,
          BSD: 1.000854,
          BTC: 5.9009324e-5,
          BTN: 82.683243,
          BWP: 12.898454,
          BYN: 2.526395,
          BYR: 19600,
          BZD: 2.017444,
          CAD: 1.34485,
          CDF: 2027.000362,
          CHF: 0.927904,
          CLF: 0.030492,
          CLP: 841.380396,
          CNY: 6.838504,
          COP: 4912,
          CRC: 591.108292,
          CUC: 1,
          CUP: 26.5,
          CVE: 104.32504,
          CZK: 22.568104,
          DJF: 177.720394,
          DKK: 6.98804,
          DOP: 56.550393,
          DZD: 137.31404,
          EGP: 27.154311,
          ERN: 15,
          ETB: 53.903876,
          EUR: 0.93775,
          FJD: 2.18775,
          FKP: 0.840431,
          GBP: 0.826925,
          GEL: 2.703863,
          GGP: 0.840431,
          GHS: 10.35039,
          GIP: 0.840431,
          GMD: 62.12039,
          GNF: 8775.000355,
          GTQ: 7.854421,
          GYD: 209.515362,
          HKD: 7.80745,
          HNL: 24.670389,
          HRK: 7.042204,
          HTG: 148.134442,
          HUF: 370.570388,
          IDR: 15607.2,
          ILS: 3.50699,
          IMP: 0.840431,
          INR: 82.273604,
          IQD: 1460.5,
          IRR: 41850.000352,
          ISK: 143.660386,
          JEP: 0.840431,
          JMD: 152.577113,
          JOD: 0.709304,
          JPY: 132.08504,
          KES: 123.650385,
          KGS: 85.680385,
          KHR: 4120.000351,
          KMF: 462.950384,
          KPW: 899.979259,
          KRW: 1253.880384,
          KWD: 0.30614,
          KYD: 0.834057,
          KZT: 464.767173,
          LAK: 17180.000349,
          LBP: 1515.692539,
          LKR: 365.833015,
          LRD: 154.550382,
          LSL: 17.130382,
          LTL: 2.95274,
          LVL: 0.60489,
          LYD: 4.820381,
          MAD: 10.336039,
          MDL: 19.247482,
          MGA: 4500.000347,
          MKD: 58.76182,
          MMK: 2101.933007,
          MNT: 3454.883096,
          MOP: 8.052496,
          MRO: 356.999828,
          MUR: 43.75091,
          MVR: 15.410378,
          MWK: 1024.000345,
          MXN: 19.13525,
          MYR: 4.403504,
          MZN: 63.830377,
          NAD: 17.130377,
          NGN: 450.050377,
          NIO: 36.380377,
          NOK: 9.990304,
          NPR: 132.291508,
          NZD: 1.574308,
          OMR: 0.385039,
          PAB: 1.000854,
          PEN: 3.790375,
          PGK: 3.525039,
          PHP: 55.243039,
          PKR: 227.250375,
          PLN: 4.19145,
          PYG: 7330.325805,
          QAR: 3.641038,
          RON: 4.629704,
          RSD: 110.240373,
          RUB: 72.50369,
          RWF: 1072,
          SAR: 3.758508,
          SBD: 8.298898,
          SCR: 14.28893,
          SDG: 573.00034,
          SEK: 10.52261,
          SGD: 1.336404,
          SHP: 1.377404,
          SLE: 18.935309,
          SLL: 18910.000338,
          SOS: 569.503664,
          SRD: 31.557504,
          STD: 20697.981008,
          SVC: 8.757231,
          SYP: 2512.06293,
          SZL: 17.13037,
          THB: 33.760369,
          TJS: 10.22904,
          TMT: 3.51,
          TND: 3.124504,
          TOP: 2.35285,
          TRY: 18.754404,
          TTD: 6.793291,
          TWD: 30.576604,
          TZS: 2337.000335,
          UAH: 36.778479,
          UGX: 3713.180771,
          USD: 1,
          UYU: 39.911871,
          UZS: 11325.000334,
          VEF: 1790448.638014,
          VES: 18.371704,
          VND: 23470,
          VUV: 117.921849,
          WST: 2.719335,
          XAF: 625.638556,
          XAG: 0.041959,
          XAU: 0.000536,
          XCD: 2.70255,
          XDR: 0.750618,
          XOF: 623.503597,
          XPF: 112.570364,
          YER: 250.303593,
          ZAR: 17.10772,
          ZMK: 9001.203589,
          ZMW: 18.250781,
          ZWL: 321.999592,
        },
      },
      {
        base: "USD",
        date: "2023-01-05",
        rates: {
          AED: 3.672995,
          AFN: 88.502283,
          ALL: 111.320136,
          AMD: 394.779769,
          ANG: 1.803181,
          AOA: 503.685103,
          ARS: 178.90948,
          AUD: 1.480297,
          AWG: 1.8025,
          AZN: 1.691881,
          BAM: 1.844131,
          BBD: 2.020293,
          BDT: 103.711621,
          BGN: 1.859003,
          BHD: 0.37703,
          BIF: 2062,
          BMD: 1,
          BND: 1.340324,
          BOB: 6.914068,
          BRL: 5.351303,
          BSD: 1.000575,
          BTC: 5.9441218e-5,
          BTN: 82.747598,
          BWP: 12.795036,
          BYN: 2.52534,
          BYR: 19600,
          BZD: 2.016736,
          CAD: 1.356135,
          CDF: 2026.999649,
          CHF: 0.93605,
          CLF: 0.030876,
          CLP: 851.949988,
          CNY: 6.882502,
          COP: 4965.18,
          CRC: 591.396177,
          CUC: 1,
          CUP: 26.5,
          CVE: 105.250003,
          CZK: 22.861703,
          DJF: 177.720142,
          DKK: 7.06826,
          DOP: 56.501083,
          DZD: 137.816963,
          EGP: 27.198601,
          ERN: 15,
          ETB: 53.6796,
          EUR: 0.950397,
          FJD: 2.210497,
          FKP: 0.829382,
          GBP: 0.839571,
          GEL: 2.702109,
          GGP: 0.829382,
          GHS: 10.249824,
          GIP: 0.829382,
          GMD: 62.096685,
          GNF: 8785.00031,
          GTQ: 7.851533,
          GYD: 209.447386,
          HKD: 7.81175,
          HNL: 24.689442,
          HRK: 7.042201,
          HTG: 147.57536,
          HUF: 376.569599,
          IDR: 15627.5,
          ILS: 3.551706,
          IMP: 0.829382,
          INR: 82.64545,
          IQD: 1460.5,
          IRR: 41900.000638,
          ISK: 144.739676,
          JEP: 0.829382,
          JMD: 152.04315,
          JOD: 0.7093,
          JPY: 133.30997,
          KES: 123.601305,
          KGS: 85.680299,
          KHR: 4116.999827,
          KMF: 468.050239,
          KPW: 899.936256,
          KRW: 1274.505013,
          KWD: 0.30673,
          KYD: 0.833856,
          KZT: 465.279415,
          LAK: 17255.000467,
          LBP: 1521.999844,
          LKR: 365.705153,
          LRD: 154.480604,
          LSL: 17.149991,
          LTL: 2.95274,
          LVL: 0.604889,
          LYD: 4.805032,
          MAD: 10.442501,
          MDL: 19.225935,
          MGA: 4495.000141,
          MKD: 58.560763,
          MMK: 2101.17839,
          MNT: 3446.829672,
          MOP: 8.046995,
          MRO: 356.999828,
          MUR: 43.699747,
          MVR: 15.409493,
          MWK: 1023.498196,
          MXN: 19.325885,
          MYR: 4.388021,
          MZN: 63.829669,
          NAD: 17.149937,
          NGN: 449.530195,
          NIO: 36.379799,
          NOK: 10.256035,
          NPR: 132.393224,
          NZD: 1.605125,
          OMR: 0.384995,
          PAB: 1.000495,
          PEN: 3.80375,
          PGK: 3.519653,
          PHP: 55.92701,
          PKR: 227.124998,
          PLN: 4.449334,
          PYG: 7321.628935,
          QAR: 3.641027,
          RON: 4.686297,
          RSD: 111.514992,
          RUB: 72.150276,
          RWF: 1070,
          SAR: 3.758523,
          SBD: 8.264739,
          SCR: 13.478385,
          SDG: 573.498972,
          SEK: 10.686525,
          SGD: 1.345665,
          SHP: 1.3774,
          SLE: 18.919538,
          SLL: 18975.000188,
          SOS: 569.494249,
          SRD: 31.605638,
          STD: 20697.981008,
          SVC: 8.755345,
          SYP: 2512.464106,
          SZL: 17.149698,
          THB: 34.049852,
          TJS: 10.209785,
          TMT: 3.5,
          TND: 3.1255,
          TOP: 2.34345,
          TRY: 18.7716,
          TTD: 6.78526,
          TWD: 30.627994,
          TZS: 2332.00024,
          UAH: 36.772733,
          UGX: 3731.808389,
          USD: 1,
          UYU: 39.765409,
          UZS: 11304.999552,
          VEF: 1782147.772616,
          VES: 17.537197,
          VND: 23485,
          VUV: 118.952592,
          WST: 2.719417,
          XAF: 618.541429,
          XAG: 0.043061,
          XAU: 0.000546,
          XCD: 2.70255,
          XDR: 0.749748,
          XOF: 618.498214,
          XPF: 113.534967,
          YER: 250.296279,
          ZAR: 17.165902,
          ZMK: 9001.198714,
          ZMW: 18.214176,
          ZWL: 321.999592,
        },
      },
      {
        base: "USD",
        date: "2023-01-04",
        rates: {
          AED: 3.673017,
          AFN: 87.501199,
          ALL: 107.150009,
          AMD: 394.489489,
          ANG: 1.802764,
          AOA: 503.685099,
          ARS: 178.571563,
          AUD: 1.4643,
          AWG: 1.8,
          AZN: 1.70389,
          BAM: 1.844862,
          BBD: 2.019714,
          BDT: 103.679291,
          BGN: 1.842718,
          BHD: 0.377012,
          BIF: 2062,
          BMD: 1,
          BND: 1.33981,
          BOB: 6.911946,
          BRL: 5.431601,
          BSD: 1.00033,
          BTC: 5.9355371e-5,
          BTN: 82.809036,
          BWP: 12.726312,
          BYN: 2.525303,
          BYR: 19600,
          BZD: 2.016224,
          CAD: 1.348125,
          CDF: 2026.999821,
          CHF: 0.929445,
          CLF: 0.031001,
          CLP: 855.410305,
          CNY: 6.888801,
          COP: 4930.63,
          CRC: 591.265387,
          CUC: 1,
          CUP: 26.5,
          CVE: 104.459678,
          CZK: 22.604601,
          DJF: 177.720657,
          DKK: 7.01028,
          DOP: 56.310259,
          DZD: 137.137984,
          EGP: 26.451798,
          ERN: 15,
          ETB: 53.650323,
          EUR: 0.94265,
          FJD: 2.197402,
          FKP: 0.835455,
          GBP: 0.82931,
          GEL: 2.685032,
          GGP: 0.835455,
          GHS: 10.306681,
          GIP: 0.835455,
          GMD: 62.049743,
          GNF: 8780.00042,
          GTQ: 7.849738,
          GYD: 209.272273,
          HKD: 7.815745,
          HNL: 24.685025,
          HRK: 7.042196,
          HTG: 147.541386,
          HUF: 373.029917,
          IDR: 15563.5,
          ILS: 3.52645,
          IMP: 0.835455,
          INR: 82.66675,
          IQD: 1460.5,
          IRR: 41850.000241,
          ISK: 142.990199,
          JEP: 0.835455,
          JMD: 151.047493,
          JOD: 0.709901,
          JPY: 132.199395,
          KES: 123.549941,
          KGS: 85.680292,
          KHR: 4116.49889,
          KMF: 464.498893,
          KPW: 900.084143,
          KRW: 1271.404973,
          KWD: 0.30646,
          KYD: 0.833656,
          KZT: 465.769964,
          LAK: 17334.999872,
          LBP: 1522.508216,
          LKR: 365.603547,
          LRD: 154.550267,
          LSL: 16.889871,
          LTL: 2.95274,
          LVL: 0.60489,
          LYD: 4.805026,
          MAD: 10.442501,
          MDL: 19.185398,
          MGA: 4485.999744,
          MKD: 58.078868,
          MMK: 2100.594444,
          MNT: 3441.162234,
          MOP: 8.055464,
          MRO: 356.999828,
          MUR: 43.695657,
          MVR: 15.409756,
          MWK: 1023.497023,
          MXN: 19.390202,
          MYR: 4.406018,
          MZN: 63.829992,
          NAD: 16.89016,
          NGN: 449.009855,
          NIO: 36.409569,
          NOK: 10.091804,
          NPR: 132.494458,
          NZD: 1.59103,
          OMR: 0.385014,
          PAB: 1.00033,
          PEN: 3.815048,
          PGK: 3.524977,
          PHP: 56.054987,
          PKR: 226.974987,
          PLN: 4.399326,
          PYG: 7298.589822,
          QAR: 3.640996,
          RON: 4.648702,
          RSD: 110.60952,
          RUB: 72.499436,
          RWF: 1071,
          SAR: 3.760759,
          SBD: 8.264824,
          SCR: 12.988053,
          SDG: 572.495518,
          SEK: 10.505497,
          SGD: 1.341435,
          SHP: 1.377401,
          SLE: 18.985536,
          SLL: 18974.999948,
          SOS: 569.499969,
          SRD: 31.605503,
          STD: 20697.981008,
          SVC: 8.752535,
          SYP: 2512.074751,
          SZL: 16.889997,
          THB: 33.899502,
          TJS: 10.192705,
          TMT: 3.51,
          TND: 3.125501,
          TOP: 2.353903,
          TRY: 18.746896,
          TTD: 6.787436,
          TWD: 30.69702,
          TZS: 2331.999751,
          UAH: 36.761873,
          UGX: 3730.970213,
          USD: 1,
          UYU: 39.82644,
          UZS: 11259.999722,
          VEF: 1752144.269673,
          VES: 17.537202,
          VND: 23525,
          VUV: 118.125928,
          WST: 2.702038,
          XAF: 618.739801,
          XAG: 0.041998,
          XAU: 0.000539,
          XCD: 2.70255,
          XDR: 0.752252,
          XOF: 617.000198,
          XPF: 113.534982,
          YER: 250.294061,
          ZAR: 16.885403,
          ZMK: 9001.200765,
          ZMW: 18.185162,
          ZWL: 321.999592,
        },
      },
      {
        base: "USD",
        date: "2023-01-03",
        rates: {
          AED: 3.673019,
          AFN: 87.499217,
          ALL: 107.15025,
          AMD: 394.690217,
          ANG: 1.802414,
          AOA: 503.6089,
          ARS: 178.380111,
          AUD: 1.486768,
          AWG: 1.8025,
          AZN: 1.691543,
          BAM: 1.857653,
          BBD: 2.019206,
          BDT: 103.408803,
          BGN: 1.852831,
          BHD: 0.377042,
          BIF: 2062,
          BMD: 1,
          BND: 1.345947,
          BOB: 6.910305,
          BRL: 5.480118,
          BSD: 1,
          BTC: 6.0005192e-5,
          BTN: 82.933569,
          BWP: 12.870631,
          BYN: 2.524149,
          BYR: 19600,
          BZD: 2.015767,
          CAD: 1.36752,
          CDF: 2026.999753,
          CHF: 0.936013,
          CLF: 0.031271,
          CLP: 862.849697,
          CNY: 6.915099,
          COP: 4862.01,
          CRC: 591.698328,
          CUC: 1,
          CUP: 26.5,
          CVE: 103.281732,
          CZK: 22.837974,
          DJF: 177.719754,
          DKK: 7.051635,
          DOP: 56.293137,
          DZD: 137.637407,
          EGP: 24.77564,
          ERN: 15,
          ETB: 53.649538,
          EUR: 0.94818,
          FJD: 2.210497,
          FKP: 0.83007,
          GBP: 0.835515,
          GEL: 2.684969,
          GGP: 0.83007,
          GHS: 10.149856,
          GIP: 0.83007,
          GMD: 62.000352,
          GNF: 8774.999703,
          GTQ: 7.847814,
          GYD: 209.22998,
          HKD: 7.81345,
          HNL: 24.685027,
          HRK: 7.042204,
          HTG: 148.01024,
          HUF: 379.519867,
          IDR: 15594.3,
          ILS: 3.533003,
          IMP: 0.83007,
          INR: 82.78595,
          IQD: 1460.5,
          IRR: 41900.000203,
          ISK: 143.840197,
          JEP: 0.83007,
          JMD: 151.011123,
          JOD: 0.7099,
          JPY: 131.253499,
          KES: 123.550231,
          KGS: 85.679797,
          KHR: 4117.999882,
          KMF: 466.350468,
          KPW: 899.974279,
          KRW: 1279.405027,
          KWD: 0.30637,
          KYD: 0.833302,
          KZT: 462.800969,
          LAK: 17329.999699,
          LBP: 1521.999918,
          LKR: 365.520933,
          LRD: 154.500874,
          LSL: 17.00029,
          LTL: 2.95274,
          LVL: 0.604891,
          LYD: 4.805021,
          MAD: 10.502501,
          MDL: 19.1695,
          MGA: 4486.000223,
          MKD: 58.41364,
          MMK: 2100.119874,
          MNT: 3444.612916,
          MOP: 8.048215,
          MRO: 356.999828,
          MUR: 43.996699,
          MVR: 15.409777,
          MWK: 1023.507104,
          MXN: 19.41275,
          MYR: 4.406047,
          MZN: 63.829927,
          NAD: 16.999645,
          NGN: 448.550301,
          NIO: 36.409931,
          NOK: 10.09363,
          NPR: 132.690271,
          NZD: 1.60093,
          OMR: 0.385,
          PAB: 1,
          PEN: 3.815006,
          PGK: 3.52502,
          PHP: 56.0502,
          PKR: 226.975029,
          PLN: 4.430857,
          PYG: 7331.566599,
          QAR: 3.64125,
          RON: 4.676901,
          RSD: 111.235004,
          RUB: 73.050191,
          RWF: 1071,
          SAR: 3.759628,
          SBD: 8.264824,
          SCR: 14.231101,
          SDG: 573.000298,
          SEK: 10.572065,
          SGD: 1.345415,
          SHP: 1.377399,
          SLE: 18.854747,
          SLL: 18974.999756,
          SOS: 568.494164,
          SRD: 31.605499,
          STD: 20697.981008,
          SVC: 8.749501,
          SYP: 2512.489789,
          SZL: 16.999902,
          THB: 34.390374,
          TJS: 10.199839,
          TMT: 3.51,
          TND: 3.125501,
          TOP: 2.34345,
          TRY: 18.751605,
          TTD: 6.794422,
          TWD: 30.6653,
          TZS: 2332.000232,
          UAH: 36.750914,
          UGX: 3728.926248,
          USD: 1,
          UYU: 39.637902,
          UZS: 11260.000144,
          VEF: 1745020.231549,
          VES: 17.537204,
          VND: 23535,
          VUV: 118.173913,
          WST: 2.701923,
          XAF: 623.05946,
          XAG: 0.041713,
          XAU: 0.000544,
          XCD: 2.70255,
          XDR: 0.748765,
          XOF: 617.000291,
          XPF: 113.534976,
          YER: 250.294738,
          ZAR: 17.026995,
          ZMK: 9001.195264,
          ZMW: 18.109702,
          ZWL: 321.999592,
        },
      },
      {
        base: "USD",
        date: "2023-01-02",
        rates: {
          AED: 3.672701,
          AFN: 89.221279,
          ALL: 106.958829,
          AMD: 393.605122,
          ANG: 1.802397,
          AOA: 503.633097,
          ARS: 178.152538,
          AUD: 1.469443,
          AWG: 1.8,
          AZN: 1.699941,
          BAM: 1.830693,
          BBD: 2.019273,
          BDT: 106.462486,
          BGN: 1.827098,
          BHD: 0.377054,
          BIF: 2072.850317,
          BMD: 1,
          BND: 1.34162,
          BOB: 6.910718,
          BRL: 5.363878,
          BSD: 1.000136,
          BTC: 6.0053906e-5,
          BTN: 82.78218,
          BWP: 12.756528,
          BYN: 2.524348,
          BYR: 19600,
          BZD: 2.015903,
          CAD: 1.35615,
          CDF: 2029.999744,
          CHF: 0.924198,
          CLF: 0.030741,
          CLP: 848.249658,
          CNY: 6.898601,
          COP: 4848,
          CRC: 591.741509,
          CUC: 1,
          CUP: 26.5,
          CVE: 103.210075,
          CZK: 22.630299,
          DJF: 177.719648,
          DKK: 6.96559,
          DOP: 56.275302,
          DZD: 137.438641,
          EGP: 24.715597,
          ERN: 15,
          ETB: 53.604279,
          EUR: 0.93656,
          FJD: 2.19595,
          FKP: 0.826559,
          GBP: 0.82904,
          GEL: 2.697209,
          GGP: 0.826559,
          GHS: 9.990967,
          GIP: 0.826559,
          GMD: 61.950299,
          GNF: 8620.355593,
          GTQ: 7.848234,
          GYD: 209.237566,
          HKD: 7.80575,
          HNL: 24.678127,
          HRK: 7.042197,
          HTG: 148.014471,
          HUF: 376.149978,
          IDR: 15554,
          ILS: 3.5078,
          IMP: 0.826559,
          INR: 82.70625,
          IQD: 1459.683345,
          IRR: 41850.000338,
          ISK: 142.079929,
          JEP: 0.826559,
          JMD: 151.020026,
          JOD: 0.709901,
          JPY: 130.799498,
          KES: 123.409547,
          KGS: 85.679898,
          KHR: 4120.523993,
          KMF: 460.225024,
          KPW: 900,
          KRW: 1270.105023,
          KWD: 0.30592,
          KYD: 0.833431,
          KZT: 462.851767,
          LAK: 17346.668476,
          LBP: 1512.137819,
          LKR: 365.542831,
          LRD: 154.496786,
          LSL: 17.009578,
          LTL: 2.95274,
          LVL: 0.60489,
          LYD: 4.828358,
          MAD: 10.450084,
          MDL: 19.131843,
          MGA: 4466.193353,
          MKD: 57.746017,
          MMK: 2100.245897,
          MNT: 3448.185558,
          MOP: 8.039894,
          MRO: 356.999828,
          MUR: 44.000618,
          MVR: 15.402199,
          MWK: 1023.600769,
          MXN: 19.46515,
          MYR: 4.404939,
          MZN: 63.829854,
          NAD: 17.010286,
          NGN: 448.079683,
          NIO: 36.513144,
          NOK: 9.84996,
          NPR: 132.451899,
          NZD: 1.580865,
          OMR: 0.385008,
          PAB: 1.000136,
          PEN: 3.804353,
          PGK: 3.523988,
          PHP: 55.814969,
          PKR: 226.722297,
          PLN: 4.375337,
          PYG: 7364.406026,
          QAR: 3.64075,
          RON: 4.616904,
          RSD: 109.880199,
          RUB: 77.999839,
          RWF: 1068.717795,
          SAR: 3.758893,
          SBD: 8.278288,
          SCR: 12.911657,
          SDG: 571.458728,
          SEK: 10.448665,
          SGD: 1.341699,
          SHP: 1.377404,
          SLE: 18.855058,
          SLL: 18824.999993,
          SOS: 569.503431,
          SRD: 31.622497,
          STD: 20697.981008,
          SVC: 8.750837,
          SYP: 2512.531208,
          SZL: 17.022993,
          THB: 34.497777,
          TJS: 10.130996,
          TMT: 3.5,
          TND: 3.108501,
          TOP: 2.341801,
          TRY: 18.745299,
          TTD: 6.794839,
          TWD: 30.735099,
          TZS: 2332.000437,
          UAH: 36.755105,
          UGX: 3726.383084,
          USD: 1,
          UYU: 39.63851,
          UZS: 11247.918529,
          VEF: 1724101.29976,
          VES: 17.095802,
          VND: 23635,
          VUV: 117.236645,
          WST: 2.696122,
          XAF: 613.999354,
          XAG: 0.041508,
          XAU: 0.000547,
          XCD: 2.70255,
          XDR: 0.748735,
          XOF: 613.98786,
          XPF: 111.950325,
          YER: 250.296031,
          ZAR: 17.00694,
          ZMK: 9001.200947,
          ZMW: 18.076763,
          ZWL: 321.999592,
        },
      },
      {
        base: "USD",
        date: "2023-01-01",
        rates: {
          AED: 3.672635,
          AFN: 87.49408,
          ALL: 107.150283,
          AMD: 393.731324,
          ANG: 1.802385,
          AOA: 503.690999,
          ARS: 176.728445,
          AUD: 1.46638,
          AWG: 1.8,
          AZN: 1.700045,
          BAM: 1.832031,
          BBD: 2.019174,
          BDT: 103.159191,
          BGN: 1.827155,
          BHD: 0.37658,
          BIF: 2062,
          BMD: 1,
          BND: 1.34029,
          BOB: 6.910305,
          BRL: 5.286699,
          BSD: 1.000033,
          BTC: 6.0217375e-5,
          BTN: 82.580068,
          BWP: 12.755638,
          BYN: 2.524225,
          BYR: 19600,
          BZD: 2.01584,
          CAD: 1.353395,
          CDF: 2029.99989,
          CHF: 0.92395,
          CLF: 0.03075,
          CLP: 848.250029,
          CNY: 6.897897,
          COP: 4848.67,
          CRC: 591.730443,
          CUC: 1,
          CUP: 26.5,
          CVE: 103.298862,
          CZK: 22.52401,
          DJF: 177.719972,
          DKK: 6.949525,
          DOP: 56.25032,
          DZD: 137.17938,
          EGP: 24.752919,
          ERN: 15,
          ETB: 53.649762,
          EUR: 0.934185,
          FJD: 2.222498,
          FKP: 0.826337,
          GBP: 0.826446,
          GEL: 2.703721,
          GGP: 0.826337,
          GHS: 10.149612,
          GIP: 0.826337,
          GMD: 61.94977,
          GNF: 8769.999776,
          GTQ: 7.84819,
          GYD: 209.232614,
          HKD: 7.801749,
          HNL: 24.70015,
          HRK: 7.0422,
          HTG: 148.006913,
          HUF: 373.36698,
          IDR: 15538.5,
          ILS: 3.527898,
          IMP: 0.826337,
          INR: 82.75005,
          IQD: 1460.5,
          IRR: 41850.000209,
          ISK: 141.549885,
          JEP: 0.826337,
          JMD: 151.711896,
          JOD: 0.709301,
          JPY: 130.919826,
          KES: 123.499621,
          KGS: 85.679943,
          KHR: 4118.497201,
          KMF: 460.224984,
          KPW: 899.999994,
          KRW: 1261.909645,
          KWD: 0.30635,
          KYD: 0.833419,
          KZT: 462.857143,
          LAK: 17340.000067,
          LBP: 1522.503496,
          LKR: 367.529437,
          LRD: 154.504446,
          LSL: 17.010251,
          LTL: 2.95274,
          LVL: 0.60489,
          LYD: 4.825011,
          MAD: 10.43875,
          MDL: 19.160087,
          MGA: 4479.999951,
          MKD: 57.712212,
          MMK: 2100.167396,
          MNT: 3448.169317,
          MOP: 8.027877,
          MRO: 356.999828,
          MUR: 43.950024,
          MVR: 15.401869,
          MWK: 1024.466171,
          MXN: 19.496875,
          MYR: 4.40501,
          MZN: 63.82954,
          NAD: 17.010149,
          NGN: 448.080051,
          NIO: 36.400356,
          NOK: 9.788197,
          NPR: 132.128371,
          NZD: 1.573642,
          OMR: 0.384958,
          PAB: 1.000066,
          PEN: 3.803975,
          PGK: 3.519421,
          PHP: 55.680156,
          PKR: 226.550018,
          PLN: 4.373947,
          PYG: 7364.289528,
          QAR: 3.64075,
          RON: 4.63494,
          RSD: 109.58009,
          RUB: 73.750269,
          RWF: 1070,
          SAR: 3.760901,
          SBD: 8.278288,
          SCR: 13.405775,
          SDG: 571.492933,
          SEK: 10.42379,
          SGD: 1.338976,
          SHP: 1.377398,
          SLE: 18.85505,
          SLL: 18825.000519,
          SOS: 569.500523,
          SRD: 31.809031,
          STD: 20697.981008,
          SVC: 8.751124,
          SYP: 2512.527043,
          SZL: 17.00989,
          THB: 34.611502,
          TJS: 10.19597,
          TMT: 3.5,
          TND: 3.1085,
          TOP: 2.34125,
          TRY: 18.691295,
          TTD: 6.796026,
          TWD: 30.662802,
          TZS: 2331.999749,
          UAH: 36.751623,
          UGX: 3720.217883,
          USD: 1,
          UYU: 39.967963,
          UZS: 11254.999749,
          VEF: 1724674.380398,
          VES: 17.095799,
          VND: 23635,
          VUV: 117.236648,
          WST: 2.696122,
          XAF: 614.462357,
          XAG: 0.041736,
          XAU: 0.000548,
          XCD: 2.70255,
          XDR: 0.751548,
          XOF: 615.508119,
          XPF: 111.950194,
          YER: 250.302768,
          ZAR: 16.989425,
          ZMK: 9001.196938,
          ZMW: 18.11118,
          ZWL: 321.999592,
        },
      },
    ];
    const FIXER_ARRAY_202212 = [
      { base: "USD", date: "2022-12-31", rates: { AUD: 1.469504, HKD: 7.81325 } },
      { base: "USD", date: "2022-12-30", rates: { AUD: 1.469508, HKD: 7.81325 } },
      { base: "USD", date: "2022-12-29", rates: { AUD: 1.478197, HKD: 7.79455 } },
      { base: "USD", date: "2022-12-28", rates: { AUD: 1.482145, HKD: 7.794801 } },
      { base: "USD", date: "2022-12-27", rates: { AUD: 1.48635, HKD: 7.80065 } },
      { base: "USD", date: "2022-12-26", rates: { AUD: 1.482745, HKD: 7.805975 } },
      { base: "USD", date: "2022-12-25", rates: { AUD: 1.491002, HKD: 7.805065 } },
      { base: "USD", date: "2022-12-24", rates: { AUD: 1.488104, HKD: 7.80625 } },
      { base: "USD", date: "2022-12-23", rates: { AUD: 1.488104, HKD: 7.80605 } },
      { base: "USD", date: "2022-12-22", rates: { AUD: 1.497903, HKD: 7.79555 } },
      { base: "USD", date: "2022-12-21", rates: { AUD: 1.4892, HKD: 7.79635 } },
      { base: "USD", date: "2022-12-20", rates: { AUD: 1.494992, HKD: 7.78825 } },
      { base: "USD", date: "2022-12-19", rates: { AUD: 1.491204, HKD: 7.78055 } },
      { base: "USD", date: "2022-12-18", rates: { AUD: 1.49543, HKD: 7.78105 } },
      { base: "USD", date: "2022-12-17", rates: { AUD: 1.495886, HKD: 7.784904 } },
      { base: "USD", date: "2022-12-16", rates: { AUD: 1.489203, HKD: 7.78465 } },
      { base: "USD", date: "2022-12-15", rates: { AUD: 1.491299, HKD: 7.776115 } },
      { base: "USD", date: "2022-12-14", rates: { AUD: 1.457939, HKD: 7.77585 } },
      { base: "USD", date: "2022-12-13", rates: { AUD: 1.461529, HKD: 7.77505 } },
      { base: "USD", date: "2022-12-12", rates: { AUD: 1.481043, HKD: 7.77505 } },
      { base: "USD", date: "2022-12-11", rates: { AUD: 1.474705, HKD: 7.786975 } },
      { base: "USD", date: "2022-12-10", rates: { AUD: 1.471454, HKD: 7.78605 } },
      { base: "USD", date: "2022-12-09", rates: { AUD: 1.471021, HKD: 7.78595 } },
      { base: "USD", date: "2022-12-08", rates: { AUD: 1.475721, HKD: 7.783965 } },
      { base: "USD", date: "2022-12-07", rates: { AUD: 1.48658, HKD: 7.79175 } },
      { base: "USD", date: "2022-12-06", rates: { AUD: 1.493619, HKD: 7.774385 } },
      { base: "USD", date: "2022-12-05", rates: { AUD: 1.491198, HKD: 7.76915 } },
      { base: "USD", date: "2022-12-04", rates: { AUD: 1.467981, HKD: 7.78555 } },
      { base: "USD", date: "2022-12-03", rates: { AUD: 1.471649, HKD: 7.789585 } },
      { base: "USD", date: "2022-12-02", rates: { AUD: 1.47145, HKD: 7.79025 } },
      { base: "USD", date: "2022-12-01", rates: { AUD: 1.467987, HKD: 7.779665 } },
    ];
    const FIXER_ARRAY_202211 = [
      { base: "USD", date: "2022-11-30", rates: { AUD: 1.470556, HKD: 7.80495 } },
      { base: "USD", date: "2022-11-29", rates: { AUD: 1.49723, HKD: 7.810855 } },
      { base: "USD", date: "2022-11-28", rates: { AUD: 1.50341, HKD: 7.813255 } },
      { base: "USD", date: "2022-11-27", rates: { AUD: 1.49008, HKD: 7.81031 } },
      { base: "USD", date: "2022-11-26", rates: { AUD: 1.48125, HKD: 7.81415 } },
      { base: "USD", date: "2022-11-25", rates: { AUD: 1.48125, HKD: 7.81405 } },
      { base: "USD", date: "2022-11-24", rates: { AUD: 1.478185, HKD: 7.80845 } },
      { base: "USD", date: "2022-11-23", rates: { AUD: 1.482942, HKD: 7.81835 } },
      { base: "USD", date: "2022-11-22", rates: { AUD: 1.50425, HKD: 7.816935 } },
      { base: "USD", date: "2022-11-21", rates: { AUD: 1.51342, HKD: 7.80415 } },
      { base: "USD", date: "2022-11-20", rates: { AUD: 1.49664, HKD: 7.82085 } },
      { base: "USD", date: "2022-11-19", rates: { AUD: 1.49815, HKD: 7.82195 } },
      { base: "USD", date: "2022-11-18", rates: { AUD: 1.49815, HKD: 7.82185 } },
      { base: "USD", date: "2022-11-17", rates: { AUD: 1.495014, HKD: 7.826595 } },
      { base: "USD", date: "2022-11-16", rates: { AUD: 1.483801, HKD: 7.82435 } },
      { base: "USD", date: "2022-11-15", rates: { AUD: 1.476941, HKD: 7.820015 } },
      { base: "USD", date: "2022-11-14", rates: { AUD: 1.494377, HKD: 7.83625 } },
      { base: "USD", date: "2022-11-13", rates: { AUD: 1.49288, HKD: 7.83814 } },
      { base: "USD", date: "2022-11-12", rates: { AUD: 1.491869, HKD: 7.83725 } },
      { base: "USD", date: "2022-11-11", rates: { AUD: 1.49185, HKD: 7.83725 } },
      { base: "USD", date: "2022-11-10", rates: { AUD: 1.513306, HKD: 7.84565 } },
      { base: "USD", date: "2022-11-09", rates: { AUD: 1.556057, HKD: 7.849415 } },
      { base: "USD", date: "2022-11-08", rates: { AUD: 1.536535, HKD: 7.849285 } },
      { base: "USD", date: "2022-11-07", rates: { AUD: 1.544535, HKD: 7.85025 } },
      { base: "USD", date: "2022-11-06", rates: { AUD: 1.552665, HKD: 7.849555 } },
      { base: "USD", date: "2022-11-05", rates: { AUD: 1.546312, HKD: 7.85015 } },
      { base: "USD", date: "2022-11-04", rates: { AUD: 1.54725, HKD: 7.85015 } },
      { base: "USD", date: "2022-11-03", rates: { AUD: 1.590192, HKD: 7.849885 } },
      { base: "USD", date: "2022-11-02", rates: { AUD: 1.577362, HKD: 7.84989 } },
      { base: "USD", date: "2022-11-01", rates: { AUD: 1.563526, HKD: 7.84975 } },
    ];
    const FIXER_ARRAY_202210 = [
      { base: "USD", date: "2022-10-31", rates: { AUD: 1.560355, HKD: 7.84965 } },
      { base: "USD", date: "2022-10-30", rates: { AUD: 1.562535, HKD: 7.84912 } },
      { base: "USD", date: "2022-10-29", rates: { AUD: 1.558862, HKD: 7.848915 } },
      { base: "USD", date: "2022-10-28", rates: { AUD: 1.558862, HKD: 7.848915 } },
      { base: "USD", date: "2022-10-27", rates: { AUD: 1.550255, HKD: 7.84955 } },
      { base: "USD", date: "2022-10-26", rates: { AUD: 1.540037, HKD: 7.84955 } },
      { base: "USD", date: "2022-10-25", rates: { AUD: 1.566048, HKD: 7.84955 } },
      { base: "USD", date: "2022-10-24", rates: { AUD: 1.579929, HKD: 7.849755 } },
      { base: "USD", date: "2022-10-23", rates: { AUD: 1.569525, HKD: 7.84965 } },
      { base: "USD", date: "2022-10-22", rates: { AUD: 1.56789, HKD: 7.84985 } },
      { base: "USD", date: "2022-10-21", rates: { AUD: 1.575794, HKD: 7.84985 } },
      { base: "USD", date: "2022-10-20", rates: { AUD: 1.59379, HKD: 7.848695 } },
      { base: "USD", date: "2022-10-19", rates: { AUD: 1.596551, HKD: 7.84945 } },
      { base: "USD", date: "2022-10-18", rates: { AUD: 1.58327, HKD: 7.849615 } },
      { base: "USD", date: "2022-10-17", rates: { AUD: 1.588098, HKD: 7.84945 } },
      { base: "USD", date: "2022-10-16", rates: { AUD: 1.6072, HKD: 7.84965 } },
      { base: "USD", date: "2022-10-15", rates: { AUD: 1.613254, HKD: 7.85049 } },
      { base: "USD", date: "2022-10-14", rates: { AUD: 1.613254, HKD: 7.85049 } },
      { base: "USD", date: "2022-10-13", rates: { AUD: 1.587554, HKD: 7.849705 } },
      { base: "USD", date: "2022-10-12", rates: { AUD: 1.59313, HKD: 7.849605 } },
      { base: "USD", date: "2022-10-11", rates: { AUD: 1.595899, HKD: 7.85005 } },
      { base: "USD", date: "2022-10-10", rates: { AUD: 1.588698, HKD: 7.849805 } },
      { base: "USD", date: "2022-10-09", rates: { AUD: 1.569788, HKD: 7.849755 } },
      { base: "USD", date: "2022-10-08", rates: { AUD: 1.570105, HKD: 7.849725 } },
      { base: "USD", date: "2022-10-07", rates: { AUD: 1.567048, HKD: 7.849475 } },
      { base: "USD", date: "2022-10-06", rates: { AUD: 1.559075, HKD: 7.85015 } },
      { base: "USD", date: "2022-10-05", rates: { AUD: 1.535532, HKD: 7.849565 } },
      { base: "USD", date: "2022-10-04", rates: { AUD: 1.535167, HKD: 7.84983 } },
      { base: "USD", date: "2022-10-03", rates: { AUD: 1.538071, HKD: 7.85125 } },
      { base: "USD", date: "2022-10-02", rates: { AUD: 1.557899, HKD: 7.849785 } },
      { base: "USD", date: "2022-10-01", rates: { AUD: 1.561524, HKD: 7.84985 } },
    ];
    const FIXER_ARRAY = [
      ...FIXER_ARRAY_202303,
      ...FIXER_ARRAY_202302,
      ...FIXER_ARRAY_202301,
      ...FIXER_ARRAY_202212,
      ...FIXER_ARRAY_202211,
      ...FIXER_ARRAY_202210,
    ];

    let dataToUse: { [index: string]: any } = {};
    if (ymd > dateStringToYmd(FIXER_ARRAY[0].date)) {
      // sample growth rate of 3% from last
      dataToUse = { ...FIXER_ARRAY[0] }; // clone the object
      const dateRequested = ymd;
      const dateWeHaveLatestDataFor = dateStringToYmd(dataToUse.date);
      const daysDifference = getDaysDifferenceYMD(dateRequested, dateWeHaveLatestDataFor);
      const allSymbols = Object.keys(dataToUse.rates); // putting this if ymd > ... function here is not great as we need to calculate each symbol each time, and also it assumes this date also contains all symbols
      allSymbols.forEach((sym: string) => {
        dataToUse.rates[sym] = dataToUse.rates[sym] * (1 - (0.03 * daysDifference) / 365);
        dataToUse.rates[sym] = round2dp(dataToUse.rates[sym]);
      });
    } else if (ymd >= dateStringToYmd(FIXER_ARRAY[0].date)) {
      dataToUse = FIXER_ARRAY[0];
    }
    if (ymd <= dateStringToYmd(FIXER_ARRAY[FIXER_ARRAY.length - 1].date)) {
      dataToUse = FIXER_ARRAY[FIXER_ARRAY.length - 1];
    }

    for (let i = 1; i < FIXER_ARRAY.length; i++) {
      if (ymd <= dateStringToYmd(FIXER_ARRAY[i].date)) {
        dataToUse = FIXER_ARRAY[i];
      }
    }

    if (base === "USD" && dataToUse.rates[symbol]) {
      return dataToUse.rates[symbol];
    }
    if (base !== "USD" && dataToUse.rates[base] && dataToUse.rates[symbol]) {
      return dataToUse.rates[symbol] / dataToUse.rates[base];
    }
  }

  function getFromGloss(symbolGloss: string, base: string, ymd: number): any {
    if (!["XASX-BHP", "XASX-CBA", "XASX-NAB"].includes(symbolGloss)) {
      return;
    } // todo handle better when we don't have historical data
    const XASX_202303 = [
      {
        base: GLOBAL_BASE_CURRENCY,
        date: "2023-03-21",
        rates: {
          XASX_BHP: 43.53,
          XASX_CBA: 96.52,
          XASX_NAB: 28.01,
        },
      },
      {
        base: GLOBAL_BASE_CURRENCY,
        date: "2023-03-20",
        rates: {
          XASX_BHP: 42.96,
          XASX_CBA: 96.08,
          XASX_NAB: 27.86,
        },
      },
      {
        base: GLOBAL_BASE_CURRENCY,
        date: "2023-03-17",
        rates: {
          XASX_BHP: 43.39,
          XASX_CBA: 96.45,
          XASX_NAB: 28.28,
        },
      },
      {
        base: GLOBAL_BASE_CURRENCY,
        date: "2023-03-16",
        rates: {
          XASX_BHP: 43.39,
          XASX_CBA: 95.3,
          XASX_NAB: 27.82,
        },
      },
      {
        base: GLOBAL_BASE_CURRENCY,
        date: "2023-03-15",
        rates: {
          XASX_BHP: 45.56,
          XASX_CBA: 95.4,
          XASX_NAB: 28.31,
        },
      },
      {
        base: GLOBAL_BASE_CURRENCY,
        date: "2023-03-14",
        rates: {
          XASX_BHP: 45.03,
          XASX_CBA: 95.28,
          XASX_NAB: 28.1,
        },
      },
      {
        base: GLOBAL_BASE_CURRENCY,
        date: "2023-03-13",
        rates: {
          XASX_BHP: 45.83,
          XASX_CBA: 95.12,
          XASX_NAB: 28.54,
        },
      },
      {
        base: GLOBAL_BASE_CURRENCY,
        date: "2023-03-10",
        rates: {
          XASX_BHP: 45.01,
          XASX_CBA: 95.51,
          XASX_NAB: 29.0,
        },
      },
      {
        base: GLOBAL_BASE_CURRENCY,
        date: "2023-03-09",
        rates: {
          XASX_BHP: 46.59,
          XASX_CBA: 98.72,
          XASX_NAB: 29.89,
        },
      },
      {
        base: GLOBAL_BASE_CURRENCY,
        date: "2023-03-08",
        rates: {
          XASX_BHP: 47.63,
          XASX_CBA: 98.41,
          XASX_NAB: 29.45,
        },
      },
      {
        base: GLOBAL_BASE_CURRENCY,
        date: "2023-03-07",
        rates: {
          XASX_BHP: 47.93,
          XASX_CBA: 98.9,
          XASX_NAB: 29.71,
        },
      },
      {
        base: GLOBAL_BASE_CURRENCY,
        date: "2023-03-06",
        rates: {
          XASX_BHP: 48.39,
          XASX_CBA: 98.6,
          XASX_NAB: 29.48,
        },
      },
      {
        base: GLOBAL_BASE_CURRENCY,
        date: "2023-03-03",
        rates: {
          XASX_BHP: 48.32,
          XASX_CBA: 97.75,
          XASX_NAB: 29.15,
        },
      },
      {
        base: GLOBAL_BASE_CURRENCY,
        date: "2023-03-02",
        rates: {
          XASX_BHP: 48.05,
          XASX_CBA: 97.07,
          XASX_NAB: 28.83,
        },
      },
      {
        base: GLOBAL_BASE_CURRENCY,
        date: "2023-03-01",
        rates: {
          XASX_BHP: 46.22,
          XASX_CBA: 99.05,
          XASX_NAB: 29.38,
        },
      },
    ];
    const XASX_202302 = [
      {
        base: GLOBAL_BASE_CURRENCY,
        date: "2023-02-28",
        rates: {
          XASX_BHP: 45.2,
          XASX_CBA: 100.69,
          XASX_NAB: 30.0,
        },
      },
      {
        base: GLOBAL_BASE_CURRENCY,
        date: "2023-02-27",
        rates: {
          XASX_BHP: 44.55,
          XASX_CBA: 101.39,
          XASX_NAB: 29.99,
        },
      },
      {
        base: GLOBAL_BASE_CURRENCY,
        date: "2023-02-24",
        rates: {
          XASX_BHP: 45.94,
          XASX_CBA: 101.22,
          XASX_NAB: 29.85,
        },
      },
      {
        base: GLOBAL_BASE_CURRENCY,
        date: "2023-02-23",
        rates: {
          XASX_BHP: 46.69,
          XASX_CBA: 99.9,
          XASX_NAB: 29.76,
        },
      },
      {
        base: GLOBAL_BASE_CURRENCY,
        date: "2023-02-22",
        rates: {
          XASX_BHP: 48.34,
          XASX_CBA: 99.2,
          XASX_NAB: 30.06,
        },
      },
      {
        base: GLOBAL_BASE_CURRENCY,
        date: "2023-02-21",
        rates: {
          XASX_BHP: 48.3,
          XASX_CBA: 101.52,
          XASX_NAB: 30.1,
        },
      },
      {
        base: GLOBAL_BASE_CURRENCY,
        date: "2023-02-20",
        rates: {
          XASX_BHP: 48.46,
          XASX_CBA: 102.23,
          XASX_NAB: 30.3,
        },
      },
      {
        base: GLOBAL_BASE_CURRENCY,
        date: "2023-02-17",
        rates: {
          XASX_BHP: 48.0,
          XASX_CBA: 100.97,
          XASX_NAB: 29.85,
        },
      },
      {
        base: GLOBAL_BASE_CURRENCY,
        date: "2023-02-16",
        rates: {
          XASX_BHP: 48.12,
          XASX_CBA: 101.5,
          XASX_NAB: 30.51,
        },
      },
      {
        base: GLOBAL_BASE_CURRENCY,
        date: "2023-02-15",
        rates: {
          XASX_BHP: 47.94,
          XASX_CBA: 103.0,
          XASX_NAB: 30.31,
        },
      },
      {
        base: GLOBAL_BASE_CURRENCY,
        date: "2023-02-14",
        rates: {
          XASX_BHP: 47.72,
          XASX_CBA: 109.25,
          XASX_NAB: 31.61,
        },
      },
      {
        base: GLOBAL_BASE_CURRENCY,
        date: "2023-02-13",
        rates: {
          XASX_BHP: 47.87,
          XASX_CBA: 109.3,
          XASX_NAB: 31.38,
        },
      },
      {
        base: GLOBAL_BASE_CURRENCY,
        date: "2023-02-10",
        rates: {
          XASX_BHP: 48.0,
          XASX_CBA: 109.95,
          XASX_NAB: 31.68,
        },
      },
      {
        base: GLOBAL_BASE_CURRENCY,
        date: "2023-02-09",
        rates: {
          XASX_BHP: 48.12,
          XASX_CBA: 110.21,
          XASX_NAB: 31.95,
        },
      },
      {
        base: GLOBAL_BASE_CURRENCY,
        date: "2023-02-08",
        rates: {
          XASX_BHP: 48.1,
          XASX_CBA: 110.32,
          XASX_NAB: 32.06,
        },
      },
      {
        base: GLOBAL_BASE_CURRENCY,
        date: "2023-02-07",
        rates: {
          XASX_BHP: 48.01,
          XASX_CBA: 110.3,
          XASX_NAB: 31.73,
        },
      },
      {
        base: GLOBAL_BASE_CURRENCY,
        date: "2023-02-06",
        rates: {
          XASX_BHP: 48.2,
          XASX_CBA: 110.75,
          XASX_NAB: 31.88,
        },
      },
      {
        base: GLOBAL_BASE_CURRENCY,
        date: "2023-02-03",
        rates: {
          XASX_BHP: 47.91,
          XASX_CBA: 111.15,
          XASX_NAB: 31.9,
        },
      },
      {
        base: GLOBAL_BASE_CURRENCY,
        date: "2023-02-02",
        rates: {
          XASX_BHP: 48.85,
          XASX_CBA: 109.73,
          XASX_NAB: 31.5,
        },
      },
      {
        base: GLOBAL_BASE_CURRENCY,
        date: "2023-02-01",
        rates: {
          XASX_BHP: 49.55,
          XASX_CBA: 110.15,
          XASX_NAB: 31.73,
        },
      },
    ];
    const XASX_202301 = [
      {
        base: GLOBAL_BASE_CURRENCY,
        date: "2023-01-31",
        rates: {
          XASX_BHP: 49.38,
          XASX_CBA: 110.06,
          XASX_NAB: 31.79,
        },
      },
      {
        base: GLOBAL_BASE_CURRENCY,
        date: "2023-01-30",
        rates: {
          XASX_BHP: 49.23,
          XASX_CBA: 109.76,
          XASX_NAB: 31.83,
        },
      },
      {
        base: GLOBAL_BASE_CURRENCY,
        date: "2023-01-27",
        rates: {
          XASX_BHP: 49.54,
          XASX_CBA: 109.85,
          XASX_NAB: 31.79,
        },
      },
      {
        base: GLOBAL_BASE_CURRENCY,
        date: "2023-01-25",
        rates: {
          XASX_BHP: 49.24,
          XASX_CBA: 108.83,
          XASX_NAB: 31.48,
        },
      },
      {
        base: GLOBAL_BASE_CURRENCY,
        date: "2023-01-24",
        rates: {
          XASX_BHP: 49.71,
          XASX_CBA: 108.05,
          XASX_NAB: 31.34,
        },
      },
      {
        base: GLOBAL_BASE_CURRENCY,
        date: "2023-01-23",
        rates: {
          XASX_BHP: 49.4,
          XASX_CBA: 108.61,
          XASX_NAB: 31.51,
        },
      },
      {
        base: GLOBAL_BASE_CURRENCY,
        date: "2023-01-20",
        rates: {
          XASX_BHP: 49.95,
          XASX_CBA: 108.66,
          XASX_NAB: 31.6,
        },
      },
      {
        base: GLOBAL_BASE_CURRENCY,
        date: "2023-01-19",
        rates: {
          XASX_BHP: 49.68,
          XASX_CBA: 108.74,
          XASX_NAB: 31.71,
        },
      },
      {
        base: GLOBAL_BASE_CURRENCY,
        date: "2023-01-18",
        rates: {
          XASX_BHP: 49.08,
          XASX_CBA: 107.65,
          XASX_NAB: 31.67,
        },
      },
      {
        base: GLOBAL_BASE_CURRENCY,
        date: "2023-01-17",
        rates: {
          XASX_BHP: 49.14,
          XASX_CBA: 107.44,
          XASX_NAB: 31.67,
        },
      },
      {
        base: GLOBAL_BASE_CURRENCY,
        date: "2023-01-16",
        rates: {
          XASX_BHP: 49.69,
          XASX_CBA: 107.35,
          XASX_NAB: 31.65,
        },
      },
      {
        base: GLOBAL_BASE_CURRENCY,
        date: "2023-01-13",
        rates: {
          XASX_BHP: 49.64,
          XASX_CBA: 106.5,
          XASX_NAB: 31.3,
        },
      },
      {
        base: GLOBAL_BASE_CURRENCY,
        date: "2023-01-12",
        rates: {
          XASX_BHP: 49.38,
          XASX_CBA: 105.18,
          XASX_NAB: 30.8,
        },
      },
      {
        base: GLOBAL_BASE_CURRENCY,
        date: "2023-01-11",
        rates: {
          XASX_BHP: 48.51,
          XASX_CBA: 103.53,
          XASX_NAB: 30.06,
        },
      },
      {
        base: GLOBAL_BASE_CURRENCY,
        date: "2023-01-10",
        rates: {
          XASX_BHP: 47.8,
          XASX_CBA: 103.32,
          XASX_NAB: 29.93,
        },
      },
      {
        base: GLOBAL_BASE_CURRENCY,
        date: "2023-01-09",
        rates: {
          XASX_BHP: 48.0,
          XASX_CBA: 103.32,
          XASX_NAB: 30.0,
        },
      },
      {
        base: GLOBAL_BASE_CURRENCY,
        date: "2023-01-06",
        rates: {
          XASX_BHP: 47.51,
          XASX_CBA: 103.18,
          XASX_NAB: 29.76,
        },
      },
      {
        base: GLOBAL_BASE_CURRENCY,
        date: "2023-01-05",
        rates: {
          XASX_BHP: 46.05,
          XASX_CBA: 103.32,
          XASX_NAB: 29.94,
        },
      },
      {
        base: GLOBAL_BASE_CURRENCY,
        date: "2023-01-04",
        rates: {
          XASX_BHP: 46.04,
          XASX_CBA: 103.14,
          XASX_NAB: 29.7,
        },
      },
      {
        base: GLOBAL_BASE_CURRENCY,
        date: "2023-01-03",
        rates: {
          XASX_BHP: 45.33,
          XASX_CBA: 101.05,
          XASX_NAB: 29.42,
        },
      },
    ];
    const GLOSS_ARRAY = [...XASX_202303, ...XASX_202302, ...XASX_202301];

    let dataToUse: { [index: string]: any } = {};
    if (ymd > dateStringToYmd(GLOSS_ARRAY[0].date)) {
      // sample growth rate of 7% from last
      dataToUse = { ...GLOSS_ARRAY[0] }; // clone the object
      const dateRequested = ymd;
      const dateWeHaveLatestDataFor = dateStringToYmd(dataToUse.date);
      const daysDifference = getDaysDifferenceYMD(dateRequested, dateWeHaveLatestDataFor);
      const allSymbols = Object.keys(dataToUse.rates); // putting this if ymd > ... function here is not great as we need to calculate each symbol each time, and also it assumes this date also contains all symbols
      allSymbols.forEach((sym: string) => {
        dataToUse.rates[sym] = dataToUse.rates[sym] * (1 + (0.07 * daysDifference) / 365);
        dataToUse.rates[sym] = round2dp(dataToUse.rates[sym]);
      });
    } else if (ymd >= dateStringToYmd(GLOSS_ARRAY[0].date)) {
      dataToUse = GLOSS_ARRAY[0];
    }
    if (ymd <= dateStringToYmd(GLOSS_ARRAY[GLOSS_ARRAY.length - 1].date)) {
      dataToUse = GLOSS_ARRAY[GLOSS_ARRAY.length - 1];
    }

    for (let i = GLOSS_ARRAY.length - 1; i >= 0; i--) {
      if (ymd >= dateStringToYmd(GLOSS_ARRAY[i].date)) {
        dataToUse = GLOSS_ARRAY[i];
      }
    }

    const sym = symbolGloss.replace("-", "_"); // converting XASX-BHP to XASX_BHP because my hard coded json object doesn't allow a - in the key
    if (dataToUse.rates[sym]) {
      if (base === dataToUse.base) {
        return dataToUse.rates[sym];
      } else if (base.length === 3) {
        const conversionToRequestedBase = getFromFixer(dataToUse.base, base, ymd);
        if (conversionToRequestedBase) {
          return dataToUse.rates[sym] / conversionToRequestedBase;
        }
      }
    }
  }
}

function dateStringToYmd(date: string): number {
  return parseInt(date.replace(/-/g, ""));
}

function setYMDToDate(ymd: number): Date {
  // todo
  //  KM: Need to do this a better way to accommodate for timezones (at the moment only hard coded to +8 for HK time)
  //  also, this should be an utility function or library instead of putting it here
  const d = new Date();
  d.setFullYear(Math.floor(ymd / 10000));
  d.setMonth(Math.floor((ymd % 10000) / 100) - 1);
  d.setDate(ymd % 100);
  d.setHours(8);
  d.setMinutes(0);
  d.setSeconds(0);
  d.setMilliseconds(0);
  return d;
}

function getDaysDifferenceYMD(ymdLater: number, ymdEarlier: number): number {
  const dateLater = setYMDToDate(ymdLater);
  const dateEarlier = setYMDToDate(ymdEarlier);
  const diff = dateLater.getTime() - dateEarlier.getTime();
  return diff / (1000 * 3600 * 24); // difference in days
}

function round2dp(num: number): number {
  return Math.round(num * 100) / 100;
}
