import { ConfirmationData } from "../../models/types/general-types";

export const confirmation: ConfirmationData = {
  vaultFile: {
    title: {
      text: "",
      i18Key: "areYouSureYouWannaDeleteThisFile",
    },
    message: {
      text: "You will not be able to recover this file!",
      i18Key: "",
    },
    type: "warning",
  },
};
