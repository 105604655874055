import { Injector } from "@angular/core";
import { BehaviorSubject } from "rxjs";

import { LogService } from "@bitwarden/common/abstractions/log.service";
import { GlossDate } from "@bitwarden/web-vault/app/models/data/shared/gloss-date";
import { BasiqTransactionProcessingService } from "@bitwarden/web-vault/app/services/api/basiq/transaction.processing.service";

import { AccountSyncStatus, StatusPoint } from "../../models/types/general-types";
import { ThirdPartyTransaction } from "../../models/types/transaction.types";
import { AccountView } from "../../models/view/account.view";

export class AccountSyncService {
  isMerged = false;
  id: string;
  private _bsSyncStatus = new BehaviorSubject<AccountSyncService>(null);
  syncStatus$ = this._bsSyncStatus.asObservable();

  accountView: AccountView;
  /**
   * The raw transactions that were fetched from the third party provider like Basiq or Plaid. This should be how we pass the original data type that we pass to the import when doing a regular connections
   * @type {{ data:ThirdPartyTransaction[] }}
   */
  rawTransactions: { data: ThirdPartyTransaction[] } = { data: [] };

  /**
   * Weather the account sync is completed or not. success or failure.
   */
  isCompleted = false;

  /**
   * A point at which the syncing of the account is at.
   * @type {point}
   */
  point: StatusPoint = {
    key: "started-syncing",
    icon: "sync",
    data: null,
  };

  lastSyncedAt: GlossDate = GlossDate.getCurrentGlossDate();

  private logService: LogService;
  private transactionProcessingService: BasiqTransactionProcessingService;

  constructor(accountView: AccountView, private injector: Injector) {
    this.accountView = accountView;
    this.id = accountView.id;
    this.logService = this.injector.get(LogService);
    this.transactionProcessingService = this.injector.get(BasiqTransactionProcessingService);
  }

  /**
   * Starts the synchronization process for the account.
   * This method should be overridden in a subclass to provide the specific synchronization logic.
   */
  async startSync() {
    this._bsSyncStatus.next(this);
    await this.runForSync();
    if (this.isCompleted) {
      this.logService.info(`Sync completed for account ${this.accountView.name}: ${this}`);
      this._bsSyncStatus.complete();
    }
  }

  private async runForSync() {
    try {
      // TODO : add no transactions to merge message
      this.rawTransactions = await this.transactionProcessingService.getRawBasiqTransactions(
        this.accountView.originalBook,
        true
      );

      this.isCompleted = true;
      this.point = {
        key: "ready",
        icon: "check",
        data: {
          type: "success",
          message: "Transactions fetched successfully",
          actions: [],
          messageI18nKey: "transactionsFetchSuccessfully",
        },
      };
    } catch (error) {
      this.isCompleted = true;
      this.point = {
        key: "failed",
        icon: "error",
        data: {
          type: "failure",
          message: "Failed to fetch transactions. Something went wrong.",
          actions: ["retry"],
          messageI18nKey: "transactionsFetchFailed",
        },
      };
    } finally {
      this.lastSyncedAt = GlossDate.getCurrentGlossDate();

      this._bsSyncStatus.next(this);
    }
  }

  setObserverNull() {
    this._bsSyncStatus.next(null);
  }

  getAccountStatus(): AccountSyncStatus {
    return {
      accountId: this.accountView.originalBook.id,
      isMerged: this.isMerged,
      point: this.point,
      isCompleted: this.isCompleted,
      isStarted: true,
      accountView: this.accountView,
      lastSyncedAt: this.lastSyncedAt,
      rawTransactions: this.rawTransactions.data,
    };
  }
  static getNoConsentAccountStatus(accountView: AccountView): AccountSyncStatus {
    return {
      accountId: accountView.originalBook.id,
      point: {
        key: "failed",
        icon: "error",
        data: {
          message: "User has not consented to sync data",
          type: "failure",
          actions: ["consent"],
          messageI18nKey: "userHasNotConsentedToSyncData",
        },
      },
      accountView,
      rawTransactions: [],
      isStarted: true,
      isMerged: false,
      lastSyncedAt: GlossDate.getCurrentGlossDate(),
      isCompleted: true,
    };
  }
}
