import { Book } from "@bitwarden/web-vault/app/models/data/blobby/book.data";
import { Transaction } from "@bitwarden/web-vault/app/models/data/blobby/transaction.data";
import {
  BalanceGroupingAttributes,
  GranularityType,
} from "@bitwarden/web-vault/app/models/types/balanceGroupingTypes";
import { EstimateActionsType } from "@bitwarden/web-vault/app/models/types/estimate-action.types";
import { GraphDataSet } from "@bitwarden/web-vault/app/models/types/graph.types";
import { InstitutionInterest } from "@bitwarden/web-vault/app/models/types/institution.type";
import { BalanceGrouping } from "@bitwarden/web-vault/app/services/DataCalculationService/balanceGrouping/balanceGrouping";
import { BestExistingInterestRate } from "@bitwarden/web-vault/app/services/DataCalculationService/scenarios/ScenarioOptions/best-existing-interest-rate";
import { BestNewInterestRate } from "@bitwarden/web-vault/app/services/DataCalculationService/scenarios/ScenarioOptions/best-new-interest-rate";
import { CurrentInterestRate } from "@bitwarden/web-vault/app/services/DataCalculationService/scenarios/ScenarioOptions/current-interest-rate";

// Type used to call a scenario and to return its outcome
export type GroupScenarioBalance = {
  ScenarioGroupId: string;
  scenarioID: string;
  scenarioType: ScenarioOptionsType;
  scenarioName: string;
  createdRecords: CreatedRecords;
  groupedBalance: BalanceGrouping;
  graphData: Array<GraphDataSet>;
  helpInfo?: ScenarioHelpInfo;
};

// Type used to store all the information relating to a scenario group
export type ScenarioData = {
  scenario: Array<GroupScenarioBalance>;
  balance: Array<GraphDataSet>;
  anchorPoint?: AnchorPointData;
};

export type AnchorPointData = {
  anchorDate: string;
  anchorBalance: number;
  groupedBalance: BalanceGrouping;
};

// Types relating to Scenario Options:
export const ScenarioOptionsClasses = {
  currentInterestRate: CurrentInterestRate,
  bestExistingInterestRate: BestExistingInterestRate,
  bestNewInterestRate: BestNewInterestRate,
};

export type ScenarioOptionsClassesProperties = typeof ScenarioOptionsClasses;
export type ScenarioOptionsType = keyof ScenarioOptionsClassesProperties;

export const currentInterestRate = "currentInterestRate" as ScenarioOptionsType;
export const bestExistingInterestRate = "bestExistingInterestRate" as ScenarioOptionsType;
export const bestNewInterestRate = "bestNewInterestRate" as ScenarioOptionsType;

export type InterestScenarioHelpInfo = {
  transferAmount?: number;
  transferToAccount?: ScenarioAccountInfo;
  transferFromAccount?: Array<ScenarioAccountInfo>;
  existingAccounts?: Array<ScenarioAccountInfo>;
  totalBalance?: number;
};

export type ScenarioHelpInfo = InterestScenarioHelpInfo | null;

export type ScenarioAccountInfo = {
  accountBank?: string;
  accountName: string;
  rate?: Array<InstitutionInterest>;
  accountUrl?: string;
};

// Used to define the list of estimate actions to run per scenario
export type EstimateActionProperties = {
  parameters: object;
  estimateActionType: EstimateActionsType;
  createdRecords?: CreatedRecords;
  groupedBalance?: BalanceGrouping; // note this balance grouping might only be daily and can't be used to graph with
};

// export type ScenarioPermutation = Array<EstimateActionProperties>;
export type ScenarioPermutation = {
  estimateActions: Array<EstimateActionProperties>;
  scenarioHelpInfo?: ScenarioHelpInfo;
};

export type ScenarioOptionWinner = {
  createdRecords: CreatedRecords;
  groupedBalance?: BalanceGrouping;
  helpInfo?: ScenarioHelpInfo;
};

export type CreatedRecords = {
  accounts?: Array<Book>;
  transactions?: Array<Transaction>;
  groupedBalance?: BalanceGrouping;
  groupedBalanceGranularity?: GranularityType;
  groupedBalanceAttributes?: BalanceGroupingAttributes;
};
