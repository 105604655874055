<app-spinner [showSpinner]="loading"></app-spinner>
<form class="modal-content" [formGroup]="createForm" (ngSubmit)="submitCreateForm()">
  <div class="modal-body">
    <ng-container>
      <div class="form-group mb-4">
        <mat-form-field appearance="outline">
          <mat-label>{{ "country" | i18n }}</mat-label>
          <input
            matInput
            formControlName="createdInstitutionCountry"
            autocomplete="off"
            (keyup)="searchForCreateCountry()"
            [matAutocomplete]="autoCreateCountry"
            placeholder="Pick a country"
          />
          <mat-autocomplete autoActiveFirstOption #autoCreateCountry="matAutocomplete">
            <mat-option
              *ngFor="let option of filteredCreateCountries"
              [value]="option.name"
              (onSelectionChange)="createCountrySelected(option)"
            >
              {{ option.name }}
            </mat-option>
          </mat-autocomplete>
          <mat-error *ngIf="createForm.controls.createdInstitutionCountry.hasError('required')"
            >Field is required</mat-error
          >
        </mat-form-field>
      </div>
    </ng-container>
    <ng-container>
      <div class="form-group mb-4">
        <mat-form-field appearance="outline">
          <mat-label>{{ "name" | i18n }}</mat-label>
          <input matInput formControlName="createdInstitutionName" autocomplete="off" />
          <mat-error *ngIf="createForm.controls.createdInstitutionName.hasError('required')"
            >Field is required</mat-error
          >
        </mat-form-field>
      </div>
    </ng-container>
  </div>

  <div class="modal-footer right-buttons-container">
    <button mat-raised-button mat-dialog-close (click)="closeDialogue()">
      {{ "cancel" | i18n }}
    </button>
    <button mat-raised-button color="primary" type="submit">
      {{ "save" | i18n }}
    </button>
  </div>
</form>
