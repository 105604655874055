import { Injectable } from "@angular/core";
import { addDays, addMonths, addWeeks, addYears, format, isBefore, isEqual } from "date-fns";
import { BehaviorSubject } from "rxjs";

import { LogService } from "@bitwarden/common/abstractions/log.service";
import { BalanceDirection } from "@bitwarden/common/enums/balanceDirection";
import { RecurringPeriod } from "@bitwarden/common/enums/recurringPeriod";
import { Book } from "@bitwarden/web-vault/app/models/data/blobby/book.data";
import { Estimate } from "@bitwarden/web-vault/app/models/data/blobby/estimate.data";
import { Transaction } from "@bitwarden/web-vault/app/models/data/blobby/transaction.data";
import { InterestMatchData } from "@bitwarden/web-vault/app/models/data/interest-match.data";
import { RecurringData } from "@bitwarden/web-vault/app/models/data/recurring.data";
import { EstimateActionResponse } from "@bitwarden/web-vault/app/models/data/response/estimate-action.response";
import { TransactionResponse } from "@bitwarden/web-vault/app/models/data/response/transaction-response";
import { GlossQuantity } from "@bitwarden/web-vault/app/models/data/shared/gloss-quantity";
import { Splitcategory } from "@bitwarden/web-vault/app/models/data/splitcategory";
import { Splitclassification } from "@bitwarden/web-vault/app/models/data/splitclassification";
import { SymbolData } from "@bitwarden/web-vault/app/models/data/symbol.data";
import { TransactionTemplateData } from "@bitwarden/web-vault/app/models/data/transaction-template.data";
import { Valuation } from "@bitwarden/web-vault/app/models/data/valuation.data";
import { BlobbyDataTypeEnum } from "@bitwarden/web-vault/app/models/enum/blobbyDataTypeEnum";
import { EstimateType } from "@bitwarden/web-vault/app/models/enum/estimateType";
import { TransactionDirection } from "@bitwarden/web-vault/app/models/enum/transactionDirection";
import { AllocationService } from "@bitwarden/web-vault/app/services/DataCalculationService/allocation/allocation.service";
import { EstimateService } from "@bitwarden/web-vault/app/services/DataService/estimates/estimate-service";
import { BlobbyService } from "@bitwarden/web-vault/app/services/blobby/blobby.service";
import { BlobbyUtils } from "@bitwarden/web-vault/app/services/blobby/blobbyUtils";
import { HelperPreference } from "@bitwarden/web-vault/app/shared/utils/helper.preference";

import {
  EstimateActionData,
  getEmptyEstimateActionData,
} from "../../../models/data/blobby/estimate.action.data";
import { DataRepositoryService } from "../../DataRepository/data-repository.service";
import { DataServiceAbstraction } from "../data.service.abstraction";
import { SymbolService } from "../symbol/symbol.service";

export interface EstimateByType {
  transaction: EstimateActionData[];
  interest: EstimateActionData[];
  revaluation: EstimateActionData[];
}

@Injectable({
  providedIn: "root",
})
export class EstimateActionsService implements DataServiceAbstraction {
  creationOrder: Array<EstimateType> = [
    EstimateType.Transaction,
    EstimateType.Interest,
    EstimateType.Revaluation,
  ];

  private estimate = getEmptyEstimateActionData();
  protected estimate$ = new BehaviorSubject<EstimateActionData>(this.estimate);
  _estimate = this.estimate$.asObservable();

  /**
   * @param dataRepositoryService
   * @param blobbyService
   * @param symbolService
   * @param allocationService
   * @param logService
   * @param estimateGroupService
   * @param helperPref
   */
  constructor(
    private dataRepositoryService: DataRepositoryService,
    /** @deprecated **/ private blobbyService: BlobbyService,
    private symbolService: SymbolService,
    private allocationService: AllocationService,
    private logService: LogService,
    private estimateGroupService: EstimateService,
    private helperPref: HelperPreference
  ) {
    this.helperPref.getBaseCurrency().then((baseCurrency) => {
      this.baseCurrency = baseCurrency;
    });
  }

  // TODO check if there is another method getting symbols if not create a real one
  getSymbols(): string[] {
    return ["BHK", "GBP", "USD", "HKD"];
  }

  // TODO check if there is another method getting currencies if not create a real one
  getCurrencies(): string[] {
    return ["AUD", "USD", "GBP"];
  }

  sendName(name: string) {
    this.estimate.name = name;
    this.estimate$.next(this.estimate);
  }
  sendStartDate(startDate: string) {
    this.estimate.startDate = startDate;
    this.estimate$.next(this.estimate);
  }
  sendDescription(description: string) {
    this.estimate.description = description;

    this.estimate$.next(this.estimate);
  }
  sendCategories(categories: Splitcategory[]) {
    this.estimate.categories = categories;
    this.estimate$.next(this.estimate);
  }
  sendClassifications(classifications: Splitclassification[]) {
    this.estimate.classifications = classifications;
    this.estimate$.next(this.estimate);
  }

  sendRecurring(recurring: string) {
    if (recurring === "Yes") {
      this.estimate.recurring = new RecurringData();
    } else {
      this.estimate.recurring = undefined;
    }

    this.estimate$.next(this.estimate);
  }
  sendEstimateAccount(account: Book) {
    this.estimate.account = account;
    this.estimate$.next(this.estimate);
  }
  sendEstimateType(type: EstimateType) {
    this.estimate.estimateType = type;
    this.estimate$.next(this.estimate);
  }
  sendEstimateDirection(direction: TransactionDirection) {
    this.estimate.direction = direction;
    this.estimate$.next(this.estimate);
  }

  sendInterestPercentage(percentage: number) {
    if (this.estimate.interestMatch === undefined) {
      this.estimate.interestMatch = new InterestMatchData();
    }
    this.estimate.interestMatch.percentage = percentage;
    this.estimate$.next(this.estimate);
  }

  sendInterestBalanceDirection(balanceDirection: BalanceDirection) {
    if (this.estimate.interestMatch === undefined) {
      this.estimate.interestMatch = new InterestMatchData();
    }
    this.estimate.interestMatch.balanceDirection = balanceDirection;
    this.estimate$.next(this.estimate);
  }
  sendInterestSymbol(symbol: string) {
    if (this.estimate.interestMatch === undefined) {
      this.estimate.interestMatch = new InterestMatchData();
    }
    this.estimate.interestMatch.symbol = symbol;
    this.estimate$.next(this.estimate);
  }
  sendInterestCalculationAccount(accounts: string[]) {
    if (this.estimate.interestMatch === undefined) {
      this.estimate.interestMatch = new InterestMatchData();
    }
    this.estimate.interestMatch.account = accounts;
    this.estimate$.next(this.estimate);
  }

  sendInterestCategory(category: string) {
    if (this.estimate.interestMatch === undefined) {
      this.estimate.interestMatch = new InterestMatchData();
    }
    this.estimate.interestMatch.category = category;
    this.estimate$.next(this.estimate);
  }
  sendInterestClassification(classification: string) {
    if (this.estimate.interestMatch === undefined) {
      this.estimate.interestMatch = new InterestMatchData();
    }
    this.estimate.interestMatch.classification = classification;
    this.estimate$.next(this.estimate);
  }

  sendRecurringEndDate(endDate: string) {
    if (this.estimate.recurring === undefined) {
      this.estimate.recurring = new RecurringData();
    }
    this.estimate.recurring.endDate = endDate;
    this.estimate$.next(this.estimate);
  }
  sendRecurringFrequency(recurringFrequency: number) {
    if (this.estimate.recurring === undefined) {
      this.estimate.recurring = new RecurringData();
    }
    this.estimate.recurring.recurringFrequency = recurringFrequency;
    this.estimate$.next(this.estimate);
  }
  sendRecurringPeriod(recurringPeriod: RecurringPeriod) {
    if (this.estimate.recurring === undefined) {
      this.estimate.recurring = new RecurringData();
    }
    this.estimate.recurring.recurringPeriod = recurringPeriod;
    this.estimate$.next(this.estimate);
  }

  sendTransactionCurrency(currency: string) {
    if (this.estimate.transactionTemplate === undefined) {
      this.estimate.transactionTemplate = new TransactionTemplateData();
    }
    this.estimate.transactionTemplate.currency = currency;
    this.estimate$.next(this.estimate);
  }
  sendTransactionQuantity(quantity: number) {
    if (this.estimate.transactionTemplate === undefined) {
      this.estimate.transactionTemplate = new TransactionTemplateData();
    }
    this.estimate.transactionTemplate.quantity = quantity;
    this.estimate$.next(this.estimate);
  }
  sendTransactionSymbol(symbol: string) {
    if (this.estimate.transactionTemplate === undefined) {
      this.estimate.transactionTemplate = new TransactionTemplateData();
    }
    this.estimate.transactionTemplate.symbol = symbol;
    this.estimate$.next(this.estimate);
  }

  getEstimate() {
    return this.estimate;
  }
  setEstimate(estimate: EstimateActionData) {
    const actionResponse = new EstimateActionResponse(estimate);
    this.estimate = new EstimateActionData(actionResponse);
    this.estimate$.next(this.estimate);
  }

  protected _projectedEstimates = new BehaviorSubject<Transaction[]>([]);

  projectedEstimates$ = this._projectedEstimates.asObservable();

  baseCurrency: string;

  existingTransactions: Array<Transaction>;
  projectedTransactions: Array<Transaction>;
  projectedRevals: Array<Transaction>;

  async create(estimate: Estimate): Promise<Estimate> {
    /*    if (estimate.groupId) {
      return await this.dataRepositoryService.createEstimateToExistingGroup(estimate);
    } else {
      return await this.dataRepositoryService.createEstimateToNewGroup(estimate);
    }*/

    return this.dataRepositoryService.createEstimate(estimate);
  }

  async createGroup(estimateGroup: Estimate): Promise<Estimate> {
    return await this.dataRepositoryService.createEstimateGroup(estimateGroup);
  }

  async delete(estimate: Estimate, triggerObservable?: boolean): Promise<boolean> {
    return this.dataRepositoryService.deleteEstimate(estimate);
  }
  async deleteEstimateGroup(
    estimateGroup: Estimate,
    triggerObservable?: boolean
  ): Promise<boolean> {
    return this.dataRepositoryService.deleteEstimateGroup(estimateGroup);
  }

  /** todo : @Sinan if there are too many estimates then it could create a problem of speed */
  /*  async deleteEstimatesOfGroup(
    estimateGroup: Estimate,
    triggerObservable?: boolean
  ): Promise<boolean> {
    const allEstimates = await this.getAll();
    for (const estimate of allEstimates) {
      if (estimate.groupId === estimateGroup.id) {
        const estimateDeleted = await this.delete(estimate);
        if (!estimateDeleted) {
          return false;
        }
      }
    }

    return true;
  }*/

  async get(itemId: string): Promise<EstimateActionData> {
    return Promise.resolve(undefined);
  }

  async getAll(): Promise<EstimateActionData[]> {
    // return await this.dataRepositoryService.getAllEstimates();
    return [];
  }

  async update(item: Estimate, triggerObservable?: boolean): Promise<Estimate> {
    try {
      return await this.dataRepositoryService.updateEstimate(item);
      /*    const estimateGroup = await this.dataRepositoryService.getEstimateGroupById(item.groupId);
      estimateGroup.estimateActions = estimateGroup.estimateActions.map((estimate) => {
        const e = new Estimate(new EstimateResponse(estimate));
        if (e.id === item.id) {
          estimate = item;
        }
        return estimate;
      });
      return this.updateGroup(estimateGroup);*/
    } catch (e) {
      this.logService.error(e);
    }
  }

  async updateGroup(item: Estimate, triggerObservable?: boolean): Promise<Estimate> {
    return await this.dataRepositoryService.updateEstimateGroup(item);
  }

  /**
   * updateDates - function is triggered when dates are updated on the projections dashboard
   *
   * @param startDate - start date selected in the UI
   * @param endDate - end date selected in the UI
   * @param frequency - frequency is the number related to the granularity that is selected in the UI
   * @param period - represents the time frame of the granularity such as days, weeks, months or years
   */
  async updateDates(
    startDate: string,
    endDate: string,
    frequency: number,
    period: RecurringPeriod
  ): Promise<void> {
    // when dates are updated we need get an updated list of real transactions, we need to generate new transactions from
    // estimates and we need to generate new reval transactions
    this.existingTransactions = await this.addRealTransactions(startDate, endDate);
    this.projectedTransactions = await this.generateEstimateTransactions(startDate, endDate);
    this.projectedRevals = await this.generateRevalTransactions(
      startDate,
      endDate,
      frequency,
      period,
      null,
      null
    );
    // trigger the updated to the table and the graphs on the projections dashboard
    this.triggerObservable();
  }

  /**
   * updateGranularity - function is triggered when the granularity is updated in the UI
   *
   * @param startDate - start date selected in the UI
   * @param endDate - end date selected in the UI
   * @param frequency - frequency is the number related to the granularity that is selected in the UI
   * @param period - represents the time frame of the granularity such as days, weeks, months or years
   */
  async updateGranularity(
    startDate: string,
    endDate: string,
    frequency: number,
    period: RecurringPeriod
  ): Promise<void> {
    // when the granularity is updated but not the dates, then we just need to generate the reval transactions again
    // estimates and real transactions remain the same if the dates are not updated
    this.projectedRevals = await this.generateRevalTransactions(
      startDate,
      endDate,
      frequency,
      period,
      null,
      null
    );
    // trigger the updated to the table and the graphs on the projections dashboard
    this.triggerObservable();
  }

  /**
   * addRealTransactions -   This function gets the real exisitng transactions from blobby that are between the
   *                        start date and the end date
   *
   * @param startDate - start date selected in the UI
   * @param endDate - end date selected in the UI
   */
  async addRealTransactions(startDate: string, endDate: string) {
    let realTransactions: Array<Transaction> = [];

    if (startDate === null || endDate === null) {
      return realTransactions;
    }

    // convert start date and end date to a number YMD
    const startDateYMD = BlobbyUtils.setDateToYMD(new Date(startDate));
    let endDateYMD = BlobbyUtils.setDateToYMD(new Date(endDate));
    const currentYMD = BlobbyUtils.setDateToYMD(new Date());

    // if the current date is before the end date then we should set the end date to the current date
    // existing real transactions should not be in the future
    if (currentYMD < endDateYMD) {
      endDateYMD = currentYMD;
    }

    if (startDateYMD < endDateYMD) {
      // get all the accounts
      const accounts = await this.dataRepositoryService.getAllBooks();

      // get all the relevant transactions for each account
      for (const account of accounts) {
        const accountTransactions = this.blobbyService.getTransactionsInRange(
          account.id,
          startDateYMD,
          endDateYMD
        );
        realTransactions = realTransactions.concat(accountTransactions);
      }
    }
    return realTransactions;
  }

  /**
   * generateEstimateTransactions - This function is called to create transactions from estimates that are stored in
   *                                blobby. Based on the type of estimate it is, it may create a one off transactions
   *                                or it may create a list of transactions based on the recurring period and frequency.
   * @param startDate - start date selected in the UI
   * @param endDate - end date selected in the UI
   * @param accounts - optional account limitation // TODO: not implemented completely yet
   * @param direction - optional direction of transactions we want to generate // TODO: not implemented completely yet
   */
  async generateEstimateTransactions(
    startDate: string,
    endDate: string,
    accounts?: Array<string> | null,
    direction?: string | null
  ) {
    const estimates: EstimateActionData[] =
      await this.estimateGroupService.getMergeEstimateGroups();
    const startDateWindow = new Date(startDate).getTime();
    const endDateWindow = new Date(endDate).getTime();
    const estimatesSorted: EstimateByType = {
      transaction: <EstimateActionData[]>[],
      interest: <EstimateActionData[]>[],
      revaluation: <EstimateActionData[]>[],
    };

    // iterate through the future estimates and generate the transactions that apply
    for (const estimate of estimates) {
      const transStartDate = new Date(estimate.startDate).getTime();
      const transAccount = estimate.account.name;

      let transEndDate = null;
      if (estimate.recurring && estimate.recurring.endDate) {
        transEndDate = new Date(estimate.recurring.endDate).getTime();
      }

      // skip anything that ends before the startdate
      if (transEndDate && transEndDate > startDateWindow) {
        continue;
      }
      // skip anything that starts after the enddate
      if (transStartDate && transStartDate > endDateWindow) {
        continue;
      }

      // skip if the direction set and not included
      if (direction && estimate.direction !== direction) {
        continue;
      }

      // skip if account is set and not included
      if (accounts && !accounts.includes(transAccount)) {
        continue;
      }

      // sort the estimates by type
      estimatesSorted[estimate.estimateType].push(estimate);
    }

    const estimateTransactions: Array<Transaction> = [];

    // process estimates based on the predefined creationOrder, transaction type before interest
    for (const type of this.creationOrder) {
      const estimatesToProcess = estimatesSorted[type];
      for (const estimate of estimatesToProcess) {
        const futureStartDateTime = new Date(estimate.startDate).getTime();
        let futureEndDate = new Date(endDate);
        let recurring = false;

        // find the start date
        let futureStartDate = new Date(estimate.startDate);
        // if the recurring start date is after the window, then use that as the start date
        if (startDateWindow > futureStartDateTime) {
          futureStartDate = new Date(startDate);
        }
        // find the end date
        if (estimate.recurring) {
          recurring = true;
          if (estimate.recurring.endDate) {
            const transEndDateTime = new Date(estimate.recurring.endDate).getTime();
            // if the end date of the recurring window is before the end date of the window then use it
            if (transEndDateTime < endDateWindow) {
              futureEndDate = new Date(estimate.recurring.endDate);
            }
          }
        } else {
          // not recurring, just one off creation
          futureEndDate = futureStartDate;
        }

        let movingDate = futureStartDate;

        // iterate through the time periods and create the transactions
        while (movingDate.getTime() <= futureEndDate.getTime()) {
          const categories = this.estimateGroupService.getCategoriesFromEstimateGroup(
            estimate.categories
          );
          const classifications = this.estimateGroupService.getClassificationsFromEstimateGroup(
            estimate.classifications
          );
          const transResData = {
            Account: estimate.account.name,
            Direction: estimate.direction,
            Description: estimate.description,
            Categories: categories,
            Classifications: classifications,
          };

          // add the type specific details to the transaction
          if (type === EstimateType.Transaction) {
            this.addTransactionData(estimate, transResData);
          } else if (type == EstimateType.Interest) {
            await this.addInterestData(estimate, transResData, movingDate, estimateTransactions);
          }

          // create the new transaction
          const transactionResponse = new TransactionResponse(transResData);
          // set the date to the movingDate
          transactionResponse.transactionDate = format(movingDate, "yyyy-MM-dd");
          const recurringTransaction = new Transaction(transactionResponse);
          // recurringTransaction.setValuation();
          estimateTransactions.push(recurringTransaction);

          if (recurring) {
            // update the movingDate to the next recurring date
            if (estimate.recurring.recurringPeriod === RecurringPeriod.Days) {
              movingDate = addDays(movingDate, estimate.recurring.recurringFrequency);
            } else if (estimate.recurring.recurringPeriod === RecurringPeriod.Weeks) {
              movingDate = addWeeks(movingDate, estimate.recurring.recurringFrequency);
            } else if (estimate.recurring.recurringPeriod === RecurringPeriod.Months) {
              movingDate = addMonths(movingDate, estimate.recurring.recurringFrequency);
            } else if (estimate.recurring.recurringPeriod === RecurringPeriod.Years) {
              movingDate = addYears(movingDate, estimate.recurring.recurringFrequency);
            }
            continue;
          } else {
            break;
          }
        }
      }
    }
    return estimateTransactions;
  }

  /**
   * addTransactionData - adds the extra details required for a transaction type estimate transaction
   *
   * @param estimate - the original estimate data
   * @param transResData - the transaction response we need to generate to create the transaction with
   */
  addTransactionData(estimate: EstimateActionData, transResData: any) {
    if (estimate.transactionTemplate) {
      const glossQuantity = new GlossQuantity();
      glossQuantity.currency = estimate.transactionTemplate.currency;
      glossQuantity.setQuantityAmountSymbol(
        estimate.transactionTemplate.quantity,
        estimate.transactionTemplate.symbol
      );

      transResData.quantity = glossQuantity;
    }
  }

  /**
   * addInterestData - adds the extra details required for an interest type estimate transaction
   *
   * @param estimate - the original estimate data
   * @param transResData - the transaction response we need to generate to create the transaction with
   * @param futureDate - the date in the future we are creating the transaction for
   * @param estimateTransactions - the list of generated transactions so far that needs to be used to get the closing balance
   */
  async addInterestData(
    estimate: EstimateActionData,
    transResData: any,
    futureDate: Date,
    estimateTransactions: Array<Transaction>
  ) {
    // the interestMatch component must be set for an interest type future estimate
    if (!estimate.interestMatch) {
      return;
    }

    let mergeClass = true;
    let mergeCat = true;

    if (estimate.interestMatch.classification) {
      mergeClass = false;
    }
    if (estimate.interestMatch.category) {
      mergeCat = false;
    }

    let balanceTotal = 0;

    // TODO: This section can be rewritten to use the new functions to the balance from blobby
    // get the current balance matrix from blobby
    for (const account of estimate.interestMatch.account) {
      const symbolListForAccount = await this.symbolService.query(
        (s: SymbolData) => s.accountId == account
      );
      for (const accountSymbol of symbolListForAccount) {
        const accountBalances = await this.allocationService.getBalanceClose(
          account,
          accountSymbol.name,
          mergeClass,
          mergeCat,
          BlobbyUtils.setDateToYMD(futureDate),
          null,
          estimateTransactions
        );

        // get the balances and add them together if the criteria matches
        let accountBalance = 0;

        accountBalances.forEach((classCats) => {
          // check if the classification and category-renderer match the interest items
          if (
            estimate.interestMatch.classification &&
            estimate.interestMatch.classification != classCats.classification
          ) {
            // skip because classification doesn't match
            return;
          } else if (
            estimate.interestMatch.category &&
            estimate.interestMatch.category != classCats.category
          ) {
            // skip because category-renderer doesn't match
            return;
          }
          // TODO: Add a clause to check for symbol matching once the getBalance
          // returns the break down with symbols included
          /*
          else if (estimate.interestMatch.symbol &&
            estimate.interestMatch.symbol != classCats.symbol) {
            return
          }
          */

          // account, classification and category-renderer are a match to add it to the balance
          accountBalance = accountBalance + classCats.value.symbolAmount.amount;
        });

        // check if the accountBalance matches the direction
        // add the accountBalance to the total balance if the direction is correct
        if (estimate.interestMatch.balanceDirection === BalanceDirection.Own) {
          if (accountBalance > 0) {
            balanceTotal = balanceTotal + accountBalance;
          }
        } else if (estimate.interestMatch.balanceDirection === BalanceDirection.Owe) {
          if (accountBalance < 0) {
            balanceTotal = balanceTotal + accountBalance;
          }
        }
      }
    }
    // calculate the current balance with the parameters from interestMatch
    balanceTotal = Math.abs(balanceTotal);

    const interestAmount = (balanceTotal * estimate.interestMatch.percentage) / 100;

    const glossQuantity = new GlossQuantity();
    glossQuantity.setQuantityAmount(interestAmount);
    transResData.quantity = glossQuantity;
  }

  /**
   * generateRevalTransactions - function that generates all the future revals and currency revals
   *  note - if the frequency is monthly then we always reval on the 1st
   *       - if the frequency is weekly we just reval out from the startdate
   *
   * @param startDate - start date selected in the UI
   * @param endDate - end date selected in the UI
   * @param frequency - frequency is the number related to the granularity that is selected in the UI
   * @param period - represents the time frame of the granularity such as days, weeks, months or years
   * @param filterAccounts - optional list of accounts to only create reval transactions for // TODO: not implemented completely
   * @param direction - optional direction of transactions to be create for // TODO: not implemented
   */
  async generateRevalTransactions(
    startDate: string,
    endDate: string,
    frequency: number,
    period: RecurringPeriod,
    filterAccounts?: Array<string> | null,
    direction?: string | null
  ) {
    const revalTransactions: Array<Transaction> = [];

    if (startDate === null || endDate === null) {
      return revalTransactions;
    }

    let startDateDt = new Date(startDate);
    const endDateDt = new Date(endDate);

    // adjust the start date to be the first of each month if the period is monthly
    if (period === RecurringPeriod.Months) {
      startDateDt = new Date(startDateDt.getFullYear(), startDateDt.getMonth(), 1);
    }

    // get all the accounts
    const blobbyResponse = await this.blobbyService.getAll(BlobbyDataTypeEnum.Book);
    const accounts = blobbyResponse.books;

    const periods: Array<Date> = [startDateDt];

    for (
      let i = BlobbyUtils.nextPeriod(startDateDt, period, frequency);
      isBefore(i, endDateDt) || isEqual(i, endDateDt);
      i = BlobbyUtils.nextPeriod(i, period, frequency)
    ) {
      periods.push(i);
    }

    for (const account of accounts) {
      // go through each time period and get all the symbols that were active before and
      // symbols that have been active
      for (let i = 0; i < periods.length - 1; i++) {
        // calculate the final date of the last period, the starting date of this period,
        // the last date of this period and the starting date of the next period
        const periodStart = periods[i];
        const nextPeriodStart = periods[i + 1];
        const prevEndDate = new Date(new Date(periodStart).setDate(periodStart.getDate() - 1));
        const currentPeriodEndDate = new Date(
          new Date(nextPeriodStart).setDate(nextPeriodStart.getDate() - 1)
        );

        // find a list of symbols on this account that had an existing balance on the first day
        // of this period
        const closingSymbols = await this.symbolService.getSymbolsWithBalance(
          account.id,
          BlobbyUtils.setDateToYMD(periodStart)
        );

        // create a reval for this symbol for the revalation since the end of the last period and the end of the
        // current period
        for (const symbol of closingSymbols) {
          await this.addReval(
            symbol,
            account,
            revalTransactions,
            prevEndDate,
            currentPeriodEndDate
          );
        }

        // process a list of symbols that were active in this current period
        const activeSymbols = this.symbolService.getActiveSymbols(
          account.id,
          BlobbyUtils.setDateToYMD(periodStart),
          BlobbyUtils.setDateToYMD(currentPeriodEndDate)
        );

        // for each of the active symbols in this month, get their relevant transactions
        for (const symbol of activeSymbols) {
          // get the transactions in between the start date and endate for each symbol
          const activeTransactions = this.blobbyService.getTransactionsInRange(
            account.id,
            BlobbyUtils.setDateToYMD(periodStart),
            BlobbyUtils.setDateToYMD(currentPeriodEndDate)
          );

          // for each transaction, reval the values and add to the corresponding array
          // go through each of the active transactions
          for (const transaction of activeTransactions) {
            // filter out any transactions not relating to the current symbol
            if (transaction.quantity.actualQuantity.symbol === symbol) {
              // create a reval transaction for the active transaction for the end of the current period
              await this.addReval(
                symbol,
                account,
                revalTransactions,
                null, // we reval it from the transaction date, not the last end date of the period
                currentPeriodEndDate,
                transaction // supply the transaction, so we can get the date, price and the conv rate to reval
              );
            }
          }
        }
      }
    }

    return revalTransactions;
  }

  /**
   * addReval - function is used to create the new reval transactions for changes in symbol value and changes in currency
   *            conversion rates and appends them to the revalTransactions array
   *
   * @param symbol - the symbol that needs revaling
   * @param account - the account that we are creating the reval in
   * @param revalTransactions - the existing array of transactions to
   * @param prevDate - the last date of the previous time period that we need to reval from. Is set to null if the
   *                    reval is being created for an active transaction as we use the transaction date instead
   * @param periodEndDate - the last date of the current period that the reval transactions will be created on
   * @param transaction - this parameter is supplied if the reval transaction is being created for an active transaction
   * @private
   */
  private async addReval(
    symbol: string,
    account: Book,
    revalTransactions: Array<Transaction>,
    prevDate: Date | null,
    periodEndDate: Date,
    transaction?: Transaction
  ) {
    if (symbol === this.baseCurrency) {
      // no revals are created or required for the base currency
      return;
    }
    let relatedToBase = false;

    // check if the symbol is related to the base currency
    const symbolInfo = await this.blobbyService.getSymbolInfo(symbol);
    if (symbolInfo) {
      // if the symbol is related to the base currency
      if (symbolInfo.valuedIn === this.baseCurrency) {
        relatedToBase = true;
      }
    }
    let descriptionVal = "Reval Transaction for Symbol " + symbol;
    let relativeTo = symbolInfo ? symbolInfo.valuedIn : this.baseCurrency;
    if (symbolInfo && symbol === symbolInfo.valuedIn) {
      // if the symbol is the same as the valuedIn, then the symbol must be a currency and we should fetch
      // its value relative to the base currency
      relativeTo = this.baseCurrency;
      relatedToBase = true;

      // set the description for currency revals
      descriptionVal = "Reval Transaction for Currency " + symbol;
    }
    let descriptionCur = "Reval Transaction for " + symbol + " for currency " + relativeTo;
    let symbolQty = 0;
    let prevEndSymbolValue = 0;
    let prevSymbolToBaseRate = 0;

    // if the reval is for a closing symbol and not an active transaction
    if (prevDate && !transaction) {
      // get the closing balance quantity of the symbol at the last end date
      const prevEndPeriodBalance = await this.allocationService.getBalanceClose(
        account.name,
        symbol,
        true,
        true,
        BlobbyUtils.setDateToYMD(prevDate),
        null,
        this.projectedTransactions
      );

      if (
        prevEndPeriodBalance.length > 0 &&
        prevEndPeriodBalance[0] &&
        prevEndPeriodBalance[0].value.symbolAmount.amount
      ) {
        // the quantity of the symbol at closing of the last period end date
        symbolQty = prevEndPeriodBalance[0].value.symbolAmount.amount;
      }

      // get the value of the symbol at the last end date
      const prevEndSymbolInfo = await this.symbolService.getSymbolValuesRelative(
        symbol,
        relativeTo,
        BlobbyUtils.setDateToYMD(prevDate),
        BlobbyUtils.setDateToYMD(prevDate),
        RecurringPeriod.Days,
        1
      );

      if (prevEndSymbolInfo && prevEndSymbolInfo.length > 0 && prevEndSymbolInfo[0].value) {
        // value of the symbol at the last end date
        prevEndSymbolValue = prevEndSymbolInfo[0].value;
      }
    } else {
      // the reval is being created for an active transaction
      descriptionVal =
        "Transaction Reval for Symbol " + symbol + " (" + transaction.transactionDate + ")";
      descriptionCur =
        "Transaction Reval for " +
        symbol +
        " for currency " +
        relativeTo +
        " (" +
        transaction.transactionDate +
        ")";

      // get the quantity from the transaction that we are looking at
      symbolQty = transaction.quantity.actualQuantity.amount;

      // if the direction is out, then the quantity is actually a negative value
      if (transaction.direction === TransactionDirection.Out) {
        symbolQty = -transaction.quantity.actualQuantity.amount;
      }

      // get the price from the transaction
      if (transaction.valuationPrice) {
        prevEndSymbolValue = transaction.valuationPrice;
      }

      // get the conversion rate from the transaction
      if (transaction.quantity.convrate) {
        prevSymbolToBaseRate = transaction.quantity.convrate;
      }
    }

    // get the value of the symbol at the reval date
    const currentSymbolInfo = await this.symbolService.getSymbolValuesRelative(
      symbol,
      relativeTo,
      BlobbyUtils.setDateToYMD(periodEndDate),
      BlobbyUtils.setDateToYMD(periodEndDate),
      RecurringPeriod.Days,
      1
    );

    let currentSymbolValue = 0;
    if (currentSymbolInfo && currentSymbolInfo.length > 0 && currentSymbolInfo[0].value) {
      // value of the symbol at the end of the current period
      currentSymbolValue = currentSymbolInfo[0].value;
    }

    // if there was a change in the symbol value we need to create a reval transaction
    if (prevEndSymbolValue !== currentSymbolValue && symbolQty !== 0) {
      const differenceSymbolValue = currentSymbolValue - prevEndSymbolValue;
      const differenceBalanceSym = symbolQty * differenceSymbolValue;
      const direction =
        differenceBalanceSym > 0 ? TransactionDirection.In : TransactionDirection.Out;

      // create the reval transaction for the change in value
      const transResData = {
        Account: account.name,
        Direction: direction,
        Description: descriptionVal,
        Symbol: symbol,
        Currency: relativeTo,
        Quantity: 0, // revals have zero quantity
      };
      const transactionResponseSym = new TransactionResponse(transResData);
      transactionResponseSym.transactionDate = format(periodEndDate, "yyyy-MM-dd");

      const valuationSym = new Valuation();
      valuationSym.setValueSymbol(relativeTo);
      valuationSym.symbolValue = currentSymbolValue;
      valuationSym.symbolValuePrior = prevEndSymbolValue;

      // if the value is not in the base currency then normalize it
      if (!relatedToBase) {
        if (symbolInfo && prevDate) {
          const prevSymbolToBaseInfo = await this.symbolService.getSymbolValuesRelative(
            symbolInfo.valuedIn,
            this.baseCurrency,
            BlobbyUtils.setDateToYMD(prevDate),
            BlobbyUtils.setDateToYMD(prevDate),
            RecurringPeriod.Days,
            1
          );

          if (
            prevSymbolToBaseInfo &&
            prevSymbolToBaseInfo.length > 0 &&
            prevSymbolToBaseInfo[0].value
          ) {
            prevSymbolToBaseRate = prevSymbolToBaseInfo[0].value;
          }
        }

        valuationSym.setNormalizedAmount(Math.abs(differenceBalanceSym) / prevSymbolToBaseRate);
        valuationSym.normalizingRate = prevSymbolToBaseRate;
      } else {
        valuationSym.setNormalizedAmount(Math.abs(differenceBalanceSym));
        valuationSym.normalizingRate = 1;
      }

      // create the reval transaction and add to the revalTransactions array
      const revalTransactionSym = new Transaction(transactionResponseSym);
      revalTransactionSym.valuation = valuationSym;
      revalTransactions.push(revalTransactionSym);
    }

    // if the symbol is not related to the base currency we need to check if we need to create a currency reval
    // transaction for it
    if (symbolInfo && !relatedToBase) {
      // get the rate compared to base for the last date of the previous period
      if (prevDate) {
        const prevSymbolToBaseInfo = await this.symbolService.getSymbolValuesRelative(
          symbolInfo.valuedIn,
          this.baseCurrency,
          BlobbyUtils.setDateToYMD(prevDate),
          BlobbyUtils.setDateToYMD(prevDate),
          RecurringPeriod.Days,
          1
        );

        if (
          prevSymbolToBaseInfo &&
          prevSymbolToBaseInfo.length > 0 &&
          prevSymbolToBaseInfo[0].value
        ) {
          // the conv rate on the last date of the previous period
          prevSymbolToBaseRate = prevSymbolToBaseInfo[0].value;
        }
      }

      // get the conversion rate compared to base at the end of the current period
      const currentSymbolToBaseInfo = await this.symbolService.getSymbolValuesRelative(
        symbolInfo.valuedIn,
        this.baseCurrency,
        BlobbyUtils.setDateToYMD(periodEndDate),
        BlobbyUtils.setDateToYMD(periodEndDate),
        RecurringPeriod.Days,
        1
      );

      let currentSymbolToBaseRate = 0;
      if (
        currentSymbolToBaseInfo &&
        currentSymbolToBaseInfo.length > 0 &&
        currentSymbolToBaseInfo[0].value
      ) {
        currentSymbolToBaseRate = currentSymbolToBaseInfo[0].value;
      }

      // if there is a difference in the conversion rate, calculate the reval and create the transaction for the reval
      if (prevSymbolToBaseRate !== currentSymbolToBaseRate && symbolQty !== 0) {
        const differenceSymbolToBaseValue = 1 / currentSymbolToBaseRate - 1 / prevSymbolToBaseRate;
        const differenceBalanceCur = symbolQty * differenceSymbolToBaseValue * currentSymbolValue;
        const direction =
          differenceBalanceCur > 0 ? TransactionDirection.In : TransactionDirection.Out;

        // create the reval transaction for the change in value
        const transResData = {
          Account: account.name,
          Direction: direction,
          Description: descriptionCur,
          Symbol: symbol,
          Currency: relativeTo,
          Quantity: 0, // revals have zero quantity
        };
        const transactionResponseCur = new TransactionResponse(transResData);
        transactionResponseCur.transactionDate = format(periodEndDate, "yyyy-MM-dd");

        const valuationCur = new Valuation();
        valuationCur.setNormalizedAmountSymbol(Math.abs(differenceBalanceCur), relativeTo);
        valuationCur.setValueSymbol(this.baseCurrency);
        valuationCur.normalizingRate = currentSymbolToBaseRate;
        valuationCur.normalizingRatePrior = prevSymbolToBaseRate;

        const revalTransactionCur = new Transaction(transactionResponseCur);
        revalTransactionCur.valuation = valuationCur;

        // add the currency reval transaction to revalTransactions
        revalTransactions.push(revalTransactionCur);
      }
    }
  }

  /**
   * triggerObservable - combines the projected transactions created from estimates with the projected revals
   * and the real transactions for the time frame and triggers the observable to change state for
   * everything subscribed to it
   */
  triggerObservable() {
    // combine the transactions together
    const transactions = this.projectedTransactions.concat(
      this.projectedRevals,
      this.existingTransactions
    );
    // trigger the observable
    this._projectedEstimates.next(transactions);
  }

  private async generateStateEstimate(glossAccount: Book): Promise<void> {
    /*
       get The Estimate Group
       if (!estimateGroup){
        cont estimateGroup = createEstimateGroup
       }
    * if glossAccount.hasType
    *   getInterestRate for the account
    *   create the estimateObject
    *   generate estimate in the estimateGroup

    return estimate
    * */
  }
  /*  private async generateOptimalEstimate(glossAccount:Book):Promise<void>{}
  private async generateBestEstimate(glossAccount:Book):Promise<void>{}*/
  private async generateAllEstimatesOfBook(glossAccount: Book) {
    await this.generateStateEstimate(glossAccount);
    /* await this.generateOptimalEstimate(glossAccount);
     await this.generateBestEstimate(glossAccount);*/
  }

  /*  private generateAvailableEstimatesOfBook(glossAccount:Book){}*/
  async generateAutoBookEstimates(glossAccount: Book) {
    const hasAccountType =
      glossAccount.institutionLink &&
      glossAccount.institutionLink.institutionId &&
      glossAccount.institutionLink.institutionAccountId;

    if (hasAccountType) {
      await this.generateAllEstimatesOfBook(glossAccount);
    } /*else{
      this.generateAvailableEstimatesOfBook(glossAccount);
    }*/
  }
}
