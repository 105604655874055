import { Injectable } from "@angular/core";

import { Transaction } from "@bitwarden/web-vault/app/models/data/blobby/transaction.data";
import { potentialTransaction } from "@bitwarden/web-vault/app/validators/base-validator";

import { SourceTransaction } from "../../../models/data/blobby/source-transaction.data";
import { PlaidValidator } from "../../../validators/plaid-validator";
import { DataRepositoryService } from "../../DataRepository/data-repository.service";
import { BlobbyService } from "../../blobby/blobby.service";
import { DataTransformer } from "../../dto/data-transformer";
import { DataServiceAbstraction } from "../data.service.abstraction";

@Injectable({
  providedIn: "root",
})
export class SourceImportService implements DataServiceAbstraction {
  constructor(
    private dataRepositoryService: DataRepositoryService,
    private blobbyService: BlobbyService,
    private validator: PlaidValidator
  ) {}

  async getAll() {
    return await this.dataRepositoryService.getAllSourceTransactions();
  }

  async create(sourceTransaction: SourceTransaction): Promise<SourceTransaction> {
    return await this.dataRepositoryService.createSourceTransaction(sourceTransaction);
  }

  async bulkCreate(sourceTransactions: SourceTransaction[]) {
    return await this.dataRepositoryService.bulkCreateSourceTransaction(sourceTransactions);
  }

  async bulkDelete(sourceTransactions: SourceTransaction[]) {
    return await this.dataRepositoryService.bulkDeleteSourceTransaction(sourceTransactions);
  }

  async delete(item: SourceTransaction, triggerObservable?: boolean): Promise<boolean> {
    return await this.dataRepositoryService.deleteSourceTransaction(item);
  }

  async get(id: string): Promise<SourceTransaction> {
    const sourceTransactions = await this.getAll();
    return sourceTransactions.find((sourceTransaction) => sourceTransaction.id === id);
  }

  async update(item: SourceTransaction, triggerObservable: boolean): Promise<SourceTransaction> {
    return Promise.resolve(undefined);
  }

  /**
   * Do a validation to check whether the SourceImport does exist
   * if not then save it otherwise ignore it
   *
   * @param records
   */
  async processSourceImport(records: SourceTransaction[] | Transaction[]) {
    const vaultTrans = await this.getAll();
    const sourceImport = DataTransformer.castToSourceTransactionArray(records);

    const validated = await this.validator.matchSourceImport(sourceImport, vaultTrans);
    const newRecords: potentialTransaction[] = validated.newRecord;

    // todo fix that casting, we should not need to do that
    // doing a cast after this.validator.matchSourceImport
    const newSourceTransactions: Array<SourceTransaction> = [];
    for (const potential of newRecords) {
      if (potential instanceof SourceTransaction) {
        newSourceTransactions.push(potential);
      }
    }

    if (newSourceTransactions.length > 0) {
      await this.bulkCreate(newSourceTransactions);
    }
  }
}
