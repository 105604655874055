import { ScrollingModule } from "@angular/cdk/scrolling";
import { NgModule } from "@angular/core";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatChipsModule } from "@angular/material/chips";
import { MatNativeDateModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatDividerModule } from "@angular/material/divider";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSelectModule } from "@angular/material/select";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { MatTabsModule } from "@angular/material/tabs";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatTooltipModule } from "@angular/material/tooltip";
import { NgSelectModule } from "@ng-select/ng-select";
import { AgGridAngular } from "ag-grid-angular";

import { AccordionComponent } from "@bitwarden/web-vault/app/components/accordion/accordion.component";
import { AccountWizardStepperComponent } from "@bitwarden/web-vault/app/components/account-wizard-stepper/account-wizard-stepper.component";
import { ArrangeColumnsTableComponent } from "@bitwarden/web-vault/app/components/arrange-columns-table/arrange-columns-table.component";
import { AutocompleteComponent } from "@bitwarden/web-vault/app/components/autocomplete/autocomplete.component";
import { BankIconsComponent } from "@bitwarden/web-vault/app/components/bank-icons/bank-icons.component";
import { TileButtonComponent } from "@bitwarden/web-vault/app/components/buttons/tile-button.component";
import { AccountActionButtonsCellRenderer } from "@bitwarden/web-vault/app/components/cell-renderers/account-action-buttons-renderer/account-action-buttons-cell-renderer.component";
import { AggregateAmountCellRenderer } from "@bitwarden/web-vault/app/components/cell-renderers/aggregate-amount-renderer/aggregate-amount-cell-renderer.component";
import { BalanceCellRenderer } from "@bitwarden/web-vault/app/components/cell-renderers/balance-renderer/balance-renderer.component";
import { BookCellRenderer } from "@bitwarden/web-vault/app/components/cell-renderers/book-renderer/book-renderer.component";
import { CategoryCellRenderer } from "@bitwarden/web-vault/app/components/cell-renderers/category-renderer/category-renderer.component";
import { CheckBoxCellRenderer } from "@bitwarden/web-vault/app/components/cell-renderers/check-box-renderer/check-box-cell-renderer.component";
import { ClassificationCellRenderer } from "@bitwarden/web-vault/app/components/cell-renderers/classification-renderer/classification-renderer.component";
import { InAmountCellRenderer } from "@bitwarden/web-vault/app/components/cell-renderers/in-amount-renderer/in-amount-cell-renderer.component";
import { InstitutionCellRenderer } from "@bitwarden/web-vault/app/components/cell-renderers/institution-cell-renderer/institution-renderer.component";
import { NoRowsOverlayComponentOfDashTable } from "@bitwarden/web-vault/app/components/cell-renderers/no-rows-overlay-component-of-dash-table/no-rows-overlay-component-of-dash-table.component";
import { NormalizedInAmountCellRenderer } from "@bitwarden/web-vault/app/components/cell-renderers/normalized-in-amount-renderer/normalized-in-amount-cell-renderer.component";
import { NormalizedOutAmountCellRenderer } from "@bitwarden/web-vault/app/components/cell-renderers/normalized-out-amount-renderer/normalized-out-amount-cell-renderer.component";
import { OutAmountCellRenderer } from "@bitwarden/web-vault/app/components/cell-renderers/out-amount-renderer/out-amount-renderer.component";
import { ProductCellRenderer } from "@bitwarden/web-vault/app/components/cell-renderers/product-cell-renderer/product-renderer.component";
import { SyncActionButtonsCellRenderer } from "@bitwarden/web-vault/app/components/cell-renderers/sync-action-buttons-renderer/sync-action-buttons-cell-renderer.component";
import { SyncStatusRendererComponent } from "@bitwarden/web-vault/app/components/cell-renderers/sync-status-renderer/sync-status-renderer.component";
import { ComingSoonPageComponent } from "@bitwarden/web-vault/app/components/coming-soon-page/coming-soon-page.component";
import { CountriesComponent } from "@bitwarden/web-vault/app/components/countries/countries.component";
import { CurrenciesComponent } from "@bitwarden/web-vault/app/components/currencies/currencies.component";
import { CustomerSupportBoxComponent } from "@bitwarden/web-vault/app/components/customer-support-box/customer-support-box.component";
import { DashTransactionTableComponent } from "@bitwarden/web-vault/app/components/dash-transaction-table/dash-transaction-table.component";
import { DashboardSelectorComponent } from "@bitwarden/web-vault/app/components/dashboard-selector/dashboard-selector.component";
import { NavDatePickerComponent } from "@bitwarden/web-vault/app/components/date-range-picker/date-picker/nav-date-picker.component";
import { DropDownSelectButtonComponent } from "@bitwarden/web-vault/app/components/drop-down-select-button/drop-down-select-button.component";
import { FileUploadComponent } from "@bitwarden/web-vault/app/components/file-upload/file-upload.component";
import { GlossButtonComponent } from "@bitwarden/web-vault/app/components/gloss-button/gloss-button.component";
import { GlossInputComponent } from "@bitwarden/web-vault/app/components/gloss-input/gloss-input.component";
import { GlossTableComponent } from "@bitwarden/web-vault/app/components/gloss-table/gloss-table.component";
import { HelpTooltipComponent } from "@bitwarden/web-vault/app/components/help-tooltip/help-tooltip.component";
import { InfoTooltipComponent } from "@bitwarden/web-vault/app/components/info-tooltip/info-tooltip.component";
import { InstitutionalAccountsComponent } from "@bitwarden/web-vault/app/components/institutional-accounts/institutional-accounts.component";
import { InstitutionsComponent } from "@bitwarden/web-vault/app/components/institutions/institutions.component";
import { ModalHeaderComponent } from "@bitwarden/web-vault/app/components/modal/modal-header.component";
import { PlanComponent } from "@bitwarden/web-vault/app/components/paywall/plan/plan.component";
import { PieChartComponent } from "@bitwarden/web-vault/app/components/pie-chart/pie-chart.component";
import { DropDownHeaderComponent } from "@bitwarden/web-vault/app/components/preview-transaction-table/drop-down-header/drop-down-header.component";
import { HudComponent } from "@bitwarden/web-vault/app/components/primary-summary-graph/hud/hud.component";
import { LegendComponent } from "@bitwarden/web-vault/app/components/primary-summary-graph/legend/legend.component";
import { ProgressBarComponent } from "@bitwarden/web-vault/app/components/progress-bar/progress-bar.component";
import { ReferenceDataImportComponent } from "@bitwarden/web-vault/app/components/reference-data-import/reference-data-import.component";
import { ReferenceDataTableComponent } from "@bitwarden/web-vault/app/components/reference-data-table/reference-data-table.component";
import { ScenarioDifferenceMessageComponent } from "@bitwarden/web-vault/app/components/scenario-difference-message/scenario-difference-message.component";
import { ListTableComponent } from "@bitwarden/web-vault/app/components/shared/list-table.component";
import { ModalComponent } from "@bitwarden/web-vault/app/components/shared/modal/modal.component";
import { SpinnerComponent } from "@bitwarden/web-vault/app/components/spinner/spinner.component";
import { SplitOptionsComponent } from "@bitwarden/web-vault/app/components/split-options/split-options.component";
import { SplitcategoryComponent } from "@bitwarden/web-vault/app/components/splitcategory/splitcategory.component";
import { SplitclassificationComponent } from "@bitwarden/web-vault/app/components/splitclassification/splitclassification.component";
import { SyncComponent } from "@bitwarden/web-vault/app/components/sync/sync.component";
import { TransactionTableTabsComponent } from "@bitwarden/web-vault/app/components/transaction-table-tabs/transaction-table-tabs.component";
import { CabinetFileUploadComponent } from "@bitwarden/web-vault/app/gloss/cabinet/cabinet-file-upload/cabinet-file-upload.component";
import { CabinetComponent } from "@bitwarden/web-vault/app/gloss/cabinet/cabinet.component";
import { FilterDropdownButtonsComponent } from "@bitwarden/web-vault/app/gloss/dashboard-primary/filter-dropdown-buttons/filter-dropdown-buttons.component";
import { EstimateGroupAddEditComponent } from "@bitwarden/web-vault/app/gloss/estimates/estimate-group-add-edit/estimate-group-add-edit.component";
import { EstimateFormComponent } from "@bitwarden/web-vault/app/gloss/estimates/estimates-add-edit/estimate/estimate-form.component";
import { EstimateTypeInterestComponent } from "@bitwarden/web-vault/app/gloss/estimates/estimates-add-edit/estimate/estimate-type-interest/estimate-type-interest.component";
import { EstimateTypeRecurringComponent } from "@bitwarden/web-vault/app/gloss/estimates/estimates-add-edit/estimate/estimate-type-recurring/estimate-type-recurring.component";
import { EstimateTypeRevaluationComponent } from "@bitwarden/web-vault/app/gloss/estimates/estimates-add-edit/estimate/estimate-type-revaluation/estimate-type-revaluation.component";
import { EstimateTypeTransactionComponent } from "@bitwarden/web-vault/app/gloss/estimates/estimates-add-edit/estimate/estimate-type-transaction/estimate-type-transaction.component";
import { CsvImportComponent } from "@bitwarden/web-vault/app/gloss/import/csv-import/csv-import.component";
import { LinkFileAccountComponent } from "@bitwarden/web-vault/app/gloss/import/linking/link-file-account/link-file-account.component";
import { LinkingNewAccountsComponent } from "@bitwarden/web-vault/app/gloss/import/linking/linking-accounts/linking-accounts.component";
import { ReferenceAddEditComponent } from "@bitwarden/web-vault/app/gloss/import/reference-management/reference-add-edit/reference-add-edit.component";
import { ReferenceManagementComponent } from "@bitwarden/web-vault/app/gloss/import/reference-management/reference-management.component";
import { UserChoiceDateFormatComponent } from "@bitwarden/web-vault/app/gloss/import/user-choice-date-format/user-choice-date-format.component";
import { UserChoiceOfYearComponent } from "@bitwarden/web-vault/app/gloss/import/user-choice-of-year/user-choice-of-year.component";
import { AccountBalanceComponent } from "@bitwarden/web-vault/app/gloss/settings/manage-accounts/account-balance/account-balance.component";
import { AccountRegionsComponent } from "@bitwarden/web-vault/app/gloss/settings/manage-accounts/account-regions/account-regions.component";
import { BasiqIoAccountsComponent } from "@bitwarden/web-vault/app/gloss/settings/manage-accounts/basiq-io-accounts/basiq-io-accounts.component";
import { CreationOptionsComponent } from "@bitwarden/web-vault/app/gloss/settings/manage-accounts/creation-options/creation-options.component";
import { InstitutionSelectionComponent } from "@bitwarden/web-vault/app/gloss/settings/manage-accounts/institution-selection/institution-selection.component";
import { TransactionsImportPopupComponent } from "@bitwarden/web-vault/app/gloss/settings/manage-accounts/transactions-import-popup/transactions-import-popup.component";
import { CategoriesAddEditComponent } from "@bitwarden/web-vault/app/gloss/settings/manage-categories/categories-add-edit/categories-add-edit.component";
import { ManageCategoriesComponent } from "@bitwarden/web-vault/app/gloss/settings/manage-categories/manage-categories.component";
import { ClassificationAddEditComponent } from "@bitwarden/web-vault/app/gloss/settings/manage-classifications/classification-add-edit/classification-add-edit.component";
import { ManageClassificationsComponent } from "@bitwarden/web-vault/app/gloss/settings/manage-classifications/manage-classifications.component";
import { AddInstitutionFormComponent } from "@bitwarden/web-vault/app/gloss/settings/manage-institutions/institutions-add-edit/add-institution-form/add-institution-form.component";
import { CreateInstitutionFormComponent } from "@bitwarden/web-vault/app/gloss/settings/manage-institutions/institutions-add-edit/create-institution-form/create-institution-form.component";
import { InstitutionsAddEditComponent } from "@bitwarden/web-vault/app/gloss/settings/manage-institutions/institutions-add-edit/institutions-add-edit.component";
import { ManageInstitutionsComponent } from "@bitwarden/web-vault/app/gloss/settings/manage-institutions/manage-institutions.component";
import { ManagePreferencesComponent } from "@bitwarden/web-vault/app/gloss/settings/manage-preferences/manage-preferences.component";
import { SideMenuComponent } from "@bitwarden/web-vault/app/layouts/desktop/side-menu.component";
import { AppMenuComponent } from "@bitwarden/web-vault/app/layouts/mobile/app-menu.component";
import { BookPipe } from "@bitwarden/web-vault/app/pipes/book.pipe";
import { CategoryPipe } from "@bitwarden/web-vault/app/pipes/category.pipe";
import { ClassificationPipe } from "@bitwarden/web-vault/app/pipes/classification.pipe";
import { EstimatePipe } from "@bitwarden/web-vault/app/pipes/estimate.pipe";
import { InstitutionsPipe } from "@bitwarden/web-vault/app/pipes/institutions.pipe";
import { PreferencePipe } from "@bitwarden/web-vault/app/pipes/preference.pipe";
import { QuantityPipe } from "@bitwarden/web-vault/app/pipes/quantity.pipe";
import { SafeHtmlPipe } from "@bitwarden/web-vault/app/pipes/safe-html.pipe";
import { ConfirmationDialogComponent } from "@bitwarden/web-vault/app/settings/confirmation-dialog.component";
import { SettingsNavigationComponent } from "@bitwarden/web-vault/app/settings/settings-navigation.component";
import { VerifyEmailRemindDialogComponent } from "@bitwarden/web-vault/app/settings/verify-email-remind-dialog.component";

import { DateFormatPipe } from "../../app/pipes/date-format.pipe";
import { HintComponent } from "../accounts/hint.component";
import { LockComponent } from "../accounts/lock.component";
import { RecoverDeleteComponent } from "../accounts/recover-delete.component";
import { RecoverTwoFactorComponent } from "../accounts/recover-two-factor.component";
import { RemovePasswordComponent } from "../accounts/remove-password.component";
import { SetPasswordComponent } from "../accounts/set-password.component";
import { TwoFactorOptionsComponent } from "../accounts/two-factor-options.component";
import { TwoFactorComponent } from "../accounts/two-factor.component";
import { UpdatePasswordComponent } from "../accounts/update-password.component";
import { VerifyEmailTokenComponent } from "../accounts/verify-email-token.component";
import { DateRangePickerComponent } from "../components/date-range-picker/date-range-picker.component";
import { PresetDateRangeComponent } from "../components/date-range-picker/preset-date-range/preset-date-range.component";
import { DatePickerComponent } from "../components/form/elements/date-picker/date-picker.component";
import { InputComponent } from "../components/form/elements/input/input.component";
import { MultiSelectComponent } from "../components/form/elements/multi-select/multi-select.component";
import { SelectComponent } from "../components/form/elements/select/select.component";
import { NestedCheckboxComponent } from "../components/nested-checkbox.component";
import { OrganizationSwitcherComponent } from "../components/organization-switcher.component";
import { PasswordRepromptComponent } from "../components/password-reprompt.component";
import { MemberRegisterComponents } from "../components/paywall/register/member-register.components";
import { PremiumBadgeComponent } from "../components/premium-badge.component";
import { PreviewTransactionTableComponent } from "../components/preview-transaction-table/preview-transaction-table.component";
import { PrimarySummaryGraphComponent } from "../components/primary-summary-graph/primary-summary-graph.component";
import { MainProgressBarComponent } from "../components/progress-bar/main-progress-bar/main-progress-bar.component";
import { ProjectionsTableComponent } from "../components/projections-table/projections-table.component";
import { TableComponent } from "../components/table/table.component";
import { TransactionTableComponent } from "../components/transaction-table/transaction-table.component";
import { UserLocationComponent } from "../components/user-location/user-location.component";
import { UserVerificationPromptComponent } from "../components/user-verification-prompt.component";
import { UserVerificationComponent } from "../components/user-verification.component";
import { TransactionFormComponent } from "../gloss/dash/transactions-form/transaction-form.component";
import { DashPrimaryComponent } from "../gloss/dashboard-primary/dash-primary.component";
import { FilteredTransactionsTableCardComponent } from "../gloss/dashboard-primary/filtered-transactions-table-card.component";
import { EstimatesAddEditComponent } from "../gloss/estimates/estimates-add-edit/estimates-add-edit.component";
import { EstimatesComponent } from "../gloss/estimates/estimates.component";
import { ImportCsvComponent } from "../gloss/import/import-csv.component";
import { ImportManagementComponent } from "../gloss/import/import-management.component";
import { GlossSettingsComponent } from "../gloss/settings/gloss-settings.component";
import { AccountAddEditComponent } from "../gloss/settings/manage-accounts/accounts-add-edit/account-add-edit.component";
import { ManageAccountComponent } from "../gloss/settings/manage-accounts/manage-account.component";
import { FooterComponent } from "../layouts/footer.component";
import { FrontendLayoutComponent } from "../layouts/frontend-layout.component";
import { NavbarComponent } from "../layouts/navbar.component";
import { UserLayoutComponent } from "../layouts/user-layout.component";
import { ProvidersComponent } from "../providers/providers.component";
import { AccountComponent } from "../settings/account.component";
import { ApiKeyComponent } from "../settings/api-key.component";
import { ChangeEmailComponent } from "../settings/change-email.component";
import { ChangeKdfComponent } from "../settings/change-kdf.component";
import { ChangePasswordComponent } from "../settings/change-password.component";
import { DangerZoneComponent } from "../settings/danger-zone.component";
import { DeauthorizeSessionsComponent } from "../settings/deauthorize-sessions.component";
import { DeleteAccountComponent } from "../settings/delete-account.component";
import { PaymentComponent } from "../settings/payment.component";
import { PreferencesComponent } from "../settings/preferences.component";
import { PremiumComponent } from "../settings/premium.component";
import { ProfileComponent } from "../settings/profile.component";
import { PurgeVaultComponent } from "../settings/purge-vault.component";
import { SecurityKeysComponent } from "../settings/security-keys.component";
import { SecurityComponent } from "../settings/security.component";
import { SettingsComponent } from "../settings/settings.component";
import { SponsoredFamiliesComponent } from "../settings/sponsored-families.component";
import { SponsoringOrgRowComponent } from "../settings/sponsoring-org-row.component";
import { SubscriptionComponent } from "../settings/subscription.component";
import { TaxInfoComponent } from "../settings/tax-info.component";
import { TwoFactorAuthenticatorComponent } from "../settings/two-factor-authenticator.component";
import { TwoFactorEmailComponent } from "../settings/two-factor-email.component";
import { TwoFactorRecoveryComponent } from "../settings/two-factor-recovery.component";
import { TwoFactorSetupComponent } from "../settings/two-factor-setup.component";
import { TwoFactorVerifyComponent } from "../settings/two-factor-verify.component";
import { TwoFactorWebAuthnComponent } from "../settings/two-factor-webauthn.component";
import { TwoFactorYubiKeyComponent } from "../settings/two-factor-yubikey.component";
import { UpdateKeyComponent } from "../settings/update-key.component";
import { UpdateLicenseComponent } from "../settings/update-license.component";
import { UserAccessPageComponent } from "../settings/user-access-page.component";
import { UserSubscriptionComponent } from "../settings/user-subscription.component";
import { VaultTimeoutInputComponent } from "../settings/vault-timeout-input.component";
import { VerifyEmailComponent } from "../settings/verify-email.component";
import { GeneratorComponent } from "../tools/generator.component";
import { PasswordGeneratorHistoryComponent } from "../tools/password-generator-history.component";
import { ToolsComponent } from "../tools/tools.component";
import { AddEditCustomFieldsComponent } from "../vault/add-edit-custom-fields.component";
import { AddEditComponent } from "../vault/add-edit.component";
import { AttachmentsComponent } from "../vault/attachments.component";
import { BulkActionsComponent } from "../vault/bulk-actions.component";
import { BulkDeleteComponent } from "../vault/bulk-delete.component";
import { BulkMoveComponent } from "../vault/bulk-move.component";
import { BulkRestoreComponent } from "../vault/bulk-restore.component";
import { BulkShareComponent } from "../vault/bulk-share.component";
import { CollectionsComponent } from "../vault/collections.component";
import { FolderAddEditComponent } from "../vault/folder-add-edit.component";
import { ShareComponent } from "../vault/share.component";
import { VaultFilterModule } from "../vault/vault-filter/vault-filter.module";

import { SharedModule } from ".";

// Please do not add to this list of declarations - we should refactor these into modules when doing so makes sense until there are none left.
// If you are building new functionality, please create or extend a feature module instead.
@NgModule({
  imports: [
    SharedModule,
    VaultFilterModule,
    MatSlideToggleModule,
    MatIconModule,
    MatListModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatTableModule,
    MatButtonModule,
    MatInputModule,
    MatPaginatorModule,
    MatSidenavModule,
    MatCardModule,
    MatDividerModule,
    MatGridListModule,
    MatMenuModule,
    MatSortModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatAutocompleteModule,
    MatChipsModule,
    ScrollingModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatTabsModule,
    MatProgressBarModule,
    NgSelectModule,
    MatToolbarModule,
    AccordionComponent,
    AgGridAngular,
  ],
  declarations: [
    PremiumBadgeComponent,
    AccountComponent,
    DangerZoneComponent,
    ConfirmationDialogComponent,
    VerifyEmailRemindDialogComponent,
    AddEditComponent,
    AddEditCustomFieldsComponent,
    AddEditCustomFieldsComponent,
    ApiKeyComponent,
    AttachmentsComponent,
    BulkActionsComponent,
    BulkDeleteComponent,
    BulkMoveComponent,
    BulkRestoreComponent,
    BulkShareComponent,
    ChangeEmailComponent,
    ChangeKdfComponent,
    ChangePasswordComponent,
    CollectionsComponent,
    DeauthorizeSessionsComponent,
    DeleteAccountComponent,
    FolderAddEditComponent,
    FooterComponent,
    FrontendLayoutComponent,
    HintComponent,
    LockComponent,
    NavbarComponent,
    NestedCheckboxComponent,
    OrganizationSwitcherComponent,
    GeneratorComponent,
    PasswordGeneratorHistoryComponent,
    PasswordRepromptComponent,
    UserVerificationPromptComponent,
    PaymentComponent,
    PreferencesComponent,
    PremiumBadgeComponent,
    PremiumComponent,
    ProfileComponent,
    ProvidersComponent,
    PurgeVaultComponent,
    RecoverDeleteComponent,
    RecoverTwoFactorComponent,
    RemovePasswordComponent,
    SecurityComponent,
    SecurityKeysComponent,
    SetPasswordComponent,
    SettingsComponent,
    UserAccessPageComponent,
    ShareComponent,
    SponsoredFamiliesComponent,
    SponsoringOrgRowComponent,
    SubscriptionComponent,
    TaxInfoComponent,
    ToolsComponent,
    TwoFactorAuthenticatorComponent,
    TwoFactorComponent,
    TwoFactorEmailComponent,
    TwoFactorOptionsComponent,
    TwoFactorRecoveryComponent,
    TwoFactorSetupComponent,
    TwoFactorVerifyComponent,
    TwoFactorWebAuthnComponent,
    TwoFactorYubiKeyComponent,
    UpdateKeyComponent,
    UpdateLicenseComponent,
    UpdatePasswordComponent,
    UserLayoutComponent,
    UserSubscriptionComponent,
    UserVerificationComponent,
    VaultTimeoutInputComponent,
    VerifyEmailComponent,
    VerifyEmailTokenComponent,
    AddInstitutionFormComponent,
    CreateInstitutionFormComponent,
    DropDownSelectButtonComponent,
    FilterDropdownButtonsComponent,
    BookPipe,
    EstimatePipe,
    InstitutionsPipe,
    QuantityPipe,
    SafeHtmlPipe,
    PreferencePipe,
    CategoryPipe,
    ClassificationPipe,
    UserChoiceDateFormatComponent,
    UserChoiceOfYearComponent,
    HelpTooltipComponent,
    InfoTooltipComponent,
    LinkingNewAccountsComponent,
    LinkFileAccountComponent,
    EstimateGroupAddEditComponent,
    SplitcategoryComponent,
    SplitclassificationComponent,
    EstimateTypeRecurringComponent,
    EstimateTypeRevaluationComponent,
    EstimateTypeInterestComponent,
    EstimateTypeTransactionComponent,
    SelectComponent,
    MultiSelectComponent,
    EstimateFormComponent,
    DatePickerComponent,
    NavDatePickerComponent,
    GlossTableComponent,
    InputComponent,
    EstimatesComponent,
    EstimatesAddEditComponent,
    TableComponent,
    SpinnerComponent,
    SplitOptionsComponent,
    MainProgressBarComponent,
    ProgressBarComponent,
    ManagePreferencesComponent,
    ManageCategoriesComponent,
    CategoriesAddEditComponent,
    ClassificationAddEditComponent,
    ManageClassificationsComponent,
    TransactionTableComponent,
    DashTransactionTableComponent,
    BookCellRenderer,
    CategoryCellRenderer,
    ClassificationCellRenderer,
    InAmountCellRenderer,
    OutAmountCellRenderer,
    NormalizedInAmountCellRenderer,
    NormalizedOutAmountCellRenderer,
    AggregateAmountCellRenderer,
    AccountActionButtonsCellRenderer,
    ScenarioDifferenceMessageComponent,
    BalanceCellRenderer,
    CheckBoxCellRenderer,
    SyncActionButtonsCellRenderer,
    SyncStatusRendererComponent,
    SyncComponent,
    InstitutionCellRenderer,
    NoRowsOverlayComponentOfDashTable,
    ProductCellRenderer,
    ImportManagementComponent,
    ImportCsvComponent,
    FilteredTransactionsTableCardComponent,
    DashPrimaryComponent,
    PrimarySummaryGraphComponent,
    ProjectionsTableComponent,
    DateRangePickerComponent,
    PresetDateRangeComponent,
    GlossSettingsComponent,
    AccountAddEditComponent,
    CabinetComponent,
    CabinetFileUploadComponent,
    CreationOptionsComponent,
    BasiqIoAccountsComponent,
    AccountRegionsComponent,
    AccountBalanceComponent,
    InstitutionSelectionComponent,
    TransactionsImportPopupComponent,
    ManageAccountComponent,
    PreviewTransactionTableComponent,
    ManageInstitutionsComponent,
    InstitutionsAddEditComponent,
    DateFormatPipe,
    ReferenceDataImportComponent,
    TransactionFormComponent,
    ReferenceDataTableComponent,
    ReferenceManagementComponent,
    PieChartComponent,
    ArrangeColumnsTableComponent,
    InstitutionsComponent,
    ComingSoonPageComponent,
    AccountWizardStepperComponent,
    CountriesComponent,
    CurrenciesComponent,
    GlossInputComponent,
    InstitutionalAccountsComponent,
    AutocompleteComponent,
    ListTableComponent,
    DropDownHeaderComponent,
    ReferenceAddEditComponent,
    ModalComponent,
    CsvImportComponent,
    SideMenuComponent,
    AppMenuComponent,
    DashboardSelectorComponent,
    TransactionTableTabsComponent,
    PlanComponent,
    MemberRegisterComponents,
    GlossInputComponent,
    GlossButtonComponent,
    HudComponent,
    LegendComponent,
    SettingsNavigationComponent,
    CustomerSupportBoxComponent,
    ModalHeaderComponent,
    BankIconsComponent,
    TileButtonComponent,
    UserLocationComponent,
    FileUploadComponent,
  ],
  exports: [
    PremiumBadgeComponent,
    AccountComponent,
    AddEditComponent,
    AddEditCustomFieldsComponent,
    AddEditCustomFieldsComponent,
    ApiKeyComponent,
    AttachmentsComponent,
    BulkActionsComponent,
    BulkDeleteComponent,
    BulkMoveComponent,
    BulkRestoreComponent,
    BulkShareComponent,
    ChangeEmailComponent,
    ChangeKdfComponent,
    ChangePasswordComponent,
    DangerZoneComponent,
    ConfirmationDialogComponent,
    VerifyEmailRemindDialogComponent,
    CollectionsComponent,
    DeauthorizeSessionsComponent,
    DeleteAccountComponent,
    FolderAddEditComponent,
    FooterComponent,
    FrontendLayoutComponent,
    HintComponent,
    LockComponent,
    NavbarComponent,
    NestedCheckboxComponent,
    OrganizationSwitcherComponent,
    GeneratorComponent,
    PasswordGeneratorHistoryComponent,
    PasswordRepromptComponent,
    PaymentComponent,
    PreferencesComponent,
    PremiumBadgeComponent,
    PremiumComponent,
    ProfileComponent,
    ProvidersComponent,
    PurgeVaultComponent,
    RecoverDeleteComponent,
    RecoverTwoFactorComponent,
    RemovePasswordComponent,
    SecurityComponent,
    SecurityKeysComponent,
    SetPasswordComponent,
    SettingsComponent,
    UserAccessPageComponent,
    ShareComponent,
    SponsoredFamiliesComponent,
    SponsoringOrgRowComponent,
    SubscriptionComponent,
    TaxInfoComponent,
    ToolsComponent,
    TwoFactorAuthenticatorComponent,
    TwoFactorComponent,
    TwoFactorEmailComponent,
    TwoFactorOptionsComponent,
    TwoFactorRecoveryComponent,
    TwoFactorSetupComponent,
    TwoFactorVerifyComponent,
    TwoFactorWebAuthnComponent,
    TwoFactorYubiKeyComponent,
    UpdateKeyComponent,
    UpdateLicenseComponent,
    UpdatePasswordComponent,
    UserLayoutComponent,
    UserSubscriptionComponent,
    UserVerificationComponent,
    VaultTimeoutInputComponent,
    VerifyEmailComponent,
    VerifyEmailTokenComponent,
    AddInstitutionFormComponent,
    CreateInstitutionFormComponent,
    DropDownSelectButtonComponent,
    FilterDropdownButtonsComponent,
    UserChoiceDateFormatComponent,
    UserChoiceOfYearComponent,
    HelpTooltipComponent,
    InfoTooltipComponent,
    LinkingNewAccountsComponent,
    LinkFileAccountComponent,
    BookPipe,
    EstimatePipe,
    InstitutionsPipe,
    QuantityPipe,
    SafeHtmlPipe,
    PreferencePipe,
    CategoryPipe,
    ClassificationPipe,
    EstimateGroupAddEditComponent,
    SplitcategoryComponent,
    SplitclassificationComponent,
    EstimateTypeRecurringComponent,
    EstimateTypeRevaluationComponent,
    EstimateTypeInterestComponent,
    EstimateTypeTransactionComponent,
    SelectComponent,
    MultiSelectComponent,
    EstimateFormComponent,
    DatePickerComponent,
    NavDatePickerComponent,
    GlossTableComponent,
    InputComponent,
    EstimatesComponent,
    EstimatesAddEditComponent,
    TableComponent,
    SpinnerComponent,
    SplitOptionsComponent,
    ManagePreferencesComponent,
    ManageCategoriesComponent,
    CategoriesAddEditComponent,
    ClassificationAddEditComponent,
    TransactionTableComponent,
    DashTransactionTableComponent,
    BookCellRenderer,
    CategoryCellRenderer,
    ClassificationCellRenderer,
    InAmountCellRenderer,
    OutAmountCellRenderer,
    NormalizedInAmountCellRenderer,
    NormalizedOutAmountCellRenderer,
    AggregateAmountCellRenderer,
    AccountActionButtonsCellRenderer,
    ScenarioDifferenceMessageComponent,
    BalanceCellRenderer,
    CheckBoxCellRenderer,
    SyncActionButtonsCellRenderer,
    SyncStatusRendererComponent,
    SyncComponent,
    InstitutionCellRenderer,
    NoRowsOverlayComponentOfDashTable,
    ProductCellRenderer,
    ManageClassificationsComponent,
    DashPrimaryComponent,
    FilteredTransactionsTableCardComponent,
    PrimarySummaryGraphComponent,
    ProjectionsTableComponent,
    DateRangePickerComponent,
    PresetDateRangeComponent,
    GlossSettingsComponent,
    AccountAddEditComponent,
    CabinetComponent,
    CabinetFileUploadComponent,
    CreationOptionsComponent,
    BasiqIoAccountsComponent,
    AccountRegionsComponent,
    AccountBalanceComponent,
    InstitutionSelectionComponent,
    TransactionsImportPopupComponent,
    ManageAccountComponent,
    PreviewTransactionTableComponent,
    ManageInstitutionsComponent,
    InstitutionsAddEditComponent,
    DateFormatPipe,
    ReferenceDataImportComponent,
    TransactionFormComponent,
    ReferenceDataTableComponent,
    ReferenceManagementComponent,
    MainProgressBarComponent,
    ProgressBarComponent,
    ArrangeColumnsTableComponent,
    InstitutionsComponent,
    GlossInputComponent,
    CountriesComponent,
    CurrenciesComponent,
    AutocompleteComponent,
    ComingSoonPageComponent,
    AccountWizardStepperComponent,
    InstitutionalAccountsComponent,
    ListTableComponent,
    DropDownHeaderComponent,
    ReferenceAddEditComponent,
    ModalComponent,
    SideMenuComponent,
    DashboardSelectorComponent,
    TransactionTableTabsComponent,
    PlanComponent,
    MemberRegisterComponents,
    GlossInputComponent,
    GlossButtonComponent,
    SettingsNavigationComponent,
    CustomerSupportBoxComponent,
    FileUploadComponent,
    ModalHeaderComponent,
    TileButtonComponent,
    UserLocationComponent,
  ],
})
export class LooseComponentsModule {}
