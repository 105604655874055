// TODO - should implement the tailwind way of doing this
export const classNamesFor: any = {
  button: `tw-flex
    tw-flex-row
    tw-justify-center
    tw-items-center
    tw-p-3
    tw-gap-1
    tw-w-full
    tw-bg-primary
    tw-rounded-[12px]
    tw-border-none
    tw-shadow-[4px_4px_12px_-16px_rgba(0,175,175,0.12),_4px_4px_16px_0px_rgba(255,255,255,0.42)_inset,_-4px_-4px_5px_0px_rgba(0,0,0,0.3)_inset]
    tw-font-montserrat
    tw-font-medium
    tw-text-[20px]
    tw-leading-[28px]
    tw-text-center
    tw-text-[#292929]`,

  secondaryButton: `tw-flex
     tw-flex-row
    tw-justify-center
    tw-items-center
    tw-p-3
    tw-rounded-lg
    tw-bg-neutral-50
    tw-gap-1
    tw-w-full
    tw-border-none
    tw-shadow-[_-2px_-2px_2px_0px_rgba(255,255,255,1),_-2px_-4px_12px_0px_rgba(255,255,255,1),_6px_6px_10px_0px_rgba(6,46,43,0.08),_2px_2px_4px_0px_rgba(6,46,43,0.24)]
    tw-font-montserrat
    tw-font-medium
    tw-text-[20px]
    tw-leading-[28px]
    tw-text-center
    tw-text-[#292929]`,

  successIcon: `mat-wizard-scenario-difference-icon
    tw-justify-center
    tw-items-center
    tw tw-w-8 tw-h-8
    tw-inline-block
    tw-bg-primary
    tw-text-white
    tw-rounded-full
    tw-text-3xl
    tw-m-2.5
    tw-ml-0`,

  errorIcon: `base-white
    tw-text-base
    tw-mt-0.5
    tw-mr-2.5
    tw-bg-accent
    tw-w-8 tw-h-8
    tw-inline-block
    tw-rounded-full
    tw-text-white
    tw-text-3xl
    tw-text-center
    tw-mr-1.5`,
};
