import { Injectable } from "@angular/core";

import { ImportReferenceDataResult } from "@bitwarden/common/models/domain/import-reference-data-result";
import { ReferenceDataResponse } from "@bitwarden/web-vault/app/models/data/response/reference-data-response";

import { ReferenceData } from "../models/data/blobby/reference-data.data";

@Injectable({
  providedIn: "root",
})
export class ReferenceDataCsvImporter {
  parse(csvResult: ReferenceData[]): Promise<ImportReferenceDataResult> {
    const result = new ImportReferenceDataResult();
    const totalResults = csvResult.length;
    let counter = 0;
    csvResult.forEach((csvRow) => {
      const referenceResponse = new ReferenceDataResponse(csvRow);
      const referenceData = new ReferenceData(referenceResponse);
      result.symbols.push(referenceData);
      counter++;
    });

    result.success = totalResults === counter;
    return Promise.resolve(result);
  }
}
