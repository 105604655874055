import { Injectable } from "@angular/core";
import _ from "lodash";

import { Subscription } from "../../models/data/subscription.data";
import { PermissionInterface } from "../../services/permission/permissionInterface";

@Injectable({
  providedIn: "root",
})
export class Permission implements PermissionInterface {
  hasAccess(userSubscription: Subscription): boolean {
    return !_.isEmpty(userSubscription.subscription);
  }
}
