<div class="tw-rounded-lg tw-bg-white">
  <div class="tw-flex tw-flex-col tw-gap-5 tw-px-10 tw-pb-8 xs:tw-px-0">
    <div class="tw-flex tw-items-start tw-justify-center tw-gap-2 xs:tw-items-end">
      <span class="tw-text-[19px] tw-font-medium xs:tw-text-[15px]">
        {{ "verifyYourEmail" | i18n }}
      </span>
    </div>
    <div class="tw-font-light">
      {{ "verificationEmailSent" | i18n }}
      <div class="tw-flex tw-font-bold tw-italic">
        <span class="tw-max-w-[200px] tw-truncate">{{ username }}</span>
        @{{ domain }}
      </div>
      <br /><br />
      {{ "checkEmailInbox" | i18n }}
    </div>
    <div class="tw-mr-12">
      <img class="tw-block tw-w-full" src="images/send-email.png" alt="send-email" />
    </div>
    <div class="tw-flex tw-justify-end tw-gap-2 xs:tw-flex-col">
      <app-gloss-button
        class="tw-w-1/2 xs:tw-w-full"
        mat-dialog-close
        [options]="{
          class: 'primary',
          text: 'ok' | i18n,
          isEnabled: true
        }"
      >
      </app-gloss-button>
    </div>
  </div>
</div>
