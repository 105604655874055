import { LogService } from "@bitwarden/common/abstractions/log.service";
import { ReferenceData } from "@bitwarden/web-vault/app/models/data/blobby/reference-data.data";
import { BalanceGrouping } from "@bitwarden/web-vault/app/services/DataCalculationService/balanceGrouping/balanceGrouping";
import { BalanceGroupingTools } from "@bitwarden/web-vault/app/services/DataCalculationService/balanceGrouping/balanceGroupingTools";
import { NormalizeTransaction } from "@bitwarden/web-vault/app/services/DataCalculationService/normalize/normalizeTransaction";
import { BalanceAlignment as BalanceAlignmentUtils } from "@bitwarden/web-vault/app/services/DataCalculationService/transaction/balance-alignment";

import { Transaction } from "../../../models/data/blobby/transaction.data";
import { TransactionResponse } from "../../../models/data/response/transaction-response";
import { TransactionDirection } from "../../../models/enum/transactionDirection";
import { createBalanceTransactionResponse } from "../../../shared/utils/helper.transactions/balance-response";
import { sortTransaction } from "../../../shared/utils/helper.transactions/sort";

// adding an alias for clarity
type BalanceAlignmentGroupingKeyType = string;

export type DateStartPreferences = {
  weekStartDay?: number;
  monthStartDay?: number;
  yearMonthStart?: number;
};

export class BalanceAlignment {
  private logService: LogService;
  private normalizeTransaction: NormalizeTransaction;

  constructor(logService: LogService, baseCurrency: string, referenceData: ReferenceData[]) {
    this.logService = logService;
    this.normalizeTransaction = new NormalizeTransaction(baseCurrency, logService, referenceData);
  }

  /**
   * Function creates a fake transaction with the description 'Generated Balance Alignment Transaction'
   * and definition 'alignment'
   *
   * @param transaction
   * @private
   */
  createAlignmentTransactionForTransactionBalance(transaction: Transaction): Transaction {
    if (!transaction.bankImportedBalance && isNaN(transaction.bankImportedBalance)) {
      return;
    }

    const openingBalance = transaction.bankImportedBalance;
    const balanceTransactionResponse = new TransactionResponse(
      createBalanceTransactionResponse(transaction, openingBalance)
    );
    return new Transaction(balanceTransactionResponse);
  }

  /**
   * This function checks that the importedBankBalance for a transaction matches the balance for that account
   * in the supplied BalanceGrouping object. Assumes that the BalanceGrouping object has only been progressively added
   * up to this transaction.
   *
   * @param transaction
   * @param groupedBalances
   */
  async checkBalanceOnTransaction(transaction: Transaction, groupedBalances: BalanceGrouping) {
    // retrieve the balance for the account in the correct currency on the transaction date
    const balanceGroupingTools = new BalanceGroupingTools();

    const accountCurrencyBalance = balanceGroupingTools.getAccountCurrencyBalanceByDate(
      groupedBalances,
      transaction.accountId,
      transaction.quantity.currency,
      transaction.transactionDate.date
    );

    // if the imported balance is different to the calculated balance then update the amount on the transaction
    const difference = transaction.bankImportedBalance - accountCurrencyBalance;

    // TODO: eventually we should be able to remove this hack once we put safe numbers into gloss numbers
    // if (transaction.bankImportedBalance !== accountCurrencyBalance) {
    // HACK: is the difference more than 0.01. Note we have to use this to allow for javascripts poor mathematical
    // ability to add and subtract decimal places. We can not use equals because the addition is not accurate.
    // eg: 74616.92 + 78388 = 153004.91999999998 instead of 153004.92
    if (Math.abs(difference) > 0.01) {
      this.logService.info(
        `Realigning the Balance from calculated ${accountCurrencyBalance} to imported bank balance of ${transaction.bankImportedBalance}`
      );

      if (difference > 0) {
        transaction.direction = TransactionDirection.In;
      } else {
        transaction.direction = TransactionDirection.Out;
      }

      transaction.quantity.actualQuantity.amount = Math.abs(difference);
      // TODO: remove
      if (transaction.quantity.currency) {
        transaction.balance.currencyBalances = {};
        transaction.balance.currencyBalances[transaction.quantity.currency] = difference;
      }
      await this.normalizeTransaction.normalizeImportedTransaction(transaction);
    } else {
      transaction.quantity.actualQuantity.amount = 0;
      transaction.balance.currencyBalances = {};
      await this.normalizeTransaction.normalizeImportedTransaction(transaction);
    }
    return;
  }

  /**
   * This function is the main function to call to align all the values for opening and closing balances
   * as well as any transactions supplied with the balance column filled in. This will result in an array of
   * transactions being returned which will have fake alignment transactions added in if any of the numbers are out.
   * @param transactions
   * @param datePreferences - optional preferences for the start of the week, month and year
   */
  async realignAllExistingTransactions(
    transactions: Transaction[],
    datePreferences?: DateStartPreferences
  ): Promise<Array<Transaction>> {
    // use this to store any generated alignment transactions
    const realignedTransactions: Array<Transaction> = [];

    if (transactions.length === 0) {
      return transactions;
    }

    // sort all transactions by date
    transactions.sort(sortTransaction);

    let currentTransactionDate;
    let closingBalanceAlignment: Record<BalanceAlignmentGroupingKeyType, Transaction> = {};
    let transactionsSameDate: Record<BalanceAlignmentGroupingKeyType, Array<Transaction>> = {};
    let sumAndFill = true;

    // create a BalanceGrouping that will be progressively added to as we process each transaction
    // TODO: replace groupedBalance with preference structure
    // const groupedBalance = new BalanceGrouping([], this.preferenceService);
    const groupedBalance = new BalanceGrouping([]);

    if (datePreferences) {
      groupedBalance.applyManualPreferences(datePreferences);
    }

    // go through all the transactions
    for (const transaction of transactions) {
      // do not include reval transactions in the balance alignment grouping calculations
      if (transaction.revalTransaction) {
        continue;
      }
      const newTransactionDate = transaction.transactionDate.date;
      // if the new transaction date is different to the last transaction date then we need to process the last set
      // and reset variables
      if (
        currentTransactionDate &&
        currentTransactionDate.toDateString() !== newTransactionDate.toDateString()
      ) {
        // if there are no transactions or closing balances for the last day, then we just need to sum and fill the day
        if (
          Object.keys(transactionsSameDate).length === 0 &&
          Object.keys(closingBalanceAlignment).length === 0
        ) {
          await groupedBalance.sumGroupedBalancesOnDate(currentTransactionDate);
        } else {
          // if there are transactions or closing balances we need to process them
          await this.processSameDateTransactions(
            transactionsSameDate,
            groupedBalance,
            closingBalanceAlignment,
            realignedTransactions,
            sumAndFill
          );
        }
        // clear these variables to be used to process a new transaction date
        transactionsSameDate = {};
        closingBalanceAlignment = {};
        sumAndFill = true;
      }
      // process the current transaction
      // get the uniq key for that balance alignment
      const balanceAlignmentGroupingKey = this.getBalanceAlignmentGroupingKey(transaction);
      if (BalanceAlignmentUtils.isBalanceAlignmentTransactionOpen(transaction)) {
        // if opening, then is needs to be aligned first before other transactions for the day are processed
        await this.alignOpeningTransaction(transaction, groupedBalance);
        sumAndFill = false;
      } else if (
        BalanceAlignmentUtils.isBalanceAlignmentTransactionClose(transaction) &&
        !closingBalanceAlignment[balanceAlignmentGroupingKey]
      ) {
        // if closing, then it needs to be aligned last for that day
        closingBalanceAlignment[balanceAlignmentGroupingKey] = transaction;
      } else {
        // sort the regular transactions into their accounts for the same date
        if (!transactionsSameDate?.[balanceAlignmentGroupingKey]) {
          transactionsSameDate[balanceAlignmentGroupingKey] = [];
        }
        transactionsSameDate[balanceAlignmentGroupingKey].push(transaction);
      }
      // set the currentTransactionDate for comparison in the next loop iteration
      currentTransactionDate = newTransactionDate;
    }

    // Process the final set of transactions
    // if there are no transactions or closing balances for the last day, then we just need to sum and fill the day
    if (
      Object.keys(transactionsSameDate).length === 0 &&
      Object.keys(closingBalanceAlignment).length === 0
    ) {
      await groupedBalance.sumGroupedBalancesOnDate(currentTransactionDate);
    }
    // if there is a closing balance or regular transactions we need to process them
    if (
      Object.keys(transactionsSameDate).length > 0 ||
      Object.keys(closingBalanceAlignment).length > 0
    ) {
      await this.processSameDateTransactions(
        transactionsSameDate,
        groupedBalance,
        closingBalanceAlignment,
        realignedTransactions,
        sumAndFill
      );
    }

    // if there were new alignment transactions created then make sure they are added to the array of transactions to be
    // returned
    for (const transaction of realignedTransactions) {
      transactions.push(transaction);
    }

    transactions.sort(sortTransaction);

    return transactions;
  }

  /**
   * Return the balance Alignment unique ID for a transaction. A balance can happen for an Account and a symbol.
   * @param transaction the transaction to retrieve its BAT id
   * @private
   */
  getBalanceAlignmentGroupingKey(transaction: Transaction): string {
    return `${transaction.accountId}-${transaction.quantity.actualQuantity.symbol}`;
  }

  /**
   * This function is used to process a set of transactions that fall on the same date and to align the last transaction
   * for the day with its bank imported balance number
   * @param transactionsSameDate
   * @param groupedBalance
   * @param closingBalanceTransactions
   * @param realignedTransactions
   * @param sumAndFill
   * @private
   */
  async processSameDateTransactions(
    transactionsSameDate: Record<string, Array<Transaction>>,
    groupedBalance: BalanceGrouping,
    closingBalanceTransactions: Record<string, Transaction>,
    realignedTransactions: Array<Transaction>,
    sumAndFill = true
  ) {
    // Add all the transactions for the same date to the grouped balance object without the last transaction
    await this.addTransactionsToBalance(transactionsSameDate, groupedBalance, sumAndFill);

    // realign all the closing balance numbers
    for (const balanceAlignmentGroupingKey in closingBalanceTransactions) {
      const closingTransaction = closingBalanceTransactions[balanceAlignmentGroupingKey];
      const closingTransactionDate = closingTransaction.transactionDate.date;

      // if the grouped balance has not been extended to the closing transaction date then we
      // need to fill the grouped balance out to that date or the balance number will come back as 0
      if (
        groupedBalance.lastRunningDateEnd &&
        closingTransactionDate > groupedBalance.lastRunningDateEnd
      ) {
        // await groupedBalance.fillTheGranularityGapsUntilDate(closingTransactionDate);
        await groupedBalance.copyGranularityBalanceFromLastDate(closingTransactionDate);
      } else if (!groupedBalance.lastRunningDateEnd) {
        groupedBalance.lastRunningDateEnd = closingTransactionDate;
      }
      // realign the closing balance transaction
      await this.alignClosingTransaction(closingTransaction, groupedBalance);
    }

    // for each of the accounts and symbol that are grouped, align the transactions if it doesn't have closing balance
    // transaction
    for (const balanceAlignmentGroupingKey in transactionsSameDate) {
      if (!closingBalanceTransactions[balanceAlignmentGroupingKey]) {
        // get the last transaction for the set of transactions
        const endDayTransaction = this.getLastTransaction(
          transactionsSameDate[balanceAlignmentGroupingKey]
        );
        if (endDayTransaction) {
          // align the final transaction of the day
          const realignBalanceTransaction = await this.alignEndDayBalance(
            endDayTransaction,
            groupedBalance
          );
          if (realignBalanceTransaction) {
            // add the new transaction to the grouped balance object
            await groupedBalance.addTransactionToExistingBalanceGrouping(
              realignBalanceTransaction,
              true,
              ["account"]
            );
            // save the new alignment transaction so it can be added to the transaction list
            realignedTransactions.push(realignBalanceTransaction);
          }
        }
      }
    }
  }

  /**
   * Add a list of transactions with the same date to the grouped balance object
   * Only sum and fill once all the transactions for the day have been processed.
   * @param transactionsSameDate
   * @param groupedBalance
   * @private
   */
  private async addTransactionsToBalance(
    transactionsSameDate: Record<string, Array<Transaction>>,
    groupedBalance: BalanceGrouping,
    sumAndFill = true
  ) {
    let transactionDate: Date;
    for (const balanceAlignmentGroupingKey in transactionsSameDate) {
      const transactions = transactionsSameDate[balanceAlignmentGroupingKey];
      for (const transaction of transactions) {
        transactionDate = transaction.transactionDate.date;
        await groupedBalance.addTransactionToEndOfBalanceGrouping(transaction, true, ["account"]);
      }
    }
    if (transactionDate && sumAndFill) {
      await groupedBalance.sumGroupedBalancesOnDate(transactionDate);
    }
  }

  /**
   * Function returns the last transaction of the day that we should be using to align the balance to.
   * This function won't be called if there is a closing balance transaction on the same date. It will currently
   * use the last transaction to be imported for the day. Eventually we should build an algorithm that will try
   * to traverse through all the days transactions and use the values and balance numbers to work out
   * the transaction order.
   *
   * @private
   * @param sameDayTransactions
   */
  private getLastTransaction(sameDayTransactions: Array<Transaction>): Transaction {
    // if there is no closing balance, then choose the last imported transaction as the last transaction
    // TODO: write a smarter algorithm to choose the correct last transaction based on the value of the transaction
    // and the closing balance. We should be able to step through tha values and balance possibilities to guess the
    // best closing balance
    if (sameDayTransactions.length > 0) {
      return sameDayTransactions[sameDayTransactions.length - 1];
    }
  }

  /**
   * Checks the end of day balance with the imported bank balance number on the transaction supplied.
   * If the numbers do not match, then an alignment transaction will be created right before
   * this transaction so that it will match. Create it with the same time stamp.
   * @param alignToTransaction
   * @param groupedBalance
   * @private
   */
  private async alignEndDayBalance(
    alignToTransaction: Transaction,
    groupedBalance: BalanceGrouping
  ): Promise<Transaction> {
    // create a fake alignment transaction based on the supplied last transaction for the day
    const alignmentTransaction =
      this.createAlignmentTransactionForTransactionBalance(alignToTransaction);

    if (alignmentTransaction) {
      // check the balance against the bank imported balance
      await this.checkBalanceOnTransaction(alignmentTransaction, groupedBalance);

      // if the amount is not 0, then it is a valid transaction alighment
      if (alignmentTransaction.valuation.value.amount !== 0) {
        return alignmentTransaction;
      }
    }
  }

  /**
   * Aligns the opening balance with the bank imported balance on the transaction
   * @param openingTransaction
   * @param groupedBalance
   * @private
   */
  private async alignOpeningTransaction(
    openingTransaction: Transaction,
    groupedBalance: BalanceGrouping
  ) {
    // if the bankImportedBalance on the opening balance isn't set properly then we can skip the alignment
    if (!openingTransaction.bankImportedBalance && isNaN(openingTransaction.bankImportedBalance)) {
      openingTransaction.quantity.actualQuantity.amount = 0;
      openingTransaction.balance.currencyBalances = {};
      await this.normalizeTransaction.normalizeImportedTransaction(openingTransaction);
      await groupedBalance.addTransactionToEndOfBalanceGrouping(openingTransaction, true, [
        "account",
      ]);
      return;
    }

    /** if the grouped balance has not been extended to the opening Transaction date, then we need
     * to fill the groupedBalance to get date to get the right balance number
     */
    const openingTransactionDate = openingTransaction.transactionDate.date;
    if (
      groupedBalance.lastRunningDateEnd &&
      openingTransactionDate > groupedBalance.lastRunningDateEnd
    ) {
      // await groupedBalance.fillTheGranularityGapsUntilDate(openingTransactionDate);
      await groupedBalance.copyGranularityBalanceFromLastDate(openingTransactionDate);
    } else if (!groupedBalance.lastRunningDateEnd) {
      groupedBalance.lastRunningDateEnd = openingTransactionDate;
    }

    // Align the balance of the opening transaction. Update the value if it doesn't align properly with the balance.
    await this.checkBalanceOnTransaction(openingTransaction, groupedBalance);

    // Add the transaction to the grouped balance so the new amount can be a part of the balance grouping
    await groupedBalance.addTransactionToEndOfBalanceGrouping(openingTransaction, true, [
      "account",
    ]);
  }

  /**
   * Aligns the closing balance transaction with the bank imported balance number
   * @param closingTransaction
   * @param groupedBalance
   * @private
   */
  private async alignClosingTransaction(
    closingTransaction: Transaction,
    groupedBalance: BalanceGrouping
  ) {
    // if the bankImportedBalance on the closing balance isn't set properly then we can skip the alignment
    if (!closingTransaction.bankImportedBalance && isNaN(closingTransaction.bankImportedBalance)) {
      closingTransaction.quantity.actualQuantity.amount = 0;
      closingTransaction.balance.currencyBalances = {};
      await this.normalizeTransaction.normalizeImportedTransaction(closingTransaction);
      await groupedBalance.addTransactionToExistingBalanceGrouping(closingTransaction, true, [
        "account",
      ]);
      return;
    }

    // Align the balance of the closing transaction. Update the value if it doesn't align properly with the balance.
    await this.checkBalanceOnTransaction(closingTransaction, groupedBalance);

    // Add the transaction to the grouped balance so the new amount can be a part of the balance grouping
    await groupedBalance.addTransactionToExistingBalanceGrouping(closingTransaction, true, [
      "account",
    ]);
  }
}
