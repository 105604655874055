import { Injectable } from "@angular/core";

import { SourceTransaction } from "@bitwarden/web-vault/app/models/data/blobby/source-transaction.data";
import { BookService } from "@bitwarden/web-vault/app/services/DataService/book/book.service";
import { AccountValidator } from "@bitwarden/web-vault/app/validators/entity/account-validator";

import { TransactionService } from "../../app/services/DataService/transaction/transaction.service";

import { BaseValidator, potentialTransaction, validationResult } from "./base-validator";
import { Validator } from "./validator";

@Injectable({
  providedIn: "root",
})
export class BasiqValidator extends BaseValidator implements Validator {
  constructor(
    private transactionService: TransactionService,
    public accountValidator: AccountValidator,
    public bookService: BookService
  ) {
    super(accountValidator, bookService);
  }

  /**
   * This will check if the record coming from plaid has duplicate
   * if no matches found on the record then it will be a new record
   *
   * @param importTransactions
   */

  async matchTransactionRecord(
    importTransactions: Array<potentialTransaction>
  ): Promise<validationResult> {
    const existingTransactions = await this.transactionService.getAllSourceTransactions();

    return await this.validate(importTransactions, existingTransactions);
  }

  /** sourceTransaction and transaction are saved on the same account when coming from Basiq */
  async checkAccountDuplicity(
    existingTransaction: SourceTransaction,
    transaction: potentialTransaction
  ) {
    return existingTransaction.accountId === transaction.accountId;
  }
}
