import { Observable } from "rxjs";

import { StateManagement } from "../../services/DataService/state-management/state.management";

export class SideMenu {
  navigation = false;
  settingPanel = false;
  transactionPanel = false;
  referenceDataPanel = false;

  getSideMenuState(stateManagement: StateManagement, sideMenu$: Observable<SideMenu>) {
    return stateManagement.getState(sideMenu$) as SideMenu;
  }

  protected toggleNavigation(sideMenu: SideMenu) {
    return {
      ...sideMenu,
      navigation: !sideMenu.navigation,
      settingPanel: false,
      transactionPanel: false,
      referenceDataPanel: false,
    };
  }

  protected toggleSideMenu(sideMenu: SideMenu, panelName: string) {
    let newSideMenu = {};
    Object.entries(sideMenu).forEach(([key, value]) => {
      if (key !== "navigation") {
        newSideMenu = {
          ...newSideMenu,
          [key]: key === panelName ? !value : false,
        };
      } else {
        newSideMenu = {
          navigation: sideMenu.navigation,
        };
      }
    });
    return newSideMenu;
  }

  protected getSubMenuId(event: any) {
    const elemAttr = event.currentTarget.attributes;
    return elemAttr.id ? elemAttr.id.value : null;
  }

  protected resetSideMenu(sideMenu: SideMenu) {
    let newSideMenu = {};
    Object.entries(sideMenu).forEach(([key]) => (newSideMenu = { ...newSideMenu, [key]: false }));

    return newSideMenu;
  }
}
