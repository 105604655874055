import { DateUtil } from "@bitwarden/web-vault/app/shared/utils/helper.date/date-util";

export function getDefaultSubscription() {
  return new Subscription({
    __v: 1,
    id: "default",
    status: "active",
    subscription: {
      id: "default",
      customer: "",
      start: 1716528171,
      end: 5748064171,
      plan: {},
    },
    metadata: {
      id: "prod_Q9KrmBkGMSn3UL",
      name: "Gloss Vault - Free",
      description: "Free",
      advanceFiltering: "true",
      openBanking: "true",
      scope: "premium",
      features: ["Advance Filtering", "Easy Bank Connection"],
    },
  });
}

export class Subscription {
  private readonly __v = 1;
  private _id: string;

  private _status: string;

  private _expiration: number;

  private _subsciptionStart: number;

  private _subscription: Record<string, any> = undefined;

  private _metadata: Record<string, any>;

  constructor(data: Record<string, any>) {
    if (!data) {
      return;
    }

    this.id = data.id;
    this.status = data.status;
    this.expiration = data?.subscription?.end;
    this.subsciptionStart = data?.subscription?.start;
    this.subscription = data.subscription;
    this.metadata = data.metadata;
    this.__v = data.__v ?? null;
  }

  get id(): string {
    return this._id;
  }

  set id(value: string) {
    this._id = value;
  }

  get status(): string {
    return this._status;
  }

  set status(value: string) {
    this._status = value;
  }

  get expiration(): number {
    return DateUtil.toMiliseconds(this._expiration);
  }

  set expiration(value: number) {
    this._expiration = value;
  }

  get subsciptionStart(): number {
    return DateUtil.toMiliseconds(this._subsciptionStart);
  }

  set subsciptionStart(value: number) {
    this._subsciptionStart = value;
  }

  get subscription(): Record<string, any> {
    return this._subscription;
  }

  set subscription(value: Record<string, any>) {
    this._subscription = value;
  }

  get metadata(): Record<string, any> {
    return this._metadata;
  }

  set metadata(value: Record<string, any>) {
    this._metadata = value;
  }
}
