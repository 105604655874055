import { createFeatureSelector, createReducer, createSelector, on } from "@ngrx/store";

import { Preference } from "../../../../models/data/blobby/preference.data";
import { getErrorMessage } from "../../../../shared/utils/helper-state-management";

import { PreferenceActions } from "./preference.action";
export const settingsFeatureKey = "user-preference";

export interface PreferenceSettingState {
  isLoading: boolean;
  isLoaded: boolean;
  preference: Preference | boolean;
  error: unknown;
}

const initialState: PreferenceSettingState = {
  isLoading: true,
  isLoaded: false,
  preference: null,
  error: undefined,
};

const getPreferenceFeatureState = createFeatureSelector<PreferenceSettingState>(settingsFeatureKey);

export const getIsLoading = createSelector(getPreferenceFeatureState, (state) => state.isLoading);

export const getIsLoaded = createSelector(getPreferenceFeatureState, (state) => state.isLoaded);

export const getPreferenceSetting = createSelector(
  getPreferenceFeatureState,
  (state) => state.preference
);

export const getError = createSelector(getPreferenceFeatureState, (state) => state.error);

export const preferenceReducer = createReducer<PreferenceSettingState>(
  initialState,
  on(PreferenceActions.initialize, (state): PreferenceSettingState => {
    return state;
  }),
  on(PreferenceActions.loadSuccess, (state, action): PreferenceSettingState => {
    return {
      ...state,
      isLoading: false,
      isLoaded: true,
      preference: action.preference,
      error: undefined,
    };
  }),
  on(PreferenceActions.loadFailure, (state, action): PreferenceSettingState => {
    return {
      ...state,
      isLoading: false,
      isLoaded: true,
      preference: undefined,
      error: getErrorMessage(action.error),
    };
  }),
  on(PreferenceActions.updateSuccess, (state, action): PreferenceSettingState => {
    return {
      ...state,
      preference: action.preference,
      error: undefined,
    };
  }),
  on(PreferenceActions.updateFailure, (state, action): PreferenceSettingState => {
    return {
      ...state,
      error: getErrorMessage(action.error),
    };
  }),
  on(PreferenceActions.clear, (state, action): PreferenceSettingState => {
    return {
      ...state,
      isLoading: false,
      isLoaded: true,
      preference: null,
      error: undefined,
    };
  })
);
