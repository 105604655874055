import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import {
  MembershipPlan,
  MembershipPlanType,
} from "@bitwarden/web-vault/app/components/paywall/plan/membership-plan";
import { StripeConfigService } from "@bitwarden/web-vault/app/services/api/stripe-config.service";

import "./plan.scss";
@Component({
  selector: "app-plan",
  templateUrl: "./plan.component.html",
  styles: ["plan.scss"],
  providers: [StripeConfigService],
})
export class PlanComponent implements OnInit {
  private unsubscribe$ = new Subject<void>();
  private readonly CURRENCY_USD = "usd";
  private email: string;
  private name: string;

  membershipPlan = MembershipPlan;
  member: Record<string, any>;
  loading = false;

  constructor(private stripeConfigService: StripeConfigService, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.queryParams.pipe(takeUntil(this.unsubscribe$)).subscribe((params) => {
      this.email = params["email"];
      this.name = params["name"];
    });
    this.member = { email: this.email, name: this.name };
  }

  async handlePlanSubmit(plan: MembershipPlanType) {
    try {
      this.loading = true;
      const payload = this.stripeConfigService.setPayload(this.member, plan);
      const response = await this.stripeConfigService.createCheckoutSessions(payload);

      if (response.url) {
        window.location = response.url;
      }
    } catch (error) {
      this.loading = false;
      // eslint-disable-next-line no-console
      console.error("Failed to process stripe payment:", error);
      throw error;
    }
  }

  private setPayload(plan: MembershipPlanType) {
    return {
      member: {
        email: this.email,
        name: this.name,
      },
      plan: {
        currency: this.CURRENCY_USD,
        name: plan.title,
        unitAmount: plan.priceInCents,
      },
    };
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
