import { MatTableDataSource } from "@angular/material/table";

import { Row } from "../../../importers/data-mapper/mapping-engine-types";
import { ImportFlow } from "../../../importers/store/import.store";
import { Institution } from "../../../models/data/blobby/institution.data";
import { TransactionImportType } from "../../../models/enum/transactionImportType";
import { ProcessStatus, TableColumns } from "../../../models/types/import.types";

export interface PlaidImport {
  typeName: TransactionImportType;
  institution: Institution | null;
}

export class PlaidType implements PlaidImport {
  typeName = TransactionImportType.plaid;
  institution: Institution = null;
  institutions?: Institution[];
  importFlow?: ImportFlow;
  arrangeTableColumns?: TableColumns[] = null;
  displayedColumns: string[] = null;
  materialTableDataSource: MatTableDataSource<Row> = null;
  arrangementStatus?: ProcessStatus = null;
}
