import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { AuthGuard } from "@bitwarden/angular/guards/auth.guard";
import { InitialRedirectGuard } from "@bitwarden/angular/guards/initialRoute.guard";
import { LockGuard } from "@bitwarden/angular/guards/lock.guard";
import { UnauthGuard } from "@bitwarden/angular/guards/unauth.guard";
import { PlanComponent } from "@bitwarden/web-vault/app/components/paywall/plan/plan.component";
import { PreviewTransactionTableComponent } from "@bitwarden/web-vault/app/components/preview-transaction-table/preview-transaction-table.component";
import { SyncComponent } from "@bitwarden/web-vault/app/components/sync/sync.component";
import { CabinetComponent } from "@bitwarden/web-vault/app/gloss/cabinet/cabinet.component";
import { TransactionFormComponent } from "@bitwarden/web-vault/app/gloss/dash/transactions-form/transaction-form.component";
import { EstimatesComponent } from "@bitwarden/web-vault/app/gloss/estimates/estimates.component";
import { ReferenceManagementComponent } from "@bitwarden/web-vault/app/gloss/import/reference-management/reference-management.component";
import { ManageCategoriesComponent } from "@bitwarden/web-vault/app/gloss/settings/manage-categories/manage-categories.component";
import { ManageClassificationsComponent } from "@bitwarden/web-vault/app/gloss/settings/manage-classifications/manage-classifications.component";
import { ManageInstitutionsComponent } from "@bitwarden/web-vault/app/gloss/settings/manage-institutions/manage-institutions.component";
import { ManagePreferencesComponent } from "@bitwarden/web-vault/app/gloss/settings/manage-preferences/manage-preferences.component";
import { GlossRouteEnum } from "@bitwarden/web-vault/app/models/enum/navigation.enum";
import { SubscriptionComponent } from "@bitwarden/web-vault/app/settings/subscription.component";
import { getRouteNameForNavigation } from "@bitwarden/web-vault/app/shared/utils/helper.navigation";

import { HintComponent } from "./accounts/hint.component";
import { LockComponent } from "./accounts/lock.component";
import { LoginWithDeviceComponent } from "./accounts/login/login-with-device.component";
import { LoginComponent } from "./accounts/login/login.component";
import { RecoverDeleteComponent } from "./accounts/recover-delete.component";
import { RecoverTwoFactorComponent } from "./accounts/recover-two-factor.component";
import { RegisterFormComponent } from "./accounts/register-form/register-form.component";
import { RemovePasswordComponent } from "./accounts/remove-password.component";
import { SetPasswordComponent } from "./accounts/set-password.component";
import { TwoFactorComponent } from "./accounts/two-factor.component";
import { UpdatePasswordComponent } from "./accounts/update-password.component";
import { VerifyEmailTokenComponent } from "./accounts/verify-email-token.component";
import { MemberRegisterComponents } from "./components/paywall/register/member-register.components";
import { DashPrimaryComponent } from "./gloss/dashboard-primary/dash-primary.component";
import { ImportCsvComponent } from "./gloss/import/import-csv.component";
import { ImportManagementComponent } from "./gloss/import/import-management.component";
import { ManageAccountComponent } from "./gloss/settings/manage-accounts/manage-account.component";
import { HomeGuard } from "./guards/home.guard";
import { FrontendLayoutComponent } from "./layouts/frontend-layout.component";
import { UserLayoutComponent } from "./layouts/user-layout.component";
import { AccountComponent } from "./settings/account.component";
import { DangerZoneComponent } from "./settings/danger-zone.component";
import { SecurityRoutingModule } from "./settings/security-routing.module";
import { SettingsComponent } from "./settings/settings.component";
import { SponsoredFamiliesComponent } from "./settings/sponsored-families.component";
import { SubscriptionRoutingModule } from "./settings/subscription-routing.module";
import { UserAccessPageComponent } from "./settings/user-access-page.component";
import { GeneratorComponent } from "./tools/generator.component";
import { ToolsComponent } from "./tools/tools.component";
import { VaultModule } from "./vault/vault.module";

const routes: Routes = [
  {
    path: "",
    component: FrontendLayoutComponent,
    data: { doNotSaveUrl: true },
    children: [
      {
        path: "",
        pathMatch: "full",
        children: [], // Children lets us have an empty component.
        canActivate: [HomeGuard], // Redirects either to primary-dashboard, login or lock page.
      },
      { path: "login", component: LoginComponent, canActivate: [UnauthGuard] },
      { path: "membership-plan", component: PlanComponent, canActivate: [UnauthGuard] },
      { path: "register-member", component: MemberRegisterComponents, canActivate: [UnauthGuard] },
      {
        path: "login-with-device",
        component: LoginWithDeviceComponent,
        data: { titleId: "loginWithDevice" },
      },
      {
        path: "register",
        component: RegisterFormComponent,
        canActivate: [UnauthGuard],
        data: { titleId: "createAccount" },
      },
      { path: "2fa", component: TwoFactorComponent, canActivate: [UnauthGuard] },
      {
        path: "trial",
        redirectTo: "register",
        pathMatch: "full",
      },
      {
        path: "set-password",
        component: SetPasswordComponent,
        data: { titleId: "setMasterPassword" },
      },
      {
        path: "hint",
        component: HintComponent,
        canActivate: [UnauthGuard],
        data: { titleId: "passwordHint" },
      },
      {
        path: "lock",
        component: LockComponent,
        canActivate: [LockGuard],
      },
      { path: "verify-email", component: VerifyEmailTokenComponent },
      { path: "recover", pathMatch: "full", redirectTo: "recover-2fa" },
      {
        path: "recover-2fa",
        component: RecoverTwoFactorComponent,
        canActivate: [UnauthGuard],
        data: { titleId: "recoverAccountTwoStep" },
      },
      {
        path: "recover-delete",
        component: RecoverDeleteComponent,
        canActivate: [UnauthGuard],
        data: { titleId: "deleteAccount" },
      },
      {
        path: "update-password",
        component: UpdatePasswordComponent,
        canActivate: [AuthGuard],
        data: { titleId: "updatePassword" },
      },
      {
        path: "remove-password",
        component: RemovePasswordComponent,
        canActivate: [AuthGuard],
        data: { titleId: "removeMasterPassword" },
      },
      {
        path: "transaction-preview",
        component: PreviewTransactionTableComponent,
      },
    ],
  },
  {
    path: "",
    component: UserLayoutComponent,
    canActivate: [AuthGuard, InitialRedirectGuard],
    children: [
      {
        path: "vault",
        loadChildren: () => VaultModule,
      },
      { path: getRouteNameForNavigation(GlossRouteEnum.estimates), component: EstimatesComponent },
      {
        path: getRouteNameForNavigation(GlossRouteEnum.dashboard),
        component: DashPrimaryComponent,
      },
      {
        path: getRouteNameForNavigation(GlossRouteEnum.import),
        component: ImportManagementComponent,
      },
      {
        path: getRouteNameForNavigation(GlossRouteEnum.reference),
        component: ReferenceManagementComponent,
      },
      { path: "import-csv", component: ImportCsvComponent },
      { path: "sync", component: SyncComponent },
      { path: "cabinet", component: CabinetComponent },
      { path: "add-edit-transaction", component: TransactionFormComponent },
      {
        path: "gloss-settings",
        children: [
          { path: "", pathMatch: "full", redirectTo: "account" },
          {
            path: getRouteNameForNavigation(GlossRouteEnum.account),
            component: ManageAccountComponent,
          },
          {
            path: getRouteNameForNavigation(GlossRouteEnum.classifications),
            component: ManageClassificationsComponent,
          },
          {
            path: getRouteNameForNavigation(GlossRouteEnum.categories),
            component: ManageCategoriesComponent,
          },
          {
            path: getRouteNameForNavigation(GlossRouteEnum.institutions),
            component: ManageInstitutionsComponent,
          },
          {
            path: getRouteNameForNavigation(GlossRouteEnum.settings),
            component: ManagePreferencesComponent,
          },
        ],
      },

      {
        path: "settings",
        component: SettingsComponent,
        children: [
          { path: "", pathMatch: "full", redirectTo: "account" },
          { path: "account", component: AccountComponent, data: { titleId: "myAccount" } },
          {
            path: "preferences",
            component: ManagePreferencesComponent,
            data: { titleId: "preferences" },
          },
          {
            path: "security",
            loadChildren: () => SecurityRoutingModule,
          },
          {
            path: "danger-zone",
            component: DangerZoneComponent,
          },
          {
            path: "access",
            component: UserAccessPageComponent,
            data: { titleId: "access" },
          },
          { path: "subscription", component: SubscriptionComponent },
          {
            path: "subscription",
            loadChildren: () => SubscriptionRoutingModule,
          },
          {
            path: "sponsored-families",
            component: SponsoredFamiliesComponent,
            data: { titleId: "sponsoredFamilies" },
          },
        ],
      },
      {
        path: "tools",
        component: ToolsComponent,
        canActivate: [AuthGuard],
        children: [
          { path: "", pathMatch: "full", redirectTo: "generator" },
          {
            path: "",
            loadChildren: () =>
              import("./tools/import-export/import-export.module").then(
                (m) => m.ImportExportModule
              ),
          },
          {
            path: "generator",
            component: GeneratorComponent,
            data: { titleId: "generator" },
          },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      paramsInheritanceStrategy: "always",
      // enableTracing: true,
    }),
  ],
  exports: [RouterModule],
})
export class OssRoutingModule {}
