import { Component, Inject, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatDatepicker } from "@angular/material/datepicker";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSelect, MatSelectChange } from "@angular/material/select";

import { Book } from "@bitwarden/web-vault/app/models/data/blobby/book.data";
import { CabinetFileUploadedData } from "@bitwarden/web-vault/app/models/types/cabinet.types";
import { DataRepositoryService } from "@bitwarden/web-vault/app/services/DataRepository/data-repository.service";
import { CabinetComponentService } from "@bitwarden/web-vault/app/services/DataService/vault-file/cabinet.component.service";

@Component({
  selector: "app-cabinet-file-upload",
  templateUrl: "./cabinet-file-upload.component.html",
})
export class CabinetFileUploadComponent implements OnInit, OnDestroy {
  uploading = false;
  uploaded = false;
  @ViewChild("accountSelect") accountSelect: MatSelect;
  @ViewChild("fromPicker") fromPicker: MatDatepicker<Date>;
  @ViewChild("toPicker") toPicker: MatDatepicker<Date>;
  fromDateText = "";
  toDateText = "";
  accounts: Book[] = [];

  fileName = "";
  fileContent: string | ArrayBuffer | null = null;
  selectedFile: File | null = null;

  selectedAccounts: { [id: string]: string } = {};

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      closeForm: CallableFunction;
    },
    private dataRepositoryService: DataRepositoryService,
    private cabinetComponentService: CabinetComponentService
  ) {}

  async ngOnInit(): Promise<void> {
    this.accounts = await this.dataRepositoryService.getAllBooks();
  }

  ngOnDestroy(): void {
    this.uploading = false;
  }

  fileSelected(event: any) {
    const file = event.target.files[0];
    if (file) {
      const isSizeValid = this.cabinetComponentService.validateFileSize(file);
      if (!isSizeValid) {
        return;
      }
      this.selectedFile = file;
      this.fileName = file.name;
    }
  }

  private isFromDateAfterToDate(): boolean {
    return new Date(this.toDateText) < new Date(this.fromDateText);
  }

  onDateChange(event: any, controlName: string) {
    const date = event.value;
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    const formattedDate = date.toLocaleDateString("en-CA", options);

    if (controlName === "fromDate") {
      this.fromDateText = formattedDate;
      if (this.isFromDateAfterToDate()) {
        this.toDateText = formattedDate;
        this.toPicker.select(date);
      }
    } else if (controlName === "toDate") {
      this.toDateText = formattedDate;
      if (this.isFromDateAfterToDate()) {
        this.fromDateText = formattedDate;
        this.fromPicker.select(date);
      }
    }
  }

  accountSelected(event: MatSelectChange) {
    this.selectedAccounts = {};
    event.value.forEach((id: string) => {
      const account = this.accounts.find((acc) => acc.id === id);
      if (account) {
        this.selectedAccounts[id] = account.name;
      }
    });
  }

  isAccountNumberOdd(index: number): boolean {
    const length = Object.keys(this.selectedAccounts).length;
    return length % 2 !== 0 && index === length - 1;
  }

  removeSelectedAccount(accountId: string) {
    delete this.selectedAccounts[accountId];
    this.accountSelect.value = Object.keys(this.selectedAccounts);
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
  }

  onDragLeave(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
  }

  onDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    const file = event.dataTransfer?.files[0];
    if (file) {
      const newEvent = { target: { files: [file] } };
      this.fileSelected(newEvent);
    }
  }

  async saveUploadedFile() {
    try {
      this.uploading = true;
      const data: CabinetFileUploadedData = {
        name: this.selectedFile?.name,
        mimeType: this.selectedFile?.type,
        statementToDate: this.toDateText,
        statementFromDate: this.fromDateText,
        statementAccounts: Object.keys(this.selectedAccounts),
        file: this.selectedFile,
      };

      const isSaved = await this.cabinetComponentService.saveFile(data);
      if (isSaved) {
        this.data.closeForm(isSaved);
      }
    } catch (error) {
      this.cabinetComponentService.displayCatchError(error);
    } finally {
      this.uploading = false;
    }
  }
}
