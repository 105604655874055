import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { PlaidConfig } from "../../../app/models/data/plaid-config.data";

@Injectable()
export class PlaidConfigService extends PlaidConfig {
  readonly product: Array<string> = ["transactions"];

  constructor(private http: HttpClient) {
    super();
  }

  async fetchPlaidToken(): Promise<string> {
    try {
      const endpoint = this.config.endpoint["create_token"];
      const url = `${this.url + endpoint}`;
      const payload = {
        //todo get the client_id from the user. like the email
        user: { client_user_id: "" },
      };

      const response = await this.http.post<any>(url, payload).toPromise();
      return response.link_token;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("Failed to fetch Plaid token:", error);
      throw error;
    }
  }

  async fetchPlaidTransactions(access_token: string): Promise<any> {
    const endpoint = this.config.endpoint["get_transaction"];
    const url = `${this.url + endpoint}`;

    const response = await this.http.post<any>(url, this.getPayLoad(access_token)).toPromise();
    return {
      transactions: Object.values(response.transactions),
      accounts: Object.values(response.accounts),
    };
  }

  async exchangePlaidToken(public_token: string): Promise<string> {
    try {
      const endpoint = this.config.endpoint["exchange_token"];
      const url = `${this.url + endpoint}`;
      const payload = {
        public_token: public_token,
      };

      const response = await this.http.post<any>(url, payload).toPromise();
      return response.access_token;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("Failed to exchange Plaid token:", error);
      throw error;
    }
  }

  private getPayLoad(access_token: string) {
    return {
      access_token: access_token,
      client_id: this.client_id,
      secret: this.secret,
      start_date: "2023-01-01",
      end_date: "2023-07-28",
    };
  }
}
