export enum UserLocationEnum {
  AU = "Australia",
  HK = "Hong Kong",
}

export enum UserLocationCodeEnum {
  AU = "AU",
  HK = "HK",
}

export enum UserLocationCurrencyEnum {
  AU = "AUD",
  HK = "HKD",
}
