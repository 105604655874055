import { AccountProgressBar } from "./account-progress-bar";
import { BalanceProgressBar } from "./balance-progress-bar";
import { BaseProgressBar } from "./base-progress-bar";
import { CsvProgressBar } from "./csv-progress-bar";
import { ProgressBarType } from "./progress-bar.enum";

export class ProgressBarFactory extends BaseProgressBar {
  constructor(loadingPercent: number, importingLabel: string, totalItems: number) {
    super(loadingPercent, importingLabel, totalItems);
    this.loadingPercent = loadingPercent;
    this.importingLabel = importingLabel;
    this.totalItems = totalItems;
  }

  create(type: string) {
    if (type === ProgressBarType.Csv) {
      return new CsvProgressBar(this.loadingPercent, this.importingLabel, this.totalItems);
    }

    if (type === ProgressBarType.Account) {
      return new AccountProgressBar(this.loadingPercent, this.importingLabel, this.totalItems);
    }

    if (type === ProgressBarType.Balance) {
      return new BalanceProgressBar(this.loadingPercent, this.importingLabel, this.totalItems);
    }
  }
}
