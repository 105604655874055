<app-spinner [showSpinner]="loading"></app-spinner>
<form class="modal-content" [formGroup]="addForm" (ngSubmit)="submitAddForm()">
  <div class="modal-body" *ngIf="loading">
    <i
      class="bwi bwi-spinner bwi-spin text-muted"
      title="{{ 'loading' | i18n }}"
      aria-hidden="true"
    ></i>
    <span class="sr-only">{{ "loading" | i18n }}</span>
  </div>
  <div class="modal-body">
    <ng-container>
      <div class="form-group mb-4">
        <mat-form-field appearance="outline">
          <mat-label>{{ "country" | i18n }}</mat-label>
          <input
            matInput
            formControlName="addedInstitutionCountry"
            autocomplete="off"
            (keyup)="searchForCountry()"
            [matAutocomplete]="autoCountry"
            placeholder="Pick a country"
          />
          <mat-autocomplete autoActiveFirstOption #autoCountry="matAutocomplete">
            <mat-option
              *ngFor="let option of filteredCountries"
              [value]="option.name"
              (onSelectionChange)="addCountrySelected(option)"
            >
              {{ option.name }}
            </mat-option>
          </mat-autocomplete>
          <mat-error *ngIf="addForm.controls.addedInstitutionCountry.hasError('required')"
            >Field is required</mat-error
          >
        </mat-form-field>
      </div>
    </ng-container>

    <ng-container *ngIf="displayInstitutions">
      <div class="form-group mb-4">
        <mat-form-field appearance="outline">
          <mat-label>{{ "institution" | i18n }}</mat-label>
          <input
            matInput
            formControlName="addedInstitutionName"
            autocomplete="off"
            (keyup)="searchForInstitution()"
            [matAutocomplete]="autoInstitution"
            placeholder="Pick an institution"
          />
          <mat-autocomplete autoActiveFirstOption #autoInstitution="matAutocomplete">
            <mat-option
              *ngFor="let option of filteredCountryInstitutions"
              [value]="option.name"
              (onSelectionChange)="institutionSelected(option)"
            >
              {{ option.name }}
            </mat-option>
          </mat-autocomplete>
          <mat-error *ngIf="addForm.controls.addedInstitutionName.hasError('required')"
            >Field is required</mat-error
          >
        </mat-form-field>
      </div>
    </ng-container>
  </div>

  <span class="info-text"
    >{{ "noInstitution" | i18n }}
    <a class="btn-filter link-span" (click)="openContactForm()"> {{ "letUsKnow" | i18n }}</a>
    {{ "andWeWillAddItForYou" | i18n }}</span
  >
  <div class="modal-footer right-buttons-container">
    <button mat-raised-button mat-dialog-close (click)="closeDialogue()">
      {{ "cancel" | i18n }}
    </button>
    <button mat-raised-button color="primary" type="submit">
      {{ "save" | i18n }}
    </button>
  </div>
</form>
