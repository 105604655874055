import { DOCUMENT } from "@angular/common";
import { Renderer2, Inject, Injectable } from "@angular/core";

@Injectable()
export class ScriptLoaderService {
  readonly linkInitializeUri: string = "https://cdn.plaid.com/link/v2/stable/link-initialize.js";
  readonly elementId: string = "plaid-initialize";

  constructor(@Inject(DOCUMENT) private document: Document) {}

  /**
   * Append the JS tag to the Document Body.
   * @param renderer The Angular Renderer
   * @param src The path to the script
   *
   * @returns the script element
   */
  loadJsScript(renderer: Renderer2, src: string): HTMLScriptElement {
    const script = renderer.createElement("script");
    script.id = this.elementId;
    script.type = "text/javascript";
    script.src = src;
    renderer.appendChild(this.document.head, script);
    return script;
  }
}
