import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "app-user-choice-date-format",
  templateUrl: "./user-choice-date-format.component.html",
})
export class UserChoiceDateFormatComponent implements OnInit {
  selectedFormat: string;
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      possibleDateFormats: string[];
      formatSelected: CallableFunction;
    }
  ) {}

  ngOnInit() {
    this.selectedFormat = this.data.possibleDateFormats[0];
  }
  userSelectedFormat(event: string) {
    this.selectedFormat = event;
  }

  saveDateFormatChoice() {
    this.data.formatSelected(this.selectedFormat);
  }

  closeDialogue() {
    this.data.formatSelected(this.data.possibleDateFormats[0]);
  }
}
