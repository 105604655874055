import { GlossNumber } from "@bitwarden/web-vault/app/models/data/shared/gloss-number";

export class GlossQuantity {
  private _actualQuantity: GlossNumber;
  private _convrate: number;
  private _convsym: string;
  private _currency: string;

  get actualQuantity(): GlossNumber {
    return this._actualQuantity;
  }

  set actualQuantity(value: GlossNumber) {
    this._actualQuantity = value;
  }

  get convrate(): number {
    return this._convrate;
  }

  set convrate(value: number) {
    this._convrate = Number(value);
  }

  get convsym(): string {
    return this._convsym;
  }

  set convsym(value: string) {
    this._convsym = value;
  }

  get currency(): string {
    return this._currency;
  }

  set currency(value: string) {
    // Note: do not set the currency to the base here because we should default to the account
    // currency and not the system base currency for the import
    this._currency = value;
  }

  setQuantityAmountSymbol(value: number, symbol: string) {
    this.setQuantityAmount(value);
    this.setQuantitySymbol(symbol);
  }

  setQuantityAmount(value: number) {
    this.actualQuantity.amount = value;
  }

  setQuantitySymbol(symbol: string) {
    this.actualQuantity.symbol = symbol;
  }

  setQuantityPrecision(precision: number) {
    this.actualQuantity.precision = precision;
  }

  setToQuantityObj(response: Record<string, any>) {
    return typeof response._quantity === "object"
      ? this.setFromObject(response._quantity)
      : this.setFromQueryString(response);
  }

  /**
   * Add the amounts from one GlossQuantity to this one
   * @param quantity
   */
  add(additionalGlossQuantity: GlossQuantity) {
    if (this.actualQuantity.symbol == additionalGlossQuantity.actualQuantity.symbol) {
      this.actualQuantity.amount =
        this.actualQuantity.amount + additionalGlossQuantity.actualQuantity.amount;
    } else {
      throw new Error("Cannot add two GlossQuantity amounts that have different symbols");
    }
  }

  private setFromObject(quantity: GlossQuantity) {
    return this.createGlossQuantityInstance(quantity);
  }

  private setFromQueryString(response: Record<string, any>) {
    return this.createGlossQuantityInstance(response);
  }

  private createGlossQuantityInstance(items: Record<string, any>) {
    if (typeof items.quantity === "object" && items.quantity instanceof GlossQuantity) {
      return items.quantity;
    }

    if (typeof items.actualQuantity === "object") {
      this.actualQuantity = new GlossNumber().setToGlossNumberObj(items.actualQuantity);
    } else if (typeof items._actualQuantity === "object") {
      this.actualQuantity = new GlossNumber().setToGlossNumberObj(items._actualQuantity);
      // this.actualQuantity.setToGlossNumberObj(items._actualQuantity)
    } else {
      this.actualQuantity = new GlossNumber();
      const quantity = items.quantity || items._quantity;
      const symbol = items.symbol || items._symbol || items.currency || items._currency;
      if (quantity && typeof quantity === "number") {
        this.setQuantityAmount(quantity);
      }
      if (symbol && typeof symbol === "string") {
        this.setQuantitySymbol(symbol);
      }
    }
    this.currency = items.currency || items._currency;
    this.convrate = items.convrate || items._convrate;
    this.convsym = items.convsym || items._convsym;

    /*
    // if the conversion rate and conversion symbol are supplied, then the currency of this transaction is the convsym
    if (this.convrate && this.convsym && this.currency != this.convsym) {
      this.currency = this.convsym;
    }
     */

    if (!this.convrate && this.actualQuantity.symbol === this.currency) {
      this.convrate = 1;
    }

    //todo  @sinan  this.actualQuantity = items.quantity || items._actualQuantity; above return NaN when items.quantity = 0
    // if (isNaN(this.actualQuantity)) {
    //   this.actualQuantity = 0;
    // }

    return this;
  }
}
