import { Injectable } from "@angular/core";

import { SupportConfigData } from "../../models/data/support-config.data";
import { DataRepositoryService } from "../DataRepository/data-repository.service";

interface SupportPayload {
  templateData: {
    senderFirstName: string;
    senderLastName: string;
    senderEmailAddress: string;
    message: string;
  };
  emailAction: string;
}

@Injectable({
  providedIn: "root",
})
export class SupportConfigService extends SupportConfigData {
  constructor(private dataRepositoryService: DataRepositoryService) {
    super();
  }

  async sendSupport(payload: SupportPayload): Promise<any> {
    const endpoint = this.config.endpoint["send_message"];

    return await this.dataRepositoryService.send("POST", endpoint, payload, true, true, this.url);
  }

  setPayload(message: Record<string, string>) {
    return {
      templateData: {
        senderFirstName: message.firstName,
        senderLastName: message.lastName,
        senderEmailAddress: message.email,
        message: message.message,
      },
      emailAction: "supportRequest",
    };
  }
}
