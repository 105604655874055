import { Allocation } from "@bitwarden/web-vault/app/models/data/allocation.data";
import { Transaction } from "@bitwarden/web-vault/app/models/data/blobby/transaction.data";
import {
  ChildNodes,
  GroupingNodeType,
} from "@bitwarden/web-vault/app/models/types/balanceGroupingTypes";
import { GroupingNode } from "@bitwarden/web-vault/app/services/DataCalculationService/balanceGrouping/groupingNode";
import { GroupingNodeFactory } from "@bitwarden/web-vault/app/services/DataCalculationService/balanceGrouping/groupingNodeFactory";

export class AllocationGroupingNode extends GroupingNode {
  nodeType: GroupingNodeType = "allocation";
  defaultChildren: Array<GroupingNodeType> = [];
  usedGroupings: Array<GroupingNodeType> = [this.nodeType];
  category = "noCategory";
  classification = "noClassification";

  /**
   * Add the transaction to this node's balance if the transaction matches the group criteria.
   * @param transaction
   */
  async addTransaction(transaction: Transaction) {
    if (Array.isArray(transaction.allocations)) {
      for (const allocation of transaction.allocations) {
        if (this.allocationMatchesGroup(allocation)) {
          if (this.category === "noCategory" && allocation.category) {
            this.category = allocation.category;
          }
          if (this.classification === "noClassification" && allocation.classification) {
            this.classification = allocation.classification;
          }
          this.addBalance(allocation.balance);
          this.transactions.push(transaction.id);
          await this.processTransactionForChildren(transaction);
        }
      }
    }
  }

  allocationMatchesGroup(allocation: Allocation): boolean {
    return allocation.id === this.groupingKey;
  }

  /**
   * Returns a list of ChildNodeGroupings of this GroupingNodeType transaction would create
   * @param transaction
   */
  async processChildren(transaction: Transaction): Promise<ChildNodes> {
    const allocationNodes: ChildNodes = {};

    if (Array.isArray(transaction.allocations)) {
      for (const allocation of transaction.allocations) {
        const transactionAllocationNode = GroupingNodeFactory.createNode(
          "allocation",
          this.usedGroupings,
          true,
          this.limitChildren
        );
        const allocationID = allocation.id;
        transactionAllocationNode.groupingKey = allocationID;
        await transactionAllocationNode.addTransaction(transaction);
        allocationNodes[allocationID] = transactionAllocationNode;
      }
    }
    return allocationNodes;
  }

  /**
   * Adds the running values from the additionalGroupingNode to the
   * @param additionalGroupingNode
   */
  async addRunning(additionalGroupingNode: AllocationGroupingNode) {
    await super.addRunning(additionalGroupingNode);
    if (this.category == "noCategory" && additionalGroupingNode.category !== "noCategory") {
      this.category = additionalGroupingNode.category;
    }
    if (
      this.classification == "noClassification" &&
      additionalGroupingNode.classification !== "noClassification"
    ) {
      this.classification = additionalGroupingNode.classification;
    }
  }
}
