import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

import { Institution } from "@bitwarden/web-vault/app/models/data/blobby/institution.data";
import { InstitutionService } from "@bitwarden/web-vault/app/services/DataService/institution/institution.service";

@Component({
  selector: "app-institutions-add-edit",
  templateUrl: "./institutions-add-edit.component.html",
  styles: [".mat-form-field {width: 100%;}"],
})
export class InstitutionsAddEditComponent {
  /** if an institution is being edited */
  editMode = false;
  /** if addition is on display */
  isAddInstitution = true;
  /** if creation is on display */
  isCreateInstitution = false;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      institution: Institution;
      actionSucceeded: CallableFunction;
      delete: CallableFunction;
    },
    private institutionService: InstitutionService,
    private dialogRef: MatDialogRef<InstitutionsAddEditComponent>
  ) {
    this.editMode = data.institution?.id != null;
  }

  /** display addition section of institution */
  showAddInstitution() {
    this.isAddInstitution = true;
    this.isCreateInstitution = false;
  }

  /** display creation section of institution */
  showCreateInstitution() {
    this.isCreateInstitution = true;
    this.isAddInstitution = false;
  }

  /** close the dialogue */
  closeDialogue() {
    this.dialogRef.close();
  }

  async deleteInstitution(institution: Institution) {
    this.data.delete(institution);
  }
}
