import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { concatMap, of } from "rxjs";
import { mergeMap, map, catchError } from "rxjs/operators";

import { PreferenceService } from "../../../../services/DataService/preference/preference.service";

import { PreferenceActions } from "./preference.action";

@Injectable()
export class PreferenceEffects {
  constructor(private actions$: Actions, private preferenceService: PreferenceService) {}

  loadPreference$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PreferenceActions.load),
      mergeMap(() =>
        this.preferenceService.loadPreference$().pipe(
          map((preference) => PreferenceActions.loadSuccess({ preference })),
          catchError((error: unknown) => of(PreferenceActions.loadFailure({ error })))
        )
      )
    );
  });

  updatePreference$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PreferenceActions.update),
      concatMap((action) =>
        this.preferenceService.updatePreference$(action.preference).pipe(
          map((preference) => PreferenceActions.updateSuccess({ preference })),
          catchError((error: unknown) => of(PreferenceActions.updateFailure({ error })))
        )
      )
    );
  });
}
