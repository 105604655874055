import { HelperCommon } from "@bitwarden/web-vault/app/shared/utils/helper.common";

const AgGridRightClickLinkingButtons = (params: any) => {
  const parent = params.context.componentParent;
  const { i18nService } = parent;

  const linkButtons = {
    name: i18nService.t("link"),
    icon: '<span class="material-icons">link</span>',
    subMenu: [
      {
        name: i18nService.t("linkAsConversion"),
        disabled: !parent.isLinkButtonEnabled(),
        action: async function () {
          await parent.linkConversion();
        },
      },
      {
        name: i18nService.t("linkAsTransfer"),
        disabled: !parent.isLinkButtonEnabled(),
        action: async function () {
          await parent.linkTransfer();
        },
      },
      {
        name: i18nService.t("removeLinkage"),
        disabled: !parent.isLinkButtonEnabled(),
        action: async function () {
          await parent.unlinkTransactions();
        },
      },
    ],
  };
  return HelperCommon.isDevEnv() ? linkButtons : null;
};

const AgGridRightClickEditButton = (params: any) => {
  const parent = params.context.componentParent;
  const { i18nService } = parent;

  return {
    name: i18nService.t("edit"),
    disabled: true,
    action: function () {
      parent.handleEdit(params.node.data);
    },
    icon: '<span class="material-icons">edit</span>',
  };
};

const AgGridRightClickDeleteButton = (params: any, isBetaUser: boolean) => {
  const parent = params.context.componentParent;
  const { i18nService } = parent;

  return {
    name: i18nService.t("delete"),
    disabled: !isBetaUser,
    action: function () {
      params.context.componentParent.rightClickDeleteTransactions(params.node.data);
    },
    cssClasses: ["redFont", "bold"],
    icon: '<span class="material-icons">delete</span>',
  };
};

export const getRightClickButtons = (params: any, isBetaUser: boolean) => {
  const linkingButtons = AgGridRightClickLinkingButtons(params);
  const editButton = AgGridRightClickEditButton(params);
  const deleteButton = AgGridRightClickDeleteButton(params, isBetaUser);

  const rightClickButtons: any = [deleteButton, editButton];

  if (linkingButtons) {
    rightClickButtons.push(linkingButtons);
  }
  return rightClickButtons;
};
