import { RecurringPeriod } from "../../../../../../libs/common/src/enums/recurringPeriod";

export class RecurringData {
  private readonly __v = 1;
  private _id: string;

  get id(): string {
    return this._id;
  }

  // fields relating to a recurring estimate
  endDate: string; // can be null if perpetual
  recurringFrequency: number;
  recurringPeriod: RecurringPeriod; //<'Days', 'Weeks', 'Months','Years'>
}
