export class SymbolData {
  private readonly __v = 1;
  id: string;
  _id: string;
  name: string;
  // todo workaround to have symbol per account. Need to rethink that
  accountId: string;

  constructor(name: string, accountId = "") {
    this.name = name;
    this.accountId = accountId;
    this._id = crypto.randomUUID();
    this.id = name + accountId;
  }
}
