import { StatusPoint } from "@bitwarden/web-vault/app/models/types/general-types";

export const SyncStatusPoints: Record<string, StatusPoint> = {
  /** When there is no active connection to the institution*/
  started: {
    key: "started-syncing",
    icon: "sync",
    data: null,
  },
  /** When there is no active connection to the institution*/
  "no-connection": {
    key: "failed",
    icon: "error",
    data: {
      type: "failure",
      message: "Gloss Vault couldn't connect to your institution",
      messageI18nKey: "noConnectionFound",
      actions: ["connect"],
    },
  },

  /** When the connection to institution failed to refresh: meaning it could not update transactions in Basiq as well*/
  "connection-refresh-failure": {
    key: "failed",
    icon: "error",
    data: {
      type: "failure",
      message: "Connection refresh failed",
      messageI18nKey: "connectionRefreshFailed",
      actions: ["retry", "connect"],
    },
  },

  /** When the connection refreshed but somehow could not get the new data: Refresh gives us jobs, this is when this job fails*/
  "job-fetch-fail": {
    key: "failed",
    icon: "error",
    data: {
      type: "failure",
      message: "Could not retrieve data",
      messageI18nKey: "couldNotRetrieveData",
      actions: ["retry"],
    },
  },

  /** When job is successful and Basiq updates the data. Now we can fetch the new data*/
  "job-fetch-success": {
    key: "ready",
    icon: "check",
    data: {
      type: "success",
      message: "Ready to merge",
      messageI18nKey: "jobFetchSuccess",
      actions: [],
    },
  },
};
