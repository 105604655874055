import { TransactionStatusEnum } from "@bitwarden/web-vault/app/models/enum/transactionType";

import { AlignmentMappingItem, MappingConfiguration } from "../mapping-engine-types";

export const defaultMapping: MappingConfiguration = [
  /*  {
    key: "quantity",
    mapping: ["quantity", "transaction"],
    formatter: "number",
    displayAs: "Quantity",
  },*/
  {
    key: "price",
    mapping: ["price"],
    formatter: "number",
    displayAs: "Price",
  },
  {
    key: "in",
    mapping: ["in", "credit", "Credit Amount", "Deposit"],
    formatter: "number",
    displayAs: "In",
  },
  {
    key: "out",
    mapping: ["out", "debit", "Debit Amount", "Withdrawl", "Withdrawal"],
    formatter: "number",
    displayAs: "Out",
  },
  {
    key: "amount",
    mapping: ["amount", "Billing amount", "quantity", "transaction"],
    formatter: "number",
    displayAs: "Amount",
  },
  {
    key: "date",
    mapping: ["date", "datetime", "statement date", "date and time", "Transaction Date", "Date"],
    formatter: "text", // date a processed separatly down the track
    displayAs: "Transaction Date",
  },
  {
    key: "description",
    mapping: ["description", "details", "Narrative", "Transaction Details"],
    formatter: "text",
    displayAs: "Description",
  },
  {
    key: "balance",
    mapping: ["balance", "ledger balance", "debit balance"],
    formatter: "number",
    displayAs: "Balance",
  },
  {
    key: "currency",
    mapping: ["currency", "Billing currency"],
    formatter: "currencyCode",
    displayAs: "Currency",
  },
  {
    key: "convrate",
    mapping: ["convrate", "conversion rate"],
    formatter: "number",
    displayAs: "Conversion Rate",
  },
  {
    key: "convsym",
    mapping: ["convsym", "conversion currency", "conversion symbol"],
    formatter: "text",
    displayAs: "Conversion Symbol",
  },
  {
    key: "accountId",
    mapping: ["accountId", "account", "Bank Account"],
    formatter: "text",
    displayAs: "Account",
  },
  {
    key: "classifications",
    mapping: ["classifications"],
    formatter: "text",
    displayAs: "Classifications",
  },
  {
    key: "categories",
    mapping: ["categories", "category"],
    formatter: "text",
    displayAs: "Categories",
  },
  {
    key: "symbol",
    mapping: ["symbol"],
    formatter: "text",
    displayAs: "Symbol",
  },
];

export const alignmentDefaultMapping: AlignmentMappingItem[] = [
  {
    key: "openingBalance",
    keyOnTransaction: TransactionStatusEnum.opening,
    mapping: ["Opening balance", "Opening balance transaction", "Opening"],
    displayAs: "Opening Balance",
  },

  {
    key: "closingBalance",
    keyOnTransaction: TransactionStatusEnum.closing,
    mapping: ["Closing balance", "Closing balance transaction", "Closing"],
    displayAs: "Closing Balance",
  },

  {
    key: "transaction",
    keyOnTransaction: TransactionStatusEnum.transaction,
    mapping: ["Transaction", ""],
    displayAs: "Transaction",
  },
];
