import { Injectable } from "@angular/core";

import { Transaction } from "../../../models/data/blobby/transaction.data";

@Injectable({
  providedIn: "root",
})
export class TransactionConversionService {
  findRelatedTransaction(transactions: Transaction[]) {
    if (transactions.length === 0) {
      return;
    }

    let i = 0;
    while (i < transactions.length) {
      /** Define the key of the current and next transaction */
      const currKey = i;
      const nextKey = i + 1;

      /** Get the current and next transaction */
      const currentTrans = transactions[currKey];
      const nextTrans = transactions[nextKey];

      /** Next transaction must be available to check if there are linked transactions
          and transaction must be on the same date */
      if (
        nextTrans &&
        this.isSameDateTransaction(
          currentTrans.transactionDate.date,
          nextTrans.transactionDate.date
        )
      ) {
        this.linkRelatedTransactions(currentTrans, nextTrans);
        i = nextKey + 1;
      } else {
        i++;
      }
    }

    return transactions;
  }

  /** If the transactions are related then store the id of each transaction to linkedTo property */
  private linkRelatedTransactions(currentTrans: Transaction, nextTrans: Transaction) {
    const currentSymbol = currentTrans.quantity.actualQuantity.symbol;
    const nextSymbol = nextTrans.quantity.actualQuantity.symbol;

    if (
      this.isSameBankAccount(currentTrans.accountId, nextTrans.accountId) &&
      this.isDifferentSymbol(currentSymbol, nextSymbol)
    ) {
      currentTrans.linkedTo.push(nextTrans.id);
      nextTrans.linkedTo.push(currentTrans.id);
    }
  }

  /** The comparing account of 2 transactions must be the same */
  private isSameBankAccount(currentAcct: string, nextAcct: string) {
    return currentAcct === nextAcct;
  }

  /** The comparing symbol of 2 transactions must be different */
  private isDifferentSymbol(currentSymbol: string, nextSymbol: string) {
    return currentSymbol !== nextSymbol;
  }

  /** The transaction dates must be the same */
  private isSameDateTransaction(currentDate: Date, nextDate: Date) {
    return currentDate === nextDate;
  }
}
