import { StringToNumberPreference } from "../../../models/types/PrefereneceTypes";

//TODO keys should be i18n versioned
export const daysOfWeek = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const tempWeekDays: StringToNumberPreference = {};
daysOfWeek.forEach((day, index) => {
  tempWeekDays[day] = index;
});
export const weekDays: StringToNumberPreference = tempWeekDays;
