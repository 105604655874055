<app-spinner [showSpinner]="loading"></app-spinner>
<div class="header">
  <h1 mat-dialog-title class="title">
    {{ title }}
  </h1>
  <button mat-icon-button color="primary" mat-dialog-close aria-label="Close dialog" tabindex="-1">
    <mat-icon>close</mat-icon>
  </button>
</div>
<mat-dialog-content>
  <form class="modal-content" [formGroup]="form" (ngSubmit)="submit()">
    <div class="modal-body">
      <div class="date-time flex-input">
        <mat-form-field appearance="outline" class="form-group mb-4 date-picker">
          <mat-label for="date">{{ "date" | i18n }}</mat-label>
          <input
            matInput
            [matDatepicker]="refDataDatePicker"
            formControlName="date"
            (click)="refDataDatePicker.open()"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="refDataDatePicker"
            id="datepicker-toggle-icon"
          ></mat-datepicker-toggle>
          <mat-datepicker #refDataDatePicker></mat-datepicker>
        </mat-form-field>
        <mat-form-field appearance="outline" class="form-group mb-4">
          <mat-label for="time">{{ "time" | i18n }}</mat-label>
          <input matInput type="time" formControlName="time" name="time" autocomplete="off" />
        </mat-form-field>
        <mat-form-field appearance="outline" class="form-group mb-4">
          <mat-label for="timeZone">Select Time Zone:</mat-label>
          <input
            matInput
            id="timeZone"
            formControlName="timeZone"
            placeholder="Pick a time-zone"
            [matAutocomplete]="autoTZ"
          />
          <mat-autocomplete autoActiveFirstOption #autoTZ="matAutocomplete">
            <mat-option [value]="null">--</mat-option>
            <mat-option *ngFor="let timeZone of timeZones" [value]="timeZone">
              {{ timeZone }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
      <div class="conversion-rate flex-input">
        <mat-form-field appearance="outline" class="form-group mb-4">
          <mat-label for="base">Base</mat-label>
          <input matInput type="text" formControlName="baseName" name="base" autocomplete="off" />
        </mat-form-field>
        <div class="equals-sign">
          <mat-icon class="equals-sign">drag_handle</mat-icon>
        </div>
        <mat-form-field appearance="outline" class="form-group mb-4">
          <mat-label for="rate">Rate</mat-label>
          <input matInput type="number" name="rate" placeholder="0.0" formControlName="rate" />
        </mat-form-field>
        <mat-form-field appearance="outline" class="form-group mb-4">
          <mat-label for="symbol">Currency</mat-label>
          <input
            matInput
            id="baseCurrency"
            formControlName="preferredCurrency"
            placeholder="Pick a currency"
            aria-label="Currency"
            name="symbol"
            autocomplete="off"
          />
        </mat-form-field>
      </div>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <div>
    <button
      mat-mini-fab
      #deleteBtn
      type="button"
      color="warn"
      appA11yTitle="{{ 'delete' | i18n }}"
      *ngIf="editMode"
    >
      <mat-icon>delete</mat-icon>
    </button>
  </div>
  <div>
    <button mat-raised-button mat-dialog-close>{{ "cancel" | i18n }}</button>
    <button mat-raised-button color="primary" type="submit" (click)="submit()">
      {{ "save" | i18n }}
    </button>
  </div>
</mat-dialog-actions>
