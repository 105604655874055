// import { CipherView } from "../view/cipher.view";
// import { CollectionView } from "../view/collection.view";
// import { FolderView } from "../view/folder.view";
import { Transaction } from "../../../../../apps/ironfly-web/src/app/models/data/blobby/transaction.data";
import { DateFormatIndex } from "../../../../../apps/ironfly-web/src/app/models/types/general-types";

export class ImportTransactionResult {
  success = false;
  errorMessage: string;
  transactions: Transaction[] = [];
  noCurrencyTransactions: Transaction[] = [];
  noSymbolTransactions: Transaction[] = [];
  categories: string[];
  classifications: string[];
  dateFormatIndex: DateFormatIndex;
}
