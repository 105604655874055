import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "app-splitclassification",
  templateUrl: "./splitclassification.component.html",
})
export class SplitclassificationComponent {
  @Input() splitName: string;
  @Input() inputName: string;
  @Input() inputValue: number;
  @Input() componentIndex: number;
  @Input() isDisabled: boolean;
  @Output() inputChangeEvent: EventEmitter<number> = new EventEmitter<number>();
  @Output() removeComponentEvent: EventEmitter<any> = new EventEmitter<number>();

  changeEvent(value: number) {
    const inputVal = typeof value === "string" ? parseInt(value) : value;
    this.inputChangeEvent.emit(inputVal);
  }

  remove(componentIndex: number) {
    this.removeComponentEvent.emit(componentIndex);
  }
}
