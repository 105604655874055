import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Subject, takeUntil } from "rxjs";

import "./scenario-difference-message.component.style.scss";
import { classNamesFor } from "@bitwarden/web-vault/app/components/scenario-difference-message/classes.selector.tailwind";
import { GlossDate } from "@bitwarden/web-vault/app/models/data/shared/gloss-date";
import { ScenarioData } from "@bitwarden/web-vault/app/models/types/scenario-group.types";
import { SideMenuService } from "@bitwarden/web-vault/app/services/menu/side-menu.service";
import { MainProgressBar } from "@bitwarden/web-vault/app/services/progress-bar/main-progress-bar";

import { WizardService } from "../../components/account-wizard-stepper/wizard-stepper-service";

type ScenarioDiffBlock = {
  textOne: string;
  actionButton?: string;
  numberToShow: string;
  textTwo: string;
};
type ScenarioDifferenceMessage = {
  headerText: string;
  headerIcon: "check" | "info";
  buttonText: string;
  firstBlock: ScenarioDiffBlock;
  secondBlock: ScenarioDiffBlock;
  thirdBlock: ScenarioDiffBlock;
};

@Component({
  selector: "app-scenario-difference-message",
  templateUrl: "./scenario-difference-message.component.html",
  styles: ["scenario-difference-message.component.scss"],
})
export class ScenarioDifferenceMessageComponent implements OnInit, OnDestroy {
  scenarioDifferenceMessage: ScenarioDifferenceMessage = null;
  helpOpen = false;
  isNotSyncedAccount: boolean;
  private destroy$ = new Subject<void>();

  constructor(
    public dialogRef: MatDialogRef<ScenarioDifferenceMessageComponent>,
    private wizardService: WizardService,
    private glossMenuService: SideMenuService,
    private mainProgressBar: MainProgressBar,
    @Inject(MAT_DIALOG_DATA) public data: { scenarioData: ScenarioData; baseCurrency: string } // The data passed to the dialog
  ) {}

  ngOnInit(): void {
    this.setScenarioDifferenceMessage();
    this.wizardService
      .isSyncedAccount()
      .pipe(takeUntil(this.destroy$))
      .subscribe((isSyncedAccount) => {
        this.isNotSyncedAccount = !isSyncedAccount;
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  setScenarioDifferenceMessage() {
    const bestExistingGroupBalance = this.data.scenarioData.scenario.filter(
      (scenario) => scenario.scenarioType === "bestNewInterestRate"
    )[0].groupedBalance;
    const currentGroupBalance = this.data.scenarioData.scenario.filter(
      (scenario) => scenario.scenarioType === "currentInterestRate"
    )[0].groupedBalance;

    const monthGranularityKeys = Object.keys(bestExistingGroupBalance.granularity["month"]);
    const lastMonth = monthGranularityKeys[monthGranularityKeys.length - 1];
    const bestExistingBalance =
      bestExistingGroupBalance?.granularity["month"][lastMonth]?.balance?.runningTotalValue[
        this.data.baseCurrency
      ]?.symbolAmount;
    const currentBalance =
      currentGroupBalance?.granularity["month"][lastMonth]?.balance?.runningTotalValue[
        this.data.baseCurrency
      ]?.symbolAmount;
    const isMaximized =
      bestExistingBalance?.amount <= currentBalance?.amount && currentBalance.amount != 0;

    const endDate = new GlossDate().setToDateObj(lastMonth);
    const today = new GlossDate().setToDateObj(new Date().toISOString());
    const monthDif = endDate.getMonthDifference(today);

    if (!currentBalance || currentBalance?.amount <= 0) {
      const overdraftMessage =
        "The Gloss Vault does not yet have the necessary information to perform calculations on overdrawn accounts";
      const currentBalanceMessage = "Your account balance is currently";
      const isZeroBalance = currentBalance?.amount === 0;
      const addPositiveBalanceMessage = `Add a positive balance to your account for more insight into the interest you will earn in the next ${monthDif} months`;

      this.scenarioDifferenceMessage = {
        headerText: "Oh no",
        headerIcon: "info",
        buttonText: "Continue",
        firstBlock: {
          textOne: isZeroBalance ? currentBalanceMessage : overdraftMessage,
          numberToShow: isZeroBalance ? "0.00" : null,
          textTwo: null,
          actionButton: !isZeroBalance ? "Contact us" : null,
        },
        secondBlock: {
          textOne: null,
          numberToShow: null,
          textTwo: null,
        },
        thirdBlock: {
          textOne: isZeroBalance ? addPositiveBalanceMessage : null,
          numberToShow: null,
          textTwo: null,
        },
      };
      return;
    }

    if (isMaximized) {
      this.scenarioDifferenceMessage = {
        headerText: "Well Done",
        headerIcon: "check",
        buttonText: "Find out how",
        firstBlock: {
          textOne: "You are already maximizing your savings interest",
          numberToShow: null,
          textTwo: null,
        },
        secondBlock: {
          textOne: "On your",
          numberToShow: this.data.scenarioData.anchorPoint.anchorBalance.toFixed(2).toString(),
          textTwo: "balance",
        },
        thirdBlock: {
          textOne: "You will earn",
          numberToShow: (currentBalance.amount - this.data.scenarioData.anchorPoint.anchorBalance)
            .toFixed(2)
            .toString(),
          textTwo: `in the next ${monthDif} months`,
        },
      };
    } else {
      this.scenarioDifferenceMessage = {
        headerText: "Well Done",
        headerIcon: "check",
        buttonText: "Find out how",
        firstBlock: {
          textOne: "You can earn",
          numberToShow: (
            bestExistingBalance.amount - this.data.scenarioData.anchorPoint.anchorBalance
          )
            .toFixed(2)
            .toString(),
          textTwo: `in the next ${monthDif} months`,
        },
        secondBlock: {
          textOne: "On your",
          numberToShow: this.data.scenarioData.anchorPoint.anchorBalance.toFixed(2).toString(),
          textTwo: "balance",
        },
        thirdBlock: {
          textOne: "This is",
          numberToShow: (bestExistingBalance.amount - currentBalance.amount).toFixed(2).toString(),
          textTwo: "more than what you currently earn",
        },
      };
    }
  }

  notifyOurTeam() {
    this.helpOpen = true;
  }

  handleHelpBoxOpen($event: boolean) {
    this.helpOpen = $event;
  }

  balanceEdit = () => {
    this.dialogRef.close("skip");
    this.wizardService.restartWizardInProgress();
    this.wizardService.setCurrentStep({ stepIndex: 3, isComplete: false });
    this.wizardService.setCurrentStep({ stepIndex: 2, isComplete: false });
    this.glossMenuService.setShouldRunWizard(true, true);
    this.wizardService.restartWizard();
  };
  protected readonly classNamesFor = classNamesFor;
}
