import { Injector } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

import { LogService } from "@bitwarden/common/abstractions/log.service";
import { BasiqAuth } from "@bitwarden/web-vault/app/importers/importer.auth.basiq";
import {
  BasiqConnection,
  BasiqJob,
  BasiqJobStep,
  GlossGetBasiqJobResponse,
} from "@bitwarden/web-vault/app/models/types/basiq.types";
import {
  AccountSyncStatus,
  StatusPoint,
} from "@bitwarden/web-vault/app/models/types/general-types";
import { InstitutionGroup } from "@bitwarden/web-vault/app/models/types/institution.type";
import { SyncStatusPoints } from "@bitwarden/web-vault/app/services/api/basiq/fixture/sync-status-messages";

import { AccountSyncService } from "./account.sync.service";

const DELAY_FOR_JOB_CHECKS = 5000;

export class BasiqInstitutionConnector {
  id: string;
  private _bsSyncStatus = new BehaviorSubject<AccountSyncStatus[]>(null);
  syncStatus$ = this._bsSyncStatus.asObservable();
  institutionGroup: InstitutionGroup;
  accountStatusListeners: Observable<AccountSyncService>[] = [];
  accountSyncServices: AccountSyncService[] = [];
  isCompleted = false;
  point: StatusPoint = {
    key: "started-syncing",
    icon: "sync",
    data: null,
  };
  private basiqAuth: BasiqAuth;
  private logService: LogService;
  institutionConnection: BasiqConnection;

  constructor(institutionGroup: InstitutionGroup, private injector: Injector) {
    this.institutionGroup = institutionGroup;
    this.basiqAuth = this.injector.get(BasiqAuth);
    this.id = institutionGroup.institutionId;
  }

  private alignAccountStatuses() {
    this.accountSyncServices = this.accountSyncServices.map((accountStatus) => {
      accountStatus.point = this.point;
      accountStatus.isCompleted = this.isCompleted;
      return accountStatus;
    });
  }

  async isValid(): Promise<boolean> {
    const basiqConnection = await this.basiqAuth.getConnection(
      this.institutionGroup.accountViews[0]
    );
    if (basiqConnection) {
      this.institutionConnection = basiqConnection;
    }

    return !!basiqConnection;
  }

  async startSync() {
    this.accountSyncServices = this.institutionGroup.accountViews.map(
      (accountView) => new AccountSyncService(accountView, this.injector)
    );

    // if connection exists, refresh the connection. if refresh fails finalize the sync as failed
    const glossConnectionRefreshResponse = await this.basiqAuth.refreshConnection(
      this.institutionConnection
    );
    if (!glossConnectionRefreshResponse?.job) {
      this.isCompleted = true;
      this.point = SyncStatusPoints["connection-refresh-failure"];
      this.alignAccountStatuses();
      this._bsSyncStatus.next(this.getAccountStatuses());
      await this.updateAccountSync();
      return;
    }

    await this.checkJob(glossConnectionRefreshResponse.job);
  }

  async checkJob(job: BasiqJob) {
    const basiqJobResponse: GlossGetBasiqJobResponse = await this.basiqAuth.getBasiqJob(job.id);
    if (this.isJobFailed(basiqJobResponse.job)) {
      await this.updateSyncStatusWithJobFailure();
      return;
    }

    if (this.isJobSucceeded(basiqJobResponse.job)) {
      await this.updateSyncStatusWithJobSuccess();
      return;
    }

    //TODO -  add a number of calls so when exceeded finalize the sync as failed
    this.basiqAuth.delay(DELAY_FOR_JOB_CHECKS).then(() => {
      this.checkJob(basiqJobResponse.job);
    });
  }

  private async updateSyncStatusWithJobFailure() {
    this.isCompleted = true;
    this.point = SyncStatusPoints["job-fetch-fail"];
    this.alignAccountStatuses();
    this._bsSyncStatus.next(this.getAccountStatuses());
    await this.updateAccountSync();
    return;
  }

  private async updateSyncStatusWithJobSuccess() {
    this.isCompleted = true;
    this.point = SyncStatusPoints["job-fetch-success"];
    await this.updateAccountSync();
    this.alignAccountStatuses();
    this._bsSyncStatus.next(this.getAccountStatuses());
    return;
  }

  async updateAccountSync() {
    for (const accountSyncStatus of this.accountSyncServices) {
      await accountSyncStatus.startSync();
    }
  }

  private isJobFailed(job: BasiqJob): boolean {
    return job.steps.some((step: BasiqJobStep) => step.status === "failed");
  }

  private isJobSucceeded(job: BasiqJob): boolean {
    return job.steps.every((step: BasiqJobStep) => step.status === "success");
  }

  getAccountStatuses(): AccountSyncStatus[] {
    return this.accountSyncServices.map((accountStatus) => accountStatus.getAccountStatus());
  }
}
