import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from "@angular/core";

import { StateService } from "@bitwarden/common/abstractions/state.service";

import { RoleScope } from "../../../../apps/ironfly-web/src/app/models/enum/role-access.enum";
import { RoleAccessService } from "../../../../apps/ironfly-web/src/app/services/permission/role-access.service";

@Directive({
  selector: "[appRoleScope]",
})
export class RoleScopeDirective implements OnInit {
  private roleScope: string;

  @Input() set appRoleScope(scope: string) {
    this.roleScope = scope;
  }

  constructor(
    private roleAccessService: RoleAccessService,
    private stateService: StateService,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef
  ) {}

  async ngOnInit(): Promise<void> {
    const roleScope = await this.stateService.getRoleScope();
    const roleClaim = await this.stateService.getRoleClaim();
    this.roleAccessService.setRoleScope(roleScope);
    this.roleAccessService.setRoleClaim(roleClaim);

    if (this.hasScopeAccess() || this.hasBetaAccess()) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }

  private hasScopeAccess() {
    const scope = this.roleAccessService.getRoleAccess().getScope();
    return scope.includes(this.roleScope);
  }

  private hasBetaAccess() {
    const scope = this.roleAccessService.getRoleAccess().getScope();
    return scope.includes(RoleScope.BETA_ACCESS);
  }
}
