import { Injectable } from "@angular/core";
import { ComponentStore } from "@ngrx/component-store";
import { Observable } from "rxjs";

import { BasiqType } from "../../gloss/import/import-type/basiq.import-type";
import { CsvType } from "../../gloss/import/import-type/csv.import-type";
import { PlaidType } from "../../gloss/import/import-type/plaid.import-type";
import { TransactionImportType } from "../../models/enum/transactionImportType";
import { ImportType } from "../../models/types/import.types";
export interface ImportTypeInterface {
  importType: ImportType;
}

@Injectable()
export class ImportTypeStore extends ComponentStore<ImportTypeInterface> {
  constructor() {
    super({
      importType: null,
    });
  }

  readonly importType$: Observable<ImportType> = this.select((state) => state.importType);

  private getImportType(type: TransactionImportType) {
    if (type === TransactionImportType.plaid) {
      return new PlaidType();
    }
    if (type === TransactionImportType.basiq) {
      return new BasiqType();
    }

    if (type === TransactionImportType.transactioncsv) {
      return new CsvType();
    }
  }

  readonly setImportType = this.updater((state, importTypeName: TransactionImportType) => {
    return {
      ...state,
      importType: this.getImportType(importTypeName),
    };
  });
}
