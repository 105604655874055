import { Component, Inject, Injector, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

import {
  balanceInputOptions,
  actionButtonOptions,
} from "@bitwarden/web-vault/app/gloss/settings/manage-accounts/accounts-add-edit/component.options";
import { PreferenceType } from "@bitwarden/web-vault/app/models/enum/preferenceType";
import { UserLocationCurrencyEnum } from "@bitwarden/web-vault/app/models/enum/user-location.enum";
import { IButton } from "@bitwarden/web-vault/app/models/interfaces/gloss-button.interface";
import { BalanceForm } from "@bitwarden/web-vault/app/models/types/account.types";
import { GlossInputOptions } from "@bitwarden/web-vault/app/models/types/general-types";
import { AccountView } from "@bitwarden/web-vault/app/models/view/account.view";
import { PreferenceService } from "@bitwarden/web-vault/app/services/DataService/preference/preference.service";
import { BookBalance } from "@bitwarden/web-vault/app/shared/utils/helper.book/balance";
import DateFormat from "@bitwarden/web-vault/app/shared/utils/helper.date/date-format";
import { HelperNumericInput } from "@bitwarden/web-vault/app/shared/utils/helper.numeric-input";

@Component({
  selector: "app-account-balance",
  templateUrl: "./account-balance.component.html",
})
export class AccountBalanceComponent implements OnInit {
  form: FormGroup;
  balanceInputOptions: GlossInputOptions = balanceInputOptions;
  saveButtonOptions: IButton = actionButtonOptions;
  maxDate = new Date();
  loading = false;
  currency: string;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      accountView: AccountView;
      closeDialogue: CallableFunction;
    },
    private formBuilder: FormBuilder,
    private dateFormat: DateFormat,
    private injector: Injector,
    private preferenceService: PreferenceService
  ) {
    Promise.resolve(this.initializeForm());
  }

  ngOnInit() {
    this.balanceInputOptions = {
      ...this.balanceInputOptions,
      onInput: (value: string) => this.onInput(value),
      inputBlurred: () => this.clearDefaultValue(),
    };

    this.saveButtonOptions = {
      ...this.saveButtonOptions,
      onClick: this.submit.bind(this),
    };
  }

  private async initializeForm() {
    const { AU, HK } = UserLocationCurrencyEnum;
    const userLocation = await this.preferenceService.get(PreferenceType.userLocation);
    this.currency = userLocation === "AU" ? AU : HK;

    this.balanceInputOptions = {
      ...this.balanceInputOptions,
      suffix: this.currency,
    };

    this.form = this.formBuilder.group({
      currency: [this.currency, Validators.required],
      balance: [null, [HelperNumericInput.isNumberValidator, Validators.required]],
      date: this.dateFormat.getDateStringFromStamp(new Date().getTime()),
    });
  }

  currencySelected(selectedCurrency: string) {
    this.form.get("currency")?.setValue(selectedCurrency);
    this.balanceInputOptions.suffix = selectedCurrency;
  }

  async submit() {
    this.loading = true;
    const balanceForm: BalanceForm = this.form.value;
    const balance = new BookBalance(this.data.accountView, balanceForm, this.injector);
    const result = await balance.process();

    if (result) {
      this.data.closeDialogue();
      this.loading = false;
    } else {
      this.saveButtonOptions.enableButton(true);
      this.loading = false;
    }
  }

  onInput(event: any) {
    HelperNumericInput.onInput(event, this.form.controls.balance);
    this.form.get("balance")?.setValue(event.target.value);
  }

  clearDefaultValue() {
    HelperNumericInput.clearDefaultValue(this.form.controls.balance);
  }

  getErrorMessage() {
    if (this.form.controls.balance.hasError("required")) {
      return "Field is required";
    }

    return this.form.controls.balance.hasError("notANumber") ? "Please enter a valid number" : "";
  }

  onEndDateChangeEvent(event: any) {
    const localDate = new Date(event);
    const timezoneOffsetMinutes = localDate.getTimezoneOffset();
    const dateInUTC = new Date(localDate.getTime() - timezoneOffsetMinutes * 60 * 1000);
    const isoString = dateInUTC.toISOString();
    const date = isoString.split("T")[0];
    this.form.get("date")?.setValue(date);
  }
}
