// async-cell-renderer.component.ts
import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";

import { PreferenceService } from "../../../services/DataService/preference/preference.service";

@Component({
  selector: "app-check-box-cell-renderer",
  templateUrl: "./check-box-cell-renderer.component.html",
})
export class CheckBoxCellRenderer implements ICellRendererAngularComp {
  amount: number;
  baseCurrency: string;
  params: any;
  checked: boolean;
  constructor(private preferenceService: PreferenceService) {}

  agInit(params: any): void {
    this.params = params;
    this.checked = params.value;
  }

  handleChange(): void {
    this.checked = !this.checked;
    this.params.context.componentParent.checkToggle(this.params.node.data, this.checked);
  }

  refresh(params: any): boolean {
    return false; // No need to refresh since data fetch is already handled
  }
}
