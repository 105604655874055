import { Component, Input, OnInit, OnChanges, SimpleChanges } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";

import { GlossInputOptions } from "@bitwarden/web-vault/app/models/types/general-types";

import "./gloss-input.component.styles.scss";

@Component({
  selector: "app-gloss-input",
  templateUrl: "./gloss-input.component.html",
  styles: ["gloss-input.component.styles.scss"],
})
export class GlossInputComponent implements OnInit, OnChanges {
  @Input() options: GlossInputOptions;

  inputValueControl: FormControl;

  ngOnInit(): void {
    this.initializeFormControl();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.options) {
      this.initializeFormControl();
    }
  }

  private initializeFormControl(): void {
    const { isRequired, isDisabled, inputBlurred, inputCleared, inputError, value, onInput } =
      this.options;
    this.inputValueControl = new FormControl(
      { value: "", disabled: isDisabled },
      isRequired ? Validators.required : null
    );

    this.inputBlurred = inputBlurred;
    this.inputCleared = inputCleared;
    this.inputError = inputError;
    this.onInput = onInput;
    if (value) {
      this.inputValueControl.setValue(value);
    }
  }

  get inputValue(): string {
    return this.inputValueControl.value;
  }

  onInputBlur(): void {
    if (this.options.isRequired && !this.inputValue && this.inputError) {
      this.inputError("Input is required.");
    } else {
      this.inputBlurred(this.inputValue);
    }
  }

  onInput(value: any): void {
    // This function will be replaced by the one from options
  }

  clearInput(): void {
    this.inputValueControl.setValue("");
    this.inputCleared();
  }

  inputBlurred(value: string): void {
    // This function will be replaced by the one from options
  }

  inputCleared(): void {
    // This function will be replaced by the one from options
  }

  inputError(message: string): void {
    // This function will be replaced by the one from options
  }
}
