export class AppState {
  private _isInitialized = false;

  getIsInitialized(): boolean {
    return this._isInitialized;
  }

  setIsInitialized(value: boolean) {
    this._isInitialized = value;
  }
}
