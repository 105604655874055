/*
primary-summary-graph-service - contains the functions that will be used to create the primary summary graph
 */

import { Injectable } from "@angular/core";
import { format, add, subDays } from "date-fns";

import { GlossBalance } from "@bitwarden/web-vault/app/models/data/shared/gloss-balance";
import { GlossNumber } from "@bitwarden/web-vault/app/models/data/shared/gloss-number";
import { TransactionDirection } from "@bitwarden/web-vault/app/models/enum/transactionDirection";
import { GranularityProperty } from "@bitwarden/web-vault/app/models/types/balanceGroupingTypes";
import { GraphDataSet, FilterParameters } from "@bitwarden/web-vault/app/models/types/graph.types";
import { BalanceGrouping } from "@bitwarden/web-vault/app/services/DataCalculationService/balanceGrouping/balanceGrouping";
import { TransactionCalculationService } from "@bitwarden/web-vault/app/services/DataCalculationService/transaction/transaction.calculation.service";

@Injectable({
  providedIn: "root",
})
export class PrimarySummaryGraphService {
  constructor(private transactionCalculationService: TransactionCalculationService) {}

  async formatDataSets(
    transactionGrouping: BalanceGrouping,
    granularity: GranularityProperty,
    startDate: string,
    endDate: string,
    transactionCalculationService: TransactionCalculationService,
    filterParameters: FilterParameters,
    blankDefault = 0
  ): Promise<Array<GraphDataSet>> {
    if (!granularity || !transactionGrouping || !startDate || !endDate) {
      return [];
    }

    // default the start date to go back a granularity so we can plot from the start date
    const prevStartDate = transactionGrouping.getPreviousGroupingDate(
      new Date(startDate),
      granularity
    );

    const formattedDataSet: Array<GraphDataSet> = [];
    const fullSetOfGroupingKeys = transactionGrouping.getGranularityGroupingKeys(
      new Date(prevStartDate),
      new Date(endDate),
      granularity
    );

    let prevBalance = blankDefault;
    // if the start date is later than then last date of the balance grouping, then use the date end as the prevBalance
    if (transactionGrouping.dateEnd && new Date(startDate) > transactionGrouping.dateEnd) {
      const dateEndGroupingKey = transactionGrouping.getDateGroupingKey(
        transactionGrouping.dateEnd,
        granularity
      );
      prevBalance = this.getNormalizedBalance(
        granularity,
        dateEndGroupingKey,
        transactionGrouping,
        transactionCalculationService,
        filterParameters
      );
    }

    let startInMiddle = false;
    let endInMiddle = false;
    let middleStartDate = null;
    let middleEndDate = null;

    for (const groupingKey in fullSetOfGroupingKeys) {
      let middleBalanceDate = null;
      const granularityInfo = fullSetOfGroupingKeys[groupingKey];
      const dataSet = this.createBlankDataSet(blankDefault);
      dataSet.date = format(granularityInfo.startDate, "yyyyMMdd");
      dataSet.endDate = format(granularityInfo.endDate, "yyyyMMdd");
      dataSet.nextStartDate = format(this.getNextDay(granularityInfo.endDate), "yyyyMMdd");
      dataSet.prevBalance = prevBalance;
      dataSet.accountGrouping =
        this.transactionCalculationService.getBalanceGroupingByAccountsOnDate(
          transactionGrouping,
          granularityInfo.endDate,
          granularity
        );

      if (
        granularityInfo.endDate.getTime() < new Date(new Date(startDate).toDateString()).getTime()
      ) {
        middleStartDate = new Date(new Date(startDate).toDateString());
        dataSet.midDate = middleStartDate;
        middleBalanceDate = subDays(middleStartDate, 1);
        startInMiddle = true;
      }

      if (
        granularityInfo.endDate.getTime() > new Date(new Date(endDate).toDateString()).getTime()
      ) {
        middleEndDate = new Date(new Date(endDate).toDateString());
        dataSet.midDate = middleEndDate;
        middleBalanceDate = middleEndDate;
        endInMiddle = true;
      }

      // if the start date of this set is before the filtered start date
      // or the enddate is after the filtered end date
      if (middleBalanceDate) {
        // because we want the OPENING balance and not the closing, we need to get the closing from the day before
        dataSet.balance = transactionCalculationService.getNormalizedBalanceByDate(
          transactionGrouping,
          middleBalanceDate
        );
        if (!dataSet.balance) {
          dataSet.balance = blankDefault;
        }
        if (endInMiddle) {
          const intervalBalance = transactionCalculationService.sumBalances(
            transactionGrouping,
            granularityInfo.startDate,
            middleBalanceDate
          );

          const normalizedBalanceIn = new GlossNumber();
          const normalizedBalanceOut = new GlossNumber();

          GlossBalance.addNormalizedFromPair(normalizedBalanceIn, intervalBalance.in);
          GlossBalance.addNormalizedFromPair(normalizedBalanceOut, intervalBalance.out);

          dataSet.in = Math.abs(normalizedBalanceIn.amount);
          dataSet.out = Math.abs(normalizedBalanceOut.amount);
        } else {
          dataSet.in = blankDefault;
          dataSet.out = blankDefault;
        }
        if (dataSet.balance) {
          prevBalance = dataSet.balance;
        }
        startInMiddle = true;
      } else if (transactionGrouping.granularity[granularity][groupingKey]) {
        const glossBalance = transactionGrouping.granularity[granularity][groupingKey].balance;
        if (glossBalance instanceof GlossBalance) {
          const balance = this.getNormalizedBalance(
            granularity,
            groupingKey,
            transactionGrouping,
            transactionCalculationService,
            filterParameters
          );
          dataSet.balance = balance;
          if (balance) {
            prevBalance = balance;
          }
        }
        const directionAmounts = this.getDirectionAmounts(
          granularity,
          groupingKey,
          transactionGrouping,
          transactionCalculationService,
          filterParameters
        );

        if (startInMiddle) {
          startInMiddle = false;
          // calculate the ins and outs from the middle out to the end of the data set using the day granularity
          const intervalBalance = transactionCalculationService.sumBalances(
            transactionGrouping,
            middleStartDate,
            granularityInfo.endDate
          );

          const normalizedBalanceIn = new GlossNumber();
          const normalizedBalanceOut = new GlossNumber();

          GlossBalance.addNormalizedFromPair(normalizedBalanceIn, intervalBalance.in);
          GlossBalance.addNormalizedFromPair(normalizedBalanceOut, intervalBalance.out);

          dataSet.in = Math.abs(normalizedBalanceIn.amount);
          dataSet.out = Math.abs(normalizedBalanceOut.amount);
        } else {
          dataSet.in = Math.abs(directionAmounts[TransactionDirection.In]);
          dataSet.out = Math.abs(directionAmounts[TransactionDirection.Out]);
        }
      } else if (formattedDataSet.length > 0) {
        startInMiddle = false;
        /* There is no granularity on that day, lets cary over the previous balance. */
        dataSet.balance = dataSet.prevBalance;
        dataSet.in = blankDefault;
        dataSet.out = blankDefault;
      } else {
        startInMiddle = false;
        dataSet.balance = prevBalance;
        dataSet.in = blankDefault;
        dataSet.out = blankDefault;
      }

      formattedDataSet.push(dataSet);
    }
    return formattedDataSet;
  }

  private getNormalizedBalance(
    granularity: GranularityProperty,
    groupingKey: string,
    transactionGrouping: BalanceGrouping,
    transactionCalculationService: TransactionCalculationService,
    filterParameters: FilterParameters
  ): number {
    /*
    const accounts = this.filterByAccounts(filterParameters);
    const directions = this.filterByDirection(filterParameters);
    const categories = this.filterByCategories(filterParameters);
    const classifications = this.filterByClassifications(filterParameters);
    */

    const balance = transactionCalculationService.getFilteredNormalizedBalance(
      transactionGrouping,
      granularity,
      groupingKey,
      null,
      null,
      null,
      null
      //accounts,
      //directions,
      //categories,
      //classifications
    );

    return balance;
  }

  private getDirectionAmounts(
    granularity: GranularityProperty,
    groupingKey: string,
    transactionGrouping: BalanceGrouping,
    transactionCalculationService: TransactionCalculationService,
    filterParameters: FilterParameters
  ): Record<string, number> {
    // const granularityGroup = transactionGrouping.granularity[granularity][groupingKey]

    /*
    const accounts = this.filterByAccounts(filterParameters);
    const directions = this.filterByDirection(filterParameters);
    const categories = this.filterByCategories(filterParameters);
    const classifications = this.filterByClassifications(filterParameters);
     */

    const directionAmounts = transactionCalculationService.getFilteredDirection(
      transactionGrouping,
      granularity,
      groupingKey,
      null,
      null,
      null,
      null
      // accounts,
      // directions,
      // categories,
      // classifications
    );

    return directionAmounts;
  }

  createBlankDataSet(blankDefault: number): GraphDataSet {
    return {
      date: "",
      endDate: "",
      nextStartDate: "",
      in: blankDefault,
      out: blankDefault,
      balance: blankDefault,
      prevBalance: blankDefault,
      estimateIn: blankDefault,
      estimateOut: blankDefault,
      estimateBalance: blankDefault,
      estimatePrevBalance: blankDefault,
      lastTransactionDate: null,
      accountGrouping: {},
    };
  }

  getNextDay(date: Date) {
    return add(date, { days: 1 });
  }

  /**
   * If all the accounts are used, then we don't need to limit by account to get balance
   * @private
   */
  private filterByAccounts(filterParameters: FilterParameters) {
    if (!filterParameters.accounts) {
      return null;
    }
    if (filterParameters.accounts.length === filterParameters.allAccounts.length) {
      return null;
    }
    return filterParameters.accounts;
  }

  private filterByClassifications(filterParameters: FilterParameters) {
    if (!filterParameters.classifications) {
      return null;
    }
    if (
      filterParameters.classifications &&
      filterParameters.allClassifications &&
      filterParameters.classifications.length === filterParameters.allClassifications.length
    ) {
      return null;
    }
    return filterParameters.classifications;
  }

  private filterByCategories(filterParameters: FilterParameters) {
    if (!filterParameters.categories) {
      return null;
    }
    if (
      filterParameters.categories &&
      filterParameters.allCategories &&
      filterParameters.categories.length === filterParameters.allCategories.length
    ) {
      return null;
    }
    return filterParameters.categories;
  }

  private filterByDirection(filterParameters: FilterParameters) {
    if (!filterParameters.directions) {
      return null;
    }
    if (
      filterParameters.directions &&
      filterParameters.allDirections &&
      filterParameters.directions.length === filterParameters.allDirections.length
    ) {
      return null;
    }
    return filterParameters.directions;
  }
}
