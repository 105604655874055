<div *ngIf="loading">
  <i
    class="bwi bwi-spinner bwi-spin text-muted"
    title="{{ 'loading' | i18n }}"
    aria-hidden="true"
  ></i>
  <span class="sr-only">{{ "loading" | i18n }}</span>
</div>

<form
  *ngIf="profile && !loading"
  #form
  [appApiAction]="formPromise"
  ngNativeValidate
  class="tw-flex tw-w-fit tw-min-w-[270px] tw-max-w-[400px] tw-flex-col tw-gap-6 tw-bg-white md:tw-w-full md:tw-max-w-full md:tw-items-center md:tw-justify-between md:tw-bg-blend-hard-light"
>
  <div class="tw-mt-6 tw-w-full">
    <div class="tw-flex tw-w-full tw-flex-col tw-gap-6 md:tw-justify-center">
      <!--      avatar-->
      <div class="md:tw-flex md:tw-w-full md:tw-items-center md:tw-justify-center">
        <bit-avatar [text]="profile | userName" [id]="profile.id" size="large"></bit-avatar>
      </div>
      <!--      name-->
      <div class="tw-flex tw-flex-col">
        <label for="name" class="tw-pointer-events-none">{{ "name" | i18n }}</label>
        <input
          class="tw-h-12 tw-rounded-lg tw-border-0 tw-border-neutral-100 tw-bg-neutral-50 tw-pl-6 focus:tw-border-[1px] focus:tw-border-solid focus:tw-border-primary focus:tw-shadow-inner focus:tw-outline-none"
          id="name"
          type="text"
          name="Name"
          [(ngModel)]="profile.name"
        />
      </div>
      <!--      email-->
      <div class="tw-flex tw-flex-col">
        <label for="email" class="tw-pointer-events-none">{{ "email" | i18n }}</label>
        <input
          id="email"
          class="tw-h-12 tw-rounded-lg tw-border-[1px] tw-border-solid tw-border-neutral-100 tw-bg-neutral-50 tw-pl-6 read-only:!tw-border-0 read-only:!tw-border-neutral-100 read-only:!tw-bg-neutral-50 focus:tw-border-[1px] focus:tw-border-solid focus:tw-border-primary focus:tw-shadow-inner focus:tw-outline-none"
          type="text"
          name="Email"
          [(ngModel)]="profile.email"
          readonly
        />
      </div>
    </div>
  </div>

  <app-gloss-button
    class="tw-w-full"
    [options]="{
      text: 'save' | i18n,
      type: 'submit',
      class: 'primary',
      onClick: submit.bind(this),
      isEnabled: !form.loading
    }"
  ></app-gloss-button>
</form>
