import { Injector } from "@angular/core";

import { RevaluationService } from "@bitwarden/web-vault/app/services/DataCalculationService/revaluation/revaluation.service";
import { BalanceAlignmentService } from "@bitwarden/web-vault/app/services/DataCalculationService/transaction/balance-alignment.service";

import { Transaction } from "../../../models/data/blobby/transaction.data";
import { TransactionNormalizeService } from "../../DataCalculationService/transaction/transaction.normalize.service";

import { StateManagement } from "./state.management";

export class StateManagementService extends StateManagement {
  private balanceAlignmentService: BalanceAlignmentService;
  private normalizeService: TransactionNormalizeService;
  private revaluationService: RevaluationService;

  constructor(injector: Injector) {
    super();
    this.balanceAlignmentService = injector.get(BalanceAlignmentService);
    this.normalizeService = injector.get(TransactionNormalizeService);
    this.revaluationService = injector.get(RevaluationService);
  }

  /**
   * This function can not be called until we move to using NGRX as the only source of truth.
   * Calling this function clears the bram of blobby which has the effect of data not
   * being loaded when other services are trying to process information.
   *
   * @param transactions
   */
  async processRevaluations(transactions: Transaction[]) {
    /** Balance alignment */
    await this.balanceAlignmentService.realignAllExistingTransactions(transactions);

    /** Process market data and normalize transactions */
    // await this.normalizeService.processNormalizeImportedTransaction(transactions);

    /** Transactions Revaluations */
    return await this.revaluationService.generateRevaluations(transactions);
  }
}
