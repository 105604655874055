export abstract class DateUtil {
  static readonly MILISECONDS = 1000;

  static toMiliseconds(epochDate: number) {
    return epochDate * this.MILISECONDS;
  }

  /**
   * Check whether TIME is set properly if the Date
   * does not contain a default time - `T00:00:00Z`
   *
   * @param date
   */
  static isTimeSetFromDate(date: string) {
    return !!date.match("T00:00:00Z");
  }
}
