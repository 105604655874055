export type MembershipPlanType = {
  title: string;
  description: string;
  priceLabel: string;
  priceInCents: string;
  detail: string;
  billing: string;
  popular?: string;
};

export const MembershipPlan: Array<MembershipPlanType> = [
  {
    title: "Essentials",
    description: "All-in-one sales and support app*",
    priceLabel: "$25",
    priceInCents: "2500",
    detail: "USD/user/month**",
    billing: "(billed annually)",
  },
  {
    title: "Professional",
    description: "Complete CRM for any size team",
    priceLabel: "$75",
    priceInCents: "7500",
    detail: "USD/user/month**",
    billing: "(billed annually)",
  },
  {
    title: "Enterprise",
    description: "Deeply customizable sales CRM for your business",
    priceLabel: "$150",
    priceInCents: "15000",
    detail: "USD/user/month**",
    billing: "(billed annually)",
    popular: "MOST POPULAR",
  },
];

export const getPlanByTitle = (planTitle: string): Record<string, any> => {
  let item = null;

  Object.values(MembershipPlan).forEach((plan) => {
    if (plan.title === planTitle) {
      item = plan;
    }
  });

  return item;
};
