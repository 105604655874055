import { createFeatureSelector, createReducer, createSelector, on } from "@ngrx/store";

import { getErrorMessage } from "@bitwarden/web-vault/app/shared/utils/helper-state-management";

import { Subscription } from "../../../../models/data/subscription.data";

import { SubscriptionActions } from "./subscription.action";

export const subscriptionFeatureKey = "subscription-data";

export interface SubscriptionState {
  isLoading: boolean;
  isLoaded: boolean;
  subscription: Subscription;
  error: unknown;
}

const initialState: SubscriptionState = {
  isLoading: true,
  isLoaded: false,
  subscription: null,
  error: undefined,
};

const getSubscriptionFeatureState =
  createFeatureSelector<SubscriptionState>(subscriptionFeatureKey);

export const getIsLoading = createSelector(getSubscriptionFeatureState, (state) => state.isLoading);

export const getIsLoaded = createSelector(getSubscriptionFeatureState, (state) => state.isLoaded);

export const getSubscription = createSelector(
  getSubscriptionFeatureState,
  (state) => state.subscription
);

export const subscriptionReducer = createReducer<SubscriptionState>(
  initialState,
  on(SubscriptionActions.initialize, (state): SubscriptionState => {
    return state;
  }),
  on(SubscriptionActions.loadSuccess, (state, action): SubscriptionState => {
    return {
      ...state,
      isLoading: false,
      isLoaded: true,
      subscription: action.subscription,
    };
  }),
  on(SubscriptionActions.loadFailure, (state, action): SubscriptionState => {
    return {
      ...state,
      isLoading: false,
      isLoaded: true,
      subscription: undefined,
      error: getErrorMessage(action.error),
    };
  }),
  on(SubscriptionActions.clear, (state): SubscriptionState => {
    return {
      ...state,
      isLoading: false,
      isLoaded: true,
      subscription: undefined,
      error: undefined,
    };
  })
);
