import { Component, OnInit } from "@angular/core";

import { MainProgressBar } from "@bitwarden/web-vault/app/services/progress-bar/main-progress-bar";

@Component({
  selector: "app-coming-soon-page",
  templateUrl: "./coming-soon-page.component.html",
})
export class ComingSoonPageComponent implements OnInit {
  constructor(private mainProgressBar: MainProgressBar) {}

  ngOnInit() {
    this.mainProgressBar.setIsLoadingCompleted(true);
  }
}
