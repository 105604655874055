import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  HostListener,
  ViewChild,
  AfterViewInit,
} from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { Subject } from "rxjs";

import { LogService } from "@bitwarden/common/abstractions/log.service";
import { MessagingService } from "@bitwarden/common/abstractions/messaging.service";
import { CustomerSupportBoxComponent } from "@bitwarden/web-vault/app/components/customer-support-box/customer-support-box.component";
import { ManagePreferencesComponent } from "@bitwarden/web-vault/app/gloss/settings/manage-preferences/manage-preferences.component";
import { RoleScope } from "@bitwarden/web-vault/app/models/enum/role-access.enum";
import { scopeEnum } from "@bitwarden/web-vault/app/models/scope/typeDef";
import { BlobbyService } from "@bitwarden/web-vault/app/services/blobby/blobby.service";
import { RoleAccessService } from "@bitwarden/web-vault/app/services/permission/role-access.service";
import { AccountComponent } from "@bitwarden/web-vault/app/settings/account.component";
import { DangerZoneComponent } from "@bitwarden/web-vault/app/settings/danger-zone.component";
import { SecurityComponent } from "@bitwarden/web-vault/app/settings/security.component";
import { UserAccessPageComponent } from "@bitwarden/web-vault/app/settings/user-access-page.component";
import { HelperCommon } from "@bitwarden/web-vault/app/shared/utils/helper.common";

type NavigationSettingsType =
  | AccountComponent
  | SecurityComponent
  | DangerZoneComponent
  | ManagePreferencesComponent
  | UserAccessPageComponent;

@Component({
  selector: "app-settings-navigation",
  templateUrl: "settings-navigation.component.html",
})
export class SettingsNavigationComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild("theSetting") theSetting: ElementRef;
  @ViewChild("theContent") theContent: ElementRef;

  private currentView: "mobile" | "web";

  protected readonly RoleScope = RoleScope;
  private destroy$ = new Subject<void>();
  protected settingDisplay = true;
  protected contentDisplay = false;

  private isMdOrSmallerScreen: boolean;

  protected isPremiumUser: boolean;

  hasScope = false;
  earlyRequestDialogRef: MatDialogRef<CustomerSupportBoxComponent>;

  constructor(
    private router: Router,
    private helperCommon: HelperCommon,
    public dialog: MatDialog,
    private messagingService: MessagingService,
    private blobbyService: BlobbyService,
    private logService: LogService,
    private userAccessTier: RoleAccessService
  ) {}

  @HostListener("window:resize", ["$event"])
  onResize() {
    this.checkScreenSize();
  }

  async ngOnInit() {
    this.isPremiumUser = this.userAccessTier
      .getRoleAccess()
      .getScope()
      .includes(RoleScope.BETA_ACCESS);
    this.hasScope = await this.helperCommon.hasScope(scopeEnum.EARLY_ACCESS);
  }

  ngAfterViewInit() {
    this.checkScreenSize();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private checkScreenSize() {
    const width = window.innerWidth;
    const mdValue = 767; // tailwind config

    if (width <= mdValue) {
      this.currentView = "mobile";
    } else if (width > mdValue) {
      this.currentView = "web";
    }
  }

  protected handleNavClick(currentView: string, destination: string) {
    if (currentView == this.currentView) {
      this.redirectToContent(destination);
    }
  }

  closeEarlyRequestDialog() {
    this.earlyRequestDialogRef.close();
  }

  lock = () => {
    this.messagingService.send("lockVault");
  };

  logOut = () => {
    this.blobbyService.setInitialised(false);
    this.messagingService.send("logout");

    sessionStorage.removeItem("basiqUserAccessToken"); // So that if another user logs in, the previous user's access token is not used
  };

  protected redirectToContent(destination = "") {
    this.settingDisplay = !this.settingDisplay;
    this.contentDisplay = !this.contentDisplay;

    const route =
      destination === "access"
        ? [`/settings/${destination}`, { queryParams: { isPremiumUser: this.isPremiumUser } }]
        : [`/settings/${destination}`];

    this.router.navigate(route);
  }

  handleChildEvent(): void {
    this.redirectToContent();
  }

  onActivate(event: NavigationSettingsType): void {
    if (!this.isNavigationSettingsActive(event)) {
      this.logService.error(`Unhandled component type`);
    }
    event.toggleDisplay.subscribe(() => this.handleChildEvent());
  }

  private isNavigationSettingsActive(event: NavigationSettingsType): boolean {
    return (
      event instanceof AccountComponent ||
      event instanceof SecurityComponent ||
      event instanceof DangerZoneComponent ||
      event instanceof ManagePreferencesComponent ||
      event instanceof UserAccessPageComponent
    );
  }
}
