import { Injectable } from "@angular/core";

import { LogService } from "@bitwarden/common/abstractions/log.service";
import { GlobalService } from "@bitwarden/common/services/global/global.service";
import { ReferenceDataResponse } from "@bitwarden/web-vault/app/models/data/response/reference-data-response";
import { ReferenceDataCalculationClass } from "@bitwarden/web-vault/app/services/DataCalculationService/reference-data/reference-data-calculation.class";
import { DataRepositoryService } from "@bitwarden/web-vault/app/services/DataRepository/data-repository.service";

import { ReferenceData } from "../../../models/data/blobby/reference-data.data";
import { ReferenceDataService } from "../../DataService/reference-data/reference-data.service";
import { CalculationServiceAbstraction } from "../calculation.service.abstraction";

@Injectable({
  providedIn: "root",
})
export class ReferenceDataCalculationService implements CalculationServiceAbstraction {
  private referenceDataCalculation: ReferenceDataCalculationClass;

  constructor(
    private referenceDataService: ReferenceDataService,
    private logger: LogService,
    private dataRepositoryService: DataRepositoryService,
    private globalService: GlobalService
  ) {
    this.referenceDataCalculation = new ReferenceDataCalculationClass(
      this.logger,
      this.referenceDataService
    );
  }

  setAllReferenceData(data: ReferenceData[]) {
    this.referenceDataCalculation.setAllReferenceData(data);
  }

  triggerRefresh() {
    this.referenceDataCalculation.triggerRefresh();
  }

  /**
   * Given a symbol, base and date, retrieve the reference data for that record
   */
  async getReferenceData(
    symbol: string,
    base: string,
    startDate: number
  ): Promise<Array<ReferenceData>> {
    return await this.referenceDataCalculation.getReferenceData(symbol, base, startDate);
  }

  getSingleClosestCurrencyData(
    symbol: string,
    base: string,
    startDate: number,
    endDate?: number
  ): ReferenceData[] {
    return this.referenceDataCalculation.getSingleClosestCurrencyData(
      symbol,
      base,
      startDate,
      endDate
    );
  }

  /** Todo : Do not delete this method. This is a placeholder to retrieve currency data from Instrument API of Ironfly */
  async getCurrencyReferenceDataFromInstruments(
    symbols: string[],
    base: string,
    startDate: number,
    endDate: number
  ): Promise<Array<ReferenceData>> {
    const url = "instrumentCallApiUrl";
    const path = `/?base=${base}&symbols=${symbols.join(
      ","
    )}&startDate=${startDate}&endDate=${endDate}`;
    const instrumentMarketData = await this.dataRepositoryService.send(
      "GET",
      path,
      null,
      true,
      true,
      url
    );
    if (!instrumentMarketData.error) {
      if (instrumentMarketData.currencyData.length) {
        return instrumentMarketData.currencyData.map(
          (currency: object) => new ReferenceData(new ReferenceDataResponse(currency))
        );
      }
      return instrumentMarketData.currencyData;
    } else {
      this.globalService.showErrorMessage("errorOccurred", instrumentMarketData.error.message);
    }
  }

  async getCurrencyReferenceData(
    symbol: string,
    base: string,
    startDate: number,
    endDate?: number
  ): Promise<Array<ReferenceData>> {
    return await this.referenceDataCalculation.getCurrencyReferenceData(
      symbol,
      base,
      startDate,
      endDate
    );
  }

  /**
   * Given a symbol, base and date, retrieve the reference data for that record
   * between the startDate and the endDate.
   * Also fill in the days in between that we don't have reference data for
   */
  async getReferenceAfterDate(
    symbol: string,
    base: string,
    startDate: number,
    endDate?: number,
    baseCurrency = "USD"
  ): Promise<Array<ReferenceData>> {
    return await this.referenceDataCalculation.getReferenceAfterDate(
      symbol,
      base,
      startDate,
      endDate,
      baseCurrency
    );
  }
}
