import { Book } from "@bitwarden/web-vault/app/models/data/blobby/book.data";
import { SourceBook } from "@bitwarden/web-vault/app/models/data/blobby/source-book";
import { SourceTransaction } from "@bitwarden/web-vault/app/models/data/blobby/source-transaction.data";
import { Transaction } from "@bitwarden/web-vault/app/models/data/blobby/transaction.data";
import { UnrecognizedAccount } from "@bitwarden/web-vault/app/models/types/account.types";
import { BookService } from "@bitwarden/web-vault/app/services/DataService/book/book.service";
import { dateDiffWithoutTime } from "@bitwarden/web-vault/app/shared/utils/helper.date/date-comparison";
import { AccountValidator } from "@bitwarden/web-vault/app/validators/entity/account-validator";

export type potentialTransaction = Transaction | SourceTransaction;

export type validationResult = {
  currencies: Array<string>;
  duplicated: Array<potentialTransaction>;
  newRecord: Array<potentialTransaction>;
  preview: Array<potentialTransaction>;
  newAccounts: Array<UnrecognizedAccount>;
  noCurrencyTransactions: Array<Transaction>;
};
export abstract class BaseValidator {
  protected constructor(
    public accountValidator: AccountValidator,
    public bookService: BookService
  ) {}

  /**
   * This will validate if the SourceImport exist or not
   *
   * @param plaidTransactions
   * @param vaultTrans
   */
  async matchSourceImport(
    plaidTransactions: Array<potentialTransaction>,
    vaultTrans: Array<SourceTransaction>
  ) {
    return await this.validate(plaidTransactions, vaultTrans);
  }

  /** description maybe exactly the same or include one another. In this case it is a sign of duplicity  */
  async checkDescriptionDuplicity(
    existingTransaction: SourceTransaction,
    item: potentialTransaction
  ) {
    const normalizedEtDescription = existingTransaction.description
      .replace(/\s/g, "")
      .toLowerCase();
    const normalizedItemDescription = item.description.replace(/\s/g, "").toLowerCase();
    return (
      normalizedItemDescription === normalizedEtDescription ||
      normalizedEtDescription.includes(normalizedItemDescription) ||
      normalizedItemDescription.includes(normalizedEtDescription)
    );
  }

  /**
   * - if there are no transaction having the same date and quantity as this one then this is considered a new transaction
   * - if there are transactions having the same date and quantity then in order for this to be considered as a new trnasaction
   *  there
   * */
  async checkForDuplication(
    item: potentialTransaction,
    existingTransactions: SourceTransaction[]
  ): Promise<boolean> {
    const itemQuantity = item.quantity.actualQuantity.amount;
    /** if item.quantity is 0 than it is either a closing balance or openning balance than it should check other conditions
     * if dateAndQuantityCheck is true the transaction might be duplicate otherwise it is definitely a new transaction*/
    const dateAndQuantityCheck =
      itemQuantity !== 0 && !isNaN(itemQuantity)
        ? existingTransactions.some((et) => {
            return (
              dateDiffWithoutTime(et.transactionDate.date, item.transactionDate.date) === 0 &&
              et.quantity.actualQuantity.amount === itemQuantity
            );
          })
        : true;

    if (!dateAndQuantityCheck) {
      return dateAndQuantityCheck;
    } else {
      /** if description , account and balance are not checking for duplicity all at once then this is considered a new transaction  */

      const dateQuantityFilteredTransactions =
        itemQuantity !== 0 && !isNaN(itemQuantity)
          ? existingTransactions.filter((et) => {
              return (
                dateDiffWithoutTime(et.transactionDate.date, item.transactionDate.date) === 0 &&
                et.quantity.actualQuantity.amount === itemQuantity
              );
            })
          : existingTransactions;
      for (const existingSourceTransaction of dateQuantityFilteredTransactions) {
        const descriptionCondition = await this.checkDescriptionDuplicity(
          existingSourceTransaction,
          item
        );
        const accountCondition = await this.checkAccountDuplicity(existingSourceTransaction, item);
        const balanceCondition = await this.checkBalanceDuplicity(existingSourceTransaction, item);

        if (descriptionCondition && accountCondition && balanceCondition) {
          return true;
        }
      }
      return false;
    }
    /** new date and new quantity means it is a new transaction */
  }

  /** account name  maybe exactly the same or include one another which means there is chance of duplicity*/
  async checkAccountDuplicity(
    existingTransaction: SourceTransaction,
    transaction: potentialTransaction
  ) {
    const accountSourceName = existingTransaction.accountId;
    let sourceAccount: Book | SourceBook;
    let accountId = "";
    const source = await this.bookService.getBookLink(accountSourceName);

    if (!source) {
      const accountByName = await this.bookService.getByName(transaction.accountId);
      if (!accountByName) {
        return false;
      }
      sourceAccount = accountByName;
      accountId = sourceAccount.id;
    } else {
      sourceAccount = source;
      accountId = sourceAccount.accountId;
    }

    const itemAccount = sourceAccount
      ? await this.bookService.get(accountId)
      : transaction.accountId;
    const itemAccountName = itemAccount instanceof Book ? itemAccount.name : itemAccount;
    const normalizedItemAccount = itemAccountName.replace(/\s/g, "").toLowerCase();

    const existingTransactionAccount = await this.bookService.get(accountId);
    if (existingTransactionAccount) {
      const normalizedEtAccount = existingTransactionAccount.name.replace(/\s/g, "").toLowerCase();
      return normalizedItemAccount === normalizedEtAccount;
    } else {
      return false;
    }
  }

  /** Balance should be equal in order to say there is chance of duplicity. Assuming there wont be more than 8 digits for floating numbers*/
  async checkBalanceDuplicity(existingTransaction: SourceTransaction, item: potentialTransaction) {
    if (existingTransaction.bankImportedBalance && item.bankImportedBalance) {
      const normalizedEtBalance = parseFloat(
        existingTransaction.bankImportedBalance.toString()
      ).toFixed(8);
      const normalizedItemBalance = parseFloat(item.bankImportedBalance.toString()).toFixed(8);

      return normalizedItemBalance === normalizedEtBalance;
    } else {
      return true;
    }
  }

  /**
   * This will verify if a record exist or not
   * then will group the transactions to duplicated (if existing)
   * and newRecord (does not exist yet)
   *
   * @param items
   * @param existingSourceTransactions
   *
   * @return Grouped transactions
   */
  protected async validate(
    items: Array<potentialTransaction>,
    existingSourceTransactions: Array<SourceTransaction>
  ): Promise<validationResult> {
    const validated: validationResult = {
      currencies: [],
      duplicated: [],
      newRecord: [],
      preview: [],
      newAccounts: [],
      noCurrencyTransactions: [],
    };

    for (const item of items) {
      const isDuplicated: boolean = await this.checkForDuplication(
        item,
        existingSourceTransactions
      );

      item.setStatus(isDuplicated);
      isDuplicated ? validated.duplicated.push(item) : validated.newRecord.push(item);
      validated.preview.push(item);

      /** checks if account exists otherwise adds it to newAccounts */
      await this.accountValidator.validateAccount(item, validated);
    }
    return validated;
  }
}
