import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class SharedService {
  // transaction
  private applyEvent = new Subject<void>();
  applyEvent$ = this.applyEvent.asObservable();

  //transaction table tabs type
  private gridTabTypeSource = new BehaviorSubject<string | null>(null);
  gridTabType$ = this.gridTabTypeSource.asObservable();

  triggerApplyEvent() {
    this.applyEvent.next();
  }

  updateGridTabType(type: string) {
    this.gridTabTypeSource.next(type);
  }
}
