<div class="modal-container" (keydown.enter)="$event.preventDefault()">
  <modal-header [showBackButton]="false" [title]="'locationSelection' | i18n"></modal-header>

  <div class="m-body tw-p-10 xs:tw-px-5">
    <span class="tw-text-xl tw-font-normal">{{ "primaryLocationSelection" | i18n }}</span>
    <div class="tw-my-4 tw-flex tw-gap-12 md:tw-flex-col">
      <tile-button
        class="tw-w-1/2 md:tw-w-full"
        [title]="'australia' | i18n"
        (onClick)="userLocationService.processUserLocation(UserLocationCode.AU)"
      >
        <bank-icons [banks]="userLocationService.getBanks(UserLocationCode.AU)"></bank-icons>
      </tile-button>
      <tile-button
        class="tw-w-1/2 md:tw-w-full"
        [title]="'hongkong' | i18n"
        (onClick)="userLocationService.processUserLocation(UserLocationCode.HK)"
      >
        <bank-icons [banks]="userLocationService.getBanks(UserLocationCode.HK)"></bank-icons>
      </tile-button>
    </div>
    <span class="tw-text-base">{{ "locationSelectionDesc" | i18n }}</span>
  </div>
</div>
