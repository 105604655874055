export abstract class WebWorker {
  protected type = "message";
  private worker: Worker;

  constructor(worker: Worker) {
    this.worker = worker;
  }

  protected addEventListener(type: string, listener: (evt: MessageEvent) => void): void {
    this.worker?.addEventListener(type, listener as any);
  }

  protected removeEventListener(type: string, listener: (evt: MessageEvent) => void): void {
    this.worker?.removeEventListener(type, listener as any);
  }

  onMessage<T>(observer: (value: T) => void): void {
    this.addEventListener(this.type, (message) => observer(message.data));
  }

  postMessage<T>(message: T): boolean {
    this.worker?.postMessage(message);
    return !!this.worker;
  }

  terminate(): void {
    this.worker?.terminate();
    this.worker = null;
  }
}
