import { Injectable } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { ComponentStore } from "@ngrx/component-store";
import { Observable } from "rxjs";

import { GlobalService } from "@bitwarden/common/services/global/global.service";
import { TransactionCsvImporter } from "@bitwarden/web-vault/app/importers/transaction-csv-importer";
import { Transaction } from "@bitwarden/web-vault/app/models/data/blobby/transaction.data";
import { AmountTypeEnum } from "@bitwarden/web-vault/app/models/enum/transactionType";
import { BookService } from "@bitwarden/web-vault/app/services/DataService/book/book.service";
import { HelperPreference } from "@bitwarden/web-vault/app/shared/utils/helper.preference";
import { CsvValidator } from "@bitwarden/web-vault/app/validators/csv-validator";

import { AmountType, TableColumns } from "../../models/types/import.types";
import { MappingConfigurationItem, Row } from "../data-mapper/mapping-engine-types";

import { ImportInstitutionsInterface } from "./institution.import.store";
import { ImportUploadInterface } from "./upload.import.store";

export interface ImportArrangeInterface {
  materialTableDataSource: MatTableDataSource<Row>;
  arrangeTableColumns: TableColumns[];
  accountActionNeeded: boolean;
  displayedColumns: string[];
  headersMapping: MappingConfigurationItem[];
  fileHasOwnCurrency: boolean;
  records: any;
  noCurrencyTransactions: Transaction[];
  noSymbolTransactions: Transaction[];
  shouldMap: boolean;
  amountType: AmountType;
  showSpinner: boolean;
}

const defaultArrangeState: ImportArrangeInterface = {
  materialTableDataSource: null,
  arrangeTableColumns: null,
  accountActionNeeded: false,
  displayedColumns: [],
  headersMapping: [],
  fileHasOwnCurrency: false,
  records: null,
  noCurrencyTransactions: [],
  noSymbolTransactions: [],
  shouldMap: true,
  amountType: AmountTypeEnum.regular,
  showSpinner: false,
};

@Injectable()
export class ArrangeStore extends ComponentStore<ImportArrangeInterface> {
  constructor(
    private transactionCsvImporter: TransactionCsvImporter,
    private bookService: BookService,
    private helperPreference: HelperPreference,
    private csvValidator: CsvValidator,
    private globalService: GlobalService
  ) {
    super(defaultArrangeState);
  }
  readonly materialTableDataSource$: Observable<MatTableDataSource<Row>> = this.select(
    (state) => state.materialTableDataSource
  );
  readonly arrangeTableColumns$: Observable<TableColumns[]> = this.select(
    (state) => state.arrangeTableColumns
  );
  readonly displayedColumns$: Observable<string[]> = this.select((state) => state.displayedColumns);
  readonly accountActionNeeded$: Observable<boolean> = this.select(
    (state) => state.accountActionNeeded
  );
  readonly headersMapping$: Observable<MappingConfigurationItem[]> = this.select(
    (state) => state.headersMapping
  );
  readonly fileHasOwnCurrency$: Observable<boolean> = this.select(
    (state) => state.fileHasOwnCurrency
  );
  readonly records$: Observable<any> = this.select((state) => state.records);
  readonly noCurrencyTransactions$: Observable<any> = this.select(
    (state) => state.noCurrencyTransactions
  );
  readonly noSymbolTransactions$: Observable<any> = this.select(
    (state) => state.noSymbolTransactions
  );
  readonly shouldMap$: Observable<boolean> = this.select((state) => state.shouldMap);
  readonly showSpinner$: Observable<boolean> = this.select((state) => state.showSpinner);
  readonly amountType$: Observable<AmountType> = this.select((state) => state.amountType);

  readonly vmArrange$ = this.select(
    this.materialTableDataSource$,
    this.arrangeTableColumns$,
    this.displayedColumns$,
    this.accountActionNeeded$,
    this.headersMapping$,
    this.fileHasOwnCurrency$,
    this.records$,
    this.noCurrencyTransactions$,
    this.noSymbolTransactions$,
    this.shouldMap$,
    this.amountType$,
    this.showSpinner$,
    (
      materialTableDataSource,
      arrangeTableColumns,
      displayedColumns,
      accountActionNeeded,
      headersMapping,
      fileHasOwnCurrency,
      records,
      noCurrencyTransactions,
      noSymbolTransactions,
      shouldMap,
      amountType,
      showSpinner
    ) => ({
      materialTableDataSource,
      arrangeTableColumns,
      displayedColumns,
      accountActionNeeded,
      headersMapping,
      fileHasOwnCurrency,
      records,
      noCurrencyTransactions,
      noSymbolTransactions,
      shouldMap,
      amountType,
      showSpinner,
    })
  );

  async startArranging(
    uploadState: ImportUploadInterface,
    institutionState: ImportInstitutionsInterface,
    amountType: AmountType = AmountTypeEnum.regular
  ) {
    const mapping = uploadState.mappingEngine;
    const fileHasOwnCurrency = mapping.isCurrencyCodeMapped();
    /** Describe the columns for <mat-table> */
    const columns: TableColumns[] = uploadState.arrangeTableColumns;
    const displayedColumns = columns.map((c: TableColumns) => c.columnDef);
    let accountActionNeeded = false;
    const materialTableDataSource: MatTableDataSource<Row> = new MatTableDataSource(
      uploadState.fileRows
    );
    let noSymbolTransactions: Transaction[] = [];
    let noCurrencyTransactions: Transaction[] = [];
    let records: any = null;

    if (uploadState.userDateFormatIndex) {
      const recordsObject = await this.getRecords(uploadState, institutionState, amountType);
      noCurrencyTransactions = recordsObject.noCurrencyTransactions;
      noSymbolTransactions = recordsObject.noSymbolTransactions;
      records = recordsObject.records;
      accountActionNeeded = records.newAccounts.length > 0;
    }
    this.setState((state) => {
      return {
        ...state,
        arrangeTableColumns: columns,
        materialTableDataSource,
        accountActionNeeded,
        displayedColumns,
        headersMapping: uploadState.headersMapping,
        fileHasOwnCurrency,
        noCurrencyTransactions,
        noSymbolTransactions,
        records,
        shouldMap: false,
      };
    });
  }

  async getRecords(
    uploadState: ImportUploadInterface,
    institutionState: ImportInstitutionsInterface,
    amountType: AmountType = AmountTypeEnum.regular
  ) {
    this.transactionCsvImporter.setBalanceAlignmentMapper(
      institutionState.selectedInstitution.balanceAlignmentMapper
    );
    if (uploadState.userDateFormatIndex) {
      const { transactions, noCurrencyTransactions, noSymbolTransactions } =
        await this.transactionCsvImporter.parse(
          uploadState.fileRows,
          uploadState.userDateFormatIndex,
          institutionState.selectedInstitution.csvMapper,
          this.bookService,
          this.helperPreference,
          amountType
        );

      const records = await this.csvValidator.matchTransactionRecord(transactions);
      return {
        noSymbolTransactions,
        noCurrencyTransactions,
        records,
      };
    }
  }

  readonly setRecordsAfterAccountAction = this.updater((state, records: any) => {
    return {
      ...state,
      records,
      accountActionNeeded: false,
    };
  });

  readonly setShowSpinner = this.updater((state, showSpinner: boolean) => {
    return {
      ...state,
      showSpinner,
    };
  });
  readonly setRecords = this.updater((state, records: any) => {
    return {
      ...state,
      records,
    };
  });

  readonly setAmountType = this.updater((state, amountType: AmountType) => {
    return {
      ...state,
      amountType,
    };
  });

  readonly setAccountActionNeeded = this.updater((state, accountActionNeeded: boolean) => {
    return {
      ...state,
      accountActionNeeded,
    };
  });

  readonly reset = this.updater((state) => {
    return {
      ...defaultArrangeState,
    };
  });

  readonly setShouldMap = this.updater((state, shouldMap: boolean) => {
    return {
      ...state,
      shouldMap,
    };
  });

  readonly setHeadersMapping = this.updater((state, headersMapping: MappingConfigurationItem[]) => {
    return {
      ...state,
      headersMapping,
    };
  });

  /*  readonly  setMappingConfig = this.updater((state,headersMapping:MappingConfigurationItem[], uploadState:ImportUploadInterface) => {
     return{
       ...state,
       headersMapping
     }
  })*/

  async setMappingConfig(
    uploadState: ImportUploadInterface,
    institutionState: ImportInstitutionsInterface
  ) {
    const { records, noCurrencyTransactions, noSymbolTransactions } = await this.getRecords(
      uploadState,
      institutionState
    );
    this.updater((state, headersMapping: MappingConfigurationItem[]) => {
      return {
        ...state,
        records,
        noSymbolTransactions,
        noCurrencyTransactions,
      };
    });
  }

  canProcess(arrangeState: ImportArrangeInterface) {
    /*    const accountCheck: boolean = this.isThereAccountColumn(arrangeState);
    if (!accountCheck) {
      this.globalService.showErrorMessage("errorOccurred", "noAccountFileInfo");
      return accountCheck;
    }*/
    const quantityCheck: boolean = this.isThereQuantityColumn(arrangeState);
    if (!quantityCheck) {
      this.globalService.showErrorMessage("errorOccurred", "quantityImportWarning");
      return quantityCheck;
    }
    const dateCheck: boolean = this.isThereDateColumn(arrangeState);
    if (!dateCheck) {
      this.globalService.showErrorMessage("errorOccurred", "dateImportWarning");
      return dateCheck;
    }

    return quantityCheck && dateCheck;
  }

  isThereAccountColumn(arrangeState: ImportArrangeInterface): boolean {
    const headersMapping = arrangeState.headersMapping;
    return headersMapping.some((mapping) => mapping.key === "accountId");
  }
  isThereQuantityColumn(arrangeState: ImportArrangeInterface): boolean {
    const headersMapping = arrangeState.headersMapping;
    return headersMapping.some((mapping) => {
      return (
        mapping.key === "amount" ||
        mapping.key === "price" ||
        mapping.key === "in" ||
        mapping.key === "out"
      );
    });
  }
  isThereDateColumn(arrangeState: ImportArrangeInterface): boolean {
    const headersMapping = arrangeState.headersMapping;
    return headersMapping.some((mapping) => mapping.key === "date");
  }
}
