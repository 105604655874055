import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

import { LogService } from "@bitwarden/common/abstractions/log.service";
import { BookUpdateRequest } from "@bitwarden/common/models/request/book/book-update-request";
import { Book } from "@bitwarden/web-vault/app/models/data/blobby/book.data";
import { Institution } from "@bitwarden/web-vault/app/models/data/blobby/institution.data";
import { SourceBook } from "@bitwarden/web-vault/app/models/data/blobby/source-book";
import { NewAccountActionTypes } from "@bitwarden/web-vault/app/models/enum/account.enum";
import { UnrecognizedAccount } from "@bitwarden/web-vault/app/models/types/account.types";
import { Origin } from "@bitwarden/web-vault/app/models/types/general-types";
import { AccountView } from "@bitwarden/web-vault/app/models/view/account.view";
import { InstitutionService } from "@bitwarden/web-vault/app/services/DataService/institution/institution.service";

import { DataRepositoryService } from "../../DataRepository/data-repository.service";
import { DataServiceAbstraction } from "../data.service.abstraction";

@Injectable({
  providedIn: "root",
})
export class BookService implements DataServiceAbstraction {
  protected _accounts = new BehaviorSubject<Book[]>([]);
  private logService: LogService;

  // TODO: not currently using this observable, but as we returned the book-renderer response from blobby
  // leaving it here in case we do want to use observables to trigger the refresh somewhere else
  accounts$ = this._accounts.asObservable();

  constructor(
    private dataRepositoryService: DataRepositoryService,
    private institutionService: InstitutionService
  ) {}

  async getAll(): Promise<Book[]> {
    return await this.dataRepositoryService.getAllBooks();
  }

  async getBooks(triggerObservable = false): Promise<Book[]> {
    try {
      const books = await this.dataRepositoryService.getAllBooks();
      if (triggerObservable) {
        await this.updateObservables();
      }
      return books;
    } catch (e) {
      this.logService.error(e);
      throw e;
    }
  }

  async getAllInstitutionBooks(institution: Institution): Promise<Book[]> {
    try {
      const books = await this.dataRepositoryService.getAllBooks();
      return books.filter((book) => {
        if (book.institutionLink && book.institutionLink.institutionId) {
          return book.institutionLink.institutionId === institution.id;
        }
      });
    } catch (e) {
      this.logService.error(e);
      throw e;
    }
  }

  async getAllSourceBooks(): Promise<SourceBook[]> {
    try {
      return await this.dataRepositoryService.getAllSourceBooks();
    } catch (e) {
      this.logService.error(e);
      throw e;
    }
  }

  async createSourceBooks(newSourceBooks: UnrecognizedAccount[]) {
    const createdSourceBookArray: SourceBook[] = [];
    for (const newSourceBook of newSourceBooks) {
      if (newSourceBook.accountId) {
        /** The account is linked to an existing one */
        if (newSourceBook.action === NewAccountActionTypes.link) {
          const sourceBook = new SourceBook(newSourceBook);
          const createdSourceBook = await this.dataRepositoryService.createSourceBook(sourceBook);
          createdSourceBookArray.push(createdSourceBook);
        }

        /** The account is renamed ... this means a new account is created with this rename value and these are linked */
        if (newSourceBook.action === NewAccountActionTypes.rename) {
          const book = await this.dataRepositoryService.getBookByName(newSourceBook.accountId);
          newSourceBook.accountId = book.id;
          const sourceBook = new SourceBook(newSourceBook);
          const createdSourceBook = await this.dataRepositoryService.createSourceBook(sourceBook);
          createdSourceBookArray.push(createdSourceBook);
        }

        /** The account does not exist so it is created and linked to itself*/
        if (newSourceBook.action === NewAccountActionTypes.default) {
          const book = await this.dataRepositoryService.getBookByName(newSourceBook.accountId);
          newSourceBook.accountId = book.id;
          const sourceBook = new SourceBook(newSourceBook);
          const createdSourceBook = await this.dataRepositoryService.createSourceBook(sourceBook);
          createdSourceBookArray.push(createdSourceBook);
        }
      }
    }

    return createdSourceBookArray;
  }

  async replaceFromRequest(id: string, newValues: BookUpdateRequest) {
    const account = await this.get(id);

    if (account) {
      Object.assign(account, newValues);
      return this.update(account);
    } else {
      throw new Error("Invalid account details");
    }
  }

  async update(book: Book, triggerObservable = false): Promise<Book> {
    try {
      const updatedBook = await this.dataRepositoryService.updateBook(book);
      if (triggerObservable) {
        // todo need to change how we process the Observables. I dont think it should be in the service. Maybe it should
        await this.updateObservables();
      }
      return updatedBook;
    } catch (e) {
      this.logService.error(e);
      throw e;
    }
  }

  async removeInstitutionsFromAccounts(institution: Institution) {
    const accounts = await this.getAllInstitutionBooks(institution);
    for (const account of accounts) {
      account.institutionLink = null;
      await this.update(account);
    }
  }

  async delete(item: Book, triggerObservable = false): Promise<boolean> {
    try {
      const success = await this.dataRepositoryService.deleteBook(item);
      if (triggerObservable) {
        // await this.updateObservables();
      }
      return success;
    } catch (e) {
      this.logService.error(e);
      throw e;
    }
  }

  async get(id: string): Promise<Book> {
    // get the book-renderer from Blobby service
    // TODO: rework this function when we can get accounts by ID in blobby
    // They need to exist as separate objects in blobby filterable by ID
    const accounts = await this.getBooks();

    for (const account of accounts) {
      if (account.id === id) {
        return account;
      }
    }
    return;
  }

  async getByName(name: string): Promise<Book> {
    const accounts = await this.getBooks();
    return accounts.find((account) => account.name === name);
  }

  async getTransactionsByAccount(id: string) {
    return await this.dataRepositoryService.getAllTransactionsByAccount(id);
  }

  async create(book: Book): Promise<Book> {
    try {
      return await this.dataRepositoryService.createBook(book);
    } catch (e) {
      this.logService.error(e);
      throw e;
    }
  }

  private async updateObservables() {
    const allBooks = await this.getAll();
    this._accounts.next(allBooks);
  }

  async isThereBook(accountName: string): Promise<boolean> {
    const allBooks = await this.getAll();
    return allBooks.some((book) => book.name === accountName);
  }

  async getBookLink(accountName: string): Promise<SourceBook> {
    const allBooks = await this.getAllSourceBooks();
    return allBooks.find((sourceBook) => sourceBook.source === accountName);
  }

  async deleteBookLink(sourceBook: SourceBook) {
    await this.dataRepositoryService.deleteSourceBook(sourceBook);
  }

  async getBooksView(): Promise<AccountView[]> {
    const books = await this.getBooks();

    const institutions = await this.dataRepositoryService.getAllInstitutions();
    return books.map((book, index) => {
      const institution = institutions.find(
        (institution) => institution.id === book.institutionLink?.institutionId
      );
      return new AccountView(book, institution);
    });
  }

  async getAutoBooksView(): Promise<AccountView[]> {
    const booksView = await this.getBooksView();

    return booksView.filter((book) => book.origin !== Origin.manual);
  }

  async hasAutoBooks() {
    const autoBooks = await this.getAutoBooksView();
    return autoBooks.length > 0;
  }

  async getBooksViewWithMockAccounts(mockAccounts: Book[]): Promise<AccountView[]> {
    const actualBookViews: AccountView[] = await this.getBooksView();
    const mockBookViews: AccountView[] = await this.getMockAccountViews(mockAccounts);

    return actualBookViews.concat(mockBookViews);
  }

  async getMockAccountViews(mockAccounts: Book[]): Promise<AccountView[]> {
    const institutions = await this.institutionService.getInstitutionsMasterList();
    const mockAccountViews: AccountView[] = [];
    for (const mockAccount of mockAccounts) {
      const institution = institutions.find(
        (institution) => institution.id === mockAccount.institutionLink.institutionId
      );
      const mockView = new AccountView(mockAccount, institution);
      mockAccountViews.push(mockView);
    }
    return mockAccountViews;
  }
}
