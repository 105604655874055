<div class="autocomplete-container">
  <label class="autocomplete-label">{{ label }}</label>
  <mat-form-field appearance="fill" class="autocomplete-form-field" floatLabel="always">
    <input
      type="text"
      matInput
      [readonly]="!isEditable"
      [matAutocomplete]="auto"
      [formControl]="itemControl"
      class="autocomplete-input"
      [placeholder]="placeholder"
      (input)="onKey($event)"
      (blur)="onBlur($event)"
      (focus)="onFocus($event)"
      tabindex="-1"
      required
    />
    <mat-icon class="icon clear-icon" matSuffix *ngIf="itemControl.value" (click)="clearInput()"
      >clear</mat-icon
    >
    <mat-error class="error-inline input-error" *ngIf="itemControl.hasError('required')">{{
      "inputRequired" | i18n
    }}</mat-error>
    <mat-icon class="icon carrot-down-icon" matSuffix>keyboard_arrow_down</mat-icon>
  </mat-form-field>
  <mat-autocomplete
    #auto="matAutocomplete"
    [displayWith]="displayItem.bind(this)"
    (optionSelected)="itemSelected($event.option.value)"
    class="autocomplete-autocomplete"
  >
    <mat-option
      *ngFor="let item of filteredItems | async"
      [value]="item"
      class="autocomplete-option"
      >{{ item.name ? item.name : item }}</mat-option
    >
  </mat-autocomplete>
</div>
