import { BalanceDirection } from "../../../../../../libs/common/src/enums/balanceDirection";

export class InterestMatchData {
  private readonly __v = 1;
  private _id: string;

  get id(): string {
    return this._id;
  }
  percentage: number; // the percentage to calculate off the nett balance to get the quantity for the transaction
  balanceDirection: BalanceDirection; // <'owe' 'own'>
  symbol: string;
  account: Array<string>;
  category: string; // combine to search criteria to find the balance to apply the interest to
  classification: string; // combine to search criteria to find the balance to apply the interest to
}
