<!--todo: use Ronald's practice instead of ngif 2/9/2024-->
<div
  *ngIf="showHelpTooltip"
  class="help-tooltip-container"
  (click)="closeHelpTooltip($event)"
></div>
<div *ngIf="showHelpTooltip" class="help-tooltip" #popupReference>
  <div class="help-inner-scroll">
    <div class="tooltip-pointer md:tw-hidden" #tooltipPointer></div>
    <div class="help-tooltip-outer" #tooltipOuter>
      <button class="close-button" mat-icon-button (click)="closeHelpTooltip($event)">
        <mat-icon class="mat-icon-secondary">close</mat-icon>
      </button>
      <div>
        <div class="tooltip-information" [innerHtml]="tooltipInfo | safeHtml"></div>
      </div>
    </div>
  </div>
</div>
