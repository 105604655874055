import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { firstValueFrom } from "rxjs";

import { ConfirmationDataKey, ConfirmationType } from "../../models/types/general-types";
import { ConfirmationDialogComponent } from "../../settings/confirmation-dialog.component";

import { confirmation } from "./confirmation.constant";

@Injectable({
  providedIn: "root",
})
export class ConfirmationDialogService {
  constructor(private dialog: MatDialog) {}

  /**
   * Opens a confirmation dialog based on the provided key.
   *
   * @returns {Promise<boolean>} - A promise that resolves to a boolean indicating the user's confirmation.
   * @param confirmationDataInput
   */
  // TODO - update this after Horace update the component
  async showConfirmDialog(
    confirmationDataInput: ConfirmationDataKey | ConfirmationType
  ): Promise<boolean> {
    const getConfirmationData = (data: ConfirmationDataKey | ConfirmationType) => {
      if (typeof data === "string") {
        return confirmation[data];
      }
      return data;
    };

    const confirmationData = getConfirmationData(confirmationDataInput);
    const title = confirmationData.title.i18Key;
    const message = confirmationData.message.text;
    const type = confirmationData.type ?? "info";

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      panelClass: "confirmation-dialog",
      data: {
        title,
        type,
        message,
      },
    });

    return await firstValueFrom(dialogRef.afterClosed());
  }
}
