import { Component, Input } from "@angular/core";
import "./bank-icons.component.scss";

@Component({
  selector: "bank-icons",
  templateUrl: "./bank-icons.component.html",
  styles: ["bank-icons.component.scss"],
})
export class BankIconsComponent {
  @Input() banks: string[];
}
