import { Injectable, Pipe, PipeTransform } from "@angular/core";

import { SplitClassificationType } from "../models/types/split-classification-type";
import { ClassificationService } from "../services/DataService/classification/classification.service";
@Pipe({
  name: "classificationPipe",
})
@Injectable({
  providedIn: "root",
})
export class ClassificationPipe implements PipeTransform {
  constructor(private classificationService: ClassificationService) {}

  async transform(value: any, method: string, args?: any[]) {
    if (method === "getClassificationName") {
      return this.getClassificationName(value);
    }

    if (method === "getClassificationsNamesList") {
      return this.getClassificationsNamesList(value);
    }
    return value;
  }

  async getClassificationName(id: string): Promise<string> {
    const cls = await this.classificationService.get(id);
    return cls.name;
  }

  async getClassificationsNamesList(ids: SplitClassificationType[]): Promise<string> {
    if (ids) {
      let idsList = "";
      for (const [index, value] of ids.entries()) {
        let classificationName = "";
        if (value.name) {
          classificationName = value.name;
        } else {
          const classification = value
            ? await this.classificationService.get(value.classificationId)
            : { name: "" };
          classificationName = classification.name;
        }
        idsList += index === ids.length - 1 ? classificationName : classificationName + ", ";
      }

      return idsList;
    } else {
      return "";
    }
  }
}
