import { inject } from "@angular/core";
import { takeUntil } from "rxjs/operators";

import {
  BaseProgressBar,
  ProgressBarInterface,
} from "@bitwarden/web-vault/app/components/progress-bar/base-progress-bar";
import { TransactionService } from "@bitwarden/web-vault/app/services/DataService/transaction/transaction.service";

export class AccountProgressBar extends BaseProgressBar implements ProgressBarInterface {
  protected transactionService: TransactionService;

  constructor(loadingPercent: number, importingLabel: string, totalItems: number) {
    super(loadingPercent, importingLabel, totalItems);
    this.transactionService = inject(TransactionService);
    this.loadingPercent = loadingPercent;
    this.importingLabel = importingLabel;
    this.totalItems = totalItems;
  }
  show() {
    this.transactionService.totalAccountsToProcess$.pipe(takeUntil(this.unsubscribe$)).subscribe({
      next: (value) => {
        this.totalItems = value;
        this.processItems("accounts");
      },
    });
  }

  processItems(type: string) {
    this.setLabel(type);
    this.transactionService.startAccountImport();
    this.transactionService.savingItemAccounts$.pipe(takeUntil(this.unsubscribe$)).subscribe({
      next: (value) => {
        this.loadingPercent = this.calculatePercentage(value);
        if (this.totalItems === 0) {
          this.transactionService.completeAccountProgress();
        }
      },
    });
  }
}
