export class SubscriptionConfigData {
  private readonly __v = 1;
  config: Record<string, any>;

  private _url: string;
  get url(): string {
    return this._url;
  }

  set url(value: string) {
    this._url = value;
  }
}
