// async-cell-renderer.component.ts
import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";

import { PreferenceService } from "../../../services/DataService/preference/preference.service";

@Component({
  selector: "app-aggregate-amount-cell-renderer",
  templateUrl: "./aggregate-amount-cell-renderer.component.html",
})
export class AggregateAmountCellRenderer implements ICellRendererAngularComp {
  amount: number;
  baseCurrency: string;
  constructor(private preferenceService: PreferenceService) {}

  agInit(params: any): void {
    this.preferenceService.get("baseCurrency").then((baseCurrency) => {
      this.baseCurrency = baseCurrency as string;
      this.amount = params.data?.valuation?.normalizedValue?.amount || 0;
    });
  }

  refresh(params: any): boolean {
    return false; // No need to refresh since data fetch is already handled
  }
}
