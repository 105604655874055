<div [id]="_id">
  <button
    (click)="onAccordionClicked()"
    class="tw-w-full tw-cursor-pointer tw-border-none tw-bg-neutral-50 tw-p-2.5 tw-transition-all"
  >
    <div class="tw-flex tw-gap-2">
      <span class="tw-w-[90%] tw-text-left">{{ title }}</span>
      <svg class="arrow tw-right-2 tw-h-7 tw-w-7">
        <use
          [attr.xlink:href]="'images/sprite.svg#' + (isCollapsed ? 'up' : 'down') + '-arrow'"
        ></use>
      </svg>
    </div>
  </button>
  <div class="tw-hidden" [ngClass]="{ '!tw-block': isCollapsed }" [innerHTML]="_content"></div>
</div>
