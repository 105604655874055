import { Injectable } from "@angular/core";
import _ from "lodash";

import { FeatureFlag } from "./feature-flag";

@Injectable({
  providedIn: "root",
})
export class ExperimentalMode extends FeatureFlag {
  async isExperimental(flagKey: string, subKey = "") {
    const mode = await this.getConfigMode();
    if (mode !== "Experimental") {
      return false;
    }

    return subKey !== ""
      ? !_.isEmpty(this.findBySubKey(flagKey, subKey))
      : !_.isEmpty(this.findByFlagKey(flagKey));
  }

  findByFlagKey(flagKey: string) {
    const experimental = FeatureFlag.loadConfig().Experimental;
    return this.findExperimentalFeature(experimental, flagKey);
  }

  findBySubKey(flagKey: string, subkey: string) {
    const experimental = this.findByFlagKey(flagKey);
    return this.findExperimentalFeature(experimental[flagKey], subkey);
  }

  private findExperimentalFeature(experimental: Record<string, any>, key: string) {
    let feature: Record<string, any>;
    Object.keys(experimental).forEach((featureKey: string) => {
      if (featureKey === key) {
        feature = {
          ...feature,
          [featureKey]: experimental[featureKey],
        };
      }
    });

    return feature;
  }
}
