import { Injectable } from "@angular/core";

import { RoleAccessData } from "../../models/data/role-access.data";

@Injectable({
  providedIn: "root",
})
export class RoleAccessService {
  private roleAccess: RoleAccessData = new RoleAccessData();

  setRoleScope(roleScope: string) {
    const scope = roleScope.split(" ");
    this.roleAccess.setScope(scope);
  }

  setRoleClaim(roleClaims: Record<string, any>) {
    this.roleAccess.setClaims(roleClaims);
  }

  getRoleAccess() {
    return this.roleAccess;
  }
}
