import { Component, EventEmitter, Injector, Output } from "@angular/core";

import { LogService } from "@bitwarden/common/abstractions/log.service";
import { Book } from "@bitwarden/web-vault/app/models/data/blobby/book.data";
import { TransactionFilterResponse } from "@bitwarden/web-vault/app/models/data/response/transaction-filter.response";
import { ScenarioGroup } from "@bitwarden/web-vault/app/models/data/scenario-group.data";
import { TransactionFilter } from "@bitwarden/web-vault/app/models/data/scenario-transaction-filter.data";
import { GlossDateType } from "@bitwarden/web-vault/app/models/types/gloss-date-types";
import { ScenarioService } from "@bitwarden/web-vault/app/services/DataCalculationService/scenarios/scenario.service";
import { DataRepositoryService } from "@bitwarden/web-vault/app/services/DataRepository/data-repository.service";
import DateFormat from "@bitwarden/web-vault/app/shared/utils/helper.date/date-format";

@Component({
  selector: "app-filter-dropdown-buttons",
  templateUrl: "./filter-dropdown-buttons.component.html",
})
export class FilterDropdownButtonsComponent {
  @Output() selectAllEvent = new EventEmitter();
  @Output() removeAllEvent = new EventEmitter();
  @Output() reverseAllEvent = new EventEmitter();
  @Output() cancelEvent = new EventEmitter();
  @Output() applyEvent = new EventEmitter();
  @Output() callScenarioEvent = new EventEmitter();

  private dateFormat: DateFormat;
  private logService: LogService;
  protected readonly isDevelopment = process.env.NODE_ENV === "development";
  constructor(
    private dataRepositoryService: DataRepositoryService,
    private scenarioService: ScenarioService,
    private injector: Injector
  ) {}

  selectAll() {
    this.selectAllEvent.emit();
  }
  removeAll() {
    this.removeAllEvent.emit();
  }

  reverseAll() {
    this.reverseAllEvent.emit();
  }
  cancel() {
    this.cancelEvent.emit();
  }

  apply() {
    this.applyEvent.emit();
  }

  async getScenarioGroupFilters(scenarioGroup: ScenarioGroup): Promise<TransactionFilter[]> {
    const scenarioGroupAccounts: Book[] = await this.dataRepositoryService.getScenarioGroupAccounts(
      scenarioGroup
    );
    const scenarioGroupTransactions = await this.dataRepositoryService.getAllTransactionsBySymbol(
      scenarioGroup.symbol
    );
    const filterObject = {
      symbol: scenarioGroup.symbol,
      accounts: scenarioGroupAccounts,
      transactions: scenarioGroupTransactions,
    };

    return [new TransactionFilter(new TransactionFilterResponse(filterObject))];
  }

  getScenarioGroupEndDate(): GlossDateType {
    this.dateFormat = this.injector.get(DateFormat);
    const todayString = this.dateFormat.getDateStringFromStamp(new Date().getTime());
    return {
      date: todayString,
      time: null,
      tz: null,
    };
  }
  async callScenario() {
    this.callScenarioEvent.emit();
  }
}
