import { createActionGroup, emptyProps, props } from "@ngrx/store";

import { Subscription } from "../../../../models/data/subscription.data";

export const SubscriptionActions = createActionGroup({
  source: "Subscription",
  events: {
    Initialize: emptyProps(),
    Load: emptyProps(),
    "Load Success": props<{ subscription: Subscription }>(),
    "Load Failure": props<{ error: unknown }>(),
    Clear: emptyProps(),
  },
});
