<div class="sync-action-buttons-container">
  <button
    *ngIf="buttons.consent"
    class="sync-action-button"
    matTooltip="Give Consent"
    (click)="consent()"
  >
    <!--<mat-icon>add_circle</mat-icon>-->
    {{ "consent" | i18n }}
  </button>

  <button
    *ngIf="buttons.connect"
    class="sync-action-button"
    matTooltip="Connect"
    (click)="connect()"
  >
    {{ "connect" | i18n }}
    <!--<mat-icon>edit</mat-icon>-->
  </button>

  <button
    *ngIf="buttons.retry"
    class="sync-action-button"
    matTooltip="Retry Syncing"
    (click)="retry()"
  >
    {{ "retry" | i18n }}
    <!--<mat-icon>delete</mat-icon>-->
  </button>
</div>
