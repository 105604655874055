import { Component, Injector, OnDestroy, OnInit } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Subject, takeUntil } from "rxjs";

import { GlobalService } from "@bitwarden/common/services/global/global.service";
import { ClassificationAddEditComponent } from "@bitwarden/web-vault/app/gloss/settings/manage-classifications/classification-add-edit/classification-add-edit.component";
import { Classification } from "@bitwarden/web-vault/app/models/data/blobby/classification.data";
import { ClassificationService } from "@bitwarden/web-vault/app/services/DataService/classification/classification.service";
import { HelperClassification } from "@bitwarden/web-vault/app/shared/utils/helper.classification";

@Component({
  selector: "app-manage-classifications",
  templateUrl: "./manage-classifications.component.html",
})
export class ManageClassificationsComponent
  extends HelperClassification
  implements OnInit, OnDestroy
{
  private destroy$: Subject<boolean> = new Subject<boolean>();
  loading = false;
  dialogueRef: MatDialogRef<ClassificationAddEditComponent>;
  existingClassifications: Classification[];
  constructor(
    public dialog: MatDialog,
    private classificationService: ClassificationService,
    private globalService: GlobalService,
    protected injector: Injector
  ) {
    super(injector);
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
  }

  async ngOnInit(): Promise<void> {
    try {
      this.existingClassifications = await this.classificationService.getAll();
    } catch (e) {
      this.globalService.showErrorMessage("errorOccurred", e);
    }
  }

  invite() {
    this.edit(null);
  }
  async edit(classification: Classification): Promise<void> {
    if (classification?.generalDefault) {
      this.globalService.showWarningMessage("warning", "canNotActOnDefaultClassification");
      return;
    }
    const dialogRef = this.dialog.open(ClassificationAddEditComponent, {
      width: "800px",
      data: {
        classification,
        actionSucceeded: this.actionSucceeded.bind(this),
        delete: this.delete.bind(this, classification),
      },
    });

    this.dialogueRef = dialogRef;

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe((newClassifications) => {
        if (newClassifications) {
          this.existingClassifications = newClassifications;
        }
      });
  }

  async delete(classification: Classification) {
    if (classification.generalDefault) {
      this.globalService.showWarningMessage("warning", "canNotActOnDefaultClassification");
      return;
    }
    const confirmed = await this.globalService.showRemoveDialogue();
    if (confirmed) {
      try {
        this.loading = true;
        const deleted = await this.classificationService.delete(classification, false);
        if (deleted) {
          /** Update the Transaction classification to default for the deleted classification */
          await this.updateTransactionClassificationToDefault(classification);
          await this.actionSucceeded("classificationRemoved");
        }
      } catch (e) {
        this.globalService.showErrorMessage("errorOccurred", e);
      } finally {
        this.loading = false;
      }
    }
  }

  async actionSucceeded(actionMessage: string) {
    this.existingClassifications = await this.classificationService.getAll();
    this.dialogueRef?.close(this.existingClassifications);
    this.globalService.showSuccessMessage("succeeded", actionMessage);
  }
}
