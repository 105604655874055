import { GroupingNode } from "@bitwarden/web-vault/app/services/DataCalculationService/balanceGrouping/groupingNode";

import { GlossDateType } from "../types/gloss-date-types";

import { Estimate } from "./blobby/estimate.data";
import { Scenario } from "./blobby/scenario.data";
import { ScenarioGroupResponse } from "./response/scenario-group.response";
import { TransactionFilter } from "./scenario-transaction-filter.data";

export class ScenarioGroup {
  private readonly __v = 1;
  _id: string;
  _dateModified: string;
  _dateCreated: string;
  name: string;
  symbol: string;
  anchorPoint: GlossDateType;
  endDate: GlossDateType;
  scenarios: Scenario[];
  userDefinedEstimates: Estimate[];
  transactionFilters: TransactionFilter[];
  startingBalances: Record<string, GroupingNode>;
  source: string;

  get id(): string {
    return this._id;
  }

  constructor(response: ScenarioGroupResponse) {
    if (response == null) {
      return;
    }

    this._id = response.id ? response.id : crypto.randomUUID();
    this._dateModified = new Date().toUTCString();
    this._dateCreated = new Date().toUTCString();
    this.name = response.name;
    this.symbol = response.symbol;
    this.anchorPoint = response.anchorPoint;
    this.scenarios = response.scenarios;
    this.userDefinedEstimates = response.userDefinedEstimates;
    this.transactionFilters = response.transactionFilters;
    this.source = response.source;
  }
}
