import { SourceTransactionResponse } from "@bitwarden/common/models/response/source-transaction-response";
import { SymbolInfoResponse } from "@bitwarden/common/models/response/symbol-info.response";
import { Book } from "@bitwarden/web-vault/app/models/data/blobby/book.data";
import { Category } from "@bitwarden/web-vault/app/models/data/blobby/category.data";
import { Classification } from "@bitwarden/web-vault/app/models/data/blobby/classification.data";
import { Connector } from "@bitwarden/web-vault/app/models/data/blobby/connector.data";
import { Estimate } from "@bitwarden/web-vault/app/models/data/blobby/estimate.data";
import { Institution } from "@bitwarden/web-vault/app/models/data/blobby/institution.data";
import { Preference } from "@bitwarden/web-vault/app/models/data/blobby/preference.data";
import { ReferenceData } from "@bitwarden/web-vault/app/models/data/blobby/reference-data.data";
import { Scenario } from "@bitwarden/web-vault/app/models/data/blobby/scenario.data";
import { SourceBook } from "@bitwarden/web-vault/app/models/data/blobby/source-book";
import { SourceCategory } from "@bitwarden/web-vault/app/models/data/blobby/source-category";
import { SourceTransaction } from "@bitwarden/web-vault/app/models/data/blobby/source-transaction.data";
import { Transaction } from "@bitwarden/web-vault/app/models/data/blobby/transaction.data";
import { VaultFile } from "@bitwarden/web-vault/app/models/data/blobby/vault-file.data";
import { BookResponse } from "@bitwarden/web-vault/app/models/data/response/book.response";
import { CategoryResponse } from "@bitwarden/web-vault/app/models/data/response/category.response";
import { ClassificationResponse } from "@bitwarden/web-vault/app/models/data/response/classification.response";
import { ConnectorResponse } from "@bitwarden/web-vault/app/models/data/response/connector.response";
import { EstimateResponse } from "@bitwarden/web-vault/app/models/data/response/estimate.response";
import { InstitutionResponse } from "@bitwarden/web-vault/app/models/data/response/institution.response";
import { PreferenceResponse } from "@bitwarden/web-vault/app/models/data/response/preference.response";
import { ReferenceDataResponse } from "@bitwarden/web-vault/app/models/data/response/reference-data-response";
import { TransactionResponse } from "@bitwarden/web-vault/app/models/data/response/transaction-response";
import { VaultFileResponse } from "@bitwarden/web-vault/app/models/data/response/vault-file.response";
import { ScenarioGroup } from "@bitwarden/web-vault/app/models/data/scenario-group.data";
import { SymbolInfoData } from "@bitwarden/web-vault/app/models/data/symbol-info.data";

export class DataTransformer {
  static jsonObjectToTransaction(jsonCandidates: object[]): Transaction[] {
    const result: Array<Transaction> = [];
    for (const transactionJson of jsonCandidates) {
      const castedTransaction = Object.assign(new Transaction(null), transactionJson);
      result.push(castedTransaction);
    }
    return result;
  }

  static castToVaultFileArray(jsonCandidates: object[]): VaultFile[] {
    const result: Array<VaultFile> = [];
    for (const json of jsonCandidates) {
      const casted = new VaultFile(new VaultFileResponse(json));
      result.push(casted);
    }
    return result;
  }

  static jsonObjectToReference(jsonCandidates: object[]): ReferenceData[] {
    const result: Array<ReferenceData> = [];
    for (const referenceJson of jsonCandidates) {
      const castedReference = Object.assign(new ReferenceData(null), referenceJson);
      result.push(castedReference);
    }
    return result;
  }

  static castToTransactionArray(obj: any): Transaction[] {
    const result: Array<Transaction> = [];
    if (Array.isArray(obj)) {
      for (const o of obj) {
        const response = new TransactionResponse(o);
        const transaction = new Transaction(response);
        result.push(transaction);
      }
    } else if (typeof obj === "object") {
      const response = new TransactionResponse(obj);
      const transaction = new Transaction(response);
      result.push(transaction);
    }

    return result;
  }

  static castToReferenceArray(obj: any): ReferenceData[] {
    const result: Array<ReferenceData> = [];
    if (Array.isArray(obj)) {
      for (const o of obj) {
        const response = new ReferenceDataResponse(o);
        const reference = new ReferenceData(response);
        result.push(reference);
      }
    } else if (typeof obj === "object") {
      const response = new ReferenceDataResponse(obj);
      const reference = new ReferenceData(response);
      result.push(reference);
    }

    return result;
  }

  static castToPreferenceArray(obj: any): Preference[] {
    const result: Array<Preference> = [];
    if (Array.isArray(obj)) {
      for (const o of obj) {
        const pResponse = new PreferenceResponse(o);
        const pref = new Preference(pResponse);
        result.push(pref);
      }
    } else if (typeof obj === "object") {
      const pResponse = new PreferenceResponse(obj);
      const pref = new Preference(pResponse);
      result.push(pref);
    }
    return result;
  }

  static castToSourceBookArray(obj: any): SourceBook[] {
    const result: Array<SourceBook> = [];
    if (Array.isArray(obj)) {
      for (const o of obj) {
        const sourceBook = new SourceBook(o);
        result.push(sourceBook);
      }
    } else if (typeof obj === "object") {
      const sourceBook = new SourceBook(obj);
      result.push(sourceBook);
    }
    return result;
  }
  static castToScenarioArray(obj: any): Scenario[] {
    const result: Array<Scenario> = [];
    if (Array.isArray(obj)) {
      for (const o of obj) {
        const scenario = new Scenario(o);
        result.push(scenario);
      }
    } else if (typeof obj === "object") {
      const scenario = new Scenario(obj);
      result.push(scenario);
    }
    return result;
  }

  static castToScenarioGroupArray(obj: any): ScenarioGroup[] {
    const result: Array<ScenarioGroup> = [];
    if (Array.isArray(obj)) {
      for (const o of obj) {
        const scenarioGroup = new ScenarioGroup(o);
        result.push(scenarioGroup);
      }
    } else if (typeof obj === "object") {
      const scenarioGroup = new ScenarioGroup(obj);
      result.push(scenarioGroup);
    }
    return result;
  }

  static castToSourceCategoryArray(obj: any): SourceCategory[] {
    const result: Array<SourceCategory> = [];
    if (Array.isArray(obj)) {
      for (const o of obj) {
        const sourceCategory = new SourceCategory(o);
        result.push(sourceCategory);
      }
    } else if (typeof obj === "object") {
      const sourceCategory = new SourceCategory(obj);
      result.push(sourceCategory);
    }
    return result;
  }

  static castToConnectorArray(obj: any): Connector[] {
    const result: Array<Connector> = [];
    if (Array.isArray(obj)) {
      for (const o of obj) {
        const connector = new Connector(new ConnectorResponse(o));
        result.push(connector);
      }
    } else if (typeof obj === "object") {
      const connector = new Connector(new ConnectorResponse(obj));
      result.push(connector);
    }
    return result;
  }

  static castToSourceTransactionArray(obj: any): SourceTransaction[] {
    const result: Array<SourceTransaction> = [];
    if (Array.isArray(obj)) {
      for (const o of obj) {
        if (o._transactionDate["_date"]) {
          o._transactionDate = o._transactionDate["_date"];
        }
        const response = new SourceTransactionResponse(o);
        const sourceTrans = new SourceTransaction(response);

        result.push(sourceTrans);
      }
    } else if (typeof obj === "object") {
      if (obj._transactionDate["_date"]) {
        obj._transactionDate = obj._transactionDate["_date"];
      }
      const response = new SourceTransactionResponse(obj);
      const sourceTrans = new SourceTransaction(response);

      result.push(sourceTrans);
    }

    return result;
  }

  static jsonObjectToSourceTransaction(jsonCandidates: object[]): SourceTransaction[] {
    const result: Array<SourceTransaction> = [];
    for (const transactionJson of jsonCandidates) {
      const castedTransaction = Object.assign(new SourceTransaction(null), transactionJson);
      result.push(castedTransaction);
    }
    return result;
  }

  static castToBookArray(obj: any): Book[] {
    const result: Array<Book> = [];
    if (Array.isArray(obj)) {
      for (const o of obj) {
        const response = new BookResponse(o);
        const book = new Book(response);
        result.push(book);
      }
    } else if (typeof obj === "object") {
      const response = new BookResponse(obj);
      const book = new Book(response);
      result.push(book);
    }
    return result;
  }

  static castToCategoryArray(obj: object | object[]): Category[] {
    const result: Array<Category> = [];
    if (Array.isArray(obj)) {
      for (const o of obj) {
        const catResponse = new CategoryResponse(o);
        const cat = new Category(catResponse);
        result.push(cat);
      }
    } else if (typeof obj === "object") {
      const catResponse = new CategoryResponse(obj);
      const cat = new Category(catResponse);
      result.push(cat);
    }
    return result;
  }

  static castToClassificationArray(obj: any): Classification[] {
    const result: Array<Classification> = [];
    if (Array.isArray(obj)) {
      for (const o of obj) {
        const clsResponse = new ClassificationResponse(o);
        const cls = new Classification(clsResponse);
        result.push(cls);
      }
    } else if (typeof obj === "object") {
      const clsResponse = new ClassificationResponse(obj);
      const cls = new Classification(clsResponse);
      result.push(cls);
    }
    return result;
  }

  static castToInstitutionArray(obj: any): Institution[] {
    const result: Array<Institution> = [];
    if (Array.isArray(obj)) {
      for (const o of obj) {
        const instsResponse = new InstitutionResponse(o);
        const insts = new Institution(instsResponse);
        result.push(insts);
      }
    } else if (typeof obj === "object") {
      const instsResponse = new InstitutionResponse(obj);
      const insts = new Institution(instsResponse);
      result.push(insts);
    }
    return result;
  }

  static castToEstimateArray(obj: any): Estimate[] {
    const result: Array<Estimate> = [];
    if (Array.isArray(obj)) {
      for (const o of obj) {
        const actionResponse = new EstimateResponse(o);
        const estimate = new Estimate(actionResponse);
        result.push(estimate);
      }
    } else if (typeof obj === "object") {
      const estimate = new Estimate(new EstimateResponse(obj));
      result.push(estimate);
    }
    return result;
  }

  static castToEstimateGroupArray(obj: any): Estimate[] {
    const result: Array<Estimate> = [];
    if (Array.isArray(obj)) {
      for (const o of obj) {
        const egResponse = new EstimateResponse(o);
        const estimateGroup = new Estimate(egResponse);
        result.push(estimateGroup);
      }
    } else if (typeof obj === "object") {
      const estimateGroup = new Estimate(new EstimateResponse(obj));
      result.push(estimateGroup);
    }

    return result;
  }

  static castToSymbolInfoArray(obj: any): SymbolInfoData[] {
    const result: Array<SymbolInfoData> = [];
    if (Array.isArray(obj)) {
      for (const o of obj) {
        const eResp = new SymbolInfoResponse(o);
        const eData = new SymbolInfoData(eResp);
        result.push(eData);
      }
    } else if (typeof obj === "object") {
      const eResp = new SymbolInfoResponse(obj);
      const eData = new SymbolInfoData(eResp);
      result.push(eData);
    }
    return result;
  }
}
