<div class="split-c-container">
  <p class="split-name">{{ splitName }}</p>

  <app-input
    labelKey="split"
    inputType="number"
    [inputName]="inputName"
    (changeEvent)="changeEvent($event)"
    [inputValue]="inputValue"
    [isDisabled]="isDisabled"
  ></app-input>

  <button
    bitButton
    buttonType="primary"
    type="button"
    (click)="remove(componentIndex)"
    appA11yTitle="{{ 'delete' | i18n }}"
    [disabled]="isDisabled"
  >
    <i class="bwi bwi-trash bwi-lg bwi-fw" aria-hidden="true"></i>
  </button>
</div>
