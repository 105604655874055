import { SideMenuActions } from "../layouts/menu/state/side-menu.action";
import { PreferenceActions } from "../services/DataService/preference/state/preference.action";
import { ReferenceDataActions } from "../services/DataService/reference-data/state/reference-data.action";
import { SubscriptionActions } from "../services/DataService/subscription/state/subscription.action";
import { RevaluationActions } from "../services/DataService/transaction/state/revaluation.action";
import { TransactionActions } from "../services/DataService/transaction/state/transaction.action";

const Actions: Record<string, any> = {
  PreferenceActions,
  TransactionActions,
  RevaluationActions,
  ReferenceDataActions,
  SideMenuActions,
  SubscriptionActions,
};

export default Actions;
