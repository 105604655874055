<ng-container class="input-class" [formGroup]="formGroup">
  <div class="form-group mb-4">
    <label class="input-label"
      >{{ title | i18n }}
      <span
        class="mat-form-field-required-marker"
        title="Split over multiple {{ title | i18n }}.Eg: 20%-25%-55% over 3 {{ title | i18n }}"
        >?</span
      >
    </label>
    <input
      [id]="mainFormControlName + '_form_' + formGroup"
      [formControlName]="mainFormControlName"
      autocomplete="off"
      class="form-control"
      type="text"
      name="Category"
      (click)="emitToggleOptionsPanel()"
    />
  </div>
  <div class="categories-container" *ngIf="isPanelOpen">
    <div class="categories-items-container">
      <div class="one-category-container" *ngFor="let option of options; let i = index">
        <span class="cat-name">{{ option.name }} : </span>
        <input
          [id]="option.id"
          [formControlName]="optionControlNamePrefix + '_' + option.id"
          autocomplete="off"
          class="form-control"
          type="number"
          (change)="onOptionSplitChange(option, $event)"
        />
      </div>
    </div>
    <button
      (click)="emitToggleOptionsPanel()"
      buttonType="primary"
      bitButton
      class="estimate-account-button"
    >
      {{ "save" | i18n }}
    </button>
    <button
      (click)="openOptionCreationModal($event)"
      buttonType="primary"
      bitButton
      class="estimate-account-button"
    >
      {{ addNewButtonText | i18n }}
    </button>
    <button
      (click)="discardChanges()"
      buttonType="primary"
      bitButton
      class="estimate-account-button"
    >
      {{ "discard" | i18n }}
    </button>
  </div>
</ng-container>
