import { createActionGroup, emptyProps, props } from "@ngrx/store";

import { Preference } from "../../../../models/data/blobby/preference.data";

export const PreferenceActions = createActionGroup({
  source: "Preference",
  events: {
    Initialize: emptyProps(),
    Load: emptyProps(),
    "Load Success": props<{ preference: Preference | boolean }>(),
    "Load Failure": props<{ error: unknown }>(),
    Update: props<{ preference: Preference }>(),
    "Update Success": props<{ preference: Preference }>(),
    "Update Failure": props<{ error: unknown }>(),
    Clear: emptyProps(),
  },
});
