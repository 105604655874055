import { SymmetricCryptoKey } from "@bitwarden/common/models/domain/symmetric-crypto-key";
import { ModelValidator } from "@bitwarden/web-vault/app/models/data/blobby/model.validator";
import { GlossDate } from "@bitwarden/web-vault/app/models/data/shared/gloss-date";
import {
  CabinetFileType,
  VaultFileStatus,
} from "@bitwarden/web-vault/app/models/types/cabinet.types";

import { VaultFileResponse } from "../response/vault-file.response";

export class VaultFile extends ModelValidator {
  readonly __v = 1;
  private _id: string;
  encryptedKey: SymmetricCryptoKey;
  name: string;
  status: VaultFileStatus;
  mimeType: CabinetFileType;
  dateCreated: GlossDate;
  statementToDate: GlossDate;
  statementFromDate: GlossDate;
  statementAccounts: string[];

  get id(): string {
    return this._id;
  }

  set id(id: string) {
    this._id = id;
  }

  constructor(response: VaultFileResponse) {
    super();
    if (response == null) {
      return;
    }
    this.checkVersion(this.__v, response.__v);
    this._id = response.id ? response.id : crypto.randomUUID();
    this.encryptedKey = response.encryptedKey;
    this.name = response.name;
    this.mimeType = response.mimeType;
    this.dateCreated = response.dateCreated;
    this.statementToDate = response.statementToDate;
    this.statementFromDate = response.statementFromDate;
    this.statementAccounts = response.statementAccounts;
    this.status = response.status;
  }
}
