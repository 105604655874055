<div class="modal-container">
  <modal-header [title]="'uploadNewFile' | i18n" [showBackButton]="false" [enableTooltip]="false">
  </modal-header>
  <div class="m-body tw-p-10 xs:tw-px-5">
    <form name="accountForm">
      <div class="tw-flex tw-flex-col tw-gap-12">
        <div class="tw-flex tw-flex-col tw-gap-6">
          <!--        select file-->
          <div class="tw-flex tw-w-full tw-flex-col">
            <span class="tw-font-medium">{{ "selectedFile" | i18n }} *</span>
            <!--          outer-->
            <div
              class="tw-h-24 tw-w-full tw-cursor-pointer tw-rounded-xl tw-bg-neutral-50 tw-p-2 tw-shadow-inner"
              (click)="fileSelector.click()"
              (dragover)="onDragOver($event)"
              (dragleave)="onDragLeave($event)"
              (drop)="onDrop($event)"
            >
              <input
                #fileSelector
                type="file"
                id="file"
                class="form-control-file"
                name="file"
                (change)="fileSelected($event)"
                hidden
              />
              <!--            dot line -->
              <div
                class="tw-inset-0 tw-flex tw-h-full tw-flex-col tw-items-center tw-justify-center tw-rounded-lg tw-border tw-border-dashed tw-border-neutral-300 tw-opacity-60"
                style="stroke-dasharray: 10, 20"
              >
                <span
                  *ngIf="fileName; else noFile"
                  class="tw-pointer-events-none tw-font-medium tw-opacity-60"
                >
                  {{ fileName }}
                </span>
                <ng-template #noFile>
                  <span class="tw-pointer-events-none tw-font-medium tw-underline tw-opacity-60">
                    {{ "clickOrDragFile" | i18n }}
                  </span>
                  <span class="tw-pointer-events-none tw-opacity-60">
                    {{ "fileUploadLimit" | i18n }}
                  </span>
                </ng-template>
              </div>
            </div>
          </div>
        </div>

        <!--        statement period-->
        <div class="tw-flex tw-w-full tw-flex-col">
          <span class="tw-font-medium">{{ "statementPeriod" | i18n }} *</span>

          <!--        todo: @Horace temporary datepicker until new design-->
          <div class="tw-flex tw-w-full tw-gap-4">
            <!--      From picker-->
            <div class="tw-flex tw-w-full tw-gap-4">
              <div
                class="tw-w-full tw-cursor-pointer tw-flex-col tw-items-center tw-justify-start tw-gap-2 tw-pl-2 md:tw-pl-0"
                (click)="fromPicker.open()"
              >
                <div
                  class="tw-relative tw-flex tw-w-full tw-items-center tw-whitespace-nowrap tw-font-medium tw-text-neutral-600 xs:tw-scale-90"
                >
                  {{ "fromDate" | i18n }}
                </div>
                <div
                  class="tw-relative tw-flex tw-h-auto tw-min-h-[48px] tw-w-full tw-items-center tw-justify-between tw-gap-3 tw-rounded-r-xl tw-rounded-l-[50px] tw-bg-neutral-50 tw-px-6 tw-py-2 tw-shadow-button xs:tw-gap-0 xs:tw-px-2"
                >
                  <div class="tw-relative tw-flex tw-items-center tw-justify-center">
                    <mat-datepicker #fromPicker></mat-datepicker>
                    <input
                      matInput
                      [matDatepicker]="fromPicker"
                      (dateChange)="onDateChange($event, 'fromDate')"
                      class="tw-pointer-events-none tw-absolute tw-opacity-0"
                    />
                    <div
                      class="tw-relative tw-flex tw-w-full tw-min-w-[6rem] tw-justify-center tw-whitespace-nowrap tw-text-neutral-800"
                      [ngClass]="{ 'tw-opacity-60': !fromDateText }"
                    >
                      {{ fromDateText || "YYYY-MM-DD" }}
                    </div>
                  </div>
                </div>
              </div>
              <!--to Picker-->
              <div
                class="tw-w-full tw-cursor-pointer tw-flex-col tw-items-center tw-justify-start tw-gap-2 tw-pr-2"
                (click)="toPicker.open()"
              >
                <div
                  class="tw-relative tw-flex tw-w-full tw-items-center tw-whitespace-nowrap tw-font-medium tw-text-neutral-600 xs:tw-scale-90"
                >
                  {{ "toDate" | i18n }}
                </div>
                <div
                  class="tw-relative tw-flex tw-h-auto tw-min-h-[48px] tw-w-full tw-items-center tw-justify-between tw-gap-3 tw-rounded-l-xl tw-rounded-r-[50px] tw-bg-neutral-50 tw-px-6 tw-py-2 tw-shadow-button xs:tw-gap-0 xs:tw-px-2"
                >
                  <div class="tw-relative tw-flex tw-items-center tw-justify-center">
                    <mat-datepicker #toPicker></mat-datepicker>
                    <input
                      matInput
                      [matDatepicker]="toPicker"
                      (dateChange)="onDateChange($event, 'toDate')"
                      class="tw-pointer-events-none tw-absolute tw-opacity-0"
                    />
                    <div
                      class="tw-relative tw-flex tw-w-full tw-min-w-[6rem] tw-justify-center tw-whitespace-nowrap tw-text-neutral-800"
                      [ngClass]="{ 'tw-opacity-60': !toDateText }"
                    >
                      {{ toDateText || "YYYY-MM-DD" }}
                    </div>
                  </div>
                  <div class="tw-overflow-hidden tw-rounded">
                    <svg class="tw-h-7 tw-w-7">
                      <use xlink:href="images/sprite.svg#calendar" />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="tw-flex tw-flex-col">
          <span class="tw-font-medium">{{ "statementAccount" | i18n }} *</span>
          <div
            class="tw-flex tw-flex-col tw-gap-2 tw-rounded-xl tw-bg-neutral-50 tw-p-3 tw-shadow-button"
          >
            <!--            todo: @Horace more account display version (shows longer acc name, but wrap algorithm needs improve), currently in use.
                                          in gist: a more tidy version of account tags (but longer acc name will be hidden always due to the fixed size) keep this comment till design is decided-->
            <div class="tw-flex tw-flex-wrap tw-gap-2">
              <ng-container *ngFor="let account of selectedAccounts | keyvalue; let i = index">
                <div
                  class="tw-flex tw-h-10 tw-w-fit tw-min-w-[100px] tw-items-center tw-justify-center tw-gap-2 tw-rounded-full tw-py-1 tw-px-2 tw-shadow-button
                          {{ isAccountNumberOdd(i) ? 'tw-w-full' : 'tw-max-w-1/2' }}"
                >
                  <span
                    class="tw-pointer-events-none tw-w-full tw-overflow-hidden tw-text-ellipsis tw-whitespace-nowrap"
                    >{{ account.value }}</span
                  >
                  <div
                    class="tw-cursor-pointer tw-p-1 tw-font-medium"
                    (click)="removeSelectedAccount(account.key)"
                  >
                    x
                  </div>
                </div>
              </ng-container>
            </div>

            <mat-select
              #accountSelect
              multiple
              (selectionChange)="accountSelected($event)"
              class="tw-h-12 tw-w-full tw-rounded-xl tw-bg-white tw-shadow-inner"
              placeholder="Search to add existing account tags"
              panelClass="!tw-min-w-full !tw-translate-x-10 tw-bg-neutral-50"
            >
              <mat-option *ngFor="let account of accounts" [value]="account.id">
                {{ account.name }}
              </mat-option>
            </mat-select>
          </div>
        </div>

        <app-gloss-button
          class="tw-w-full"
          [options]="{
            type: 'submit',
            text: uploading
              ? ('uploading' | i18n)
              : uploaded
              ? ('UploadCompleted' | i18n)
              : ('startUploading' | i18n),
            onClick: saveUploadedFile.bind(this),
            icon: 'uploadNewFile2',
            isEnabled: !uploading && !uploaded
          }"
        >
        </app-gloss-button>
      </div>
    </form>
  </div>
</div>
