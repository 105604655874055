<ng-container *ngIf="vm$ | async as vm">
  <div class="info-placehodler" *ngIf="loading">
    <span *ngIf="!isBasiqJobsReady" class="info-text">{{ "basiqConnectionInfo" | i18n }} </span>
    <span *ngIf="isBasiqJobsReady" class="info-text">{{ "basiqConnectionMessage" | i18n }} </span>
  </div>
  <app-spinner [showSpinner]="loading"> </app-spinner>

  <div class="content">
    <div class="container import-container">
      <div class="import-wrapper" *ngIf="!vm.processStarted && !loading && !isFromBasiq">
        <div class="import-icon" *ngIf="!isImportFromApi">
          <img
            class="import-icon-image"
            src="../../images/icons/importer/csv-import-icon.png"
            alt="CSV Import"
            (click)="importCsv()"
          />
          <span class="button-text">{{ "csvImport" | i18n }}</span>
        </div>

        <div class="import-icon" *ngIf="!isImportFromApi">
          <img
            class="import-icon-image link-to-api"
            src="../../images/icons/importer/link-to-api-2.png"
            alt="Plaid Importer"
            (click)="goToImportFromApi()"
          />
          <span class="button-text">{{ "thirdPartySync" | i18n }}</span>
        </div>

        <img
          *ngIf="localEnv && !isImportFromApi"
          class="import-icon"
          src="../../images/icons/importer/commonwealth-bank.png"
          alt="CSV Importer"
          (click)="importCommonWealthBank()"
        />

        <img
          *ngIf="localEnv && !isImportFromApi"
          class="import-icon"
          src="../../images/icons/importer/westpac.png"
          alt="CSV Importer"
          (click)="importWestpacBank()"
        />

        <div class="import-icon" *ngIf="isImportFromApi">
          <img
            class="import-icon-image"
            src="../../images/icons/importer/back-icon.png"
            alt="Back Button"
            (click)="goBackToMainSection()"
          />
          <span class="button-text">{{ "goBack" | i18n }}</span>
        </div>

        <img
          *ngIf="isImportFromApi"
          class="import-icon"
          src="../../images/icons/importer/plaid.svg"
          alt="Plaid Importer"
          (click)="openLink()"
        />
        <div class="dropdown">
          <img
            *ngIf="isImportFromApi"
            class="import-icon dropdown-toggle"
            src="../../images/icons/importer/basiq.svg"
            alt="Basiq Importer"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          />
          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <button class="dropdown-item" (click)="importBasiqIo()">
              {{ "import" | i18n }}
            </button>

            <button class="dropdown-item" (click)="connectToNewInstitution()">
              {{ "connectToAnotherInstitution" | i18n }}
            </button>

            <button class="dropdown-item" (click)="manageConsents()">
              {{ "manageConsent" | i18n }}
            </button>

            <button class="dropdown-item" (click)="extendConsents()">Extend Consent</button>
          </div>
        </div>
      </div>

      <div *ngIf="vm.processStarted">
        <app-csv-import></app-csv-import>
      </div>

      <div *ngIf="isDataValidated && showTable">
        <app-preview-transaction-table
          (closeTable)="handleCloseTable()"
        ></app-preview-transaction-table>
      </div>
    </div>
  </div>
</ng-container>
