<app-spinner [showSpinner]="loading"></app-spinner>
<h1 mat-dialog-title>{{ title }}</h1>
<div mat-dialog-content>
  <form class="modal-content" [formGroup]="form" (ngSubmit)="submit()">
    <div class="modal-body" *ngIf="!loading">
      <ng-container>
        <div class="form-group mb-4">
          <mat-form-field appearance="outline">
            <mat-label for="name">{{ "name" | i18n }}</mat-label>
            <input matInput formControlName="categoryName" autocomplete="off" />
            <mat-error *ngIf="form.controls.categoryName.hasError('required')">
              Field is required
            </mat-error>
          </mat-form-field>
        </div>
      </ng-container>
    </div>

    <div class="modal-footer right-buttons-container">
      <button mat-raised-button mat-dialog-close (click)="closeDialogue()">
        {{ "cancel" | i18n }}
      </button>
      <button mat-raised-button color="primary" type="submit">
        {{ "save" | i18n }}
      </button>
    </div>
  </form>
</div>
