<ng-container id="gloss-menu">
  <div class="side-menu tw-flex tw-h-full tw-flex-col tw-justify-between tw-gap-2 tw-text-white">
    <div class="tw-flex tw-w-full tw-flex-col tw-gap-2">
      <div class="logo-button tw-text-center" (click)="handleToggleNavigation()">
        <img
          class="tw-my-5 tw-w-[65%]"
          alt="Gloss Logo"
          src="../../../../images/icons/gloss-logo-white.png"
        />
      </div>

      <ng-container *ngFor="let item of navItems">
        <ng-container *appRoleScope="item.roleScope">
          <nav
            class="tw-mx-2 tw-flex tw-h-14 tw-cursor-pointer tw-items-center tw-gap-2 tw-px-6"
            [routerLink]="item.routerLink"
            (click)="getActiveTab()"
            [ngClass]="{ 'active-class': isActive(item) }"
          >
            <svg
              class="tw-h-8 tw-w-8"
              [ngClass]="{ 'tw-stroke-light': this.currentUrl !== item.currentUrl }"
            >
              <use [attr.xlink:href]="'images/sprite.svg#' + getSelectedIcon(item)" />
            </svg>
            <span class="tw-font-medium">{{ item.label | i18n }}</span>
          </nav>
        </ng-container>
      </ng-container>
    </div>
    <div *appRoleScope="RoleScope.GLOSS" class="tw-flex tw-flex-col">
      <nav
        class="tw-mx-2 tw-mb-2 tw-flex tw-h-14 tw-cursor-pointer tw-items-center tw-gap-2 tw-px-6"
        (click)="changeHelpOpen.emit(true)"
      >
        <svg class="tw-h-8 tw-w-8 tw-stroke-light">
          <use xlink:href="images/sprite.svg#help" />
        </svg>
        <span class="tw-font-medium">{{ "help" | i18n }}</span>
      </nav>
    </div>
  </div>
</ng-container>
