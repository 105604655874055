import { Injectable } from "@angular/core";

import { ApiService } from "@bitwarden/common/abstractions/api.service";
import { getDefaultSubscription } from "@bitwarden/web-vault/app/models/data/subscription.data";
import { DataRepositoryService } from "@bitwarden/web-vault/app/services/DataRepository/data-repository.service";

import { StripeConfigData } from "../../models/data/stripe-config.data";

@Injectable({
  providedIn: "root",
})
export class StripeConfigService extends StripeConfigData {
  private readonly CURRENCY_USD = "usd";

  constructor(
    private dataRepositoryService: DataRepositoryService,
    private apiService: ApiService
  ) {
    super();
  }

  async createCheckoutSessions(payload: Record<string, any>): Promise<any> {
    const endpoint = this.config.endpoint["checkout_sessions"];

    payload = {
      ...payload,
      redirectUrl: this.redirectUrl,
    };

    return await this.dataRepositoryService.send("POST", endpoint, payload, false, true, this.url);
  }

  async getCheckoutSessions(sessionId: string): Promise<any> {
    const endpoint = this.config.endpoint["checkout_sessions"];

    return await this.dataRepositoryService.send(
      "GET",
      `${endpoint}/${sessionId}`,
      null,
      false,
      true,
      this.url
    );
  }

  async getSubscription() {
    return Promise.resolve(getDefaultSubscription());

    // try {
    //   const profile = await this.apiService.getProfile();
    //   const getSubscription = this.config.endpoint["get_subscription"];
    //   const endpoint = `${getSubscription}/${profile.email}`;
    //   return await this.dataRepositoryService.send(
    //     "GET",
    //     `${endpoint}`,
    //     null,
    //     true,
    //     true,
    //     this.url
    //   );
    // } catch (e) {
    //   return Promise.resolve(getDefaultSubscription());
    // }
  }

  async cancelSubscription(subscriptionId: string) {
    const endpoint = this.config.endpoint["cancel_subscription"];
    const payload = { subscriptionId };

    return await this.dataRepositoryService.send(
      "DELETE",
      `${endpoint}`,
      payload,
      true,
      true,
      this.url
    );
  }

  setPayload(member: Record<string, any>, plan: Record<string, any>) {
    return {
      member: {
        email: member.email,
        name: member.name,
      },
      plan: {
        currency: this.CURRENCY_USD,
        name: plan.title,
        unitAmount: plan.priceInCents,
      },
    };
  }

  gotoSubscriptionPage() {
    return this.config.subscriptionPage;
  }
}
