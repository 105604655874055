import { Book } from "../../models/data/blobby/book.data";
import { Institution } from "../../models/data/blobby/institution.data";

export const columnDefinition = [
  {
    headerName: "File name",
    headerClass: "wrap-header-text",
    field: "name",
    valueGetter: (params: any) => params.data?.name,
    cellClass: "truncate-text",
  },
  {
    headerName: "Statement period",
    headerClass: "wrap-header-text",
    field: "statementFromDate",
    valueGetter: (params: any) =>
      params.data?.statementFromDate.dateString + " - " + params.data?.statementToDate.dateString,
    cellClass: "wrap-text",
  },
  {
    headerName: "Bank",
    headerClass: "wrap-header-text",
    field: "institution",
    valueGetter: (params: any) =>
      params.data?.banks.map((bank: Institution) => bank.name).join(", "),
    cellClass: "wrap-text",
  },
  {
    headerName: "Account Name",
    headerClass: "wrap-header-text",
    field: "statementAccounts",
    valueGetter: (params: any) =>
      params.data?.statementAccounts.map((account: Book) => account.name).join(", "),
    cellClass: "wrap-text",
  },
];
