import { StringToNumberPreference } from "../../../models/types/PrefereneceTypes";

//TODO keys should be i18n versioned
export const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "Jun",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const tempMonths: StringToNumberPreference = {};
months.forEach((month, index) => {
  tempMonths[month] = index + 1;
});
export const yearMonths: StringToNumberPreference = tempMonths;
