import { SymmetricCryptoKey } from "@bitwarden/common/models/domain/symmetric-crypto-key";
import { BaseResponse } from "@bitwarden/common/models/response/base.response";
import { GlossDate } from "@bitwarden/web-vault/app/models/data/shared/gloss-date";
import {
  CabinetFileType,
  VaultFileStatus,
} from "@bitwarden/web-vault/app/models/types/cabinet.types";

export class VaultFileResponse extends BaseResponse {
  __v: number;
  id: string;
  name: string;
  encryptedKey: SymmetricCryptoKey;
  mimeType: CabinetFileType;
  dateCreated: GlossDate;
  statementToDate: GlossDate;
  statementFromDate: GlossDate;
  statementAccounts: string[];
  status: VaultFileStatus;

  constructor(response: any) {
    super(response);
    this.__v = this.getResponseProperty("__v");
    this.id = this.getResponseProperty("_id");
    this.name = this.getResponseProperty("name");
    this.encryptedKey = this.getResponseProperty("encryptedKey");
    this.dateCreated = new GlossDate().setToDateObj(this.getResponseProperty("dateCreated"));
    this.mimeType = this.getResponseProperty("mimeType");
    this.statementToDate = new GlossDate().setToDateObj(
      this.getResponseProperty("statementToDate")
    );
    this.statementFromDate = new GlossDate().setToDateObj(
      this.getResponseProperty("statementFromDate")
    );
    this.statementAccounts = this.getResponseProperty("statementAccounts");
    this.status = this.getResponseProperty("status");
  }
}
