import { ConsoleLogService } from "@bitwarden/common/services/consoleLog.service";
import { EstimateActionData } from "@bitwarden/web-vault/app/models/data/blobby/estimate.action.data";
import { Transaction } from "@bitwarden/web-vault/app/models/data/blobby/transaction.data";
import { EstimateActionResponse } from "@bitwarden/web-vault/app/models/data/response/estimate-action.response";
import {
  TransferParameters,
  TransferOutput,
} from "@bitwarden/web-vault/app/models/types/estimate-action.types";
import { CreatedRecords } from "@bitwarden/web-vault/app/models/types/scenario-group.types";
import { BalanceGrouping } from "@bitwarden/web-vault/app/services/DataCalculationService/balanceGrouping/balanceGrouping";
import { DataTransformer } from "@bitwarden/web-vault/app/services/dto/data-transformer";

export class TransactionTransferAction extends EstimateActionData {
  parameters: TransferParameters;
  createdRecords: TransferOutput;
  groupedBalance: BalanceGrouping;

  logger: ConsoleLogService;

  constructor(response: EstimateActionResponse) {
    super(response);
  }

  async run(
    parameters: TransferParameters,
    createdRecords?: CreatedRecords
  ): Promise<TransferOutput> {
    this.fillInParameters(createdRecords, parameters);
    await super.run(parameters, createdRecords);
    const transactions: Array<Transaction> = [];

    // create the out transaction
    const outTransaction = await this.createTransferTransaction(
      this.parameters.accountFrom.id,
      "Mock transfer : Out",
      this.parameters.amount * -1
    );

    // create the in transaction
    const inTransaction = await this.createTransferTransaction(
      this.parameters.accountTo.id,
      "Mock transfer : In",
      this.parameters.amount
    );

    // link the two transactions as a transfer in the system so they are not counted as a directional value
    outTransaction.linkedTo = [inTransaction.id];
    inTransaction.linkedTo = [outTransaction.id];

    transactions.push(outTransaction);
    transactions.push(inTransaction);

    if (this.groupedBalance) {
      await this.addTransaction(outTransaction, this.groupedBalance);
      await this.addTransaction(inTransaction, this.groupedBalance);
    }

    // create the output to return
    this.createdRecords = {
      transactions: transactions,
      groupedBalance: this.groupedBalance,
    };

    return this.createdRecords;
  }

  /**
   * createInterestTransaction - given a date and an amount, create an interest transaction for it
   *
   * @param previousDate
   * @param monthlyInterestAmount
   */

  async createTransferTransaction(
    accountID: string,
    description: string,
    amount: number
  ): Promise<Transaction> {
    const transferTransaction = await this.createFakeTransaction(
      accountID,
      amount,
      this.parameters.symbol,
      this.parameters.transactionDate,
      description,
      // transactionNormalizeService,
      this.parameters.defaultSplitClassification,
      this.parameters.defaultSplitCategory,
      this.parameters.baseCurrency,
      this.parameters.referenceData,
      this.logger,
      ["placeholder"]
    );
    return transferTransaction;
  }

  fillInParameters(createdRecords: CreatedRecords, parameter: TransferParameters) {
    // transform the arrays from the web worker back into book objects
    if (parameter?.accountTo === null && createdRecords.accounts.length > 0) {
      const bookArray = DataTransformer.castToBookArray(createdRecords.accounts[0]);
      if (bookArray.length > 0) {
        parameter.accountTo = bookArray[0];
      }
    } else if (parameter?.accountTo) {
      const bookArray = DataTransformer.castToBookArray(parameter.accountTo);
      if (bookArray.length > 0) {
        parameter.accountTo = bookArray[0];
      }
    }
    if (parameter?.accountFrom) {
      const bookArray = DataTransformer.castToBookArray(parameter.accountFrom);
      if (bookArray.length > 0) {
        parameter.accountFrom = bookArray[0];
      }
    }
  }
}
