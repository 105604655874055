import { ModelValidator } from "@bitwarden/web-vault/app/models/data/blobby/model.validator";

import { Origin } from "../../types/general-types";

export class SourceBook extends ModelValidator {
  private readonly __v = 1;
  private _id: string;
  source: string;
  accountId: string;
  origin: Origin;

  // todo remove the any
  constructor(sourceBookObj: any) {
    super();
    if (sourceBookObj == null) {
      return;
    }

    // todo when it uses a BaseResponse object as constructor remove the ?? this.__v
    this.checkVersion(this.__v, sourceBookObj.__v ?? this.__v);
    this._id = sourceBookObj._id ? sourceBookObj._id : crypto.randomUUID();
    this.source = sourceBookObj.source;
    this.accountId = sourceBookObj.accountId;
    this.origin = sourceBookObj.origin ? sourceBookObj.origin : Origin.manual;
  }

  get id(): string {
    return this._id;
  }

  set id(value: string) {
    this._id = value;
  }
}
