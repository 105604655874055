import { Component } from "@angular/core";

import { UserLocationCodeEnum } from "@bitwarden/web-vault/app/models/enum/user-location.enum";
import { UserLocationService } from "@bitwarden/web-vault/app/services/location/user-location.service";

@Component({
  selector: "user-location",
  templateUrl: "./user-location.component.html",
})
export class UserLocationComponent {
  protected UserLocationCode = UserLocationCodeEnum;

  constructor(protected userLocationService: UserLocationService) {}
}
