import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { mergeMap, map, catchError } from "rxjs/operators";

import { ReferenceDataService } from "../reference-data.service";

import { ReferenceDataActions } from "./reference-data.action";

@Injectable()
export class ReferenceDataEffects {
  constructor(private actions$: Actions, private referenceDataService: ReferenceDataService) {}

  loadReferenceData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ReferenceDataActions.load),
      mergeMap(() =>
        this.referenceDataService.loadReferenceData$().pipe(
          map((referenceData) => ReferenceDataActions.loadSuccess({ referenceData })),
          catchError((error: unknown) => of(ReferenceDataActions.loadFailure({ error })))
        )
      )
    );
  });
}
