import {
  GroupingNodeType,
  GroupingNodeTypeClasses,
} from "@bitwarden/web-vault/app/models/types/balanceGroupingTypes";
import { GroupingNode } from "@bitwarden/web-vault/app/services/DataCalculationService/balanceGrouping/groupingNode";

export class GroupingNodeFactory {
  static createNode(
    nodeType: GroupingNodeType,
    usedGroupings: Array<GroupingNodeType>,
    createChildren = true,
    limitChildren?: Array<GroupingNodeType>,
    groupingKey?: string
  ): GroupingNode {
    const newNode = new GroupingNodeTypeClasses[nodeType]();
    newNode.addUsedGroupings(usedGroupings);
    // if not creating children nodes, then we should clear the default children array
    if (createChildren === false) {
      newNode.defaultChildren = [];
    }
    if (limitChildren) {
      newNode.limitChildren = limitChildren;
    }
    if (groupingKey) {
      newNode.groupingKey = groupingKey;
    }
    return newNode;
  }
}
