import { DatePipe } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";

import { GlossDate } from "@bitwarden/web-vault/app/models/data/shared/gloss-date";
import { GlossDateType } from "@bitwarden/web-vault/app/models/types/gloss-date-types";

@Pipe({
  name: "dateFormat",
})
export class DateFormatPipe implements PipeTransform {
  static readonly defaultFormat = "yyyy-MM-dd";

  transform(date: string | Date | GlossDateType | GlossDate, ...args: any[]): string {
    if (date instanceof Date || typeof date === "string") {
      return new DatePipe("en-us").transform(date, DateFormatPipe.defaultFormat);
    } else if (typeof date === "object" && date.date) {
      // todo fix that GlossDate instanceOf when we convert the object from a type to a class
      return new DatePipe("en-us").transform(date.date, DateFormatPipe.defaultFormat);
    } else {
      return "";
    }
  }
}
