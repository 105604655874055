<div class="institutions-header-btn-container">
  <!--  <div-->
  <!--    class="institutions-header-btn auto-focus"-->
  <!--    [class.active-institution-header]="isAddInstitution"-->
  <!--    (click)="showAddInstitution()"-->
  <!--  >-->
  <div
    class="institutions-header-btn"
    [class.active-institution-header]="isAddInstitution"
    (click)="showAddInstitution()"
  >
    {{ editMode ? ("UpdateInstitution" | i18n) : ("AddInstitution" | i18n) }}
  </div>
  <!--  <div
    *ngIf="!editMode"
    class="institutions-header-btn"
    [class.active-institution-header]="isCreateInstitution"
    (click)="showCreateInstitution()"
  >
    {{ "CreateInstitution" | i18n }}
  </div>-->
</div>

<div mat-dialog-content>
  <app-add-institution-form *ngIf="isAddInstitution" [data]="data"></app-add-institution-form>
  <app-create-institution-form
    *ngIf="isCreateInstitution"
    [data]="data"
  ></app-create-institution-form>
</div>
