import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  OnChanges,
  Renderer2,
  SimpleChanges,
  ViewChild,
  HostListener,
} from "@angular/core";
import { DeviceDetectorService } from "ngx-device-detector";

@Component({
  selector: "app-help-tooltip",
  templateUrl: "./help-tooltip.component.html",
})
export class HelpTooltipComponent implements AfterViewInit, OnChanges {
  @Input() tooltipInfo: string;
  @Input() tooltipHeading: string;
  @Input() xPosition = 0;
  @Input() yPosition = 0;
  @Input() pointer: string;
  @Input() showHelpTooltip: boolean;
  //todo: extract tab type to a separate interface
  @Input() tabs: Array<{ key: number; content: string; type: string; class: string }>;
  @Input() tab: { key: number; content: string; type: string; class: string };
  @Output() onTooltipClose: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() toggleDeselected = new EventEmitter<void>();

  @ViewChild("popupReference") popupReference: ElementRef;
  @ViewChild("tooltipOuter") tooltipOuter: ElementRef;
  @ViewChild("tooltipPointer") tooltipPointer: ElementRef;

  constructor(
    private renderer: Renderer2,
    private cdr: ChangeDetectorRef,
    private deviceService: DeviceDetectorService
  ) {}

  ngAfterViewInit() {
    this.setPopupPositionWrapper();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.tooltipInfo ||
      changes.showHelpTooltip ||
      changes.xPosition ||
      changes.yPosition ||
      changes.popupReference ||
      changes.pointer
    ) {
      this.cdr.detectChanges();
      this.setPopupPositionWrapper();
    }
  }

  @HostListener("window:resize")
  onResize() {
    this.setPopupPositionWrapper();
  }

  setPopupPositionWrapper() {
    if (this.showHelpTooltip && this.popupReference) {
      this.setPopupPosition();
    }
  }

  closeHelpTooltip(e: MouseEvent) {
    const popupElement = this.popupReference.nativeElement as HTMLElement;
    this.renderer.removeStyle(popupElement, "height");
    this.showHelpTooltip = false;
    this.onTooltipClose.emit();
    this.toggleDeselected.emit();
  }

  private setPopupPosition() {
    const popupElement = this.popupReference.nativeElement as HTMLElement;
    const tooltipPointerElement = this.tooltipPointer.nativeElement as HTMLElement;

    let pointer = this.pointer;
    let buttonPosition: HTMLElement;
    if (this.deviceService.isMobile()) {
      pointer = "modal";
    }

    //popup position
    if (this.tooltipInfo && this.popupReference) {
      if (pointer === "top" || pointer === "bottom") {
        const selectedTab = this.tabs.find((tab) => tab.class.includes("selected"));
        buttonPosition = document.getElementById(`scenario-${selectedTab.key + 1}-help-button`);

        if (pointer === "bottom") {
          const selectedTabPosition = document.querySelector(`.selected`) as HTMLElement;
          const leftPosition = Math.max(selectedTabPosition.offsetLeft, 10);
          this.renderer.setStyle(popupElement, "left", `${leftPosition}px`);
          const xCalculated =
            window.innerWidth -
            (popupElement.offsetWidth + popupElement.getBoundingClientRect().left) -
            20;
          const yCalculated = this.yPosition - popupElement.getBoundingClientRect().height;

          if (xCalculated < 0) {
            this.renderer.setStyle(
              popupElement,
              "left",
              `${selectedTabPosition.offsetLeft + xCalculated}px`
            );
          }
          if (yCalculated < 0) {
            pointer = "top";
            this.renderer.setStyle(
              popupElement,
              "top",
              `${selectedTabPosition.offsetTop + selectedTabPosition.offsetHeight * 1.5}px`
            );
          } else {
            this.renderer.setStyle(
              popupElement,
              "top",
              `${
                selectedTabPosition.offsetTop -
                popupElement.offsetHeight -
                selectedTabPosition.offsetHeight / 2
              }px`
            );
          }
        }
      } else if (pointer === "right") {
        this.renderer.addClass(tooltipPointerElement, "tw-hidden");
      } else if (pointer === "modal") {
        if (this.deviceService.isMobile()) {
          this.renderer.addClass(tooltipPointerElement, "tw-hidden");
          this.renderer.addClass(popupElement, "help-tooltip-mobile");

          // this is for absolute position to stick to the div
          // const popupPositionY =
          //   window.innerHeight -
          //   popupElement.getBoundingClientRect().top -
          //   popupElement.offsetHeight -
          //   75;
          // if (popupPositionY < 0) {
          //   this.renderer.setStyle(
          //     popupElement,
          //     "top",
          //     `${Math.max(popupElement.offsetTop + popupPositionY, 10)}px`
          //   );
          // }

          // this is for absolute position to stick to the bottom of the screen
          // const popupPositionY =
          //   window.innerHeight -
          //   popupElement.getBoundingClientRect().top -
          //   popupElement.offsetHeight;
          // if (popupPositionY < 0) {
          //   this.renderer.setStyle(
          //     popupElement,
          //     "top",
          //     `${popupElement.offsetTop+popupPositionY-5}px`
          //   );
          // }else{
          //   this.renderer.setStyle(
          //     popupElement,
          //     "top",
          //     `${popupElement.offsetTop+window.innerHeight-popupElement.getBoundingClientRect().top-popupElement.offsetHeight-5}px`
          //   );
          // }

          // and if this doesnt make the popup stay at the bottom on IOS, use the commented one above, description is in the first line
          // because the request keeps changing, please dont delete the commented code (in this method) for now
          this.renderer.addClass(popupElement, "!tw-fixed");

          const popupPositionX =
            window.innerWidth - popupElement.offsetLeft - popupElement.offsetWidth * 1.2;
          if (popupPositionX < 0) {
            this.renderer.setStyle(
              popupElement,
              "left",
              `${Math.max(popupElement.offsetLeft + popupPositionX, 0)}px`
            );
          }
        } else {
          buttonPosition = document.getElementById("help-info-button");
          this.renderer.setStyle(
            popupElement,
            "top",
            `${buttonPosition.offsetTop + buttonPosition.offsetHeight + 16}px`
          );
          pointer = "top";
        }
      } else if (pointer === "modal-header") {
        buttonPosition = document.getElementById("help-info-button-creation");
        this.renderer.setStyle(
          popupElement,
          "top",
          `${buttonPosition.offsetTop + buttonPosition.offsetHeight + 16}px`
        );
        pointer = "top";
      }

      if (buttonPosition) {
        this.renderer.setStyle(
          tooltipPointerElement,
          "left",
          `${
            buttonPosition.offsetLeft - popupElement.offsetLeft - buttonPosition.offsetWidth / 6
          }px`
        );
      }

      //actual pointer position
      if (pointer === "top") {
        // adjust the styling of the triangle to the bottom of the tooltip
        this.renderer.setStyle(
          tooltipPointerElement,
          "border-color",
          "transparent transparent rgb(255, 255, 255) transparent"
        );
        this.renderer.setStyle(tooltipPointerElement, "bottom", "100%");
      } else if (pointer === "bottom") {
        // adjust the styling of the triangle to the top of the tooltip
        this.renderer.setStyle(
          tooltipPointerElement,
          "border-color",
          "rgb(255, 255, 255) transparent transparent transparent"
        );
        this.renderer.setStyle(tooltipPointerElement, "top", "100%");
      }
    }
  }
}
