import { Injectable, Injector } from "@angular/core";

import { ApiService } from "@bitwarden/common/abstractions/api.service";
import { LogService } from "@bitwarden/common/abstractions/log.service";
import { devFlagEnabled } from "@bitwarden/common/misc/flags";
import { TokenService } from "@bitwarden/common/services/token.service";
import { ClaimType, scopeEnum } from "@bitwarden/web-vault/app/models/scope/typeDef";
import { TransactionService } from "@bitwarden/web-vault/app/services/DataService/transaction/transaction.service";

@Injectable({
  providedIn: "root",
})
export class HelperCommon {
  static RUN_VALUATION = false;

  protected transactionService: TransactionService;
  protected apiService: ApiService;
  protected log: LogService;
  constructor(injector: Injector) {
    this.transactionService = injector.get(TransactionService);
    this.apiService = injector.get(ApiService);
    this.log = injector.get(LogService);
  }

  /**
   * @deprecated - this is a legacy method that will be replace by RBAC
   */
  static isDevEnv() {
    return devFlagEnabled("development");
  }

  /**
   * @deprecated - this is a legacy method that will be replace by RBAC
   */
  async hasScope(scope: scopeEnum, claims: ClaimType[] = null): Promise<boolean> {
    try {
      const activeToken = await this.apiService.getActiveBearerToken();
      const decoded = await TokenService.decodeToken(activeToken);

      if (
        (decoded?.scope && Array.isArray(decoded?.scope) && decoded?.scope.includes(scope)) ||
        HelperCommon.isDevEnv()
      ) {
        // todo check claims if needed
        return true;
      } else {
        // for MVP as long as you have a token you have scope
        return false;
      }
    } catch (e) {
      // todo assuming we need to make that less vocal
      this.log.error(e);
      return false;
    }
  }

  static isLocal(): boolean {
    return process.env.IS_LOCAL === "true";
  }
}
