import { Injectable } from "@angular/core";
import { ComponentStore } from "@ngrx/component-store";
import { Observable } from "rxjs";

import { GlobalService } from "@bitwarden/common/services/global/global.service";
import { alignmentDefaultMapping } from "@bitwarden/web-vault/app/importers/data-mapper/mappers/default-mapping";
import { InstitutionService } from "@bitwarden/web-vault/app/services/DataService/institution/institution.service";

import { Institution } from "../../models/data/blobby/institution.data";
export interface ImportInstitutionsInterface {
  institutions: Institution[];
  selectedInstitution: Institution;
}
const defaultInstitutionState: ImportInstitutionsInterface = {
  institutions: null,
  selectedInstitution: null,
};
@Injectable()
export class InstitutionStore extends ComponentStore<ImportInstitutionsInterface> {
  constructor(
    private institutionService: InstitutionService,
    private globalService: GlobalService
  ) {
    super(defaultInstitutionState);
  }

  readonly institutions$: Observable<Institution[]> = this.select((state) => state.institutions);
  readonly selectedInstitution$: Observable<Institution> = this.select(
    (state) => state.selectedInstitution
  );

  readonly vmInstitution$ = this.select(
    this.institutions$,
    this.selectedInstitution$,
    (institutions, selectedInstitution) => ({
      institutions,
      selectedInstitution,
    })
  );

  readonly setInstitutions = this.updater((state, institutions: Institution[]) => {
    return {
      ...state,
      institutions,
    };
  });

  readonly reset = this.updater((state) => defaultInstitutionState);

  readonly setSelectedInstitution = this.updater((state, selectedInstitution: Institution) => {
    return {
      ...state,
      selectedInstitution,
    };
  });

  async setInitialInstitution(): Promise<void> {
    try {
      const institutions = await this.institutionService.getAll();
      if (institutions.length) {
        this.setInstitutions(institutions);
        if (!institutions[0].balanceAlignmentMapper) {
          institutions[0].balanceAlignmentMapper = alignmentDefaultMapping;
        }
        this.setSelectedInstitution(institutions[0]);
      }
    } catch (error) {
      this.globalService.showErrorMessage("errorOccurred", "selectInstitutionInfo");
    }
  }

  async setAllInstitutions(): Promise<void> {
    try {
      const institutions = await this.institutionService.getAll();
      if (institutions.length) {
        this.setInstitutions(institutions);
      }
    } catch (error) {
      this.globalService.showErrorMessage("errorOccurred", "selectInstitutionInfo");
    }
  }
}
