import { BaseResponse } from "../../../../../../../libs/common/src/models/response/base.response";

export class CategoryResponse extends BaseResponse {
  __v: number;
  id: string;
  name: string;
  weight: number; // Link to the account AccountIdReference
  generalDefault?: boolean; // when we import data and create accounts if there are no category-renderer related to transaction
  // we create a default category-renderer as account has to have at least one default category-renderer

  constructor(response: any) {
    super(response);
    this.__v = this.getResponseProperty("__v");
    this.id = this.getResponseProperty("_id");
    this.name = this.getResponseProperty("name");
    this.weight = this.getResponseProperty("weight");
    this.generalDefault = this.getResponseProperty("generalDefault");
  }
}
