<div class="ironfly-primary-graph">
  <!--  <div class="ironfly-primary-graph__filter-bar">-->
  <!--    <div-->
  <!--      class="primary-graph-form-inputs tw-w-full tw-rounded-lg tw-bg-white tw-p-2 xs:tw-flex-col"-->
  <!--    >-->
  <!--      DATE PICKER-->
  <!--      <app-date-range-picker-->
  <!--        class="ironfly-primary-graph__filter-item"-->
  <!--        [defaultDates]="defaultDates"-->
  <!--        (dateChangeEvent)="updateDate($event)"-->
  <!--      ></app-date-range-picker>-->
  <!--      ACCOUNT PICKER-->

  <div *appRoleScope="RoleScope.BETA_ACCESS" class="ironfly-primary-graph__filter-item">
    <div *ngIf="isTransactionGraph()">
      <mat-form-field>
        <mat-label>{{ "accounts" | i18n }}</mat-label>
        <mat-select
          #accountSelect
          panelClass="custom-mat-select-panel"
          [formControl]="selectedAccountsControl"
          [multiple]="true"
          [disableOptionCentering]="true"
          (closed)="cancelAccountSelection()"
        >
          <mat-select-trigger>
            {{ selectedAccountsControl.value?.length || 0 }} option(s) selected
          </mat-select-trigger>
          <input
            matInput
            class="search-input"
            placeholder="Search accounts"
            (input)="filterAccounts($event)"
          />
          <mat-option *ngFor="let account of filteredAccounts" [value]="account">
            {{ account.name }}
          </mat-option>
          <mat-divider></mat-divider>
          <app-filter-dropdown-buttons
            (selectAllEvent)="selectAllAccounts()"
            (removeAllEvent)="removeAllAccounts()"
            (reverseAllEvent)="reverseAllAccounts()"
            (cancelEvent)="cancelAccountSelection()"
            (applyEvent)="applySelectedAccounts()"
            (callScenarioEvent)="graphScenario()"
          ></app-filter-dropdown-buttons>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <!--      DIRECTION PICKER-->
  <!--s
      <div>
        <mat-form-field>
          <mat-label>{{ "direction" | i18n }}</mat-label>
          <mat-select
            panelClass="custom-mat-select-panel"
            #directionSelect
            [formControl]="selectedDirectionsControl"
            [multiple]="true"
            [disableOptionCentering]="true"
            (closed)="cancelDirectionSelection()"
          >
            <mat-select-trigger>
              {{ selectedDirectionsControl.value?.length || 0 }} option(s) selected
            </mat-select-trigger>
            <mat-option *ngFor="let direction of directions" [value]="direction">
              {{ direction }}
            </mat-option>
            <mat-divider></mat-divider>
            <app-filter-dropdown-buttons
              (selectAllEvent)="selectAllDirections()"
              (removeAllEvent)="removeAllDirections()"
              (reverseAllEvent)="reverseAllDirections()"
              (cancelEvent)="cancelDirectionSelection()"
              (applyEvent)="applySelectedDirections()"
            ></app-filter-dropdown-buttons>
          </mat-select>
        </mat-form-field>
      </div>
      -->
  <!--      CATEGORY PICKER-->
  <!--
     <div>
       <mat-form-field>
         <mat-label>{{ "categories" | i18n }}</mat-label>
         <mat-select
           panelClass="custom-mat-select-panel"
           #categorySelect
           [formControl]="selectedCategoriesControl"
           [multiple]="true"
           [disableOptionCentering]="true"
           (closed)="cancelCategorySelection()"
         >
           <mat-select-trigger>
             {{ selectedCategoriesControl.value?.length || 0 }} option(s) selected
           </mat-select-trigger>
           <input
             matInput
             class="search-input"
             placeholder="Search Categories"
             (input)="filterCategories($event)"
           />
           <mat-option *ngFor="let category-renderer of filteredCategories" [value]="category-renderer">
             {{ category-renderer.name }}
           </mat-option>
           <mat-divider></mat-divider>
           <app-filter-dropdown-buttons
             (selectAllEvent)="selectAllCategories()"
             (removeAllEvent)="removeAllCategories()"
             (reverseAllEvent)="reverseAllCategories()"
             (cancelEvent)="cancelCategorySelection()"
             (applyEvent)="applySelectedCategories()"
           ></app-filter-dropdown-buttons>
         </mat-select>
       </mat-form-field>
     </div>
      -->
  <!--      CLASSIFICATION PICKER-->
  <!--
      <div>
        <mat-form-field>
          <mat-label>{{ "classifications" | i18n }}</mat-label>
          <mat-select
            panelClass="custom-mat-select-panel"
            #classificationSelect
            [formControl]="selectedClassificationsControl"
            [multiple]="true"
            [disableOptionCentering]="true"
            (closed)="cancelClassificationSelection()"
          >
            <mat-select-trigger>
              {{ selectedClassificationsControl.value?.length || 0 }} option(s) selected
            </mat-select-trigger>
            <input
              matInput
              class="search-input"
              placeholder="Search Classifications"
              (input)="filterClassifications($event)"
            />
            <mat-option
              *ngFor="let classification of filteredClassifications"
              [value]="classification"
            >
              {{ classification.name }}
            </mat-option>
            <mat-divider></mat-divider>
            <app-filter-dropdown-buttons
              (selectAllEvent)="selectAllClassifications()"
              (removeAllEvent)="removeAllClassifications()"
              (reverseAllEvent)="reverseAllClassifications()"
              (cancelEvent)="cancelClassificationSelection()"
              (applyEvent)="applySelectedClassifications()"
            ></app-filter-dropdown-buttons>
          </mat-select>
        </mat-form-field>
      </div>
      -->

  <!--      GRANULARITY PICKER (original)-->
  <!--            <div class="ironfly-primary-graph__filter-item">-->
  <!--              <mat-form-field>-->
  <!--                <mat-label>{{ "granularity" | i18n }}</mat-label>-->
  <!--                <mat-select-->
  <!--                  panelClass="custom-mat-select-panel"-->
  <!--                  #granularitySelect-->
  <!--                  [formControl]="selectedGranularityControl"-->
  <!--                  [multiple]="false"-->
  <!--                  [disableOptionCentering]="true"-->
  <!--                  (selectionChange)="handleUpdateGranularity($event.value)"-->
  <!--                >-->
  <!--                  <mat-option *ngFor="let granularity of granularityOptions" [value]="granularity">-->
  <!--                    {{ granularity }}-->
  <!--                  </mat-option>-->
  <!--                </mat-select>-->
  <!--              </mat-form-field>-->
  <!--            </div>-->
  <!--    </div>-->
  <!--  </div>-->

  <!--  D3 Graph-->
  <div>
    <app-primary-summary-graph-hud
      [dashboardParameters]="dashboardParameters"
      [graphData]="graphData"
      [scenarioData]="scenarioData"
      [selectedBand]="selectedBand"
    >
    </app-primary-summary-graph-hud>
  </div>

  <div id="psgContainer" class="psg-container"></div>
  <div id="psgLegend" class="psg-legend">
    <app-primary-summary-graph-legend
      [dashboardParameters]="dashboardParameters"
      [scenarioData]="scenarioData"
    >
    </app-primary-summary-graph-legend>
  </div>
</div>
