import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class ExpandableCardService {
  panelExpanded: boolean[] = [false];

  async toggleExpand(panel: number) {
    // If the panel's state has not been initialized, initialize it as false (not expanded)
    if (!this.panelExpanded[panel]) {
      this.panelExpanded[panel] = false;
    }

    // Toggle the panel's state
    this.panelExpanded[panel] = !this.panelExpanded[panel];

    return this.panelExpanded[panel];
  }
}
