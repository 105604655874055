<div class="buttons-overlay" *ngIf="this.arrangeState.showSpinner">
  <app-spinner [showSpinner]="true" [diameter]="25"></app-spinner>
</div>
<ng-container *ngIf="vmArrange$ | async as vmArrange">
  <h1 class="preview-nodata" *ngIf="vmArrange.materialTableDataSource?.data.length === 0">
    {{ "importNothingError" | i18n }}
  </h1>

  <mat-form-field class="amount-type-container">
    <mat-label>Use my data as ...</mat-label>
    <mat-select [(ngModel)]="amountType" (selectionChange)="onAmountTypeChange($event)">
      <mat-option *ngFor="let option of amountTypes" [value]="option">
        {{ option.toUpperCase() }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <div class="preview-table-wrapper">
    <mat-card
      *ngIf="vmArrange.materialTableDataSource?.data.length > 0"
      class="preview-table mat-elevation-z2"
      #cardRef
    >
      <mat-card-content
        #cardContentRef
        class="preview-table-content arrange-table fill-available-height"
      >
        <cdk-virtual-scroll-viewport [itemSize]="itemSize" class="table-viewport" scrollWindow>
          <!--        todo move mat-table to a separate component-->
          <table mat-table #table [dataSource]="vmArrange.materialTableDataSource">
            <ng-container
              *ngFor="let column of vmArrange.arrangeTableColumns"
              [matColumnDef]="column.columnDef"
            >
              <th
                mat-header-cell
                *matHeaderCellDef
                class="preview-table-title"
                [ngSwitch]="column.columnDef"
              >
                <app-drop-down-header
                  *ngSwitchDefault
                  [preselect]="column"
                  [selectedOption]="column.mappedHeader"
                  (optionChanged)="handleHeaderChange($event)"
                ></app-drop-down-header>
                <span class="original-header">{{ column.originalHeader }}</span>
              </th>

              <td mat-cell *matCellDef="let row; let i = index">
                {{ column.cell(row) }}
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="vmArrange.displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: vmArrange.displayedColumns"></tr>
          </table>
        </cdk-virtual-scroll-viewport>
      </mat-card-content>
      <mat-card-actions align="end" class="preview-table-actions preview-import">
        <button mat-raised-button color="accent" (click)="previous()">
          {{ "previous" | i18n }}
        </button>
        <ng-container *ngIf="vmUpload$ | async as vmUpload">
          <div class="additional-actions">
            <button
              mat-raised-button
              color="primary"
              [matMenuTriggerFor]="buttonsMenu"
              #actionButtonsTrigger="matMenuTrigger"
              aria-label="Toggle menu"
              (click)="beforeMenuOpens($event)"
            >
              <i class="bwi bwi-caret-down bwi-sm" aria-hidden="true"></i>
            </button>
            <mat-menu #buttonsMenu="matMenu" xPosition="before">
              <div class="buttons-overlay" *ngIf="this.arrangeState.shouldMap">
                <!--<app-spinner [showSpinner]="true" [diameter]="25"></app-spinner>-->
              </div>
              <div
                *ngIf="
                  vmUpload.userDateFormatIndex &&
                  vmUpload.userDateFormatIndex.year === undefined &&
                  !this.arrangeState.showSpinner
                "
                class="action-button-container"
              >
                <button mat-menu-item (click)="takeYearAction()">
                  <mat-icon
                    class="material-icons"
                    *ngIf="vmUpload.userDateFormatIndex.year === undefined"
                    >error</mat-icon
                  >
                  <mat-icon class="material-icons" *ngIf="vmUpload.userDateFormatIndex.year"
                    >check</mat-icon
                  >
                  Missing Year
                  <span class="button-info">Set the the year for transactions...</span>
                </button>
              </div>

              <div class="action-button-container">
                <button
                  mat-menu-item
                  (click)="takeDateFormatAction()"
                  [disabled]="
                    vmUpload.userDateFormatIndex &&
                    vmUpload.userDateFormatIndex.year === undefined &&
                    !this.arrangeState.showSpinner
                  "
                >
                  <mat-icon
                    class="material-icons"
                    *ngIf="
                      !vmUpload.userDateFormatIndex ||
                      vmUpload.userDateFormatIndex?.year === undefined
                    "
                    >error</mat-icon
                  >
                  <mat-icon
                    class="material-icons"
                    *ngIf="
                      vmUpload.userDateFormatIndex &&
                      vmUpload.userDateFormatIndex?.year !== undefined
                    "
                    >check</mat-icon
                  >
                  Date Format
                  <span class="button-info"
                    >Set the date format for the file before proceeding...</span
                  >
                </button>
              </div>

              <div class="action-button-container">
                <button
                  mat-menu-item
                  (click)="takeAccountAction()"
                  [disabled]="
                    !vmUpload.userDateFormatIndex ||
                    (vmUpload.userDateFormatIndex.year === undefined &&
                      !this.arrangeState.showSpinner)
                  "
                >
                  <mat-icon class="material-icons" *ngIf="vmArrange.accountActionNeeded"
                    >error</mat-icon
                  >
                  <mat-icon class="material-icons" *ngIf="!vmArrange.accountActionNeeded"
                    >check</mat-icon
                  >
                  New Accounts
                  <span class="button-info">Save your new accounts before proceeding...</span>
                </button>
              </div>
            </mat-menu>
          </div>
        </ng-container>
        <ng-container *ngIf="vmUpload$ | async as vmUpload">
          <button
            mat-raised-button
            color="primary"
            (click)="next()"
            [disabled]="!vmUpload.userDateFormatIndex || vmArrange.accountActionNeeded"
          >
            <span>{{ "next" | i18n }}</span>
          </button>
        </ng-container>
      </mat-card-actions>
    </mat-card>
  </div>
</ng-container>
