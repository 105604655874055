<div
  class="tw-inset-0 tw-flex tw-overflow-visible"
  role="dialog"
  aria-modal="true"
  aria-labelledby="purgeVaultTitle"
>
  <div class="tw-relative tw-mx-auto tw-w-full" role="document">
    <form
      class="tw-flex tw-flex-col tw-gap-6 tw-bg-white"
      #form
      [appApiAction]="formPromise"
      ngNativeValidate
    >
      <div class="tw-flex md:tw-justify-between">
        <h1 class="tw-text-xl" id="purgeVaultTitle">{{ "purgeVault" | i18n }}</h1>
        <button
          type="button"
          class="close tw-hidden md:tw-flex"
          mat-dialog-close
          aria-label="Close dialog"
          appA11yTitle="{{ 'close' | i18n }}"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="tw-flex tw-flex-col tw-gap-6">
        <p class="tw-mb-0 tw-text-justify">
          {{ (organizationId ? "purgeOrgVaultDesc" : "purgeVaultDesc") | i18n }}
        </p>

        <!--        todo: refactor app-callout-->
        <!--        <app-callout type="warning">{{ "purgeVaultWarning" | i18n }}</app-callout>-->

        <div
          class="tw-flex tw-h-auto tw-w-full tw-min-w-[350px] tw-flex-col tw-gap-2 tw-rounded-lg tw-border-[2px] tw-border-solid tw-border-danger tw-py-2 tw-px-4 md:tw-w-full md:tw-min-w-[300px]"
        >
          <div class="tw-flex tw-items-center tw-gap-2">
            <span class="tw-mb-0">
              <svg class="tw-h-5 tw-w-5">
                <use xlink:href="images/sprite.svg#info-danger" />
              </svg>
            </span>
            <div class="">
              <span class="tw-mb-0 tw-whitespace-nowrap tw-font-semibold tw-text-danger">
                {{ "warning" | i18n }}
              </span>
            </div>
          </div>
          <div>
            <p class="tw-mb-0 tw-text-justify">{{ "purgeVaultWarning" | i18n }}</p>
          </div>
        </div>

        <app-user-verification [(ngModel)]="masterPassword" ngDefaultControl name="secret">
        </app-user-verification>
      </div>
      <div class="tw-flex tw-justify-around tw-gap-6 md:tw-flex-col md:tw-gap-4">
        <app-gloss-button
          class="tw-w-full"
          [options]="{
            text: 'purgeVault' | i18n,
            type: 'submit',
            class: 'danger',
            onClick: submit.bind(this),
            isEnabled: !form.loading
          }"
        ></app-gloss-button>

        <app-gloss-button
          type="button"
          mat-dialog-close
          aria-label="Close dialog"
          class="tw-w-full"
          [options]="{
            text: 'close' | i18n,
            class: 'neutral',
            isEnabled: true
          }"
        ></app-gloss-button>
      </div>
    </form>
  </div>
</div>
