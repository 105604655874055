import * as d3 from "d3";

import { GraphDataSet } from "@bitwarden/web-vault/app/models/types/graph.types";
import { ScenarioData } from "@bitwarden/web-vault/app/models/types/scenario-group.types";

export class DateTimeFormatter {
  /** Holds the granularity that user has selected and applied to the graph */
  private selectedGranularity: string;

  formatYearDate: (date: Date) => string;
  formatDisplayDate: (date: Date) => string;
  timeParse: (dateString: string) => Date;

  constructor(granularity: string) {
    this.selectedGranularity = granularity;
    this.initDateFormatters();
  }

  updateGranularity(granularity: string) {
    this.selectedGranularity = granularity;
    this.initGranularityFormatters();
  }

  parseDates(
    graphDataSet: GraphDataSet[],
    scenarioData?: ScenarioData
  ): Array<{ mY: string; display: string }> {
    if (!graphDataSet || graphDataSet.length === 0) {
      if (scenarioData?.balance) {
        graphDataSet = scenarioData.balance;
      }
    }
    const parsedDates = graphDataSet.map((graphData) => {
      return {
        mY: this.formatYearDate(this.timeParse(graphData.date)),
        display: this.formatDisplayDate(this.timeParse(graphData.date)),
      };
    });

    const dateSet = new Set(parsedDates.map((dateData) => dateData.mY));

    if (scenarioData && scenarioData?.scenario.length > 0) {
      for (const scenario of scenarioData.scenario) {
        if (scenario.graphData) {
          // check if the date is already in parseDates array
          for (const graphData of scenario.graphData) {
            const mY = this.formatYearDate(this.timeParse(graphData.date));
            if (!dateSet.has(mY)) {
              const display = this.formatDisplayDate(this.timeParse(graphData.date));
              parsedDates.push({ mY, display });
              dateSet.add(mY);
            }
          }
        }
      }
    }
    return parsedDates;
  }

  private initDateFormatters() {
    this.initGranularityFormatters();
    this.timeParse = d3.timeParse("%Y%m%d");
  }

  private initGranularityFormatters() {
    this.formatYearDate = this.getFormatterForGranularity(this.selectedGranularity);
    this.formatDisplayDate = this.getFormatterForGranularity(this.selectedGranularity);
  }

  private getFormatterForGranularity(granularity: string) {
    switch (granularity) {
      case "day":
        return d3.timeFormat("%b %d, %Y");
      case "week":
        return d3.timeFormat("%Y-W%V"); // Adjusted for week granularity
      case "month":
        return d3.timeFormat("%b %Y");
      case "quarter":
        return (date: Date) => {
          const quarter = Math.floor(date.getMonth() / 3 + 1);
          const year = date.getFullYear();
          return `Q${quarter} ${year}`;
        };
      case "year":
        return d3.timeFormat("%Y");
      default:
        return d3.timeFormat("%b %Y"); // Default to month format
    }
  }
}
