import { LogService } from "@bitwarden/common/abstractions/log.service";
import { currencies } from "@bitwarden/web-vault/app/gloss/settings/manage-preferences/currencies";
import { ReferenceData } from "@bitwarden/web-vault/app/models/data/blobby/reference-data.data";
import {
  NormalizationProperties,
  NormalizationRates,
} from "@bitwarden/web-vault/app/models/types/normalization-types";
import { ReferenceDataCalculationClass } from "@bitwarden/web-vault/app/services/DataCalculationService/reference-data/reference-data-calculation.class";
import { ReferenceDataService } from "@bitwarden/web-vault/app/services/DataService/reference-data/reference-data.service";

export class SymbolConversion {
  private _baseCurrency: string;
  protected logService: LogService;
  private referenceDataCalculation: ReferenceDataCalculationClass;

  constructor(
    baseCurrency: string,
    logger: LogService,
    referenceData?: ReferenceData[],
    referenceDataService?: ReferenceDataService
  ) {
    this._baseCurrency = baseCurrency;
    this.logService = logger;
    this.referenceDataCalculation = new ReferenceDataCalculationClass(
      this.logService,
      referenceDataService,
      referenceData
    );
  }

  private isCurrency(symbol: string) {
    return currencies.includes(symbol);
  }

  set baseCurrency(currency: string) {
    this._baseCurrency = currency;
  }

  /**
   * Return the calculated normalizingRates data for symbols.
   * The rate supplied to the function is the rate to get from the symbol to the base, but if the symbol is an equity
   * and not a currency, then we need to split the rate into two parts, one for the symbol and the rest for the currency
   * normalization.
   * @param symbol
   * @param base
   * @param date
   * @param rate
   * @private
   */
  private async checkForSymbolRate(
    symbol: string,
    base: string,
    date: number,
    rate: number
  ): Promise<NormalizationRates> {
    const normalizedRates: NormalizationRates = {
      normalizingRate: rate,
    };
    if (this.isCurrency(symbol)) {
      // add in the valueCurrency if it's a currency
      normalizedRates.valueCurrency = symbol;
      return normalizedRates;
    } else {
      // look for the symbol as base rate
      let symbolReferenceData = await this.referenceDataCalculation.getReferenceForBaseDate(
        symbol,
        date
      );
      if (symbolReferenceData) {
        const possibleCurrencies = Object.keys(symbolReferenceData.symbols);
        let symbolRate;
        let valueCurrency;
        if (possibleCurrencies.includes(base)) {
          symbolRate = symbolReferenceData.symbols[base];
          valueCurrency = base;
        } else {
          // take the first currency as the one we are using
          symbolRate = symbolReferenceData.symbols[possibleCurrencies[0]];
          valueCurrency = possibleCurrencies[0];
        }
        const updatedRate = rate / symbolRate;

        normalizedRates.symbolValue = symbolRate;
        normalizedRates.valueCurrency = valueCurrency;
        normalizedRates.normalizingRate = updatedRate;
        return normalizedRates;
      }
      // look for the symbol as a symbol in reference data
      symbolReferenceData = await this.referenceDataCalculation.getReferenceForSymbolDate(
        symbol,
        date
      );

      // try to get the symbol rate in the currency of the transaction
      let symbolRate;
      let valueCurrency;
      if (symbolReferenceData) {
        symbolRate = symbolReferenceData.symbols[symbol];
        valueCurrency = symbolReferenceData.base;
        symbolRate = 1 / symbolRate;

        const updatedRate = rate / symbolRate;

        normalizedRates.symbolValue = symbolRate;
        normalizedRates.valueCurrency = valueCurrency;
        normalizedRates.normalizingRate = updatedRate;
      }
      return normalizedRates;
    }
  }

  /**
   * Rate to convert 1 of symbol into the supplied base currency base
   * @param symbol
   * @param base
   * @param date
   */
  async getConversionRate(symbol: string, base: string, date: number): Promise<number> {
    if (!base && this._baseCurrency) {
      base = this._baseCurrency;
    }

    // if symbol is the same as the base, then the rate is 1
    if (symbol === base) {
      return 1;
    }

    const baseSymbolData = await this.referenceDataCalculation.getReferenceData(symbol, base, date);

    if (baseSymbolData && baseSymbolData.length > 0) {
      const rate = 1 / baseSymbolData[0].symbols[symbol];
      return rate;
    }

    const reverseSymbolData = await this.referenceDataCalculation.getReferenceData(
      base,
      symbol,
      date
    );

    if (reverseSymbolData && reverseSymbolData.length > 0) {
      const rate = reverseSymbolData[0].symbols[base];
      return rate;
    }

    // if there was no match for the symbol to the base in the reference data, then try to create reference
    // data for the two using a global reserve currency
    const reserveRate = await this.getReferenceDataFromReserveCurrency(symbol, base, date);
    if (reserveRate) {
      return reserveRate;
    }

    // if can't get a match joined on reserve currency, try to find any currency for symbol and then convert to base
    // using global reserve
    const inferredRate = await this.getAnyMatchToCurrency(symbol, base, date);
    if (inferredRate) {
      return inferredRate;
    }

    return null;
  }

  async getReferenceDataFromReserveCurrency(
    symbol: string,
    base: string,
    date: number
  ): Promise<number> {
    const symbolData = await this.referenceDataCalculation.getReferenceDataFromReserveCurrency(
      symbol,
      base,
      date
    );

    if (symbolData.length > 0) {
      const rate = symbolData[0].symbols[symbol];
      return rate;
    }
    return null;
  }

  async getAnyMatchToCurrency(symbol: string, base: string, date: number): Promise<number> {
    const symbolData = await this.referenceDataCalculation.getReferenceForBaseDate(symbol, date);

    if (symbolData) {
      // using the symbol base and the base we want, see if we can match it on reserve currency
      // const symbolBase = symbolData.base;
      const possibleSymbols = Object.keys(symbolData.symbols);

      // go through each possible symbol and try to find a way to get to the base
      for (const possibleSymbol of possibleSymbols) {
        const symbolRate = symbolData.symbols[possibleSymbol];
        // try to get there directly
        const directSymbolData = await this.referenceDataCalculation.getReferenceData(
          possibleSymbol,
          base,
          date
        );

        let currencyRate;
        // check for a direct match
        if (directSymbolData.length > 0) {
          currencyRate = directSymbolData[0].symbols[possibleSymbol];
          const finalRate = symbolRate / currencyRate;
          return finalRate;
        }
        // check for a direct reverse match
        const reverseSymbolData = await this.referenceDataCalculation.getReferenceData(
          base,
          possibleSymbol,
          date
        );
        if (reverseSymbolData.length > 0) {
          currencyRate = reverseSymbolData[0].symbols[base];
          const finalRate = symbolRate * currencyRate;
          return finalRate;
        }
        // check for a reserve currency match
        currencyRate = await this.getReferenceDataFromReserveCurrency(possibleSymbol, base, date);
        if (currencyRate) {
          const finalRate = symbolRate * currencyRate;
          return finalRate;
        }
        /*
        // Shouldn't need to find reverser of reserve as this is already covered in that function
        // check for a reverse reserve currency match
        currencyRate = await this.getReferenceDataFromReserveCurrency(base, possibleSymbol, date);
        if (currencyRate) {
          const finalRate = symbolRate / currencyRate;
          return finalRate;
        }
         */
      }
    }
    return null;
  }

  /**
   * Given a quantity and a symbol, work out the normalised value for a given date using market data.
   * Normalisation is calculated to the base currency unless a different currency is supplied as parameter.
   *
   * @param quantity
   * @param date
   * @param symbol
   * @param currency
   */
  async normalizeQuantity(
    quantity: number,
    date: Date,
    symbol: string,
    currency?: string
  ): Promise<NormalizationProperties> {
    // normalize to the base currency if the to symbol is not supplied
    // await this.setBaseCurrency();
    if (!currency) {
      currency = this._baseCurrency;
    }

    let normalizedValue = quantity;

    const normalizedProperties: NormalizationProperties = {
      value: quantity,
      normalizedValue: normalizedValue,
      valueCurrency: symbol,
      normalizedCurrency: currency,
    };

    const unixDate = date.getTime();
    let rate = await this.getConversionRate(symbol, currency, unixDate);

    if (rate === null) {
      const inverseRate = await this.getConversionRate(currency, symbol, unixDate);
      if (inverseRate) {
        rate = 1 / inverseRate;
      }
    }

    if (rate) {
      normalizedValue = normalizedValue * rate;
      const normalizedProperties: NormalizationProperties = {
        value: quantity,
        normalizingRate: rate,
        normalizedValue: normalizedValue,
        normalizedCurrency: currency,
      };
      const normalizedRates = await this.checkForSymbolRate(symbol, currency, unixDate, rate);
      Object.assign(normalizedProperties, normalizedRates);

      if (normalizedRates.symbolValue) {
        const newValue = quantity * normalizedRates.symbolValue;
        normalizedProperties.value = newValue;
      }

      return normalizedProperties;
    } else {
      this.logService.warning(
        "No normalisation on symbol " +
          symbol +
          " because symbol does not exist in the reference data"
      );
    }
    return normalizedProperties;
  }
}
