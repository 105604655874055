import { Injectable, Pipe, PipeTransform } from "@angular/core";

import { SplitCategoryType } from "@bitwarden/web-vault/app/models/types/split-category-type";
import { CategoryService } from "@bitwarden/web-vault/app/services/DataService/category/category.service";
@Pipe({
  name: "categoryPipe",
})
@Injectable({
  providedIn: "root",
})
export class CategoryPipe implements PipeTransform {
  constructor(private categoryService: CategoryService) {}

  async transform(value: any, method: string, args?: any[]) {
    if (method === "getCategoryName") {
      return this.getCategoryName(value);
    }

    if (method === "getCategoriesNamesList") {
      return this.getCategoriesNamesList(value);
    }

    return value;
  }

  async getCategoryName(id: string): Promise<string> {
    const book = await this.categoryService.get(id);
    return book.name;
  }

  async getCategoriesNamesList(ids: SplitCategoryType[]): Promise<string> {
    let idsList = "";
    const promises = ids.map(async (value, index) => {
      const category = await this.categoryService.get(value.categoryId);
      if (category) {
        idsList += index === ids.length - 1 ? category.name : category.name + ", ";
      }
    });

    await Promise.all(promises);
    return idsList;
  }
}
