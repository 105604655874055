import { inject, PLATFORM_ID } from "@angular/core";
import { DeviceDetectorService, DeviceInfo, DeviceType } from "ngx-device-detector";

import { HelperCommon } from "@bitwarden/web-vault/app/shared/utils/helper.common";

import { DeviceOS } from "../../../app/models/enum/device-detector.enum";

export interface DeviceDetection {
  getDeviceInfo(): DeviceInfo;
  isMobile(): boolean;
  isTablet(): boolean;
  isDesktop(): boolean;
  isIphoneDevice(): boolean;
  isIpadDevice(): boolean;
}

/**
 * Class for detecting device information and type.
 * Implements the DeviceDetection interface.
 */
export class DeviceDetector implements DeviceDetection {
  private platformId = inject(PLATFORM_ID);
  private deviceDetectorService: DeviceDetectorService;

  /**
   * Constructor for DeviceDetector.
   * Initializes the DeviceDetectorService with the platform ID.
   */
  constructor() {
    this.deviceDetectorService = new DeviceDetectorService(this.platformId);
  }

  /**
   * Retrieves information about the device.
   * @returns {DeviceInfo} An object containing device information.
   */
  getDeviceInfo(): DeviceInfo {
    return this.deviceDetectorService.getDeviceInfo();
  }

  /**
   * Checks if the device is a mobile device.
   * @returns {boolean} True if the device is a mobile device, false otherwise.
   */
  isMobile(): boolean {
    return this.deviceDetectorService.isMobile();
  }

  /**
   * Checks if the device is a tablet.
   * @returns {boolean} True if the device is a tablet, false otherwise.
   */
  isTablet(): boolean {
    return this.deviceDetectorService.isTablet();
  }

  /**
   * Checks if the device is a desktop.
   * @returns {boolean} True if the device is a desktop, false otherwise.
   */
  isDesktop(): boolean {
    return this.deviceDetectorService.isDesktop();
  }

  /**
   * Checks if the device is an iPhone.
   * @returns {boolean} True if the device is an iPhone, false otherwise.
   */
  isIphoneDevice(): boolean {
    const { os, deviceType } = this.getDeviceInfo();
    return os === DeviceOS.iOS && deviceType === DeviceType.Mobile;
  }

  isIpadDevice(): boolean {
    const { os, deviceType } = this.getDeviceInfo();
    return os === DeviceOS.iOS && deviceType === DeviceType.Tablet;
  }

  isLocalMobileDevelopment(): boolean {
    return HelperCommon.isLocal() && navigator.userAgent.indexOf("Mobi") > -1;
  }
}
