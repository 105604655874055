import { SymbolInfoResponse } from "../../../../../../libs/common/src/models/response/symbol-info.response";

export class SymbolInfoData {
  private readonly __v = 1;
  get id(): string {
    return this.symLocal;
  }

  private _dateModified: string;
  private _dateCreated: string;
  private _id: string;

  symLocal: string; // the symbol use on this local instance, the main key for all queries around the system
  symGloss: string; // the Gloss symbol used for retrieving data from external data - the main symbology used, and key should ideally match this
  valuedIn: string; // what this symbol is valued in (usually a currency)
  mic: string; // if this symbol is listed on an exchange
  name: string;
  description: string;

  constructor(response: SymbolInfoResponse) {
    if (response == null) {
      return;
    }
    this._dateCreated = new Date().toUTCString();
    this._dateModified = new Date().toUTCString();
    this._id = response.id ? response.id : crypto.randomUUID();

    this.symLocal = response.symLocal;
    this.symGloss = response.symGloss;
    this.valuedIn = response.valuedIn;
    this.mic = response.mic;
    this.name = response.name;
    this.description = response.description;
  }
}
/*
export function getSymbolInfo(symbol: string): SymbolInfoData {
  const sym = new SymbolInfoData();
  sym.symLocal = symbol;
  sym.symGloss = symbol;
  if (isCurrency(symbol)) {
    sym.valuedIn = symbol;
  } else if (isAuEquity(symbol)) {
    sym.valuedIn = "AUD";
    sym.mic = "XASX";
  }
  return sym;
}

function isCurrency(symbol: string): boolean {
  const currencies = [
    "AED",
    "AFN",
    "ALL",
    "AMD",
    "ANG",
    "AOA",
    "ARS",
    "AUD",
    "AWG",
    "AZN",
    "BAM",
    "BBD",
    "BDT",
    "BGN",
    "BHD",
    "BIF",
    "BMD",
    "BND",
    "BOB",
    "BRL",
    "BSD",
    "BTC",
    "BTN",
    "BWP",
    "BYN",
    "BYR",
    "BZD",
    "CAD",
    "CDF",
    "CHF",
    "CLF",
    "CLP",
    "CNY",
    "COP",
    "CRC",
    "CUC",
    "CUP",
    "CVE",
    "CZK",
    "DJF",
    "DKK",
    "DOP",
    "DZD",
    "EGP",
    "ERN",
    "ETB",
    "EUR",
    "FJD",
    "FKP",
    "GBP",
    "GEL",
    "GGP",
    "GHS",
    "GIP",
    "GMD",
    "GNF",
    "GTQ",
    "GYD",
    "HKD",
    "HNL",
    "HRK",
    "HTG",
    "HUF",
    "IDR",
    "ILS",
    "IMP",
    "INR",
    "IQD",
    "IRR",
    "ISK",
    "JEP",
    "JMD",
    "JOD",
    "JPY",
    "KES",
    "KGS",
    "KHR",
    "KMF",
    "KPW",
    "KRW",
    "KWD",
    "KYD",
    "KZT",
    "LAK",
    "LBP",
    "LKR",
    "LRD",
    "LSL",
    "LTL",
    "LVL",
    "LYD",
    "MAD",
    "MDL",
    "MGA",
    "MKD",
    "MMK",
    "MNT",
    "MOP",
    "MRO",
    "MUR",
    "MVR",
    "MWK",
    "MXN",
    "MYR",
    "MZN",
    "NAD",
    "NGN",
    "NIO",
    "NOK",
    "NPR",
    "NZD",
    "OMR",
    "PAB",
    "PEN",
    "PGK",
    "PHP",
    "PKR",
    "PLN",
    "PYG",
    "QAR",
    "RON",
    "RSD",
    "RUB",
    "RWF",
    "SAR",
    "SBD",
    "SCR",
    "SDG",
    "SEK",
    "SGD",
    "SHP",
    "SLE",
    "SLL",
    "SOS",
    "SRD",
    "STD",
    "SVC",
    "SYP",
    "SZL",
    "THB",
    "TJS",
    "TMT",
    "TND",
    "TOP",
    "TRY",
    "TTD",
    "TWD",
    "TZS",
    "UAH",
    "UGX",
    "USD",
    "UYU",
    "UZS",
    "VEF",
    "VES",
    "VND",
    "VUV",
    "WST",
    "XAF",
    "XAG",
    "XAU",
    "XCD",
    "XDR",
    "XOF",
    "XPF",
    "YER",
    "ZAR",
    "ZMK",
    "ZMW",
    "ZWL",
  ];
  return currencies.includes(symbol);
}

function isAuEquity(symbol: string): boolean {
  const xasx = ["XASX-BHP", "XASX-CBA", "XASX-NAB"];
  return xasx.includes(symbol);
}
*/
