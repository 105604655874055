import { ModelValidator } from "@bitwarden/web-vault/app/models/data/blobby/model.validator";

import { EstimateActionsEnum } from "../../enum/estimate-actions.enum";
import { ScenarioOptionsType } from "../../types/scenario-group.types";
import { ScenarioResponse } from "../response/scenario.response";

import { EstimateActionData } from "./estimate.action.data";

export class Scenario extends ModelValidator {
  private readonly __v = 1;
  _id: string;
  _dateModified: string;
  _dateCreated: string;
  name: string;
  symbol: string;
  scenarioType: ScenarioOptionsType;
  source: string;
  estimateActions: EstimateActionData[];
  estimateActionTypes: EstimateActionsEnum[];

  get id(): string {
    return this._id;
  }

  constructor(response: ScenarioResponse) {
    super();
    if (response == null) {
      return;
    }

    this.checkVersion(this.__v, response.__v);
    this._id = response.id ? response.id : crypto.randomUUID();
    this._dateModified = new Date().toUTCString();
    this._dateCreated = new Date().toUTCString();
    this.name = response.name;
    this.estimateActions = response.estimateActions;
    this.scenarioType = response.scenarioType;
    this.source = response.source;
    this.estimateActionTypes = response.estimateActionTypes;
  }
}
