export class HelperTooltip {
  isClicked = false;
  showHelpTooltip = false; // for showing actual tooltip content
  xPosition = 0;
  yPosition = 0;

  toggle() {
    this.isClicked = !this.isClicked;
  }

  close() {
    this.showHelpTooltip = false;
    this.isClicked = false;
  }

  show(e: MouseEvent) {
    e.stopPropagation();
    this.showHelpTooltip = true;
    this.getPosition(e);
  }

  getPosition(e: MouseEvent) {
    const target = e.currentTarget as HTMLElement;
    if (!target) {
      return { xPosition: 0, yPosition: 0 };
    }

    const targetRect = target.getBoundingClientRect();
    const trianglePointerOffset = 50;
    const trianglePointerHeight = 16;

    this.xPosition = targetRect.right + trianglePointerOffset;
    this.yPosition = targetRect.bottom + trianglePointerHeight;
  }
}
