import { Injectable } from "@angular/core";

import { Preference } from "@bitwarden/web-vault/app/models/data/blobby/preference.data";
import { PreferenceResponse } from "@bitwarden/web-vault/app/models/data/response/preference.response";
import {
  UserLocationCurrencyEnum,
  UserLocationEnum,
} from "@bitwarden/web-vault/app/models/enum/user-location.enum";
import { InstitutionService } from "@bitwarden/web-vault/app/services/DataService/institution/institution.service";

import { PreferenceService } from "../DataService/preference/preference.service";

@Injectable({
  providedIn: "root",
})
export class UserLocationService {
  constructor(
    private preferenceService: PreferenceService,
    private institutionService: InstitutionService
  ) {}

  getBanks(country: string): string[] {
    const banks: { [key: string]: string[] } = {
      AU: ["cba", "anz", "nab"],
      US: ["wfo", "ctg", "boa"],
      HK: ["hsbc", "scb", "boc"],
    };

    return banks[country];
  }

  /**
   * Template Pattern Method that will be executed after selecting the user location
   *
   * @param location
   */
  async processUserLocation(location: string) {
    this.preferenceService.setInProgress(true);
    const preference = <Preference>await this.preferenceService.getAll();
    const { AU, HK } = UserLocationCurrencyEnum;

    const updatedPreference = { ...preference, _id: preference.id };

    updatedPreference.userLocation = <UserLocationEnum>location;
    updatedPreference.baseCurrency = location === "AU" ? AU : HK;

    await Promise.all([
      this.institutionService.loadInstitution(location),
      this.preferenceService.update(new Preference(new PreferenceResponse(updatedPreference))),
    ]);

    this.preferenceService.setInProgress(false);
    this.preferenceService.setUserLocation(true);
  }
}
