import { Component } from "@angular/core";

import { IMainProgressBar } from "../../../models/interfaces/main-progress-bar";
import { MainProgressBar } from "../../../services/progress-bar/main-progress-bar";

@Component({
  selector: "main-progress-bar",
  templateUrl: "/main-progress-bar.component.html",
  styles: ["main-progress-bar.scss"],
})
export class MainProgressBarComponent {
  progressbar: IMainProgressBar;

  constructor(protected mainProgressBar: MainProgressBar) {
    this.progressbar = mainProgressBar;
  }
}
