import { View } from "@bitwarden/common/models/view/view";
import { Book } from "@bitwarden/web-vault/app/models/data/blobby/book.data";
import { Transaction } from "@bitwarden/web-vault/app/models/data/blobby/transaction.data";
import { GlossDate } from "@bitwarden/web-vault/app/models/data/shared/gloss-date";
import { DetailedBalance } from "@bitwarden/web-vault/app/models/types/balanceGroupingTypes";
import { TransactionColumnSortable } from "@bitwarden/web-vault/app/models/view/transaction-column-sortable";

import { Allocation } from "../../models/data/allocation.data";
import { GlossBalance } from "../../models/data/shared/gloss-balance";
import { GlossQuantity } from "../../models/data/shared/gloss-quantity";
import { Valuation } from "../../models/data/valuation.data";
import { TransactionDirection } from "../../models/enum/transactionDirection";
import { SplitCategoryType } from "../../models/types/split-category-type";
import { SplitClassificationType } from "../../models/types/split-classification-type";

export class TransactionView extends TransactionColumnSortable implements View {
  private readonly _sourceId: string;
  private readonly _accountId: string;
  private readonly _allocations: Array<Allocation>;
  private readonly _bankImportedBalance: number;
  private readonly _categories: SplitCategoryType[] = [];
  private readonly _classifications: SplitClassificationType[] = [];
  private readonly _description: string;
  private readonly _direction: TransactionDirection;
  private readonly _valuationPrice: number;
  private readonly _balance: GlossBalance;
  private readonly _dateCreated: string;
  private readonly _dateModified: string;
  private readonly _estimateTransaction: boolean;
  private readonly _id: string;
  private readonly _isUsingValuationPrice: boolean;
  private readonly _linkedTo: Array<string> = [];
  private readonly _quantity: GlossQuantity;
  private readonly _revalTransaction: boolean;
  private readonly _valuation: Valuation;
  private _transactionDate: Date;
  private _dailyBalance: number;
  private _detailedBalance: DetailedBalance;
  private _mockAccountName: string;
  private _baseTransaction: Transaction;
  private _isSelected = false;
  private _glossDate: GlossDate;

  constructor(transaction: Transaction) {
    super();
    if (!transaction) {
      return;
    }

    this._sourceId = transaction.sourceId;
    this._baseTransaction = transaction;
    this._accountId = transaction.accountId;
    this._allocations = transaction.allocations;
    this._bankImportedBalance = transaction.bankImportedBalance;
    this._categories = transaction.categories;
    this._classifications = transaction.classifications;
    this._description = transaction.description;
    this._direction = transaction.direction;
    this._valuationPrice = transaction.valuationPrice;
    this._balance = transaction.balance;
    this._dateCreated = transaction.dateCreated;
    this._dateModified = transaction.dateModified;
    this._estimateTransaction = transaction.estimateTransaction;
    this._id = transaction.id;
    this._isUsingValuationPrice = transaction.isUsingValuationPrice;
    this._linkedTo = transaction.linkedTo;
    this._quantity = transaction.quantity;
    this._revalTransaction = transaction.revalTransaction;
    this._transactionDate = transaction.transactionDate.date;
    this._glossDate = transaction.transactionDate;
    this._valuation = transaction.valuation;
  }

  get isSelected(): boolean {
    return this._isSelected;
  }

  set isSelected(isSelected: boolean) {
    this._isSelected = isSelected;
  }

  get baseTransaction(): Transaction {
    return this._baseTransaction;
  }

  get accountId(): string {
    return this._accountId;
  }

  get sourceId(): string {
    return this._sourceId;
  }

  get allocations(): Array<Allocation> {
    return this._allocations;
  }

  get bankImportedBalance(): number {
    return this._bankImportedBalance;
  }

  get categories(): SplitCategoryType[] {
    return this._categories;
  }

  get classifications(): SplitClassificationType[] {
    return this._classifications;
  }

  get description(): string {
    return this._description;
  }

  get direction(): TransactionDirection {
    return this._direction;
  }

  get valuationPrice(): number {
    return this._valuationPrice;
  }

  get balance(): GlossBalance {
    return this._balance;
  }

  get dateCreated(): string {
    return this._dateCreated;
  }

  get dateModified(): string {
    return this._dateModified;
  }

  get estimateTransaction(): boolean {
    return this._estimateTransaction;
  }

  get id(): string {
    return this._id;
  }

  get isUsingValuationPrice(): boolean {
    return this._isUsingValuationPrice;
  }

  get linkedTo(): Array<string> {
    return this._linkedTo;
  }

  get quantity(): GlossQuantity {
    return this._quantity;
  }

  get transactionDate(): Date {
    return this._transactionDate;
  }

  get glossDate(): GlossDate {
    return this._glossDate;
  }

  get revalTransaction(): boolean {
    return this._revalTransaction;
  }

  get valuation(): Valuation {
    return this._valuation;
  }

  get dailyBalance(): number {
    return this._dailyBalance;
  }

  set dailyBalance(value: number) {
    this._dailyBalance = value;
  }

  get detailedBalance(): DetailedBalance {
    return this._detailedBalance;
  }

  set detailedBalance(value: DetailedBalance) {
    this._detailedBalance = value;
  }

  get mockAccountName(): string {
    return this._mockAccountName;
  }

  set mockAccountName(mockAccountName: string) {
    this._mockAccountName = mockAccountName;
  }

  setMockAccount(mockAccounts: Array<Book>) {
    const mockAccount = mockAccounts.find((account) => account.id == this.accountId);
    if (mockAccount) {
      this.mockAccountName = mockAccount.name;
      this.setSortableFieldsFromMockAccount();
    }
  }

  setSortableFieldsFromMockAccount() {
    if (!this.accountName) {
      this.accountName = this.mockAccountName;
    }
    if (!this.institutionName) {
      this.institutionName = this.mockAccountName;
    }
    if (!this.productName) {
      this.productName = this.mockAccountName;
    }
  }

  update(transaction: Transaction): TransactionView {
    return new TransactionView(transaction);
  }
}
