<form #form [appApiAction]="formPromise" ngNativeValidate>
  <div
    class="tw-mx-auto tw-flex tw-max-w-lg tw-flex-col tw-items-center tw-justify-center tw-py-8 tw-px-8 xs:tw-px-2"
  >
    <div class="mb-4 tw-text-center">
      <i class="bwi bwi-lock bwi-4x text-muted" aria-hidden="true"></i>
    </div>
    <p class="tw-mb-4 tw-text-center tw-text-xl">
      {{ "yourVaultIsLocked" | i18n }} <br />
      {{ "verifyPassword" | i18n }}
    </p>
    <div
      class="tw-border-secondary-300 tw-mt-3 tw-flex tw-flex-col tw-rounded-3xl tw-bg-background tw-p-6 tw-shadow-xl xs:tw-flex-none"
    >
      <label for="masterPassword">
        {{ "masterPass" | i18n }}
        <span class="tw-text-xs tw-font-normal">(required)</span>
      </label>
      <div class="tw-flex">
        <input
          id="masterPassword"
          type="{{ showPassword ? 'text' : 'password' }}"
          name="MasterPassword"
          class="tw-w-full"
          [(ngModel)]="masterPassword"
          appAutofocus
          appInputVerbatim
        />
        <button
          type="button"
          class="tw-flex tw-items-center tw-rounded tw-border"
          appA11yTitle="{{ 'toggleVisibility' | i18n }}"
          (click)="togglePassword()"
        >
          <i
            class="bwi"
            aria-hidden="true"
            [ngClass]="{ 'bwi-eye': !showPassword, 'bwi-eye-slash': showPassword }"
          ></i>
        </button>
      </div>
      <small class="tw-mt-2">{{ "loggedInAsEmailOn" | i18n: email:webVaultHostname }}</small>
      <hr />
      <div class="tw-flex tw-gap-2 xs:tw-flex-col">
        <app-gloss-button
          class="tw-w-1/2"
          [options]="{
            type: 'submit',
            text: 'unlock' | i18n,
            onClick: submit.bind(this),
            isEnabled: !form.loading
          }"
        >
        </app-gloss-button>
        <app-gloss-button
          class="tw-w-1/2"
          [options]="{
            type: 'submit',
            class: 'neutral',
            text: 'logOut' | i18n,
            onClick: logOut.bind(this),
            isEnabled: !form.loading
          }"
        >
        </app-gloss-button>
      </div>
    </div>
  </div>
</form>
