import { Injectable, Pipe, PipeTransform } from "@angular/core";

import { BookService } from "../services/DataService/book/book.service";
@Pipe({
  name: "bookPipe",
})
@Injectable({
  providedIn: "root",
})
export class BookPipe implements PipeTransform {
  constructor(private bookService: BookService) {}

  async transform(value: any, method: string, args?: any[]) {
    if (method === "getBookName") {
      if (args && args.length > 0) {
        return this.getBookName(value, args[0]);
      }
      return this.getBookName(value);
    }
    return value;
  }

  async getBookName(id: string, mockAccountName?: string): Promise<string> {
    const book = await this.bookService.get(id);
    if (!mockAccountName) {
      mockAccountName = "";
    }
    return book ? book.name : mockAccountName;
  }
}
