import { Subject } from "rxjs";

import {
  BlobbyDataTypeEnum,
  BlobbyDataTypeNameEnum,
} from "@bitwarden/web-vault/app/models/enum/blobbyDataTypeEnum";

export interface ProgressBarInterface {
  show(): void;
  processItems(type: string): void;
}

export class BaseProgressBar {
  protected unsubscribe$ = new Subject<void>();

  totalItems: number;
  loadingPercent: number;
  importingLabel: string;

  constructor(loadingPercent?: number, importingLabel?: string, totalItems?: number) {
    this.loadingPercent = loadingPercent;
    this.importingLabel = importingLabel;
    this.totalItems = totalItems;
  }

  calculatePercentage(value: number, totalItems?: number): number {
    if (totalItems) {
      this.totalItems = totalItems;
    }
    return Math.round(this.totalItems === 0 ? 0 : (value / this.totalItems) * 100);
  }

  labelResponse(label: string): string {
    if (label === BlobbyDataTypeEnum.ReferenceData) {
      return "exchange rates and prices";
    } else {
      const readableLAbe = BlobbyDataTypeNameEnum[label as keyof typeof BlobbyDataTypeNameEnum];
      return (readableLAbe || label).toLowerCase();
    }
  }

  setLabel(type: string) {
    const label = this.labelResponse(type);
    if (label) {
      return (this.importingLabel = "Saving " + this.labelResponse(type) + "...");
    } else {
      return (this.importingLabel = "");
    }
  }
}
