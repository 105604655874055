import { Injectable } from "@angular/core";
import { ComponentStore } from "@ngrx/component-store";
import { Observable } from "rxjs";

import { GlobalService } from "@bitwarden/common/services/global/global.service";
import { BasiqType } from "@bitwarden/web-vault/app/gloss/import/import-type/basiq.import-type";
import { CsvType } from "@bitwarden/web-vault/app/gloss/import/import-type/csv.import-type";
import { PlaidType } from "@bitwarden/web-vault/app/gloss/import/import-type/plaid.import-type";
import { MappingEngine } from "@bitwarden/web-vault/app/importers/data-mapper/mapping-engine";
import {
  AlignmentMappingItem,
  MappingConfigurationItem,
} from "@bitwarden/web-vault/app/importers/data-mapper/mapping-engine-types";
import { ImportArrangeInterface } from "@bitwarden/web-vault/app/importers/store/arrange.import.strore";
import { ImportInstitutionsInterface } from "@bitwarden/web-vault/app/importers/store/institution.import.store";
import { Institution } from "@bitwarden/web-vault/app/models/data/blobby/institution.data";
import { Transaction } from "@bitwarden/web-vault/app/models/data/blobby/transaction.data";
import {
  ImportStepsEnum,
  TransactionImportType,
} from "@bitwarden/web-vault/app/models/enum/transactionImportType";
import { UnrecognizedAccount } from "@bitwarden/web-vault/app/models/types/account.types";
import { DateFormatIndex, ImportSteps } from "@bitwarden/web-vault/app/models/types/general-types";
import {
  ImportState,
  ImportType,
  ProcessStatus,
  TransactionBalanceAlignmentMap,
} from "@bitwarden/web-vault/app/models/types/import.types";
import { DataRepositoryService } from "@bitwarden/web-vault/app/services/DataRepository/data-repository.service";
import { BookService } from "@bitwarden/web-vault/app/services/DataService/book/book.service";
import { InstitutionService } from "@bitwarden/web-vault/app/services/DataService/institution/institution.service";

export interface ImportFlow {
  step: ImportSteps;
  completedSteps: ImportSteps[];
}

export interface ImportStoreInterface {
  loading: boolean;
  error: string;
  processStarted: boolean;
  processFinished: boolean;
  importType: ImportType;
  importFlow: ImportFlow;
  userDateFormatIndex: DateFormatIndex;
  possibleDateFormats: string[];
  mappingEngine: MappingEngine;
  unrecognizedAccounts: UnrecognizedAccount[];
  accountActionNeeded: boolean;
  balanceAlignmentObject: TransactionBalanceAlignmentMap[];
}

@Injectable()
export class ImportStore extends ComponentStore<ImportStoreInterface> {
  constructor(
    private globalService: GlobalService,
    private institutionService: InstitutionService,
    private dataRepositoryService: DataRepositoryService,
    private bookService: BookService
  ) {
    super({
      loading: false,
      importType: null,
      importFlow: {
        step: ImportStepsEnum.institution,
        completedSteps: [],
      },
      processStarted: false,
      processFinished: false,
      error: "",
      possibleDateFormats: [],
      userDateFormatIndex: null,
      mappingEngine: null,
      unrecognizedAccounts: null,
      accountActionNeeded: null,
      balanceAlignmentObject: null,
    });
  }

  readonly loading$: Observable<boolean> = this.select((state) => state.loading);
  readonly importType$: Observable<ImportType> = this.select((state) => state.importType);
  readonly importTypeName$: Observable<string> = this.select((state) => state.importType?.typeName);
  readonly processStarted$: Observable<boolean> = this.select((state) => state.processStarted);
  readonly processFinished$: Observable<boolean> = this.select((state) => state.processFinished);
  readonly importFlow$: Observable<ImportFlow> = this.select((state) => state.importFlow);
  readonly arrangementStatus$: Observable<ProcessStatus> = this.select(
    (state) => state.importType?.arrangementStatus
  );
  readonly mappingEngine$: Observable<MappingEngine> = this.select((state) => state.mappingEngine);
  readonly unrecognizedAccounts$: Observable<UnrecognizedAccount[]> = this.select(
    (state) => state.unrecognizedAccounts
  );
  readonly accountActionNeeded$: Observable<boolean> = this.select(
    (state) => state.accountActionNeeded
  );
  readonly balanceAlignmentObject$: Observable<TransactionBalanceAlignmentMap[]> = this.select(
    (state) => state.balanceAlignmentObject
  );

  readonly vm$ = this.select(
    this.loading$,
    this.importType$,
    this.importTypeName$,
    this.processStarted$,
    this.processFinished$,
    this.importFlow$,
    this.arrangementStatus$,
    this.mappingEngine$,
    this.unrecognizedAccounts$,
    this.accountActionNeeded$,
    this.balanceAlignmentObject$,
    (
      loading,
      importType,
      importTypeName,
      processStarted,
      processFinished,
      importFlow,
      arrangementStatus,
      mappingEngine,
      unrecognizedAccounts,
      accountActionNeeded,
      balanceAlignmentObject
    ): ImportState => ({
      loading,
      importType,
      importTypeName,
      processStarted,
      processFinished,
      importFlow,
      arrangementStatus,
      mappingEngine,
      unrecognizedAccounts,
      accountActionNeeded,
      balanceAlignmentObject,
    })
  );

  private getImportType(type: TransactionImportType) {
    if (type === TransactionImportType.plaid) {
      return new PlaidType();
    }
    if (type === TransactionImportType.basiq) {
      return new BasiqType();
    }

    if (type === TransactionImportType.transactioncsv) {
      return new CsvType();
    }
  }

  readonly resetImport = this.updater((state) => ({
    ...state,
    step: null,
    importType: null,
    processStarted: false,
    processFinished: false,
  }));

  readonly startCsvImport = this.updater((state) => ({
    ...state,
    processStarted: true,
    importFlow: {
      step: ImportStepsEnum.institution,
      completedSteps: [],
    },
  }));

  readonly startPlaidImport = this.updater((state) => ({
    ...state,
    processStarted: true,
    importType: this.getImportType(TransactionImportType.plaid),
  }));

  readonly startBasiqImport = this.updater((state) => ({
    ...state,
    processStarted: true,
    importType: this.getImportType(TransactionImportType.basiq),
  }));

  readonly setLoading = this.updater((state, loading: boolean) => ({
    ...state,
    loading,
  }));

  readonly setImportFlow = this.updater((state, importFlow: ImportFlow) => ({
    ...state,
    importFlow,
  }));

  readonly setBalanceAlignmentObject = this.updater(
    (state, balanceAlignmentObject: TransactionBalanceAlignmentMap[]) => ({
      ...state,
      balanceAlignmentObject,
    })
  );

  goToUpload(institutionType: ImportInstitutionsInterface) {
    if (institutionType.selectedInstitution) {
      this.setImportFlow({
        step: ImportStepsEnum.upload,
        completedSteps: [ImportStepsEnum.institution],
      });
    } else {
      this.globalService.showErrorMessage("errorOccurred", "selectInstitutionInfo");
    }
  }

  setBalanceAlignment(
    arrangeState: ImportArrangeInterface,
    balanceAlignmentMapper: AlignmentMappingItem[]
  ) {
    const balanceAlignmentObject: TransactionBalanceAlignmentMap[] = [];
    const mappingEngine = new MappingEngine();
    arrangeState.records.preview.forEach((transaction: Transaction) => {
      balanceAlignmentObject.push({
        transactionId: transaction.id,
        transactionStatus: mappingEngine.transactionStatus(transaction, balanceAlignmentMapper),
      });
    });

    this.setBalanceAlignmentObject(balanceAlignmentObject);
  }

  goToImport() {
    this.setImportFlow({
      step: ImportStepsEnum.import,
      completedSteps: [
        ImportStepsEnum.institution,
        ImportStepsEnum.upload,
        ImportStepsEnum.arrange,
      ],
    });
  }

  getNextFlowState(csvImport: CsvType, step: ImportStepsEnum): ImportFlow {
    const completedStep = this.getCompletedStepInFlow(step);
    return {
      step: step,
      completedSteps: [...csvImport.importFlow.completedSteps, completedStep],
    };
  }

  getPreviousFlowState(csvImport: CsvType, step: ImportStepsEnum): ImportFlow {
    return {
      step: step,
      completedSteps: csvImport.importFlow.completedSteps.filter((s) => s !== step),
    };
  }

  getCompletedStepInFlow(step: ImportStepsEnum): ImportStepsEnum {
    switch (step) {
      case ImportStepsEnum.institution:
        return null;
      case ImportStepsEnum.upload:
        return ImportStepsEnum.institution;
      case ImportStepsEnum.arrange:
        return ImportStepsEnum.upload;
      case ImportStepsEnum.import:
        return ImportStepsEnum.arrange;
      default:
        return null;
    }
  }

  /*UPLOAD*/

  readonly gotToArrangement = this.updater((state) => {
    return {
      ...state,
      importFlow: {
        ...state.importFlow,
        step: ImportStepsEnum.arrange,
        completedSteps: [ImportStepsEnum.institution, ImportStepsEnum.upload],
      },
    };
  });

  readonly goBackToArrangement = this.updater((state) => {
    return {
      ...state,
      importFlow: {
        ...state.importFlow,
        step: ImportStepsEnum.arrange,
        completedSteps: [ImportStepsEnum.institution, ImportStepsEnum.upload],
      },
    };
  });

  readonly gotBackToInstitution = this.updater((state) => {
    return {
      ...state,
      importFlow: {
        ...state.importFlow,
        step: ImportStepsEnum.institution,
        completedSteps: [],
      },
    };
  });

  readonly gotBackToUpload = this.updater((state) => {
    return {
      ...state,
      importFlow: {
        step: ImportStepsEnum.upload,
        completedSteps: [ImportStepsEnum.institution],
      },
    };
  });

  readonly goBackToImportTypeSelection = this.updater((state) => {
    return {
      ...state,
      processStarted: false,
    };
  });

  getInstitutionWithCsvMapper(state: any, mapping: MappingEngine): Institution {
    if (!state.mappingEngine && state.importType.importFlow.step === ImportStepsEnum.arrange) {
      mapping.process(state.importType.fileRows);
      let headersMap: MappingConfigurationItem[];
      if (!state.importType.institution.csvMapper) {
        /*
         * TODO updateInstitutionWithMapper = true
         *  */
        headersMap = Object.values(mapping.headersMapping);
        state.importType.institution.csvMapper = headersMap;
      }
    }

    return state.importType.institution;
  }
}
