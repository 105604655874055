import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";

import { TransactionActions } from "../../transaction/state/transaction.action";
import { TransactionService } from "../transaction.service";

@Injectable()
export class TransactionEffects {
  constructor(private actions$: Actions, private transactionService: TransactionService) {}

  loadTransactions$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TransactionActions.load),
      mergeMap(() =>
        this.transactionService.loadTransactions$().pipe(
          map((transactions) => TransactionActions.loadSuccess({ transactions })),
          catchError((error: unknown) => of(TransactionActions.loadFailure({ error })))
        )
      )
    );
  });
}
