import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "app-splitcategory",
  templateUrl: "./splitcategory.component.html",
})
export class SplitcategoryComponent {
  @Input() splitName: string;
  @Input() inputName: string;
  @Input() inputValue: number;
  @Input() componentIndex: number;
  @Input() isDisabled: boolean;
  @Output() inputChangeEvent: EventEmitter<number> = new EventEmitter<number>();
  @Output() removeComponentEvent: EventEmitter<any> = new EventEmitter<number>();

  displayedColumns: ["columnName", "split", "delete"];

  changeEvent(value: number) {
    this.inputChangeEvent.emit(value);
  }

  remove(componentIndex: number) {
    this.removeComponentEvent.emit(componentIndex);
  }
}
