<app-spinner [showSpinner]="loading"></app-spinner>
<ng-container *ngIf="!loading">
  <div class="row">
    <div class="col-12">
      <div class="subscription-wrapper">
        <div class="subscription-header-wrapper">
          <span>{{ item?.metadata.name | uppercase }}</span>
          <span class="change-plan" (click)="handleSubscribe()">{{ "changePlan" | i18n }}</span>
        </div>
        <div *ngIf="!isPremiumSubscription">
          <span>{{ item?.metadata.description }}</span>
        </div>
        <ng-container *ngIf="isPremiumSubscription">
          <div class="subscription-price">
            <span>{{ item?.subscription.plan.currency | uppercase }}</span>
            <span>{{ "planPricePerMonth" | i18n }}</span>
          </div>
          <div class="subscription-price-detail">
            <span *ngIf="isPremiumSubscription">{{ "subscriptionPricePerMonth" | i18n }}</span>
            <span>{{ item?.metadata.description | lowercase }}</span>
          </div>
          <div class="subscription-billing-wrapper">
            <span>{{ "nextBillingDate" | i18n }}</span>
            <span class="billing-amount">{{ "billingAmount" | i18n }}</span>
            <div class="subscription-billing-detail">
              <span>{{ item?.expiration | date: "mediumDate" }}</span>
              <span class="billing-amount">
                {{ item?.subscription.plan.currency | uppercase }}
                {{ getBillingAmount() }}
              </span>
            </div>
          </div>
          <div class="billing-history">
            <span>{{ "viewBillingHistory" | i18n }}</span>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>
