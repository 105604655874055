import {
  interestCreation,
  transactionTransfer,
} from "@bitwarden/web-vault/app/models/types/estimate-action.types";
import {
  EstimateActionProperties,
  ScenarioHelpInfo,
  ScenarioPermutation,
} from "@bitwarden/web-vault/app/models/types/scenario-group.types";
import { InterestRateScenarioOptionUtils } from "@bitwarden/web-vault/app/services/DataCalculationService/scenarios/ScenarioOptions/interest-rate-scenario-option.utils";
import { DataRepositoryService } from "@bitwarden/web-vault/app/services/DataRepository/data-repository.service";

export class BestExistingInterestRate extends InterestRateScenarioOptionUtils {
  /**
   * createPermutations - Create the different possible sets of permutations to run
   *                      By default, this should only run the ones from blobby so that it will
   *                      work for user generated scenarios eventually
   */
  async createPermutations() {
    this.permutations = [];

    const symbol = this.scenarioGroup.symbol;
    // should return an array of multiple interestActions for anything that currently has
    // AUD in it from an institution that has

    if (this.startingAccountBalances) {
      const dataRepositoryService = this.injector.get(DataRepositoryService);

      const possibleAccounts = await dataRepositoryService.getAllBooks();

      for (const newAccount of possibleAccounts) {
        const scenarioPermutation: ScenarioPermutation = { estimateActions: [] };
        const helpInfo: ScenarioHelpInfo = {};

        // check for the account type being one with interest rates
        let interestRates = await this.getInterestRates(newAccount, symbol);

        if (
          (!interestRates || interestRates.length === 0) &&
          this.startingAccountBalances[newAccount.id]?.balance.runningTotalValue?.[symbol]
            ?.symbolAmount?.amount > 0
        ) {
          interestRates = [{ rate: 0, symbol: symbol, banded: false, range: -1, last_updated: "" }];
        }
        const toAccountUrl: string = await this.getAccountUrl(newAccount);
        const toInstitutionName: string = await this.getInstitutionName(newAccount);

        if (interestRates && interestRates.length > 0) {
          for (const oldAccountID in this.startingAccountBalances) {
            // don't use the account if it's the same one
            if (oldAccountID === newAccount.id) {
              continue;
            }
            const oldAccount = await dataRepositoryService.getBookById(oldAccountID);
            const oldAccountSymbolValue = this.getSymbolValue(oldAccountID, symbol);
            const creditAccount = this.isCredit(oldAccount);
            const fromInterestRate = await this.getInterestRates(oldAccount, symbol);
            const fromAccountUrl: string = await this.getAccountUrl(oldAccount);
            const fromInstitutionName: string = await this.getInstitutionName(oldAccount);

            // create a transfer estimate action to move the symbol to the new account
            if (oldAccount && !creditAccount && oldAccountSymbolValue > 0) {
              const transferParameters = this.createTransferParameters(
                oldAccount,
                newAccount,
                oldAccountSymbolValue,
                symbol
              );
              await this.addToHelpFromTransferParameters(
                helpInfo,
                transferParameters,
                interestRates,
                fromInterestRate,
                toAccountUrl,
                fromAccountUrl,
                toInstitutionName,
                fromInstitutionName
              );
              const permutation: EstimateActionProperties = {
                parameters: transferParameters,
                estimateActionType: transactionTransfer,
              };
              scenarioPermutation.estimateActions.push(permutation);
            }
          }

          // create an interest estimate action to create interest transactions
          const interestParameters = this.createInterestParameters(newAccount, interestRates);
          const permutation: EstimateActionProperties = {
            parameters: interestParameters,
            estimateActionType: interestCreation,
          };
          scenarioPermutation.estimateActions.push(permutation);
        }
        scenarioPermutation.scenarioHelpInfo = helpInfo;
        this.permutations.push(scenarioPermutation);
      }
    }
  }
}
