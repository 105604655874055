import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import _ from "lodash";

import { GlobalService } from "@bitwarden/common/services/global/global.service";
import { Category } from "@bitwarden/web-vault/app/models/data/blobby/category.data";
import { SourceCategory } from "@bitwarden/web-vault/app/models/data/blobby/source-category";
import { CategoryResponse } from "@bitwarden/web-vault/app/models/data/response/category.response";
import { CategoryService } from "@bitwarden/web-vault/app/services/DataService/category/category.service";
@Component({
  selector: "app-categories-add-edit",
  templateUrl: "./categories-add-edit.component.html",
  styles: [".mat-form-field {width: 100%;}"],
})
export class CategoriesAddEditComponent implements OnInit {
  form: FormGroup;
  categoryName: string;
  categoryId: string;
  loading = false;
  editMode = false;
  title: string;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      category: Category;
      actionSucceeded: CallableFunction;
      delete: CallableFunction;
    },
    private dialogRef: MatDialogRef<CategoriesAddEditComponent>,
    private categoryService: CategoryService,
    private globalService: GlobalService,
    private formBuilder: FormBuilder
  ) {
    if (data.category) {
      if (data.category.id) {
        this.categoryId = data.category.id;
      }
      if (data.category.name) {
        this.categoryName = data.category.name;
      }
    }
  }

  async ngOnInit() {
    this.initializeForm();
    this.editMode = this.categoryId != null;

    if (this.editMode) {
      this.editMode = true;
      this.title = this.globalService.getLocaleValue("editCategory");
      this.form.patchValue({
        categoryName: this.categoryName,
      });
    } else {
      this.title = this.globalService.getLocaleValue("createNewCategory");
    }
  }

  initializeForm() {
    this.form = this.formBuilder.group({
      categoryName: ["", Validators.required],
    });
  }

  async submit() {
    this.loading = true;
    try {
      if (this.form.valid) {
        if (this.editMode) {
          await this.updateCategory();
        } else {
          await this.createCategory();
        }
      }
    } catch (e) {
      this.globalService.showErrorMessage("errorOccurred", e);
    }
    this.loading = false;
  }

  closeDialogue() {
    this.dialogRef.close();
  }

  async createCategory() {
    const newCategory = new Category(new CategoryResponse(""));
    newCategory.name = this.form.value.categoryName;
    try {
      const createdCategory = await this.categoryService.create(newCategory);
      if (createdCategory instanceof Category) {
        const sourceCategory = new SourceCategory({
          name: createdCategory.name,
          categoryId: createdCategory.id,
        });
        await this.categoryService.createSourceCategory(sourceCategory);
        this.data.actionSucceeded("createdSuccessfully");
      }
    } catch (e) {
      this.globalService.showErrorMessage("errorOccurred", e);
    }
  }

  async updateCategory() {
    try {
      const categoryToBeUpdated = _.cloneDeep(this.data.category);
      categoryToBeUpdated.name = this.form.value.categoryName;

      const updatedCategory = await this.categoryService.update(categoryToBeUpdated, false);
      if (updatedCategory instanceof Category) {
        this.data.actionSucceeded("updatedSuccessfully");
      }
    } catch (e) {
      this.globalService.showErrorMessage("errorOccurred", e);
    }
  }

  async deleteCategory(category: Category) {
    // TODO check if it is a callable
    this.data.delete(category);
  }
}
