import { GLOBAL_BASE_CURRENCY } from "@bitwarden/web-vault/app/models/constants/global.constants";

import { TransactionFilterResponse } from "../../../../../models/data/response/transaction-filter.response";
import { TransactionFilter } from "../../../../../models/data/scenario-transaction-filter.data";

export const transactionFilter: TransactionFilter = new TransactionFilter(
  new TransactionFilterResponse({
    _id: crypto.randomUUID(),
    symbol: GLOBAL_BASE_CURRENCY,
  })
);
