import { createFeatureSelector, createReducer, createSelector, on } from "@ngrx/store";

import { Transaction } from "../../../../models/data/blobby/transaction.data";
import { getErrorMessage } from "../../../../shared/utils/helper-state-management";

import { RevaluationActions } from "./revaluation.action";

export const revaluationFeatureKey = "revaluation-data";

export interface RevaluationState {
  isLoading: boolean;
  isLoaded: boolean;
  revaluations: Transaction[];
  error: unknown;
}

const initialState: RevaluationState = {
  isLoading: true,
  isLoaded: false,
  revaluations: [],
  error: undefined,
};

const getRevaluationFeatureState = createFeatureSelector<RevaluationState>(revaluationFeatureKey);

export const getIsLoading = createSelector(getRevaluationFeatureState, (state) => state.isLoading);

export const getIsLoaded = createSelector(getRevaluationFeatureState, (state) => state.isLoaded);

export const getRevaluations = createSelector(
  getRevaluationFeatureState,
  (state) => state.revaluations
);

export const getError = createSelector(getRevaluationFeatureState, (state) => state.error);

export const revaluationReducer = createReducer<RevaluationState>(
  initialState,
  on(RevaluationActions.initialize, (state): RevaluationState => {
    return state;
  }),
  on(RevaluationActions.createSuccess, (state, action): RevaluationState => {
    return {
      ...state,
      isLoading: false,
      isLoaded: true,
      revaluations: action.revaluations,
    };
  }),
  on(RevaluationActions.createFailure, (state, action): RevaluationState => {
    return {
      ...state,
      isLoading: false,
      isLoaded: true,
      revaluations: undefined,
      error: getErrorMessage(action.error),
    };
  }),
  on(RevaluationActions.clear, (state, action): RevaluationState => {
    return {
      ...state,
      isLoading: false,
      isLoaded: true,
      revaluations: [],
      error: undefined,
    };
  })
);
