import { ModelValidator } from "@bitwarden/web-vault/app/models/data/blobby/model.validator";

import { SourceTransactionResponse } from "../../../../../../../libs/common/src/models/response/source-transaction-response";
import { TransactionDirection } from "../../enum/transactionDirection";
import { TransactionStatusEnum } from "../../enum/transactionType";
import { GlossDate } from "../shared/gloss-date";
import { GlossQuantity } from "../shared/gloss-quantity";

export class SourceTransaction extends ModelValidator {
  private readonly __v = 1;
  accountId: string;
  symbol: string;
  currency: string;
  description: string;
  kind: string;
  direction: TransactionDirection;
  bankImportedBalance: number;

  private _quantity: GlossQuantity;
  price: number;
  categories: Array<string>;
  classifications: Array<string>;
  sourceId: string;
  private _id: string;

  _definition: TransactionStatusEnum;
  /** Status will determine whether the transaction is duplicated or not */
  // todo need to move non data related field to the view
  private status: boolean;
  /** Date time of the transaction. if only date time will be set to 00:00:00 */
  private _transactionDate: GlossDate;

  constructor(response: SourceTransactionResponse) {
    super();
    if (response == null) {
      return;
    }

    this.checkVersion(this.__v, response.__v);
    this._id = response.id || crypto.randomUUID();
    this.transactionDate = new GlossDate().setToDateObj(response.transactionDate);
    this.sourceId = response.sourceId;
    this.definition = response.definition;
    this.symbol = response.symbol;
    this.currency = response.currency;
    this.quantity = response.quantity;
    this.price = response.price;
    this.bankImportedBalance = response.bankImportedBalance;
    this.direction = response.direction;
    this.description = response.description;
    this.kind = response.kind;
    this.categories = response.categories;
    this.classifications = response.classifications;
    this.accountId = response.accountId || "";
  }

  get id(): string {
    return this._id;
  }

  set id(value: string) {
    this._id = value;
  }

  get definition(): TransactionStatusEnum {
    return this._definition;
  }

  set definition(value: TransactionStatusEnum) {
    this._definition = value;
  }

  get quantity(): GlossQuantity {
    return this._quantity;
  }

  set quantity(value: GlossQuantity) {
    this._quantity = value;
  }
  setStatus(status: boolean) {
    this.status = status;
  }

  get transactionDate(): GlossDate {
    return this._transactionDate;
  }

  set transactionDate(value: GlossDate) {
    this._transactionDate = value;
  }
}
