import { BaseResponse } from "../../models/response/base.response";

export class SymbolInfoResponse extends BaseResponse {
  id: string;
  _id: string;
  symLocal: string; // the symbol use on this local instance, the main key for all queries around the system
  symGloss: string; // the Gloss symbol used for retrieving data from external data - the main symbology used, and key should ideally match this
  valuedIn: string; // what this symbol is valued in (usually a currency)
  mic: string; // if this symbol is listed on an exchange
  name: string;
  description: string;

  constructor(response: any) {
    super(response);
    this.id = this.getResponseProperty("SymLocal");
    this.symLocal = this.getResponseProperty("SymLocal");
    this.symGloss = this.getResponseProperty("SymGloss");
    this.valuedIn = this.getResponseProperty("ValuedIn");
    this.mic = this.getResponseProperty("MIC");
    this.name = this.getResponseProperty("Name");
    this.description = this.getResponseProperty("Description");
    this._id = crypto.randomUUID();
  }
}
