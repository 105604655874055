<div
  class="custom-tooltip"
  (mouseenter)="onMouseEnter()"
  (mouseleave)="onMouseLeave()"
  [id]="transaction.id"
  #popupReference
>
  <div *ngIf="transaction">
    <div *ngFor="let account of transaction.detailedBalance" class="tooltip-account">
      <span class="tooltip-account-name">{{
        account.accountId | bookPipe: "getBookName" | async
      }}</span>
      <div *ngFor="let symbolAmount of account.amounts" class="tooltip-amount">
        <span class="symbol-tooltip"
          >{{ symbolAmount.symbol }} : {{ symbolAmount.symbolAmount | currency }}
        </span>
      </div>
    </div>
  </div>
</div>
