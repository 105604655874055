// import { CipherView } from "../view/cipher.view";
// import { CollectionView } from "../view/collection.view";
// import { FolderView } from "../view/folder.view";
import { ReferenceData } from "../../../../../apps/ironfly-web/src/app/models/data/blobby/reference-data.data";

export class ImportReferenceDataResult {
  success = false;
  errorMessage: string;
  symbols: ReferenceData[] = [];
}
