import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class ColumnStateService {
  private userId: string;

  setUserId(userId: string) {
    this.userId = userId;
  }

  getColumnState() {
    return JSON.parse(localStorage.getItem(`columnState-${this.userId}`));
  }

  saveColumnState(gridApi: any) {
    const columnState = gridApi?.getColumnState();
    if (columnState) {
      localStorage.setItem(`columnState-${this.userId}`, JSON.stringify(columnState));
    }
  }
}
