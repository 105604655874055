<app-spinner [showSpinner]="loading"></app-spinner>
<div class="content">
  <div class="estimate-table-container">
    <nav class="navbar navbar-expand-lg navbar-light nav-transactions">
      <div class="navigation-container">
        <div class="dropdown">
          <button
            [disabled]="!isActionButtonEnabled()"
            class="btn btn-secondary dropdown-toggle"
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Actions
          </button>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <button class="dropdown-item" href="#" (click)="deleteEstimate()">
              {{ "deleteEstimates" | i18n }}
            </button>
          </div>
        </div>
        <i
          (click)="openEstimateForm(null)"
          aria-hidden="true"
          class="bwi bwi-plus bwi-fw add-estimate-icon"
        ></i>
      </div>
    </nav>
    <table mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="checkBox">
        <th mat-header-cell *matHeaderCellDef></th>
        <td
          mat-cell
          *matCellDef="let item; let i = index"
          class="transaction-table__column-text link-checkbox"
        >
          <mat-checkbox
            [color]="'primary'"
            (change)="toggleCheckBox($event, item)"
            [(ngModel)]="check[item.id]"
          ></mat-checkbox>
        </td>
      </ng-container>
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Estimate</th>
        <td mat-cell *matCellDef="let element" class="transaction-table__column-text">
          {{ element.name }}
        </td>
      </ng-container>

      <ng-container matColumnDef="categories">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Categories</th>
        <td mat-cell *matCellDef="let element" class="transaction-table__column-text">
          {{ element.categories | categoryPipe: "getCategoriesNamesList" | async }}
        </td>
      </ng-container>

      <ng-container matColumnDef="classifications">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Classifications</th>
        <td mat-cell *matCellDef="let element" class="transaction-table__column-text">
          {{ element.classifications | classificationPipe: "getClassificationsNamesList" | async }}
        </td>
      </ng-container>

      <ng-container matColumnDef="account">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Account</th>
        <td mat-cell *matCellDef="let element" class="transaction-table__column-text">
          {{ element.accountId | bookPipe: "getBookName" | async }}
        </td>
      </ng-container>

      <ng-container matColumnDef="direction">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Direction</th>
        <td mat-cell *matCellDef="let element" class="transaction-table__column-text">
          {{ element.direction }}
        </td>
      </ng-container>

      <ng-container matColumnDef="period">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Frequency/Period</th>
        <td mat-cell *matCellDef="let element" class="transaction-table__column-text">
          {{ element | estimatePipe: "getEstimateFreqAndPeriod" | async }}
        </td>
      </ng-container>

      <ng-container matColumnDef="value">
        <th mat-header-cell *matHeaderCellDef mat-sort-header arrowPosition="before">Value</th>
        <td mat-cell *matCellDef="let element" class="transaction-table__column-number">
          {{ element.initialValue | currency: element.symbol }}
        </td>
      </ng-container>

      <ng-container matColumnDef="edit">
        <th mat-header-cell *matHeaderCellDef class="actions"></th>
        <td mat-cell *matCellDef="let row; let i = index" class="actions">
          <button mat-icon-button color="primary" matTooltip="Edit" (click)="openEstimateForm(row)">
            <mat-icon>edit</mat-icon>
          </button>
        </td>
      </ng-container>

      <!--      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef mat-sort-header arrowPosition="before">Actions</th>
        <td mat-cell *matCellDef="let element" class="transaction-table__column-number table-list-options">

          <button type="button" class="tw-border-none tw-bg-transparent tw-text-main"  title="Options" >
            <i aria-hidden="true" class="bwi bwi-ellipsis-v bwi-lg"></i>
          </button>
          <bit-menu>
            <button
              bitButton
              buttonType="danger"
              type="button"
              appA11yTitle="{{ 'delete' | i18n }}"
            >
              <i class="bwi bwi-trash bwi-lg bwi-fw" aria-hidden="true"></i>
            </button>
          </bit-menu>

        </td>
      </ng-container>-->

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let estimate; columns: displayedColumns"></tr>
    </table>
  </div>

  <!--  <div class="d-flex">
      <div class="groups">
        <mat-nav-list>
          <div
            class="eg-container"
            *ngFor="let eg of estimateGroups; let index = index"
            [ngClass]="{ active: selectedGroup && selectedGroup.id === eg.id }"
            (click)="groupSelected(eg)"
          >
            <button mat-list-item>{{ eg.name }}</button>
            <div
              *ngIf="selectedGroup && selectedGroup.id === eg.id"
              class="estimate-group-card-action-button-container"
            >
              <button
                [bitMenuTriggerFor]="estimateGroupOptions"
                class="eg-action-openner tw-border-none tw-bg-transparent tw-text-main"
                type="button"
                appA11yTitle="{{ 'options' | i18n }}"
              >
                <i class="bwi bwi-ellipsis-v bwi-lg estimate-options-ellipsis" aria-hidden="true"></i>
              </button>
              <bit-menu #estimateGroupOptions>
                <button bitMenuItem (click)="openEstimateGroupForm(eg, index)">
                  {{ "edit" | i18n }}
                </button>

                <button bitMenuItem (click)="deleteGroup(eg, index)">
                  {{ "remove" | i18n }}
                </button>
              </bit-menu>
            </div>
          </div>

          <button
            mat-list-item
            class="create-new-estimate-group btn btn-sm btn-outline-primary"
            (click)="openEstimateGroupForm(null)"
          >
            {{ "createNewEstimateGroup" | i18n }}
          </button>
        </mat-nav-list>
      </div>
      <div class="group-content">
        <div class="page-header d-flex estimate-container">
          <div class="d-flex">
            <div class="ml-auto d-flex">
              <button
                class="btn btn-sm btn-outline-primary ml-3"
                type="button"
                (click)="createNewEstimate(null)"
              >
                <i aria-hidden="true" class="bwi bwi-plus bwi-fw"></i>
                Create New Estimate
              </button>
            </div>

            <div class="ml-auto d-flex">
              <button
                class="btn btn-sm btn-outline-primary ml-3"
                type="button"
                (click)="addNewEstimate(null)"
              >
                <i aria-hidden="true" class="bwi bwi-plus bwi-fw"></i>
                Add New Estimate
              </button>
            </div>
          </div>
        </div>

        <mat-grid-list cols="2" rowHeight="1:1">
          <mat-grid-tile *ngFor="let eg of selectedGroup?.estimateActions">
            <div class="estimate-card-action-button-container">
              <button
                [bitMenuTriggerFor]="estimateOptions"
                class="tw-border-none tw-bg-transparent tw-text-main"
                type="button"
                appA11yTitle="{{ 'options' | i18n }}"
              >
                <i class="bwi bwi-ellipsis-v bwi-lg estimate-options-ellipsis" aria-hidden="true"></i>
              </button>
              <bit-menu #estimateOptions>
                <button bitMenuItem (click)="editEstimate(eg)">
                  {{ "edit" | i18n }}
                </button>

                <button bitMenuItem (click)="delete(eg)">
                  {{ "remove" | i18n }}
                </button>
              </bit-menu>
            </div>
            <div class="section">
              <p>
                <span class="section-name-span">{{ "name" | i18n }}</span> :
              </p>
              <p class="section-value">{{ eg.name }}</p>
            </div>

            <div class="section">
              <p>
                <span class="section-name-span">{{ "account" | i18n }}</span> :
              </p>
              <p>{{ eg.account?.name }}</p>
            </div>

            <div class="section">
              <p>
                <span class="section-name-span">{{ "type" | i18n }}</span> :
              </p>
              <p>{{ eg.estimateType }}</p>
            </div>

            <div class="section">
              <p>
                <span class="section-name-span">{{ "startDate" | i18n }}</span> :
              </p>
              <p>{{ eg.startDate | date: "yyyy-MM-dd" }}</p>
            </div>

            <div class="section">
              <p>
                <span class="section-name-span">{{ "direction" | i18n }}</span> :
              </p>
              <p>{{ eg.direction }}</p>
            </div>

            <div class="section">
              <p>
                <span class="section-name-span">{{ "description" | i18n }}</span> :
              </p>
              <p>{{ eg.description }}</p>
            </div>

            <div class="section">
              <p>
                <span class="section-name-span">{{ "categories" | i18n }}</span> :
              </p>
              <p>
                <span *ngFor="let option of eg.categories; let last = last">
                  {{ option.category-renderer?.name }}
                  <span *ngIf="!last">, </span>
                </span>
              </p>
            </div>

            <div class="section">
              <p>
                <span class="section-name-span">{{ "classifications" | i18n }}</span> :
              </p>
              <p>
                <span *ngFor="let cls of eg.classifications; let last = last">
                  {{ cls.classification?.name }}
                  <span *ngIf="!last">, </span>
                </span>
              </p>
            </div>
          </mat-grid-tile>
        </mat-grid-list>
        &lt;!&ndash; <table mat-table [dataSource]="selectedGroup?.estimates" class="container">
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>Name</th>
            <td mat-cell *matCellDef="let element">{{ element.name }}</td>
          </ng-container>

          <ng-container matColumnDef="account">
            <th mat-header-cell *matHeaderCellDef>Account</th>
            <td mat-cell *matCellDef="let element">{{ element.account?.name }}</td>
          </ng-container>

          <ng-container matColumnDef="estimateType">
            <th mat-header-cell *matHeaderCellDef>Type</th>
            <td mat-cell *matCellDef="let element">{{ element.estimateType }}</td>
          </ng-container>

          <ng-container matColumnDef="startDate">
            <th mat-header-cell *matHeaderCellDef>Start Date</th>
            <td mat-cell *matCellDef="let element">{{ element.startDate | date: "dd-MM-yyyy" }}</td>
          </ng-container>

          <ng-container matColumnDef="direction">
            <th mat-header-cell *matHeaderCellDef>Direction</th>
            <td mat-cell *matCellDef="let element">{{ element.direction }}</td>
          </ng-container>

          <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef>Description</th>
            <td mat-cell *matCellDef="let element">{{ element.description }}</td>
          </ng-container>

          <ng-container matColumnDef="categories">
            <th mat-header-cell *matHeaderCellDef>Categories</th>
            <td mat-cell *matCellDef="let element">
              <ng-container *ngFor="let option of element.categories; let last = last">
                {{ option.category-renderer?.name }}
                <span *ngIf="!last">, </span>
              </ng-container>
            </td>
          </ng-container>

          <ng-container matColumnDef="classifications">
            <th mat-header-cell *matHeaderCellDef>Classifications</th>
            <td mat-cell *matCellDef="let element">
              <ng-container *ngFor="let option of element.classifications; let last = last">
                {{ option.classification?.name }}
                <span *ngIf="!last">, </span>
              </ng-container>
            </td>
          </ng-container>

          <ng-container matColumnDef="delete">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element" (click)="optionsClicked($event)">
              <button
                [bitMenuTriggerFor]="estimateOptions"
                class="tw-border-none tw-bg-transparent tw-text-main"
                type="button"
                appA11yTitle="{{ 'options' | i18n }}"
              >
                <i class="bwi bwi-ellipsis-v bwi-lg" aria-hidden="true"></i>
              </button>
              <bit-menu #estimateOptions>
                <button bitMenuItem (click)="delete(element)">
                  <i class="bwi bwi-fw bwi-close" aria-hidden="true"></i>
                  {{ "remove" | i18n }}
                </button>
              </bit-menu>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: displayedColumns"
            (click)="openEstimateForm(row)"
            class="hover-row"
          ></tr>
        </table>&ndash;&gt;
      </div>
    </div>-->
</div>
