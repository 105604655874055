import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";

import { LogService } from "@bitwarden/common/abstractions/log.service";
import { DashboardParameters } from "@bitwarden/web-vault/app/components/dashboard-selector/dashboard-selector.component";
import { GraphColourOptions } from "@bitwarden/web-vault/app/models/types/graph.types";
import { ScenarioData } from "@bitwarden/web-vault/app/models/types/scenario-group.types";

/**
 * Types required for the legend data
 */
type LegendDetailsType = Array<LegendEntry>;

type LegendEntry = {
  title: string;
  colour: string;
};

/**
 * Represents the Legend component which is required for scenario graphs
 * @param {LogService} log - Service for logging errors and information.
 */
@Component({
  selector: "app-primary-summary-graph-legend",
  templateUrl: "./legend.component.html",
})
export class LegendComponent implements OnChanges, OnInit {
  @Input() dashboardParameters: DashboardParameters;
  @Input() scenarioData: ScenarioData;
  private log: LogService;
  legendData: LegendDetailsType = [];
  showLegend = false;

  constructor(log: LogService) {
    this.log = log;
  }

  /**
   * Determine if we need to show the legend on initialisation
   * @async
   * @method
   */
  async ngOnInit() {
    this.showLegend = this.showLegendDetails();
  }

  /**
   * Determine if we need to show the legend based on the dashboard type
   */
  showLegendDetails() {
    if (this.dashboardParameters) {
      return (
        this.dashboardParameters.type === "scenarioOnly" ||
        this.dashboardParameters.type === "transactionAndScenario"
      );
    }
  }

  /**
   * Handles changes to the dashboard data and reloads the legend if necessary
   */
  async ngOnChanges(changes: SimpleChanges) {
    /* Dataset for the graph is not consistent at the moment, the structure will be different depending on the type of dashboard*/
    if (this.dashboardParameters) {
      this.showLegend = this.showLegendDetails();
    }
    if (this.showLegend) {
      await this.buildLegend();
    }
  }

  /**
   * Builds the legend data based on the scenario data
   * @private
   */
  private async buildLegend() {
    let index = 0;
    this.legendData = [];
    for (const scenario of this.scenarioData.scenario) {
      const colourIndex = index % (GraphColourOptions.length - 1);
      this.legendData.push({
        title: scenario.scenarioName,
        colour: GraphColourOptions?.[colourIndex],
      });
      index++;
    }
  }
}
