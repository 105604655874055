import { createActionGroup, emptyProps, props } from "@ngrx/store";

import { ReferenceData } from "../../../../models/data/blobby/reference-data.data";

export const ReferenceDataActions = createActionGroup({
  source: "Reference",
  events: {
    Initialize: emptyProps(),
    Load: emptyProps(),
    "Load Success": props<{ referenceData: ReferenceData[] | boolean }>(),
    "Load Failure": props<{ error: unknown }>(),
  },
});
