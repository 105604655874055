// const G8_CURRENCIES = ["AUD", "CAD", "CHF", "EUR", "GBP", "JPY", "NZD", "USD"];
//
// const OTHER_CURRENCIES = [
//   "AED",
//   "AFN",
//   "ALL",
//   "AMD",
//   "ANG",
//   "AOA",
//   "ARS",
//   "AWG",
//   "AZN",
//   "BAM",
//   "BBD",
//   "BDT",
//   "BGN",
//   "BHD",
//   "BIF",
//   "BMD",
//   "BND",
//   "BOB",
//   "BOV",
//   "BRL",
//   "BSD",
//   "BTN",
//   "BWP",
//   "BYR",
//   "BZD",
//   "CDF",
//   "CHE",
//   "CHW",
//   "CLF",
//   "CLP",
//   "CNY",
//   "COP",
//   "COU",
//   "CRC",
//   "CUC",
//   "CUP",
//   "CVE",
//   "CZK",
//   "DJF",
//   "DKK",
//   "DOP",
//   "DZD",
//   "EGP",
//   "ERN",
//   "ETB",
//   "FJD",
//   "FKP",
//   "GEL",
//   "GHS",
//   "GIP",
//   "GMD",
//   "GNF",
//   "GTQ",
//   "GYD",
//   "HKD",
//   "HNL",
//   "HRK",
//   "HTG",
//   "HUF",
//   "IDR",
//   "ILS",
//   "INR",
//   "IQD",
//   "IRR",
//   "ISK",
//   "JMD",
//   "JOD",
//   "KES",
//   "KGS",
//   "KHR",
//   "KMF",
//   "KPW",
//   "KRW",
//   "KWD",
//   "KYD",
//   "KZT",
//   "LAK",
//   "LBP",
//   "LKR",
//   "LRD",
//   "LSL",
//   "LTL",
//   "LVL",
//   "LYD",
//   "MAD",
//   "MDL",
//   "MGA",
//   "MKD",
//   "MMK",
//   "MNT",
//   "MOP",
//   "MRO",
//   "MUR",
//   "MVR",
//   "MWK",
//   "MXN",
//   "MXV",
//   "MYR",
//   "MZN",
//   "NAD",
//   "NGN",
//   "NIO",
//   "NOK",
//   "NPR",
//   "OMR",
//   "PAB",
//   "PEN",
//   "PGK",
//   "PHP",
//   "PKR",
//   "PLN",
//   "PYG",
//   "QAR",
//   "RON",
//   "RSD",
//   "RUB",
//   "RWF",
//   "SAR",
//   "SBD",
//   "SCR",
//   "SDG",
//   "SEK",
//   "SGD",
//   "SHP",
//   "SLL",
//   "SOS",
//   "SRD",
//   "SSP",
//   "STD",
//   "SYP",
//   "SZL",
//   "THB",
//   "TJS",
//   "TMT",
//   "TND",
//   "TOP",
//   "TRY",
//   "TTD",
//   "TWD",
//   "TZS",
//   "UAH",
//   "UGX",
//   "USN",
//   "USS",
//   "UYI",
//   "UYU",
//   "UZS",
//   "VEF",
//   "VND",
//   "VUV",
//   "WST",
//   "XAF",
//   "XAG",
//   "XAU",
//   "XBA",
//   "XBB",
//   "XBC",
//   "XBD",
//   "XCD",
//   "XDR",
//   "XFU",
//   "XOF",
//   "XPD",
//   "XPF",
//   "XPT",
//   "XTS",
//   "XXX",
//   "YER",
//   "ZAR",
//   "ZMW",
// ];

export const currencies = ["AUD", "HKD"];
