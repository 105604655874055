import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "app-input",
  templateUrl: "./input.component.html",
})
export class InputComponent {
  @Input() inputValue: string | number;
  @Input() inputName: string;
  @Input() inputType: string;
  @Input() labelKey: string;
  @Input() isDisabled: boolean;
  @Input() inputClass: string;

  @Output() changeEvent = new EventEmitter();

  onInputChange() {
    this.changeEvent.emit(this.inputValue);
  }
}
