export class RoleAccessData {
  private scope: Array<string>;
  private claim: Record<string, any>;

  getScope() {
    return this.scope;
  }

  setScope(scopes: Array<string>) {
    this.scope = scopes;
  }

  getClaim() {
    return this.claim;
  }

  setClaims(claims: Record<string, any>) {
    this.claim = claims;
  }
}
