import { Book } from "@bitwarden/web-vault/app/models/data/blobby/book.data";
import { Category } from "@bitwarden/web-vault/app/models/data/blobby/category.data";
import { Classification } from "@bitwarden/web-vault/app/models/data/blobby/classification.data";
import { GroupingNode } from "@bitwarden/web-vault/app/services/DataCalculationService/balanceGrouping/groupingNode";

export type GraphDataSet = {
  in: number;
  out: number;
  prevBalance: number;
  balance: number;
  midDate?: Date;
  estimateIn: number;
  estimateOut: number;
  estimatePrevBalance: number;
  estimateBalance: number;
  date: string;
  endDate: string;
  nextStartDate: string;
  lastTransactionDate: string;
  accountGrouping: Record<string, GroupingNode>;
};

export type FilterParameters = {
  accounts: Array<Book>;
  allAccounts: Array<Book>;
  directions: Array<string>;
  allDirections: Array<string>;
  categories?: Array<Category>;
  allCategories?: Array<Category>;
  classifications?: Array<Classification>;
  allClassifications?: Array<Classification>;
};

export type BandData = Array<BandDataSet>;

export type BandDataSet = {
  endDate: string;
  date: string;
  midDate?: Date;
  nextStartDate: string;
  highestBalance: number;
  balances: Array<{ display: string; balance: number }>;
  balanceData: string;
};

export type ParsedDates = Array<{ mY: string; display: string }>;

export const GraphColourOptions: Array<string> = [
  "MediumBlue",
  "LightSkyBlue",
  "LightSteelBlue",
  "DarkTurquiose",
  "Cyan",
  "DarkCyan",
];

export type ScenarioDiffBlock = {
  actionButton?: string;
  textOne: string;
  numberToShow: string;
  textTwo: string;
};
export type ScenarioDifferenceMessage = {
  headerText: string;
  headerIcon: "check" | "error_outline" | "thumb_up";
  buttonText: string;
  firstBlock: ScenarioDiffBlock;
  secondBlock: ScenarioDiffBlock;
};
