import { GlossRouteEnum } from "@bitwarden/web-vault/app/models/enum/navigation.enum";
import { RoleScope } from "@bitwarden/web-vault/app/models/enum/role-access.enum";

export const NavItems = [
  {
    routerLink: ["/", "primary-dashboard"],
    currentUrl: GlossRouteEnum.dashboard,
    icon: "views",
    selectedIcon: "views-selected",
    label: "sideMenuDashboard",
    roleScope: RoleScope.VIEW,
  },
  {
    routerLink: ["/gloss-settings", "account"],
    currentUrl: GlossRouteEnum.account,
    icon: "accounts",
    selectedIcon: "accounts-selected",
    label: "sideMenuAccount",
    roleScope: RoleScope.GLOSS,
  },
  {
    //TODO - update the icons here
    routerLink: ["/", "cabinet"],
    currentUrl: GlossRouteEnum.cabinet,
    icon: "cabinet",
    selectedIcon: "cabinet-selected",
    label: "sideMenuCabinet",
    roleScope: RoleScope.GLOSS,
  },
  {
    routerLink: ["/gloss-settings", "categories"],
    currentUrl: GlossRouteEnum.categories,
    icon: "categories",
    selectedIcon: "categories-selected",
    label: "categories",
    roleScope: RoleScope.BETA_ACCESS,
  },
  {
    routerLink: ["/gloss-settings", "classifications"],
    currentUrl: GlossRouteEnum.classifications,
    icon: "classifications",
    selectedIcon: "classifications-selected",
    label: "classifications",
    roleScope: RoleScope.BETA_ACCESS,
  },
  {
    routerLink: ["/gloss-settings", "institutions"],
    currentUrl: GlossRouteEnum.institutions,
    icon: "institutions",
    selectedIcon: "institutions-selected",
    label: "institutions",
    roleScope: RoleScope.BETA_ACCESS,
  },
  {
    routerLink: ["/", "import-management"],
    currentUrl: GlossRouteEnum.import,
    icon: "import-data",
    selectedIcon: "import-data-selected",
    label: "importData",
    roleScope: RoleScope.BETA_ACCESS,
  },
  {
    routerLink: ["/", "reference-management"],
    currentUrl: GlossRouteEnum.reference,
    icon: "reference",
    selectedIcon: "reference-selected",
    label: "sideMenuReferenceData",
    roleScope: RoleScope.EQUITY,
  },
  {
    routerLink: ["/", "estimates"],
    currentUrl: GlossRouteEnum.estimates,
    icon: "estimate",
    selectedIcon: "estimate-selected",
    label: "sideMenuEstimates",
    roleScope: RoleScope.FORECASTING,
  },
  {
    routerLink: ["/", "sync"],
    currentUrl: GlossRouteEnum.sync,
    icon: "sync",
    selectedIcon: "sync-selected",
    label: "sideMenuSync",
    roleScope: RoleScope.DATA_AGGREGATOR,
  },
  {
    routerLink: ["/settings", "account"],
    currentUrl: "/settings",
    icon: "settings",
    selectedIcon: "settings-selected",
    label: "settings",
    roleScope: RoleScope.GLOSS,
  },
];

export const NavItemsMobile = [
  {
    key: "dashboard",
    routerLink: ["/", "primary-dashboard"],
    icon: "views",
    selectedIcon: "views-selected",
    label: "sideMenuDashboard",
  },
  {
    key: "account",
    routerLink: ["/gloss-settings", "account"],
    icon: "accounts",
    selectedIcon: "accounts-selected",
    label: "sideMenuAccount",
  },
  {
    key: "cabinet",
    routerLink: ["/", "cabinet"],
    icon: "cabinet",
    selectedIcon: "cabinet-selected",
    label: "sideMenuCabinet",
    roleScope: RoleScope.BETA_ACCESS,
  },
  {
    key: "settings",
    routerLink: ["/settings", "account"],
    icon: "settings",
    selectedIcon: "settings-selected",
    label: "settings",
  },

  //TODO -  return this back once the new mobile nav is ready or when you are done with cabinet mobile
  { key: "help", routerLink: null, icon: "help", selectedIcon: "help-selected", label: "help" },
];
