import { View } from "@bitwarden/common/models/view/view";
import { Institution } from "@bitwarden/web-vault/app/models/data/blobby/institution.data";

import { Book } from "../data/blobby/book.data";
import { VaultFile } from "../data/blobby/vault-file.data";
import { GlossDate } from "../data/shared/gloss-date";

export class VaultFileView implements View {
  private readonly _id: string;
  private readonly _name: string;
  private readonly _dateCreated: GlossDate;
  private readonly _statementFromDate: GlossDate;
  private readonly _statementToDate: GlossDate;
  private readonly _statementAccounts: Book[];
  private readonly _banks: Institution[];
  private readonly _fileContent: string;
  private readonly _origin: VaultFile;

  constructor(vaultFile: VaultFile, fileAccounts: Book[], institutions: Institution[]) {
    if (!vaultFile) {
      return;
    }

    this._origin = vaultFile;
    this._id = vaultFile.id;
    this._name = vaultFile.name;
    this._dateCreated = vaultFile.dateCreated;
    this._statementFromDate = vaultFile.statementFromDate;
    this._statementToDate = vaultFile.statementToDate;
    this._statementAccounts = fileAccounts;
    this._banks = institutions;
  }

  get id(): string {
    return this._id;
  }

  get name(): string {
    return this._name;
  }
  get origin(): VaultFile {
    return this._origin;
  }

  get banks(): Institution[] {
    return this._banks;
  }

  get dateCreated(): GlossDate {
    return this._dateCreated;
  }

  get statementFromDate(): GlossDate {
    return this._statementFromDate;
  }

  get statementToDate(): GlossDate {
    return this._statementToDate;
  }

  get statementAccounts(): Book[] {
    return this._statementAccounts;
  }

  get fileContent(): string {
    return this._fileContent;
  }
}
