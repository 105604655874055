import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

// todo: refactor the html so it handles the different types of dialogs, right now only warning

@Component({
  selector: "app-confirmation-dialog",
  templateUrl: "./confirmation-dialog.component.html",
})
export class ConfirmationDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title: string;
      type: string;
      message: string;
    }
  ) {}

  hasHtml(): boolean {
    return this.data.message.includes("<"); //todo regex
  }
}
