import { ModelValidator } from "@bitwarden/web-vault/app/models/data/blobby/model.validator";

export class SourceCategory extends ModelValidator {
  private readonly __v = 1;
  private readonly _id: string;
  name: string;
  categoryId: string;

  // todo remove the any
  constructor(sourceCategoryObj: any) {
    super();
    if (sourceCategoryObj == null) {
      return;
    }
    // todo when it uses a BaseResponse object as constructor remove the ?? this.__v
    this.checkVersion(this.__v, sourceCategoryObj.__v ?? this.__v);
    this._id = crypto.randomUUID();
    this.name = sourceCategoryObj.name;
    this.categoryId = sourceCategoryObj.categoryId;
  }

  get id(): string {
    return this._id;
  }
}
