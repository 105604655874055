import { BaseResponse } from "@bitwarden/common/models/response/base.response";
import { Institution } from "@bitwarden/web-vault/app/models/data/blobby/institution.data";
import { ConnectionInfoType } from "@bitwarden/web-vault/app/models/types/connector.type";
import { AccountSyncStatus, Origin } from "@bitwarden/web-vault/app/models/types/general-types";

export class ConnectorResponse extends BaseResponse {
  __v = 1;
  id: string;
  name: string;
  origin: Origin;
  connectorType: string;
  institution: Institution[];
  connectionInfo: ConnectionInfoType;
  accountStatus: AccountSyncStatus[] = [];

  constructor(response: any) {
    super(response);
    this.__v = this.getResponseProperty("__v");
    this.id = this.getResponseProperty("_id");
    this.name = this.getResponseProperty("_name");
    this.origin = this.getResponseProperty("_origin");
    this.connectorType = this.getResponseProperty("_connectorType");
    this.institution = this.getResponseProperty("_institution");
    this.connectionInfo = this.getResponseProperty("_connectionInfo");
    /*TODO - make sure you remove the  [] form accountStatus section*/
    this.accountStatus = this.getResponseProperty("accountStatus") || [];
  }
}
