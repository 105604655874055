export class ItemCount {
  private _totalTransaction: number;
  private _totalAccount: number;

  getTotalTransaction(): number {
    return this._totalTransaction;
  }

  setTotalTransaction(value: number) {
    this._totalTransaction = value;
  }

  getTotalAccount(): number {
    return this._totalAccount;
  }

  setTotalAccount(value: number) {
    this._totalAccount = value;
  }
}
