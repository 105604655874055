import { FormControl } from "@angular/forms";

import { FormValidator } from "@bitwarden/web-vault/app/gloss/estimates/estimates-add-edit/estimate/form/form-validator";
import { Estimate } from "@bitwarden/web-vault/app/models/data/blobby/estimate.data";
import { EstimateResponse } from "@bitwarden/web-vault/app/models/data/response/estimate.response";

import { FormBuilder } from "../../../../../shared/form/FormBuilder";

export type FormType = Estimate | EstimateResponse;

export class FormEstimate extends FormBuilder {
  private formValidator = new FormValidator();

  create(estimate: FormType): FormType {
    Object.entries(this.formValidator.rules()).forEach(([propKey, rule]) => {
      const formValue = this.valueMapper(estimate, propKey);

      this.form = {
        ...this.form,
        [propKey]: new FormControl(formValue, rule),
      };
    });

    return this.form as FormType;
  }

  private valueMapper(estimate: FormType, propKey: string) {
    switch (propKey) {
      case "amount":
        return estimate.initialValue;
      case "isRecurring":
        return estimate.isRecurring ? "Yes" : "No";
      case "valueOption":
        return estimate.isFixedValue ? "fixed" : "variable";
      default:
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return estimate[propKey] || "";
    }
  }
}
