import { Injectable } from "@angular/core";

import { LogService } from "@bitwarden/common/abstractions/log.service";
import { Connector } from "@bitwarden/web-vault/app/models/data/blobby/connector.data";
import { Origin } from "@bitwarden/web-vault/app/models/types/general-types";
import { DataRepositoryService } from "@bitwarden/web-vault/app/services/DataRepository/data-repository.service";

@Injectable({
  providedIn: "root",
})
export class ConnectorService {
  private logService: LogService;
  constructor(private dataRepositoryService: DataRepositoryService) {}

  async createConnector(connector: Connector): Promise<Connector> {
    try {
      return await this.dataRepositoryService.createConnector(connector);
    } catch (e) {
      this.logService.error(e);
      throw e;
    }
  }

  async updateConnector(connector: Connector) {
    try {
      return await this.dataRepositoryService.updateConnector(connector);
    } catch (e) {
      this.logService.error(e);
      throw e;
    }
  }

  async deleteConnector(connector: Connector): Promise<boolean> {
    try {
      return await this.dataRepositoryService.deleteConnector(connector);
    } catch (e) {
      this.logService.error(e.message);
      throw e;
    }
  }

  async bulkDeleteConnectors(connectors: Connector[]): Promise<void> {
    try {
      return await this.dataRepositoryService.bulkDeleteConnectors(connectors);
    } catch (e) {
      this.logService.error(e);
      throw e;
    }
  }

  async getAllConnectors() {
    try {
      return await this.dataRepositoryService.getAllConnectors();
    } catch (e) {
      this.logService.error(e);
      throw e;
    }
  }

  async getConnectorByOrigin(origin: Origin): Promise<Connector> {
    try {
      const connectors = await this.dataRepositoryService.getAllConnectors();
      return connectors.find((connector) => connector.origin === origin);
    } catch (e) {
      this.logService.error(e);
      throw e;
    }
  }

  async testConnector(): Promise<Connector[]> {
    return await this.dataRepositoryService.getAllConnectors();
  }
}
