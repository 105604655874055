import { Injectable } from "@angular/core";

import { GlossQuantity } from "@bitwarden/web-vault/app/models/data/shared/gloss-quantity";

import { Allocation } from "../../../models/data/allocation.data";
import { Transaction } from "../../../models/data/blobby/transaction.data";
import { DataRepositoryService } from "../../DataRepository/data-repository.service";
import { BlobbyUtils } from "../../blobby/blobbyUtils";
import { CalculationServiceAbstraction } from "../calculation.service.abstraction";

@Injectable({
  providedIn: "root",
})
export class AllocationService implements CalculationServiceAbstraction {
  constructor(private dataRepositoryService: DataRepositoryService) {}

  async getBalanceOpen(
    account: string,
    symbol: string,
    mergeClassification: boolean,
    mergeCategories: boolean,
    ymd?: number,
    date?: Date,
    futureTransactions?: Array<Transaction>
  ): Promise<Allocation[]> {
    const now = date || BlobbyUtils.setYMDToDate(ymd);

    return this.getBalanceByDate(
      account,
      symbol,
      mergeClassification,
      mergeCategories,
      now,
      false,
      futureTransactions
    );
  }

  /**
   * @param account
   * @param symbol
   * @param mergeClassification
   * @param mergeCategories
   * @param ymd
   * @param date
   * @param futureTransactions
   */
  async getBalanceClose(
    account: string,
    symbol: string,
    mergeClassification: boolean,
    mergeCategories: boolean,
    ymd?: number,
    date?: Date,
    futureTransactions?: Array<Transaction>
  ): Promise<Allocation[]> {
    const now = date || BlobbyUtils.setYMDToDate(ymd);
    return this.getBalanceByDate(
      account,
      symbol,
      mergeClassification,
      mergeCategories,
      now,
      true,
      futureTransactions
    );
  }

  allocationSumQuantity(allocationArray: Allocation[]): number {
    let allocSum = 0;
    allocationArray.forEach((alloc) => {
      allocSum = allocSum + alloc.value.symbolAmount.amount;
    });
    return allocSum;
  }

  async getBalanceByDate(
    account: string,
    symbol: string,
    mergeClassification: boolean,
    mergeCategories: boolean,
    balanceUpToDate: Date,
    inclusive: boolean,
    futureTransactions: Array<Transaction>
  ): Promise<Allocation[]> {
    // todo remove "any" type
    const resObject: { [index: string]: any } = {};
    const transactions: Array<Transaction> = await this.dataRepositoryService.getAllTransactions();
    if (futureTransactions) {
      transactions.push(...futureTransactions);
    }

    // todo
    //  KM: scanning all transactions probably isn't the best way to do this
    //  for the purposes of speed, this will work for now, just inefficiently, and can rework at a later date
    transactions?.forEach((transaction) => {
      const glossQuantity = new GlossQuantity().setToQuantityObj(transaction.quantity);
      const acct = transaction.accountId;
      const sym = glossQuantity.actualQuantity.symbol || glossQuantity.currency;

      if (sym !== symbol) {
        return;
      }

      resObject[acct] = resObject[acct] || {};
      resObject[acct][sym] = resObject[acct][sym] || {};
      transaction.allocations?.forEach((alloc) => {
        const cls = alloc.classification;
        const cat = alloc.category;
        resObject[acct][sym][cls] = resObject[acct][sym][cls] || {};
        resObject[acct][sym][cls][cat] = resObject[acct][sym][cls][cat] || 0;
        const addQty = BlobbyUtils.isUpToTime(
          transaction.transactionDate.date,
          balanceUpToDate,
          inclusive
        );
        if (account && alloc.value.symbolAmount.amount && acct === account && addQty) {
          resObject[acct][sym][cls][cat] += alloc.value.symbolAmount.amount;
        }
      });
    });

    return this.expandResObject(resObject, mergeClassification, mergeCategories);
  }

  expandResObject(
    // todo remove "any" type
    resObject: { [index: string]: any },
    mergeClassification: boolean,
    mergeCategory: boolean
  ) {
    // expand resObject e.g. {"HK Cash":{"HKD":{"Me":{"undefined":28522862.340000004,"Income":1506377.4300000002}}}}
    // into array of allocation objects
    const allocations: Allocation[] = [];

    Object.keys(resObject).forEach((acct) => {
      Object.keys(resObject[acct]).forEach((sym) => {
        Object.keys(resObject[acct][sym]).forEach((cls) => {
          Object.keys(resObject[acct][sym][cls]).forEach((cat) => {
            const alloc = new Allocation();
            alloc.classification = cls;
            alloc.category = cat;
            alloc.value.symbolAmount.amount = BlobbyUtils.roundDp(resObject[acct][sym][cls][cat]);
            allocations.push(alloc);
          });
        });
      });
    });
    // return all the allocations as the default split
    if (!mergeClassification && !mergeCategory) {
      return allocations;
    }
    // sum all the quantities together and return only 1 alloc in the list (todo and later all the normalisedValues)
    if (mergeClassification && mergeCategory) {
      const mergedAlloc = new Allocation();
      mergedAlloc.value.symbolAmount.amount = 0;
      allocations.forEach((alloc) => {
        mergedAlloc.value.symbolAmount.amount += alloc.value.symbolAmount.amount;
      });
      return [mergedAlloc];
    }
    // return allocations split by categories only
    if (mergeClassification) {
      let categories = allocations.map((a) => a.category);
      categories = [...new Set(categories)]; // uniques the map
      const categoryMap: { [index: string]: Allocation } = {};
      categories.forEach((cat) => {
        categoryMap[cat] = new Allocation();
        categoryMap[cat].category = cat;
        categoryMap[cat].value.symbolAmount.amount = 0;
      });
      allocations.forEach((alloc) => {
        categoryMap[alloc.category].value.symbolAmount.amount += alloc.value.symbolAmount.amount;
      });
      const mergedClassificationAllocs: Allocation[] = [];
      categories.forEach((cat) => {
        mergedClassificationAllocs.push(categoryMap[cat]);
      });
      return mergedClassificationAllocs;
    }
    // return allocations split by categories only
    if (mergeCategory) {
      let classifications = allocations.map((a) => a.classification);
      classifications = [...new Set(classifications)]; // uniques the map
      const classificationMap: { [index: string]: Allocation } = {};
      classifications.forEach((cls) => {
        classificationMap[cls] = new Allocation();
        classificationMap[cls].classification = cls;
        classificationMap[cls].value.symbolAmount.amount = 0;
      });
      allocations.forEach((alloc) => {
        classificationMap[alloc.classification].value.symbolAmount.amount +=
          alloc.value.symbolAmount.amount;
      });
      const mergedCategoryAllocs: Allocation[] = [];
      classifications.forEach((cat) => {
        mergedCategoryAllocs.push(classificationMap[cat]);
      });
      return mergedCategoryAllocs;
    }

    return allocations;
  }
}
