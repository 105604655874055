import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Output,
  ViewChild,
  OnInit,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDatepicker } from "@angular/material/datepicker";
import { addMonths, isBefore } from "date-fns";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { DashboardService } from "@bitwarden/web-vault/app/services/dashboard/dashboard-service";

@Component({
  selector: "app-nav-date-picker",
  templateUrl: "./nav-date-picker.component.html",
})
export class NavDatePickerComponent implements OnInit {
  private destroy$ = new Subject<void>();
  dateForm: FormGroup;
  @ViewChild("startPicker") startPicker!: MatDatepicker<any>;
  @ViewChild("endPicker") endPicker!: MatDatepicker<any>;
  @Output() dateChangeEvent = new EventEmitter<Array<string>>();

  constructor(
    private fb: FormBuilder,
    private dashboardService: DashboardService,
    private cdr: ChangeDetectorRef
  ) {
    this.dateForm = this.initDateForm();
  }

  async ngOnInit() {
    await this.subscribeToDashObservables();
  }

  private initDateForm(): FormGroup {
    const currentDate = new Date();
    const endDateInitial = addMonths(currentDate, 3);
    return this.fb.group(
      {
        startDate: [currentDate, Validators.required],
        endDate: [endDateInitial, Validators.required],
      },
      { validators: this.dateRangeValidator }
    );
  }

  onDateChange(event: any, dateType: string): void {
    const value = event.value;
    if (dateType === "startDate") {
      this.dateForm.controls.startDate.setValue(value);
    } else if (dateType === "endDate") {
      this.dateForm.controls.endDate.setValue(value);
    }

    this.dateForm.updateValueAndValidity();

    const startDate = this.dateForm.value.startDate;
    const endDate = this.dateForm.value.endDate;

    if (startDate > endDate) {
      this.dateForm.controls.endDate.setValue(startDate);
    }
    this.dateChangeEvent.emit([this.formatDate(startDate), this.formatDate(endDate)]);

    this.updateDate(this.startDateText, this.endDateText);
  }

  dateRangeValidator(group: FormGroup): { [key: string]: any } | null {
    const startDate = group.value.startDate;
    const endDate = group.value.endDate;

    return startDate && endDate && isBefore(new Date(endDate), new Date(startDate))
      ? { dateRangeInvalid: true }
      : null;
  }

  formatDate(date: Date): string {
    if (!date) {
      return "";
    }

    const offset = date.getTimezoneOffset();
    const adjustedDate = new Date(date.getTime() - offset * 60 * 1000);
    return adjustedDate.toISOString().split("T")[0];
  }

  getMinEndDate(): Date {
    const startDateValue = this.dateForm.value.startDate;
    return startDateValue ? new Date(startDateValue) : new Date();
  }

  async updateDate(startDate: string, endDate: string): Promise<void> {
    setTimeout(async () => {
      await this.dashboardService.updateDates(startDate, endDate);
    }, 250);
  }

  get startDateText(): string {
    return this.formatDate(this.dateForm.value.startDate);
  }

  get endDateText(): string {
    return this.formatDate(this.dateForm.value.endDate);
  }

  async subscribeToDashObservables() {
    this.dashboardService.defaultStartDate$
      .pipe(takeUntil(this.destroy$))
      .subscribe((startDate) => {
        if (startDate) {
          this.dateForm.controls.startDate.setValue(new Date(startDate));
          this.cdr.detectChanges();
        }
      });

    this.dashboardService.defaultEndDate$.pipe(takeUntil(this.destroy$)).subscribe((endDate) => {
      if (endDate) {
        this.dateForm.controls.endDate.setValue(new Date(endDate));
        this.cdr.detectChanges();
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
