<!--<app-spinner [showSpinner]="loading"></app-spinner>-->
<div class="modal-content">
  <div class="modal-header">
    <span> {{ "dateFormatNotDetected" | i18n }}</span>
  </div>
  <div class="modal-body">
    <app-select
      labelKey="dateFormats"
      selectElementId="`accountLink${newAccount}`"
      [itemList]="data.possibleDateFormats"
      (selectEvent)="userSelectedFormat($event)"
      [selectedValue]="selectedFormat"
    ></app-select>

    <div class="modal-footer right-buttons-container">
      <button mat-raised-button mat-dialog-close (click)="closeDialogue()">
        {{ "cancel" | i18n }}
      </button>
      <button mat-raised-button color="primary" type="submit" (click)="saveDateFormatChoice()">
        {{ "importWithSelectedFormat" | i18n }}
      </button>
    </div>
  </div>
</div>
