import { Transaction } from "@bitwarden/web-vault/app/models/data/blobby/transaction.data";
import { TransactionResponse } from "@bitwarden/web-vault/app/models/data/response/transaction-response";
import { TransactionDirection } from "@bitwarden/web-vault/app/models/enum/transactionDirection";
import { TransactionStatusEnum } from "@bitwarden/web-vault/app/models/enum/transactionType";
import DateFormat from "@bitwarden/web-vault/app/shared/utils/helper.date/date-format";

export const getClosingAlignmentTransaction = (account: any, balance?: number): Transaction => {
  const closingTransactionObject: any = {
    id: crypto.randomUUID(),
    convrate: 1,
    symbol: account.currency,
    currency: account.currency,
    description: "Generated Transaction",
    amount: "",
    accountId: account.id,
    classifications: account.defaultClassifications || [],
    categories: account.defaultCategories || [],
    balance: balance ? balance.toString() : account.balance.toString(),
    direction: Number(account.balance) > 0 ? TransactionDirection.In : TransactionDirection.Out,
    date: account.date
      ? account.date
      : DateFormat.getDateStringFromStampStatically(new Date().getTime()),
    definition: TransactionStatusEnum.closing,
  };

  return new Transaction(new TransactionResponse(closingTransactionObject));
};

export const getOpeningAlignmentTransaction = (
  account: any,
  balance: number,
  openingBalanceDate: string
): Transaction => {
  const closingTransactionObject: any = {
    id: crypto.randomUUID(),
    convrate: 1,
    symbol: account.currency,
    currency: account.currency,
    description: "Generated Transaction (Opening)",
    amount: "",
    accountId: account.id,
    classifications: account.defaultClassifications || [],
    categories: account.defaultCategories || [],
    balance: balance.toString(),
    direction: Number(account.balance) > 0 ? TransactionDirection.In : TransactionDirection.Out,
    date: openingBalanceDate,
    definition: TransactionStatusEnum.opening,
  };

  return new Transaction(new TransactionResponse(closingTransactionObject));
};

export const getAlignmentTransaction = (
  account: any,
  balance: number | string,
  openingBalanceDate: string
): Transaction => {
  const closingTransactionObject: any = {
    id: crypto.randomUUID(),
    convrate: 1,
    symbol: account.currency,
    currency: account.currency,
    description: "Generated Transaction (Alignment)",
    amount: "",
    accountId: account.id,
    classifications: account.defaultClassifications || [],
    categories: account.defaultCategories || [],
    balance: typeof balance !== "string" ? balance.toString() : balance,
    direction: Number(account.balance) > 0 ? TransactionDirection.In : TransactionDirection.Out,
    date: openingBalanceDate,
    definition: TransactionStatusEnum.closing,
  };

  return new Transaction(new TransactionResponse(closingTransactionObject));
};
