import { Component, DoCheck, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Subject, takeUntil } from "rxjs";

import { GlobalService } from "@bitwarden/common/services/global/global.service";
import { CustomerSupportBoxComponent } from "@bitwarden/web-vault/app/components/customer-support-box/customer-support-box.component";
import { RoleAccessData } from "@bitwarden/web-vault/app/models/data/role-access.data";
import {
  DATA_AGGREGATOR_OPTIONS,
  RoleScope,
} from "@bitwarden/web-vault/app/models/enum/role-access.enum";
import { ConfirmationDialogService } from "@bitwarden/web-vault/app/services/confirmation/confirmation.servise";
import { RoleAccessService } from "@bitwarden/web-vault/app/services/permission/role-access.service";
import { HelperCommon } from "@bitwarden/web-vault/app/shared/utils/helper.common";

@Component({
  selector: "app-creation-options",
  templateUrl: "./creation-options.component.html",
  styles: ["creation-options.component.scss"],
})
export class CreationOptionsComponent implements DoCheck {
  private destroy$ = new Subject<void>();
  private roleAccess: RoleAccessData;
  protected readonly RoleScope = RoleScope;
  protected readonly DATA_AGGREGATOR_OPTIONS = DATA_AGGREGATOR_OPTIONS;

  earlyRequestDialogRef: MatDialogRef<CustomerSupportBoxComponent>;
  hasAccess: boolean;
  hasScope: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      createManually: CallableFunction;
      createByLinking: CallableFunction;
      closeDialogue: CallableFunction;
      isPremiumAccount: boolean;
    },
    private helperCommon: HelperCommon,
    private globalService: GlobalService,
    private roleAccessService: RoleAccessService,
    public dialog: MatDialog,
    private confirmationDialogService: ConfirmationDialogService
  ) {
    this.hasAccess = data.isPremiumAccount;
  }

  ngDoCheck() {
    if (!this.roleAccess) {
      this.roleAccess = this.roleAccessService.getRoleAccess();
      this.hasScope = this.hasBasiqAccess();
    }
  }

  hasBasiqAccess() {
    if (this.roleAccess) {
      const scope = this.roleAccess.getScope();
      const claim = this.roleAccess.getClaim();
      const dataAggregator = claim.data_aggregator_connector_name;

      return (
        (scope.includes(RoleScope.BETA_ACCESS) || scope.includes(RoleScope.DATA_AGGREGATOR)) &&
        dataAggregator.includes(DATA_AGGREGATOR_OPTIONS.BASIQ)
      );
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  createManually() {
    this.data.createManually();
  }

  async createByLinking() {
    if (!this.hasScope) {
      const confirm = await this.confirmationDialogService.showConfirmDialog({
        title: {
          text: "",
          i18Key: "betaAccount",
        },
        message: {
          text: "earlyAccessConfirmation",
          i18Key: "earlyAccessConfirmation",
        },
        type: "info",
      });

      if (confirm) {
        this.openHelpMenu();
      }

      return;
    }

    if (this.data.isPremiumAccount) {
      this.data.createByLinking();
    }
  }

  openHelpMenu() {
    this.requestEarlyAccess();
  }

  requestEarlyAccess() {
    this.earlyRequestDialogRef = this.dialog.open(CustomerSupportBoxComponent, {
      panelClass: "no-background-dialog",
      data: {
        supportBoxHeader: "Request An Early Access",
        textFieldMessage: "",
        placeholderMessage: "message",
        dialogOpen: true,
        closeDialogue: this.closeEarlyRequestDialog.bind(this),
      },
      disableClose: true,
    });

    this.earlyRequestDialogRef.afterClosed().pipe(takeUntil(this.destroy$));
  }

  closeEarlyRequestDialog() {
    this.earlyRequestDialogRef.close();
  }
}
