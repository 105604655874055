import { Component, Inject, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "app-user-choice-of-year",
  templateUrl: "./user-choice-of-year.component.html",
})
export class UserChoiceOfYearComponent implements OnInit {
  inputControl = new FormControl("");
  yearFormatFits = true;
  currentYear = new Date().getFullYear();
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      setTheYear: CallableFunction;
    }
  ) {}

  ngOnInit(): void {
    const currentYear = new Date().getFullYear().toString();
    this.inputControl.setValue(currentYear);
  }

  save() {
    if (this.isYearFit()) {
      this.data.setTheYear(this.inputControl.value);
    }
  }

  closeDialogue() {
    this.data.setTheYear(null);
  }

  onChange(event: Event) {
    this.yearFormatFits = this.isYearFit();
  }

  isYearFit() {
    const value: string = this.inputControl.value;
    return /^\d{2}(\d{2})?$/.test(value) && parseInt(value) <= new Date().getFullYear();
  }
}
