import { GlossBalance } from "@bitwarden/web-vault/app/models/data/shared/gloss-balance";
import {
  dayGranularity,
  DetailedBalance,
} from "@bitwarden/web-vault/app/models/types/balanceGroupingTypes";
import { BalanceGrouping } from "@bitwarden/web-vault/app/services/DataCalculationService/balanceGrouping/balanceGrouping";

import { TransactionView } from "../../models/view/transaction.view";

export const getDailyBalance = (
  transactionsView: Array<TransactionView>,
  groupBalances: BalanceGrouping
) => {
  const transactionView: TransactionView[] = [];

  for (let i = 0; i < transactionsView.length; i++) {
    const nextIndex = i + 1;
    const currentTrans = transactionsView[i];
    const nextTrans = transactionsView[nextIndex];

    if (nextTrans && currentTrans.glossDate.dateString === nextTrans.glossDate.dateString) {
      transactionView.push(currentTrans);
      continue;
    }

    const transactionDate = currentTrans.transactionDate;
    currentTrans.dailyBalance = getDailyNormalizedBalance(transactionDate, groupBalances);
    currentTrans.detailedBalance = getDetailedBalance(currentTrans, groupBalances);
    transactionView.push(currentTrans);
  }

  return transactionView;
};

/**
 *
 * @param date
 * @param groupBalances
 * @return Return the normalized balance for the day. If the groupBalances doesn't include that range, it will return NaN.
 * @throws Error if the dailyGranularity balance is not instance of GlossBalance
 */
const getDailyNormalizedBalance = (date: Date, groupBalances: BalanceGrouping): number => {
  const dayKey = groupBalances.getDateGroupingKey(date, dayGranularity);
  const dailyGranularity = groupBalances.granularity.day[dayKey];

  if (typeof dailyGranularity === "undefined") {
    return NaN;
  }

  if (dailyGranularity?.balance) {
    return dailyGranularity.balance.runningTotalNormalizedValue.amount;
  } else {
    throw new Error(
      "TransactionGrouping Malformed: Properties balance expecting a GlossBalance object"
    );
  }
};

const getDetailedBalance = (
  currentTransaction: TransactionView,
  groupBalances: BalanceGrouping
): DetailedBalance => {
  const transactionDate = currentTransaction.transactionDate.toString();
  const dailyGranularity = groupBalances.granularity.day[transactionDate];

  if (typeof dailyGranularity === "undefined") {
    return {};
  }

  if (dailyGranularity?.balance instanceof GlossBalance) {
    const accountDetailBalances: DetailedBalance[] = [];

    for (const accountId in dailyGranularity.children.account) {
      const detailedBalance: DetailedBalance = {
        accountId: accountId,
        amounts: [],
      };

      for (const symbol in dailyGranularity.children.account[accountId].balance.runningTotalValue) {
        //dailyGranularity.balance.runningTotalNormalizedValue.amount;
        const symbolAmount = {
          symbol: symbol,
          symbolAmount:
            dailyGranularity.children.account[accountId].balance.runningTotalValue[symbol]
              .symbolAmount.amount,
          normalizedSymbol:
            dailyGranularity.children.account[accountId].balance.runningTotalValue[symbol]
              .normalizedValue.symbol,
          normalizedAmount:
            dailyGranularity.children.account[accountId].balance.runningTotalValue[symbol]
              .normalizedValue.amount,
        };
        detailedBalance.amounts.push(symbolAmount);
      }
      accountDetailBalances.push(detailedBalance);
    }
    return accountDetailBalances;
  } else {
    throw new Error(
      "TransactionGrouping Malformed: Properties balance expecting a GlossBalance object"
    );
  }
};
