<button
  class="tw-h-12 tw-w-full tw-px-6 tw-rounded-full tw-border-none
  {{
    isEnabled ? 'hover:tw-shadow-hover active:tw-shadow-inner tw-shadow-button' : 'tw-bg-neutral-50'
  }}
  {{ isEnabled && className }}"
  [disabled]="!isEnabled"
  (click)="onButtonClicked()"
  [routerLink]="options.link ? options.link : null"
  tabindex="-1"
>
  <span class="tw-flex tw-justify-center tw-gap-2 tw-whitespace-nowrap">
    {{ options.text }}
    <svg *ngIf="options.icon" class="tw-h-5 tw-w-5">
      <use [attr.xlink:href]="'images/sprite.svg#' + options.icon"></use>
    </svg>
  </span>
</button>
