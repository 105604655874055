import { createFeatureSelector, createReducer, createSelector, on } from "@ngrx/store";

import { ReferenceData } from "../../../../models/data/blobby/reference-data.data";
import { getErrorMessage } from "../../../../shared/utils/helper-state-management";

import { ReferenceDataActions } from "./reference-data.action";
export const referenceDataFeatureKey = "reference-data";

export interface ReferenceDataState {
  isLoading: boolean;
  isLoaded: boolean;
  referenceData: ReferenceData[] | boolean;
  error: unknown;
}

const initialState: ReferenceDataState = {
  isLoading: true,
  isLoaded: false,
  referenceData: [],
  error: undefined,
};

const getReferenceDataFeatureState =
  createFeatureSelector<ReferenceDataState>(referenceDataFeatureKey);

export const getIsLoading = createSelector(
  getReferenceDataFeatureState,
  (state) => state.isLoading
);

export const getIsLoaded = createSelector(getReferenceDataFeatureState, (state) => state.isLoaded);

export const getReferenceData = createSelector(
  getReferenceDataFeatureState,
  (state) => state.referenceData
);

export const getError = createSelector(getReferenceDataFeatureState, (state) => state.error);

export const referenceDataReducer = createReducer<ReferenceDataState>(
  initialState,
  on(ReferenceDataActions.initialize, (state): ReferenceDataState => {
    return state;
  }),
  on(ReferenceDataActions.loadSuccess, (state, action): ReferenceDataState => {
    return {
      ...state,
      isLoading: false,
      isLoaded: true,
      referenceData: action.referenceData,
      error: undefined,
    };
  }),
  on(ReferenceDataActions.loadFailure, (state, action): ReferenceDataState => {
    return {
      ...state,
      isLoading: false,
      isLoaded: true,
      referenceData: [],
      error: getErrorMessage(action.error),
    };
  })
);
