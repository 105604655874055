// eslint-disable-next-line no-restricted-imports
import { addDays, addMonths, addWeeks, addYears } from "date-fns";

import { RecurringPeriod } from "@bitwarden/common/enums/recurringPeriod";
import { BlobbyConfigType } from "@bitwarden/web-vault/app/models/types/environement-config.type";

import { BlobbyDataTypeEnum } from "../../models/enum/blobbyDataTypeEnum";
import { GlossEncryptedDataType } from "../../models/enum/glossEncryptedDataType";

export class BlobbyUtils {
  static getFolderId(type: BlobbyDataTypeEnum): string {
    return null;
  }

  static getBatchSizeSymbol(items: Array<GlossEncryptedDataType>, batchSize: number) {
    const itemSize = items.length;
    return Math.floor(itemSize / batchSize) + 1;
  }

  static getNumberOfBatch(items: Array<GlossEncryptedDataType>, batchSize: number) {
    return Math.ceil(items.length / batchSize);
  }

  static getBatches(items: Array<GlossEncryptedDataType>): Array<Array<GlossEncryptedDataType>> {
    /* Bitwarden backend as a fix size of 10 000 for the notes chiffer. This can be defined server side. */
    const blobbyConfig = process.env.BLOBBY as BlobbyConfigType;
    const SIZE_LIMIT = blobbyConfig.max_char ?? 10000;

    /* SHA-256 Enc has a total string overhead of 305 ( from google search ). The whole notes size will be at a minimum of 305 char */
    const ENCRYPTION_OVERHEAD_RATIO = blobbyConfig.enc_ratio ?? 1.4;
    /* Each iem in the array will produce extra JSON string charater to join them "," and multiply by the total number of item. */
    const JSON_BYTE_OVERHEAD_PER_ITEM = 1;
    const JSON_BYTE_OVERHEAD_ARRAY = 5;

    const batches: Array<Array<GlossEncryptedDataType>> = [];
    let currentTotalSize = 0;
    let currentBatch: Array<GlossEncryptedDataType> = [];

    /* For all item  loop and create batches that are under the maximum db size */
    for (const item of items) {
      const itemSize = JSON.stringify(item).length;
      if (
        SIZE_LIMIT <
        (currentTotalSize +
          itemSize +
          JSON_BYTE_OVERHEAD_PER_ITEM * currentBatch.length +
          JSON_BYTE_OVERHEAD_ARRAY) *
          ENCRYPTION_OVERHEAD_RATIO
      ) {
        batches.push(currentBatch);
        currentBatch = [];
        currentTotalSize = 0;
      }
      currentTotalSize += itemSize;
      currentBatch.push(item);
    }
    batches.push(currentBatch);
    return batches;
  }

  static roundDp(x: number): number {
    return Math.round(x * 100) / 100;
  }

  static setDateToYMD(d: Date): number {
    return d.getFullYear() * 10000 + (d.getMonth() + 1) * 100 + d.getDate();
  }

  static setYMDToDate(ymd: number): Date {
    // todo
    //  KM: Need to do this a better way to accommodate for timezones (at the moment only hard coded to +8 for HK time)
    //  also, this should be an utility function or library instead of putting it here
    const d = new Date();
    d.setFullYear(Math.floor(ymd / 10000));
    d.setMonth(Math.floor((ymd % 10000) / 100) - 1);
    d.setDate(ymd % 100);
    d.setHours(8);
    d.setMinutes(0);
    d.setSeconds(0);
    d.setMilliseconds(0);
    return d;
  }

  static isUpToTime(transactionDate: Date, balanceUpToDate: Date, inclusive: boolean): boolean {
    if (transactionDate.getTime() < balanceUpToDate.getTime()) {
      return true;
    } else if (inclusive && transactionDate.getTime() <= balanceUpToDate.getTime()) {
      return true;
    }
    return false;
  }

  static nextPeriod(dateAsNum: Date, recurringPeriod: string, recurringFreq: number): Date {
    if (recurringPeriod === RecurringPeriod.Days) {
      return addDays(new Date(dateAsNum), recurringFreq);
    } else if (recurringPeriod === RecurringPeriod.Weeks) {
      return addWeeks(new Date(dateAsNum), recurringFreq);
    } else if (recurringPeriod === RecurringPeriod.Months) {
      return addMonths(new Date(dateAsNum), recurringFreq);
    } else if (recurringPeriod === RecurringPeriod.Years) {
      return addYears(new Date(dateAsNum), recurringFreq);
    }
  }
}
