import { ConsoleLogService } from "@bitwarden/common/services/consoleLog.service";
import { ModelValidator } from "@bitwarden/web-vault/app/models/data/blobby/model.validator";
import { ReferenceData } from "@bitwarden/web-vault/app/models/data/blobby/reference-data.data";
import { TransactionResponse } from "@bitwarden/web-vault/app/models/data/response/transaction-response";
import { TransactionStatusEnum } from "@bitwarden/web-vault/app/models/enum/transactionType";
import { dayGranularity } from "@bitwarden/web-vault/app/models/types/balanceGroupingTypes";
import { SplitCategoryType } from "@bitwarden/web-vault/app/models/types/split-category-type";
import { SplitClassificationType } from "@bitwarden/web-vault/app/models/types/split-classification-type";
import { NormalizeTransaction } from "@bitwarden/web-vault/app/services/DataCalculationService/normalize/normalizeTransaction";

import { BalanceGrouping } from "../../../services/DataCalculationService/balanceGrouping/balanceGrouping";
import { EstimateType } from "../../enum/estimateType";
import { TransactionDirection } from "../../enum/transactionDirection";
import { CreatedRecords } from "../../types/scenario-group.types";
import { InterestMatchData } from "../interest-match.data";
import { RecurringData } from "../recurring.data";
import { EstimateActionResponse } from "../response/estimate-action.response";
import { Splitcategory } from "../splitcategory";
import { Splitclassification } from "../splitclassification";
import { TransactionTemplateData } from "../transaction-template.data";

import { Book } from "./book.data";
import { Transaction } from "./transaction.data";

export function getEmptyEstimateActionData(): EstimateActionData {
  return new EstimateActionData(new EstimateActionResponse({ __v: 1 }));
}

export class EstimateActionData extends ModelValidator {
  private readonly __v = 1;
  // internal prop be manage by the constructor and the set method
  private readonly _id: string;

  get id(): string {
    return this._id;
  }

  private _dateModified: string;
  private _dateCreated: string;
  actionType: string;
  name: string;
  account: Book; // Link to the account AccountIdReference
  estimateType: EstimateType; // transaction, interest
  startDate: string;
  direction: TransactionDirection; //<'in','out'> // the direction of the new transaction
  description: string; // the description that the new transaction will have
  categories: Splitcategory[];
  classifications: Splitclassification[];
  groupId: string;
  recurring: RecurringData; // RecurringIdReference - set to Null if is one off transaction
  transactionTemplate: TransactionTemplateData; // TransactionTemplateIDReference - set if transaction type is transaction
  interestMatch: InterestMatchData; // InterestMatchIdReference - set if estimateType is interest

  // possible Inputs
  parameters: object;

  // Created Outputs
  createdRecords: CreatedRecords;

  // logger: ConsoleLogService;

  groupedBalance: BalanceGrouping;

  constructor(response: EstimateActionResponse) {
    super();
    this._dateCreated = new Date().toUTCString();
    this._dateModified = new Date().toUTCString();

    if (response == null) {
      return;
    }

    this.checkVersion(this.__v, response.__v);

    this._id = response.id ? response.id : crypto.randomUUID();
    this.name = response.name;
    this.actionType = response.actionType;
    this.account = response.account;
    this.estimateType = response.estimateType;
    this.startDate = response.startDate;
    this.direction = response.direction;
    this.description = response.description;
    this.categories = response.categories;
    this.classifications = response.classifications;
    this.recurring = response.recurring;
    this.transactionTemplate = response.transactionTemplate;
    this.interestMatch = response.interestMatch;
    this.groupId = response.groupId;
  }

  setParameters(parameters: object) {
    this.parameters = parameters;
  }

  async run(parameters: object, createdRecords?: CreatedRecords): Promise<object> {
    // set the parameters
    this.setParameters(parameters);

    if (createdRecords?.groupedBalance) {
      this.groupedBalance = new BalanceGrouping([]);
      await this.groupedBalance.rebuildFromWebWorker(createdRecords.groupedBalance, [
        dayGranularity,
      ]);
    } else if (
      createdRecords?.groupedBalanceGranularity &&
      createdRecords?.groupedBalanceAttributes
    ) {
      this.groupedBalance = new BalanceGrouping([]);
      await this.groupedBalance.rebuildForWebWorker(
        createdRecords.groupedBalanceAttributes,
        createdRecords.groupedBalanceGranularity,
        [dayGranularity]
      );
    }
    this.createdRecords = {};
    return this.createdRecords;
  }

  /**
   * addTransaction - Given a transaction and a balance grouping, add the transaction to the end
   * of the balance grouping.
   *
   * @param interestTransaction
   * @param groupedBalance
   */
  async addTransaction(transaction: Transaction, groupedBalance: BalanceGrouping) {
    // if the grouping is processed, then just add to the existing grouping
    const transactionDate = new Date(transaction.transactionDate.date);
    if (
      groupedBalance.lastRunningDateEnd &&
      groupedBalance.lastRunningDateEnd.valueOf() >= transactionDate.valueOf()
    ) {
      await groupedBalance.addTransactionToExistingBalanceGrouping(transaction, true, ["account"]);
    } else {
      // if the grouping does not exist, then add the transaction and do a sum
      await groupedBalance.addTransactionToEndOfBalanceGrouping(transaction, true, ["account"]);
      await groupedBalance.sumGroupedBalancesOnDate(transactionDate);
    }
  }

  async createFakeTransaction(
    accountID: string,
    symbolAmount: number,
    symbol: string,
    transactionDate: Date,
    description: string,
    defaultSplitClassification: SplitClassificationType[],
    defaultSplitCategory: SplitCategoryType[],
    baseCurrency: string,
    referenceData: ReferenceData[],
    logger: ConsoleLogService,
    linkedTo?: Array<string>
  ): Promise<Transaction> {
    const fakeTransactionResponse = new TransactionResponse({
      __v: 1,
      accountId: accountID,
      description: description,
      quantity: symbolAmount,
      symbol: symbol,
      date: transactionDate.toDateString(),
      definition: TransactionStatusEnum.fake,
      _linkedTo: linkedTo,
    });
    const fakeTransaction = new Transaction(fakeTransactionResponse);
    fakeTransaction.classifications = defaultSplitClassification;
    fakeTransaction.categories = defaultSplitCategory;

    // TODO: pass in the logservice from somewhere
    const normalizeTransaction = new NormalizeTransaction(baseCurrency, logger, referenceData);
    await normalizeTransaction.normalizeImportedTransaction(fakeTransaction);

    // set the currency of the quantity
    if (!fakeTransaction.quantity.currency && fakeTransactionResponse.valuation.value.symbol) {
      fakeTransaction.quantity.currency = fakeTransactionResponse.valuation.value.symbol;
    }
    // set the valuation price
    if (!fakeTransaction.valuationPrice && fakeTransactionResponse.valuation.symbolValue) {
      fakeTransaction.valuationPrice = fakeTransactionResponse.valuation.symbolValue;
    }
    return fakeTransaction;
  }
}
