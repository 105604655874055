import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";

@Component({
  selector: "accordion-component",
  standalone: true,
  templateUrl: "./accordion.component.html",
  imports: [CommonModule],
})
export class AccordionComponent {
  @Input() set id(value: string) {
    this._id = "#" + value;
  }
  @Input() title = "";
  @Input() set content(value: string) {
    this._content = this.sanitizer.bypassSecurityTrustHtml(value);
  }
  @Output() accordionClicked = new EventEmitter<void>();
  protected isCollapsed = false;
  protected _content: SafeHtml;
  protected _id: string;
  constructor(private sanitizer: DomSanitizer) {}

  onAccordionClicked() {
    this.isCollapsed = !this.isCollapsed;
    this.accordionClicked.emit();
  }
}
