import { Injector } from "@angular/core";
import * as d3 from "d3";
import { DeviceDetectorService } from "ngx-device-detector";

import { DateTimeFormatter } from "@bitwarden/web-vault/app/components/primary-summary-graph/graph-elements/date-time-formatter";
import { DottedLines } from "@bitwarden/web-vault/app/components/primary-summary-graph/graph-elements/dotted-lines";
import { GraphDataSet, ParsedDates } from "@bitwarden/web-vault/app/models/types/graph.types";
import { ScenarioData } from "@bitwarden/web-vault/app/models/types/scenario-group.types";

export class Axis {
  private graphContentGroup: any;
  private dateTimeFormatter: DateTimeFormatter;
  private xScale: d3.ScaleBand<string>;
  private yScale: d3.ScaleLinear<number, number, never>;
  private maxYAxisValue: number;
  private minYAxisValue: number;
  private deviceService: DeviceDetectorService;
  private baseCurrency: string;
  constructor(
    graphContentGroup: any,
    dateTimeFormatter: DateTimeFormatter,
    xScale: d3.ScaleBand<string>,
    yScale: d3.ScaleLinear<number, number, never>,
    baseCurrency: string,
    injector: Injector
  ) {
    this.graphContentGroup = graphContentGroup;
    this.dateTimeFormatter = dateTimeFormatter;
    this.xScale = xScale;
    this.yScale = yScale;
    this.deviceService = injector.get(DeviceDetectorService);
    this.baseCurrency = baseCurrency;
  }

  drawAxis(innerHeight: number, graphData: Array<GraphDataSet>, parsedDates: ParsedDates) {
    this.graphContentGroup
      .append("g")
      .attr("id", "x-axis")
      .attr("transform", `translate(0,${innerHeight})`)
      .style("stroke-width", "0.5px")
      .call(this.createXAxis(parsedDates))
      .selectAll("text")
      //.attr("transform", this.deviceService.isMobile() ? "" : "rotate(-45)") // Adjust the rotation angle as needed
      .style("text-anchor", "middle");

    this.graphContentGroup
      .append("g")
      .attr("id", "y-axis")
      .style("stroke-width", "0.5px")
      .call(this.createYAxis(graphData)); // left: text will be shown left of the line
  }

  redrawAxis(innerHeight: number, graphData: Array<GraphDataSet>, parsedDates: ParsedDates) {
    this.graphContentGroup
      .select("#x-axis")
      .attr("transform", `translate(0,${innerHeight})`)
      .style("stroke-width", "0.5px")
      .call(this.createXAxis(parsedDates))
      .selectAll("text")
      //.attr("transform", this.deviceService.isMobile() ? "" : "rotate(-45)") // Adjust the rotation angle as needed
      .style("text-anchor", "middle")
      .transition()
      .ease(d3.easePolyInOut)
      .duration(500); // Adjust text-anchor for proper alignment

    this.redrawYAxis(innerHeight, graphData);
  }

  redrawYAxis(innerHeight: number, graphData: Array<GraphDataSet>) {
    this.graphContentGroup
      .select("#y-axis")
      .transition()
      .ease(d3.easePolyInOut)
      .duration(500)
      .style("stroke-width", "0.5px")
      .call(this.createYAxis(graphData));
  }

  /**
   * Collate all the data points and look for a minimum and maximum y value
   * @private
   */
  calculateMaxMinYAxisValues(
    dottedLines: DottedLines,
    graphData?: GraphDataSet[],
    scenarioData?: ScenarioData
  ) {
    // using the data from the balance graph and the scenario
    // work out the upper and lower boundaries of the y-axis
    this.maxYAxisValue = null;
    this.minYAxisValue = null;

    if (graphData && graphData.length > 0) {
      this.parseDataSetArrayForMinMax(graphData, dottedLines.getYValueOut, dottedLines.getYValueIn);
    }

    if (scenarioData?.scenario) {
      for (const scenario of scenarioData.scenario) {
        this.parseDataSetArrayForMinMax(
          scenario.graphData,
          (d) => d.balance,
          (d) => d.balance
        );
      }
    }

    if (scenarioData?.anchorPoint && scenarioData.anchorPoint !== null) {
      if (scenarioData.anchorPoint.anchorBalance !== null) {
        this.minYAxisValue = Math.min(this.minYAxisValue, scenarioData.anchorPoint.anchorBalance);
        this.maxYAxisValue = Math.max(this.maxYAxisValue, scenarioData.anchorPoint.anchorBalance);
      }
    }
    return [this.minYAxisValue, this.maxYAxisValue];
  }

  private parseDataSetArrayForMinMax(
    graphData: GraphDataSet[],
    yMinAccessor: (d: GraphDataSet) => number,
    yMaxAccessor: (d: GraphDataSet) => number
  ) {
    for (const graphDataSet of graphData) {
      const yMinValue = yMinAccessor(graphDataSet);
      const yMaxValue = yMaxAccessor(graphDataSet);

      if (this.minYAxisValue === null) {
        this.minYAxisValue = yMinValue;
      }
      if (this.maxYAxisValue === null) {
        this.maxYAxisValue = yMaxValue;
      }

      if (yMinValue !== null) {
        this.minYAxisValue = Math.min(this.minYAxisValue, yMinValue);
      }
      if (yMaxValue !== null) {
        this.maxYAxisValue = Math.max(this.maxYAxisValue, yMaxValue);
      }
    }
  }

  private createMobileXAxis(parsedDates: ParsedDates) {
    const xAxis = d3.axisBottom(this.xScale).tickFormat((d, i) => {
      const numberOfTicks = this.deviceService.isMobile() ? 6 : 10; // Specify the number of ticks you want
      const step = Math.ceil(this.xScale.domain().length / numberOfTicks);
      return i === 0 || i % step === 0
        ? parsedDates.find((l: { mY: string; display: string }) => l.mY == d).display
        : "";
    });

    return (g: d3.Selection<SVGGElement, unknown, null, undefined>) => {
      const displayedYears = new Set<string>();
      g.call(xAxis);
      g.selectAll("text").each(function () {
        const text = d3.select(this);
        const display = text.text();
        text.html(""); // Clear the existing text

        // Regular expression to match the year part
        const yearMatch = display.match(/(\d{4})/);
        if (yearMatch) {
          const year = yearMatch[0];

          const otherPart = display.replace(year, "").replace(/[,-]/g, "").trim();
          // Append the date part
          text
            .append("tspan")
            .attr("class", "tw-font-black")
            .attr("x", 0)
            .attr("dy", "1em")
            .text(otherPart);
          if (!displayedYears.has(year)) {
            // Append the year part
            text
              .append("tspan")
              .attr("class", "year-data-in-x-axis xs:tw-text-xs")
              .attr("x", 0)
              .attr("dy", "1.2em")
              .text(year);
            displayedYears.add(year);
          }
        } else {
          text.text(display);
        }
      });
    };
  }
  private createXAxis(parsedDates: ParsedDates) {
    return this.createMobileXAxis(parsedDates);
    /*    if (this.deviceService.isMobile()) {

    }

    return d3
      .axisBottom(this.xScale)
      .tickFormat(
        (d) => parsedDates.find((l: { mY: string; display: string }) => l.mY == d).display
      );*/
  }

  private addYAxisLabel(g: d3.Selection<SVGGElement, unknown, null, undefined>, title: string) {
    if (typeof g.append === "function") {
      g.append("text")
        .attr("class", "y-axis-title")
        .attr("dy", "-10px")
        .attr("dx", "-20px")
        .style("text-anchor", "middle")
        .style("font-size", "11px")
        .attr("fill", "#c6c6c6")
        .text(title);
    }
  }

  private createYAxis(graphData: Array<GraphDataSet>) {
    const yAxis = d3.axisLeft(this.yScale);

    // if (this.deviceService.isMobile()) {
    //   yAxis.ticks(4).tickFormat(d3.format(".3s"));
    // }
    yAxis.ticks(4).tickFormat(d3.format(".3s"));

    if (graphData.length === 1) {
      yAxis.ticks(1);
    }

    return (g: d3.Selection<SVGGElement, unknown, null, undefined>) => {
      g.call(yAxis);
      this.addYAxisLabel(g, "$" + this.baseCurrency); // Add your desired title here
    };
  }
}
