import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";

@Component({
  selector: "app-member-register",
  templateUrl: "./member-register.component.html",
})
export class MemberRegisterComponents implements OnInit {
  form: FormGroup;

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.initializeForm();
  }

  private initializeForm() {
    this.form = new FormGroup({
      email: new FormControl(null, [Validators.required, Validators.email]),
      name: new FormControl(null, [Validators.required]),
    });
  }

  handleSubmit() {
    if (this.form.valid) {
      this.router.navigate(["/membership-plan"], { queryParams: this.form.value });
    }
  }
}
