import { Component, EventEmitter, Input, Output, OnChanges, SimpleChanges } from "@angular/core";

import { Institution } from "@bitwarden/web-vault/app/models/data/blobby/institution.data";
import { Country } from "@bitwarden/web-vault/app/models/types/general-types";
import { InstitutionService } from "@bitwarden/web-vault/app/services/DataService/institution/institution.service";

@Component({
  selector: "app-institutions",
  templateUrl: "./institutions.component.html",
})
export class InstitutionsComponent implements OnChanges {
  private _institutions: Institution[] = [];
  institutions: Institution[] = this._institutions;
  @Input() country: Country;
  @Output() selectedInstitution: EventEmitter<Institution> = new EventEmitter<Institution>();

  constructor(private institutionService: InstitutionService) {}

  // TODO make sure that we include the existing institutions in the list
  async ngOnChanges(changes: SimpleChanges) {
    if (changes.country && changes.country.currentValue) {
      this._institutions = await this.institutionService.getInstitutionsMasterListObservable();
      this.institutions = this._institutions.filter(
        (institution) => institution.swift.countryCode === this.country.code
      );
    }

    if (changes.country && !changes.country.currentValue) {
      this.institutions = [];
    }
  }

  institutionSelected(institution: Institution) {
    if (this.selectedInstitution) {
      this.selectedInstitution.emit(institution);
    }
  }

  selectionCleared() {
    this.selectedInstitution.emit(null);
  }
}
