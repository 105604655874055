<div>
  <h1 class="tw-mt-12 tw-text-center tw-text-xl">{{ "createAccount" | i18n }}</h1>
  <div
    class="tw-min-w-xl tw-border-secondary-300 tw-m-auto tw-max-w-xl tw-rounded tw-border tw-border-solid tw-bg-background tw-p-8"
  >
    <form [formGroup]="form" *ngIf="form" class="tw-container tw-mx-auto">
      <div>
        <div class="tw-mb-3">
          <bit-form-field>
            <bit-label>{{ "emailAddress" | i18n }}</bit-label>
            <input id="register-form_input_email" bitInput type="email" formControlName="email" />
            <bit-hint>{{ "emailAddressDesc" | i18n }}</bit-hint>
          </bit-form-field>
        </div>
        <div class="tw-mb-3">
          <bit-form-field>
            <bit-label>{{ "name" | i18n }}</bit-label>
            <input id="register-form_input_name" bitInput type="text" formControlName="name" />
            <bit-hint>{{ "yourNameDesc" | i18n }}</bit-hint>
          </bit-form-field>
        </div>
      </div>
      <div class="modal-footer right-buttons-container">
        <button mat-raised-button color="primary" type="submit" (click)="handleSubmit()">
          {{ "create" | i18n }}
        </button>
      </div>
    </form>
  </div>
</div>
