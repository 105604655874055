import { Component, EventEmitter, Output } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Subject, takeUntil } from "rxjs";

import { RoleScope } from "../models/enum/role-access.enum";

import { DeauthorizeSessionsComponent } from "./deauthorize-sessions.component";
import { DeleteAccountComponent } from "./delete-account.component";
import { PurgeVaultComponent } from "./purge-vault.component";

@Component({
  selector: "app-danger-zone",
  templateUrl: "./danger-zone.component.html",
})
export class DangerZoneComponent {
  @Output() toggleDisplay = new EventEmitter<void>();
  private dialogRef: MatDialogRef<any> | null = null;
  private destroy$ = new Subject<void>();

  constructor(private dialog: MatDialog) {}

  deauthorizeSessions = async () => {
    this.dialogRef = this.dialog.open(DeauthorizeSessionsComponent, {
      panelClass: "mobile-dialog",
      width: "600px",
      maxWidth: "100vh",
    });
    this.dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.dialogRef = null;
      });
  };

  purgeVault = async () => {
    this.dialogRef = this.dialog.open(PurgeVaultComponent, {
      panelClass: "mobile-dialog",
      width: "600px",
      maxWidth: "100vh",
    });
    this.dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.dialogRef = null;
      });
  };

  deleteAccount = async () => {
    this.dialogRef = this.dialog.open(DeleteAccountComponent, {
      panelClass: "mobile-dialog",
      width: "600px",
      maxWidth: "100vh",
    });
    this.dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.dialogRef = null;
      });
  };

  isDialogOpen(): boolean {
    return this.dialogRef !== null;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  redirectToSettings() {
    this.toggleDisplay.emit();
  }

  protected readonly RoleScope = RoleScope;
}
