import { Category } from "@bitwarden/web-vault/app/models/data/blobby/category.data";
import { Classification } from "@bitwarden/web-vault/app/models/data/blobby/classification.data";
import { SplitCategoryType } from "@bitwarden/web-vault/app/models/types/split-category-type";
import { SplitClassificationType } from "@bitwarden/web-vault/app/models/types/split-classification-type";

export class TransactionFormValidator {
  selectedCategories: SplitCategoryType[] = [];
  selectedClassifications: SplitClassificationType[] = [];

  isCategoryExists(category: Category) {
    return this.selectedCategories.some(
      (item: SplitCategoryType) => category.id === item.categoryId
    );
  }

  isClassificationExists(classification: Classification) {
    return this.selectedClassifications.some(
      (item: SplitClassificationType) => classification.id === item.classificationId
    );
  }
}
