import * as d3 from "d3";

import { DataLine } from "@bitwarden/web-vault/app/components/primary-summary-graph/graph-elements/data-line";
import { DateTimeFormatter } from "@bitwarden/web-vault/app/components/primary-summary-graph/graph-elements/date-time-formatter";
import { Slider } from "@bitwarden/web-vault/app/components/primary-summary-graph/graph-elements/slider";
import { GraphDataSet } from "@bitwarden/web-vault/app/models/types/graph.types";

export class DottedLines {
  private graphContentGroup: any;
  private dateTimeFormatter: DateTimeFormatter;
  private slider: Slider;
  private xScale: d3.ScaleBand<string>;
  private yScale: d3.ScaleLinear<number, number, never>;
  private incomeColour = "#00C4C4";
  private expenseColour = "#2A6CE2";

  constructor(
    graphContentGroup: any,
    dateTimeFormatter: DateTimeFormatter,
    slider: Slider,
    xScale: d3.ScaleBand<string>,
    yScale: d3.ScaleLinear<number, number, never>
  ) {
    this.graphContentGroup = graphContentGroup;
    this.dateTimeFormatter = dateTimeFormatter;
    this.xScale = xScale;
    this.yScale = yScale;
    this.slider = slider;
  }

  generateDottedLineData(graphData: GraphDataSet[]): number[][] {
    const dottedLineData = [];

    for (let i = 1; i < graphData.length; i++) {
      const d = graphData[i];
      const prevData = graphData[i - 1];
      let x1;
      if (!prevData?.midDate) {
        x1 =
          this.xScale.bandwidth() / 2 +
          this.xScale(
            this.dateTimeFormatter.formatYearDate(this.dateTimeFormatter.timeParse(prevData.date))
          );
      } else {
        const bandData = this.slider.findBand(prevData.midDate);
        x1 = this.slider.calculateMiddleX(bandData, prevData.midDate);
      }
      const y1 = this.yScale(d.prevBalance);
      let x2;
      if (!d?.midDate) {
        x2 =
          this.xScale.bandwidth() / 2 +
          this.xScale(
            this.dateTimeFormatter.formatYearDate(this.dateTimeFormatter.timeParse(d.date))
          );
      } else {
        const bandData = this.slider.findBand(d.midDate);
        x2 = this.slider.calculateMiddleX(bandData, d.midDate);
      }
      const y2 = this.yScale(this.getYValueIn(d));
      const y3 = this.yScale(this.getYValueOut(d));

      dottedLineData.push([x1, y1, x2, y2, y3]);
    }
    return dottedLineData;
  }

  plotDottedLines(graphData: GraphDataSet[], dataLine: DataLine) {
    const dottedLineData = this.generateDottedLineData(graphData);
    dataLine.updateLine(
      "dottedBalanceLine",
      dottedLineData,
      1,
      "#777",
      true,
      0.8,
      "previousBalance",
      "3,3"
    );
    dataLine.updateLine(
      "dottedInLine",
      dottedLineData,
      3,
      this.incomeColour,
      false,
      0.8,
      "incomeArrow",
      "3,3"
    );
    dataLine.updateLine(
      "dottedOutLine",
      dottedLineData,
      4,
      this.expenseColour,
      false,
      0.8,
      "expenseArrow",
      "3,3"
    );
  }

  getYValueIn(d: GraphDataSet) {
    return d.prevBalance + d.in;
  }

  getYValueOut(d: GraphDataSet) {
    return d.prevBalance - d.out;
  }
}
