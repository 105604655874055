import { DeviceDetector } from "./device-detector";

export class HelperPagination {
  private deviceDetectorService: DeviceDetector;

  constructor() {
    this.deviceDetectorService = new DeviceDetector();
  }

  /**
   * A Pagination helper function to determine if pagination is enabled.
   */
  isPaginationEnabled() {
    return this.enabledPaginationDevice();
  }

  /**
   * Enable the pagination for desktop and tablet devices.
   */
  private enabledPaginationDevice() {
    return this.deviceDetectorService.isDesktop() || this.deviceDetectorService.isTablet();
  }
}
