<app-spinner [showSpinner]="showSpinner" [diameter]="50"></app-spinner>
<div id="dashboard-selector" class="tw-flex">
  <div>
    <app-help-tooltip
      [showHelpTooltip]="showHelpTooltip"
      [tooltipInfo]="tooltipInfo"
      [xPosition]="xPosition"
      [yPosition]="yPosition"
      [pointer]="pointer"
      (onTooltipClose)="onTooltipClose()"
    ></app-help-tooltip>
  </div>
  <app-select
    labelKey="Dashboard"
    [selectedValue]="dashboardID"
    selectElementId="dashboardType"
    [itemList]="dashboardTypes"
    (selectEvent)="updateDashboardSelection($event)"
    valueKey="dashboardID"
    displayKey="display"
  ></app-select>

  <div
    class="tw-ml-1 tw-flex tw-h-12 tw-cursor-pointer tw-items-end tw-rounded-lg tw-p-1 xs:tw-items-end"
    title="{{ 'dashboardHelpTitle' | i18n }}"
    (click)="openDashboardHelp()"
  >
    <mat-icon
      id="help-info-button"
      class="mat-icon-secondary mat-icon-tooltip"
      [ngClass]="{ clicked: isClicked }"
      (click)="toggleClick()"
    >
      info_outline
    </mat-icon>
  </div>
</div>
