import { createActionGroup, emptyProps, props } from "@ngrx/store";

import { SideMenu } from "../side-menu";

export const SideMenuActions = createActionGroup({
  source: "SideMenu",
  events: {
    Initialize: emptyProps(),
    Load: emptyProps(),
    "Load Success": props<{ sideMenu: SideMenu }>(),
    Toggle: props<{ sideMenu: SideMenu }>(),
  },
});
