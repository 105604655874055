// async-cell-renderer.component.ts
import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";

import { CategoryPipe } from "@bitwarden/web-vault/app/pipes/category.pipe";

@Component({
  selector: "app-category-renderer-cell-renderer",
  template: `{{ value }}`,
})
export class CategoryCellRenderer implements ICellRendererAngularComp {
  value: string;

  constructor(private categoryPipe: CategoryPipe) {}

  async agInit(params: any): Promise<void> {
    if (params.value) {
      this.value = await this.categoryPipe.getCategoriesNamesList(params.value);
    } else {
      this.value = "No category found";
    }
  }

  refresh(params: any): boolean {
    return false; // No need to refresh since data fetch is already handled
  }
}
