import { HttpClient } from "@angular/common/http";
import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { format, isValid, parse } from "date-fns";

import { I18nService } from "@bitwarden/common/abstractions/i18n.service";
import { PlatformUtilsService } from "@bitwarden/common/abstractions/platformUtils.service";
import { BaseImporter } from "@bitwarden/common/importers/base-importer";
import { ReferenceDataCsvImporter } from "@bitwarden/web-vault/app/importers/reference-data-csv-importer";
import { ReferenceData } from "@bitwarden/web-vault/app/models/data/blobby/reference-data.data";
import { GlossDate } from "@bitwarden/web-vault/app/models/data/shared/gloss-date";
import { DateFormatPipe } from "@bitwarden/web-vault/app/pipes/date-format.pipe";
import { ReferenceDataService } from "@bitwarden/web-vault/app/services/DataService/reference-data/reference-data.service";
import HelperCsvImporter from "@bitwarden/web-vault/app/shared/utils/helper-csv-importer";
import DateFormat from "@bitwarden/web-vault/app/shared/utils/helper.date/date-format";

@Component({
  selector: "app-reference-data-import",
  templateUrl: "./reference-data-import.component.html",
})
export class ReferenceDataImportComponent extends BaseImporter implements OnInit {
  @Output() createTabEvent = new EventEmitter<void>();
  @Output() importedReferenceDataEvent = new EventEmitter<Array<ReferenceData>>();
  @Output() previewEvent = new EventEmitter<{ createTab: boolean; data?: any }>();
  isImporting = false;
  selectedCurrencyFileName = "";
  currencyForm: FormGroup;
  isImportComplete = false;

  constructor(
    private http: HttpClient,
    protected referenceCsvImporter: ReferenceDataCsvImporter,
    protected i18nService: I18nService,
    protected platformUtilsService: PlatformUtilsService,
    private referenceDataService: ReferenceDataService,
    private formBuilder: FormBuilder //    private transactionService: TransactionService, //   private transactionNormalizeService: TransactionNormalizeService, //   private dataRepositoryService: DataRepositoryService
  ) {
    super();
    this.currencyForm = this.formBuilder.group({
      currencyCsvFile: [null, [Validators.required]],
    });
  }

  async ngOnInit() {
    this.isImporting = false;
  }

  async preview(fileUploadInputId: string): Promise<void> {
    const fileInputElement = document.getElementById(fileUploadInputId) as HTMLInputElement;

    /** Validate input and Selection **/
    if (!this.validateFileInput(fileInputElement)) {
      return;
    }

    try {
      /** Lock the component has importing in progress. **/
      this.isImporting = true;

      /** Read File Content. **/
      const content = await this.getFileContent(fileInputElement.files[0]);

      /** Parse Content and map references **/
      const csvResults = this.parseReferenceDataCsv(content);
      const parsingResult = await this.referenceCsvImporter.parse(csvResults);

      /** Emit result **/
      this.previewEvent.emit({ createTab: true, data: parsingResult.symbols });
    } catch (e) {
      this.logService.error(e);
    } finally {
      this.isImporting = false;
    }
  }

  private validateFileInput(fileInputElement: HTMLInputElement) {
    if (!fileInputElement.files || fileInputElement.files.length === 0) {
      this.platformUtilsService.showToast(
        "error",
        this.i18nService.t("errorOccurred"),
        this.i18nService.t("selectFile")
      );
      return false;
    }

    return true;
  }

  private async getFileContent(file: File) {
    const helperCsvImporter = new HelperCsvImporter();
    return await helperCsvImporter.getFileContents(file);
  }

  protected parseReferenceDataCsv(content: string): any[] {
    const parsedData = this.parseCsv(content, true);
    const uniqueDates = new Set(parsedData.map((row) => row.YMD));
    const dateSeparatorRegex = /[T, @\s]+/;
    const dateFormatSample = [...uniqueDates].map(
      (dateString) => dateString.split(dateSeparatorRegex)[0]
    );
    const possibleDateFormatsFromRegEx =
      DateFormat.getPossibleDateFormatsFromRegEx(dateFormatSample);
    const possibleDateFormat = possibleDateFormatsFromRegEx.highestMatchFormats[0];
    const uniqueBases = new Set(parsedData.map((row) => row.base));

    const referenceData = [];
    for (const date of uniqueDates) {
      for (const base of uniqueBases) {
        const symbols: { [key: string]: number } = {};
        const filteredRows = parsedData.filter((row) => row.YMD === date);

        for (const row of filteredRows) {
          for (const reference of Object.keys(row)) {
            if (reference !== "YMD" && reference !== "base") {
              symbols[reference] = parseFloat(row[reference]);
            }
          }
        }

        referenceData.push({
          date: this.createGlossDate(date, possibleDateFormat),
          base,
          symbols,
        });
      }
    }
    return referenceData;
  }

  uploadListener(event: Event) {
    this.selectedCurrencyFileName = this.getSelectedFileName(event);
  }

  private getSelectedFileName(event: Event): string {
    const inputElement = event.target as HTMLInputElement;
    const file = inputElement.files[0];
    return file ? file.name : "";
  }

  private createGlossDate(dateString: string, possibleDateFormat: string): GlossDate {
    let date: Date = null;
    let formattedDate: string = null;
    let formattedTime: string = null;
    let formattedTimezone: string = null;

    try {
      const dateSeparatorRegex = /[T@\s]+/;
      const parts = dateString.split(dateSeparatorRegex);
      const datePart = parts[0];
      const defaultFormat = DateFormatPipe.defaultFormat;
      date = parse(datePart, defaultFormat, GlossDate.getNow());

      if (isValid(date)) {
        formattedDate = format(date, defaultFormat);
      }

      if (parts.length >= 2) {
        const timePart = parts.slice(1).join(dateSeparatorRegex.source);
        formattedTime = DateFormat.getPossibleTimeFormatsFromRegEx(timePart);
        formattedTimezone = DateFormat.getPossibleTimeZoneFormatsFromRegEx(timePart);
      }
    } catch (e) {
      this.logService.error(e);
    }

    const glossDate = new GlossDate();
    glossDate.date = new Date(formattedDate);
    glossDate.time = formattedTime;
    glossDate.timeZone = formattedTimezone;

    return glossDate;
  }
}
