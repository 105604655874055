import { fromEvent, Observable, Subject, takeUntil } from "rxjs";

import { WebWorker } from "./web.worker";
import { WorkerMessage } from "./worker.message";

export class WebWorkerImplementation<T extends WorkerMessage> extends WebWorker {
  private clear$ = new Subject<void>();
  private source: Observable<MessageEvent>;

  constructor(worker: Worker) {
    super(worker);
    this.source = fromEvent<MessageEvent>(worker, this.type);
  }

  terminate(): void {
    this.clear$.next();
    super.terminate();
  }

  onMessage<T>(observer: (value: T) => void): void {
    this.source.pipe(takeUntil(this.clear$)).subscribe((value) => observer(value.data));
  }

  do(message: T): void {
    this.postMessage(message);
  }
}
