import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Subject } from "rxjs";

import { AccountProgressBar } from "./account-progress-bar";
import { BalanceProgressBar } from "./balance-progress-bar";
import { CsvProgressBar } from "./csv-progress-bar";
import { ProgressBar } from "./progress-bar";
import { ProgressBarType as PBType } from "./progress-bar.enum";
import { ProgressBarFactory } from "./progress-bar.factory";

import "./progress-bar-loader.scss";
@Component({
  selector: "app-progress-bar",
  templateUrl: "./progress-bar.component.html",
  styles: ["progress-bar-loader.scss"],
})
export class ProgressBarComponent extends ProgressBar implements OnInit, OnDestroy {
  @Input() isStart = false;
  @Input() parentLabel: string;
  @Input() isInfo: boolean;
  @Input() infoText = "";
  protected unsubscribe$ = new Subject<void>();

  csvProgressBar: CsvProgressBar;
  accountsProgressBar: AccountProgressBar;
  balanceProgressBar: BalanceProgressBar;
  progressBarFactory: ProgressBarFactory;

  constructor() {
    super();
    this.progressBarFactory = new ProgressBarFactory(
      this.loadingPercent,
      this.importingLabel,
      this.totalItems
    );

    /** Create the concrete Progress Bar from the factory */
    this.createProgressBar();
  }

  ngOnInit() {
    this.csvImports();
    this.accountCreation();
    this.balanceAlignment();
  }

  private createProgressBar() {
    this.csvProgressBar = this.progressBarFactory.create(PBType.Csv) as CsvProgressBar;
    this.accountsProgressBar = this.progressBarFactory.create(PBType.Account) as AccountProgressBar;
    this.balanceProgressBar = this.progressBarFactory.create(PBType.Balance) as BalanceProgressBar;
  }

  private csvImports() {
    this.csvProgressBar.show();
  }

  private accountCreation() {
    this.accountsProgressBar.show();
  }

  private balanceAlignment() {
    this.balanceProgressBar.show();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
