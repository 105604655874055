<div class="modal-body">
  <h1>{{ "transactionDeleteConfirmation" | i18n }}</h1>
  <div class="button-group">
    <button mat-raised-button mat-dialog-close (click)="handleCancel()">
      {{ "cancel" | i18n }}
    </button>
    <button mat-raised-button color="primary" (click)="handleDelete()">
      {{ "delete" | i18n }}
    </button>
  </div>
</div>
