import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { mergeMap, of } from "rxjs";
import { catchError, map } from "rxjs/operators";

import { RevaluationService } from "@bitwarden/web-vault/app/services/DataCalculationService/revaluation/revaluation.service";

import { RevaluationActions } from "../../transaction/state/revaluation.action";
@Injectable()
export class RevaluationEffects {
  constructor(private actions$: Actions, private revaluationService: RevaluationService) {}

  createRevaluations$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RevaluationActions.create),
      mergeMap(() =>
        this.revaluationService.createRevaluations$().pipe(
          map((revaluations) => RevaluationActions.createSuccess({ revaluations })),
          catchError((error: unknown) => of(RevaluationActions.createFailure({ error })))
        )
      )
    );
  });
}
