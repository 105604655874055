import { Injectable } from "@angular/core";
import { ComponentStore } from "@ngrx/component-store";
import { Observable, take } from "rxjs";

import { GlossDate } from "@bitwarden/web-vault/app/models/data/shared/gloss-date";
import {
  AccountSyncStatus,
  GlossConnectorMessage,
  Origin,
  SyncState,
} from "@bitwarden/web-vault/app/models/types/general-types";
import { SyncStatusPoints } from "@bitwarden/web-vault/app/services/api/basiq/fixture/sync-status-messages";
import { BasiqConnector } from "@bitwarden/web-vault/app/services/syncing/basiq-connector.service";
import { BasiqInstitutionConnector } from "@bitwarden/web-vault/app/services/syncing/basiq-institution-connector";
import { GlossSyncService } from "@bitwarden/web-vault/app/services/syncing/gloss-sync.service";

export interface SyncStoreInterface {
  isStarted: boolean;
  isCompleted: boolean;
  isAvailable: boolean;
  accountStatus: AccountSyncStatus[];

  basiqConnector: BasiqConnector;
  basiqInstitutionConnections: BasiqInstitutionConnector[];
}

@Injectable({
  providedIn: "root",
})
export class SyncStore extends ComponentStore<SyncStoreInterface> {
  constructor(private glossSyncService: GlossSyncService) {
    super({
      isStarted: false,
      isCompleted: false,
      isAvailable: true,
      accountStatus: [],
      basiqConnector: null,
      basiqInstitutionConnections: [],
    });

    this.glossSyncService.getLastStatus().then((syncStatus) => {
      this.setAccountStatus(syncStatus.accountStatus);
    });
  }

  readonly isStarted$: Observable<boolean> = this.select((state) => state.isStarted);
  readonly isCompleted$: Observable<boolean> = this.select((state) => state.isCompleted);
  readonly isAvailable$: Observable<boolean> = this.select((state) => state.isAvailable);
  readonly accountStatus$: Observable<AccountSyncStatus[]> = this.select(
    (state) => state.accountStatus
  );
  readonly basiqConnector$: Observable<BasiqConnector> = this.select(
    (state) => state.basiqConnector
  );
  readonly basiqInstitutionConnections$: Observable<BasiqInstitutionConnector[]> = this.select(
    (state) => state.basiqInstitutionConnections
  );

  readonly vm$ = this.select(
    this.isStarted$,
    this.isCompleted$,
    this.isAvailable$,
    this.accountStatus$,
    this.basiqConnector$,
    this.basiqInstitutionConnections$,
    (
      isStarted,
      isCompleted,
      isAvailable,
      accountStatus,
      basiqConnector,
      basiqInstitutionConnections
    ): SyncState => ({
      isStarted,
      isCompleted,
      isAvailable,
      accountStatus,
      basiqConnector,
      basiqInstitutionConnections,
    })
  );

  readonly getCurrentState = this.get((state) => state);

  readonly setStarted = this.updater((state) => {
    const accountStatus = state.accountStatus.map((status) => {
      status.isStarted = true;
      status.point = SyncStatusPoints["started"];
      status.lastSyncedAt = GlossDate.getCurrentGlossDate();
      return status;
    });
    return {
      ...state,
      accountStatus: accountStatus,
      isStarted: true,
      isCompleted: false,
    };
  });

  readonly setCompleted = this.updater((state) => ({
    ...state,
    isCompleted: true,
  }));

  readonly setIsAvailable = this.updater((state, isAvailable: boolean) => ({
    ...state,
    isAvailable: isAvailable,
    isCompleted: !isAvailable ? true : state.isCompleted,
  }));

  readonly setAccountStatus = this.updater((state, accountStatus: AccountSyncStatus[]) => ({
    ...state,
    accountStatus: accountStatus,
    isCompleted: accountStatus.every((status) => status.isCompleted),
  }));

  readonly updateAccountStatus = this.updater((state, accountStatus: AccountSyncStatus[]) => {
    const newAccountStatus = state.accountStatus.map((status) => {
      const _status = accountStatus.find((newStatus) => newStatus.accountId === status.accountId);
      if (_status) {
        status = _status;
      }

      return status;
    });
    return {
      ...state,
      accountStatus: newAccountStatus,
      isCompleted: newAccountStatus.every((status) => status.isCompleted),
    };
  });

  readonly setBasiqConnector = this.updater((state, basiqConnector: BasiqConnector) => ({
    ...state,
    basiqConnector: basiqConnector,
  }));

  readonly setInstitutionConnections = this.updater(
    (state, institutionConnections: BasiqInstitutionConnector[]) => ({
      ...state,
      basiqInstitutionConnections: institutionConnections,
    })
  );

  async startSync() {
    const isAvailable = await this.glossSyncService.isStatusAvailable();
    if (!isAvailable) {
      this.setIsAvailable(isAvailable);
      return;
    }

    this.setStarted();

    const connectors = this.glossSyncService.getGlossConnectors();
    const basiqConnector = connectors.find((connector) => connector.origin === Origin.basiq);
    const glossConnectorMessage = await basiqConnector.isConnectorAvailable();
    if (!glossConnectorMessage?.isOpen) {
      const accountStatus = this.killBasiqAccounts(glossConnectorMessage);
      await this.glossSyncService.updateFailedConnectorsInBlobby(accountStatus);
      this.killBasiqConnector(accountStatus);
      return;
    }

    this.setBasiqConnector(basiqConnector);

    const validInstitutionConnections: BasiqInstitutionConnector[] = [];
    const killedAccountStatus: AccountSyncStatus[] = [];
    for (const institutionConnection of basiqConnector.institutionConnectors) {
      const isValid = await institutionConnection.isValid();
      if (!isValid) {
        const accountStatus = this.getKilledBasiqInstitutionAccounts(institutionConnection);
        await this.glossSyncService.updateFailedConnectorsInBlobby(accountStatus);
        killedAccountStatus.concat(accountStatus);
      } else {
        validInstitutionConnections.push(institutionConnection);
      }
    }

    if (killedAccountStatus.length > 0) {
      this.updateAccountStatus(killedAccountStatus);
    }

    this.setInstitutionConnections(validInstitutionConnections);

    this.basiqInstitutionConnections$.pipe(take(1)).subscribe((validInstitutionConnections) => {
      validInstitutionConnections.forEach((institutionConnection) => {
        institutionConnection.syncStatus$.subscribe((newStatuses) => {
          if (newStatuses) {
            this.updateAccountStatus(newStatuses);
          }
        });

        institutionConnection.startSync();
      });
    });
  }

  getKilledBasiqInstitutionAccounts(institutionConnection: BasiqInstitutionConnector) {
    return this.getCurrentState.accountStatus.filter((status) => {
      if (status.accountView.institution.basiqId === institutionConnection.id) {
        status.point = {
          key: "failed",
          icon: "error",
          data: {
            type: "failure",
            message: "Gloss Vault couldn't connect to your institution",
            messageI18nKey: "noConnectionFound",
            actions: ["connect"],
          },
        };
        status.isCompleted = true;
        return status;
      }
    });
  }

  readonly killBasiqConnector = this.updater((state, accountStatus: AccountSyncStatus[]) => ({
    ...state,
    accountStatus: accountStatus,
    basiqConnector: null,
  }));

  killBasiqAccounts(connectorMessage: GlossConnectorMessage): AccountSyncStatus[] {
    return this.getCurrentState.accountStatus.map((status) => {
      if (status.accountView.origin === Origin.basiq) {
        status.point = {
          key: "failed",
          icon: "error",
          data: {
            type: "failure",
            message: connectorMessage.message,
            messageI18nKey: connectorMessage.message,
            actions: connectorMessage.actions,
          },
        };
        status.isCompleted = true;
      }

      return status;
    });
  }
}
