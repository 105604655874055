import { createActionGroup, emptyProps, props } from "@ngrx/store";

import { Transaction } from "../../../../models/data/blobby/transaction.data";

export const TransactionActions = createActionGroup({
  source: "Transaction",
  events: {
    Initialize: emptyProps(),
    Load: emptyProps(),
    "Load Success": props<{ transactions: Transaction[] }>(),
    "Load Failure": props<{ error: unknown }>(),
    Clear: emptyProps(),
  },
});
