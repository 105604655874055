import { createFeatureSelector, createReducer, createSelector, on } from "@ngrx/store";

import { Transaction } from "../../../../models/data/blobby/transaction.data";
import { getErrorMessage } from "../../../../shared/utils/helper-state-management";

import { TransactionActions } from "./transaction.action";
export const transactionFeatureKey = "transactions-data";

export interface TransactionState {
  isLoading: boolean;
  isLoaded: boolean;
  transactions: Transaction[];
  error: unknown;
}

const initialState: TransactionState = {
  isLoading: true,
  isLoaded: false,
  transactions: [],
  error: undefined,
};

const getTransactionFeatureState = createFeatureSelector<TransactionState>(transactionFeatureKey);

export const getIsLoading = createSelector(getTransactionFeatureState, (state) => state.isLoading);

export const getIsLoaded = createSelector(getTransactionFeatureState, (state) => state.isLoaded);

export const getTransactions = createSelector(
  getTransactionFeatureState,
  (state) => state.transactions
);

export const getError = createSelector(getTransactionFeatureState, (state) => state.error);

export const transactionReducer = createReducer<TransactionState>(
  initialState,
  on(TransactionActions.initialize, (state): TransactionState => {
    return state;
  }),
  on(TransactionActions.loadSuccess, (state, action): TransactionState => {
    return {
      ...state,
      isLoading: false,
      isLoaded: true,
      transactions: action.transactions,
    };
  }),
  on(TransactionActions.loadFailure, (state, action): TransactionState => {
    return {
      ...state,
      isLoading: false,
      isLoaded: true,
      transactions: undefined,
      error: getErrorMessage(action.error),
    };
  }),
  on(TransactionActions.clear, (state, action): TransactionState => {
    return {
      ...state,
      isLoading: false,
      isLoaded: true,
      transactions: [],
      error: undefined,
    };
  })
);
