import { Origin } from "@bitwarden/web-vault/app/models/types/general-types";
import { AccountView } from "@bitwarden/web-vault/app/models/view/account.view";

const AgGridRightClickEditButton = (params: any) => {
  const parent = params.context.componentParent;
  const { i18nService } = parent;

  return {
    name: i18nService.t("edit"),
    action: function () {
      parent.editRight(params.node.data);
    },
    icon: '<span class="material-icons">edit</span>',
  };
};

const AgGridRightClickDeleteButton = (params: any) => {
  const parent = params.context.componentParent;
  const { i18nService } = parent;

  return {
    name: i18nService.t("delete"),
    action: function () {
      parent.deleteRight(params.node.data);
    },
    cssClasses: ["redFont", "bold"],
    icon: '<span class="material-icons">delete</span>',
  };
};

const AgGridRightClickAddEntryButton = (params: any) => {
  const accountView: AccountView = params.node.data;
  if (accountView.origin !== Origin.manual) {
    return null;
  }
  const parent = params.context.componentParent;
  const { i18nService } = parent;

  return {
    name: i18nService.t("addEntry"),
    action: function () {
      parent.addEntryRight(params.node.data);
    },
    cssClasses: ["redFont", "bold"],
    icon: '<span class="material-icons">account_balance_wallet</span>',
  };
};

export const getAccountRightClickButtons = (params: any) => {
  const addEntryButton = AgGridRightClickAddEntryButton(params);
  const editButton = AgGridRightClickEditButton(params);
  const deleteButton = AgGridRightClickDeleteButton(params);

  const rightClickButtons: any = [];

  if (addEntryButton) {
    rightClickButtons.push(addEntryButton);
  }

  if (editButton) {
    rightClickButtons.push(editButton);
  }

  if (deleteButton) {
    rightClickButtons.push(deleteButton);
  }

  return rightClickButtons;
};
