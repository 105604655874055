<app-spinner [showSpinner]="showSpinner" [diameter]="50"></app-spinner>
<div class="sync-container">
  <div class="sync-buttons-container">
    <button
      [disabled]="!syncState.isCompleted"
      class="table-action-buttons right-space"
      matTooltip="Merge your transactions"
      (click)="importSyncedData()"
    >
      <mat-icon class="transaction-action-button-icon download-button" aria-hidden="true"
        >merge</mat-icon
      >
      {{ "merge" | i18n }}
    </button>

    <button
      class="table-action-buttons"
      matTooltip="Synchronize your data"
      (click)="syncManually()"
    >
      <mat-icon class="transaction-action-button-icon download-button" aria-hidden="true"
        >sync</mat-icon
      >
      {{ "sync" | i18n }}
    </button>
  </div>

  <div *ngIf="vm$ | async as vm">
    <ag-grid-angular
      class="ag-theme-quartz"
      style="height: 200px; max-width: 2000px; min-width: 1080px"
      [rowData]="vm.accountStatus"
      [columnDefs]="colDefs"
      [context]="context"
      [autoSizeStrategy]="autoSizeStrategy"
      (firstDataRendered)="onFirstDataRendered($event)"
      (gridReady)="onGridReady($event)"
    ></ag-grid-angular>
  </div>
</div>
