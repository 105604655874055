import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

import { BalanceAlignment as BalanceAlignmentClass } from "@bitwarden/web-vault/app/services/DataCalculationService/balanceAlignment/balanceAlignment";
import { HelperPreference } from "@bitwarden/web-vault/app/shared/utils/helper.preference";

import { LogService } from "../../../../../../../libs/common/src/abstractions/log.service";
import { Transaction } from "../../../models/data/blobby/transaction.data";
import { DataRepositoryService } from "../../DataRepository/data-repository.service";

@Injectable({
  providedIn: "root",
})
export class BalanceAlignmentService {
  private balanceAlignmentClass: BalanceAlignmentClass;
  baseCurrency: string;
  private initialized = false;

  constructor(
    private dataRepositoryService: DataRepositoryService,
    private logService: LogService,
    private helperPreferences: HelperPreference
  ) {}

  private numberOfBalanceAlignmentOnProcess = new BehaviorSubject<number>(0);
  numberOfBalanceAlignmentOnProcess$ = this.numberOfBalanceAlignmentOnProcess.asObservable();

  private totalAlignmentTransactionNumber = new BehaviorSubject<number>(0);
  totalAlignmentTransactionNumber$ = this.totalAlignmentTransactionNumber.asObservable();

  async initialize() {
    if (!this.initialized) {
      await this.setBaseCurrency();
      const referenceData = await this.dataRepositoryService.getAllReferenceData();
      this.balanceAlignmentClass = new BalanceAlignmentClass(
        this.logService,
        this.baseCurrency,
        referenceData
      );
      this.initialized = true;
    }
  }

  async setBaseCurrency() {
    const preferenceCurrency = await this.helperPreferences.getBaseCurrency();
    if (preferenceCurrency !== this.baseCurrency) {
      this.baseCurrency = preferenceCurrency;
    }
  }

  startRecalculatingBalance() {
    this.numberOfBalanceAlignmentOnProcess = new BehaviorSubject<number>(0);
    this.numberOfBalanceAlignmentOnProcess$ = this.numberOfBalanceAlignmentOnProcess.asObservable();
  }

  completeBalanceProgress() {
    this.numberOfBalanceAlignmentOnProcess.complete();
  }

  /**
   * This function is the main function to call to align all the values for opening and closing balances
   * as well as any transactions supplied with the balance column filled in. This will result in an array of
   * transactions being returned which will have fake alignment transactions added in if any of the numbers are out.
   * @param transactions
   */
  async realignAllExistingTransactions(transactions: Transaction[]): Promise<Array<Transaction>> {
    // logic moved to balanceAlignment class when webworkers introduced
    return this.balanceAlignmentClass.realignAllExistingTransactions(transactions);
  }
}
