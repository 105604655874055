import { View } from "@bitwarden/common/models/view/view";
import { Institution } from "@bitwarden/web-vault/app/models/data/blobby/institution.data";
import { Origin } from "@bitwarden/web-vault/app/models/types/general-types";
import {
  InstitutionAccount,
  InstitutionAccountLink,
} from "@bitwarden/web-vault/app/models/types/institution.type";
import { SplitCategoryType } from "@bitwarden/web-vault/app/models/types/split-category-type";
import { SplitClassificationType } from "@bitwarden/web-vault/app/models/types/split-classification-type";

import { Book } from "../data/blobby/book.data";

export class AccountView implements View {
  private readonly _id: string;
  private readonly _originalBook: Book;
  private readonly _name: string;
  private readonly _type: string;
  private readonly _currency: string;
  private readonly _balance: number;
  private readonly _isDeleted: boolean;
  private readonly _origin: Origin;
  private readonly _institution: Institution;
  private readonly _institutionLink: InstitutionAccountLink;
  private readonly _defaultClassifications: SplitClassificationType[];
  private readonly _defaultCategories: SplitCategoryType[];
  private _institutionAccountType: InstitutionAccount;
  private _status: string;
  private _source: string;

  constructor(account: Book, institution: Institution) {
    if (!account) {
      return;
    }

    this._originalBook = account;
    this._id = account.id;
    this._name = account.name;
    this._type = account.type;
    this._currency = account.currency;
    this._balance = account.balance;
    this._origin = account.origin;
    this._status = "Active"; // TODO when it is implemented update this part as well
    this._institutionLink = account.institutionLink;
    this._institution = this.setInstitution(institution);
    this._defaultClassifications = account.defaultClassifications;
    this._defaultCategories = account.defaultCategories;
    this.setInstitutionAccountType();
    this.setSource();
  }

  setInstitution(institution: Institution) {
    if (institution) {
      return institution;
    } else {
      return { id: "", name: "unknown" } as Institution;
    }
  }
  setInstitutionAccountType() {
    if (
      this.institution &&
      this.institution.availableAccounts?.length &&
      this.institutionLink &&
      this.institutionLink.institutionAccountId
    ) {
      this.institutionAccountType = this.institution.availableAccounts.find(
        (institutionAccount) => institutionAccount.id === this.institutionLink.institutionAccountId
      );
    } else {
      this.institutionAccountType = { id: "", name: "unknown", interestRates: [] };
    }
  }

  setSource() {
    if (this.origin !== Origin.manual) {
      this.source = "Auto Sync";
    } else {
      this.source = "Manual Import";
    }
  }

  set source(source: string) {
    this._source = source;
  }

  get source(): string {
    return this._source;
  }

  get originalBook(): Book {
    return this._originalBook;
  }

  set status(status: string) {
    this._status = status;
  }

  get status(): string {
    return this._status;
  }

  get defaultCategories(): SplitCategoryType[] {
    return this._defaultCategories;
  }

  get defaultClassifications(): SplitClassificationType[] {
    return this._defaultClassifications;
  }

  get origin(): Origin {
    return this._origin;
  }

  set institutionAccountType(type: InstitutionAccount) {
    this._institutionAccountType = type;
  }

  get institutionAccountType(): InstitutionAccount {
    return this._institutionAccountType;
  }

  get institution(): Institution {
    return this._institution;
  }

  get institutionLink(): InstitutionAccountLink {
    return this._institutionLink;
  }

  get id(): string {
    return this._id;
  }

  get name(): string {
    return this._name;
  }

  get type(): string {
    return this._type;
  }

  get currency(): string {
    return this._currency;
  }

  get balance(): number {
    return this._balance;
  }

  get isDeleted(): boolean {
    return this._isDeleted;
  }
}
