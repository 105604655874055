import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild,
} from "@angular/core";

@Component({
  selector: "app-info-tooltip",
  templateUrl: "./info-tooltip.component.html",
})
export class InfoTooltipComponent implements AfterViewInit, OnChanges {
  @Input() transaction: any;
  @Input() xPosition = 0;
  @Input() yPosition = 0;
  @Input() selectedTransactionId = "";
  @Output() onMouseEnterTooltip: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onMouseLeaveTooltip: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild("popupReference") popupReference: ElementRef;

  constructor(private renderer: Renderer2) {}
  ngAfterViewInit() {
    this.setPopupPosition();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.xPosition ||
      changes.yPosition ||
      changes.selectedTransactionId ||
      changes.popupReference
    ) {
      this.setPopupPosition();
    }
  }

  private setPopupPosition() {
    if (this.transaction?.id === this.selectedTransactionId && this.popupReference) {
      const popupElement = this.popupReference.nativeElement;

      const computedStyle = window.getComputedStyle(popupElement);

      const width = parseFloat(computedStyle.getPropertyValue("width"));
      const height = parseFloat(computedStyle.getPropertyValue("height"));

      this.renderer.setStyle(popupElement, "position", "absolute");
      this.renderer.setStyle(popupElement, "left", `${this.xPosition - width}px`);
      this.renderer.setStyle(popupElement, "top", `${this.yPosition - height}px`);
    }
  }
  onMouseEnter() {
    this.onMouseEnterTooltip.emit(true);
  }

  onMouseLeave() {
    this.onMouseLeaveTooltip.emit(false);
  }
}
