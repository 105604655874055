import { ModelValidator } from "@bitwarden/web-vault/app/models/data/blobby/model.validator";

import { EstimateActionsEnum } from "../../enum/estimate-actions.enum";
import { WeeklyOption, EstimatePeriod, MonthlyOption } from "../../enum/estimateType";
import { TransactionDirection } from "../../enum/transactionDirection";
import { SplitCategoryType } from "../../types/split-category-type";
import { SplitClassificationType } from "../../types/split-classification-type";
import { EstimateResponse } from "../response/estimate.response";

import { EstimateActionData } from "./estimate.action.data";

export class Estimate extends ModelValidator {
  private readonly __v = 1;
  private readonly _id: string;
  name: string;
  estimateActionKeys: EstimateActionsEnum[]; /** instead of saving estimate actions in memory. we just save the keys for them and create them on the fly */
  estimateActions: EstimateActionData[];
  categories: SplitCategoryType[];
  classifications: SplitClassificationType[];
  accountId: string;
  direction: TransactionDirection;
  period: EstimatePeriod;
  initialValue: number; /** The value that estimate will generate transactions of off. */
  symbol: string; /** Transaction symbol */
  dateCreated: Date;
  dateUpdated: Date;
  startDate: Date;
  endDate: Date;
  periodicTransactionDatesFormula: WeeklyOption | MonthlyOption;
  isRecurring: boolean;
  isFixedValue: boolean;
  frequency: number;
  transactionGenerator: EstimateActionsEnum; /** Used to detect the right estimateAction to run to generate transactions */
  autoGenerated: boolean; /*TODO :  what does this mean @Sinan */

  get id(): string {
    return this._id;
  }

  constructor(response: EstimateResponse) {
    super();
    if (response == null) {
      return;
    }

    this.checkVersion(this.__v, response.__v);
    this._id = response.id ? response.id : crypto.randomUUID();
    this.name = response.name;
    this.estimateActionKeys = response.estimateActionKeys; //the keys to be saved to generate actions on the fly
    this.estimateActions = response.estimateActions; //estimate actions when created on the fly
    this.categories = response.categories; //split categories
    this.classifications = response.classifications; //split classifications
    this.accountId = response.accountId;
    this.direction = response.direction;
    this.period = response.period;
    this.initialValue = response.initialValue; //The value that transactions of estimate will be generated of off
    this.symbol = response.symbol; //The value that transactions of estimate will be generated of off
    this.dateCreated = response.dateCreated;
    this.dateUpdated = response.dateUpdated;
    this.startDate = response.startDate; // When to start generating transactions
    this.endDate = response.endDate; // when to end generating transactions
    this.periodicTransactionDatesFormula = response.periodicTransactionDatesFormula; // when to end generating transactions
    this.isRecurring = response.isRecurring;
    this.isFixedValue = response.isFixedValue;
    this.frequency = response.frequency;
    this.transactionGenerator = response.transactionGenerator;
    this.autoGenerated = response.autoGenerated;
  }
}
