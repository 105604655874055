import { GlossQuantity } from "../../../../../apps/ironfly-web/src/app/models/data/shared/gloss-quantity";
import { TransactionDirection } from "../../../../../apps/ironfly-web/src/app/models/enum/transactionDirection";
import { TransactionStatusEnum } from "../../../../../apps/ironfly-web/src/app/models/enum/transactionType";
import { BaseResponse } from "../../models/response/base.response";

export class SourceTransactionResponse extends BaseResponse {
  __v: number;
  id: string;
  direction: TransactionDirection;
  symbol: string;
  currency: string;
  convsym: string;
  accountId: string;
  description: string;
  kind: string;
  sourceId: string;
  private _quantity: GlossQuantity;
  private _price: number;
  private _convrate: number;
  private _bankImportedBalance: number;
  private _categories?: string[];
  private _classifications?: string[];
  private _transactionDate: string;
  private _definition: TransactionStatusEnum;

  constructor(response: any) {
    super(response);
    this.__v = this.getResponseProperty("__v");
    this.id = this.getResponseProperty("_id");
    this.sourceId = this.getResponseProperty("sourceId");
    this.definition = this.getResponseProperty("_definition") || response._definition;
    this.accountId = this.getResponseProperty("AccountId") || response.account_id;
    this.description = this.getResponseProperty("Description") || response.name;
    this.kind = this.getResponseProperty("kind") || response.kind;
    this.currency = this.getResponseProperty("Currency") || response.iso_currency_code;
    this.convsym = this.getResponseProperty("Convsym");
    this.symbol = this.getResponseProperty("Symbol") || this.currency;

    this.convrate = this.getResponseProperty("Convrate");
    this.price = this.getResponseProperty("Price") || response.amount;
    this.bankImportedBalance =
      this.getResponseProperty("BankImportedBalance") || response.bankImportedBalance;
    this.categories = this.getResponseProperty("Categories") || response.category;
    this.classifications = this.getResponseProperty("Classifications");

    //todo this should be in the import handler logic
    this.setQuantityAndDirection(response);
    this.setTransactionDate();
  }

  get definition(): TransactionStatusEnum {
    return this._definition;
  }

  set definition(value: TransactionStatusEnum) {
    this._definition = value;
  }

  get categories(): string[] {
    return this._categories;
  }

  set categories(value: string[] | string) {
    if (Array.isArray(value)) {
      this._categories = value;
    } else if (typeof value === "string") {
      this._categories = [value];
    }
  }

  get classifications(): string[] {
    return this._classifications;
  }

  set classifications(value: string[] | string) {
    if (Array.isArray(value)) {
      this._classifications = value;
    } else if (typeof value === "string") {
      this._classifications = [value];
    }
  }

  get quantity(): GlossQuantity {
    return this._quantity;
  }

  set quantity(response: Record<string, any>) {
    this._quantity = new GlossQuantity().setToQuantityObj(response);
  }

  get price(): number {
    return this._price;
  }

  set price(value: any) {
    if (typeof value === "string") {
      this._price = Number.parseFloat(value.replace(/[\s,]/g, ""));
    } else {
      this._price = Number.parseFloat(value);
    }
  }

  get convrate(): number {
    return this._convrate;
  }

  set convrate(value: any) {
    this._convrate = Number.parseFloat(value);
  }

  get bankImportedBalance(): number {
    return this._bankImportedBalance;
  }

  set bankImportedBalance(value: any) {
    this._bankImportedBalance = Number.parseFloat(value);
  }

  get transactionDate(): string {
    return this._transactionDate;
  }

  set transactionDate(value: string) {
    this._transactionDate = value;
  }

  private setTransactionDate() {
    const transactionDateString =
      this.getResponseProperty("_transactionDate") ||
      this.getResponseProperty("Date") ||
      this.getResponseProperty("transactionDate");
    const epox = Date.parse(transactionDateString);
    if (!Number.isNaN(epox)) {
      const castDate = new Date();
      castDate.setTime(epox);
      this.transactionDate = castDate.toUTCString();
    } else {
      this.transactionDate = new Date().toUTCString();
    }
  }

  private setQuantityAndDirection(response: any) {
    // default case if the information is derely provided set it
    this.direction = this.getResponseProperty("Direction");

    // if we have a quantity but no direction
    this.quantity = response;
    if (!this.direction && this.quantity?.actualQuantity) {
      if (this.quantity.actualQuantity.amount >= 0) {
        this.direction = TransactionDirection.In;
      } else {
        this.direction = TransactionDirection.Out;
        this.quantity.setQuantityAmount(Math.abs(Number(this.quantity.actualQuantity)));
      }
    }

    // override if we have the specific in and out fields
    const inQty = this.getResponseProperty("In");
    const outQty = this.getResponseProperty("Out");
    if (inQty || outQty) {
      this.direction = inQty ? TransactionDirection.In : TransactionDirection.Out;
      this.quantity.setQuantityAmount(inQty ? Number(inQty) : Number(outQty));
    }
  }
}
