import * as d3 from "d3";

export class Arrows {
  private graphContentGroup: any;
  private xScale: d3.ScaleBand<string>;
  private yScale: d3.ScaleLinear<number, number, never>;
  private incomeColour = "#00C4C4";
  private expenseColour = "#2A6CE2";

  constructor(
    graphContentGroup: any,
    xScale: d3.ScaleBand<string>,
    yScale: d3.ScaleLinear<number, number, never>
  ) {
    this.graphContentGroup = graphContentGroup;
    this.xScale = xScale;
    this.yScale = yScale;
  }

  createArrowMarker(id: string, color: string) {
    const markerBoxWidth = 12;
    const markerBoxHeight = 12;
    const refX = markerBoxWidth - 2;
    const refY = markerBoxHeight / 2;
    const arrowPoints: [number, number][] = [
      [2, 2],
      [10, 6],
      [2, 10],
      [7, 6],
      [2, 2],
    ];

    this.graphContentGroup
      .append("defs")
      .append("marker")
      .attr("id", id)
      .attr("viewBox", [0, 0, markerBoxWidth, markerBoxHeight])
      .attr("refX", refX)
      .attr("refY", refY)
      .attr("markerWidth", markerBoxWidth)
      .attr("markerHeight", markerBoxHeight)
      .attr("orient", "auto-start-reverse")
      .append("path")
      .attr("d", d3.line()(arrowPoints))
      .attr("fill", color)
      .style("stroke-width", "0.5px")
      .attr("stroke", color);
  }

  createArrowMarkers() {
    this.createArrowMarker("greenArrow", "green");
    this.createArrowMarker("orangeArrow", "green");
    this.createArrowMarker("incomeArrow", this.incomeColour);
    this.createArrowMarker("expenseArrow", this.expenseColour);
    this.createArrowMarker("redArrow", "red");
    this.createArrowMarker("blackArrow", "black");
  }
}
