<div class="content">
  <div class="page-header d-flex">
    <div class="ml-auto d-flex">
      <button class="btn btn-sm btn-outline-primary ml-3" type="button" (click)="invite()">
        <i aria-hidden="true" class="bwi bwi-plus bwi-fw"></i>
        Add New Institution
      </button>
    </div>
  </div>
  <div height="200px">
    <p *ngIf="existingInstitutions && !existingInstitutions.length">
      There are currently no institutions created. Please create new institutions in the system or
      connect your finance institutions.
    </p>
  </div>
  <table class="table table-hover table-list mb-0" min-height="200px">
    <tbody>
      <tr *ngFor="let institution of existingInstitutions; let i = index">
        <td width="30">
          <bit-avatar
            [text]="institution.name + institution.swift.countryCode"
            [id]="institution.id"
            size="small"
          ></bit-avatar>
        </td>
        <td>
          <a href="#" appStopClick>{{
            institution | institutionPipe: "getInstitutionNameWithCountry" | async
          }}</a>
        </td>
        <!--       <td class="table-list-options">
          <button
            class="tw-border-none tw-bg-transparent tw-text-main"
            type="button"
            appA11yTitle="{{ 'options' | i18n }}"
          >
            <i class="bwi bwi-ellipsis-v bwi-lg" aria-hidden="true"></i>
          </button>
          &lt;!&ndash; TODO When have time implement a proper delete functionality that includes checking for accounts and transactions&ndash;&gt;
          &lt;!&ndash; [bitMenuTriggerFor]="accountOptions"&ndash;&gt;
          &lt;!&ndash;   <bit-menu #accountOptions>
            <button bitMenuItem (click)="delete(institution)">
              <i class="bwi bwi-fw bwi-close" aria-hidden="true"></i>
              {{ "remove" | i18n }}
            </button>
            <button bitMenuItem (click)="edit(institution)">
              <i class="bwi bwi-fw bwi-pencil" aria-hidden="true"></i>
              {{ "edit" | i18n }}
            </button>
          </bit-menu>&ndash;&gt;
        </td>-->
        <td class="table-list-options">
          <button
            [bitMenuTriggerFor]="accountOptions"
            class="tw-text-main tw-border-none tw-bg-transparent"
            type="button"
            appA11yTitle="{{ 'options' | i18n }}"
          >
            <i class="bwi bwi-ellipsis-v bwi-lg" aria-hidden="true"></i>
          </button>
          <bit-menu #accountOptions>
            <button bitMenuItem (click)="delete(institution)">
              <i class="bwi bwi-fw bwi-close" aria-hidden="true"></i>
              {{ "remove" | i18n }}
            </button>
            <!--            <button bitMenuItem (click)="edit(institution)">-->
            <!--              <i class="bwi bwi-fw bwi-pencil" aria-hidden="true"></i>-->
            <!--              {{ "edit" | i18n }}-->
            <!--&lt;!&ndash;            </button>&ndash;&gt; TODO the edit will break the Accounts view-->
          </bit-menu>
        </td>
      </tr>
    </tbody>
  </table>
</div>
