<div class="modal-container">
  <modal-header
    [title]="'createNewAccount' | i18n"
    [showBackButton]="false"
    [enableTooltip]="true"
    [tooltipInfo]="'creationOptionsTooltip' | i18n"
  >
  </modal-header>

  <div class="m-body tw-p-10 xs:tw-px-5">
    <div class="tw-flex tw-gap-12 md:tw-flex-col">
      <tile-button
        class="tw-w-1/2 md:tw-w-full"
        [title]="'linkedAccounts' | i18n"
        [description]="'linkedAccountsExplanation' | i18n"
        (onClick)="createByLinking()"
        [isEnabled]="hasBasiqAccess()"
      >
        <svg class="tw-h-12 tw-w-12 tw-text-primary tw-shadow-icon">
          <use xlink:href="images/sprite.svg#add-account" />
        </svg>
      </tile-button>
      <tile-button
        class="tw-w-1/2 md:tw-w-full"
        *appRoleScope="RoleScope.GLOSS"
        [title]="'unlinkedAccounts' | i18n"
        [description]="'unlinkedAccountsExplanation' | i18n"
        (onClick)="createManually()"
      >
        <svg class="tw-h-12 tw-w-12 tw-text-secondary tw-shadow-icon">
          <use xlink:href="images/sprite.svg#add-account" />
        </svg>
      </tile-button>
    </div>
  </div>
</div>
