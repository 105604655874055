<form #form [appApiAction]="formPromise" ngNativeValidate>
  <div
    class="tw-mx-auto tw-flex tw-max-w-lg tw-flex-col tw-items-center tw-justify-center tw-py-8 tw-px-8 xs:tw-px-2"
  >
    <div>
      <img class="logo logo-themed" alt="Gloss" />
      <p class="tw-mb-4 tw-text-center tw-text-xl">{{ "passwordHint" | i18n }}</p>
    </div>
    <div
      class="tw-border-secondary-300 tw-mt-3 tw-flex tw-flex-col tw-rounded-3xl tw-bg-background tw-p-6 tw-shadow-xl xs:tw-flex-none"
    >
      <label for="email">{{ "emailAddress" | i18n }}</label>
      <input
        id="email"
        type="text"
        name="Email"
        [(ngModel)]="email"
        required
        appAutofocus
        inputmode="email"
        appInputVerbatim="false"
      />
      <small class="tw-mt-2">{{ "enterEmailToGetHint" | i18n }}</small>
      <hr />
      <div class="tw-flex tw-gap-2 xs:tw-flex-col">
        <app-gloss-button
          class="tw-w-1/2"
          [options]="{
            type: 'submit',
            text: 'submit' | i18n,
            onClick: submit.bind(this),
            isEnabled: !form.loading
          }"
        >
        </app-gloss-button>
        <app-gloss-button
          class="tw-w-1/2"
          [options]="{
            type: 'link',
            class: 'neutral',
            text: 'cancel' | i18n,
            link: '/login',
            isEnabled: true
          }"
        >
        </app-gloss-button>
      </div>
    </div>
  </div>
</form>
