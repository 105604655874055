// async-cell-renderer.component.ts
import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";

import { PreferenceService } from "../../../services/DataService/preference/preference.service";

@Component({
  selector: "app-balance-cell-renderer",
  templateUrl: "./balance-renderer.component.html",
})
export class BalanceCellRenderer implements ICellRendererAngularComp {
  amount: number;
  baseCurrency: string;
  constructor(private preferenceService: PreferenceService) {}

  agInit(params: any): void {
    if (params.value) {
      this.preferenceService.get("baseCurrency").then((baseCurrency) => {
        this.baseCurrency = baseCurrency as string;
        this.amount = params.data.dailyBalance;
      });
    }
  }

  refresh(params: any): boolean {
    return false; // No need to refresh since data fetch is already handled
  }
}
