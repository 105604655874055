import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "app-drop-down-select-button",
  templateUrl: "./drop-down-select-button.component.html",
})
export class DropDownSelectButtonComponent {
  @Input() buttonText: string;
  @Output() clickEvent = new EventEmitter();

  onButtonClick() {
    this.clickEvent.emit();
  }
}
