<div class="preview-table-wrapper">
  <form [formGroup]="refTableForm">
    <fieldset>
      <mat-form-field id="reference-table-date-selector" appearance="outline">
        <mat-label id="ref-label-date-select">Select Date</mat-label>
        <input
          id="reference-date-picker"
          matInput
          [matDatepicker]="refDatePicker"
          formControlName="dateControl"
          (dateChange)="onDateChange()"
          [max]="uniqueAvailableDates?.[0]"
          [min]="uniqueAvailableDates?.[uniqueAvailableDates?.length - 1]"
          (click)="refDatePicker.open()"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="refDatePicker"
          id="datepicker-toggle-icon"
        ></mat-datepicker-toggle>
        <mat-datepicker #refDatePicker></mat-datepicker>
      </mat-form-field>
    </fieldset>
    <fieldset *ngIf="selectedDate">
      <mat-form-field id="reference-table-base-selector">
        <mat-label>Select Base Currency</mat-label>
        <mat-select formControlName="baseControl" (selectionChange)="onBaseChange()">
          <mat-option *ngFor="let base of uniqueBaseCurrencies" [value]="base">{{
            base
          }}</mat-option>
        </mat-select>
      </mat-form-field>
    </fieldset>
  </form>
  <cdk-virtual-scroll-viewport
    itemSize="34"
    class="reference-table-viewport mat-elevation-z2"
    *ngIf="shouldDisplayTable()"
  >
    <mat-table editable [dataSource]="dataSource">
      <ng-container matColumnDef="currency">
        <mat-header-cell *matHeaderCellDef> Currency </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.currency }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="rate">
        <mat-header-cell *matHeaderCellDef> Rate </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.rate }} </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
  </cdk-virtual-scroll-viewport>
  <div *ngIf="dataSource.length === 0 && selectedDate && selectedBase">
    <p>No reference data available for the selected date and base.</p>
  </div>
</div>
