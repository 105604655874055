import { ModelValidator } from "@bitwarden/web-vault/app/models/data/blobby/model.validator";

import { ReferenceDataResponse } from "../response/reference-data-response";
import { GlossDate } from "../shared/gloss-date";

const UNDEFINED_BASE = "UNDEFINED_BASE";
export class ReferenceData extends ModelValidator {
  private readonly __v = 1;
  private _id: string;
  private _date: GlossDate;
  private _base: string;
  private _symbols: { [key: string]: number };

  constructor(response: ReferenceDataResponse) {
    super();
    if (response == null) {
      return;
    }

    this.checkVersion(this.__v, response.__v);
    this._date = response.glossDate;
    this._base = response.base ?? UNDEFINED_BASE;
    this._symbols = response.symbols;
    this._id = response.id ?? this.generateId();
  }

  get id(): string {
    return this._id;
  }
  set id(value: string) {
    this._id = value;
  }

  get date(): GlossDate {
    return this._date;
  }

  set date(value: GlossDate) {
    this._date = value;
  }

  get base(): string {
    return this._base;
  }
  set base(value: string) {
    this._base = value;
  }

  get symbols(): { [p: string]: number } {
    return this._symbols;
  }
  set symbols(value: { [p: string]: number }) {
    this._symbols = value;
  }

  addSymbolRate(symbol: string, rate: number) {
    this._symbols[symbol] = rate;
  }

  private generateId() {
    if (this.date) {
      return `${this._base}@${GlossDate.getDateAsString(this._date)}`;
    }

    return `${this._base}@${GlossDate.getEpochDateTime()}`;
  }
}
