import { createFeatureSelector, createReducer, createSelector, on } from "@ngrx/store";

import { SideMenu } from "../side-menu";

import { SideMenuActions } from "./side-menu.action";

export const sideMenuFeatureKey = "menu";

export interface MenuState {
  sideMenu: SideMenu;
  error: unknown;
}

const initialState: MenuState = {
  sideMenu: new SideMenu(),
  error: undefined,
};

const getSideMenuFeatureState = createFeatureSelector<MenuState>(sideMenuFeatureKey);

export const getSideMenu = createSelector(getSideMenuFeatureState, (state) => state.sideMenu);

export const sideMenuReducer = createReducer<MenuState>(
  initialState,
  on(SideMenuActions.initialize, (state): MenuState => {
    return state;
  }),
  on(SideMenuActions.loadSuccess, (state, action): MenuState => {
    return {
      ...state,
      sideMenu: action.sideMenu,
    };
  }),
  on(SideMenuActions.toggle, (state, action): MenuState => {
    return {
      ...state,
      sideMenu: action.sideMenu,
    };
  })
);
