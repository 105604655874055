import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

import { LogService } from "@bitwarden/common/abstractions/log.service";
import { Book } from "@bitwarden/web-vault/app/models/data/blobby/book.data";
import { Institution } from "@bitwarden/web-vault/app/models/data/blobby/institution.data";

import { VaultFile } from "../../../models/data/blobby/vault-file.data";
import { DataRepositoryService } from "../../DataRepository/data-repository.service";
import { DataServiceAbstraction } from "../data.service.abstraction";

@Injectable({
  providedIn: "root",
})
export class VaultFileService implements DataServiceAbstraction {
  protected _vaultFiles = new BehaviorSubject<VaultFile[]>([]);
  private logService: LogService;

  constructor(private dataRepositoryService: DataRepositoryService) {}

  async getAll(): Promise<VaultFile[]> {
    return await this.dataRepositoryService.getAllVaultFile();
  }

  async update(file: VaultFile, triggerObservable = false): Promise<VaultFile> {
    try {
      const updatedBook = await this.dataRepositoryService.updateVaultFile(file);
      if (triggerObservable) {
        await this.updateObservables();
      }
      return updatedBook;
    } catch (e) {
      this.logService.error(e);
      throw e;
    }
  }

  async delete(item: VaultFile, triggerObservable = false): Promise<boolean> {
    try {
      const success = await this.dataRepositoryService.deleteVaultFile(item);
      if (triggerObservable) {
        await this.updateObservables();
      }
      return success;
    } catch (e) {
      this.logService.error(e);
      throw e;
    }
  }

  async get(id: string): Promise<VaultFile> {
    const vaultFiles = await this.getAll();

    for (const vaultFile of vaultFiles) {
      if (vaultFile.id === id) {
        return vaultFile;
      }
    }
    return;
  }

  async getFileAccounts(vaultFile: VaultFile): Promise<Book[]> {
    const books = await this.dataRepositoryService.getAllBooks();

    return books.filter((book) => vaultFile.statementAccounts.includes(book.id));
  }

  async getFileInstitutions(vaultFile: VaultFile): Promise<Institution[]> {
    const books = await this.getFileAccounts(vaultFile);
    const processedInstitutions = new Map<string, Institution>();
    const institutions: Institution[] = [];
    for (const book of books) {
      const institutionId = book.institutionLink?.institutionId;
      if (institutionId && !processedInstitutions.has(institutionId)) {
        const institution = await this.dataRepositoryService.getInstitutionById(institutionId);
        institutions.push(institution);
      }
    }

    return institutions;
  }

  async create(file: VaultFile): Promise<VaultFile> {
    try {
      return await this.dataRepositoryService.createVaultFile(file);
    } catch (e) {
      this.logService.error(e);
      throw e;
    }
  }

  private async updateObservables() {
    const allVaultFiles = await this.getAll();
    this._vaultFiles.next(allVaultFiles);
  }
}
