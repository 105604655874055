<div class="tw-flex tw-w-[95px] tw-justify-end">
  <div class="tw-relative tw-flex tw-h-[70px] tw-w-full md:tw-h-auto">
    <div
      *ngFor="let bank of banks; let i = index"
      class="bank icon {{ bank }} tw-absolute tw-shadow-icon position-{{ i }}"
    ></div>
    <div
      class="icon tw-z-2 position-3 tw-relative tw-rounded-full tw-bg-white tw-text-center tw-shadow-icon"
    >
      <svg class="tw-my-5 tw-h-4 tw-w-4 md:tw-my-2">
        <use xlink:href="images/sprite.svg#more" />
      </svg>
    </div>
  </div>
</div>
