import { Validators } from "@angular/forms";

import { FormValidatorInterface } from "../../../../../shared/form/FormValidatorInterface";

export class FormValidator implements FormValidatorInterface {
  rules(): Record<string, any> {
    return {
      name: [Validators.required],
      amount: null,
      account: [Validators.required],
      accountName: [Validators.required],
      categories: null,
      classifications: null,
      direction: [Validators.required],
      startDate: [Validators.required],
      endDate: null,
      frequency: null,
      period: null,
      isRecurring: [Validators.required],
      valueOption: [Validators.required],
      calculateAs: [Validators.required],
      symbol: [Validators.required],
      periodDateOption: null,
    };
  }
}
