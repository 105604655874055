import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";

import { LooseComponentsModule, SharedModule } from "../../shared";

import { RegisterFormComponent } from "./register-form.component";

@NgModule({
  imports: [SharedModule, MatButtonModule, MatDialogModule, MatIconModule, LooseComponentsModule],
  declarations: [RegisterFormComponent],
  exports: [RegisterFormComponent],
})
export class RegisterFormModule {}
