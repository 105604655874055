import { DateFormatIndex } from "../../../models/types/general-types";

export type DateAtomRegExType = {
  matchedFormat: string;
  regex: string;
  datePart: DateFormatIndexKeyType;
};

export type TripletType = [DateAtomRegExType, DateAtomRegExType, DateAtomRegExType];
export type PermutationListType = Array<TripletType>;

/* Create a type for looping in day month year. */
export type DateFormatIndexKeyType = keyof DateFormatIndex;

/* List Of Regex to match each element of a potential date string*/
export const dayRegex: DateAtomRegExType = {
  matchedFormat: "DD",
  regex: "(?<day>\\b(0?[1-9]|1\\d|2\\d|3[01])\\b)",
  datePart: "day" as DateFormatIndexKeyType,
};

/* Matching Numerical month */
export const monthRegex: DateAtomRegExType = {
  matchedFormat: "MM",
  regex: "(?<month>\\b(0?[1-9]|1[012])\\b)",
  datePart: "month" as DateFormatIndexKeyType,
};
/* Matching Abbreviation Month */
export const monthTextRegex: DateAtomRegExType = {
  matchedFormat: "MMM",
  regex: "(?<month>\\b([a-zA-Z]{3})\\b)",
  datePart: "month" as DateFormatIndexKeyType,
};

export const monthFullTextRegex: DateAtomRegExType = {
  matchedFormat: "MMMM",
  regex:
    "(?<month>\\b(january|february|march|april|may|june|july|august|september|october|november|december)\\b)",
  datePart: "month" as DateFormatIndexKeyType,
};

export const year4Regex: DateAtomRegExType = {
  matchedFormat: "YYYY",
  regex: "(?<year>\\b(19\\d\\d|20\\d\\d|21\\d\\d)\\b)",
  datePart: "year" as DateFormatIndexKeyType,
};
// todo generate the const from now - 5 years
export const year2RegexMostlikely: DateAtomRegExType = {
  matchedFormat: "YY",
  regex: "(?<year>\\b(20|21|22|23|24)\\b)",
  datePart: "year" as DateFormatIndexKeyType,
};
export const year2Regex: DateAtomRegExType = {
  matchedFormat: "YY",
  regex: "(?<year>\\b(\\d\\d)\\b)",
  datePart: "year" as DateFormatIndexKeyType,
};
export const regexPermutationList = [
  dayRegex,
  monthRegex,
  monthTextRegex,
  monthFullTextRegex,
  year2Regex,
  year4Regex,
  year2RegexMostlikely,
];

export const separator1Regex = "(?<separator1>[\\W]+)";
export const separator2Regex = "(?<separator2>[\\W]+)";
