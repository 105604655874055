<app-help-tooltip (onTooltipClose)="onTooltipClose()"></app-help-tooltip>
<nav *ngIf="glossRoute" class="tw-p-4">
  <div *ngIf="glossRoute.type === 'button'">
    <div class="tw-flex tw-h-full tw-justify-between tb:tw-flex-col">
      <app-dashboard-selector
        class="tw-mt-4 tw-mb-0 tw-flex tw-items-end md:tw-my-0 md:tw-mb-4"
      ></app-dashboard-selector>
      <div
        *appRoleScope="RoleScope.GLOSS"
        class="tw-my-0 tw-flex tw-h-full xxs:tw-origin-top-left xxs:tw-scale-90 sm:tw-my-0"
      >
        <app-nav-date-picker class="tw-flex tw-h-full"></app-nav-date-picker>
        <!--          granularity-->
        <div class="tw-mt-4 tw-mb-0 tw-flex tw-items-end">
          <div
            #menuContainer
            class="tw-min-h-12 tw-relative tw-inline-flex tw-h-auto tw-cursor-pointer tw-items-center tw-justify-between tw-gap-3 tw-rounded-full tw-bg-neutral-50 tw-px-4 tw-py-2 tw-shadow-button xs:tw-gap-1 xs:tw-px-2"
            [matMenuTriggerFor]="granularity"
          >
            <div class="tw-relative tw-flex group-hover:tw-flex">
              {{ selectedGranularityOption }}
            </div>

            <div class="tw-relative xs:tw-scale-75">
              <svg class="tw-h-7 tw-w-7">
                <use xlink:href="images/sprite.svg#down-arrow" />
              </svg>
            </div>
          </div>
          <mat-menu #granularity="matMenu" class="tw-mt-1">
            <button
              class="tw-h-10"
              mat-menu-item
              *ngFor="let granularity of granularityOptions"
              (click)="updateSelectedGranularityOption(granularity)"
            >
              {{ granularity }}
            </button>
          </mat-menu>
        </div>
      </div>
      <!--          granularity-->
    </div>

    <!--    Filter (class) hidden for now until further design comes out gloss-client#975 -->
    <div
      [matMenuTriggerFor]="menu"
      class="bg-white tw-relative tw-ml-auto tw-mt-5 tw-hidden tw-cursor-pointer tw-items-start tw-gap-2 tw-rounded-lg tw-px-6 tw-py-2 tw-shadow-lg xs:tw-px-3 sm:tw-mt-1"
    >
      <div class="tw-relative">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            d="M1.33337 3.06667C1.33337 2.6933 1.33337 2.50661 1.40604 2.36401C1.46995 2.23856 1.57194 2.13658 1.69738 2.07266C1.83999 2 2.02667 2 2.40004 2H13.6C13.9734 2 14.1601 2 14.3027 2.07266C14.4281 2.13658 14.5301 2.23856 14.594 2.36401C14.6667 2.50661 14.6667 2.6933 14.6667 3.06667V3.51293C14.6667 3.69213 14.6667 3.78173 14.6448 3.86504C14.6254 3.93887 14.5935 4.00882 14.5504 4.07184C14.5018 4.14295 14.4341 4.20164 14.2986 4.319L10.0348 8.01434C9.89936 8.1317 9.83165 8.19038 9.78303 8.26149C9.73995 8.32451 9.70801 8.39446 9.6886 8.46829C9.66671 8.55161 9.66671 8.64121 9.66671 8.8204V12.3056C9.66671 12.436 9.66671 12.5011 9.64568 12.5575C9.6271 12.6073 9.59688 12.6519 9.55754 12.6877C9.51302 12.7281 9.4525 12.7524 9.33145 12.8008L7.06478 13.7074C6.81975 13.8054 6.69724 13.8545 6.59889 13.834C6.51288 13.8161 6.43741 13.765 6.38888 13.6918C6.33337 13.6081 6.33337 13.4762 6.33337 13.2122V8.8204C6.33337 8.64121 6.33337 8.55161 6.31148 8.46829C6.29207 8.39446 6.26013 8.32451 6.21705 8.26149C6.16843 8.19038 6.10072 8.1317 5.9653 8.01434L1.70145 4.319C1.56603 4.20164 1.49832 4.14295 1.4497 4.07184C1.40662 4.00882 1.37468 3.93887 1.35527 3.86504C1.33337 3.78173 1.33337 3.69213 1.33337 3.51293V3.06667Z"
            [attr.stroke]="isInfoOpen ? '#00C4C4' : '#A8A8A8'"
            stroke-width="1"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      <div class="tw-relative tw-w-fit tw-whitespace-nowrap xs:tw-hidden">Filters</div>
    </div>
    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="onFilterMenuClick('Accounts')">
        {{ "accounts" | i18n }}
      </button>
    </mat-menu>
    <!--Filter-->
  </div>
  <div class="tw-flex" *ngIf="glossRoute.type === 'text'">
    <app-help-tooltip
      [showHelpTooltip]="showHelpTooltip"
      [tooltipInfo]="tooltipInfo"
      [xPosition]="xPosition"
      [yPosition]="yPosition"
      [pointer]="pointer"
      (onTooltipClose)="onTooltipClose()"
    ></app-help-tooltip>
    <h3 class="component-header xs:tw-w-full xs:tw-text-center">
      {{ glossRoute.i18Mapper | i18n }}
    </h3>
    <div
      *ngIf="tooltipInfo"
      title="{{ glossRoute.i18Mapper | i18n }}"
      (click)="openDashboardHelp($event)"
    >
      <mat-icon
        class="mat-icon-secondary mat-icon-tooltip"
        id="help-info-button"
        [ngClass]="{ clicked: isClicked }"
        (click)="toggleClick($event)"
      >
        info_outline
      </mat-icon>
    </div>
  </div>
</nav>
