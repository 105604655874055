import { Book } from "@bitwarden/web-vault/app/models/data/blobby/book.data";
import { GlossDate } from "@bitwarden/web-vault/app/models/data/shared/gloss-date";

import { Transaction } from "../../../../models/data/blobby/transaction.data";
import { TransactionResponse } from "../../../../models/data/response/transaction-response";
import { TransactionDirection } from "../../../../models/enum/transactionDirection";
import { TransactionStatusEnum } from "../../../../models/enum/transactionType";
import { BasiqIoTransactionType } from "../../../../models/types/basiq-io-transaction.types";
import { SplitCategoryType } from "../../../../models/types/split-category-type";
import { SplitClassificationType } from "../../../../models/types/split-classification-type";

export class BasiqIoTransaction {
  type: BasiqIoTransactionType["type"];
  id: BasiqIoTransactionType["id"];
  status: BasiqIoTransactionType["status"];
  description: BasiqIoTransactionType["description"];
  amount: BasiqIoTransactionType["amount"];
  account: BasiqIoTransactionType["account"];
  balance: BasiqIoTransactionType["balance"];
  direction: BasiqIoTransactionType["direction"];
  class: BasiqIoTransactionType["class"];
  institution: BasiqIoTransactionType["institution"];
  connection: BasiqIoTransactionType["connection"];
  private _transactionDate: BasiqIoTransactionType["transactionDate"];
  postDate: BasiqIoTransactionType["postDate"];
  timezone: BasiqIoTransactionType["timezone"];
  subClass: BasiqIoTransactionType["subClass"];
  enrich: BasiqIoTransactionType["enrich"];
  links: BasiqIoTransactionType["links"];
  definition: string;
  private _transaction: Transaction; // A gloss transaction that is based on the basiq data
  private _transactionResponse: TransactionResponse; // A gloss transaction response that is based on the basiq data

  constructor(basiqTransactionObj: BasiqIoTransactionType) {
    const {
      type,
      id,
      status,
      description,
      amount,
      account,
      balance,
      direction,
      class: transactionClass,
      institution,
      connection,
      transactionDate,
      postDate,
      subClass,
      enrich,
      links,
      timezone,
    } = basiqTransactionObj;

    this.type = type;
    this.id = id;
    this.status = status;
    this.description = description;
    this.amount = amount;
    this.account = account;
    this.balance = balance;
    this.direction = direction;
    this.class = transactionClass;
    this.institution = institution;
    this.connection = connection;
    this._transactionDate = transactionDate;
    this.postDate = postDate;
    this.subClass = subClass;
    this.enrich = enrich;
    this.links = links;
    this.timezone = timezone;
  }

  get transactionDate(): BasiqIoTransactionType["transactionDate"] {
    return this._transactionDate;
  }

  set transactionDate(value: BasiqIoTransactionType["transactionDate"]) {
    this._transactionDate = value;
  }

  set transaction(transaction: Transaction) {
    this._transaction = transaction;
  }
  get transaction(): Transaction {
    return this._transaction;
  }

  set transactionResponse(transactionResponse: TransactionResponse) {
    this._transactionResponse = transactionResponse;
  }
  get transactionResponse(): TransactionResponse {
    return this._transactionResponse;
  }

  /**
   * @deprecated The function is not in use when importing Basiq transaction
   */
  runTransactionCalculations(
    accounts: any[],
    categories: SplitCategoryType[],
    relatedAccount?: Book
  ) {
    const account = relatedAccount
      ? relatedAccount
      : accounts.find((account) => account.id === this.account);
    const classifications: SplitClassificationType[] = [];
    const directionAndAmount = this.directionAndQuantity();

    const glossDate = new GlossDate();
    glossDate.timeZone = relatedAccount ? relatedAccount.timezone : undefined;
    glossDate.setToDateObj(this.postDate);

    const toResponseObj = {
      quantity: Number(this.amount),
      convrate: 1,
      currency: account.currency,
      balance: Number(this.balance) !== 0 ? Number(this.balance) : "",
      symbol: account.currency,
      accountId: account.name,
      classifications,
      categories,
      description:
        this.enrich?.cleanDescription && this.enrich?.cleanDescription !== ""
          ? this.enrich.cleanDescription
          : this.description,
      date: glossDate,
      direction: this.direction === "credit" ? TransactionDirection.In : TransactionDirection.Out,
      definition: TransactionStatusEnum.transaction,
      ...directionAndAmount,
    };

    this.transactionResponse = new TransactionResponse(toResponseObj);

    this.transaction = new Transaction(this.transactionResponse);
  }

  directionAndQuantity() {
    if (this.direction === "credit") {
      return {
        direction: TransactionDirection.In,
        in: this.amount,
      };
    } else {
      return {
        direction: TransactionDirection.Out,
        out: Math.abs(Number(this.amount)),
      };
    }
  }
}
