import { Injectable, Injector } from "@angular/core";

import { FeatureFlagConfig } from "../../../../../config/feature-flag.config";
import { PreferenceService } from "../../../../app/services/DataService/preference/preference.service";

@Injectable({
  providedIn: "root",
})
export class FeatureFlag {
  private preferenceService: PreferenceService;

  constructor(injector: Injector) {
    this.preferenceService = injector.get(PreferenceService);
  }

  static loadConfig(): Record<string, any> {
    return FeatureFlagConfig;
  }

  async getConfigMode() {
    return await this.preferenceService.get("mode");
  }
}
