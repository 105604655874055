import { Injectable } from "@angular/core";

import { BlobbyDataTypeEnum } from "@bitwarden/web-vault/app/models/enum/blobbyDataTypeEnum";

import { ItemCount } from "../../../models/data/shared/item-count";

@Injectable({
  providedIn: "root",
})
export class ItemCountService {
  private itemCount: ItemCount;
  private blobbyInitialise: boolean;

  constructor() {
    if (!this.itemCount) {
      this.itemCount = new ItemCount();
    }
  }

  getItemCountInstance(): ItemCount {
    return this.itemCount;
  }

  /**
   * @param type Blobby Data Type
   * @param totalCount
   */
  setItemCountValue(type: BlobbyDataTypeEnum, totalCount: number) {
    if (type === BlobbyDataTypeEnum.Book) {
      this.itemCount.setTotalAccount(totalCount);
    } else if (type === BlobbyDataTypeEnum.Transaction) {
      this.itemCount.setTotalTransaction(totalCount);
    }
  }

  setBlobbyInitialise(value: boolean) {
    this.blobbyInitialise = value;
  }

  getBlobbyInitialise(): boolean {
    return this.blobbyInitialise;
  }
}
