import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import _ from "lodash";

import { GlobalService } from "@bitwarden/common/services/global/global.service";
import { Classification } from "@bitwarden/web-vault/app/models/data/blobby/classification.data";
import { ClassificationResponse } from "@bitwarden/web-vault/app/models/data/response/classification.response";
import { ClassificationService } from "@bitwarden/web-vault/app/services/DataService/classification/classification.service";

@Component({
  selector: "app-classification-add-edit",
  templateUrl: "./classification-add-edit.component.html",
  styles: [".mat-form-field {width: 100%;}"],
})
export class ClassificationAddEditComponent implements OnInit {
  form: FormGroup;
  loading = true;
  editMode = false;
  title: string;
  classificationName: string;
  classificationId: string;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      classification: Classification;
      actionSucceeded: CallableFunction;
      delete: CallableFunction;
    },
    private classificationService: ClassificationService,
    private dialogRef: MatDialogRef<ClassificationAddEditComponent>,
    private globalService: GlobalService,
    private formBuilder: FormBuilder
  ) {
    if (data.classification) {
      if (data.classification.id) {
        this.classificationId = data.classification.id;
      }
      if (data.classification.name) {
        this.classificationName = data.classification.name;
      }
    }
  }

  async ngOnInit() {
    this.initializeForm();
    this.editMode = this.classificationId != null;
    if (this.editMode) {
      this.editMode = true;
      this.title = this.globalService.getLocaleValue("editClassification");
      this.form.patchValue({
        classificationName: this.classificationName,
      });
    } else {
      this.title = this.globalService.getLocaleValue("createNewClassification");
    }
    this.loading = false;
  }

  initializeForm() {
    this.form = this.formBuilder.group({
      classificationName: ["", Validators.required],
    });
  }

  closeDialogue() {
    this.dialogRef.close();
  }

  async submit() {
    this.loading = true;

    try {
      if (this.form.valid) {
        if (this.editMode) {
          await this.updateClassification();
        } else {
          await this.createClassification();
        }
      }
    } catch (e) {
      this.globalService.showErrorMessage("errorOccurred", e);
    }
    this.loading = false;
  }

  async createClassification() {
    const newClassification = new Classification(new ClassificationResponse(""));
    newClassification.name = this.form.value.classificationName;
    try {
      const createdClassification = await this.classificationService.create(newClassification);
      if (createdClassification instanceof Classification) {
        await this.data.actionSucceeded("createdSuccessfully");
      }
    } catch (e) {
      this.globalService.showErrorMessage("errorOccurred", e);
    }
  }

  async updateClassification() {
    try {
      const clonedClassification = _.cloneDeep(this.data.classification);
      clonedClassification.name = this.form.value.classificationName;
      const updatedClassification = await this.classificationService.update(
        clonedClassification,
        false
      );
      if (updatedClassification instanceof Classification) {
        await this.data.actionSucceeded("updatedSuccessfully");
      }
    } catch (e) {
      this.globalService.showErrorMessage("errorOccurred", e);
    }
  }

  async deleteClassification(classification: Classification) {
    this.data.delete(classification);
  }
}
