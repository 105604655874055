import { LogService } from "@bitwarden/common/src/abstractions/log.service";
import { Allocation } from "@bitwarden/web-vault/app/models/data/allocation.data";
import { ReferenceData } from "@bitwarden/web-vault/app/models/data/blobby/reference-data.data";
import { Transaction } from "@bitwarden/web-vault/app/models/data/blobby/transaction.data";
import { GlossBalance } from "@bitwarden/web-vault/app/models/data/shared/gloss-balance";
import { Valuation } from "@bitwarden/web-vault/app/models/data/valuation.data";
import { TransactionDirection } from "@bitwarden/web-vault/app/models/enum/transactionDirection";
import { NormalizationProperties } from "@bitwarden/web-vault/app/models/types/normalization-types";
import { SplitCategoryType } from "@bitwarden/web-vault/app/models/types/split-category-type";
import { SplitClassificationType } from "@bitwarden/web-vault/app/models/types/split-classification-type";
import { SymbolConversion } from "@bitwarden/web-vault/app/services/DataCalculationService/symbol/symbolConversion";
import { ReferenceDataService } from "@bitwarden/web-vault/app/services/DataService/reference-data/reference-data.service";

export class NormalizeTransaction {
  _baseCurrency: string;
  symbolConversion: SymbolConversion;
  private logger: LogService;
  referenceData: ReferenceData[];
  referenceDataService: ReferenceDataService;

  constructor(
    baseCurrency: string,
    logger: LogService,
    referenceData?: ReferenceData[],
    referenceDataService?: ReferenceDataService
  ) {
    this._baseCurrency = baseCurrency;
    this.logger = logger;

    if (referenceData) {
      this.referenceData = referenceData;
    }
    if (referenceDataService) {
      this.referenceDataService = referenceDataService;
    }
  }

  // <editor-fold desc="Public Functions">
  /**
   * Normalizes a transaction to the base currency
   * @param transaction
   */
  async getNormalizedProperties(transaction: Transaction): Promise<NormalizationProperties> {
    if (!transaction.quantity) {
      throw new Error("Transaction Gloss Quantity was not set");
    }
    if (!transaction.balance) {
      throw new Error("Transaction Balance object was not set");
    }

    // the transaction currency is different from the required base currency
    // but a conversion rate and conversion symbol are already supplied on the transaction
    if (
      transaction.quantity.convrate &&
      transaction.quantity.convsym &&
      transaction.quantity.convsym === this._baseCurrency
    ) {
      const normalized = this.normalizeWithSuppliedConvRateSymbol(transaction);
      return normalized;
    }

    // if the transaction currency is the same as the base currency
    if (transaction.quantity.currency === this._baseCurrency) {
      // if the symbol is the currency then no conversion is required
      const normalized = await this.normaliseCurrencyEqualBase(transaction);
      return normalized;
    }

    if (transaction.isUsingValuationPrice && transaction.valuationPrice) {
      const normalized = this.normalizeWithValuationPrice(transaction);
      return normalized;
    }

    if (transaction.quantity.actualQuantity.symbol) {
      const normalized = this.normalizeWithSymbol(transaction);
      return normalized;
    }

    // default to returning the quantity
    let quantity = transaction.quantity.actualQuantity.amount;
    quantity = transaction.direction === TransactionDirection.Out ? -1 * quantity : quantity;
    const normalizedProperties: NormalizationProperties = {
      value: quantity,
      normalizedValue: quantity,
      normalizedCurrency: this._baseCurrency,
    };

    return normalizedProperties;
  }

  // </editor-fold desc="Public Functions">

  // <editor-fold desc="Private Functions">
  private async normalizeWithReferenceData(
    quantity: number,
    date: Date,
    symbol: string
  ): Promise<NormalizationProperties> {
    if (!this.symbolConversion) {
      this.symbolConversion = new SymbolConversion(
        this._baseCurrency,
        this.logger,
        this.referenceData,
        this.referenceDataService
      );
    }

    return await this.symbolConversion.normalizeQuantity(quantity, date, symbol);
  }

  /**
   * If the base we need and the currency of the transaction is already the same as the required base
   * we want to normalise to
   * @param transaction
   * @private
   */
  private async normaliseCurrencyEqualBase(
    transaction: Transaction
  ): Promise<NormalizationProperties> {
    let quantity = transaction.quantity.actualQuantity.amount;

    quantity = transaction.direction === TransactionDirection.Out ? -1 * quantity : quantity;

    let normalizedProperties: NormalizationProperties = {
      value: quantity,
      normalizedValue: quantity,
      normalizedCurrency: this._baseCurrency,
    };

    // if the symbol is the currency then no conversion is required
    if (transaction.quantity.actualQuantity.symbol === this._baseCurrency) {
      normalizedProperties.normalizingRate = 1;
      return normalizedProperties;
    }

    // if a valuation price was supplied on import then we only need to multiply the two
    if (transaction.isUsingValuationPrice && transaction.valuationPrice) {
      // multiply the symbol to the price if one is supplied
      normalizedProperties = {
        value: quantity * transaction.valuationPrice,
        symbolValue: transaction.valuationPrice,
        normalizedValue: quantity * transaction.valuationPrice,
        normalizingRate: 1,
        normalizedCurrency: this._baseCurrency,
      };
      return normalizedProperties;
    }

    // if there is a symbol, then we need to find the price of the symbol on the day
    if (quantity && transaction.quantity.actualQuantity.symbol) {
      // normalise the symbol with the current rate because a price was not supplied
      const normalisedRates = await this.normalizeWithReferenceData(
        quantity,
        new Date(transaction.transactionDate.date),
        transaction.quantity.actualQuantity.symbol
      );
      Object.assign(normalizedProperties, normalisedRates);
      return normalizedProperties;
    }
    return normalizedProperties;
  }

  private async normalizeWithSuppliedConvRateSymbol(
    transaction: Transaction
  ): Promise<NormalizationProperties> {
    let value = transaction.quantity.actualQuantity.amount;

    value = transaction.direction === TransactionDirection.Out ? -1 * value : value;

    let normalizedProperties: NormalizationProperties = {
      value: value,
      normalizedValue: value,
      normalizedCurrency: this._baseCurrency,
    };
    let valueCurrencyOverride;

    if (transaction.isUsingValuationPrice && transaction.valuationPrice) {
      // we need to use the supplied conversion rate to work out the value
      value = value * transaction.valuationPrice;
      normalizedProperties.value = value;
      normalizedProperties.normalizedValue = value;
      normalizedProperties.symbolValue = transaction.valuationPrice;
      normalizedProperties.valueCurrency = transaction.quantity.currency;
      valueCurrencyOverride = transaction.quantity.currency;
    }

    if (transaction.quantity.currency === this._baseCurrency) {
      // if the currency supplied already matches the base, then no conversion is required
      normalizedProperties.normalizingRate = 1;
      return normalizedProperties;
    }

    if (transaction.quantity.convrate) {
      // we need to use the supplied conversion rate to work out the value
      value = value / transaction.quantity.convrate;
      normalizedProperties.valueCurrency = transaction.quantity.convsym;
      valueCurrencyOverride = transaction.quantity.convsym;
      normalizedProperties.normalizedValue = value;
    }

    if (transaction.quantity.convsym === this._baseCurrency) {
      // TODO: Check with Kev if the convrate should be flipped for storing or should we
      // TODO: store the normalizingRate as 1/convrate to keep the system consistent
      normalizedProperties.normalizingRate = transaction.quantity.convrate;
      normalizedProperties.valueCurrency = transaction.quantity.currency;
      return normalizedProperties;
    }

    // normalize the value using the conversion symbol and reference data because it is not in the base currency
    const normalized = await this.normalizeWithReferenceData(
      value,
      new Date(transaction.transactionDate.date),
      transaction.quantity.convsym
    );
    normalizedProperties = Object.assign(normalizedProperties, normalized);
    if (valueCurrencyOverride) {
      normalizedProperties.valueCurrency = valueCurrencyOverride;
    }
    return normalizedProperties;
  }

  private async normalizeWithValuationPrice(
    transaction: Transaction
  ): Promise<NormalizationProperties> {
    const quantity = transaction.quantity.actualQuantity.amount;
    let value = quantity * transaction.valuationPrice;

    value = transaction.direction === TransactionDirection.Out ? -1 * value : value;

    const normalizedProperties: NormalizationProperties = {
      value: value,
      normalizedValue: value,
      symbolValue: transaction.valuationPrice,
      normalizedCurrency: this._baseCurrency,
    };

    if (transaction.quantity.currency) {
      const normalizedRates = await this.normalizeWithReferenceData(
        normalizedProperties.normalizedValue,
        new Date(transaction.transactionDate.date),
        transaction.quantity.currency
      );
      Object.assign(normalizedProperties, normalizedRates);
      return normalizedProperties;
    }
    return normalizedProperties;
  }

  private async normalizeWithSymbol(transaction: Transaction): Promise<NormalizationProperties> {
    let value = transaction.quantity.actualQuantity.amount;

    value = transaction.direction === TransactionDirection.Out ? -1 * value : value;

    const normalized = await this.normalizeWithReferenceData(
      value,
      new Date(transaction.transactionDate.date),
      transaction.quantity.actualQuantity.symbol
    );
    return normalized;
  }

  async normalizeImportedTransaction(transaction: Transaction): Promise<void> {
    // await this.getBaseCurrency();
    const normalizedProperties = await this.getNormalizedProperties(transaction);

    this.setValuationOnTransaction(transaction, normalizedProperties);
    this.setBalanceOnTransaction(transaction);
    this.setAllocationNormalizedValues(transaction);

    return;
  }

  private setValuationOnTransaction(
    transaction: Transaction,
    normalizedProperties: NormalizationProperties
  ) {
    if (normalizedProperties) {
      if (transaction.valuation instanceof Valuation) {
        transaction.valuation.setToValuationObj(normalizedProperties);
      } else {
        transaction.valuation = new Valuation().setToValuationObj(normalizedProperties);
      }
    }
  }

  private setBalanceOnTransaction(transaction: Transaction) {
    transaction.setGlossBalance();
  }

  /**
   * @deprecated - Removed this function from the import process because normalizeImportedTransaction calls
   * the setAllocationNormalizedValues function already. We shouldn't need to normalize the allocation
   * values twice in the import process
   * @param transactions
   */
  normalizeAllocationTransactions(transactions: Array<Transaction>) {
    for (const transaction of transactions) {
      this.setAllocationNormalizedValues(transaction);
    }
    return transactions;
  }

  /**
   * Assign the correct normalized value to the allocation object with respect to the splits
   * in classification and catgories
   * @param transaction
   * @private
   */
  private setAllocationNormalizedValues(transaction: Transaction) {
    transaction.setAllocation();
    if (!Allocation.isValidToProcess(transaction.classifications)) {
      return;
    }
    // TODO: get the precision for the base currency in the symbol data (currently default to 2)
    const precision = 2;
    const totalClassWeight = Allocation.getTotalWeight(transaction.classifications);
    const totalCatWeight = Allocation.getTotalWeight(transaction.categories);
    const totalWeight = totalCatWeight * totalClassWeight;
    const normalizedValue = transaction.valuation.normalizedValue.amount;
    const normalizedSymbol = transaction.valuation.normalizedValue.symbol;

    const isBaseDivisible = Allocation.isDivisible(normalizedValue, totalClassWeight);
    const classOverFlow = Allocation.getClassOverFlow(
      normalizedValue,
      totalClassWeight,
      isBaseDivisible,
      precision
    );

    for (const allocation of transaction.allocations) {
      const classification = this.getClassification(
        allocation.classification,
        transaction.classifications
      );
      const category = this.getCategory(allocation.category, transaction.categories);

      const classificationAmount = Allocation.getSplitAmount(
        classification,
        classOverFlow,
        totalClassWeight,
        normalizedValue,
        precision
      );

      const categoryBaseDivisible = Allocation.isDivisible(classificationAmount, totalWeight);
      let catOverFlow = 0;
      if (!categoryBaseDivisible) {
        catOverFlow = Allocation.getRemainder(classificationAmount, totalCatWeight);
      }
      // assign the normalized value for the allocation
      const normalizedSplit = Allocation.getSplitAmount(
        category,
        catOverFlow,
        totalCatWeight,
        classificationAmount,
        precision
      );

      allocation.setNormalizedAmountSymbol(normalizedSplit, normalizedSymbol);
    }
    this.setAllocationBalanceValues(transaction);
  }

  setAllocationBalanceValues(transaction: Transaction) {
    let isTransfer = false;
    if (transaction.linkedTo.length > 0) {
      isTransfer = true;
    }
    for (const allocation of transaction.allocations) {
      allocation.balance = GlossBalance.createGlossBalanceNormalizedPair(
        allocation.value,
        isTransfer
      );
    }
  }

  getClassification(
    classificationID: string,
    classifications: Array<SplitClassificationType>
  ): SplitClassificationType {
    for (const classification of classifications) {
      if (classification.classificationId === classificationID) {
        return classification;
      }
    }
    return;
  }

  getCategory(categoryIO: string, categories: Array<SplitCategoryType>) {
    for (const category of categories) {
      if (category.categoryId === categoryIO) {
        return category;
      }
    }
    return;
  }

  // </editor-fold desc="Public Functions">
}
