import {
  alignmentDefaultMapping,
  defaultMapping,
} from "@bitwarden/web-vault/app/importers/data-mapper/mappers/default-mapping";
import {
  AlignmentMappingItem,
  HeadersMapping,
  MappingConfiguration,
  MappingConfigurationItem,
  Row,
} from "@bitwarden/web-vault/app/importers/data-mapper/mapping-engine-types";
import { Transaction } from "@bitwarden/web-vault/app/models/data/blobby/transaction.data";
import { TransactionStatusEnum } from "@bitwarden/web-vault/app/models/enum/transactionType";
import { NumberFormatValidator } from "@bitwarden/web-vault/app/validators/format/number.format.validator";

export class MappingEngine extends NumberFormatValidator {
  protected unMappedKeyItem: Record<string, boolean> = {};
  protected _headersMap: HeadersMapping = {};
  private _mappingConfiguration: MappingConfiguration;

  constructor(mappingConfiguration?: MappingConfiguration) {
    super();
    const isArrayOfMappingConfigurationItems = mappingConfiguration?.every((item) => {
      if (item) {
        const keys = Object.keys(item);
        const expectedKeys: (keyof MappingConfigurationItem)[] = [
          "key",
          "mapping",
          "displayAs",
          "formatter",
        ];

        // Check if the keys in the item match the expected keys
        return expectedKeys.every((expectedKey) => keys.includes(expectedKey));
      }
    });

    if (mappingConfiguration && mappingConfiguration.length && isArrayOfMappingConfigurationItems) {
      this.mappingConfiguration = mappingConfiguration;
    } else {
      this.mappingConfiguration = defaultMapping;
    }
  }

  get mappingConfiguration(): MappingConfiguration {
    return this._mappingConfiguration;
  }

  set mappingConfiguration(value: MappingConfiguration) {
    this._mappingConfiguration = value;
  }

  get headersMapping(): HeadersMapping {
    return this._headersMap;
  }
  private getConfigItemWithDisplayAsValue(
    displayAs: string,
    configItem: MappingConfigurationItem
  ): MappingConfigurationItem {
    if (configItem) {
      return {
        displayAs,
        mapping: configItem.mapping,
        key: configItem.key,
        formatter: configItem.formatter,
      };
    } else {
      return configItem;
    }
  }

  getMappingConfigurationByKey(internalKey: string): MappingConfigurationItem {
    return this.mappingConfiguration.find((mapItem) => mapItem.key === internalKey);
  }

  isCurrencyCodeMapped() {
    const hasACurrencyCodeFormatter = Object.values(this.headersMapping).find(
      (mapping) => this.getMappingConfigurationByKey(mapping.key)?.formatter === "currencyCode"
    );
    return hasACurrencyCodeFormatter != undefined;
  }

  setMapper(headersMapping: HeadersMapping) {
    if (headersMapping && Object.keys(headersMapping).length !== 0) {
      this._headersMap = headersMapping;
    }
  }

  process(items: any[]) {
    const records: Row[] = [];

    for (const item of items) {
      const mappedRow: Row = {};
      for (const itemKey of Object.keys(item)) {
        const value = item[itemKey];

        /** columns already been mapped **/
        if (this.headersMapping[itemKey]) {
          const mappingItem = this.getMappingConfigurationByKey(this.headersMapping[itemKey].key);
          mappedRow[this.headersMapping[itemKey].key] = this.formatValue(
            mappingItem?.formatter,
            value
          );
        } else {
          /** lookup for the mapping **/
          const matchedMapping = this.searchMapping(itemKey);

          if (matchedMapping) {
            mappedRow[matchedMapping.key] = this.formatValue(matchedMapping.formatter, value);
            this.headersMapping[itemKey] = {
              key: matchedMapping.key,
              mapping: matchedMapping.mapping,
              displayAs: itemKey,
              formatter: matchedMapping.formatter,
            };
          } else {
            /** Set the mapper if not found from the matchedMapping */
            this.unMappedKeyItem[itemKey] = true;
            this.headersMapping[itemKey] = {
              key: itemKey,
              mapping: [itemKey],
              displayAs: itemKey,
              formatter: "text",
            };

            /** Set the value of the newly added mapper */
            mappedRow[itemKey] = this.formatValue(this.headersMapping[itemKey].formatter, value);
          }
        }
      }
      records.push(mappedRow);
    }

    return records;
  }

  transactionStatus(
    transaction: Transaction,
    alignmentMapping?: AlignmentMappingItem[]
  ): AlignmentMappingItem {
    const mapping = alignmentMapping ? alignmentMapping : alignmentDefaultMapping;
    let mappingItem: AlignmentMappingItem;
    if (transaction.definition) {
      mappingItem = mapping.find((m) => m.keyOnTransaction === transaction.definition);
    } else {
      mappingItem = mapping.find((mappingItem) => {
        const desc = transaction.description.toLowerCase().replace(/\s/g, "");
        return mappingItem.mapping.some((mappingKeywords) => {
          const keyword = mappingKeywords.toLowerCase().replace(/\s/g, "");
          return keyword === desc;
        });
      });
    }

    if (mappingItem) {
      return mappingItem;
    } else {
      return mapping.find((mappingItem) => mappingItem.key === TransactionStatusEnum.transaction);
    }
  }

  private searchMapping(searchTerm: string): MappingConfigurationItem {
    const configItem = this.mappingConfiguration.find((mappingItem) => {
      return mappingItem.mapping.find((fk) => {
        return searchTerm.toLowerCase() === fk.toLowerCase();
      });
    });
    return this.getConfigItemWithDisplayAsValue(searchTerm, configItem);
  }

  formatValue(formatterType: string, value: string) {
    if (!value && value.toString() !== "0") {
      return "";
    }

    switch (formatterType) {
      case "number": {
        if (typeof value === "string") {
          value = this.cleanNumber(value);
        }
        return this.parseNumber(value);
      }
      case "text": {
        return value.trim();
      }
      case "currencyCode": {
        return value.trim();
      }
      default:
        // eslint-disable-next-line no-console
        console.error("Error: formatterType is undefined");
        return value;
    }
  }

  /**
   * @todo this will not work with french number. Need to be redone with regex
   * @param value
   * @private
   */
  private parseNumber(value: string) {
    const toNumber = Number.parseFloat(value);

    if (!toNumber) {
      return 0;
    }
    return toNumber;
  }
}
