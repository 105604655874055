import { EffectsModule } from "@ngrx/effects";

import { PreferenceEffects } from "../services/DataService/preference/state/preference.effects";
import { ReferenceDataEffects } from "../services/DataService/reference-data/state/reference-data.effects";
import { SubscriptionEffects } from "../services/DataService/subscription/state/subscription.effects";
import { RevaluationEffects } from "../services/DataService/transaction/state/revaluation.effects";
import { TransactionEffects } from "../services/DataService/transaction/state/transaction.effects";

export const AppEffects = EffectsModule.forFeature([
  PreferenceEffects,
  TransactionEffects,
  RevaluationEffects,
  ReferenceDataEffects,
  SubscriptionEffects,
]);
