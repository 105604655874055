import * as _ from "lodash";

import { LogService } from "@bitwarden/common/abstractions/log.service";
import { ConsoleLogService } from "@bitwarden/common/services/consoleLog.service";
import { Transaction } from "@bitwarden/web-vault/app/models/data/blobby/transaction.data";
import { GlossBalance } from "@bitwarden/web-vault/app/models/data/shared/gloss-balance";
import {
  GroupingNodeType,
  ChildNodeGroupings,
  ChildNodes,
} from "@bitwarden/web-vault/app/models/types/balanceGroupingTypes";
import { GroupingNodeFactory } from "@bitwarden/web-vault/app/services/DataCalculationService/balanceGrouping/groupingNodeFactory";

export class GroupingNode {
  nodeType: GroupingNodeType = "root";
  groupingKey: string;
  defaultChildren: Array<GroupingNodeType> = ["account", "allocation"];
  limitChildren: Array<GroupingNodeType> = [];
  balance: GlossBalance = new GlossBalance();
  usedGroupings: Array<GroupingNodeType> = [this.nodeType];
  children: ChildNodeGroupings;
  parent: GroupingNode;
  transactions: Array<string> = [];

  protected logService: LogService = new ConsoleLogService(false);

  /**
   * addBalance - adds the values from the GlossBalance parameter to all the balance values
   *              in this GroupingNode.
   * @param additionalBalance
   */
  addBalance(additionalBalance: GlossBalance) {
    try {
      this.balance.add(additionalBalance);
    } catch (e) {
      this.logService.error(e);
    }
  }

  /**
   * addRunningBalance -  only adds the running values from the GlossBalance parameter to all the
   *                      running valued in this GroupingNode.
   * @param additionalBalance
   */
  async addRunningBalance(additionalBalance: GlossBalance) {
    try {
      this.balance.addRunningBalances(additionalBalance);
    } catch (e) {
      this.logService.error(e);
    }
  }

  /**
   * Add the transaction to this node's balance if the transaction matches the group criteria.
   * @param transaction
   */
  async addTransaction(transaction: Transaction) {
    this.setupDefaultChildren();
    if (this.transactionMatchesGroup(transaction)) {
      this.addBalance(transaction.balance);
      this.transactions.push(transaction.id);
      await this.processTransactionForChildren(transaction);
    }
  }

  /**
   * For each of the default child node type, process the transaction
   * @param transaction
   */
  async processTransactionForChildren(transaction: Transaction) {
    for (const childNodeType of this.defaultChildren) {
      if (this.limitChildren.length > 0 && !this.limitChildren.includes(childNodeType)) {
        // skip the child grouping as it is not part of the requested children
        continue;
      }
      if (this.usedGroupings.includes(childNodeType)) {
        // skip the child grouping if it is already used
        continue;
      }
      const childNodes = await GroupingNodeFactory.createNode(
        childNodeType,
        this.usedGroupings,
        true,
        this.limitChildren
      ).processChildren(transaction);
      await this.addChildNodes(childNodeType, childNodes);
    }
  }

  /**
   * Returns a list of ChildNodeGroupings that the transaction would create
   * @param transaction
   */
  async processChildren(transaction: Transaction): Promise<ChildNodes> {
    // Note a root node this should never be a childNodeType that is processing Children
    // These need to have their balances filled out from the transaction
    return {};
  }

  async addChildNodes(groupingKey: string, additionalChildNodes: ChildNodes) {
    for (const childNodeKey in additionalChildNodes) {
      const childNode = additionalChildNodes[childNodeKey];
      childNode.parent = this;
      // if the grouping key is new, then instantiate the record
      if (!this.children?.[groupingKey as GroupingNodeType]) {
        this.children[groupingKey as GroupingNodeType] = {};
      }
      // if the childNode key is new, then set it to be the node
      if (!this.children[groupingKey as GroupingNodeType]?.[childNodeKey]) {
        if (childNode instanceof GroupingNode) {
          this.children[groupingKey as GroupingNodeType][childNodeKey] = await childNode.copy();
        }
      } else {
        // add the childNode components to the existing childNode for this key
        const existingChildNode = this.children[groupingKey as GroupingNodeType][childNodeKey];
        if (existingChildNode instanceof GroupingNode && childNode instanceof GroupingNode) {
          await existingChildNode.add(childNode);
        }
      }
    }
  }

  async addChildRunningBalance(groupingKey: string, additionalChildNodes: ChildNodes) {
    for (const childNodeKey in additionalChildNodes) {
      const childNode = additionalChildNodes[childNodeKey];

      // if the grouping key is new, then instantiate the record
      if (!this.children?.[groupingKey as GroupingNodeType]) {
        this.children[groupingKey as GroupingNodeType] = {};
      }
      // if the childNode key is new, then set it to be the node
      if (!this.children?.[groupingKey as GroupingNodeType]?.[childNodeKey]) {
        // TODO: only copy the running amounts of the child
        this.children[groupingKey as GroupingNodeType][childNodeKey] =
          await childNode.copyRunning();
      } else {
        // add the childNode components to the existing childNode for this key
        await this.children[groupingKey as GroupingNodeType][childNodeKey].addRunning(childNode);
      }
    }
  }

  /**
   * Check if the transaction matches the criteria to be added to this GroupingNode
   * @param transaction
   */
  transactionMatchesGroup(transaction: Transaction): boolean {
    return true;
  }

  /**
   * For each of the default child node types, instantiate the record object
   */
  setupDefaultChildren() {
    if (!this.children) {
      this.children = {} as ChildNodeGroupings;
      for (const childNodeType of this.defaultChildren) {
        if (this.limitChildren.length === 0 || this.limitChildren.includes(childNodeType)) {
          if (!this.usedGroupings.includes(childNodeType)) {
            this.children[childNodeType] = {};
          }
        }
      }
    }
  }

  addUsedGroupings(newUsedGroupings: Array<GroupingNodeType>) {
    this.usedGroupings = [...new Set([...newUsedGroupings, ...this.usedGroupings])];
  }

  /**
   * Add the values in the additional node to the current node
   * @param additionalGroupingNode
   */
  async add(additionalGroupingNode: GroupingNode) {
    if (this.nodeType != additionalGroupingNode.nodeType) {
      throw new Error("Trying to add two grouping nodes that are not the same type");
    }
    this.balance.add(additionalGroupingNode.balance);
    for (const groupingKey in additionalGroupingNode.children) {
      const additionalChildNodes = additionalGroupingNode.children[groupingKey as GroupingNodeType];
      await this.addChildNodes(groupingKey, additionalChildNodes);
    }
  }

  /**
   * Adds the running values from the additionalGroupingNode to the
   * @param additionalGroupingNode
   */
  async addRunning(additionalGroupingNode: GroupingNode) {
    if (this.nodeType != additionalGroupingNode.nodeType) {
      throw new Error("Trying to add two grouping nodes that are not the same type");
    }
    await this.addRunningBalance(additionalGroupingNode.balance);
    for (const groupingKey in additionalGroupingNode.children) {
      const additionalChildNodes = additionalGroupingNode.children[groupingKey as GroupingNodeType];
      await this.addChildRunningBalance(groupingKey, additionalChildNodes);
    }
  }

  async copy(): Promise<GroupingNode> {
    return _.cloneDeep(this);
  }

  async copyRunning(): Promise<GroupingNode> {
    const copyNode = GroupingNodeFactory.createNode(
      this.nodeType,
      this.usedGroupings,
      true,
      this.limitChildren,
      this.groupingKey
    );
    copyNode.defaultChildren = this.defaultChildren;
    copyNode.setupDefaultChildren();
    await copyNode.addRunning(this);
    return copyNode;
  }

  async buildAttributesFromArray(o: any) {
    if (o?.defaultChildren) {
      this.defaultChildren = o.defaultChildren;
    }
    this.setupDefaultChildren();
    if (o?.transactions) {
      this.transactions = o.transactions;
    }

    if (o?.balance) {
      this.balance = new GlossBalance().setToBalanceObj(o.balance);
    }

    // create Children
    if (o.children) {
      for (const childNodeType in o.children) {
        for (const childNodeKey in o.children[childNodeType]) {
          const childNode = o.children[childNodeType][childNodeKey];
          const newChildNode = GroupingNodeFactory.createNode(
            childNode.nodeType,
            childNode.usedGroupings,
            true,
            childNode.limitChildren,
            childNode.groupingKey
          );
          await newChildNode.buildAttributesFromArray(childNode);
          // newChildNode.parent = this;
          const childNodes: ChildNodes = {};
          childNodes[childNodeKey] = newChildNode;
          await this.addChildNodes(childNodeType, childNodes);
          // this.children[childNodeType as GroupingNodeType][childNodeArray][childNodeKey] = newChildNode;
        }
      }
    }
  }
}
