export enum EstimateActionsEnum {
  accountCreation = "account-creation",
  transactionTransfer = "transactionTransfer",
  interest = "interest-action" /** Interest calculation*/,
  transactionTemplate = "transaction-template" /** For a specific template: When there is one we should change name accordingly*/,
  recurringFixed = "recurring-fixed" /** Calculating the same transaction for the given period */,
  nonRecurring = "non-recurring" /** Generating only one transaction. Can be like recurringFixed with both startDate and endDate being the same */,
}

export enum UserEstimateActionsEnum {
  interest = "Interest",
  template = "Template",
}
