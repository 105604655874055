import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

import { PreferenceStateType } from "@bitwarden/web-vault/app/models/types/PrefereneceTypes";

import { PreferenceService } from "./preference.service";

@Injectable({
  providedIn: "root",
})
export class PreferenceState {
  constructor(private preferenceService: PreferenceService) {
    this.getInitialState();
  }

  private initialState: PreferenceStateType;
  private stateSubject: BehaviorSubject<PreferenceStateType> =
    new BehaviorSubject<PreferenceStateType>(null);
  getInitialState() {
    this.preferenceService.getAll().then((preference) => {
      this.preferenceService.getUserPreferenceObject().then((visualPref) => {
        if (preference && visualPref) {
          this.initialState = { preferenceType: preference, preferenceVisual: visualPref };
          this.stateSubject.next(this.initialState);
        } else {
          this.initialState = { preferenceType: false, preferenceVisual: false };
          this.stateSubject.next(this.initialState);
        }
      });
    });
  }

  getState(): Observable<PreferenceStateType> {
    if (this.stateSubject) {
      return this.stateSubject.asObservable();
    } else {
      this.getInitialState();

      this.stateSubject = new BehaviorSubject<PreferenceStateType>(null); // Create a new stateSubject
      return this.stateSubject.asObservable();
    }
  }

  setState(newState: PreferenceStateType): void {
    this.stateSubject.next(newState);
  }
}
