import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Subject, takeUntil } from "rxjs";

import { GlobalService } from "@bitwarden/common/services/global/global.service";
import { InstitutionsAddEditComponent } from "@bitwarden/web-vault/app/gloss/settings/manage-institutions/institutions-add-edit/institutions-add-edit.component";
import { Connector } from "@bitwarden/web-vault/app/models/data/blobby/connector.data";
import { Institution } from "@bitwarden/web-vault/app/models/data/blobby/institution.data";
import { BookService } from "@bitwarden/web-vault/app/services/DataService/book/book.service";
import { ConnectorService } from "@bitwarden/web-vault/app/services/DataService/connector/connector.service";
import { InstitutionService } from "@bitwarden/web-vault/app/services/DataService/institution/institution.service";

@Component({
  selector: "app-manage-institutions",
  templateUrl: "./manage-institutions.component.html",
})
export class ManageInstitutionsComponent implements OnInit, OnDestroy {
  private destroy$: Subject<boolean> = new Subject<boolean>();
  loading = true;
  dialogueRef: MatDialogRef<InstitutionsAddEditComponent>;
  existingInstitutions: Institution[];
  connector: Connector;

  @ViewChild("inputElement") inputElement: ElementRef;
  constructor(
    public dialog: MatDialog,
    private institutionService: InstitutionService,
    private globalService: GlobalService,
    private bookService: BookService,
    private connectorService: ConnectorService
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next(true);
  }

  async ngOnInit(): Promise<void> {
    try {
      this.existingInstitutions = await this.institutionService.getAll();
    } catch (e) {
      this.globalService.showErrorMessage("errorOccurred", e);
    }
  }

  invite() {
    this.edit(null);
  }
  async edit(institution: Institution): Promise<void> {
    const dialogRef = this.dialog.open(InstitutionsAddEditComponent, {
      width: "800px", // for mat-dialog-container, built in max-width: 80vw; so this width works for smaller screens too
      data: {
        institution,
        actionSucceeded: this.actionSucceeded.bind(this),
        delete: this.delete.bind(this, institution),
      },
      autoFocus: ".auto-focus",
      backdropClass: "custom-backdrop-class",
      disableClose: true,
    });

    this.dialogueRef = dialogRef;

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe((newInstitutions) => {
        if (newInstitutions) {
          this.existingInstitutions = newInstitutions;
        }
      });
  }

  async delete(institution: Institution) {
    const confirmed = await this.globalService.showRemoveDialogue();
    if (confirmed) {
      try {
        this.loading = true;
        const deleted = await this.institutionService.delete(institution, false);
        if (deleted) {
          await this.bookService.removeInstitutionsFromAccounts(institution);
          await this.actionSucceeded("institutionRemoved");
        }
      } catch (e) {
        this.globalService.showErrorMessage("errorOccurred", e);
      } finally {
        this.loading = false;
      }
    }
  }

  async actionSucceeded(actionMessage: string) {
    this.existingInstitutions = await this.institutionService.getAll();
    this.dialogueRef?.close(this.existingInstitutions);
    this.globalService.showSuccessMessage("succeeded", actionMessage);
  }

  async testConnector() {
    this.loading = true;
    try {
      await this.connectorService.testConnector();
      this.globalService.showSuccessMessage("succeeded", "connectorTested");
    } catch (e) {
      this.globalService.showErrorMessage("errorOccurred", e);
    } finally {
      this.loading = false;
    }
  }
}
