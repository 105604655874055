import { Injectable } from "@angular/core";

import { SourceTransactionResponse } from "@bitwarden/common/models/response/source-transaction-response";
import { SymbolInfoResponse } from "@bitwarden/common/models/response/symbol-info.response";
import { Book } from "@bitwarden/web-vault/app/models/data/blobby/book.data";
import { Classification } from "@bitwarden/web-vault/app/models/data/blobby/classification.data";
import { Connector } from "@bitwarden/web-vault/app/models/data/blobby/connector.data";
import { Estimate } from "@bitwarden/web-vault/app/models/data/blobby/estimate.data";
import { Institution } from "@bitwarden/web-vault/app/models/data/blobby/institution.data";
import { Preference } from "@bitwarden/web-vault/app/models/data/blobby/preference.data";
import { ReferenceData } from "@bitwarden/web-vault/app/models/data/blobby/reference-data.data";
import { SourceBook } from "@bitwarden/web-vault/app/models/data/blobby/source-book";
import { SourceCategory } from "@bitwarden/web-vault/app/models/data/blobby/source-category";
import { SourceTransaction } from "@bitwarden/web-vault/app/models/data/blobby/source-transaction.data";
import { Transaction } from "@bitwarden/web-vault/app/models/data/blobby/transaction.data";
import { VaultFile } from "@bitwarden/web-vault/app/models/data/blobby/vault-file.data";
import { ImportHandler } from "@bitwarden/web-vault/app/models/data/import-handler.data";
import { BookResponse } from "@bitwarden/web-vault/app/models/data/response/book.response";
import { CategoryResponse } from "@bitwarden/web-vault/app/models/data/response/category.response";
import { ClassificationResponse } from "@bitwarden/web-vault/app/models/data/response/classification.response";
import { ConnectorResponse } from "@bitwarden/web-vault/app/models/data/response/connector.response";
import { EstimateResponse } from "@bitwarden/web-vault/app/models/data/response/estimate.response";
import { ImportHandlerResponse } from "@bitwarden/web-vault/app/models/data/response/import-handler.response";
import { InstitutionResponse } from "@bitwarden/web-vault/app/models/data/response/institution.response";
import { PreferenceResponse } from "@bitwarden/web-vault/app/models/data/response/preference.response";
import { ReferenceDataResponse } from "@bitwarden/web-vault/app/models/data/response/reference-data-response";
import { TransactionResponse } from "@bitwarden/web-vault/app/models/data/response/transaction-response";
import { VaultFileResponse } from "@bitwarden/web-vault/app/models/data/response/vault-file.response";
import { GlossBalance } from "@bitwarden/web-vault/app/models/data/shared/gloss-balance";
import { SymbolInfoData } from "@bitwarden/web-vault/app/models/data/symbol-info.data";
import { Valuation } from "@bitwarden/web-vault/app/models/data/valuation.data";

import { CipherView } from "../../../../../../libs/common/src/models/view/cipher.view";
import { Category } from "../../models/data/blobby/category.data";
import { BlobbyDataTypeEnum } from "../../models/enum/blobbyDataTypeEnum";
import {
  crudFlag,
  crudFlagDataType,
  GlossEncryptedDataType,
} from "../../models/enum/glossEncryptedDataType";

@Injectable({
  providedIn: "root",
})
export class CipherItems {
  ciphers: CipherView[] = [];

  constructor() {
    // Fetch all transaction
  }

  getNewClassByItemType(
    item: GlossEncryptedDataType,
    type: BlobbyDataTypeEnum
  ): GlossEncryptedDataType {
    if (type === BlobbyDataTypeEnum.Category) {
      const categoryResponse = new CategoryResponse(item);
      return new Category(categoryResponse);
    }

    if (type === BlobbyDataTypeEnum.Classification) {
      const classificationResponse = new ClassificationResponse(item);
      return new Classification(classificationResponse);
    }

    if (type === BlobbyDataTypeEnum.Institution) {
      const institutionResponse = new InstitutionResponse(item);
      return new Institution(institutionResponse);
    }

    if (type === BlobbyDataTypeEnum.Book) {
      const bookResponse = new BookResponse(item);
      return new Book(bookResponse);
    }

    if (type === BlobbyDataTypeEnum.Estimate) {
      const actionResponse = new EstimateResponse(item);
      return new Estimate(actionResponse);
    }

    if (type === BlobbyDataTypeEnum.Symbol) {
      const symbolResponse = new SymbolInfoResponse(item);
      return new SymbolInfoData(symbolResponse);
    }

    if (type === BlobbyDataTypeEnum.SourceTransaction) {
      const stResponse = new SourceTransactionResponse(item);
      return new SourceTransaction(stResponse);
    }

    if (type === BlobbyDataTypeEnum.SourceCategory) {
      const stResponse = new SourceCategory(item);
      return new SourceCategory(stResponse);
    }

    if (type === BlobbyDataTypeEnum.SourceBook) {
      return new SourceBook(item);
    }

    if (type === BlobbyDataTypeEnum.Transaction) {
      const tResponse = new TransactionResponse(item);
      return new Transaction(tResponse);
    }

    if (type === BlobbyDataTypeEnum.ReferenceData) {
      const referenceResponse = new ReferenceDataResponse(item);
      return new ReferenceData(referenceResponse);
    }

    if (type === BlobbyDataTypeEnum.ImportHandler) {
      const ihResponse = new ImportHandlerResponse(item);
      return new ImportHandler(ihResponse);
    }

    if (type === BlobbyDataTypeEnum.EstimateGroup) {
      const egResponse = new EstimateResponse(item);
      return new Estimate(egResponse);
    }

    if (type === BlobbyDataTypeEnum.Preference) {
      const pResponse = new PreferenceResponse(item);
      return new Preference(pResponse);
    }

    if (type === BlobbyDataTypeEnum.Connector) {
      const connResponse = new ConnectorResponse(item);
      return new Connector(connResponse);
    }

    if (type === BlobbyDataTypeEnum.VaultFile) {
      const vfResponse = new VaultFileResponse(item);
      return new VaultFile(vfResponse);
    }
  }

  getNewCipherItemsByFlag(
    existingCipherItems: GlossEncryptedDataType[],
    type: BlobbyDataTypeEnum,
    item: GlossEncryptedDataType,
    flag: crudFlagDataType
  ): GlossEncryptedDataType[] {
    switch (flag) {
      case crudFlag.update:
        return existingCipherItems.map((ei) => {
          let eiClass = this.getNewClassByItemType(ei, type);
          if (eiClass.id === item.id) {
            eiClass = item;
          } else {
            eiClass = ei;
          }
          return eiClass;
        });

      case crudFlag.create:
        existingCipherItems.push(item);
        return existingCipherItems.map((ei) => {
          return this.getNewClassByItemType(ei, type);
        });

      case crudFlag.delete:
        return existingCipherItems.filter((ei) => {
          const c = this.getNewClassByItemType(ei, type);
          if (c.id !== item.id) {
            return c;
          }
        });
    }
  }

  getNewCategoryCipherItems(
    existingCipherItems: Category[],
    type: BlobbyDataTypeEnum,
    item: Category,
    flag: crudFlagDataType
  ): Category[] {
    return <Category[]>this.getNewCipherItemsByFlag(existingCipherItems, type, item, flag);
  }

  getNewVaultFileCipherItems(
    existingCipherItems: VaultFile[],
    type: BlobbyDataTypeEnum,
    item: VaultFile,
    flag: crudFlagDataType
  ): VaultFile[] {
    return <VaultFile[]>this.getNewCipherItemsByFlag(existingCipherItems, type, item, flag);
  }

  getNewSourceCategoryCipherItems(
    existingCipherItems: SourceCategory[],
    type: BlobbyDataTypeEnum,
    item: SourceCategory,
    flag: crudFlagDataType
  ): SourceCategory[] {
    return <SourceCategory[]>this.getNewCipherItemsByFlag(existingCipherItems, type, item, flag);
  }

  getNewClassificationCipherItems(
    existingItems: Classification[],
    type: BlobbyDataTypeEnum,
    item: Classification,
    flag: crudFlagDataType
  ): Classification[] {
    return <Classification[]>this.getNewCipherItemsByFlag(existingItems, type, item, flag);
  }
  getNewInstitutionCipherItems(
    existingItems: Institution[],
    type: BlobbyDataTypeEnum,
    item: Institution,
    flag: crudFlagDataType
  ): Institution[] {
    return <Institution[]>this.getNewCipherItemsByFlag(existingItems, type, item, flag);
  }

  getNewSourceTransactionCipherItems(
    existingCipherItems: SourceTransaction[],
    type: BlobbyDataTypeEnum,
    item: SourceTransaction,
    flag: crudFlagDataType
  ): SourceTransaction[] {
    return <SourceTransaction[]>this.getNewCipherItemsByFlag(existingCipherItems, type, item, flag);
  }

  getNewSourceBookCipherItems(
    existingCipherItems: SourceBook[],
    type: BlobbyDataTypeEnum,
    item: SourceBook,
    flag: crudFlagDataType
  ): SourceBook[] {
    return <SourceBook[]>this.getNewCipherItemsByFlag(existingCipherItems, type, item, flag);
  }

  getNewEstimateGroupCipherItems(
    existingCipherItems: Estimate[],
    type: BlobbyDataTypeEnum,
    item: Estimate,
    flag: crudFlagDataType
  ): Estimate[] {
    return <Estimate[]>this.getNewCipherItemsByFlag(existingCipherItems, type, item, flag);
  }

  getNewPreferenceCipherItems(
    existingCipherItems: Preference[],
    type: BlobbyDataTypeEnum,
    item: Preference,
    flag: crudFlagDataType
  ): Preference[] {
    return <Preference[]>this.getNewCipherItemsByFlag(existingCipherItems, type, item, flag);
  }

  getNewSymbolCipherItems(
    existingCipherItems: SymbolInfoData[],
    type: BlobbyDataTypeEnum,
    item: SymbolInfoData,
    flag: crudFlagDataType
  ): SymbolInfoData[] {
    return <SymbolInfoData[]>this.getNewCipherItemsByFlag(existingCipherItems, type, item, flag);
  }

  getNewEstimateCipherItems(
    existingCipherItems: Estimate[],
    type: BlobbyDataTypeEnum,
    item: Estimate,
    flag: crudFlagDataType
  ): Estimate[] {
    return <Estimate[]>this.getNewCipherItemsByFlag(existingCipherItems, type, item, flag);
  }

  getNewTransactionCipherItems(
    existingCipherItems: Transaction[],
    type: BlobbyDataTypeEnum,
    item: Transaction,
    flag: crudFlagDataType
  ): Transaction[] {
    const transactions = <Transaction[]>(
      this.getNewCipherItemsByFlag(existingCipherItems, type, item, flag)
    );
    for (const transaction of transactions) {
      if (transaction.id && transaction.id === item.id) {
        transaction.valuation = new Valuation().setToValuationObj({});
        transaction.balance = new GlossBalance().setToBalanceObj({});
        transaction.allocations = [];
      }
    }

    return transactions;
  }
  getNewReferenceCipherItems(
    existingCipherItems: ReferenceData[],
    type: BlobbyDataTypeEnum,
    item: ReferenceData,
    flag: crudFlagDataType
  ): ReferenceData[] {
    return <ReferenceData[]>this.getNewCipherItemsByFlag(existingCipherItems, type, item, flag);
  }

  getNewConnectorCipherItems(
    existingCipherItems: Connector[],
    type: BlobbyDataTypeEnum,
    item: Connector,
    flag: crudFlagDataType
  ): ReferenceData[] {
    return <ReferenceData[]>this.getNewCipherItemsByFlag(existingCipherItems, type, item, flag);
  }

  getNewAccountCipherItems(
    existingCipherItems: Book[],
    type: BlobbyDataTypeEnum,
    item: Book,
    flag: crudFlagDataType
  ): Book[] {
    return <Book[]>this.getNewCipherItemsByFlag(existingCipherItems, type, item, flag);
  }

  getNewImportHandlerCipherItems(
    existingCipherItems: ImportHandler[],
    type: BlobbyDataTypeEnum,
    item: ImportHandler,
    flag: crudFlagDataType
  ): ImportHandler[] {
    return <ImportHandler[]>this.getNewCipherItemsByFlag(existingCipherItems, type, item, flag);
  }

  getNewCipherItems(
    existingCipherItems: GlossEncryptedDataType[],
    type: BlobbyDataTypeEnum,
    item: GlossEncryptedDataType,
    flag: crudFlagDataType
  ): GlossEncryptedDataType[] {
    if (type === BlobbyDataTypeEnum.Category) {
      return this.getNewCategoryCipherItems(
        <Category[]>existingCipherItems,
        type,
        <Category>item,
        flag
      );
    }

    if (type === BlobbyDataTypeEnum.VaultFile) {
      return this.getNewVaultFileCipherItems(
        <VaultFile[]>existingCipherItems,
        type,
        <VaultFile>item,
        flag
      );
    }

    if (type === BlobbyDataTypeEnum.SourceCategory) {
      return this.getNewSourceCategoryCipherItems(
        <SourceCategory[]>existingCipherItems,
        type,
        <SourceCategory>item,
        flag
      );
    }

    if (type === BlobbyDataTypeEnum.Classification) {
      return this.getNewClassificationCipherItems(
        <Classification[]>existingCipherItems,
        type,
        <Classification>item,
        flag
      );
    }

    if (type === BlobbyDataTypeEnum.Institution) {
      return this.getNewInstitutionCipherItems(
        <Institution[]>existingCipherItems,
        type,
        <Institution>item,
        flag
      );
    }

    if (type === BlobbyDataTypeEnum.SourceTransaction) {
      return this.getNewSourceTransactionCipherItems(
        <SourceTransaction[]>existingCipherItems,
        type,
        <SourceTransaction>item,
        flag
      );
    }

    if (type === BlobbyDataTypeEnum.SourceBook) {
      return this.getNewSourceBookCipherItems(
        <SourceBook[]>existingCipherItems,
        type,
        <SourceBook>item,
        flag
      );
    }

    if (type === BlobbyDataTypeEnum.EstimateGroup) {
      return this.getNewEstimateGroupCipherItems(
        <Estimate[]>existingCipherItems,
        type,
        <Estimate>item,
        flag
      );
    }

    if (type === BlobbyDataTypeEnum.Preference) {
      return this.getNewPreferenceCipherItems(
        <Preference[]>existingCipherItems,
        type,
        <Preference>item,
        flag
      );
    }

    if (type === BlobbyDataTypeEnum.Symbol) {
      return this.getNewSymbolCipherItems(
        <SymbolInfoData[]>existingCipherItems,
        type,
        <SymbolInfoData>item,
        flag
      );
    }

    if (type === BlobbyDataTypeEnum.Estimate) {
      return this.getNewEstimateCipherItems(
        <Estimate[]>existingCipherItems,
        type,
        <Estimate>item,
        flag
      );
    }

    if (type === BlobbyDataTypeEnum.Transaction) {
      return this.getNewTransactionCipherItems(
        <Transaction[]>existingCipherItems,
        type,
        <Transaction>item,
        flag
      );
    }

    if (type === BlobbyDataTypeEnum.ReferenceData) {
      return this.getNewReferenceCipherItems(
        <ReferenceData[]>existingCipherItems,
        type,
        <ReferenceData>item,
        flag
      );
    }

    if (type === BlobbyDataTypeEnum.Connector) {
      return this.getNewConnectorCipherItems(
        <Connector[]>existingCipherItems,
        type,
        <Connector>item,
        flag
      );
    }

    if (type === BlobbyDataTypeEnum.Book) {
      return this.getNewAccountCipherItems(<Book[]>existingCipherItems, type, <Book>item, flag);
    }

    if (type === BlobbyDataTypeEnum.ImportHandler) {
      return this.getNewImportHandlerCipherItems(
        <ImportHandler[]>existingCipherItems,
        type,
        <ImportHandler>item,
        flag
      );
    }
  }
}
