import { Injectable } from "@angular/core";

import { PreferenceService } from "../../services/DataService/preference/preference.service";

@Injectable({
  providedIn: "root",
})
export class HelperPreference {
  constructor(private preferenceService: PreferenceService) {}
  async getBaseCurrency() {
    const pref = await this.preferenceService.getAll();
    if (pref) {
      return pref.baseCurrency;
    } else {
      return "USD";
    }
  }
}
