import { Injectable } from "@angular/core";
import { isBefore } from "date-fns";

import { getReferenceData } from "@bitwarden/web-vault/app/models/data/reference.data";
import { SymbolInfoData } from "@bitwarden/web-vault/app/models/data/symbol-info.data";
import { SymbolValue } from "@bitwarden/web-vault/app/models/data/symbol-value.data";
import { SymbolData } from "@bitwarden/web-vault/app/models/data/symbol.data";
import { AllocationService } from "@bitwarden/web-vault/app/services/DataCalculationService/allocation/allocation.service";
import { BlobbyService } from "@bitwarden/web-vault/app/services/blobby/blobby.service";
import { BlobbyUtils } from "@bitwarden/web-vault/app/services/blobby/blobbyUtils";
import { HelperPreference } from "@bitwarden/web-vault/app/shared/utils/helper.preference";

import { DataRepositoryService, queryFilter } from "../../DataRepository/data-repository.service";
import { DataServiceAbstraction } from "../data.service.abstraction";
import { TransactionService } from "../transaction/transaction.service";

@Injectable({
  providedIn: "root",
})
export class SymbolService implements DataServiceAbstraction {
  constructor(
    private dataRepositoryService: DataRepositoryService,
    private transactionService: TransactionService,
    private blobbyService: BlobbyService,
    private allocationService: AllocationService,
    private helperPreference: HelperPreference
  ) {}

  // todo implement
  async getAll(): Promise<SymbolData[]> {
    return await this.dataRepositoryService.getAllSymbols();
  }
  async update(item: SymbolData, triggerObservable: boolean): Promise<SymbolData> {
    throw new Error("Method not implemented.");
  }
  async delete(item: SymbolInfoData, triggerObservable: boolean): Promise<boolean> {
    throw new Error("Method not implemented.");
  }
  async get(itemId: string): Promise<SymbolData> {
    throw new Error("Method not implemented.");
  }
  async create(item: SymbolData): Promise<SymbolData> {
    throw new Error("Method not implemented.");
  }

  async query(where: queryFilter): Promise<SymbolData[]> {
    return await this.dataRepositoryService.querySymbols(where);
  }

  /**
   * Returns the symbols of an account that have a closing balance at the YMD date supplied
   * inefficiently implemented, should be able to read caches of this rather than calculate each time
   *
   * @param accountId
   * @param ymd
   */
  async getSymbolsWithBalance(accountId: string, ymd: number): Promise<string[]> {
    const symbolMap: { [index: string]: any } = {};
    const allSymbols = await this.query((symbol: SymbolData) => symbol.accountId === accountId);
    for (const accountSymbol of allSymbols) {
      const accountBalAllocs = await this.allocationService.getBalanceClose(
        accountId,
        accountSymbol.name,
        true,
        true,
        ymd
      );
      for (const alloc of accountBalAllocs) {
        if (alloc.value.symbolAmount.amount !== 0) {
          symbolMap[accountSymbol.name] = true;
        }
      }
    }
    return Object.keys(symbolMap);
  }

  /**
   * Retrieve an array of reference data symbol values for display/calculation
   *
   * @param symbol
   * @param startYMD
   * @param endYMD
   * @param recurringPeriod
   * @param recurringFreq
   */
  async getSymbolValues(
    symbol: string,
    startYMD: number,
    endYMD: number,
    recurringPeriod: string,
    recurringFreq: number
  ): Promise<SymbolValue[]> {
    return await this.getSymbolValuesRelative(
      symbol,
      null,
      startYMD,
      endYMD,
      recurringPeriod,
      recurringFreq
    );
  }

  async getSymbolValuesRelative(
    symbol: string,
    symbolBase: string,
    startYMD: number,
    endYMD: number,
    recurringPeriod: string,
    recurringFreq: number
  ): Promise<SymbolValue[]> {
    // get the list of dates to return
    const startAsDate = BlobbyUtils.setYMDToDate(startYMD);
    const endAsDate = BlobbyUtils.setYMDToDate(endYMD);
    const baseCurrency = await this.helperPreference.getBaseCurrency();
    const dateYMDsToGet: Array<number> = [startYMD];
    const result: Array<SymbolValue> = [];
    for (
      let i = BlobbyUtils.nextPeriod(startAsDate, recurringPeriod, recurringFreq);
      isBefore(i, endAsDate);
      i = BlobbyUtils.nextPeriod(i, recurringPeriod, recurringFreq)
    ) {
      dateYMDsToGet.push(BlobbyUtils.setDateToYMD(i));
    }
    // return all as 1 for now, figure out how to load them from data later
    dateYMDsToGet.forEach((ymd) => {
      const symVal = new SymbolValue();
      symVal.symbol = symbol;
      symVal.symbolBase = symbolBase || baseCurrency; // todo get the user's default currency here
      symVal.value = getReferenceData(symbol, symVal.symbolBase, ymd);
      symVal.dateYMD = ymd;
      result.push(symVal);
    });

    return result;
  }

  /**
   * Returns the list of symbols of an account that has been active between a date period
   * This includes any symbols that have transactions for that period for that account
   *
   * @param accountId
   * @param startYMD
   * @param endYMD
   */
  getActiveSymbols(accountId: string, startYMD: number, endYMD: number): string[] {
    const symbolMap: { [index: string]: any } = {};
    const transactionsInRange = this.blobbyService.getTransactionsInRange(
      accountId,
      startYMD,
      endYMD
    );

    if (transactionsInRange) {
      transactionsInRange.forEach((trans) => {
        const symbol = trans.quantity.actualQuantity.symbol;
        const currency = trans.quantity.currency;

        if (trans.accountId === accountId && symbol) {
          symbolMap[symbol] = true;
        } else if (trans.accountId === accountId && !symbol && currency) {
          symbolMap[currency] = true;
        }
      });
      return Object.keys(symbolMap);
    }
    return [];
  }
}
