<div class="estimates-form-dialog">
  <app-spinner [showSpinner]="loading"></app-spinner>
  <h1 mat-dialog-title id="create-estimate-header">
    {{ title }}
    <span
      class="mat-form-field-required-marker"
      title="Weather estimate is making money or loosing money"
      >(* : Required fields , ?: Hover over for info)</span
    >
  </h1>

  <div mat-dialog-content>
    <app-estimate-form
      [isEditMode]="data.isEditMode"
      [estimateGroupId]="null"
      [isNewGroup]="false"
      [estimate]="data.estimate"
      (actionSucceeded)="data.actionSucceeded($event)"
      (delete)="data.delete($event)"
    ></app-estimate-form>
  </div>
</div>
