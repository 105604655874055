import { Injectable } from "@angular/core";

import { Transaction } from "@bitwarden/web-vault/app/models/data/blobby/transaction.data";
import { DateStartPreferences } from "@bitwarden/web-vault/app/services/DataCalculationService/balanceAlignment/balanceAlignment";
import { BalanceGrouping } from "@bitwarden/web-vault/app/services/DataCalculationService/balanceGrouping/balanceGrouping";

import { GranularityProperty, GroupingNodeType } from "../../../models/types/balanceGroupingTypes";

@Injectable({
  providedIn: "root",
})
export class BalanceGroupingWorkerService {
  private port1: MessagePort;
  private worker: Worker;

  constructor(
    transactions: Array<Transaction>,
    datePreferences: DateStartPreferences,
    createChildren = true,
    fillDaily = false,
    limitChildren?: Array<GroupingNodeType>,
    granularityList?: Array<GranularityProperty>,
    callback?: (groupedBalance: BalanceGrouping, type: string, id: string) => Promise<void>
  ) {
    // Create a MessageChannel
    const channel = new MessageChannel();
    this.port1 = channel.port1; // Port to use on the main thread
    const port2 = channel.port2; // Port to transfer to the web worker

    // Create the web worker and transfer port2
    this.worker = new Worker(new URL("./balance-grouping.worker", import.meta.url), {
      type: "module",
    });

    // Transfer port2 to the worker
    this.worker.postMessage(
      {
        port: port2,
        transactions: transactions,
        datePreferences: datePreferences,
        createChildren: createChildren,
        fillDaily: fillDaily,
        limitChildren: limitChildren,
        granularityList: granularityList,
      },
      [port2]
    );

    // Set up message handling on port1
    this.port1.onmessage = async (event) => {
      const type = event?.data?.type ? event.data.type : null;
      const id = event?.data?.id ? event.data.id : null;

      if (event?.data?.groupedBalance) {
        // Rebuild the balance grouping from the object
        const groupedBalance = new BalanceGrouping(transactions);
        await groupedBalance.rebuildFromWebWorker(event.data.groupedBalance, granularityList);

        if (typeof callback === "function") {
          await callback(groupedBalance, type, id);
        }
      }
    };

    // Send a message through port1
    this.port1.postMessage({ message: "Hello from main thread!" });
  }
}
