import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";

import { pathMessages } from "@bitwarden/web-vault/app/components/cell-renderers/no-rows-overlay-component-of-dash-table/scenario-messages";
import { ScenarioMessageType } from "@bitwarden/web-vault/app/models/types/general-types";
import { BalanceGrouping } from "@bitwarden/web-vault/app/services/DataCalculationService/balanceGrouping/balanceGrouping";
import { DashboardService } from "@bitwarden/web-vault/app/services/dashboard/dashboard-service";
import { ScenarioMessagesService } from "@bitwarden/web-vault/app/services/scenarioMessages/scenario-messages.service";

@Component({
  selector: "app-no-rows-overlay",
  templateUrl: "./no-rows-overlay-component-of-dash-table.component.html",
})
export class NoRowsOverlayComponentOfDashTable implements ICellRendererAngularComp {
  private readonly DEFAULT_SCENARIO_INDEX = 2;
  scenarioIndex: number;
  params: any;
  message: ScenarioMessageType;
  positiveAccountsCount = 0;
  accountsCount = 0;
  balanceGrouping: BalanceGrouping;
  dashboardService: DashboardService;
  dashboardType: string;
  hasInterestRate: boolean;
  userPrefilledMessage = "I would like to join the early access program…";

  constructor(private scenarioMessagesService: ScenarioMessagesService) {}

  agInit(params: any): void {
    this.params = params;
    const componentParent = params?.context?.componentParent;
    this.scenarioIndex = componentParent?.scenarioIndex || this.DEFAULT_SCENARIO_INDEX;
    this.balanceGrouping = componentParent?.groupBalances;
    this.dashboardType = componentParent?.dashboardParameters?.type;
    this.dashboardService = componentParent?.dashboardService;
    this.setIsInterestRateStatus().then(() => {
      this.setScenarioMessages();
    });
  }

  async setIsInterestRateStatus() {
    this.hasInterestRate = await this.scenarioMessagesService.hasInterestRate();
  }

  setScenarioMessages() {
    if (!this.balanceGrouping) {
      return;
    }

    this.setAccountInfo().then(() => {
      this.setMessages();
    });
  }

  refresh(params: any): boolean {
    return false; // No need to refresh since data fetch is already handled
  }

  openHelp(event: Event) {
    this.params.context.componentParent.openHelp(event, this.userPrefilledMessage);
  }

  async setAccountInfo() {
    const { accountsCount, positiveAccountsCount } =
      await this.scenarioMessagesService.getAccountsCount(this.balanceGrouping);
    this.accountsCount = accountsCount;
    this.positiveAccountsCount = positiveAccountsCount;
  }

  setMessages() {
    if (this.positiveAccountsCount === 0) {
      this.message = pathMessages[this.scenarioIndex].noPositiveBalanceAccounts;
      this.scenarioMessagesService.reset();
      return;
    }

    if (!this.hasInterestRate) {
      // TODO - we may need to be specific about no interest rate and all zero interest rate
      this.message = pathMessages[this.scenarioIndex].noInterestRate;
      this.userPrefilledMessage = this.scenarioMessagesService.getPredefinedMessage();
      this.scenarioMessagesService.reset();
      return;
    }

    if (this.accountsCount === 1 && this.positiveAccountsCount === 1) {
      this.message = pathMessages[this.scenarioIndex].singleAndPositive;
      this.scenarioMessagesService.reset();
      return;
    }

    if (this.accountsCount > 1 && this.positiveAccountsCount === 1) {
      this.message = pathMessages[this.scenarioIndex].multipleWithOnePositive;
      this.scenarioMessagesService.reset();
      return;
    }

    if (this.positiveAccountsCount > 1) {
      const hasEarning = this.scenarioMessagesService.hasInterestEarnings(this.balanceGrouping);
      if (!hasEarning) {
        this.message = pathMessages[this.scenarioIndex].notGettingInterest;
      } else {
        this.message = pathMessages[this.scenarioIndex].congratulations;
      }

      this.scenarioMessagesService.reset();
      return;
    }
  }
}
