import { BaseResponse } from "@bitwarden/common/models/response/base.response";
import {
  AlignmentMappingItem,
  MappingConfigurationItem,
} from "@bitwarden/web-vault/app/importers/data-mapper/mapping-engine-types";
import {
  BicType,
  InstitutionAccount,
  SwiftType,
} from "@bitwarden/web-vault/app/models/types/institution.type";

export class InstitutionResponse extends BaseResponse {
  __v: number;
  id: string;
  name: string;
  basiqId: string;
  split: number; // Link to the account AccountIdReference
  csvMapper: MappingConfigurationItem[]; // Link to the account AccountIdReference
  /** CSV balance alignment map */
  balanceAlignmentMapper: AlignmentMappingItem[];
  swift: SwiftType;
  bic: BicType;
  availableAccounts: InstitutionAccount[];
  constructor(response: any) {
    super(response);
    this.__v = this.getResponseProperty("__v");
    this.id = this.getResponseProperty("_id");
    this.name = this.getResponseProperty("_name");
    this.split = this.getResponseProperty("split");
    this.csvMapper = this.getResponseProperty("csvMapper");
    this.balanceAlignmentMapper = this.getResponseProperty("balanceAlignmentMapper");
    this.swift = this.getResponseProperty("_swift");
    this.bic = this.getResponseProperty("_bic");
    this.basiqId = this.getResponseProperty("_basiqId");
    this.availableAccounts = this.getResponseProperty("_availableAccounts");
  }
}
