<div
  class="spinner-container currency-importing-from-insturment"
  *ngIf="showRetrievingCurrenciesProgressBar"
>
  <div class="currency-update-progress-container">
    <p>
      {{ "retrievingCurrencyData" | i18n }} {{ retrievingCurrenciesProgressValue?.toFixed(1) }}%
      {{ "completed" | i18n }}
    </p>
    <mat-progress-bar
      mode="buffer"
      [value]="retrievingCurrenciesProgressValue"
      class="custom-progress-bar"
    ></mat-progress-bar>
  </div>
</div>

<div
  class="spinner-container currency-importing-from-insturment"
  *ngIf="showSavingCurrenciesProgressBar"
>
  <div class="currency-update-progress-container">
    <p *ngIf="showImportProgress">
      {{ "savingCurrencyData" | i18n }} {{ savingCurrenciesProgressValue.toFixed(1) }}%
      {{ "completed" | i18n }}
    </p>
    <p *ngIf="showTransactionUpdateProgress">{{ "updatingTransactions" | i18n }}</p>
    <mat-progress-bar
      mode="buffer"
      [value]="savingCurrenciesProgressValue"
      class="custom-progress-bar"
    ></mat-progress-bar>
  </div>
</div>

<div class="content">
  <div class="container">
    <div class="page-header d-flex">
      <div class="ml-auto d-flex">
        <button class="btn btn-sm btn-outline-primary ml-3" type="button" (click)="invite()">
          <i aria-hidden="true" class="bwi bwi-plus bwi-fw"></i>
          Add New Reference Data
        </button>
      </div>
    </div>
    <div>
      <mat-tab-group
        fitInkBarToContent
        animationDuration="0ms"
        [(selectedIndex)]="selectedTabIndex"
      >
        <mat-tab *ngIf="!showPreview" [label]="'importReferenceData' | i18n">
          <app-reference-data-import
            (previewEvent)="handlePreview($event)"
          ></app-reference-data-import>
        </mat-tab>
        <mat-tab *ngIf="showPreview" [label]="'previewData' | i18n">
          <div *ngIf="previewData">
            <mat-card>
              <app-reference-data-table [referenceData]="previewData"></app-reference-data-table>
              <mat-card-actions align="end" class="preview-table-actions preview-import">
                <button mat-raised-button color="warn" (click)="onCancelPreview()">
                  {{ "cancel" | i18n }}
                </button>
                <button mat-raised-button color="primary" (click)="importReferenceData()">
                  <span>{{ "importReferenceData" | i18n }}</span>
                </button>
              </mat-card-actions>
            </mat-card>
          </div>
        </mat-tab>
        <mat-tab [label]="'viewReferenceData' | i18n">
          <div *ngIf="referenceData">
            <app-reference-data-table [referenceData]="referenceData"></app-reference-data-table>
          </div>
        </mat-tab>

        <mat-tab [label]="'updateCurrencyData' | i18n" *ngIf="HelperCommon.isDevEnv()">
          <mat-card-actions align="end" class="preview-table-actions preview-import">
            <button mat-raised-button color="primary" (click)="updateCurrencies()">
              <span>{{ "updateCurrencies" | i18n }}</span>
            </button>
          </mat-card-actions>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>
