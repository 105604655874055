import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

// todo: refactor the html so it handles the different types of dialogs, right now only warning

@Component({
  selector: "app-verify-email-remind-dialog",
  templateUrl: "./verify-email-remind-dialog.component.html",
})
export class VerifyEmailRemindDialogComponent {
  protected username: string;
  protected domain: string;
  constructor(
    public dialogRef: MatDialogRef<VerifyEmailRemindDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      email: string;
    }
  ) {
    this.splitEmail();
  }

  splitEmail() {
    const email = this.data?.email.split("@");
    this.username = email[0];
    this.domain = email[1];
  }
}
