export enum TransactionImportType {
  transactioncsv = "Transactions (csv)",
  plaid = "Transactions (plaid)",
  basiq = "Transactions (basiq)",
}
export enum TransactionPropertyPlaceHolders {
  _transactionDate = "_transactionDate",
  date = "date",
  statementDate = "statement date",
}
export enum ImportStepsEnum {
  institution = "institution",
  upload = "upload",
  arrange = "arrange",
  import = "import",
}
