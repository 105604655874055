import {
  PathMessagesType,
  ScenarioMessageActionType,
  ScenarioMessagesType,
} from "@bitwarden/web-vault/app/models/types/general-types";

const joinEarlyAccessAction: ScenarioMessageActionType = {
  pre: "joinPreLink",
  text: "joinLink",
  post: `joinPostLink`,
};

const notifyTeamAction: ScenarioMessageActionType = {
  pre: ``,
  text: "notifyOurTeamLink",
  post: `notifyOurTeamPostLink`,
};

const pathTwoMessages: ScenarioMessagesType = {
  noPositiveBalanceAccounts: {
    info: `noAccountsWithPositiveBalance`,
    action: joinEarlyAccessAction,
  },

  noInterestRate: {
    info: `notEnoughInfoToCalculateInterest`,
    action: notifyTeamAction,
  },
  singleAndPositive: {
    info: `isOnlyOneAccount`,
    action: joinEarlyAccessAction,
  },

  multipleWithOnePositive: {
    info: `isOnlyOnePositiveAccount`,
    action: joinEarlyAccessAction,
  },

  singleAndNonPositive: {
    info: `noPositiveAccounts`,
    action: joinEarlyAccessAction,
  },

  notGettingInterest: {
    info: `noInterestEarning`,
    action: joinEarlyAccessAction,
  },

  congratulations: {
    info: `congratulate`,
    action: null,
  },
};

const pathThreeMessages: ScenarioMessagesType = {
  noPositiveBalanceAccounts: {
    info: `notEnoughInfoToCalculateOverDraftAccounts`,
    action: joinEarlyAccessAction,
  },

  noInterestRate: {
    info: ``,
    action: null,
  },
  singleAndPositive: {
    info: `wellDone`,
    action: null,
  },

  multipleWithOnePositive: {
    info: `wellDone`,
    action: null,
  },
  singleAndNonPositive: {
    info: `notEnoughInfoToCalculateOverDraftAccounts`,
    action: null,
  },

  notGettingInterest: {
    info: `wellDone`,
    action: null,
  },

  congratulations: {
    info: `wellDone`,
    action: null,
  },
};

export const pathMessages: PathMessagesType = {
  0: null,
  1: pathTwoMessages,
  2: pathThreeMessages,
};
