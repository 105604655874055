import { Injectable, Injector } from "@angular/core";
import { Store } from "@ngrx/store";
import _ from "lodash";
import { defer, Observable } from "rxjs";

import { ApiService } from "@bitwarden/common/abstractions/api.service";
import { SubscriptionConfigData } from "@bitwarden/web-vault/app/models/data/subscription-config.data";
import { Subscription } from "@bitwarden/web-vault/app/models/data/subscription.data";
import { DataRepositoryService } from "@bitwarden/web-vault/app/services/DataRepository/data-repository.service";
import { StateManagement } from "@bitwarden/web-vault/app/services/DataService/state-management/state.management";
import { getSubscription } from "@bitwarden/web-vault/app/services/DataService/subscription/state/subscription.reducer";
import { StripeConfigService } from "@bitwarden/web-vault/app/services/api/stripe-config.service";

@Injectable({
  providedIn: "root",
})
export class SubscriptionService extends SubscriptionConfigData {
  private subscription$: Observable<Subscription>;
  private stripeConfigService: StripeConfigService;
  private userSubscription: Subscription | null;

  constructor(
    private dataRepositoryService: DataRepositoryService,
    private apiService: ApiService,
    private injector: Injector,
    private store: Store
  ) {
    super();
    this.stripeConfigService = this.injector.get(StripeConfigService);
  }

  /** NgRx event to load the subscription from Stripe API */
  loadSubscription$(): Observable<Subscription> {
    return defer(() => {
      return this.stripeConfigService.getSubscription();
    });
  }

  /** User Subscription set in the AppComponent */
  setUserSubscription(userSubscription: Subscription | null) {
    this.userSubscription = userSubscription;
  }

  getUserSubscription() {
    return this.userSubscription;
  }

  initSubscription() {
    this.subscription$ = this.store.select(getSubscription);
    const stateManagement = new StateManagement();
    const userSubscription = new Subscription(
      stateManagement.getState(this.subscription$) as Subscription
    );

    if (userSubscription.metadata?.id) {
      this.setUserSubscription(userSubscription);
    }
  }

  isPremiumAccount() {
    return !_.isEmpty(this.userSubscription.subscription);
  }
}
