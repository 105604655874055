import { Injectable, Injector } from "@angular/core";

import { ScenarioGroup } from "@bitwarden/web-vault/app/models/data/scenario-group.data";
import {
  ScenarioOptionsClasses,
  ScenarioOptionsType,
} from "@bitwarden/web-vault/app/models/types/scenario-group.types";
import { ScenarioOption } from "@bitwarden/web-vault/app/services/DataCalculationService/scenarios/ScenarioOptions/scenario-option";
import { WebWorkerQueue } from "@bitwarden/web-vault/app/services/web-worker/WebWorkerQueue";

@Injectable({
  providedIn: "root",
})
export class ScenarioOptionsFactory {
  static createScenarioOption(
    type: ScenarioOptionsType,
    scenarioGroup: ScenarioGroup,
    injector: Injector,
    webWorkerQueue: WebWorkerQueue
  ): ScenarioOption {
    const scenarioOption = new ScenarioOptionsClasses[type](
      scenarioGroup,
      injector,
      webWorkerQueue
    );

    return scenarioOption;
  }
}
