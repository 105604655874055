import { createActionGroup, emptyProps, props } from "@ngrx/store";

import { Transaction } from "../../../../models/data/blobby/transaction.data";

export const RevaluationActions = createActionGroup({
  source: "Revaluation",
  events: {
    Initialize: emptyProps(),
    Create: emptyProps(),
    "Create Success": props<{ revaluations: Transaction[] }>(),
    "Create Failure": props<{ error: unknown }>(),
    Clear: emptyProps(),
  },
});
