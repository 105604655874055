<h1
  mat-dialog-title
  [ngClass]="{ 'tw-text-accent': data.type === 'warning', 'tw-text-primary': data.type === 'info' }"
>
  {{ data.title | i18n }}
</h1>
<div mat-dialog-content>
  <p *ngIf="hasHtml()" [innerHTML]="data.message"></p>
  <p *ngIf="!hasHtml()">{{ data.message | i18n }}</p>
</div>
<div class="tw-flex tw-w-full tw-justify-between tw-gap-6">
  <app-gloss-button
    class="tw-w-full"
    mat-dialog-close
    aria-label="Close dialog"
    [options]="{
      text: 'no' | i18n,
      class: 'neutral',
      isEnabled: true
    }"
  ></app-gloss-button>
  <app-gloss-button
    class="tw-w-full"
    [mat-dialog-close]="true"
    [options]="{
      text: 'yes' | i18n,
      class: 'primary',
      isEnabled: true
    }"
  ></app-gloss-button>
</div>
