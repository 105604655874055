<div *ngIf="!isStripeLoaded" class="mt-5 d-flex justify-content-center">
  <div>
    <img class="logo logo-themed tw-mb-4" alt="Gloss" />
    <p class="tw-text-center">
      <i
        class="bwi bwi-spinner bwi-spin bwi-2x tw-text-muted"
        title="Loading"
        aria-hidden="true"
      ></i>
    </p>
  </div>
</div>
<ng-container *ngIf="isStripeLoaded">
  <div
    class="tw-mx-auto tw-flex tw-max-w-lg tw-flex-col tw-items-center tw-justify-center tw-px-8 tw-pt-8 xs:tw-px-2"
  >
    <div>
      <img class="logo logo-themed" alt="Gloss" />
      <p class="tw-mt-auto tw-text-center tw-text-xl">{{ "createAccount" | i18n }}</p>
    </div>
    <div
      class="tw-min-w-xl tw-border-secondary-300 tw-mx-auto tw-max-w-xl tw-rounded-3xl tw-bg-background tw-p-8"
    >
      <form
        #form
        [appApiAction]="formPromise"
        class="tw-container tw-mx-auto"
        [formGroup]="formGroup"
      >
        <div>
          <div class="tw-mb-3">
            <bit-form-field>
              <bit-label>{{ "emailAddress" | i18n }}</bit-label>
              <input id="register-form_input_email" bitInput type="email" formControlName="email" />
              <bit-hint>{{ "emailAddressDesc" | i18n }}</bit-hint>
            </bit-form-field>
          </div>

          <div class="tw-mb-3">
            <bit-form-field>
              <bit-label>{{ "name" | i18n }}</bit-label>
              <input id="register-form_input_name" bitInput type="text" formControlName="name" />
              <bit-hint>{{ "yourNameDesc" | i18n }}</bit-hint>
            </bit-form-field>
          </div>

          <div class="tw-mb-3">
            <app-callout
              type="info"
              [enforcedPolicyOptions]="enforcedPolicyOptions"
              *ngIf="enforcedPolicyOptions"
            >
            </app-callout>
            <bit-form-field>
              <bit-label>{{ "masterPass" | i18n }}</bit-label>
              <input
                id="register-form_input_master-password"
                bitInput
                type="password"
                formControlName="masterPassword"
              />
              <button
                type="button"
                bitSuffix
                bitIconButton
                bitPasswordInputToggle
                [(toggled)]="showPassword"
              ></button>
              <bit-hint>
                <span class="tw-font-semibold">Important:</span>
                {{ "masterPassImportant" | i18n }}
                <span (click)="openPasswordHelp($event)" class="tw-cursor-pointer tw-text-accent"
                  >Learn more.</span
                >
                <div class="tw-z-1 tw-flex">
                  <app-help-tooltip
                    [showHelpTooltip]="showHelpTooltip"
                    [tooltipInfo]="tooltipInfo"
                    [xPosition]="xPosition"
                    [yPosition]="yPosition"
                    [pointer]="pointer"
                    (onTooltipClose)="onTooltipClose()"
                  ></app-help-tooltip>
                </div>
              </bit-hint>
            </bit-form-field>
            <app-password-strength
              [password]="formGroup.get('masterPassword')?.value"
              [email]="formGroup.get('email')?.value"
              [name]="formGroup.get('name')?.value"
              [showText]="true"
              (passwordStrengthResult)="getStrengthResult($event)"
            >
            </app-password-strength>
          </div>

          <div class="tw-mb-3">
            <bit-form-field>
              <bit-label>{{ "reTypeMasterPass" | i18n }}</bit-label>
              <input
                id="register-form_input_confirm-master-password"
                bitInput
                type="password"
                formControlName="confirmMasterPassword"
              />
              <button
                type="button"
                bitSuffix
                bitIconButton
                bitPasswordInputToggle
                [(toggled)]="showPassword"
              ></button>
            </bit-form-field>
          </div>

          <div class="tw-mb-3">
            <bit-form-field>
              <bit-label>{{ "masterPassHint" | i18n }}</bit-label>
              <input id="register-form_input_hint" bitInput type="text" formControlName="hint" />
              <bit-hint>{{ "masterPassHintDesc" | i18n }}</bit-hint>
            </bit-form-field>
          </div>

          <div [hidden]="!showCaptcha()">
            <iframe id="hcaptcha_iframe" height="80"></iframe>
          </div>

          <ng-container *ngIf="!accountCreated">
            <app-gloss-button
              class="tw-w-1/2"
              [options]="{
                type: 'submit',
                class: 'primary',
                text: 'createAccount' | i18n,
                onClick: submit.bind(this),
                isEnabled: !form.loading
              }"
            >
            </app-gloss-button>
          </ng-container>
          <bit-error-summary *ngIf="showErrorSummary" [formGroup]="formGroup"></bit-error-summary>
        </div>
      </form>
    </div>

    <div class="tw-min-w-xl tw-mx-auto tw-flex tw-items-center tw-justify-center tw-px-8 tw-py-4">
      <div class="tw-px-4 tw-py-2 tw-text-center sm:tw-flex-col">
        {{ "policyConfirmation" | i18n }}
        <a
          href="https://www.gloss-vault.com/privacy-policy"
          target="_blank"
          rel="noopener noreferrer"
          class="tw-underline tw-underline-offset-2"
        >
          {{ "privacyPolicyLink" | i18n }}
        </a>
      </div>
    </div>

    <ng-template #notificationPassword>
      <div class="tw-rounded-lg tw-bg-white">
        <div class="tw-flex tw-justify-end">
          <button mat-icon-button mat-dialog-close aria-label="Close dialog" tabindex="-1">
            <mat-icon>close</mat-icon>
          </button>
        </div>
        <div class="tw-flex tw-flex-col tw-gap-5 tw-px-8 tw-pb-8">
          <div class="tw-flex tw-items-start tw-justify-center tw-gap-2 xs:tw-items-end">
            <svg class="tw-mr-3 tw-h-7 tw-w-7">
              <use xlink:href="images/sprite.svg#info" />
            </svg>
            <span class="tw-text-[19px] tw-font-extrabold xs:tw-text-[15px]">
              {{ "importantNote" | i18n }}
            </span>
          </div>
          <p>
            This password is special, and different from the password on most other apps -
            <span class="tw-font-bold tw-text-accent">we can not reset your account for you.</span
            ><br /><br />
            This is because your data is encrypted with this password, and for your privacy and
            security, we do not store it.<br /><br />
            So keep your password safe and do not lose it, as we are unable to unlock your account
            without it.
          </p>
          <div class="tw-flex tw-gap-2 xs:tw-flex-col">
            <app-gloss-button
              class="tw-w-1/2 xs:tw-w-full"
              [options]="{
                type: 'submit',
                class: 'primary',
                text: 'confirmSignUp' | i18n,
                onClick: submitStart.bind(this),
                isEnabled: true
              }"
            >
            </app-gloss-button>
            <app-gloss-button
              class="tw-w-1/2 xs:tw-w-full"
              [options]="{
                class: 'neutral',
                text: 'setDifferentPassword' | i18n,
                onClick: resetPassword.bind(this),
                isEnabled: true
              }"
            >
            </app-gloss-button>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</ng-container>
