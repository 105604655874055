import { TimeFormatIndex } from "../../../models/types/general-types";

export type TimeAtomRegExType = {
  matchedFormat: string;
  regex: string;
  timePart: keyof TimeFormatIndex;
};

export type TimeTripletType = Array<TimeAtomRegExType>;
export type TimePermutationListType = Array<TimeTripletType>;

export type TimeFormatIndexKeyType = keyof TimeFormatIndex;

export const hoursRegex24: TimeAtomRegExType = {
  matchedFormat: "HH",
  regex: "(?<hours>\\b([01]?[0-9]|2[0-3])\\b)",
  timePart: "hours",
};

export const hoursRegex12: TimeAtomRegExType = {
  matchedFormat: "hh",
  regex: "(?<hours>\\b(0?[1-9]|1[0-2])\\b)",
  timePart: "hours",
};

export const minutesRegex: TimeAtomRegExType = {
  matchedFormat: "mm",
  regex: "(?<minutes>\\b([0-5]?[0-9])\\b)",
  timePart: "minutes",
};

export const secondsRegex: TimeAtomRegExType = {
  matchedFormat: "ss",
  regex: "(?<seconds>[0-5]?\\d)(?![0-9])",
  timePart: "seconds",
};

export const amPmRegex: TimeAtomRegExType = {
  matchedFormat: "A",
  regex: "(?<amPm>([APap][Mm]))",
  timePart: "amPm",
};

export const timeSeparatorRegex = "(?<timeSeparator>(T|\\s{1,}))";

export const Time12HrFormatPatterns: TimePermutationListType = [
  [hoursRegex12, minutesRegex, secondsRegex],
  [hoursRegex24, minutesRegex],
];
export const Time24HrFormatPatterns: TimePermutationListType = [
  [hoursRegex24, minutesRegex, secondsRegex],
  [hoursRegex24, minutesRegex],
];
