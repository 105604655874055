import { DatePipe } from "@angular/common";

import { BaseResponse } from "../../../../../../../libs/common/src/models/response/base.response";
import { EstimateType } from "../../enum/estimateType";
import { TransactionDirection } from "../../enum/transactionDirection";
import { Book } from "../blobby/book.data";
import { InterestMatchData } from "../interest-match.data";
import { RecurringData } from "../recurring.data";
import { Splitcategory } from "../splitcategory";
import { Splitclassification } from "../splitclassification";
import { TransactionTemplateData } from "../transaction-template.data";

export class EstimateActionResponse extends BaseResponse {
  __v: number;
  id: string;
  name: string;
  actionType: string;
  account: Book; // Link to the account AccountIdReference
  estimateType: EstimateType; // transaction, interest, reval
  startDate: string;
  direction: TransactionDirection; //<'in','out'> // the direction of the new transaction
  description: string; // the description that the new transaction will have
  categories: Splitcategory[];
  classifications: Splitclassification[];
  groupId: string;
  recurring: RecurringData; // RecurringIdReference - set to Null if is one off transaction

  transactionTemplate: TransactionTemplateData; // TransactionTemplateIDReference - set if transaction type is transaction
  interestMatch: InterestMatchData; // InterestMatchIdReference - set if estimateType is interest

  constructor(response: any) {
    super(response);
    this.__v = this.getResponseProperty("__v");
    this.id = this.getResponseProperty("_id");
    this.name = this.getResponseProperty("Name");
    this.actionType = this.getResponseProperty("actionType");
    this.account = this.getResponseProperty("Account");
    this.estimateType = this.getResponseProperty("EstimateType");
    this.direction = this.getResponseProperty("Direction");
    this.description = this.getResponseProperty("Description");
    this.recurring = this.getResponseProperty("Recurring");
    this.transactionTemplate = this.getResponseProperty("TransactionTemplate");
    this.interestMatch = this.getResponseProperty("InterestMatch");
    this.categories = this.getResponseProperty("Categories");
    this.classifications = this.getResponseProperty("Classifications");
    this.groupId = this.getResponseProperty("groupId");

    const startDate = this.getResponseProperty("StartDate");
    this.startDate = new DatePipe("en-us").transform(startDate, "yyyy-MM-dd");
  }
}
