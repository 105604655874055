import { format, isAfter, isEqual, sub } from "date-fns";

import { BalanceAlignment } from "@bitwarden/web-vault/app/services/DataCalculationService/transaction/balance-alignment";

import { Transaction } from "../../../models/data/blobby/transaction.data";

export const getAlignmentTransactionDate = (transaction: Transaction): Date => {
  return BalanceAlignment.isBalanceAlignmentTransactionClose(transaction)
    ? new Date(transaction.transactionDate.date)
    : sub(new Date(transaction.transactionDate.date), { days: 1 });
};

export const getFirstTransactionDate = (transactions: Array<Transaction>) => {
  const transaction = getFirstTransaction(transactions);
  return transaction?.transactionDate.date ?? "";
};

export const getLastTransactionDate = (transactions: Array<Transaction>) => {
  const transaction = getLastTransaction(transactions);
  return transaction?.transactionDate.date ?? "";
};

const getFirstTransaction = (transactions: Array<Transaction>): Transaction => {
  if (!transactions || transactions.length === 0) {
    return null;
  }
  return transactions[0];
};

const getLastTransaction = (transactions: Array<Transaction>): Transaction => {
  if (!transactions || transactions.length === 0) {
    return null;
  }
  return transactions[transactions.length - 1];
};

export const getStartingFilterDate = (transactions: Array<Transaction>, dateFormat: string) => {
  if (!transactions || transactions.length === 0) {
    return format(new Date(new Date().getFullYear(), new Date().getMonth() - 12, 1), dateFormat);
  }

  transactions.sort(
    (a, b) =>
      new Date(a.transactionDate.date).getTime() - new Date(b.transactionDate.date).getTime()
  );

  const earliestTransactionDate = new Date(transactions[0].transactionDate.date);
  const oneYearAgo = sub(new Date(), { months: 12 });

  // Check if the earliest transaction date is within one year from today
  if (
    isAfter(earliestTransactionDate, oneYearAgo) ||
    isEqual(earliestTransactionDate, oneYearAgo)
  ) {
    return format(earliestTransactionDate, dateFormat);
  } else {
    return format(new Date(new Date().getFullYear(), new Date().getMonth() - 12, 1), dateFormat);
  }
};
