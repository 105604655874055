<ng-container *ngIf="helpOpen">
  <div
    *ngIf="!formSendSuccess"
    class="customer-support modal-container tw-flex tw-h-full tw-w-full tw-items-center tw-justify-center tw-overflow-auto"
  >
    <div class="tw-absolute tw-z-[3] tw-h-full tw-w-full tw-bg-neutral-500 tw-opacity-40"></div>
    <div class="tw-z-[4] tw-flex tw-flex-col xs:tw-h-full xs:tw-w-full xs:tw-bg-white">
      <div class="m-header tw-mb-[30px] tw-px-5">
        <div class="tw-flex tw-items-center tw-text-2xl xs:tw-text-lg">
          <svg class="tw-mr-3 tw-h-7 tw-w-7">
            <use xlink:href="images/sprite.svg#help2" />
          </svg>
          {{ supportBoxHeader }}
        </div>
        <button
          (click)="closeHelpBox()"
          mat-icon-button
          mat-dialog-close
          aria-label="Close dialog"
          tabindex="-1"
        >
          <mat-icon>close</mat-icon>
        </button>
      </div>
      <div class="m-body tw-flex tw-flex-col tw-p-5">
        <div
          *ngIf="requestProcessing"
          class="tw-flex tw-h-[390px] tw-items-center tw-justify-center"
        >
          <div class="lds-default">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
        <div *ngIf="!requestProcessing">
          <form [formGroup]="supportEnquiry" (ngSubmit)="sendMessage()">
            <div class="tw-mb-2">
              <label for="firstName"> First Name </label>
              <input
                class="form-input"
                id="firstName"
                type="text"
                name="firstName"
                placeholder="First Name"
                formControlName="firstName"
              />
              <div
                *ngIf="
                  supportEnquiry.controls.firstName.errors &&
                  supportEnquiry.controls.firstName.touched
                "
                class="tw-text-danger"
              >
                First Name is required
              </div>
            </div>
            <div class="tw-mb-2">
              <label for="lastName"> Last Name </label>
              <input
                class="form-input"
                id="lastName"
                type="text"
                name="lastName"
                placeholder="Last Name"
                formControlName="lastName"
              />
              <div
                *ngIf="supportEnquiry.controls.lastName.errors?.['required'] && supportEnquiry.controls.lastName.touched"
                class="tw-text-danger"
              >
                Last Name is required
              </div>
            </div>
            <div class="tw-mb-2">
              <label for="email"> Email Address </label>
              <input
                class="form-input"
                id="email"
                type="text"
                name="email"
                placeholder="email"
                formControlName="email"
              />
              <div
                *ngIf="
                  supportEnquiry.controls.email.errors && supportEnquiry.controls.email.touched
                "
                class="tw-text-danger"
              >
                Email Address is required
              </div>
            </div>
            <div class="tw-mb-2">
              <label for="message"> Message </label>
              <textarea
                class="form-input tw-resize-none"
                id="message"
                type="text"
                placeholder="Enter your message..."
                formControlName="message"
                [rows]="rows"
              ></textarea>
              <div
                *ngIf="
                  supportEnquiry.controls.message.errors && supportEnquiry.controls.message.touched
                "
                class="tw-text-danger"
              >
                What's on your mind ?
              </div>
            </div>
            <div>
              <app-gloss-button
                [options]="{
                  class: 'accent',
                  text: 'submit' | i18n,
                  onClick: submit.bind(this),
                  isEnabled: true
                }"
              >
              </app-gloss-button>
              <div *ngIf="submitClicked && !supportEnquiry.valid" class="tw-pl-3 tw-text-danger">
                Don't forget to fill out all the fields
              </div>
            </div>
          </form>
          <div *ngIf="webError" class="tw-text-danger">
            {{ webError }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--  <div *ngIf="formSendSuccess">-->
  <!--    <mat-icon-->
  <!--      (click)="messageSendSuccessClose()"-->
  <!--      class="mat-icon-secondary tw-absolute tw-top-[10px] tw-right-[10px] tw-cursor-pointer tw-border-0 tw-bg-transparent"-->
  <!--      >close</mat-icon-->
  <!--    >-->
  <!--    <p class="tw-pt-3">Thank you for contact us, we will reach to you as soon as possible</p>-->
  <!--  </div>-->
</ng-container>
