import * as d3 from "d3";

import { DateTimeFormatter } from "@bitwarden/web-vault/app/components/primary-summary-graph/graph-elements/date-time-formatter";
import { GraphDataSet } from "@bitwarden/web-vault/app/models/types/graph.types";

export class DataPoints {
  private graphContentGroup: any;
  private dateTimeFormatter: DateTimeFormatter;
  private xScale: d3.ScaleBand<string>;
  private yScale: d3.ScaleLinear<number, number, never>;

  constructor(
    graphContentGroup: any,
    dateTimeFormatter: DateTimeFormatter,
    xScale: d3.ScaleBand<string>,
    yScale: d3.ScaleLinear<number, number, never>
  ) {
    this.graphContentGroup = graphContentGroup;
    this.dateTimeFormatter = dateTimeFormatter;
    this.xScale = xScale;
    this.yScale = yScale;
  }

  generatePointData(data: GraphDataSet[]): number[][] {
    const dataPoints: number[][] = [];

    for (const d of data) {
      const xValue =
        this.xScale.bandwidth() / 2 +
        this.xScale(
          this.dateTimeFormatter.formatYearDate(this.dateTimeFormatter.timeParse(d.date))
        );
      let yValue = null;
      if (d?.midDate && d.midDate) {
        continue;
      }
      if (d.balance !== null) {
        yValue = this.yScale(d.balance);
        dataPoints.push([xValue, yValue]);
      }
    }
    return dataPoints;
  }

  updatePoints(
    name: string,
    colour: string,
    pointData: number[][],
    opacity: number,
    fill = "white"
  ) {
    const points = this.graphContentGroup.selectAll(`#${name}`).data(pointData);

    points
      .join("circle")
      .attr("id", name)
      .attr("cx", (d: number[]) => d[0])
      .attr("cy", (d: number[]) => d[1])
      .attr("r", 3)
      .attr("stroke", colour)
      .attr("stroke-opacity", opacity)
      .style("stroke-width", "0.5px")
      .attr("fill", fill);
  }
}
