import { BaseResponse } from "@bitwarden/common/models/response/base.response";
import { Book } from "@bitwarden/web-vault/app/models/data/blobby/book.data";
import { Transaction } from "@bitwarden/web-vault/app/models/data/blobby/transaction.data";

export class TransactionFilterResponse extends BaseResponse {
  id: string;
  symbol: string;
  accounts: Book[];
  transactions: Transaction[];

  constructor(response: any) {
    super(response);
    this.id = this.getResponseProperty("id");
    this.symbol = this.getResponseProperty("symbol");
    this.accounts = this.getResponseProperty("accounts");
    this.transactions = this.getResponseProperty("transactions");
  }
}
