<app-spinner [showSpinner]="loading"></app-spinner>
<div class="modal-container">
  <modal-header [title]="data.accountView.name + ' : ' + ('setBalance' | i18n)"></modal-header>

  <div class="m-body tw-p-10 xs:tw-px-5">
    <form [formGroup]="form" name="accountForm">
      <div class="tw-flex tw-flex-col">
        <app-currencies (selectedCurrency)="currencySelected($event)"></app-currencies>
        <br />

        <app-gloss-input [options]="balanceInputOptions"></app-gloss-input>
        <br />
        <div class="tw-h-[120px]">
          <app-date-picker
            inputId="date"
            controlName="date"
            [dateValue]="form.controls.date.value"
            [maxDate]="maxDate"
            labelKey="date"
            (changeDateEvent)="onEndDateChangeEvent($event)"
          >
          </app-date-picker>
          <mat-error *ngIf="form.controls.date.hasError('required')"> Field is required </mat-error>
        </div>
      </div>
      <div class="tw-flex tw-justify-center tw-gap-8 md:tw-flex-col">
        <app-gloss-button [options]="saveButtonOptions" class="tw-w-full"></app-gloss-button>
      </div>
    </form>
  </div>
</div>
