import { Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { MatDatepicker } from "@angular/material/datepicker";

@Component({
  selector: "app-date-picker",
  templateUrl: "./date-picker.component.html",
})
export class DatePickerComponent {
  @Input() inputId: string;
  @Input() controlName: string;
  @Input() dateValue: string;
  @Input() labelKey: string;
  @Input() picker: string;
  @Input() isDisabled: boolean;
  @Input() maxDate: Date | null = null;

  @Output() changeDateEvent: EventEmitter<any> = new EventEmitter();

  @ViewChild("picker") datePicker: MatDatepicker<any>;

  openDatePicker() {
    this.datePicker.open();
  }

  onDateChange() {
    this.changeDateEvent.emit(this.dateValue);
  }
}
