import { EstimateActionsEnum } from "@bitwarden/web-vault/app/models/enum/estimate-actions.enum";
import { ScenarioOptionsType } from "@bitwarden/web-vault/app/models/types/scenario-group.types";

import { BaseResponse } from "../../../../../../../libs/common/src/models/response/base.response";
import { ScenarioSource } from "../../enum/scenario-and-groups.enum";
import { EstimateActionData } from "../blobby/estimate.action.data";

export class ScenarioResponse extends BaseResponse {
  __v: number;
  id: string;
  name: string;
  symbol: string;
  scenarioType: ScenarioOptionsType;
  source: string;
  estimateActions: EstimateActionData[];
  estimateActionTypes: EstimateActionsEnum[];

  constructor(response: any) {
    super(response);
    this.__v = this.getResponseProperty("__v");
    this.id = this.getResponseProperty("id") || this.getResponseProperty("_id");
    this.name = this.getResponseProperty("name");
    this.estimateActions = this.getResponseProperty("estimateActions");
    this.scenarioType = this.getResponseProperty("scenarioType");
    this.estimateActionTypes = this.getResponseProperty("estimateActionTypes");
    this.source = this.getResponseProperty("source")
      ? this.getResponseProperty("source")
      : ScenarioSource.system;
  }
}
