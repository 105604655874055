import { SelectionModel } from "@angular/cdk/collections";
import { CdkVirtualScrollViewport } from "@angular/cdk/scrolling";
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormControl } from "@angular/forms";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatSelectChange } from "@angular/material/select";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Subject, takeUntil } from "rxjs";

import { alignmentDefaultMapping } from "@bitwarden/web-vault/app/importers/data-mapper/mappers/default-mapping";
import { AlignmentMappingItem } from "@bitwarden/web-vault/app/importers/data-mapper/mapping-engine-types";
import { ImportStore } from "@bitwarden/web-vault/app/importers/store/import.store";
import { InstitutionStore } from "@bitwarden/web-vault/app/importers/store/institution.import.store";
import { GlossDate } from "@bitwarden/web-vault/app/models/data/shared/gloss-date";
import { TransactionBalanceAlignmentMap } from "@bitwarden/web-vault/app/models/types/import.types";
import { DateFormatPipe } from "@bitwarden/web-vault/app/pipes/date-format.pipe";

import { HelperListTable } from "../../../app/shared/utils/helper-list-table";
import { Transaction } from "../../models/data/blobby/transaction.data";

import "./list-table.scss";
@Component({
  selector: "app-list-table",
  templateUrl: "./list-table.component.html",
  styles: ["list-table.scss"],
})
export class ListTableComponent implements AfterViewInit, OnChanges, OnInit {
  @Input() onTransactionDefinitionChange: (definition: AlignmentMappingItem, row: any) => void;
  @Output() onTransactionDefinitionChangeEvent = new EventEmitter<{
    definition: AlignmentMappingItem;
    row: any;
    rowIndex: number;
  }>();
  @Input() transactions: Array<Transaction>;
  @Input() cardContentRef: HTMLElement; // Receive cardContentRef from the parent
  @Input() helperListTable: HelperListTable;
  @ViewChild("table", { read: ElementRef }) dynamicTableRef: ElementRef;
  @ViewChild(CdkVirtualScrollViewport) virtualScroll: CdkVirtualScrollViewport;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  protected readonly DateFormatPipe = DateFormatPipe;
  protected readonly GlossDate = GlossDate;
  private destroy$: Subject<boolean> = new Subject();
  private viewChecked = false;
  private vmImport$ = this.importStore.vm$;
  private balanceAlignmentObject$ = this.importStore.balanceAlignmentObject$;
  private vmInstitution$ = this.institutionStore.vmInstitution$;

  selectControls: FormControl[] = [];
  dataSource: MatTableDataSource<Transaction>;
  columns: Array<any>;
  displayedColumns: Array<any>;
  initialSelection: any[];
  allowMultiSelect = true;
  itemSize = 30;
  alignmentOptions = alignmentDefaultMapping;
  balanceAlignmentObject: TransactionBalanceAlignmentMap[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private renderer: Renderer2,
    private importStore: ImportStore,
    private institutionStore: InstitutionStore
  ) {}

  ngOnInit() {
    this.balanceAlignmentObject$
      .pipe(takeUntil(this.destroy$))
      .subscribe((balanceAlignmentObject) => {
        this.balanceAlignmentObject = balanceAlignmentObject;
        this.transactions.forEach((transaction: any, index) => {
          const transactionStatusObj = this.balanceAlignmentObject.find(
            (mapping) => mapping.transactionId === transaction._id
          );
          const control = new FormControl(transactionStatusObj.transactionStatus);
          this.selectControls.push(control);
          transaction.definition = transactionStatusObj.transactionStatus.keyOnTransaction;
        });
      });

    this.vmInstitution$.pipe(takeUntil(this.destroy$)).subscribe((institutionState) => {
      if (institutionState.selectedInstitution?.balanceAlignmentMapper) {
        this.alignmentOptions = institutionState.selectedInstitution.balanceAlignmentMapper;
      }
    });
  }

  ngAfterViewInit() {
    this.dataSource = new MatTableDataSource<Transaction>(this.transactions);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.initComponent();
    this.viewChecked = true;
    this.calculateVirtualScrollHeight();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.transactions) {
      this.dataSource = new MatTableDataSource<Transaction>(this.transactions);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;

      this.initComponent();
    }
  }

  @HostListener("window:resize", ["$event"])
  onWindowResize(event: Event) {
    // Re-calculate the virtual scroll height when the window is resized
    this.calculateVirtualScrollHeight();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
  }

  private initComponent() {
    if (!this.transactions) {
      return;
    }

    /** Get list of columns by gathering unique keys of objects found in DATA */
    const columns = this.helperListTable.generateHeader(this.transactions[0]);

    /** Describe the columns for <mat-table> */
    this.columns = columns.map((column: string | number) => {
      return {
        columnDef: column,
        header: column,
        cell: (element: any) => `${element[column]}`,
      };
    });

    this.displayedColumns = this.columns.map((c) => c.columnDef);

    this.helperListTable.dataSource = new MatTableDataSource<Transaction>(this.transactions);
    this.helperListTable.dataSource.paginator = this.paginator;
    this.dataSource = this.helperListTable.dataSource;
    this.initialSelection = this.dataSource.data.filter((row: any) => row["status"] === false);
    this.helperListTable.selection = new SelectionModel(
      this.allowMultiSelect,
      this.initialSelection
    );
  }

  onPageChange(event: PageEvent): void {
    this.dataSource.paginator.pageIndex = event.pageIndex;
    this.dataSource.paginator.pageSize = event.pageSize;
  }

  calculateVirtualScrollHeight() {
    if (!this.viewChecked) {
      return;
    }

    // Get the height of mat-card-content
    const cardContentHeight = this.cardContentRef.offsetHeight;

    // Get the height of mat-table
    const tableHeight = this.dynamicTableRef.nativeElement?.clientHeight;

    // Set the height of the cdk-virtual-scroll-viewport
    let virtualScrollHeight = Math.min(cardContentHeight, tableHeight + 10); // tableHeight + 10 to avoid the scroll bar for smaller data
    virtualScrollHeight = Math.max(virtualScrollHeight, this.itemSize * 4); // Set a minimum height of 4 times the itemSize

    // If the table height is smaller than the mat-card-content height, adjust the mat-card-content height
    if (tableHeight < cardContentHeight) {
      this.renderer.setStyle(this.cardContentRef, "height", `${tableHeight}px`);
      this.renderer.removeClass(this.cardContentRef, "fill-available-height");
    } else {
      // Otherwise, reset the mat-card-content height to its original value
      this.renderer.removeStyle(this.cardContentRef, "height");
      this.renderer.addClass(this.cardContentRef, "fill-available-height");
    }

    // Convert height to the number of virtual scroll items (rounding up to the nearest whole number)
    const numItems = Math.ceil(virtualScrollHeight / this.itemSize);
    this.virtualScroll.setTotalContentSize(numItems * this.itemSize);

    // Set the height of the cdk-virtual-scroll-viewport
    this.renderer.setStyle(
      this.virtualScroll.elementRef.nativeElement,
      "height",
      `${virtualScrollHeight}px`
    );

    // Recalculate the visible range
    this.virtualScroll.checkViewportSize();
  }

  onSelectionChange(event: MatSelectChange, column: any, row: any, rowIndex: number): void {
    const selectedValue: AlignmentMappingItem = event.value;
    this.onTransactionDefinitionChangeEvent.emit({ definition: selectedValue, row, rowIndex });
  }
}
