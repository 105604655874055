export class PreviewTransactionMapper {
  static header = [
    "select",
    "accountId",
    "categories",
    "description",
    "price",
    "convrate",
    "symbol",
    "quantity",
    "balance",
    "amount",
    "currency",
    "_transactionDate",
    "status",
    "definition",
    "actions",
  ];
}
