// async-cell-renderer.component.ts
import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";

import { TransactionDirection } from "@bitwarden/web-vault/app/models/enum/transactionDirection";

import { PreferenceService } from "../../../services/DataService/preference/preference.service";

@Component({
  selector: "app-normalized-out-cell-renderer",
  templateUrl: "./normalized-out-amount-cell-renderer.component.html",
})
export class NormalizedOutAmountCellRenderer implements ICellRendererAngularComp {
  amount: number;
  isOut = false;
  baseCurrency: string;
  constructor(private preferenceService: PreferenceService) {}

  agInit(params: any): void {
    if (params.data?.direction) {
      this.preferenceService.get("baseCurrency").then((baseCurrency) => {
        if (params.data.direction === TransactionDirection.Out) {
          this.baseCurrency = baseCurrency as string;
          this.isOut = true;
          this.amount = params.data.valuation.normalizedValue.amount * -1;
        }
      });
    } else {
      this.isOut = false;
    }
  }

  refresh(params: any): boolean {
    return false; // No need to refresh since data fetch is already handled
  }
}
