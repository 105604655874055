import { Transaction } from "@bitwarden/web-vault/app/models/data/blobby/transaction.data";
import {
  ChildNodes,
  GroupingNodeType,
} from "@bitwarden/web-vault/app/models/types/balanceGroupingTypes";
import { GroupingNode } from "@bitwarden/web-vault/app/services/DataCalculationService/balanceGrouping/groupingNode";
import { GroupingNodeFactory } from "@bitwarden/web-vault/app/services/DataCalculationService/balanceGrouping/groupingNodeFactory";

export class AccountGroupingNode extends GroupingNode {
  nodeType: GroupingNodeType = "account";
  defaultChildren: Array<GroupingNodeType> = ["allocation"];
  usedGroupings: Array<GroupingNodeType> = [this.nodeType];

  transactionMatchesGroup(transaction: Transaction): boolean {
    return transaction.accountId === this.groupingKey;
  }

  /**
   * Returns a list of ChildNodeGroupings of this GroupingNodeType transaction would create
   * @param transaction
   */
  async processChildren(transaction: Transaction): Promise<ChildNodes> {
    const accountNodes: ChildNodes = {};
    const transactionAccountNode = GroupingNodeFactory.createNode(
      "account",
      this.usedGroupings,
      true,
      this.limitChildren
    );
    const accountID = transaction.accountId;
    transactionAccountNode.groupingKey = accountID;
    await transactionAccountNode.addTransaction(transaction);
    accountNodes[accountID] = transactionAccountNode;

    return accountNodes;
  }
}
