<ng-container [ngClass]="inputClass">
  <div class="form-group mb-4">
    <label class="input-label">{{ labelKey | i18n }}</label>
    <input
      autocomplete="off"
      class="form-control"
      [type]="inputType"
      [name]="inputName"
      [(ngModel)]="inputValue"
      (ngModelChange)="onInputChange()"
      [disabled]="isDisabled"
    />
  </div>
</ng-container>
