import { CdkStepperModule } from "@angular/cdk/stepper";
import { TitleCasePipe } from "@angular/common";
import { NgModule } from "@angular/core";

import { FormFieldModule } from "@bitwarden/components";

import { LooseComponentsModule, SharedModule } from "../../shared";
import { RegisterFormModule } from "../register-form/register-form.module";

import { TrialInitiationComponent } from "./trial-initiation.component";

@NgModule({
  imports: [
    SharedModule,
    CdkStepperModule,
    FormFieldModule,
    RegisterFormModule,
    LooseComponentsModule,
  ],
  declarations: [TrialInitiationComponent],
  exports: [TrialInitiationComponent],
  providers: [TitleCasePipe],
})
export class TrialInitiationModule {}
