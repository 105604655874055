import { Pipe, PipeTransform } from "@angular/core";

import { InstitutionInterest } from "@bitwarden/web-vault/app/models/types/institution.type";
import { InstitutionService } from "@bitwarden/web-vault/app/services/DataService/institution/institution.service";

import { Institution } from "../models/data/blobby/institution.data";
@Pipe({
  name: "institutionPipe",
})
export class InstitutionsPipe implements PipeTransform {
  constructor(private institutionService: InstitutionService) {}

  async transform(
    value: any,
    method: string,
    index?: number,
    interestRates?: InstitutionInterest[],
    args?: any[]
  ) {
    if (method === "getInterestRateValues") {
      return this.getInterestRateValues(value);
    }

    if (method === "getInterestRateValue") {
      return this.getInterestRateValue(interestRates, index);
    }
    if (method === "getInstitutionNameWithCountry") {
      return this.getInstitutionNameWithCountry(value);
    }

    return value;
  }

  getInterestRateValues(interestRates: InstitutionInterest[]): string {
    let ratesString = "";
    const lastIndex = interestRates.length - 1;
    for (const [index, interestRate] of interestRates.entries()) {
      const previousRange = index === 0 ? 0 : interestRates[index - 1].range;
      if (index !== lastIndex) {
        ratesString += `$${previousRange} < $${interestRate.range} : ${interestRate.rate},`;
      } else {
        if (interestRate.range === Number(-1)) {
          interestRate.range = Number(100000000);
        }
        ratesString += `$${previousRange} < $${interestRate.range} : ${interestRate.rate}`;
      }
    }
    return ratesString;
  }

  getInterestRateValue(interestRates: InstitutionInterest[], index: number): string {
    const InstitutionInterest = interestRates[index];
    const previousRange = index === 0 ? 0 : interestRates[index - 1].range;
    const currentRange = InstitutionInterest.range !== -1 ? InstitutionInterest.range : "...";

    return `$${previousRange} - $${currentRange} : ${InstitutionInterest.rate}`;
  }

  async getInstitutionNameWithCountry(institution: Institution): Promise<string> {
    const countries = await this.institutionService.getCountryMasterList();
    const country = countries.find((country) => country.code === institution.swift.countryCode);
    return `${country.name} - ${institution.name}`;
  }
}
