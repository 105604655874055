// Format needs to be date-fns compatible https://date-fns.org/v2.29.3/docs/format
export const dateFormats: Array<string> = [
  "yyyy-MM-dd",
  "dd/MM/yyyy",
  "MM/dd/yy",
  "dd/MM/yy",
  "yy/MM/dd",
  "M/d/yy",
  "d/M/yy",
  "yy/M/d",
];
