import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

import { BaseProgressBar } from "../../components/progress-bar/base-progress-bar";
import { IMainProgressBar } from "../../models/interfaces/main-progress-bar";

@Injectable({
  providedIn: "root",
})
export class MainProgressBar extends BaseProgressBar implements IMainProgressBar {
  static PROGRESS_LABEL_LOADING = "Loading...";
  private loadingPercentage$ = new BehaviorSubject<number>(0);
  private isLoadingCompleted$ = new BehaviorSubject<boolean>(false);
  private progressLabel$ = new BehaviorSubject<string>(MainProgressBar.PROGRESS_LABEL_LOADING);

  setLoadingPercentage(percentage: number) {
    this.loadingPercentage$.next(percentage);
  }

  getLoadingPercentage() {
    return this.loadingPercentage$;
  }

  setIsLoadingCompleted(isLoadingCompleted: boolean) {
    setTimeout(() => this.isLoadingCompleted$.next(isLoadingCompleted), 0);
  }

  getIsLoadingCompleted() {
    try {
      return this.isLoadingCompleted$;
    } catch (error) {
      this.setIsLoadingCompleted(true);
    }
  }

  setProgressLabel(label: string) {
    this.progressLabel$.next(label);
  }

  getProgressLabel() {
    return this.progressLabel$;
  }

  reset() {
    this.setIsLoadingCompleted(false);
    this.setProgressLabel(MainProgressBar.PROGRESS_LABEL_LOADING);
    this.setLoadingPercentage(0);
  }
}
