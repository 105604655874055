import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { parse, format, isValid } from "date-fns";
import { Subscription } from "rxjs";

import { LogService } from "@bitwarden/common/abstractions/log.service";
import { DashboardParameters } from "@bitwarden/web-vault/app/components/dashboard-selector/dashboard-selector.component";
import { ExpandableCardService } from "@bitwarden/web-vault/app/components/primary-summary-graph/hud/expandableCardService";
import { GLOBAL_BASE_CURRENCY } from "@bitwarden/web-vault/app/models/constants/global.constants";
import { GraphDataSet } from "@bitwarden/web-vault/app/models/types/graph.types";
import {
  AnchorPointData,
  GroupScenarioBalance,
  ScenarioData,
} from "@bitwarden/web-vault/app/models/types/scenario-group.types";
import { BookService } from "@bitwarden/web-vault/app/services/DataService/book/book.service";
import { PreferenceService } from "@bitwarden/web-vault/app/services/DataService/preference/preference.service";
import { DashboardService } from "@bitwarden/web-vault/app/services/dashboard/dashboard-service";

type HudDataType = "balance" | "scenario1" | "scenario2" | "scenario3";
interface BalanceDetails {
  amount: number;
  date: string;
}
type band = number;

interface HudDetailsType {
  balance: HudAccountDetails;
  scenario1: HudAccountDetails;
  scenario2: HudAccountDetails;
  scenario3: HudAccountDetails;
}

interface HudTotalsType {
  balance: HudTotalDetails;
  scenario1: HudTotalDetails;
  scenario2: HudTotalDetails;
  scenario3: HudTotalDetails;
}

type HudAccountDetails = Map<band, AccountCollection>;
type HudTotalDetails = Map<band, BalanceDetails>;

type AccountCollection = Array<HudAccountBalanceDataInfo>;

interface HudAccountBalanceDataInfo {
  accountName: string;
  accountBalance: number;
  accountNormalisedCurrency: string;
}

// adjust this number to display number of accounts before moving into "Other"
const NUMBER_OF_ACCOUNTS_TO_DISPLAY = 2;
// todo centralise this into 1 def only
const DASHBOARD_DATE_FORMAT = "yyyyMMdd";
@Component({
  selector: "app-primary-summary-graph-hud",
  templateUrl: "./hud.component.html",
})

/**
 * Represents the HUD component, managing the display of account balances and scenario data.
 * @class
 * @param {BookService} bookService - Service for accessing book/account information.
 * @param {LogService} log - Service for logging errors and information.
 * @param {DashboardService} dashboardService - Service for accessing dashboard data.
 * @param {PreferenceService} preferenceService - Service for accessing user preferences.
 * @param {ExpandableCardService} cardService - Service for managing the expandable card UI elements.
 */
export class HudComponent implements OnChanges, OnInit {
  @Input() dashboardParameters: DashboardParameters;
  @Input() graphData: Array<GraphDataSet>;
  @Input() scenarioData: ScenarioData;
  @Input() selectedBand: number;
  protected panelExpanded: boolean[] = [false];
  private readonly _hudDetails: HudDetailsType;
  private readonly _hudTotals: HudTotalsType;
  private _accountNameMap;
  private bookService: BookService;
  private log: LogService;
  private dashboardService: DashboardService;
  private cardService: ExpandableCardService;
  private preferenceService: PreferenceService;
  private _dateFormat: string;
  private _userCurrency: string;

  private gridTabTypeSubscription: Subscription;

  constructor(
    bookService: BookService,
    log: LogService,
    dashboardService: DashboardService,
    preferenceService: PreferenceService,
    cardService: ExpandableCardService
  ) {
    this.bookService = bookService;
    this.log = log;
    this.dashboardService = dashboardService;
    this.cardService = cardService;
    this.preferenceService = preferenceService;

    this._hudDetails = {
      balance: new Map(),
      scenario1: new Map(),
      scenario2: new Map(),
      scenario3: new Map(),
    };

    this._hudTotals = {
      balance: new Map(),
      scenario1: new Map(),
      scenario2: new Map(),
      scenario3: new Map(),
    };
    this._accountNameMap = new Map<string, string>();
    this.panelExpanded = [false];
  }

  get userCurrency(): string {
    return this._userCurrency;
  }

  get hudAccountsBalanceData(): AccountCollection {
    return this._hudDetails.balance.get(this.selectedBand);
  }

  get hudScenario1BalanceData(): AccountCollection {
    return this._hudDetails.scenario1.get(this.selectedBand);
  }

  get hudScenario2BalanceData(): AccountCollection {
    return this._hudDetails.scenario2.get(this.selectedBand);
  }

  get hudScenario3BalanceData(): AccountCollection {
    return this._hudDetails.scenario3.get(this.selectedBand);
  }

  get hubTotalsDate() {
    if (this.showBalanceDetails()) {
      const balance = this._hudTotals.balance.get(this.selectedBand);
      return balance ? this.formatDate(balance.date) : "";
    } else if (this.showScenarioDetails()) {
      const balance = this._hudTotals.scenario1.get(this.selectedBand);
      return balance ? this.formatDate(balance.date) : "";
    }
  }

  get hudTotalsBalance() {
    const balance = this._hudTotals.balance.get(this.selectedBand);
    return balance ? this.balanceFormat(balance.amount) : "";
  }

  get hudTotalsScenario1(): number {
    const balance = this._hudTotals.scenario1.get(this.selectedBand);
    return balance ? this.balanceFormat(balance.amount) : 0;
  }

  get hudTotalsScenario2() {
    const balance = this._hudTotals.scenario2.get(this.selectedBand);
    return balance ? this.balanceFormat(balance.amount) : 0;
  }

  get hudTotalsScenario3() {
    const balance = this._hudTotals.scenario3.get(this.selectedBand);
    return balance ? this.balanceFormat(balance.amount) : 0;
  }

  /**
   * Fetches and sets the user's preferred currency on component initialization.
   * @async
   * @method
   */
  async ngOnInit() {
    const currency = await this.preferenceService.get("baseCurrency");
    const dateFormat = await this.preferenceService.get("dateFormat");
    if (typeof currency === "string") {
      this._userCurrency = currency;
    } else {
      this.log.error("User currency not found in preference service");
      this._userCurrency = GLOBAL_BASE_CURRENCY;
      // this.normalizedCurrencySymbol = dataset[0].accountGrouping[Object.keys(dataset[0].accountGrouping)[0]].balance.runningTotalNormalizedValue.symbol;
    }

    if (typeof dateFormat === "string") {
      this._dateFormat = dateFormat;
    } else {
      this.log.error("User date format not found in preference service");
      this._dateFormat = "dd/MM/yyyy";
    }
  }

  ngOnDestroy() {
    if (this.gridTabTypeSubscription) {
      this.gridTabTypeSubscription.unsubscribe();
    }
  }

  showBalanceDetails() {
    if (this.dashboardParameters) {
      return this.dashboardParameters.type === "transactionOnly";
    }
  }

  showScenarioDetails() {
    if (this.dashboardParameters) {
      return (
        this.dashboardParameters.type === "scenarioOnly" ||
        this.dashboardParameters.type === "transactionAndScenario"
      );
    }
  }

  /**
   * Handles changes to input properties, updating the HUD data accordingly.
   * @param {SimpleChanges} changes - Object containing changes to input properties.
   * @async
   * @method
   */
  async ngOnChanges(changes: SimpleChanges) {
    if (this.dashboardParameters) {
      /* Dataset for the graph is not consistent at the moment, the structure will be different depending on the type of dashboard*/
      if (this.dashboardParameters.type === "transactionOnly") {
        if (changes?.graphData?.currentValue) {
          const graphData = changes.graphData.currentValue as Array<GraphDataSet>;
          await this.setBalanceDetails(graphData);
        }
      } else if (this.dashboardParameters.type === "scenarioOnly") {
        if (changes?.scenarioData?.currentValue) {
          const scenarioData = changes.scenarioData.currentValue as ScenarioData;
          await this.setScenarioDetails(scenarioData);
        }
      } else if (this.dashboardParameters.type === "transactionAndScenario") {
        if (changes?.scenarioData?.currentValue) {
          const scenarioData = changes.scenarioData.currentValue as ScenarioData;
          await this.setScenarioDetails(scenarioData);

          const graphData = scenarioData.balance;
          await this.setBalanceDetails(graphData);
        }
      } else {
        this.log.error(
          `dashboardParameters.type not implemented in hud.component.ts ngOnChanges() ${this.dashboardParameters.type}`
        );
      }
    }
  }

  /**
   * Toggles the expansion state of a panel in the HUD.
   * @param {number} panel - The index of the panel to toggle.
   * @async
   * @method
   */
  async toggleExpand(panel: number) {
    this.panelExpanded[panel] = await this.cardService.toggleExpand(panel);
  }

  private formatDate(date: string): string {
    const dateObj = parse(date, "yyyyMMdd", new Date()); // hud date comes in that hardcoded format. todo need to make that as a const null
    if (isValid(dateObj)) {
      return format(dateObj, this._dateFormat);
    } else {
      this.log.error(`Could not parse date format ${date} 'yyyyMMdd' to ${DASHBOARD_DATE_FORMAT}`);
      return date;
    }
  }

  private async setBalanceDetails(graphData: Array<GraphDataSet>) {
    this._hudDetails.balance = await this.getHudAccountsBalanceData(graphData);
    this._hudTotals.balance = this.getHudTotals(graphData);
  }

  private async setScenarioDetails(scenarioData: ScenarioData) {
    let index = 1;
    let baseScenario: GroupScenarioBalance = scenarioData.scenario[0];
    //TODO : @Sinan@Michelle@Horace - If the scenario does not have the graph data it is the same as previous scenario. So display its value as such.
    for (const scenario of scenarioData.scenario) {
      if (scenario.graphData?.length && index !== 1) {
        baseScenario = scenario;
      }
      const scenarioName = `scenario${index}` as HudDataType;
      this._hudDetails[scenarioName] = await this.getHudAccountsBalanceData(baseScenario.graphData);
      this._hudTotals[scenarioName] = this.getHudTotals(
        baseScenario.graphData,
        scenarioData.anchorPoint
      );
      index++;
    }
  }

  private getHudTotals(dataset: Array<GraphDataSet>, anchorPoint: AnchorPointData = null) {
    const hudTotals = new Map<number, BalanceDetails>();
    for (let band = 0; band < dataset.length; band++) {
      const data = dataset[band];
      const detail = {
        amount: data.balance,
        date: data.midDate ? format(data.midDate, DASHBOARD_DATE_FORMAT) : data.endDate,
      } as BalanceDetails;

      /* Override the fist data point is provided with the AnchorPointData
       * this is required for scenarioOnly view to have the correct start balance displayed */
      if (anchorPoint && band === 0) {
        detail.amount = anchorPoint.anchorBalance;
      }
      hudTotals.set(band, detail);
    }
    return hudTotals;
  }

  private async getHudAccountsBalanceData(
    dataset: Array<GraphDataSet>,
    order = "desc"
  ): Promise<Map<number, AccountCollection>> {
    const hubAccountsBalanceData = new Map<number, AccountCollection>();

    for (let band = 0; band < dataset.length; band++) {
      const data = dataset[band];
      const nodeData: Array<HudAccountBalanceDataInfo> = [];
      let nodeKey = 0;

      for (const accountPair of Object.entries(data.accountGrouping)) {
        const accountId = accountPair[0];
        let accountName = "";
        const accountData = accountPair[1];

        if (this._accountNameMap.has(accountId)) {
          accountName = this._accountNameMap.get(accountId);
        } else {
          // Get the account from the user created ones or the mock account generated from the scenario
          const book =
            (await this.bookService.get(accountId)) ||
            this.dashboardService.getMockAccountPerId(accountId);
          accountName = book?.name ?? "Unknown Account Name";
          this._accountNameMap.set(accountId, accountName);
        }

        nodeKey = band;
        nodeData.push({
          accountName: accountName,
          accountBalance: this.balanceFormat(
            accountData.balance.runningTotalNormalizedValue.amount
          ),
          accountNormalisedCurrency: accountData.balance.runningTotalNormalizedValue.symbol,
        });
      }

      const sortedNodeData = nodeData.sort((a, b) => {
        const balanceA = this.parseBalance(a.accountBalance);
        const balanceB = this.parseBalance(b.accountBalance);
        if (order === "asc") {
          return balanceA - balanceB;
        }
        return balanceB - balanceA;
      });

      if (sortedNodeData.length > NUMBER_OF_ACCOUNTS_TO_DISPLAY) {
        hubAccountsBalanceData.set(
          nodeKey,
          this.combineOtherAccounts(sortedNodeData, NUMBER_OF_ACCOUNTS_TO_DISPLAY)
        );
      } else {
        hubAccountsBalanceData.set(nodeKey, sortedNodeData);
      }
    }

    return hubAccountsBalanceData;
  }

  /**
   * Parses a formatted balance string, removing any formatting to return a numeric value.
   * @param {string} balance - The formatted balance string.
   * @returns {number} The numeric value of the balance.
   * @private
   * @method
   */
  private parseBalance(balance: number): number {
    return Number(parseFloat(balance.toString()).toFixed(2));
  }

  private combineOtherAccounts(
    data: Array<HudAccountBalanceDataInfo>,
    accounts = 2
  ): Array<HudAccountBalanceDataInfo> {
    const mainAccounts = data.slice(0, accounts);
    const otherAccounts = data.slice(accounts);

    const otherTotalBalance = otherAccounts.reduce(
      (acc, curr) => acc + this.parseBalance(curr.accountBalance),
      0
    );
    const otherEntry: HudAccountBalanceDataInfo = {
      accountName: `Other (${otherAccounts.length})`,
      accountBalance: this.balanceFormat(otherTotalBalance),
      accountNormalisedCurrency: this.userCurrency, // Assuming all accounts use the same currency
    };

    return mainAccounts.concat(otherEntry);
  }

  /**
   * Formats a numeric balance into a string with appropriate localization and formatting.
   * @param {number} balance - The numeric balance to format.
   * @private
   * @method
   */
  private balanceFormat(balance: number): number {
    return balance;

    // return balance.toFixed(2);
  }
}
