<div
  class="tw-flex tw-h-auto tw-w-full tw-flex-col tw-justify-center tw-rounded-lg tw-bg-white tw-p-6 md:tw-h-full"
>
  <nav
    class="tw-relative tw-flex tw-w-full tw-cursor-pointer tw-items-center tw-gap-4"
    (click)="redirectToSettings()"
  >
    <div class="md:tw-hidden">
      <svg class="tw-h-7 tw-w-7">
        <use xlink:href="images/sprite.svg#left-arrow" />
      </svg>
    </div>
    <div
      class="tw-hidden tw-h-10 tw-w-10 tw-items-center tw-justify-center tw-rounded-lg tw-bg-neutral-50 tw-shadow-button md:tw-flex"
    >
      <svg class="tw-h-7 tw-w-7">
        <use xlink:href="images/sprite.svg#left-arrow" />
      </svg>
    </div>
    <span class="tw-text-xl tw-font-semibold tw-text-neutral-800 md:tw-hidden">Profile</span>
    <div class="tw-absolute tw-hidden tw-w-full tw-items-center tw-justify-center md:tw-flex">
      <span class="tw-text-xl tw-font-semibold tw-text-neutral-800">Profile</span>
    </div>
  </nav>
  <app-profile class="tw-my-6 tw-flex tw-h-full"></app-profile>
</div>
