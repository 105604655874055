<form
  #form
  (ngSubmit)="submit()"
  [appApiAction]="formPromise"
  class="tw-container tw-mx-auto"
  [formGroup]="formGroup"
>
  <div
    class="tw-mx-auto tw-flex tw-max-w-lg tw-flex-col tw-items-center tw-justify-center tw-py-8 tw-px-8 xs:tw-px-2"
  >
    <div>
      <img class="logo logo-themed" alt="Gloss" />
      <p class="tw-mx-4 tw-mt-3 tw-mb-4 tw-text-center tw-text-xl">
        {{ "loginOrCreateNewAccount" | i18n }}
      </p>
    </div>
    <div
      class="tw-border-secondary-300 tw-mt-3 tw-w-full tw-rounded-3xl tw-bg-background tw-p-6 tw-shadow-xl"
    >
      <ng-container *ngIf="!validatedEmail; else loginPage">
        <div class="tw-mb-3">
          <bit-form-field>
            <bit-label>{{ "emailAddress" | i18n }}</bit-label>
            <input
              id="login_input_email"
              bitInput
              type="email"
              formControlName="email"
              appAutofocus
              (keyup.enter)="validateEmail()"
            />
          </bit-form-field>
        </div>

        <div class="tw-mb-3 tw-flex tw-items-start">
          <div class="tw-flex tw-h-6 tw-items-center">
            <input
              id="login_input_remember-email"
              class="tw-w-4 tw-rounded tw-border"
              bitInput
              type="checkbox"
              formControlName="rememberEmail"
            />
          </div>
          <bit-label class="ml-2">
            {{ "rememberEmail" | i18n }}
          </bit-label>
        </div>

        <div class="tw-mb-3">
          <app-gloss-button
            [options]="{
              text: 'continue' | i18n,
              type: 'submit',
              class: 'primary',
              onClick: validateEmail.bind(this),
              isEnabled: !form.loading
            }"
          >
          </app-gloss-button>
        </div>
      </ng-container>
    </div>
    <div class="tw-mt-7 tw-w-full tw-rounded-xl tw-bg-background tw-py-3 tw-shadow-xl">
      <div class="tw-m-0 tw-flex tw-items-center tw-justify-center tw-text-sm">
        {{ "newAroundHere" | i18n }}
        <svg class="tw-mx-2 tw-h-7 tw-w-7">
          <use xlink:href="images/sprite.svg#add-person" />
        </svg>
        <a
          class="tw-cursor-pointer tw-text-accent hover:tw-text-accent"
          (click)="handleCreateAccount()"
          >{{ "createAccount" | i18n }}
        </a>
      </div>
    </div>
  </div>
</form>

<ng-template [formGroup]="formGroup" #loginPage>
  <div class="tw-mb-6 tw-h-28">
    <bit-form-field class="!tw-mb-1">
      <bit-label>{{ "masterPass" | i18n }}</bit-label>
      <input
        id="login_input_master-password"
        type="password"
        bitInput
        formControlName="masterPassword"
        appAutofocus
      />
      <button type="button" bitSuffix bitIconButton bitPasswordInputToggle></button>
    </bit-form-field>
    <a class="tw-mt-2" routerLink="/hint" (mousedown)="goToHint()" (click)="setFormValues()">{{
      "getMasterPasswordHint" | i18n
    }}</a>
  </div>

  <div [hidden]="!showCaptcha()">
    <iframe id="hcaptcha_iframe" height="80"></iframe>
  </div>

  <div class="tw-flex tw-flex-col tw-gap-2">
    <app-gloss-button
      [options]="{
        text: 'login' | i18n,
        type: 'submit',
        class: 'primary',
        onClick: submit.bind(this),
        isEnabled: !form.loading
      }"
    >
    </app-gloss-button>
    <app-gloss-button
      *ngIf="showLoginWithDevice && showPasswordless"
      [options]="{
        text: 'loginWithDevice' | i18n,
        type: 'submit',
        class: 'neutral',
        onClick: startPasswordlessLogin.bind(this),
        isEnabled: !form.loading
      }"
    >
    </app-gloss-button>
  </div>

  <hr />

  <div class="tw-m-0 tw-text-sm">
    <p class="tw-mb-1">{{ "loggingInAs" | i18n }} {{ loggedEmail }}</p>
    <a [routerLink]="[]" (click)="toggleValidateEmail(false)">{{ "notYou" | i18n }}</a>
  </div>
</ng-template>
