import { PlaidConfigType } from "@bitwarden/web-vault/app/models/types/environement-config.type";

const plaidConfig = process.env.PLAID as PlaidConfigType;
export class PlaidConfig {
  private readonly __v = 1;
  config: Record<string, any>;

  private _url: string;

  private _env: string;

  private _clientName: string;

  private _token: string;

  private _access_token: string;

  private _client_id: string;

  private _secret: string;

  constructor() {
    this.config = plaidConfig;

    /** Set the value of the properties */
    this.url = `${plaidConfig.url}/${plaidConfig.apiStage}`;
    this.env = plaidConfig.apiStage === "local" ? "development" : "production";
    this.clientName = plaidConfig.client_name;
    this.client_id = plaidConfig.client_id;
    this.secret = plaidConfig.secret;
  }

  get url(): string {
    return this._url;
  }

  set url(value: string) {
    this._url = value;
  }

  get env(): string {
    return this._env;
  }

  set env(value: string) {
    this._env = value;
  }

  get clientName(): string {
    return this._clientName;
  }

  set clientName(value: string) {
    this._clientName = value;
  }

  get token(): string {
    return this._token;
  }

  set token(value: string) {
    this._token = value;
  }

  get access_token(): string {
    return this._access_token;
  }

  set access_token(value: string) {
    this._access_token = value;
  }

  get client_id(): string {
    return this._client_id;
  }

  set client_id(value: string) {
    this._client_id = value;
  }

  get secret(): string {
    return this._secret;
  }

  set secret(value: string) {
    this._secret = value;
  }
}
