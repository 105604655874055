import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";

import { Origin } from "@bitwarden/web-vault/app/models/types/general-types";
import { AccountSyncService } from "@bitwarden/web-vault/app/services/syncing/account.sync.service";

import "./sync-action-buttons-cell-renderer.style.scss";

@Component({
  selector: "app-sync-action-buttons-cell-renderer",
  templateUrl: "./sync-action-buttons-cell-renderer.component.html",
  styles: ["sync-action-buttons-cell-renderer.component.scss"],
})
export class SyncActionButtonsCellRenderer implements ICellRendererAngularComp {
  origin: Origin;
  params: any;
  buttons: {
    retry: boolean;
    connect: boolean;
    consent: boolean;
    merge: boolean;
  };

  agInit(params: { data: AccountSyncService }): void {
    this.params = params;
    this.buttons = {
      merge: params.data.point.data?.actions?.includes("merge"),
      connect: params.data.point.data?.actions?.includes("connect"),
      consent: params.data.point.data?.actions?.includes("consent"),
      retry: params.data.point.data?.actions?.includes("retry"),
    };
  }

  refresh(params: any): boolean {
    return false; // No need to refresh since data fetch is already handled
  }

  merge(): void {
    this.params.context.componentParent.merge(this.params.node.data);
  }

  connect(): void {
    this.params.context.componentParent.connect(this.params.node.data);
  }

  consent(): void {
    this.params.context.componentParent.consent(this.params.node.data);
  }

  retry(): void {
    this.params.context.componentParent.retry(this.params.node.data);
  }

  protected readonly Origin = Origin;
}
